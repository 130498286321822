import React from "react";

import styles from "./User.module.scss";

export default ({
  user: {
    personalProfile: { description },
  },
}) => (
  <div className={styles.subtitle}>
    <h4 className={styles.bold}>{description.short}</h4>
  </div>
);
