import { onError } from "apollo-link-error";
import { ACCOUNT_LIMIT_EXCEEDED } from "store/actionTypes";

const errorHandler = (store) =>
  onError((errors) => {
    console.log({ errors });
    if (
      errors &&
      errors.graphQLErrors &&
      errors.graphQLErrors.some(
        (err) => err.message === "Account limit exceeded"
      )
    ) {
      const originalErr = errors.graphQLErrors.find(
        (err) => err.message === "Account limit exceeded"
      );
      errors.response.errors = null;
      store.dispatch({
        type: ACCOUNT_LIMIT_EXCEEDED,
        payload: originalErr.info,
      });
      window.Intercom("trackEvent", "account-limit-triggered", {
        account: originalErr.info.account,
      });
    }

    if (errors && errors.networkError) {
      if (
        errors.networkError.response &&
        errors.networkError.response.status === 401
      ) {
        window.location.replace("/login");
      } else if (!errors.networkError.response) {
        // Log network errors to Rollbar if there is no response.
        window.rollbar && window.rollbar.error(errors.networkError);
      }
    }

    if (errors && errors.graphQLErrors) {
      errors.graphQLErrors.forEach((error) => {
        if (error.code === "UNAUTHENTICATED") {
          window.location.replace("/login");
        }
      });
    }
  });

export default errorHandler;
