import React from "react";
import Button from "components/UI/Button";
import compose from "./compose";

export default compose(({ handleResendInvite, btnState }) => (
  <Button
    bStyle={["none"]}
    onClick={handleResendInvite}
    disabled={btnState.disabled}
  >
    <i className={btnState.text} />
    {btnState.hoverText}
  </Button>
));
