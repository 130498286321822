import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import QueryString from "query-string";
import Icon from "components/UI/Icon";
import Button from "components/UI/Button";
import IENotificationBar from "components/Header/IENotificationBar";
import SignupBox from "components/SignupBox";
import compose from "./compose";

import styles from "./Signup.module.scss";

const slides = [
  {
    title: "Start for free",
    subtitle: "Free for teams of up to 4 people",
    color: "dark",
  },
  {
    title: "Decision support tools",
    subtitle: "Hiring, succession planning, and team development",
    color: "dark",
  },
  {
    title: "Commitment-free",
    subtitle: "No credit card required for full access",
    color: "dark",
  },
];

export default compose(({ location }) => {
  // Internet Explorer 6-11
  const isIE = /* @cc_on!@ */ false || !!document.documentMode;
  const queryString = QueryString.parse(location.search);
  const [notificationBar, setNotificationBar] = useState(!!isIE);
  const [slideNumber, setSlideNumber] = useState(0);

  const setSlide = (direction) => {
    if (direction === "prev") {
      if (slideNumber - 1 >= 0) {
        setSlideNumber(slideNumber - 1);
      } else {
        setSlideNumber(slides.length - 1);
      }
    }

    if (direction === "next") {
      if (slideNumber + 1 <= slides.length - 1) {
        setSlideNumber(slideNumber + 1);
      } else {
        setSlideNumber(0);
      }
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setSlide("next");
    }, 8000);
    return () => clearInterval(interval);
  }, [slideNumber]);

  return (
    <div className={styles.mainWrapper}>
      {notificationBar && (
        <IENotificationBar setNotificationBar={setNotificationBar} />
      )}
      <div className={styles.main}>
        <div className={styles.left}>
          <div className={styles.wrapper}>
            <div className={styles.header}>
              <div className={styles.logoWrapper}>
                <div className={styles.defaultLogo} />
              </div>
            </div>
            <div className={styles.login}>
              <SignupBox promptTermsAndCondition />
              <div className={styles.footer}>
                <p className={styles.alreadyAccount}>
                  Already have an account?
                  <Link
                    to={`login${
                      queryString && queryString.positionTemplate
                        ? `?positionTemplate=${queryString.positionTemplate}`
                        : ""
                    }`}
                  >
                    Log in
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.right}>
          <div className={styles.slides}>
            {slides.map((slide, index) => (
              <div
                key={"Slide-" + slide.title}
                className={classNames(
                  styles.slide,
                  styles[slide.color],
                  slideNumber === index && styles.show
                )}
              >
                <div className={styles.text}>
                  <h3 className={styles.title}>{slide.title}</h3>
                  <p className={styles.subtitle}>{slide.subtitle}</p>
                </div>
                <div className={styles.image} />
                <div className={styles.progressBar}>
                  <div className={styles.progress}></div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
});
