import { withHandlers } from 'recompose'

export default withHandlers({
  handleSetCandidateToOnboard: ({
    setCandidateToOnboard,
    match,
    handleNextStep,
    history,
    CANDIDATE_QUERY: {
      candidateInTeam: {
        position: { onboarding }
      }
    }
  }) => (candidateId) => {
    const positionId = parseInt(match.params.positionId, 10)
    const teamId = parseInt(match.params.teamId, 10)
    if (
      onboarding === null
      || (onboarding && onboarding.candidate.id !== candidateId)
    ) {
      setCandidateToOnboard({
        variables: {
          positionId,
          teamId,
          candidateId
        }
      }).then((res) => {
        handleNextStep()
        history.push(`/team/${teamId}/position/${positionId}`)
      })
    } else {
      handleNextStep()
      history.push(`/team/${teamId}/position/${positionId}`)
    }
  }
})
