import React from "react";
import { compose } from "recompose";
import { graphql } from "react-apollo";
import { loader } from "graphql.macro";
import componentLoader from "utils/loader";
import styles from "./User.module.scss";

const DESCRIPTION_QUERY = loader("./description5.graphql");

const Skeleton = () => <div className={styles.skeleton} />;
export default compose(
  graphql(DESCRIPTION_QUERY),
  componentLoader(Skeleton)
)(
  ({
    data: {
      personalProfile: { description },
    },
  }) => (
    <div className={styles.subtitle}>
      <h4>
        {description.short !== "false"
          ? description.short
          : "Please complete the surveys to learn about your personality and values."}
      </h4>
    </div>
  )
);
