import React from "react";
import classNames from "classnames";
import compose from "./compose";

import styles from "./Footer.module.scss";

export default compose(({ account, relative }) => {
  // Kui ekraani resizeda, tuleks vaadata, kui kõrge on sidebarWrapperi sisu ja vastavalt sellele muuta Footer responsive'ks või mitte.

  return (
    <div
      className={classNames(styles.footerWrapper, relative && styles.relative)}
    >
      {account.whitelabel && account.whitelabel.id !== 1 && (
        <div className={styles.poweredBy}>
          <p>Powered by</p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://wisnio.com"
          >
            <div className={styles.logo} />
          </a>
        </div>
      )}
    </div>
  );
});
