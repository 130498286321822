import React from "react";
import classNames from "classnames";

import styles from "../index.module.scss";

const colors = ["green", "yellow", "grey"];

export default ({
  competencies,
  wedgeAngle,
  midX,
  midY,
  sectorRadius,
  groups,
  setShowModal,
  member,
  type,
  memberCompes,
}) => {
  const compesSortedByScore =
    type === "memberInTeam"
      ? [...memberCompes].sort((a, b) => b.score - a.score)
      : [...competencies].sort((a, b) => b.score - a.score);

  return (
    <>
      {competencies.map((comp, index) => {
        const compIndexByScore = compesSortedByScore.findIndex(
          (d) => d.name === comp.name
        );

        const groupColorIndex =
          compIndexByScore < 3 ? 0 : compIndexByScore > 16 ? 1 : 2;

        const sctorColorIndex =
          groupColorIndex === 0
            ? compIndexByScore
            : groupColorIndex === 1
            ? competencies.length - compIndexByScore
            : 4;

        const startAngle = wedgeAngle * index;
        const scoreAngle = startAngle + wedgeAngle / 2;

        const flipText = startAngle >= 0 && startAngle <= 179;
        const endAngle = startAngle + wedgeAngle;
        const titleRadius = sectorRadius + 3;
        const multiLineTitleRadius = titleRadius + 1.6;
        const flippedMultiLineTitleRadius = titleRadius - 1.6;

        const scoreRadius = (comp.score * sectorRadius) / 100;
        const memberScore =
          member &&
          member.groups
            .map((m) => m.dimensions)
            .flat()
            .find((dim) => dim.name === comp.name);

        const comparisonScore =
          memberScore && comp.score + (50 - memberScore.score) * 2;
        const compariosnScoreRadius =
          comparisonScore && (comparisonScore * sectorRadius) / 100;

        const comparisonEffect =
          comparisonScore && compariosnScoreRadius > scoreRadius
            ? "negative"
            : "positive";

        // console.log({comp, memberScore, comparisonEffect }, 'style', styles[comparisonEffect])

        const sx1 = midX + scoreRadius * Math.cos((Math.PI * startAngle) / 180);
        const sy1 = midY + scoreRadius * Math.sin((Math.PI * startAngle) / 180);
        const sx2 = midX + scoreRadius * Math.cos((Math.PI * endAngle) / 180);
        const sy2 = midY + scoreRadius * Math.sin((Math.PI * endAngle) / 180);

        const Msx1 =
          midX + compariosnScoreRadius * Math.cos((Math.PI * startAngle) / 180);
        const Msy1 =
          midY + compariosnScoreRadius * Math.sin((Math.PI * startAngle) / 180);
        const Msx2 =
          midX + compariosnScoreRadius * Math.cos((Math.PI * endAngle) / 180);
        const Msy2 =
          midY + compariosnScoreRadius * Math.sin((Math.PI * endAngle) / 180);

        const x1 = midX + sectorRadius * Math.cos((Math.PI * startAngle) / 180);
        const y1 = midY + sectorRadius * Math.sin((Math.PI * startAngle) / 180);
        const x2 = midX + sectorRadius * Math.cos((Math.PI * endAngle) / 180);
        const y2 = midY + sectorRadius * Math.sin((Math.PI * endAngle) / 180);
        const tx1 =
          midX + titleRadius * Math.cos((Math.PI * startAngle - 4) / 180);
        const ty1 =
          midY + titleRadius * Math.sin((Math.PI * startAngle - 4) / 180);

        const tx1c =
          midX +
          titleRadius *
            Math.cos((Math.PI * (startAngle - 1 + wedgeAngle / 2) + 4) / 180);
        const ty1c =
          midY +
          titleRadius *
            Math.sin((Math.PI * (startAngle - 1 + wedgeAngle / 2) + 4) / 180);
        const tx2 =
          midX + titleRadius * Math.cos((Math.PI * endAngle + 4) / 180);
        const ty2 =
          midY + titleRadius * Math.sin((Math.PI * endAngle + 4) / 180);
        const tx1m =
          midX +
          multiLineTitleRadius * Math.cos((Math.PI * startAngle - 4) / 180);
        const ty1m =
          midY +
          multiLineTitleRadius * Math.sin((Math.PI * startAngle - 4) / 180);
        const tx2m =
          midX +
          multiLineTitleRadius * Math.cos((Math.PI * endAngle + 4) / 180);
        const ty2m =
          midY +
          multiLineTitleRadius * Math.sin((Math.PI * endAngle + 4) / 180);
        const tx1mf =
          midX +
          flippedMultiLineTitleRadius *
            Math.cos((Math.PI * startAngle - 4) / 180);
        const ty1mf =
          midY +
          flippedMultiLineTitleRadius *
            Math.sin((Math.PI * startAngle - 4) / 180);
        const tx2mf =
          midX +
          flippedMultiLineTitleRadius *
            Math.cos((Math.PI * endAngle + 4) / 180);
        const ty2mf =
          midY +
          flippedMultiLineTitleRadius *
            Math.sin((Math.PI * endAngle + 4) / 180);

        const scoreX =
          midX + sectorRadius * Math.cos((Math.PI * scoreAngle) / 180);
        const scoreY =
          midY + sectorRadius * Math.sin((Math.PI * scoreAngle) / 180);

        const titleIsMultiLine = comp.name.split(" ").length > 1;
        const multiLineTitleArray = comp.name.split(" ");
        const multiLineTitle = [
          [...multiLineTitleArray].reverse().slice(1).reverse().join(" "),
          multiLineTitleArray[multiLineTitleArray.length - 1],
        ];

        return (
          <g
            className={classNames(
              styles.valueSector,
              index === 2 && "competencyGraphSector"
            )}
            key={`competency-${comp.name}`}
            data-tour="2"
            onClick={() => setShowModal(comp.name)}
          >
            <defs>
              <filter id="Shadow" width="300%" height="300%">
                <feOffset result="offOut" in="SourceAlpha" dx=".1" dy=".1" />

                <feGaussianBlur
                  result="blurOut"
                  in="offOut"
                  stdDeviation=".2"
                />
                <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
              </filter>
            </defs>
            <path
              id={`${comp.dim_id}-title-path`}
              className={styles.titlePath}
              d={`M${tx1} ${ty1}  A${titleRadius} ${titleRadius} ${
                flipText ? "0 0 0" : "0 0 1"
              } ${tx2} ${ty2}`}
              transform={flipText ? `rotate(180 ${tx1c},${ty1c})` : ""}
            />

            <path
              id={`${comp.dim_id}-multiLine-title-path`}
              className={styles.titlePath}
              d={`M${tx1m} ${ty1m}  A${multiLineTitleRadius} ${multiLineTitleRadius} ${
                flipText ? "0 0 0" : "0 0 1"
              } ${tx2m} ${ty2m}`}
              transform={flipText ? `rotate(180 ${tx1c},${ty1c})` : ""}
            />
            <path
              id={`${comp.dim_id}-flippedMultiLine-title-path`}
              className={styles.titlePath}
              d={`M${tx1mf} ${ty1mf}  A${flippedMultiLineTitleRadius} ${flippedMultiLineTitleRadius} ${
                flipText ? "0 0 0" : "0 0 1"
              } ${tx2mf} ${ty2mf}`}
              transform={flipText ? `rotate(180 ${tx1c},${ty1c})` : ""}
            />
            <path
              className={styles.sector}
              d={`M${midX} ${midY} L${x1} ${y1} A${sectorRadius} ${sectorRadius} 0 0 1 ${x2} ${y2} z`}
            />

            <path
              className={classNames(styles.hoverIndicator)}
              stroke={styles[`${colors[groupColorIndex]}${sctorColorIndex}`]}
              d={`M${x1} ${y1}  A${sectorRadius} ${sectorRadius} 0 0 1 ${x2} ${y2}`}
            />
            <g transform={`translate(${scoreX}, ${scoreY})`}>
              <circle r="1.5" className={classNames(styles.scoreBg)} />
              <text className={styles.score} textAnchor="middle" y=".5">
                {memberScore
                  ? parseInt(memberScore.score, 10)
                  : parseInt(comp.score, 10)}
              </text>
            </g>

            {titleIsMultiLine ? (
              <>
                <text className={styles.title} textAnchor="middle">
                  <textPath
                    xlinkHref={
                      flipText
                        ? `#${comp.dim_id}-title-path`
                        : `#${comp.dim_id}-multiLine-title-path`
                    }
                    startOffset="50%"
                  >
                    {multiLineTitle[0]}
                  </textPath>
                </text>
                <text className={styles.title} textAnchor="middle">
                  <textPath
                    xlinkHref={
                      flipText
                        ? `#${comp.dim_id}-flippedMultiLine-title-path`
                        : `#${comp.dim_id}-title-path`
                    }
                    startOffset="50%"
                  >
                    {multiLineTitle[1]}
                  </textPath>
                </text>
              </>
            ) : (
              <text className={styles.title} textAnchor="middle">
                <textPath
                  xlinkHref={`#${comp.dim_id}-title-path`}
                  startOffset="50%"
                >
                  {comp.name}
                </textPath>
              </text>
            )}
          </g>
        );
      })}
    </>
  );
};
