import React from "react";
import Layout from "layouts/index";
import Button from "components/UI/Button/index";
import StepStatusRightCol from "./StepStatusRightCol";
import { withRouter } from "react-router-dom";
import styles from "./styles.module.scss";

const StepStatus = ({ assessmentSession, history }) => {
  return (
    <Layout
      variant="2-col-page"
      title={assessmentSession.group.title}
      rightColContent={
        <StepStatusRightCol assessmentSession={assessmentSession} />
      }
      icon="360Assesment"
    >
      <div className={styles.stepStatus__container}>
        <div>
          <p>{assessmentSession.group.subtitle}</p>
          <p>
            Check the status of the assessment, invite reviewers, and access the
            final report.
          </p>
        </div>

        <div>
          <Button
            bStyle={["blue", "verySmall"]}
            onClick={() => history.push(`${history.location.pathname}/results`)}
          >
            View results
          </Button>
        </div>
      </div>
    </Layout>
  );
};

export default withRouter(StepStatus);
