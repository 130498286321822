import React from "react";
import classNames from "classnames";
import Icon from "components/UI/Icon";
import Button from "components/UI/Button";
import TextEditor from "components/TextEditor";
import { useDebounce } from "utils/debounce";
import compose from "./compose";

import styles from "../Requirements.module.scss";

export default compose(
  ({
    handleUpsertRequirement,
    handleDeleteRequirement,
    index,
    card,
    cardOpen,
    setCardOpen,
    cardIndex,
    cardTitle,
    setCardTitle,
    cardDesc,
    setCardDesc,
    isNewCard,
    setNewCard,
    isOpen,
    newCard,
  }) => {
    const autoSave = useDebounce(() => {
      if (card.id && cardTitle && cardTitle !== "") {
        handleUpsertRequirement();
      } else {
        if (cardTitle && cardTitle !== "") {
          handleUpsertRequirement();
        }
      }
    }, 500);

    return (
      <div
        role="button"
        tabIndex={index}
        className={classNames(
          styles.containerWrapper,
          isOpen ? styles.open : styles.closed
        )}
        onKeyPress={() => {
          if (!isOpen) {
            setNewCard(null);
            setCardOpen(card.order);
          }
        }}
        onClick={() => {
          if (!isOpen) {
            setNewCard(null);
            setCardOpen(card.order);
          }
        }}
      >
        <div className={classNames(styles.buttons, isOpen && styles.open)}>
          {isOpen && card.id && (
            <Button
              bStyle={["newsimple", "red"]}
              onClick={handleDeleteRequirement}
            >
              Remove
            </Button>
          )}
          {isOpen ? (
            <Button
              bStyle={["none"]}
              onClick={() => {
                setCardOpen(null);
                if (!card.id && cardTitle === "") {
                  setNewCard(null);
                }
              }}
            >
              <div className={classNames(styles.icon, styles.open)}>
                {card.id ? <Icon icon="Chevron" /> : <Icon icon="Close" />}
              </div>
            </Button>
          ) : (
            <div className={styles.icon}>
              <Button bStyle={["none"]}>
                <Icon icon="Chevron" />
              </Button>
            </div>
          )}
        </div>
        <div className={styles.row}>
          <div className={styles.col}>
            <span className={styles.number}>{card.order + 1}</span>
          </div>
          <div className={styles.col}>
            <div className={styles.text}>
              <input
                className={styles.title}
                type="text"
                placeholder="Add requirement title"
                autoFocus
                value={cardTitle}
                onChange={(e) => {
                  setCardTitle(e.target.value);
                  autoSave();
                }}
                disabled={!isOpen}
              />
              {isOpen && (
                <div className={styles.textAreaWrapper}>
                  <TextEditor
                    value={cardDesc}
                    handleChange={setCardDesc}
                    autoSave={autoSave}
                    placeholder="Describe requirement"
                  />
                </div>
              )}
            </div>
          </div>
          <div className={styles.col} />
        </div>
      </div>
    );
  }
);

// <TextareaAutosize
//   maxRows={8}
//   className={styles.text}
//   rows={2}
//   type='text'
//   placeholder='Add criteria description'
//   value={cardDesc}
//   onChange={e => setCardDesc(e.target.value)}
// />
