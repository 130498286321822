import React from 'react'
import classNames from 'classnames'
import Button from 'components/UI/Button'

import styles from './MapCompetencies.module.scss'

export default ({
  competencies,
  setShowModal,
  columnTitle,
  colStyle,
  amountOfStars
}) => (
  <div className={classNames(styles.compBox, styles[colStyle])}>
    <h4 className={styles.title}>
      <div className={styles.stars}>
        {[...Array(amountOfStars)].map((e, i) => (
          <i key={`star${i}`} className='fas fa-star' />
        ))}
      </div>
      {columnTitle}
    </h4>
    <div className={styles.compColumn}>
      {competencies.map(c => (
        <div className={styles.comp} key={c.title}>
          <Button
            bStyle={['none']}
            type='button'
            onClick={() => setShowModal(c.title)}
          >
            <h5 className={styles.compTitle}>{c.title}</h5>
            <p className={styles.compDesc}>{c.descShort}</p>
          </Button>
        </div>
      ))}
    </div>
  </div>
)
