import React from 'react'
import Score from 'components/Score'
import classNames from 'classnames'
import ViewerAvatar from 'components/ViewerAvatar'

export default ({ styles, dim }) => {
  return (
    <>
      <div className={styles.lineWrapper}>
        <div className={styles.line}>
          <div
            className={styles.avatar}
            style={{ left: `calc(${dim.score}% - 35px)` }}
          >
            <p className={styles.avatarScore}>
              <Score score={dim.score} />
            </p>
            <ViewerAvatar size="medium" />
          </div>
        </div>
        <div className={styles.opposites}>
          <div className={styles.left}>
            <h4 className={styles.label}>{dim.label_min}</h4>
            <p className={styles.desc}>{dim.description_min}</p>
          </div>
          <div className={styles.right}>
            <h4 className={styles.label}>{dim.label_max}</h4>
            <p className={styles.desc}>{dim.description_max}</p>
          </div>
        </div>
      </div>
      <div className={styles.asteriskWrapper} />
      <div className={styles.listBoxWrapper}>
        <div className={classNames(styles.listBox, styles.workBest)}>
          <h2>
            <i className="fas fa-bolt" />
            You are strong at:
          </h2>
          <div className={styles.listRowWrapper}>
            <div className={styles.listRow}>
              <h4>{dim.strengths[0].short}</h4>
              <p>{dim.strengths[0].long}</p>
            </div>
            <div className={styles.listRow}>
              <h4>{dim.strengths[1].short}</h4>
              <p>{dim.strengths[1].long}</p>
            </div>
          </div>
        </div>
        <div
          className={classNames(styles.listBox, styles.mightStruggle)}
        >
          <h2>
            <i className="fas fa-exclamation-triangle" />
            Not comfortable with:
          </h2>
          <div className={styles.listRowWrapper}>
            <div className={styles.listRow}>
              <h4>{dim.derailers[0].short}</h4>
              <p>{dim.derailers[0].long}</p>
            </div>
            <div className={styles.listRow}>
              <h4>{dim.derailers[1].short}</h4>
              <p>{dim.derailers[1].long}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
