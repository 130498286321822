import React, { useEffect } from "react";
import Modal from "react-modal";
import classNames from "classnames";
import SidebarWrapper from "components/UI/SidebarWrapper";
import Footer from "components/Footer";
import Button from "components/UI/Button";
import { Link } from "react-router-dom";
import { ReactComponent as UserIcon } from "images/icons/User.svg";
import { ReactComponent as CardIcon } from "images/icons/Card.svg";
import { useSidebar } from "utils/sidebarProvider";
import SubHeader from "../SubHeader";
import styles from "./Billing.module.scss";
import SubscriptionManagement from "./SubscriptionManagement";
import SubscriptionModal from "./SubscriptionModal";

import compose from "./compose";

export default compose(
  ({
    data: { account },
    history,
    viewer,
    subscribeModal,
    setSubscribeModal,
  }) => {
    const { contentContext } = useSidebar();
    const [content, setContent] = contentContext;

    const sidebarContent = [
      {
        items: [
          {
            title: "General",
            action: () => history.push("/account/" + account.id + "/settings"),
            style: "",
          },
          {
            title: "Members",
            action: () => history.push("/account/" + account.id + "/members"),
            style: "",
          },
          {
            title: "Organisations",
            action: () =>
              history.push("/account/" + account.id + "/organisations"),
            style: "",
          },
          {
            title: "Billing",
            action: () => history.push("/account/" + account.id + "/billing"),
            style: "active",
          },
        ],
      },
    ];

    useEffect(() => {
      setContent(sidebarContent);
    }, []);

    return (
      <SidebarWrapper noHeader>
        <div className={styles.wrapper}>
          <div className={styles.topWrapper}>
            <h2 className={styles.title}>Workspace billing</h2>
            <p className={styles.name}>{account.name}</p>
          </div>
          <div className={styles.bottomWrapper}>
            <div className={styles.left}>
              <div className={styles.icon}>
                <UserIcon />
              </div>
              <h2 className={styles.seats}>
                {`${
                  account.subscription.type === "stripe"
                    ? account.subscription.seatsUsed
                    : account.subscription.allowedProfiles
                } seats`}
              </h2>
              <div className={styles.usedBar}>
                {account.subscription.type !== "stripe" && (
                  <>
                    <p className={styles.used}>
                      {`${account.subscription.seatsUsed} of ${account.subscription.allowedProfiles} used`}
                    </p>
                    <div className={styles.bar}>
                      <div
                        style={{
                          width: `${
                            (account.subscription.seatsUsed /
                              account.subscription.allowedProfiles) *
                              100 >
                            100
                              ? 100
                              : (account.subscription.seatsUsed /
                                  account.subscription.allowedProfiles) *
                                100
                          }%`,
                        }}
                        className={styles.filler}
                      />
                    </div>
                  </>
                )}
              </div>
              <div className={styles.bottomText}>
                <p>
                  Seat usage is calculated based on the number of admins,
                  members, and candidates across all organisations in your
                  workspace.
                </p>
              </div>
              <div className={styles.bottomButtons}>
                <Link to={`/account/${account.id}/members`}>
                  <Button bStyle={["secondary", "grey"]}>Manage people</Button>
                </Link>
              </div>
            </div>
            <div className={styles.right}>
              <div className={styles.icon}>
                <CardIcon />
              </div>
              {account.subscription.type === "free" && (
                <div className={styles.text}>
                  <h5>Free tier</h5>
                  {account.isOwner ? (
                    <p>
                      With the Wisnio free tier, you can invite up to three
                      additional people to join you on the platform. If you need
                      more seats, subscribe or contact sales below.
                    </p>
                  ) : (
                    <p>
                      All billing is handled through the workspace owner. With
                      any questions, contact them or talk with Customer Support
                      directly.
                    </p>
                  )}
                </div>
              )}

              {account.subscription.type === "stripe" && (
                <div className={styles.text}>
                  <h5>Manage subscription settings</h5>
                  {viewer.id === account.subscription.billingUser.id ? (
                    <p>
                      This workspace is currently on a pay per seat plan. If you
                      need to make changes, you can manage the subscription
                      below or contact us directly.
                    </p>
                  ) : (
                    <p>
                      This workspace is currently on a pay per seat plan. If you
                      need to make changes, please contact the person below.
                    </p>
                  )}
                </div>
              )}

              {account.subscription.type === "custom" && (
                <div className={styles.text}>
                  <h5>Enterprise billing</h5>
                  <p>
                    This workspace is using enterprise billing. If you have
                    questions or need to change anything, contact us below.
                  </p>
                </div>
              )}
              <SubscriptionManagement
                subscription={account.subscription}
                setSubscribeModal={setSubscribeModal}
                isOwner={account.isOwner}
              />
            </div>
          </div>
        </div>
        <Modal
          closeTimeoutMS={350}
          isOpen={!!subscribeModal}
          onRequestClose={() => setSubscribeModal(false)}
          shouldCloseOnOverlayClick
          className={classNames(styles.modal, "fullModal")}
        >
          <Button
            bStyle="close"
            type="button"
            onClick={() => setSubscribeModal(false)}
          >
            <i className="fal fa-times" />
          </Button>
          <SubscriptionModal setSubscribeModal={setSubscribeModal} />
        </Modal>
      </SidebarWrapper>
    );
  }
);
