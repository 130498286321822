import React, { useEffect } from "react";
import SidebarWrapper from "components/UI/SidebarWrapper";
import Footer from "components/Footer";
import Field from "components/UI/Field";
import Modal from "react-modal";
import Button from "components/UI/Button";
import { useSidebar } from "utils/sidebarProvider";
import compose from "./compose";
import RemoveOrg from "./RemoveOrg";

import styles from "./General.module.scss";

export default compose(
  ({
    history,
    data: { organization },
    deleteOrg,
    handleChangeName,
    showDeletePrompt,
    updateDeletePrompt,
    orgName,
    setOrgName,
  }) => {
    const { contentContext } = useSidebar();
    const [content, setContent] = contentContext;

    const sidebarContent = [];

    useEffect(() => {
      setContent(sidebarContent);
    }, []);

    return (
      <SidebarWrapper noHeader>
        <h2 className={styles.title}>Organisation settings</h2>
        <p className={styles.name}>{organization.name}</p>
        <div className={styles.content}>
          <div className={styles.wrapper}>
            <div className={styles.left}>
              <div className={styles.nameChange}>
                <form onSubmit={(e) => handleChangeName(e)} autoComplete="off">
                  <div className={styles.input}>
                    <h5 className={styles.label}>Organisation name</h5>
                    <Field
                      white
                      small
                      type="text"
                      name="name"
                      value={orgName}
                      onChange={(e) => {
                        setOrgName(e.target.value);
                      }}
                    />
                  </div>
                  <Button bStyle={["primary"]} type="submit">
                    Save changes
                  </Button>
                </form>
              </div>
              <div className={styles.deleteOrg}>
                <h5 className={styles.title}>Delete organisation</h5>
                <p>
                  Be aware that all open positions and teams in this
                  organisation will also be deleted!
                </p>
                <Button
                  bStyle={["secondary", "grey"]}
                  type="button"
                  onClick={() => updateDeletePrompt(true)}
                >
                  Delete organisation
                </Button>
              </div>
            </div>

            <Modal
              isOpen={showDeletePrompt}
              onRequestClose={() => updateDeletePrompt(false)}
              shouldCloseOnOverlayClick
              className={styles.modal}
            >
              <RemoveOrg
                deleteOrg={deleteOrg}
                organization={organization}
                updateDeletePrompt={updateDeletePrompt}
                history={history}
              />
            </Modal>
          </div>
        </div>
      </SidebarWrapper>
    );
  }
);
