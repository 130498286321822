import React from "react";
import { compose, withState, withHandlers, lifecycle } from "recompose";
import { graphql, withApollo } from "react-apollo";
import { loader } from "graphql.macro";
import withStore from "utils/withStore";
import handleLoading from "utils/loader";
import scrollToTop from "utils/scrollToTop";
import Circles from "components/Loaders/Circles";
import { TOGGLE_EDITING_COMPETENCIES } from "store/actionTypes";

import returnCardObject from "./returnCardObject";

const POSITION_TEMPLATE = loader("./positionTemplateCompetencies4.graphql");
const UPDATE_POSITION_TEMPLATE_COMPETENCIES = loader(
  "./updatePositionTemplateCompetencies.graphql"
);

export default compose(
  withApollo,
  withStore("newposition"),
  graphql(POSITION_TEMPLATE, {
    options: (props) => ({
      variables: {
        id: parseInt(props.match.params.templateId, 10),
        org_id: parseInt(props.match.params.orgId, 10),
      },
      notifyOnNetworkStatusChange: true,
    }),
  }),
  graphql(UPDATE_POSITION_TEMPLATE_COMPETENCIES, {
    name: "updatePositionTemplateCompetencies",
  }),
  handleLoading(() => (
    <div style={{ height: "100vh" }}>
      <Circles />
    </div>
  )),
  withState("columns", "setColumns", ({ data }) => {
    const columns = [[], [], [], []];
    const colKeys = [
      [0, 1, 2],
      [3, 4, 5],
      [6, 7, 8],
    ];
    console.log(data.positionTemplate);
    if (data.positionTemplate && data.positionTemplate.competencies !== null) {
      data.positionTemplate.competencies.forEach((comp, key) => {
        const colKey =
          comp.answer === null
            ? 3
            : key > 8
            ? 3
            : colKeys.findIndex((col) => col.some((cKey) => cKey === key));

        columns[colKey].push(returnCardObject(comp));
      });
    }
    return columns;
  }),
  withState("openModal", "setOpenModal", ""),
  withHandlers({
    handleSave:
      ({
        match,
        history,
        client,
        data: { positionTemplate },
        updatePositionTemplateCompetencies,
        columns,
      }) =>
      async () => {
        try {
          const result = await updatePositionTemplateCompetencies({
            variables: {
              template_id: positionTemplate.id,
              competencies: columns
                .flat()
                .map(
                  (
                    {
                      dim_id: id,
                      title,
                      desc,
                      descShort,
                      survey_row_id,
                      behaviours,
                    },
                    index
                  ) => ({
                    id,
                    title,
                    desc,
                    descShort,
                    behaviours,
                    survey_row_id,
                    answer: 100 - index,
                  })
                ),
            },
          });

          history.push(
            `/${parseInt(match.params.orgId, 10)}/scorecard/${parseInt(
              match.params.templateId,
              10
            )}/edit`
          );
        } catch (err) {
          console.log({ err });
        }
      },
    handleDiscard:
      ({ match, history, data: { positionTemplate } }) =>
      () => {
        history.push(
          `/${parseInt(match.params.orgId, 10)}/scorecard/${parseInt(
            match.params.templateId,
            10
          )}/edit`
        );
      },
  }),
  scrollToTop,
  lifecycle({
    componentWillUnmount() {
      this.props.dispatch({ type: TOGGLE_EDITING_COMPETENCIES });
    },
  })
);
