import { compose, withProps } from 'recompose'

export default compose(
  withProps((props) => {
    const dimScores = props.members
      .filter(m => m[`${props.resource}Chart`].readyToRender)
      .map((m) => {
        const d = m[props.resource].find(v => v.name === props.dim.name)
        return d.score
      })
    return {
      minScore: Math.min(...dimScores),
      maxScore: Math.max(...dimScores)
    }
  })
)
