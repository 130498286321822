import React, { useState } from "react";
import { typography } from "style/general";
import Review from "./Review";
import ScorecardListRow from "components/v2/UI/ScorecardListRow";
import UserRowStatus from "components/v2/UI/UserRowStatus";
import Invite from "../Invite";
import Modal from "react-modal";
import compose from "./compose";
import Icon from "components/UI/Icon";
import Button from "components/UI/Button";
import styles from "../styles.module.scss";

const StepStatusRightCol = compose(({ assessmentSession }) => {
  const [modal, updateModal] = useState(false);
  const [overlayClose, setOverlayClose] = useState(true);
  const { employee_under_review, scorecard, reviews } = assessmentSession;

  return (
    <div className={styles.stepStatusRightCol}>
      <div className={styles.stepStatusRightCol__section}>
        <p className="label">Evaluating</p>
        <div>
          <UserRowStatus user={employee_under_review} />
        </div>
      </div>
      <div className={styles.stepStatusRightCol__section}>
        <p className="label">Scorecard</p>
        {scorecard && (
          <div>
            <ScorecardListRow scorecard={scorecard} />
          </div>
        )}
      </div>
      <div className={styles.stepStatusRightCol__section}>
        <p className="label">Reviewers</p>
        <div>
          {reviews.length > 0 &&
            reviews.map((review) => {
              return (
                <Review
                  user={review.reviewer}
                  review={review}
                  status={review.status}
                  assessmentSession={assessmentSession}
                  key={review.id}
                />
              );
            })}
        </div>
      </div>
      <div className={styles.stepStatusRightCol__center}>
        <Button
          bStyle={["verySmall", "bluetext"]}
          onClick={() => updateModal(true)}
        >
          <Icon icon="Plus" primary marginRight />
          Add reviewers
        </Button>
      </div>

      <Modal
        closeTimeoutMS={350}
        isOpen={modal}
        onRequestClose={() => updateModal(false)}
        shouldCloseOnOverlayClick={overlayClose}
        shouldCloseOnEsc={overlayClose}
      >
        <Invite
          assessmentSession={assessmentSession}
          updateModal={updateModal}
          setOverlayClose={setOverlayClose}
        />
      </Modal>
    </div>
  );
});

export default StepStatusRightCol;
