import React from "react";
import Button from "components/UI/Button";
import EditorComponent from "./EditorComponent";
import compose from "./compose";

import styles from "./AboutPosition.module.scss";

export default compose(
  ({
    position,
    description,
    setDescription,
    handleSaveDescription,
    closeModal,
  }) => (
    <div className={styles.main}>
      <div className={styles.wrapper}>
        <h2>
          <span className={styles.checkWrapper}>
            <i className="far fa-check" />
          </span>
          Position description
        </h2>
        <p>
          Add a short description that summarises and explains the position. 1-2
          paragraphs are enough. Don't add a list of requirements, competencies,
          or a long introduction of the company.
        </p>
        <div className={styles.content}>
          <EditorComponent
            content={position.description}
            value={description}
            handleSave={handleSaveDescription}
            position={position}
            handleTextEditorChange={setDescription}
            editBtnTitle="Edit text"
          />
        </div>
      </div>
    </div>
  )
);
