import React from "react"
import classNames from "classnames"

import styles from "../index.module.scss"

const colors = ["green", "yellow", "grey"]

export default ({
  data,
  valuesChart,
  values,
  wedgeAngle,
  midX,
  midY,
  sectorRadius,
  updateModal,
  openModal,
  modalComponent,
  members,
  isCandidate,
  resource,
  highlight,
  candidate,
  casestudy,
}) => {
  const valuesSortedByScore =
    resource === "member"
      ? [...highlight.valuesChart.values].sort((a, b) => b.score - a.score)
      : [...valuesChart.values].sort((a, b) => b.score - a.score)

  return (
    <>
      {values.map((value, index) => {
        const valueIndexByScore = valuesSortedByScore.findIndex(
          (d) => d.name === value.name
        )

        const groupColorIndex =
          valueIndexByScore < 3 ? 0 : valueIndexByScore > 15 ? 1 : 2

        const sctorColorIndex =
          groupColorIndex === 0
            ? valueIndexByScore
            : groupColorIndex === 1
            ? values.length - valueIndexByScore
            : 4

        const condition = valuesChart.valueGroups.find(
          (group) => group.groupId === value.parentGroup
        )
        if (!condition) {
          // console.log('groups', valuesChart.valueGroups)
          // console.log('value', value)
        }

        const startAngle = wedgeAngle * index
        const scoreAngle = startAngle + wedgeAngle / 2

        const flipText = startAngle >= 0 && startAngle <= 180
        const endAngle = startAngle + wedgeAngle
        const titleRadius = sectorRadius + 3
        const scoreRadius = sectorRadius
        const multiLineTitleRadius = titleRadius + 1.6
        const flippedMultiLineTitleRadius = titleRadius - 1.6
        const x1 = midX + sectorRadius * Math.cos((Math.PI * startAngle) / 180)
        const y1 = midY + sectorRadius * Math.sin((Math.PI * startAngle) / 180)
        const x2 = midX + sectorRadius * Math.cos((Math.PI * endAngle) / 180)
        const y2 = midY + sectorRadius * Math.sin((Math.PI * endAngle) / 180)
        const tx1 =
          midX + titleRadius * Math.cos((Math.PI * startAngle - 4) / 180)
        const ty1 =
          midY + titleRadius * Math.sin((Math.PI * startAngle - 4) / 180)

        const tx1c =
          midX +
          titleRadius *
            Math.cos((Math.PI * (startAngle - 1 + wedgeAngle / 2) + 4) / 180)
        const ty1c =
          midY +
          titleRadius *
            Math.sin((Math.PI * (startAngle - 1 + wedgeAngle / 2) + 4) / 180)
        const tx2 =
          midX + titleRadius * Math.cos((Math.PI * endAngle + 4) / 180)
        const ty2 =
          midY + titleRadius * Math.sin((Math.PI * endAngle + 4) / 180)
        const tx1m =
          midX +
          multiLineTitleRadius * Math.cos((Math.PI * startAngle - 4) / 180)
        const ty1m =
          midY +
          multiLineTitleRadius * Math.sin((Math.PI * startAngle - 4) / 180)
        const tx2m =
          midX + multiLineTitleRadius * Math.cos((Math.PI * endAngle + 4) / 180)
        const ty2m =
          midY + multiLineTitleRadius * Math.sin((Math.PI * endAngle + 4) / 180)
        const tx1mf =
          midX +
          flippedMultiLineTitleRadius *
            Math.cos((Math.PI * startAngle - 4) / 180)
        const ty1mf =
          midY +
          flippedMultiLineTitleRadius *
            Math.sin((Math.PI * startAngle - 4) / 180)
        const tx2mf =
          midX +
          flippedMultiLineTitleRadius * Math.cos((Math.PI * endAngle + 4) / 180)
        const ty2mf =
          midY +
          flippedMultiLineTitleRadius * Math.sin((Math.PI * endAngle + 4) / 180)

        const titleIsMultiLine = value.name.split(" ").length > 1

        const multiLineTitleArray = value.name.split(" ")
        const multiLineTitle = [
          [...multiLineTitleArray].reverse().slice(1).reverse().join(" "),
          multiLineTitleArray[multiLineTitleArray.length - 1],
        ]

        const scoreX =
          midX + scoreRadius * Math.cos((Math.PI * scoreAngle) / 180)
        const scoreY =
          midY + scoreRadius * Math.sin((Math.PI * scoreAngle) / 180)

        const resouceValues =
          resource === "member"
            ? [...highlight.valuesChart.values]
            : [...values]

        const isMotivator = resouceValues
          .sort((a, b) => b.score - a.score)
          .slice(0, 3)
          .some((v) => v.name === value.name)

        const isStruggle = resouceValues
          .sort((a, b) => a.score - b.score)
          .slice(0, 3)
          .some((v) => v.name === value.name)

        return (
          <g
            className={classNames(
              styles.valueSector,
              index === 1 && "valuesGraphSector"
            )}
            key={value.dim_id}
          >
            <defs>
              <filter id="Shadow" width="300%" height="300%">
                <feOffset result="offOut" in="SourceAlpha" dx=".1" dy=".1" />
                <feGaussianBlur
                  result="blurOut"
                  in="offOut"
                  stdDeviation=".2"
                />
                <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
              </filter>
            </defs>
            <path
              id={`${value.dim_id}-title-path`}
              className={styles.titlePath}
              d={`M${tx1} ${ty1}  A${titleRadius} ${titleRadius} ${
                flipText ? "0 0 0" : "0 0 1"
              } ${tx2} ${ty2}`}
              transform={flipText ? `rotate(180 ${tx1c},${ty1c})` : ""}
            />

            <path
              id={`${value.dim_id}-multiLine-title-path`}
              className={styles.titlePath}
              d={`M${tx1m} ${ty1m}  A${multiLineTitleRadius} ${multiLineTitleRadius} ${
                flipText ? "0 0 0" : "0 0 1"
              } ${tx2m} ${ty2m}`}
              transform={flipText ? `rotate(180 ${tx1c},${ty1c})` : ""}
            />
            <path
              id={`${value.dim_id}-flippedMultiLine-title-path`}
              className={styles.titlePath}
              d={`M${tx1mf} ${ty1mf}  A${flippedMultiLineTitleRadius} ${flippedMultiLineTitleRadius} ${
                flipText ? "0 0 0" : "0 0 1"
              } ${tx2mf} ${ty2mf}`}
              transform={flipText ? `rotate(180 ${tx1c},${ty1c})` : ""}
            />
            <path
              onClick={() => updateModal(value)}
              className={styles.sector}
              d={`M${midX} ${midY} L${x1} ${y1} A${sectorRadius} ${sectorRadius} 0 0 1 ${x2} ${y2} z`}
            />
            <path
              className={classNames(styles.hoverIndicator)}
              stroke={styles[`${colors[groupColorIndex]}${sctorColorIndex}`]}
              d={`M${x1} ${y1}  A${sectorRadius} ${sectorRadius} 0 0 1 ${x2} ${y2}`}
            />
            <g transform={`translate(${scoreX}, ${scoreY})`}>
              <circle
                r="1.5"
                className={classNames(
                  styles.scoreBg,
                  resource === "member" && styles.member
                )}
              />
              <text className={styles.score} textAnchor="middle" y=".5">
                {resource === "member"
                  ? parseInt(
                      highlight.valuesChart.values.find(
                        (v) => v.name === value.name
                      ).score,
                      10
                    )
                  : parseInt(value.score, 10)}
              </text>
            </g>

            {titleIsMultiLine ? (
              <>
                <text className={styles.title} textAnchor="middle">
                  <textPath
                    xlinkHref={
                      flipText
                        ? `#${value.dim_id}-title-path`
                        : `#${value.dim_id}-multiLine-title-path`
                    }
                    startOffset="50%"
                  >
                    {multiLineTitle[0]}
                  </textPath>
                </text>
                <text className={styles.title} textAnchor="middle">
                  <textPath
                    xlinkHref={
                      flipText
                        ? `#${value.dim_id}-flippedMultiLine-title-path`
                        : `#${value.dim_id}-title-path`
                    }
                    startOffset="50%"
                  >
                    {multiLineTitle[1]}
                  </textPath>
                </text>
              </>
            ) : (
              <text className={styles.title} textAnchor="middle">
                <textPath
                  xlinkHref={`#${value.dim_id}-title-path`}
                  startOffset="50%"
                >
                  {value.name}
                </textPath>
              </text>
            )}
          </g>
        )
      })}
    </>
  )
}
