import React from 'react'
import Button from 'components/UI/Button'
import compose from './compose'

export default compose(({ btnState, handleResendEvaluationInvite }) => (
  <Button bStyle={['none']} onClick={handleResendEvaluationInvite} disabled={btnState.disabled}>
    <i className={btnState.text} />
    {btnState.hoverText}
  </Button>
))
