export default ({
  cache, candId, proxy, team_id
}) => {
  const positions = Object.keys(cache.data).filter(
    key => key.match(/^PositionType:([0-9]+)$/) !== null
  )

  positions.forEach((position) => {
    const cachedPosition = cache.data[position]
    const condition = Object.keys(cachedPosition).includes('candidates')
      && cachedPosition.candidates.some(
        candidate => candidate.id === `${candidate.typename}:${candId}`
      )
    if (condition) {
      cache.data[position].candidates = cache.data[position].candidates.filter(
        candidate => candidate.id !== `${candidate.typename}:${candId}`
      )
    }
    if (
      cachedPosition.onboarding
      && cachedPosition.onboarding.candidate
      && cachedPosition.onboarding.candidate.id === candId
    ) {
      cachedPosition.onboarding = null
    }
  })
  const teamKeys = Object.keys(cache.data).filter(key => key.includes(`TeamType:${team_id}`))
  teamKeys.forEach((key) => {
    delete cache.data[key]
  })
  proxy.restore(cache.data)

  return true
}
