import React from "react";
import { compose } from "recompose";
import { graphql } from "react-apollo";
import { loader } from "graphql.macro";
import renderLoader from "utils/loader";

import styles from "./Values.module.scss";

const PERSONAL_VALUES = loader("./PersonalValues.graphql");

const Skeleton = () => (
  <div className={styles.skeleton}>
    <div className={styles.column}>
      <div className={styles.header}>
        <div />
        <div />
      </div>
      <div className={styles.body}>
        <div />
        <div />
        <div />
      </div>
      <div className={styles.body}>
        <div />
        <div />
        <div />
      </div>
      <div className={styles.body}>
        <div />
        <div />
        <div />
      </div>
    </div>
    <div className={styles.column}>
      <div className={styles.header}>
        <div />
        <div />
      </div>
      <div className={styles.body}>
        <div />
        <div />
        <div />
      </div>
      <div className={styles.body}>
        <div />
        <div />
        <div />
      </div>
      <div className={styles.body}>
        <div />
        <div />
        <div />
      </div>
    </div>
  </div>
);

export default compose(graphql(PERSONAL_VALUES), renderLoader(Skeleton));
