import React, { useState, useEffect } from "react";
import classNames from "classnames";
import TextareaAutosize from "react-autosize-textarea";
import { useDebounce } from "utils/debounce";
import Button from "components/UI/Button";
import Icon from "components/UI/Icon";
import styles from "./KeyHighlights.module.scss";

export default ({
  highlight,
  handleUpdateCandidateKeyHighlight,
  handleDeleteCandidateKeyHighlight,
}) => {
  const titleValue = highlight.description
    ? highlight.title + " - " + highlight.description
    : highlight.title;
  const [title, setTitle] = useState(titleValue);
  const [desc, setDesc] = useState(highlight.description);

  const autoSave = useDebounce(() => {
    handleUpdateCandidateKeyHighlight(highlight.id, title, desc);
  }, 500);

  return (
    <div className={styles.card}>
      <div className={classNames(styles.dragHandle)}>
        <Icon icon="Drag" />
      </div>
      <div className={styles.top}>
        <div className={styles.deleteIcon}>
          <Button
            bStyle={["none"]}
            onClick={() => handleDeleteCandidateKeyHighlight(highlight.id)}
          >
            <Icon icon="Close" />
          </Button>
        </div>
      </div>
      <div className={styles.bottom}>
        <TextareaAutosize
          rows={1}
          maxRows={5}
          value={title}
          onChange={(e) => {
            setTitle(e.target.value);
            autoSave();
          }}
          placeholder="Add highlight"
          className={styles.title}
          data-gramm_editor="false"
          spellCheck="false"
        />
      </div>
    </div>
  );
};
