import React from "react";
import { compose, withState, withHandlers } from "recompose";
import { graphql, withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import handleLoading from "utils/loader";
import FullPage from "components/Loaders/FullPage";
import { loader } from "graphql.macro";

const POSITION_QUERY = loader("client/Queries/PositionQuery98.graphql");
const UPDATE_POSITION_TEMPLATE = loader("./updatePositionTemplate6.graphql");
const UPDATE_POSITION = loader("./updatePosition2.graphql");

export default compose(
  withApollo,
  withRouter,
  graphql(POSITION_QUERY, {
    options: ({ match }) => ({
      variables: {
        id: parseInt(match.params.positionId, 10),
      },
    }),
  }),
  handleLoading(() => <FullPage />),
  withState("openModal", "updateModal", ""),
  withState(
    "nameValue",
    "setNameValue",
    (props) => props.data.openPosition.name
  ),
  withState(
    "descValue",
    "setDescValue",
    (props) => props.data.openPosition.description
  ),
  withState("saved", "setSaved", false),
  graphql(UPDATE_POSITION, {
    name: "updatePosition",
  }),
  withHandlers({
    handleUpdatePosition:
      ({ updatePosition, nameValue, descValue, setSaved, match }) =>
      () => {
        updatePosition({
          variables: {
            id: parseInt(match.params.positionId, 10),
            name: nameValue,
            description: descValue,
          },
        }).then((res) => {
          setSaved(true);
          setTimeout(() => setSaved(false), 4000);
        });
      },
  })
);
