import sortTeamCompetencies from 'utils/sortTeamCompetencies'

export default (props) => {
  const { comp } = props

  const teamHasCompetencies = props.team.competencies.length > 0
    && props.team.members.some(m => m.readyToRender)
  const teamCompes = sortTeamCompetencies(props.team)

  return {
    coreComp:
      teamHasCompetencies
      && ['expert', 'strong'].includes(
        teamCompes.find(c => c.name === comp.title).group
      ),

    potGap:
      teamHasCompetencies
      && ['gap'].includes(teamCompes.find(c => c.name === comp.title).group)
  }
}
