import React, { useState, useContext, createContext } from "react";

const useProvideSidebar = () => {
  const [content, setContent] = useState("");

  return [content, setContent];
};

const sidebarContext = createContext();

const SidebarProvider = ({ children, collapsed, setCollapsed }) => {
  const sidebarData = useProvideSidebar();

  return (
    <sidebarContext.Provider
      value={{
        contentContext: sidebarData,
        collapsedContext: [collapsed, setCollapsed],
      }}
    >
      {children}
    </sidebarContext.Provider>
  );
};

export const useSidebar = () => {
  return useContext(sidebarContext);
};

export default SidebarProvider;
