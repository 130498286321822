import React from "react";
import classNames from "classnames";
import Button from "components/UI/Button";
import Icon from "components/UI/Icon";

import styles from "./InitiateScreen.module.scss";

export default ({ description, disabledText, examplePrompts }) => (
  <div className={styles.initiateScreen}>
    <div className={styles.header}>
      <p className={styles.small}>Introducing</p>
      <div className={styles.logo}>
        <Icon icon="Logo" />
      </div>
      <h3 className={styles.title}>WisGPT</h3>
    </div>
    <p className={styles.description}>{description}</p>
    <div className={styles.examplesWrapper}>
      <p className={styles.title}>Questions that might interest you</p>
      <div className={styles.examples}>
        {examplePrompts
          .filter((e) => e.length < 65)
          .slice(0, 3)
          .map((example) => (
            <p className={styles.example} key={"Example-" + example}>
              {example}
            </p>
          ))}
      </div>
    </div>
    {disabledText && (
      <p className={styles.disabledText}>
        <span className={styles.icon}>
          <Icon icon="Warning" />
        </span>
        {disabledText}
      </p>
    )}
  </div>
);
