import React from "react";
import Modal from "react-modal";
import { Helmet } from "react-helmet";

import { compose } from "recompose";
import { initRollbar } from "utils/confRollbar";
import withStore from "utils/withStore";
import { CLOSE_ACCOUNT_LIMIT_EXCEEDED } from "store/actionTypes";

class Utils extends React.Component {
  componentWillMount() {
    if (process.env.NODE_ENV !== "development") {
      initRollbar();
    }
    Modal.setAppElement("body");
  }

  closeModal = () =>
    this.props.dispatch({ type: CLOSE_ACCOUNT_LIMIT_EXCEEDED });

  render() {
    return (
      <>
        <Helmet>
          <title>Wisnio</title>
          <link rel="shortcut icon" href={`/favicon/default/favicon.ico?v=2`} />
          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href={`/favicon/default/apple-touch-icon.png?v=2`}
          />
          <link
            rel="icon"
            type="image/png"
            href={`/favicon/default/favicon-32x32.png?v=2`}
            sizes="32x32"
          />
          <link
            rel="icon"
            type="image/png"
            href={`/favicon/default/favicon-16x16.png?v=2`}
            sizes="16x16"
          />
        </Helmet>
        {this.props.children}
      </>
    );
  }
}

export default compose(withStore("account"))(Utils);

//
// <Modal
//   closeTimeoutMS={350}
//   isOpen={this.props.accountLimit.modalOpen}
//   onRequestClose={this.closeModal}
//   shouldCloseOnOverlayClick
// >
//   <AccountLimitExceededModal
//     closeModal={this.closeModal}
//     accountId={this.props.accountLimit.account}
//   />
// </Modal>
