import { compose, withHandlers } from "recompose";
import withStore from "utils/withStore";
import { TOGGLE_SURVEYS_REMINDER } from "store/actionTypes";
import { withRouter } from "react-router-dom";

export default compose(
  withRouter,
  withStore("userOnboarding"),
  withHandlers({
    proceed: ({ dispatch }) => () => dispatch({ type: TOGGLE_SURVEYS_REMINDER })
  })
);
