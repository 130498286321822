import { withProps, withHandlers, withState, compose } from "recompose";
import { withApollo, graphql } from "react-apollo";
import { withRouter } from "react-router-dom";
import { loader } from "graphql.macro";

const GENERATE_PROFILE_SUMMARY = loader("./generateGPTSummary2.graphql");
const DELETE_ORG_USER_GPT_SUMMARY = loader("./deleteGPTSummary1.graphql");

export default compose(
  withApollo,
  withRouter,
  withState("isGenerating", "toggleGenerating", false),
  withState("gptSummary", "setGptSummary", ({ user }) =>
    user.personalProfile.summary ? user.personalProfile.summary.text : ""
  ),
  graphql(GENERATE_PROFILE_SUMMARY, {
    name: "generateGPTSummary",
    options: ({ toggleGenerating, setGptSummary, match, user }) => {
      return {
        variables: {
          org_id: parseInt(match.params.orgId, 10),
          user_id: parseInt(user.id, 10),
        },
        update: (proxy, { data: { generateOrgUserProfileSummary } }) => {
          toggleGenerating(false);
          setGptSummary(generateOrgUserProfileSummary.text);
        },
      };
    },
  }),
  graphql(DELETE_ORG_USER_GPT_SUMMARY, {
    name: "deleteOrgUserProfileSummary",
    options: ({ setGptSummary, match, user }) => {
      return {
        variables: {
          org_id: parseInt(match.params.orgId, 10),
          user_id: parseInt(user.id, 10),
        },
        update: (proxy, { data }) => {
          console.log(data);
          setGptSummary("");
        },
      };
    },
  }),
  withHandlers({
    handleGenerateSummary:
      ({ generateGPTSummary, toggleGenerating }) =>
      async () => {
        toggleGenerating(true);
        await generateGPTSummary();
        if (window.heap) {
          window.heap.track("create-summary-org-user");
        }
      },
    handleDeleteSummary:
      ({ deleteOrgUserProfileSummary }) =>
      async () => {
        await deleteOrgUserProfileSummary();
        if (window.heap) {
          window.heap.track("delete-summary-org-user");
        }
      },
  }),
  withProps(
    ({
      user: {
        personalProfile: { personality, competencies, values },
      },
    }) => {
      return {
        dimensions: [...personality, ...competencies, ...values],
      };
    }
  )
);
