import React from "react";
import classNames from "classnames";
import ModalTitle from "components/UI/ModalTitle";
import Button from "components/UI/Button";
import Invite from "components/Invite";
import compose from "./compose";

import styles from "./Modal.module.scss";

export default compose(
  ({ updateModal, team, setOverlayClose, handleAddTeamMembers, isLoading }) => (
    <div className={styles.wrapper}>
      <Button bStyle="close" type="button" onClick={() => updateModal("")}>
        <i className="fal fa-times" />
      </Button>
      <div className={classNames(styles.modalWrapper)}>
        <div className={styles.modalHead}>
          <ModalTitle>Add new team members</ModalTitle>
          <p>
            Select from existing organisation members or invite new teammates to
            join your team.
          </p>
        </div>
        <div className={styles.newTeam}>
          <div className={styles.row}>
            <Invite
              validationCase="team"
              org_id={team.organization.id}
              searchEnabled={team.organization.adminRights}
              team_id={team.id}
              team={team}
              setOverlayClose={setOverlayClose}
              updateModal={updateModal}
              resource="team"
              handleSubmit={({ list }) => handleAddTeamMembers(list)}
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
    </div>
  )
);
