import React from 'react'
import {
  compose,
  withState,
  withHandlers,
  branch,
  renderComponent
} from 'recompose'
import { graphql, withApollo } from 'react-apollo'
import withStore from 'utils/withStore'
import Circles from 'components/Loaders/Circles'
import { withRouter } from 'react-router-dom'
import { loader } from 'graphql.macro'
import purgeTeamPositionTalentpoolCache from 'utils/purgeTeamPositionTalentpoolCache'
import handleChangeOrgContract from './handleChangeOrgContract'

import handleRemoveUser from './handleRemoveUser'

const ORGANIZATION_QUERY = loader(
  'client/Queries/OrganizationQuery15.graphql'
)
const TEAMS_AND_POSITIONS_IN_ORG = loader(
  './teamsAndPositionsInOrgByResouceType3.graphql'
)

const UPDATE_ORG_CONTRACT = loader('./UpdateOrgContract7.graphql')
const REMOVE_FROM_ORGANIZATION = loader(
  './RemoveFromOrganization7.graphql'
)
const ADD_TEAM_MEMBERS = loader('./addTeamMembers10.graphql')
const ADD_CANDIDATES = loader('./addCandidatesToPosition14.graphql')

export default compose(
  withRouter,
  withApollo,
  withStore('viewer'),
  withState('removeMemberModal', 'updateRemoveMemberModal', false),
  graphql(TEAMS_AND_POSITIONS_IN_ORG, {
    name: 'TEAMS_AND_POSITIONS',
    options: (props) => ({
      variables: {
        resource_type: props.member.type,
        resource_id: parseInt(props.member.id, 10),
        org_id: parseInt(props.organization.id, 10)
      }
    })
  }),
  graphql(ORGANIZATION_QUERY, {
    name: 'ORGANIZATION_QUERY',
    options: (props) => ({
      variables: {
        id: parseInt(props.organization.id, 10)
      }
    })
  }),
  graphql(UPDATE_ORG_CONTRACT, {
    name: 'updateOrgContractMutation'
  }),
  graphql(REMOVE_FROM_ORGANIZATION, {
    name: 'removeFromOrganizationMutation',
    options: (props) => ({
      update: (proxy, { data: { removeFromOrganization } }) => {
        purgeTeamPositionTalentpoolCache({
          cache: props.client.cache.data,
          keys: removeFromOrganization.keys,
          orgId: parseInt(props.organization.id, 10),
          proxy
        })
        props.updateMemberModal('')
        props.updateRemoveMemberModal(false)
      }
    })
  }),
  withState('loading', 'setLoading', false),
  graphql(ADD_TEAM_MEMBERS, {
    name: 'addTeamMembers',
    options: (props) => ({
      update: () => props.TEAMS_AND_POSITIONS.refetch()
    })
  }),
  graphql(ADD_CANDIDATES, {
    name: 'addCandidates',
    options: (props) => ({
      update: () => props.TEAMS_AND_POSITIONS.refetch()
    })
  }),
  withHandlers({
    addTeamMembersToExitstingTeam:
      ({ member, addTeamMembers, setLoading, organization }) =>
      (team) => {
        setLoading(true)
        addTeamMembers({
          variables: {
            id: team.id,
            org_id: organization.id,
            users: {
              obj_id: member.id,
              type: member.state === 'pending' ? 'invite' : 'user',
              email: member.email
            }
          }
        }).then((res) => {
          setLoading(false)
        })
      },
    addCandidatesToPosition:
      ({ member, addCandidates, setLoading, organization }) =>
      (position) => {
        setLoading(true)
        addCandidates({
          variables: {
            org_id: organization.id,
            position_id: position.id,
            team_id: position.parentTeam.id,
            users: {
              obj_id: member.id,
              type: member.state === 'pending' ? 'invite' : 'user',
              email: member.email
            }
          }
        }).then((res) => {
          setLoading(false)
        })
      }
  }),
  branch(
    (props) => {
      const condition =
        (props.ORGANIZATION_QUERY &&
          props.ORGANIZATION_QUERY.loading) ||
        (props.TEAMS_AND_POSITIONS &&
          props.TEAMS_AND_POSITIONS.loading)
      return condition
    },
    renderComponent(() => <Circles />)
  ),
  handleChangeOrgContract,
  handleRemoveUser
)
