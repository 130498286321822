export default ({ dim, members, dimGroup, candidate }) => {
  return [...members, candidate]
    .filter(Boolean)
    .map((member) => {
      const currentDim = member[dimGroup].find(
        (compe) => compe && dim && compe.name === dim.name
      );

      const { id, name, avatar_url, initials } = member;
      if (!currentDim || !currentDim.score) return null;
      return {
        id,
        name,
        avatar_url,
        initials,
        score: currentDim.score,
        user_dim_id: currentDim.dim_id,
      };
    })
    .filter(Boolean);
};
