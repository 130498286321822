import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import Cookies from "cookies-js";
import pathToRegexp from "path-to-regexp";
import QueryString from "query-string";

export default compose(withRouter)(({ children, location, history }) => {
  const isAuthenticated = Cookies.get("_s") && true;
  if (
    children.some(child =>
      pathToRegexp(child.props.path).exec(location.pathname)
    ) &&
    isAuthenticated
  ) {
    const queryString = QueryString.parse(location.search);
    const invite =
      location.pathname.includes("invite") &&
      location.pathname.split("/invite/")[1];

    history.replace(
      `/dashboard${
        invite
          ? `?invite=${invite}`
          : queryString && queryString.positionTemplate
          ? `?positionTemplate=${queryString.positionTemplate}`
          : ""
      }`
    );
    return null;
  }

  return children;
});
