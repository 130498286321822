import React from "react";
import Button from "components/UI/Button";
import Icon from "components/UI/Icon";
import { INVITE } from "store/actionTypes";
import styles from "./Notifications.module.scss";

export default ({ invites, dispatch }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.notificationsWrapper}>
        {invites.length > 0 &&
          invites.map((invite) => (
            <Button
              bStyle={["none"]}
              type="button"
              onClick={() => dispatch({ type: INVITE, payload: invite })}
              key={invite.secret}
            >
              <div className={styles.invite}>
                <div className={styles.icon}>
                  <Icon icon="Mail" />
                </div>
                <div className={styles.right}>
                  <p className={styles.text}>
                    You have been invited to the organisation
                    {` ${invite.organizations[0].name}. `}
                  </p>
                </div>
              </div>
            </Button>
          ))}
      </div>
    </div>
  );
};
