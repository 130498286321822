import { withHandlers } from "recompose";
import { FORM_ERROR } from "final-form";

export default withHandlers({
  onSubmit:
    ({
      history,
      createNewTeam,
      organization,
      selectedOrg,
      noReroute,
      updateModal,
      setTeam,
      data,
    }) =>
    async (e, values) => {
      e.preventDefault();
      e.persist();

      createNewTeam({
        variables: {
          name: values.name,
          org_id: organization ? organization.id : selectedOrg.value,
        },
      })
        .then((res) => {
          if (window.Intercom) {
            window.Intercom("trackEvent", "create-team", {
              id: res.data.createTeam.id,
            });
          }
          if (!noReroute) {
            history.replace(`/team/${res.data.createTeam.id}`);
          } else {
            updateModal({ open: false });
            setTeam(res.data.createTeam.id);
            data.refetch();
          }
        })
        .catch((err) => {
          return { [FORM_ERROR]: err };
        });
    },
});
