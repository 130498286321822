import React from "react";
import Wrapper from "components/UI/Wrapper";
import Button from "components/UI/Button";
import Field from "components/UI/Field";
import compose from "./compose";

import styles from "./CreateTemplate.module.scss";

export default compose(
  ({ close, handleCreateTemplate, nameValue, setNameValue }) => {
    return (
      <div className={styles.main}>
        <Wrapper left>
          <h5 className={styles.title}>Create a hiring scorecard</h5>
          <p className={styles.subtitle}>
            Make a list of required experiences, qualifications, and skills to
            achieve the key objectives in the role.
          </p>
          <div className={styles.content}>
            <form onSubmit={(e) => handleCreateTemplate(e)} autoComplete="off">
              <div className={styles.row}>
                <h5>Scorecard name</h5>
                <div className={styles.smallRow}>
                  <Field
                    white
                    type="text"
                    name="name"
                    required
                    placeholder="Scorecard name"
                    value={nameValue}
                    onChange={(e) => {
                      setNameValue(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className={styles.footerButtons}>
                <Button
                  type="submit"
                  bStyle={["primary", "small"]}
                  disabled={nameValue === ""}
                >
                  Create scorecard
                </Button>
                <Button
                  type="button"
                  bStyle={["secondary", "small"]}
                  onClick={close}
                >
                  Cancel
                </Button>
              </div>
            </form>
          </div>
        </Wrapper>
      </div>
    );
  }
);
