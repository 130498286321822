import React from "react";
import {
  compose,
  withState,
  branch,
  renderComponent,
  withHandlers,
} from "recompose";
import { withRouter } from "react-router-dom";
import { graphql } from "react-apollo";
import { loader } from "graphql.macro";
import FullPage from "components/Loaders/FullPage";
import scrollToTop from "utils/scrollToTop";
import withStore from "utils/withStore";
import makeWorkflow from "./makeWorkflow";

const TEAM_QUERY = loader("client/Queries/TeamQuery124.graphql");
const TEAM_PLAY_QUERY = loader("./TeamPlay9.graphql");
const FINISH_TEAM_PLAY = loader("./FinishTeamPlay.graphql");

export default compose(
  withRouter,
  withStore("viewer"),
  graphql(TEAM_PLAY_QUERY, {
    name: "TEAM_PLAY_QUERY",
    options: (props) => {
      return {
        variables: { play_id: parseInt(props.match.params.toolId, 10) },
      };
    },
  }),
  graphql(FINISH_TEAM_PLAY, {
    name: "finishTeamPlay",
  }),
  withHandlers({
    handleFinishTeamPlay:
      ({ history, match, finishTeamPlay }) =>
      () => {
        finishTeamPlay({
          variables: {
            id: parseInt(match.params.toolId, 10),
          },
        }).then(() => {
          history.push(`/team/${parseInt(match.params.teamId, 10)}/toolkit`);
        });
      },
  }),

  branch(
    ({ TEAM_QUERY, TEAM_PLAY_QUERY }) =>
      (TEAM_QUERY && TEAM_QUERY.loading) ||
      (TEAM_PLAY_QUERY && TEAM_PLAY_QUERY.loading),
    renderComponent(() => <FullPage />)
  ),
  withState("closed", "setClosed", window.innerWidth > 1080 ? false : true),
  withState(
    "currentTab",
    "setCurrentTab",
    ({ TEAM_PLAY_QUERY: { teamPlay: play }, viewer }) => {
      if (play.toolkit.name === "Team effectiveness") {
        if (play.players.length === 0) {
          return 0;
        } else if (
          play.players.find((p) => p.id === viewer.id) &&
          play.players
            .find((p) => p.id === viewer.id)
            .effectiveness.filter((e) => e.score === null).length > 4
        ) {
          return 0;
        } else if (
          play.players.length > 0 &&
          !play.effectiveness.some((e) => e.score == null)
        ) {
          return 1;
        }
      }

      return 0;
    }
  ),
  withState(
    "currentIndex",
    "setCurrentIndex",
    ({ TEAM_PLAY_QUERY: { teamPlay: play }, currentTab }) => {
      if (play.toolkit.name === "Team effectiveness") {
        if (currentTab === 0 && play.players.length === 0) {
          return 0;
        }
        if (
          currentTab === 0 &&
          (play.players.length > 0 ||
            play.effectiveness.some((e) => e.score == null))
        ) {
          return 2;
        }
        if (currentTab === 1) {
          return 0;
        }
      }

      return 0;
    }
  ),
  withState("list", "setList", ({ TEAM_PLAY_QUERY: { teamPlay } }) =>
    makeWorkflow({ teamPlay })
  ),
  scrollToTop
);
