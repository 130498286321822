import { withHandlers } from "recompose";

export default withHandlers({
  handleUpsertScore:
    ({ upsertScore }) =>
    async (scoreData) => {
      try {
        await upsertScore({
          variables: {
            data: scoreData,
          },
        });
      } catch (err) {
        throw new Error(err);
      }
    },
});
