import React, { useState } from "react";
import { palette, typography } from "style/general";
import ButtonsRow from "./ButtonsRow";
import Button from "components/UI/Button/index";
import Icon from "components/UI/Icon/index";

import { getContentHtml } from "utils/getContentHtml";
import styles from "./EvaluationGroup.module.scss";
import CommentWidget from "components/Comment";
import classNames from "classnames";

const EvaluationGroup = ({ scores, onScoreChange, evaluationRows }) => {
  const [openAccordions, setOpenAccordions] = useState([]);
  const [notesDrawerOpen, setNotesDrawerOpen] = useState(false);
  const [currentRowId, setCurrentRowId] = useState(null); // To track which row is currently being edited

  const toggleAccordion = (id) => {
    setOpenAccordions((prev) =>
      prev.includes(id)
        ? prev.filter((accordionId) => accordionId !== id)
        : [...prev, id]
    );
    setCurrentRowId(id); // Set the current row ID when toggling accordion
  };

  const openNotesDrawer = (rowId) => {
    setCurrentRowId(rowId); // Set the currentRowId to the clicked row's ID
    setNotesDrawerOpen(true); // Open the drawer
  };

  const groupedEvaluationRows = evaluationRows.reduce((acc, row) => {
    acc[row.group_title] = acc[row.group_title] || [];
    acc[row.group_title].push(row);
    return acc;
  }, {});

  const findScore = (id) =>
    scores.find((score) => score.assessment_row_id === id) || {};

  const handleNote = (assessment_row_id, note) => {
    const scoreData = findScore(assessment_row_id);
    onScoreChange({
      id: scoreData.id,
      assessment_row_id,
      note,
    });
  };

  return Object.entries(groupedEvaluationRows).map(([groupTitle, rows]) => (
    <div
      key={groupTitle}
      style={{
        width: "100%",
      }}
    >
      <div className={styles.evaluationGroup__header}>
        <div className={styles.evaluationGroup__headerCell}>
          <p
            style={{
              ...typography.label,
              ...typography.size14,
              color: palette.primary,
            }}
          >
            {groupTitle}
          </p>
        </div>
      </div>
      <div>
        {rows.map((row) => {
          const item = Object.assign({}, row, {
            ...row,
            ...scores.find((score) => score.assessment_row_id === row.id),
          });
          // console.log({ item });
          return (
            <React.Fragment key={row.id}>
              <div className={styles.evaluationGroup__row}>
                <div className={styles.evaluationGroup__title}>
                  <Button
                    bStyle={["none"]}
                    onClick={() => toggleAccordion(row.id)}
                  >
                    <span
                      style={{ display: "flex", gap: 8, alignItems: "center" }}
                    >
                      <p style={{ ...typography.label, ...typography.size14 }}>
                        {row.title}
                      </p>
                      <Icon
                        icon="ChevronPrimary"
                        style={{
                          transform: openAccordions.includes(row.id)
                            ? "rotate(0deg)"
                            : "rotate(180deg)",
                        }}
                      />
                    </span>
                  </Button>
                </div>
                <div>
                  <ButtonsRow
                    minValue={1}
                    maxValue={4}
                    handleScore={(value) => {
                      const scoreData = findScore(row.id);

                      onScoreChange({
                        id: scoreData.id,
                        assessment_row_id: row.id,
                        score: value === 0 ? null : value,
                      });
                    }}
                    activeValue={findScore(row.id)?.score}
                  />
                </div>

                <div
                  className={classNames(
                    styles.addComment,
                    !item.score && item.score !== 0 && styles.disabled
                  )}
                >
                  <CommentWidget
                    relative
                    showAll
                    comments={item.comments || []}
                    objType="assessment_row"
                    objId={row.group_id}
                    subjectType="reviewer"
                    subjectId={row.review_id}
                    itemType="assessment_row"
                    itemId={item.id}
                    modal
                    noIcon={window.innerWidth < 1000}
                  />
                  <span className={styles.tooltip}>
                    <p>Please leave an evaluation before commenting.</p>
                  </span>
                </div>
              </div>
              {openAccordions.includes(row.id) && (
                <div
                  style={{
                    borderBottom: "0.5px solid " + palette.grey4,
                    padding: "8px 16px",
                  }}
                >
                  <div
                    className={styles.content}
                    dangerouslySetInnerHTML={getContentHtml(row.description)}
                  ></div>
                </div>
              )}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  ));
};

export default EvaluationGroup;
