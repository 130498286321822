import { compose, withState } from 'recompose'
import { withRouter } from 'react-router-dom'
import { graphql } from 'react-apollo'
import handleLoading from 'utils/handleLoading'
import { loader } from 'graphql.macro'
import withStore from 'utils/withStore'
import handleSubmit from './handleSubmit'

const LOGIN = loader('./LoginMutation12.graphql')

export default compose(
  withRouter,
  withState('openModal', 'updateModal', ''),
  withStore('onboarding'),
  withState('forgotModal', 'setForgotModal', false),
  withState('showPassword', 'setShowPassword', false),
  graphql(LOGIN, { name: 'login' }),
  handleSubmit,
  handleLoading
)
