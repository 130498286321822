import React from "react";

import styles from "./LoaderBar.module.scss";

export default ({ isGenerating }) => {
  return (
    <div className={styles.loaderbar}>
      <div className={styles.progress}></div>
    </div>
  );
};
