export const palette = {
  primary: "#0ea3c3",
  black: "#000",
  white: "#fff",
  darkgrey: "#454754",
  grey1: "#91939A",
  grey3: "#c5cbd3",
  grey4: "#E4E6E8",
  grey5: "#EDEDED",
  blue1: "#ECF9FF",
  green: "#30be9f",
  teal3: "#F0FFF6",
  orange: "#FFB575",
  lightorange3: "#FCF6EF",
  yellow: "#ffb575",
  red: "#f27a5a",
  purple: "#640fda",
  blue: "#ecf9ff",
  primaryblue: "#80D2E5",

  lightblue: "#d8edfa",
  lightgreen: "#f0fff6",
  lightred: "#fff7f4",
  lightteal: "#d0f1e4",
  haze: "#fcf6ef",
  lightorange: "#fde3c8",
  lightpink: "rgba($pink, 0.4)",
  lightyellow: "#fde3c8",
  lightpurple: "#f8f4ff",
  secondarypurple: "#5a17d1",
};

export const typography = {
  body: {
    color: palette.black,
    fontFamily: "proxima-nova",
    fontSize: 16,
    fontStyle: "normal",
    lineHeight: 1.7 /* 171.429% */,
  },
  label: {
    color: palette.darkgrey,
    fontFamily: "proxima-nova",
    fontSize: 16,
    fontWeight: 600,
    lineHeight: 1.5,
  },
  primary: {
    color: palette.primary,
  },
  grey1: {
    color: palette.grey1,
  },
  size12: {
    fontSize: 12,
  },
  size14: {
    fontSize: 14,
  },
  size18: { fontSize: 18 },
  bold: {
    fontWeight: 700,
  },
  h4: {
    fontFamily: "proxima-nova",
    fontSize: 22,
    fontWeight: 600,
    lineHeight: 1.27,
  },
};

//TODO: Rename if necessary
export const JSstyles = {
  palette,
  typography,
};
