import React, { useState } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import Button from "components/UI/Button";
import Icon from "components/UI/Icon";
import resolveTemplates from "../resolveTemplates";
import styles from "./Tour.module.scss";

export default ({
  title,
  text,
  button,
  buttonUrl,
  action,
  actions,
  color,
  image,
  skippable,
  handleNextStep,
  handlePrevStep,
  handleSkipStep,
  viewer,
  surveys,
}) => {
  const createMarkup = (text) => {
    return { __html: resolveTemplates({ text, user: viewer, surveys }) };
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.left}>
        <h1 className={styles.title}>
          {resolveTemplates({ text: title, user: viewer, surveys })}
        </h1>
        <div
          className={styles.subtitle}
          dangerouslySetInnerHTML={createMarkup(text)}
        />
        <div className={styles.buttons}>
          {buttonUrl ? (
            <Link to={buttonUrl}>
              <Button
                onClick={() => action && actions[action]()}
                bStyle={["primary", "small"]}
              >
                {button}
              </Button>
            </Link>
          ) : (
            <Button
              bStyle={["primary", "small"]}
              onClick={() => {
                if (action) actions[action]();
                handleNextStep();
              }}
            >
              {button}
            </Button>
          )}
          {skippable && (
            <Button
              bStyle={["newsimple", "nopadding"]}
              onClick={handleSkipStep}
            >
              <div className={styles.noBackgroundGrey}>Skip tour</div>
            </Button>
          )}
        </div>
      </div>
      <div className={classNames(styles.right, styles[color])}>
        <div className={styles.img}>
          <img src={image} />
        </div>
      </div>
    </div>
  );
};
