import React from "react";
import { loader } from "graphql.macro";

const LOG_MUTATION = loader("client/Mutations/LogReadMutation.graphql");

export default (resource) => (ComposedComponent) => (props) => {
  const variables = {};

  Object.keys(resource).forEach((variable) =>
    variable.includes("_id")
      ? (variables[variable] = parseInt(
          resource[variable].split(".").reduce((obj, key) => obj[key], props),
          10
        ))
      : (variables[variable] = resource[variable])
  );

  // console.log({ resource, props, variables });

  props.client.mutate({
    mutation: LOG_MUTATION,
    variables,
  });

  const eventName = ["read", resource.obj_type, resource.subject_type]
    .filter(Boolean)
    .join("-");

  window.Intercom("trackEvent", eventName, variables);

  return <ComposedComponent {...props} />;
};
