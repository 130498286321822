import React from "react";
import UserRowStatus from "components/v2/UI/UserRowStatus";

import compose from "./compose";

export default compose(
  ({
    user,
    review,
    status,
    handleRemoveReview,
    handleSendReminder,
    btnState,
  }) => {
    const setReviewActions = (review) => {
      return [
        {
          name: btnState.hoverText,
          faIcon: btnState.faIcon,
          action: () => handleSendReminder({ review }),
          disabled: btnState.disabled,
        },
        {
          name: "Remove reviewer",
          icon: "Delete",
          action: () => handleRemoveReview({ review }),
        },
      ];
    };

    return (
      <UserRowStatus
        user={user}
        actions={setReviewActions(review)}
        status={status}
      />
    );
  }
);
