import React from 'react'
import PersonalityPlaceholder from 'components/Placeholders/PersonalityPlaceholder'
import PersonalityChart from '../Chart'
import Dimensions from '../Dimensions'

import styles from '../Personality.module.scss'

export default ({ user }) => {
  if (
    user.personalProfile.personality.some((dim) => dim.score === null)
  ) {
    return <PersonalityPlaceholder resource="personal" />
  }
  return (
    <>
      <PersonalityChart
        personality={user.personalProfile.personality}
      />
      <div className={styles.personalityTraits}>
        <Dimensions
          personality={user.personalProfile.personality}
          user={user}
        />
      </div>
    </>
  )
  // {atLeastOneSurveyComplete ? (
  //   <PersonalityChart />
  // ) : (
  //   <PersonalityPlaceholder resource='personal' />
  // )}
  // {atLeastOneSurveyComplete && (
  //   <div className={styles.personalityTraits}>
  //     <Dimensions />
  //   </div>
  // )}
}
