import React from "react";
import Wrapper from "components/UI/Wrapper";
import Title from "components/UI/Title";
import Subtitle from "components/UI/Subtitle";
import styles from "./Personality.module.scss";
import PersonalityChart from "./Chart";
import Dimensions from "./Dimensions";

export default ({ team, teamMember }) => (
  <div className={styles.main}>
    <Wrapper>
      <Title text="Personality map" icon="fas fa-dna" />
      <Subtitle
        text={`${teamMember.first_name}’s unique traits and behavioural characteristics.`}
      />
      <Subtitle
        verySmall
        text={`Understand the unique behavioural characteristics ${teamMember.first_name} brings to the team and think about how to use this as an advantage.`}
      />
      <PersonalityChart member={teamMember} team={team} />
      <Dimensions member={teamMember} team={team} />
    </Wrapper>
  </div>
);
