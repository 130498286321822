import React from "react";
import { compose, withState } from "recompose";
import { graphql } from "react-apollo";
import handleCreateAssessmentGroup from "./handleCreateAssessmentGroup";
import { loader } from "graphql.macro";
import FullPage from "components/Loaders/FullPage";
import handleLoading from "utils/loader";

const CREATE_GROUP = loader("./createAssessmentGroup3.graphql");

export default compose(
  withState("groupData", "setGroupData", {
    title: "",
    subtitle: "",
    description: "",
  }),
  graphql(CREATE_GROUP, {
    name: "createAssessmentGroup",
  }),
  handleCreateAssessmentGroup,
  handleLoading(() => <FullPage />)
);
