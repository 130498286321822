import {
  ROUTE_CHANGE,
  SET_ROUTE_PARAMS,
  TOGGLE_STATE_CHANGE_IN_PROGRESS,
} from "store/actionTypes";

const initialState = {
  current: null,
  isDirty: false,
  stateChangeInProgress: false,
};

const paramsMap = {
  accountId: "account_id",
  orgId: "org_id",
  teamId: "team_id",
  positionId: "position_id",
  candidateId: "candidate_id",
  memberId: "member_id",
  secret: "public_contract",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ROUTE_CHANGE:
      return Object.assign({}, state, action.payload);
    case SET_ROUTE_PARAMS:
      if (state.isDirty) {
        return Object.assign(
          {},
          Object.keys(action.payload.params).reduce(
            (acc, key) => {
              if (paramsMap[key] === "public_contract") {
                acc[paramsMap[key]] = action.payload.current;
              } else {
                acc[paramsMap[key]] = parseInt(action.payload.params[key], 10);
              }
              return acc;
            },
            {
              isDirty: false,
              current: state.current,
              stateChangeInProgress: true,
            }
          )
        );
      }
      return state;
    case TOGGLE_STATE_CHANGE_IN_PROGRESS:
      return Object.assign({}, state, {
        stateChangeInProgress: !state.stateChangeInProgress,
      });
    default:
      return state;
  }
};
