import React from "react";
import TourStep from "components/UI/TourStep";

const steps = [
  {
    selector: ".teamMembers",
    content: (
      <TourStep
        title="Welcome to the Team Profile."
        text="Here, you can see your team members and invite more people to join."
      />
    ),
  },
  {
    selector: ".teamMemberSingle",
    content: (
      <TourStep text="Click on a person to see their profile compared to the rest of the team." />
    ),
    stepInteraction: true,
  },
  {
    selector: ".competencyGraph",
    content: (
      <TourStep text="The competency map helps you discover what your team members feel confident in and where there might be gaps and development opportunities." />
    ),
  },
  {
    selector: ".competencyGraphSector",
    content: (
      <TourStep text="Click on competency to see a detailed definition and drill down across the team." />
    ),
    stepInteraction: true,
  },
  {
    selector: ".personalityRow",
    content: (
      <TourStep text="Personality insights help you understand your team members' behavioural preferences and working styles." />
    ),
  },
  {
    selector: ".personalityRowMoreInfo",
    content: (
      <TourStep text="Click here to see specific recommendations for your team based on a particular personality trait." />
    ),
    stepInteraction: true,
  },
  {
    selector: ".valuesGraphSector",
    content: (
      <TourStep text="Use the values section to discover what motivates your team and shape a culture where everyone can perform at their best." />
    ),
    stepInteraction: true,
  },
  {
    selector: ".topMotivatorsTitle",
    content: (
      <TourStep text="Top motivators help you discover what your team cares about the most." />
    ),
  },
  {
    selector: ".mightStruggleDim",
    content: (
      <TourStep text="Values on which team members disagree indicate topics you should discuss and make the expected norms explicit to avoid conflicts and misunderstanding." />
    ),
  },
];

export default steps;
