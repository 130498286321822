import React from 'react'
import classNames from 'classnames'
import styles from './index.module.scss'

function lineDistance(point1, point2) {
  let xs = 0
  let ys = 0

  xs = point2.x - point1.x
  xs *= xs

  ys = point2.y - point1.y
  ys *= ys

  return Math.sqrt(xs + ys)
}

const apList = (
  fs,
  xs //
) => [].concat.apply([], fs.map(f => [].concat.apply([], xs.map(x => [f(x)]))))

// fst :: (a, b) -> a
const fst = tpl => tpl[0]

// map :: (a -> b) -> [a] -> [b]
const map = (f, xs) => xs.map(f)

// snd :: (a, b) -> b
const snd = tpl => tpl[1]

// show :: a -> String

const intersection = (ab, pq) => {
  if (ab[0][0] === ab[1][0] && ab[0][1] === ab[1][1]) {
    return ab[0]
  }

  const delta = f => x => f(fst(x)) - f(snd(x))

  const [abDX, pqDX, abDY, pqDY] = apList([delta(fst), delta(snd)], [ab, pq])
  // console.log('next in intersection', [abDX, pqDX, abDY, pqDY])

  const determinant = abDX * pqDY - abDY * pqDX

  const [abD, pqD] = map(([a, b]) => fst(a) * snd(b) - fst(b) * snd(a), [
    ab,
    pq
  ])
  return apList(
    [([pq, ab]) => (abD * pq - ab * pqD) / determinant],
    [[pqDX, abDX], [pqDY, abDY]]
  )
}

export default ({
  valuesChart: { scoresPathData: scores },
  wedgeAngle,
  midX,
  midY,
  isMemberHover,
  isMember,
  resource,
  sectorRadius
}) => {
  const line = []
  const maxBezDistance = sectorRadius
  scores.forEach((score, i) => {
    const startAngle = wedgeAngle * i
    const scoreAngle = startAngle + wedgeAngle / 2

    const prev = i - 1 === -1 ? scores.length - 1 : i - 1
    const next = i + 1 === scores.length ? 0 : i + 1

    const scoreMaxX = midX + 100 * Math.cos((Math.PI * scoreAngle) / 180)
    const scoreMaxY = midY + 100 * Math.sin((Math.PI * scoreAngle) / 180)

    const prevX = scores[prev].x
    const prevY = scores[prev].y

    const nextX = scores[next].x
    const nextY = scores[next].y

    // find the intersection of lines between prev score, next score and middle of graph, max possible score in sector
    let [intersectionX, intersectionY] = intersection(
      [[prevX, prevY], [nextX, nextY]],
      [[midX, midY], [scoreMaxX, scoreMaxY]]
    )
    if (intersectionX === 50) intersectionX = 51
    if (intersectionY === 50) intersectionY = 51
    // find angle of prjection
    const projAngle =
      (Math.atan2(score.y - intersectionY, score.x - intersectionX) * 180) /
      Math.PI

    const projDistance = lineDistance(
      { x: score.x, y: score.y },
      { x: intersectionX, y: intersectionY }
    )

    const prevProjX =
      prevX + projDistance * Math.cos((Math.PI * projAngle) / 180)
    const prevProjY =
      prevY + projDistance * Math.sin((Math.PI * projAngle) / 180)
    const nextProjX =
      nextX + projDistance * Math.cos((Math.PI * projAngle) / 180)
    const nextProjY =
      nextY + projDistance * Math.sin((Math.PI * projAngle) / 180)

    const bezMinInterX =
      midX + 100 * Math.cos((Math.PI * (scoreAngle - wedgeAngle / 5)) / 180)
    const bezMinInterY =
      midY + 100 * Math.sin((Math.PI * (scoreAngle - wedgeAngle / 5)) / 180)

    const bezMaxInterX =
      midX + 100 * Math.cos((Math.PI * (scoreAngle + wedgeAngle / 5)) / 180)
    const bezMaxInterY =
      midY + 100 * Math.sin((Math.PI * (scoreAngle + wedgeAngle / 5)) / 180)

    const maxBezMinInterX =
      midX +
      maxBezDistance *
        Math.cos((Math.PI * (scoreAngle - wedgeAngle / 1.5)) / 180)
    const maxBezMinInterY =
      midY +
      maxBezDistance *
        Math.sin((Math.PI * (scoreAngle - wedgeAngle / 1.5)) / 180)

    const maxBezMaxInterX =
      midX +
      maxBezDistance *
        Math.cos((Math.PI * (scoreAngle + wedgeAngle / 1.5)) / 180)
    const maxBezMaxInterY =
      midY +
      maxBezDistance *
        Math.sin((Math.PI * (scoreAngle + wedgeAngle / 1.5)) / 180)

    const [bezMinX, bezMinY] = intersection(
      [[prevProjX, prevProjY], [nextProjX, nextProjY]],
      [[midX, midY], [bezMinInterX, bezMinInterY]]
    )

    const [bezMaxX, bezMaxY] = intersection(
      [[prevProjX, prevProjY], [nextProjX, nextProjY]],
      [[midX, midY], [bezMaxInterX, bezMaxInterY]]
    )

    const bezMinDistance = lineDistance(
      { x: bezMinX, y: bezMinY },
      { x: midX, y: midY }
    )

    const bezMaxDistance = lineDistance(
      { x: bezMaxX, y: bezMaxY },
      { x: midX, y: midY }
    )

    const maxBezCondition =
      bezMinDistance > maxBezDistance || bezMaxDistance > maxBezDistance

    line.push({
      bezMinX: maxBezCondition ? maxBezMinInterX : bezMinX,
      bezMinY: maxBezCondition ? maxBezMinInterY : bezMinY,
      bezMaxX: maxBezCondition ? maxBezMaxInterX : bezMaxX,
      bezMaxY: maxBezCondition ? maxBezMaxInterY : bezMaxY,
      scoreX: score.x,
      scoreY: score.y
    })
  })

  const curve = line.map((s, i) => {
    const next = i + 1 === line.length ? 0 : i + 1
    if (i === 0) {
      return `M${s.scoreX},${s.scoreY} C${s.bezMaxX},${s.bezMaxY} ${line[next].bezMinX},${line[next].bezMinY} ${line[next].scoreX},${line[next].scoreY}`
    }

    return `S${line[next].bezMinX},${line[next].bezMinY} ${line[next].scoreX},${line[next].scoreY}`
  })

  return (
    <>
      <path
        className={classNames(
          styles.blob,
          isMember && styles.isMember,
          isMemberHover && styles.memberHover,
          resource === 'member' && styles.memberPage
        )}
        d={`${curve.join(' ')}`}
      />
    </>
  )
}
