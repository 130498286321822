import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import SidebarWrapper from "components/UI/SidebarWrapper";
import NoRights from "components/EmptyStates/NoRights";
import Icon from "components/UI/Icon";
import Button from "components/UI/Button";
import Field from "components/UI/Field";
import InvitePeople from "components/Modals/InvitePeopleToOrg";
import RenderMemberModal from "components/RenderMemberModal";
import compose from "./compose";
import UsersList from "./UsersList";
import styles from "./ManagePeople.module.scss";
import { useSidebar } from "utils/sidebarProvider";

export default compose(
  ({
    ORGANIZATION_QUERY: { organization },
    TALENT_POOL_QUERY,
    inviteModal,
    updateInviteModal,
    overlayClose,
    setOverlayClose,
    memberModal,
    updateMemberModal,
    setRole,
    role,
    limit,
    setLimit,
    pageNr,
    setPageNr,
    order,
    setOrder,
    selectedOrg,
  }) => {
    const [searchValue, setSearchValue] = useState("");
    const { contentContext } = useSidebar();
    const [content, setContent] = contentContext;

    const sidebarContent = [
      {
        items: [
          selectedOrg.adminRights && {
            title: "Invite people",
            icon: "Plus",
            style: "primary",
            action: () => updateInviteModal(true),
          },
          selectedOrg.adminRights && {
            title: "Everyone",
            amount: TALENT_POOL_QUERY.organization.usersInTalentPool,
            color: "primary",
            initial: "E",
            action: () => {
              setRole(null);
              setPageNr(0);
            },
            style: role === null && "active",
          },
          selectedOrg.adminRights && {
            title: "Admins",
            amount: TALENT_POOL_QUERY.organization.adminsInTalentPool,
            color: "extradarkgrey",
            initial: "A",
            action: () => {
              setRole("admin");
              setPageNr(0);
            },
            style: role === "admin" && "active",
          },
          selectedOrg.adminRights && {
            title: "Members",
            amount: TALENT_POOL_QUERY.organization.membersInTalentPool,
            color: "green",
            initial: "M",
            action: () => {
              setRole("member");
              setPageNr(0);
            },
            style: role === "member" && "active",
          },
          selectedOrg.adminRights && {
            title: "Candidates",
            amount: TALENT_POOL_QUERY.organization.candidatesInTalentPool,
            color: "yellow",
            initial: "C",
            action: () => {
              setRole("candidate");
              setPageNr(0);
            },
            style: role === "candidate" && "active",
          },
        ],
      },
    ];

    useEffect(() => {
      setContent(sidebarContent);
    }, [role]);

    const emptyStateContent = {
      notice: {
        title: "You currently don’t have access to this feature. ",
        subtitle:
          "To fully experience Wisnio, please ask your workspace admin for access or consider setting up your own workspace.",
      },
      thumbnail:
        "https://storage.googleapis.com/iaeqpsxmmk/onboarding_images/discovering.png",
      video: "https://storage.googleapis.com/iaeqpsxmmk/just_discovering.mp4",
      subtitle: "This is what you would get if you created your own workspace:",
      list: [
        {
          title: "Hiring",
          description:
            "Scientific assessments powered by AI capture critical talent data on the team, the job, and shortlisted candidates to give you the insights you need.",
        },
        {
          title: "Team Development",
          description:
            "Get insights into team strengths, development areas and culture. AI-powered tools help you to analyse the data and make it immediately actionable.",
          // button: {
          //   title: "Download PDF",
          //   action: () => console.log("download pdf"),
          // },
        },
      ],
    };

    return (
      <SidebarWrapper noHeader>
        {selectedOrg.adminRights && (
          <>
            <h2 className={styles.title}>Organisation members</h2>
            <p className={styles.name}>{organization.name}</p>
          </>
        )}
        {!selectedOrg.adminRights && (
          <div className={styles.noRights}>
            <NoRights
              pageTitle={"People"}
              emptyStateContent={emptyStateContent}
              admins={organization.admins.active}
              isCandidate={selectedOrg.contract.role === "candidate"}
            />
          </div>
        )}
        {selectedOrg.adminRights && (
          <div className={styles.content}>
            <div className={styles.main}>
              {organization.usersInTalentPool > 0 && (
                <>
                  <div className={styles.searchrow}>
                    <div className={styles.search}>
                      <Field
                        white
                        small
                        noBorder
                        deleteIcon
                        icon="fas fa-search"
                        type="text"
                        name="search"
                        value={searchValue}
                        setValue={setSearchValue}
                        placeholder="Search members"
                        autoComplete="off"
                        onChange={(e) => setSearchValue(e.target.value)}
                      />
                    </div>
                    {selectedOrg.adminRights && (
                      <div className={styles.invite}>
                        <Button
                          bStyle={["primary", "small"]}
                          type="button"
                          onClick={() => updateInviteModal(true)}
                        >
                          <Icon icon="AddUser" marginRight />
                          Invite people
                        </Button>
                        <Modal
                          isOpen={inviteModal && true}
                          onRequestClose={() => updateInviteModal(false)}
                          shouldCloseOnOverlayClick={overlayClose}
                          shouldCloseOnEsc={overlayClose}
                          className={styles.modal}
                        >
                          <InvitePeople
                            organization={organization}
                            updateModal={updateInviteModal}
                            setOverlayClose={setOverlayClose}
                          />
                        </Modal>
                      </div>
                    )}
                  </div>
                </>
              )}
              {TALENT_POOL_QUERY.organization.talentPool && (
                <UsersList
                  searchValue={searchValue}
                  setSearchValue={setSearchValue}
                  memberModal={memberModal}
                  updateMemberModal={updateMemberModal}
                  TALENT_POOL_QUERY={TALENT_POOL_QUERY}
                  role={role}
                  limit={limit}
                  setLimit={setLimit}
                  pageNr={pageNr}
                  setPageNr={setPageNr}
                  order={order}
                  setOrder={setOrder}
                />
              )}
            </div>
          </div>
        )}
        {!!memberModal && (
          <Modal
            isOpen={memberModal !== ""}
            onRequestClose={() => updateMemberModal("")}
            shouldCloseOnOverlayClick
            className={styles.modal}
          >
            <RenderMemberModal
              updateMemberModal={updateMemberModal}
              member={memberModal}
              organization={TALENT_POOL_QUERY.organization}
            />
          </Modal>
        )}
      </SidebarWrapper>
    );
  }
);
