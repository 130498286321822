import React from "react";
import { ReactComponent as IconExpert } from "images/icons/competencies/colored-must.svg";
import { ReactComponent as IconStrong } from "images/icons/competencies/colored-important.svg";
import { ReactComponent as IconGood } from "images/icons/competencies/colored-good.svg";
import scoreGroups from "./compeScoreGroups";

const groupIcons = {
  expert: <IconExpert />,
  strong: <IconStrong />,
  good: <IconGood />,
  lacking: <i className="fas fa-chart-network" />,
};

const posGroupIcons = {
  must: (
    <div data-star="true">
      <i className="fas fa-star" />
      <i className="fas fa-star" />
      <i className="fas fa-star" />
    </div>
  ),
  important: (
    <div data-star="true">
      <i className="fas fa-star" />
      <i className="fas fa-star" />
    </div>
  ),
  good: (
    <div data-star="true">
      <i className="fas fa-star" />
    </div>
  ),
  lacking: <i className="fas fa-chart-network" />,
};

const posGroupTitles = {
  must: "Must have",
  important: "Important to have",
  good: "Good to have",
  lacking: "Least prioritized in the team",
};

const posCompeGroupsByIndex = {
  0: "must",
  1: "must",
  2: "must",
  3: "important",
  4: "important",
  5: "important",
  6: "good",
  7: "good",
  8: "good",
};

export default (team, candidate) =>
  candidate.competencies &&
  candidate.competencies.some((tc) => tc.score !== null) &&
  candidate.competencies
    .sort(
      (a, b) =>
        candidate.positionCompetencies.findIndex((pc) => pc.title === a.name) -
        candidate.positionCompetencies.findIndex((pc) => pc.title === b.name)
    )
    .filter((comp) => comp.dim_parent !== null)
    .map((c, i) => {
      const groupTitles = {
        expert: `${candidate.first_name} is great at`,
        strong: `${candidate.first_name} is strong at`,
        good: `${candidate.first_name} is good at`,
        lacking: `${candidate.first_name}'s learning opportunities`,
      };

      c.members = team.members
        .filter((m) => m.competencies.some((comps) => comps.score !== null))
        .map((m) => {
          const memberCompeScore = m.competencies.find(
            (mc) => mc.name === c.name
          ).score;
          return {
            id: m.id,
            score: memberCompeScore,
            group: scoreGroups[memberCompeScore],
          };
        });

      c.groups = [...new Set(Object.values(scoreGroups))].map((group) =>
        c.members.filter((m) => m.group === group)
      );
      c.group = scoreGroups[c.score];
      c.icon = groupIcons[c.group];
      c.title = groupTitles[c.group];
      c.posGroup = posCompeGroupsByIndex[i];
      c.posGroupTitle = posGroupTitles[c.posGroup];
      c.posGroupIcon = posGroupIcons[c.posGroup];
      c.__typename = "CompetencyDimension";
      return c;
    });
