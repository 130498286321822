import React from 'react'
import { Link } from 'react-router-dom'
import Wrapper from 'components/UI/Wrapper'
import Button from 'components/UI/Button'
import MapCompetencies from './MapCompetencies'
import compose from './compose'

import styles from './AboutPosition.module.scss'

export default compose(({ position, toggleEditCompes, closeModal }) => (
  <div className={styles.main} id='competencies'>
    <Wrapper left>
      <div className={styles.row}>
        <div className={styles.col}>
          <h2>
            <span className={styles.checkWrapper}>
              <i className='far fa-check' />
            </span>
            Competencies
          </h2>
          <p className={styles.subtitle}>
            Based on the key objectives of the role, map the competencies that
            will help to distinguish superior performers from average
            performers. Also, pay attention to the potential competency gaps in
            the team.
          </p>
        </div>
        <div className={styles.col}>
          {position.competenciesHaveBeenConfed && (
            <Link to={`/position/${position.id}/mapcompetencies`}>
              <Button bStyle={['dark', 'small']} onClick={toggleEditCompes}>
                <i className='fas fa-pencil-alt' />
                Edit competencies
              </Button>
            </Link>
          )}
        </div>
      </div>
      <MapCompetencies
        position={position}
        toggleEditCompes={toggleEditCompes}
      />
      <div className={styles.buttonGroup}>
        <Button bStyle={['green']} type='button' onClick={closeModal}>
          Save
        </Button>
      </div>
    </Wrapper>
  </div>
))
