import SidebarHeader from "components/UI/SidebarHeader/index";
import SidebarWrapper from "components/UI/SidebarWrapper/index";
import React from "react";
import compose from "./compose";

import { reviewSteps } from "./utils/steps";

import styles from "./styles.module.scss";

export default compose((props) => {
  const { currentStepIndex, data } = props;

  const name = props.data.assessmentReview.session.employee_under_review.name;

  const assessmentTitle = (
    <div className={styles.header}>
      <div className={styles.header__title}>
        <p>Assessment</p>
      </div>
      <div className={styles.header__name}>
        <p>{name}</p>
      </div>
    </div>
  );
  const StepComponent = reviewSteps[currentStepIndex].component;

  return (
    <SidebarWrapper>
      <SidebarHeader title={assessmentTitle}></SidebarHeader>
      <StepComponent {...props} />
    </SidebarWrapper>
  );
});
