import React, { useState } from "react";
import SidebarWrapper from "components/UI/SidebarWrapper";
import Button from "components/UI/Button";
import PositionsList from "./PositionsList";
import Popup from "reactjs-popup";
import { Form } from "react-final-form";
import Icon from "components/UI/Icon";
import DropDown from "components/UI/DropDown";
import FormInfo from "components/PositionTemplates/UseTemplatePopup";
import compose from "./compose";
import styles from "./Positions.module.scss";

export default compose(
  ({
    selectedOrg,
    history,
    selectedTeam,
    setSelectedTeam,
    handleCreatePosition,
    positionName,
    setPositionName,
  }) => {
    const [modal, updateModal] = useState("");
    const [formStep, setFormStep] = useState(0);
    const [orgTeamsState, setOrgTeamsState] = useState(null);

    const isRR =
      selectedOrg &&
      selectedOrg.id === parseInt(process.env.REACT_APP_RUSSELL_DEMO_ORG, 10);

    return (
      <SidebarWrapper noHeader>
        <div className={styles.showTeams}>
          <div className={styles.header}>
            <h2 className={styles.title}>{`Open ${
              isRR ? "projects" : "positions"
            }`}</h2>
            <p className={styles.subtitle}>
              {isRR
                ? "Explore and manage open assignments, or create new hiring and assessment projects."
                : "Here you will see the ongoing hiring processes that you have started or have been added to."}
            </p>
            {selectedOrg.adminRights && orgTeamsState && (
              <div className="dropdownWrapper" id="dropdownMenu">
                <Popup
                  keepTooltipInside="body"
                  trigger={
                    <Button bStyle={["primary", "verySmall"]}>
                      <Icon icon="Plus" marginRight />
                      {isRR ? "New project" : "Create position"}
                    </Button>
                  }
                  position="right bottom"
                >
                  {(close) => (
                    <DropDown>
                      <Form
                        autoComplete="new-password"
                        onSubmit={handleCreatePosition}
                        render={({ handleSubmit, pristine, values }) => {
                          return (
                            <div className={styles.popup}>
                              <form onSubmit={handleSubmit} autoComplete="off">
                                <FormInfo
                                  data={orgTeamsState}
                                  selectedTeam={selectedTeam}
                                  setSelectedTeam={setSelectedTeam}
                                  selectedTemplate={null}
                                  formStep={formStep}
                                  setFormStep={setFormStep}
                                  positionName={positionName}
                                  setPositionName={setPositionName}
                                />
                                {formStep === 1 ? (
                                  <div className={styles.buttonGroup}>
                                    <Button
                                      bStyle={["primary", "small"]}
                                      type="button"
                                      onClick={() =>
                                        history.push(
                                          selectedOrg.id +
                                            "/scorecards?name=" +
                                            values.name +
                                            "&teamId=" +
                                            selectedTeam
                                        )
                                      }
                                    >
                                      Choose scorecard
                                    </Button>
                                    <Button
                                      bStyle={["secondary", "small"]}
                                      type="submit"
                                    >
                                      Create blank position
                                    </Button>
                                  </div>
                                ) : (
                                  <div className={styles.buttonGroup}>
                                    <Button
                                      bStyle={["primary", "small"]}
                                      type="button"
                                      onClick={() => setFormStep(1)}
                                      disabled={
                                        pristine ||
                                        !values.name ||
                                        !selectedTeam ||
                                        selectedTeam === -1
                                      }
                                    >
                                      Continue
                                    </Button>
                                    <Button
                                      bStyle={["secondary", "small"]}
                                      type="button"
                                      onClick={close}
                                    >
                                      Cancel
                                    </Button>
                                  </div>
                                )}
                              </form>
                            </div>
                          );
                        }}
                      />
                    </DropDown>
                  )}
                </Popup>
              </div>
            )}
          </div>

          <PositionsList
            selectedOrg={selectedOrg}
            history={history}
            updateModal={updateModal}
            selectedTeam={selectedTeam}
            setSelectedTeam={setSelectedTeam}
            handleCreatePosition={handleCreatePosition}
            positionName={positionName}
            setPositionName={setPositionName}
            setOrgTeamsState={setOrgTeamsState}
          />
        </div>
      </SidebarWrapper>
    );
  }
);
