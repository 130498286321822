import { compose, withHandlers, withState } from "recompose";
import { withApollo, graphql } from "react-apollo";
import { withRouter } from "react-router-dom";
import { loader } from "graphql.macro";

const ATTACHMENT_READ_SIGNED_URL = loader("./attachmentReadSignedUrl.graphql");

const DELETE_CANDIDATE_ATTACHMENT = loader(
  "./deleteCandidateAttachment.graphql"
);

const CANDIDATE_ATTACHMENTS = loader(
  "client/Queries/candidateAttachments1.graphql"
);

export default compose(
  withRouter,
  withApollo,
  graphql(DELETE_CANDIDATE_ATTACHMENT, {
    name: "deleteCandidateAttachment",
  }),
  withHandlers({
    handleDeleteAttachment:
      ({
        match: {
          params: { candidateType },
        },
        deleteCandidateAttachment,
        candidate,
        client,
      }) =>
      async ({ id, file_name, content_type }) => {
        await deleteCandidateAttachment({
          variables: {
            id,
            position_id: candidate.position.id,
            user_id: candidateType === "candidate" ? candidate.id : null,
            person_id: candidateType === "person" ? candidate.id : null,
          },
        });
        await client.query({
          query: CANDIDATE_ATTACHMENTS,
          variables: {
            user_id: candidateType === "candidate" ? candidate.id : null,
            person_id: candidateType === "person" ? candidate.id : null,
            position_id: candidate.position.id,
            team_id: candidate.position.parent_team_id,
            org_id: candidate.position.parent_org_id,
          },
          fetchPolicy: "network-only",
        });
      },
    handleAttachmentDownload:
      ({
        match: {
          params: { candidateType },
        },
        client,
        candidate,
      }) =>
      async ({ id, file_name }) => {
        const {
          data: { attachmentReadSignedUrl },
        } = await client.query({
          query: ATTACHMENT_READ_SIGNED_URL,
          variables: {
            user_id: candidateType === "candidate" ? candidate.id : null,
            person_id: candidateType === "person" ? candidate.id : null,
            position_id: candidate.position.id,
            file_name,
            id,
          },
          fetchPolicy: "network-only",
        });

        const link = document.createElement("a");
        link.href = attachmentReadSignedUrl;
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      },
  })
);
