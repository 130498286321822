import React from "react";
import Timestamp from "react-timestamp";
import Layout from "layouts";
import Table from "components/v2/UI/Table";
import Chip from "components/v2/UI/Chip";
import { typography } from "style/general";
import UserAvatar from "components/UserAvatar";
import SidebarWrapper from "components/UI/SidebarWrapper";
import SidebarHeader from "components/UI/SidebarHeader";
import { useResponsive } from "utils/hooks/useResponsive";

import compose from "./compose";
import Icon from "components/UI/Icon/index";
import Button from "components/UI/Button/index";
import classnames from "classnames";

import styles from "./styles.module.scss";

export default compose(
  ({
    history,
    match: {
      params: { org_id },
    },
    data: { assessmentGroup },
  }) => {
    const { isMobile } = useResponsive();
    const status = {
      "IN PROGRESS": {
        variant: "light",
        color: "orange",
      },
      COMPLETED: {
        variant: "light",
        color: "green",
      },
    };

    const popoverActions = [
      {
        name: "Complete session",
        icon: "CheckLarge",
        action: () => alert("Send reminder"),
      },
    ];

    const columns = React.useMemo(
      () => [
        {
          Header: "Name",
          accessor: "employee_under_review", // accessor is the "key" in the data
          Cell: ({ row }) => {
            return (
              <a
                href={`/${org_id}/assessment/${row.original.id}`}
                className={classnames(
                  styles.assessmentGroup__link,
                  "label",
                  "no-border"
                )}
              >
                <div className={styles.assessmentGroup__flexContainer}>
                  <div className={styles.assessmentGroup__flexColumn}>
                    <div className={styles.assessmentGroup__flexRow}>
                      <div>
                        <UserAvatar
                          member={row.original.employee_under_review}
                          size="verySmall"
                        />
                      </div>

                      {row.original.employee_under_review.name}
                    </div>

                    {isMobile && (
                      <div className={styles.assessmentGroup__iconContainer}>
                        <Icon icon="Clipboard" primary />
                        <p
                          style={{
                            ...typography.label,
                            ...typography.bold,
                            ...typography.size14,
                          }}
                        >
                          {row.original.scorecard.title}
                        </p>
                      </div>
                    )}
                  </div>
                  {isMobile && (
                    <div className={styles.assessmentGroup__chipContainer}>
                      <Chip
                        color={status[row.original.status].color}
                        variant={status[row.original.status].variant}
                      >
                        {row.original.status}
                      </Chip>
                    </div>
                  )}
                </div>
              </a>
            );
          },
          width: 300,
        },
      ],
      []
    );

    if (!isMobile) {
      columns.push(
        {
          Header: "Scorecard",
          accessor: "scorecard",
          Cell: ({ value }) => {
            return (
              <div style={{ display: "flex", gap: 4, alignItems: "center" }}>
                <Icon icon="Clipboard" primary />
                <p
                  style={{
                    ...typography.label,
                    ...typography.bold,
                    ...typography.size14,
                  }}
                >
                  {value.title}
                </p>
              </div>
            );
          },
          width: "450px",
        },
        {
          Header: "Company",
          accessor: "company",
          Cell: ({ row }) => (
            <p className={styles.pStyle}>{row.original.company}</p>
          ),
        },
        {
          Header: "Job title",
          accessor: "job_title",
          Cell: ({ row }) => {
            return (
              <p style={{ minWidth: "50px" }} className={styles.pStyle}>
                {row.original.job_title}
              </p>
            );
          },
        },
        {
          Header: "Date",
          accessor: "created_at",
          Cell: ({ value }) => {
            const date = new Date(parseInt(value));
            return (
              <p style={typography.size14}>
                <Timestamp
                  date={date}
                  autoUpdate
                  options={{ format: "date" }}
                />
              </p>
            );
          },
        },
        {
          Header: "Status",
          accessor: "status",
          Cell: ({ value }) => {
            return (
              <Chip color={status[value].color} variant={status[value].variant}>
                {value}
              </Chip>
            );
          },
        },
        // TODO: Restore after "Complete session" action is ready on API
        // {
        //   Header: "",
        //   accessor: "actions",
        //   Cell: ({ row }) => (
        //     <div
        //       style={{
        //         display: "flex",
        //         alignItems: "center",
        //         justifyContent: "center",
        //       }}
        //     >
        //       <Popover popOverActions={popoverActions} popLeft={true} />
        //     </div>
        //   ),
        // }

        // TODO: Remove after "Complete session" action is ready on API
        {
          Header: "",
          accessor: "id",
          Cell: ({ value }) => {
            return (
              <a href={`/${org_id}/assessment/${value}`}>
                <Button bStyle={["verySmall"]}>
                  <Icon icon="Arrow" />
                </Button>
              </a>
            );
          },
        }
      );
    }

    const rightSideCol = <p>{assessmentGroup.description}</p>;

    return (
      <SidebarWrapper>
        <SidebarHeader title={assessmentGroup.title}>
          <Button
            bStyle={["primary", "verySmall"]}
            onClick={() =>
              history.push(`${history.location.pathname}/session/create`)
            }
          >
            <Icon icon="Plus" marginRight />
            New session
          </Button>
        </SidebarHeader>
        <div className={styles.assessmentGroup__layoutContainer}>
          <Layout
            title={assessmentGroup.title}
            variant="2-col-page"
            rightColContent={rightSideCol}
            icon="360Assesment"
          >
            <p>{assessmentGroup.subtitle}</p>
          </Layout>
          <div className={styles.assessmentGroup__flexColumnGap24}>
            <h4 style={typography.h4}>Individual assessments</h4>

            <Table columns={columns} data={assessmentGroup.sessions} />
          </div>
        </div>
      </SidebarWrapper>
    );
  }
);
