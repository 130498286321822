import { withRouter } from 'react-router-dom'

export default withRouter(({ history, match, children }) => {
  const shouldReroute = match.url.split('/').includes('join')
  if (shouldReroute) {
    history.push(`/invite/${match.params.inviteSecret}`)
    return null
  }

  return children
})
