import { withHandlers } from "recompose";

export default withHandlers({
  handleUpdateReview:
    ({ updateReview }) =>
    async (review_id, data) => {
      try {
        await updateReview({
          variables: {
            id: review_id,
            data,
          },
        });
      } catch (err) {
        throw new Error(err);
      }
    },
});
