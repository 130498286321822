import React from 'react'
import { ReactComponent as PlaceholderImg } from 'images/position/compareNoCandidates.svg'

import styles from './CompareCandidates.module.scss'

export default ({ type }) => (
  <div className={styles.emptyState}>
    <PlaceholderImg />
    <h4>
      {type === 'compare'
        ? 'Move candidates into Compare column'
        : 'Invite candidates'}
    </h4>
    <p>
      {type === 'compare'
        ? "Move candidates into the Compare column, or select 'All profiles' from the filter above."
        : 'Invite candidates into position and evaluate them to start comparing.'}
    </p>
  </div>
)
