import React from "react";
import { compose, withState, withHandlers, withProps } from "recompose";
import { graphql, withApollo } from "react-apollo";
import { loader } from "graphql.macro";
import { withRouter } from "react-router-dom";
import handleLoading from "utils/loader";
import Circles from "components/Loaders/Circles";
import serializer from "components/TextEditor/editorSerializer";
import deserialize from "./deserialize";

const POSITION_TEMPLATE = loader("client/Queries/PositionTemplate8.graphql");
const UPSERT_REQUIREMENT = loader(
  "./upsertPositionTemplateRequirement.graphql"
);
const DELETE_REQUIREMENT = loader(
  "./deletePositionTemplateRequirement.graphql"
);

export default compose(
  withRouter,
  withApollo,
  withState("cardTitle", "setCardTitle", (props) => props.card.title),
  withState(
    "cardDesc",
    "setCardDesc",
    (props) => deserialize(props.card.description) || ""
  ),
  withProps(({ cardOpen, card, newCard }) => ({
    isOpen: cardOpen === card.order && !newCard,
  })),
  graphql(UPSERT_REQUIREMENT, {
    name: "upsertRequirement",
    options: ({ data, client, templateId, orgId }) => ({
      update: (proxy, { data: { upsertPositionTemplateRequirement } }) => {
        const data = proxy.readQuery({
          query: POSITION_TEMPLATE,
          variables: {
            id: templateId,
            org_id: parseInt(orgId, 10),
          },
        });

        // data.positionTemplate.requirements = [
        //   ...data.positionTemplate.requirements.filter(
        //     req => req.id !== deletePositionTemplateRequirement
        //   )
        // ]

        proxy.writeQuery({
          query: POSITION_TEMPLATE,
          variables: {
            id: templateId,
            org_id: parseInt(orgId, 10),
          },
          data,
        });
      },
    }),
  }),
  graphql(DELETE_REQUIREMENT, {
    name: "deleteRequirement",
    options: ({ data, client, templateId, orgId }) => ({
      update: (proxy, { data: { deletePositionTemplateRequirement } }) => {
        const data = proxy.readQuery({
          query: POSITION_TEMPLATE,
          variables: {
            id: templateId,
            org_id: parseInt(orgId, 10),
          },
        });

        data.positionTemplate.requirements = [
          ...data.positionTemplate.requirements.filter(
            (req) => req.id !== deletePositionTemplateRequirement
          ),
        ];

        proxy.writeQuery({
          query: POSITION_TEMPLATE,
          variables: {
            id: templateId,
            org_id: parseInt(orgId, 10),
          },
          data,
        });
      },
    }),
  }),
  withHandlers({
    handleUpsertRequirement:
      ({
        upsertRequirement,
        setCardOpen,
        setNewCard,
        cardTitle,
        cardDesc,
        card,
        templateId,
      }) =>
      () => {
        const isUpdate = card.id;
        upsertRequirement({
          variables: {
            template_id: templateId,
            requirement_id: card ? card.id : null,
            title: cardTitle,
            description: serializer.serialize(cardDesc),
            order: card.order,
          },
        }).then((res) => {
          if (!isUpdate) {
            setNewCard(null);
            setCardOpen(card.order);
          }
        });
      },
    handleDeleteRequirement:
      ({
        deleteRequirement,
        setCardOpen,
        cardTitle,
        cardDesc,
        card,
        templateId,
        orgId,
      }) =>
      () => {
        deleteRequirement({
          variables: {
            template_id: templateId,
            requirement_id: card ? card.id : null,
            org_id: parseInt(orgId, 10),
          },
        }).then((res) => {
          setCardOpen(null);
        });
      },
  }),
  handleLoading(() => (
    <div style={{ height: "100vh" }}>
      <Circles />
    </div>
  ))
);
