import React from "react";
import Modal from "react-modal";
import Button from "components/UI/Button";
import CompetencyChart from "components/Charts/CompetencyChart";
import CompetencyModal from "components/Competencies/CompetencyModal";
import CompetencyPlaceholder from "components/Placeholders/CompetencyPlaceholder";
import styles from "./CompetenciesList.module.scss";
import compose from "./compose";

export default compose(
  ({
    user,

    showModal,
    setShowModal,
    competencyGraph,
  }) => {
    return (
      <div className={styles.competencies}>
        {user.personalProfile.competencies.some((c) => c.score === null) ? (
          <div className={styles.placeholder}>
            <CompetencyPlaceholder resource="personal" />
          </div>
        ) : (
          <div className={styles.chart}>
            <CompetencyChart
              competencies={competencyGraph}
              setShowModal={setShowModal}
              type="personal"
            />
          </div>
        )}

        <Modal
          isOpen={showModal !== ""}
          onRequestClose={() => setShowModal("")}
          shouldCloseOnOverlayClick
        >
          <Button bStyle="close" type="button" onClick={() => setShowModal("")}>
            <i className="fal fa-times" />
          </Button>
          <CompetencyModal
            resource="orgUser"
            member={user}
            competencies={user.personalProfile.competencies}
            setShowModal={setShowModal}
            currentCompName={showModal}
          />
        </Modal>
      </div>
    );
  }
);
