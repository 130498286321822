import { TEAMDEV_STEP } from 'store/actionTypes'

const initialState = {
  currentStep: 1,
  id: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case TEAMDEV_STEP:
      return Object.assign({}, state, {
        currentStep: action.payload
      })
    default:
      return state
  }
}
