import React from "react";
import PropTypes from "prop-types";
import Button from "components/UI/Button/index";
import Icon from "components/UI/Icon/index";
import { palette, typography } from "style/general";
import Chip from "../Chip/index";

const ScorecardListRow = ({ scorecard }) => {
  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <div style={{ display: "flex", gap: 16, alignItems: "center" }}>
        <div
          style={{
            width: 32,
            height: 32,
            backgroundColor: palette.blue1,
            borderRadius: 32,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Icon icon={scorecard?.icon || "ProfileSummary"} />
        </div>
        <div>
          <p style={typography.label}>{scorecard.position}</p>
        </div>
        <div style={{ display: "flex", gap: 8 }}>
          <Chip>{scorecard.category}</Chip>
          <Chip variant="outlined">{scorecard.stage}</Chip>
        </div>
      </div>
      {scorecard.action && (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Button bStyle={["none"]} onClick={() => scorecard.action}>
            <Icon icon="Edit" />
          </Button>
        </div>
      )}
    </div>
  );
};

ScorecardListRow.propTypes = {
  scorecard: PropTypes.shape({
    icon: PropTypes.string,
    position: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    stage: PropTypes.string.isRequired,
    action: PropTypes.func,
  }).isRequired,
};

export default ScorecardListRow;
