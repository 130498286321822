import React from "react";
import { compose, withState } from "recompose";
import { withRouter } from "react-router-dom";
import { graphql } from "react-apollo";
import scrollToTop from "utils/scrollToTop";
import FullPage from "components/Loaders/FullPage";

import { loader } from "graphql.macro";
import handleLoading from "utils/loader";

const CANDIDATE_QUERY = loader("client/Queries/CandidateQuery198.graphql");

export default compose(
  withRouter,
  withState("currentTab", "setCurrentTab", 0),

  withState("selectedEvaluators", "setSelectedEvaluators", []),
  withState("list", "setList", ["Interview & Evaluate", "Summary"]),
  withState("openModal", "updateModal", null),
  withState("overlayClose", "setOverlayClose", true),
  graphql(CANDIDATE_QUERY, {
    options: ({
      match: {
        params: { candidateId, teamId, positionId, orgId, candidateType },
      },
    }) => ({
      variables: {
        user_id:
          candidateType === "candidate" ? parseInt(candidateId, 10) : null,
        person_id:
          candidateType === "person" ? parseInt(candidateId, 10) : null,
        team_id: parseInt(teamId, 10),
        position_id: parseInt(positionId, 10),
        org_id: parseInt(orgId, 10),
      },
      notifyOnNetworkStatusChange: true,
    }),
  }),
  handleLoading(() => <FullPage />),
  scrollToTop
);
