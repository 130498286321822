import { withRouter } from "react-router-dom";
import { compose, withState, withHandlers } from "recompose";
import { graphql, withApollo } from "react-apollo";
import { loader } from "graphql.macro";
import deserialize from "utils/deserialize";

const GENERATE_WISGPT_SUMMARY = loader(
  "./generateSummaryFromLinkedinData.graphql"
);

export default compose(
  withRouter,
  withApollo,
  withState("summary", "setSummary", ({ candidate }) =>
    deserialize(candidate.candidateSummary)
  ),
  withState("editSummary", "setEditSummary", false),
  withState("isGenerating", "toggleGenerating", false),
  withState("gptModal", "setGptModal", false),
  graphql(GENERATE_WISGPT_SUMMARY, {
    name: "generateSummaryFromLinkedinData",
    options: ({
      setSummary,
      toggleGenerating,
      setEditSummary,
      setGptModal,
      match: {
        params: { candidateId, positionId, candidateType },
      },
    }) => {
      return {
        variables: {
          user_id:
            candidateType === "candidate" ? parseInt(candidateId, 10) : null,
          person_id:
            candidateType === "person" ? parseInt(candidateId, 10) : null,
          position_id: parseInt(positionId, 10),
        },
        update: (proxy, { data: { generateSummaryFromLinkedinData } }) => {
          toggleGenerating(false);
          setSummary(deserialize(generateSummaryFromLinkedinData));
          setEditSummary(true);
          setGptModal(false);
        },
      };
    },
  }),
  withHandlers({
    handleGenerateSummary:
      ({ generateSummaryFromLinkedinData, toggleGenerating }) =>
      async () => {
        toggleGenerating(true);
        await generateSummaryFromLinkedinData();
        if (window.heap) {
          window.heap.track("create-linkedin-summary-candidate");
        }
      },
  })
);
