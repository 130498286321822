import { withProps, withHandlers, withState, compose } from "recompose";
import { graphql } from "react-apollo";
import { loader } from "graphql.macro";
import handleLoading from "utils/loader";
import FullPage from "components/Loaders/FullPage";
import addMembersToTeamDim from "utils/addMembersToTeamDim";

const CANDIDATE_QUERY = loader("client/Queries/CandidateQuery198.graphql");
const GENERATE_CANDIDATE_SUMMARY = loader(
  "./generateCandidateGPTSummary.graphql"
);
const DELETE_CANDIDATE_SUMMARY = loader("./deleteCandidateGPTSummary.graphql");

export default compose(
  withState("isGenerating", "toggleGenerating", false),
  handleLoading(FullPage),
  withState("gptSummary", "setGptSummary", ({ candidate }) =>
    candidate.summary ? candidate.summary.text : ""
  ),
  graphql(GENERATE_CANDIDATE_SUMMARY, {
    name: "generateCandidateProfileSummary",
    options: ({
      toggleGenerating,
      setGptSummary,
      position,
      candidate,
      match,
    }) => {
      return {
        variables: {
          position_id: parseInt(position.id, 10),
          user_id: parseInt(candidate.id, 10),
        },
        update: (proxy, { data: { generateCandidateProfileSummary } }) => {
          toggleGenerating(false);
          setGptSummary(generateCandidateProfileSummary.text);

          const data = proxy.readQuery({
            query: CANDIDATE_QUERY,
            variables: {
              user_id: parseInt(match.params.candidateId, 10),
              team_id: parseInt(match.params.teamId, 10),
              position_id: parseInt(match.params.positionId, 10),
              org_id: parseInt(match.params.orgId, 10),
            },
          });

          data.candidateInTeam.summary = generateCandidateProfileSummary;

          proxy.writeQuery({
            query: CANDIDATE_QUERY,
            variables: {
              user_id: parseInt(match.params.candidateId, 10),
              team_id: parseInt(match.params.teamId, 10),
              position_id: parseInt(match.params.positionId, 10),
              org_id: parseInt(match.params.orgId, 10),
            },
            data,
          });
        },
      };
    },
  }),
  graphql(DELETE_CANDIDATE_SUMMARY, {
    name: "deleteCandidateProfileSummary",
    options: ({ setGptSummary, position, candidate, match }) => {
      return {
        variables: {
          position_id: parseInt(position.id, 10),
          user_id: parseInt(candidate.id, 10),
        },
        update: (proxy, { data: { deleteCandidateProfileSummary } }) => {
          setGptSummary("");

          const data = proxy.readQuery({
            query: CANDIDATE_QUERY,
            variables: {
              user_id: parseInt(match.params.candidateId, 10),
              team_id: parseInt(match.params.teamId, 10),
              position_id: parseInt(match.params.positionId, 10),
              org_id: parseInt(match.params.orgId, 10),
            },
          });

          data.candidateInTeam.summary = null;

          proxy.writeQuery({
            query: CANDIDATE_QUERY,
            variables: {
              user_id: parseInt(match.params.candidateId, 10),
              team_id: parseInt(match.params.teamId, 10),
              position_id: parseInt(match.params.positionId, 10),
              org_id: parseInt(match.params.orgId, 10),
            },
            data,
          });
        },
      };
    },
  }),
  withHandlers({
    handleGenerateSummary:
      ({ generateCandidateProfileSummary, toggleGenerating }) =>
      async () => {
        toggleGenerating(true);
        await generateCandidateProfileSummary();
        if (window.heap) {
          window.heap.track("create-summary-candidate");
        }
      },
    handleDeleteSummary:
      ({ deleteCandidateProfileSummary }) =>
      async () => {
        await deleteCandidateProfileSummary();
        if (window.heap) {
          window.heap.track("delete-summary-candidate");
        }
      },
  }),
  withProps(({ position, candidate }) => {
    const {
      parentTeam: { personality, competencies, values, members },
    } = position;
    return {
      dimensions: [
        ...personality.map((dim) => ({
          ...dim,
          members: addMembersToTeamDim({
            dim,
            members,
            candidate,
            dimGroup: "personality",
          }),
        })),
        ...competencies.map((dim) => ({
          ...dim,
          members: addMembersToTeamDim({
            dim,
            members,
            candidate,
            dimGroup: "competencies",
          }),
        })),
        ...values.map((dim) => ({
          ...dim,
          members: addMembersToTeamDim({
            dim,
            members,
            candidate,
            dimGroup: "values",
          }),
        })),
      ],
    };
  })
);
