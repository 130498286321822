import { compose, withHandlers, withState } from "recompose";
import { graphql } from "react-apollo";
import { withRouter } from "react-router-dom";
import { loader } from "graphql.macro";

const CREATE_COMMENT = loader("./createComment4.graphql");
const REMOVE_COMMENT = loader("./removeComment1.graphql");
const TEAM_QUERY = loader("client/Queries/TeamQuery124.graphql");
const VIEWER = loader("client/Queries/ViewerQuery25.graphql");
const CANDIDATE_QUERY = loader("client/Queries/CandidateQuery198.graphql");
const POSITION_QUERY = loader("client/Queries/PositionQuery98.graphql");
const ASSESSMENT_REVIEW = loader("client/Queries/AssessmentReview9.graphql");

export default compose(
  withRouter,
  withState("comment", "setComment", ""),
  withState("openModal", "setOpenModal", false),
  graphql(VIEWER),
  graphql(CREATE_COMMENT, {
    name: "createComment",
    options: ({
      match,
      subjectType,
      subjectId,
      objType,
      objId,
      itemType,
      itemId,
    }) => ({
      update: (proxy, { data: { createComment } }) => {
        if (objType === "assessment_row") {
          const data = proxy.readQuery({
            query: ASSESSMENT_REVIEW,
            variables: {
              review_id: subjectId,
            },
          });

          data.assessmentReview.scores.find(
            (score) => score.id === createComment.item_id
          ).comments = [
            ...data.assessmentReview.scores.find(
              (score) => score.id === createComment.item_id
            ).comments,
            createComment,
          ];

          proxy.writeQuery({
            query: ASSESSMENT_REVIEW,
            variables: {
              review_id: subjectId,
            },
            data,
          });
        }

        if (objType === "team") {
          const data = proxy.readQuery({
            query: TEAM_QUERY,
            variables: {
              id: objId,
            },
          });
          data.team.comments = [...data.team.comments, createComment];

          proxy.writeQuery({
            query: TEAM_QUERY,
            variables: {
              id: objId,
            },
            data,
          });
        }

        if (
          objType === "position" &&
          !["candidate", "person"].includes(subjectType)
        ) {
          const data = proxy.readQuery({
            query: POSITION_QUERY,
            variables: {
              id: objId,
            },
          });

          data.openPosition.comments = [
            ...data.openPosition.comments,
            createComment,
          ];

          proxy.writeQuery({
            query: POSITION_QUERY,
            variables: {
              id: objId,
            },
            data,
          });
        }

        if (subjectType === "member") {
          const data = proxy.readQuery({
            query: TEAM_QUERY,
            variables: {
              id: objId,
            },
          });
          data.team.members.find((m) => m.id === subjectId).comments = [
            ...data.team.members.find((m) => m.id === subjectId).comments,
            createComment,
          ];

          proxy.writeQuery({
            query: TEAM_QUERY,
            variables: {
              id: objId,
            },
            data,
          });
        }

        if (["candidate", "person"].includes(subjectType)) {
          const data = proxy.readQuery({
            query: CANDIDATE_QUERY,
            variables: {
              user_id: subjectType === "candidate" ? subjectId : null,
              person_id: subjectType === "person" ? subjectId : null,
              team_id: parseInt(match.params.teamId, 10),
              position_id: objId,
              org_id: parseInt(match.params.orgId, 10),
            },
          });

          if (itemType === "evaluation") {
            const evalKey = data.candidateInTeam.evaluation.findIndex(
              (ev) => ev.id === parseInt(createComment.item_id, 10)
            );

            if (data.candidateInTeam.evaluation[evalKey].comments) {
              data.candidateInTeam.evaluation[evalKey].comments = [
                ...data.candidateInTeam.evaluation[evalKey].comments,
                createComment,
              ];
            } else {
              data.candidateInTeam.evaluation[evalKey].comments = [
                createComment,
              ];
            }
          } else {
            data.candidateInTeam.comments = [
              ...data.candidateInTeam.comments,
              createComment,
            ];
          }

          proxy.writeQuery({
            query: CANDIDATE_QUERY,
            variables: {
              user_id: subjectType === "candidate" ? subjectId : null,
              person_id: subjectType === "person" ? subjectId : null,
              team_id: parseInt(match.params.teamId, 10),
              position_id: objId,
              org_id: parseInt(match.params.orgId, 10),
            },
            data,
          });
        }
      },
    }),
  }),
  graphql(REMOVE_COMMENT, {
    name: "removeComment",
    options: ({ match, subjectType, subjectId, objType, objId, itemType }) => ({
      update: (proxy, { data: { removeComment } }) => {
        if (objType === "assessment_row") {
          const data = proxy.readQuery({
            query: ASSESSMENT_REVIEW,
            variables: {
              review_id: subjectId,
            },
          });

          data.assessmentReview.scores.find(
            (score) => score.id === removeComment.item_id
          ).comments = [
            ...data.assessmentReview.scores
              .find((score) => score.id === removeComment.item_id)
              .comments.filter((c) => c.id !== removeComment.id),
          ];

          proxy.writeQuery({
            query: ASSESSMENT_REVIEW,
            variables: {
              review_id: subjectId,
            },
            data,
          });
        }

        if (objType === "team") {
          const data = proxy.readQuery({
            query: TEAM_QUERY,
            variables: {
              id: objId,
            },
          });
          data.team.comments = [
            ...data.team.comments.filter((c) => c.id !== removeComment.id),
          ];

          proxy.writeQuery({
            query: TEAM_QUERY,
            variables: {
              id: objId,
            },
            data,
          });
        }

        if (
          objType === "position" &&
          !["candidate", "person"].includes(subjectType)
        ) {
          const data = proxy.readQuery({
            query: POSITION_QUERY,
            variables: {
              id: objId,
            },
          });
          data.openPosition.comments = [
            ...data.openPosition.comments.filter(
              (c) => c.id !== removeComment.id
            ),
          ];

          proxy.writeQuery({
            query: POSITION_QUERY,
            variables: {
              id: objId,
            },
            data,
          });
        }

        if (subjectType === "member") {
          const data = proxy.readQuery({
            query: TEAM_QUERY,
            variables: {
              id: objId,
            },
          });
          data.team.members.find((m) => m.id === subjectId).comments = [
            ...data.team.members
              .find((m) => m.id === subjectId)
              .comments.filter((c) => c.id !== removeComment.id),
          ];

          proxy.writeQuery({
            query: TEAM_QUERY,
            variables: {
              id: objId,
            },
            data,
          });
        }

        if (["candidate", "person"].includes(subjectType)) {
          const data = proxy.readQuery({
            query: CANDIDATE_QUERY,
            variables: {
              user_id: subjectType === "candidate" ? subjectId : null,
              person_id: subjectType === "person" ? subjectId : null,
              team_id: parseInt(match.params.teamId, 10),
              position_id: objId,
              org_id: parseInt(match.params.orgId, 10),
            },
          });

          if (itemType === "evaluation") {
            let evalKey;

            data.candidateInTeam.evaluation.forEach((e, eKey) => {
              if (e.comments) {
                e.comments.forEach((c, cKey) => {
                  if (c.id === removeComment.id) {
                    evalKey = eKey;
                  }
                });
              }
            });

            data.candidateInTeam.evaluation[evalKey].comments = [
              ...data.candidateInTeam.evaluation[evalKey].comments.filter(
                (c) => c.id !== removeComment.id
              ),
            ];
          } else {
            data.candidateInTeam.comments = [
              ...data.candidateInTeam.comments.filter(
                (c) => c.id !== removeComment.id
              ),
            ];
          }

          proxy.writeQuery({
            query: CANDIDATE_QUERY,
            variables: {
              user_id: subjectType === "candidate" ? subjectId : null,
              person_id: subjectType === "person" ? subjectId : null,
              team_id: parseInt(match.params.teamId, 10),
              position_id: objId,
              org_id: parseInt(match.params.orgId, 10),
            },
            data,
          });
        }
      },
    }),
  }),
  withHandlers({
    handleCreateComment:
      ({
        comment,
        createComment,
        setComment,
        objType,
        objId,
        subjectType,
        subjectId,
        itemType,
        itemId,
      }) =>
      async (e) => {
        e.preventDefault();
        if (comment && comment.length > 0) {
          createComment({
            variables: {
              obj_type: objType,
              obj_id: objId,
              subject_type: subjectType,
              subject_id: subjectId,
              item_type: itemType,
              item_id: itemId,
              comment,
            },
          }).then((res) => {
            setComment("");
          });
        }
      },
    handleRemoveComment:
      ({ removeComment }) =>
      async (comment) => {
        await removeComment({
          variables: {
            id: comment.id,
          },
        });
      },
  })
);
