import React, { useState } from "react";
import Circles from "components/Loaders/Circles";
import SignupForm from "components/Forms/SignupForm";
import compose from "./compose";
import styles from "./SignupBox.module.scss";
import SSOButtons from "components/SSOButtons";

export default compose(
  ({
    setLoading,
    loading,
    authStrategies,
    email,
    onlyLocal,
    ssoTermsAccepted,
    toggleSSoTerms,
    location,
    match,
  }) => {
    return (
      <div className={styles.signup}>
        <h3 className={styles.heading}>Create a new account with:</h3>
        {loading ? (
          <div className={styles.loading}>
            <Circles />
          </div>
        ) : (
          <>
            {!onlyLocal && (
              <>
                {/* <h5 className={styles.ssoTitle}>Create a new account with:</h5> */}
                <SSOButtons authStrategies={authStrategies} />
              </>
            )}
            {authStrategies.some((strategy) => strategy.name === "local") && (
              <>
                {authStrategies.length === 1 &&
                authStrategies[0].name === "local" ? null : !onlyLocal ? (
                  <p className={styles.separate}>or</p>
                ) : null}
                <SignupForm
                  email={email}
                  setLoading={setLoading}
                  loading={loading}
                />
              </>
            )}
          </>
        )}
      </div>
    );
  }
);
