import React from "react";
import {
  compose,
  withState,
  withProps,
  branch,
  renderComponent,
} from "recompose";
import { withRouter } from "react-router-dom";
import { graphql } from "react-apollo";
import Circles from "components/Loaders/Circles";
import { loader } from "graphql.macro";
import scrollToTop from "utils/scrollToTop";
import handleLoading from "utils/loader";
import createList from "./createList";

const CANDIDATE_QUERY = loader("client/Queries/CandidateQuery198.graphql");
const VIEWER_QUERY = loader("client/Queries/ViewerQuery25.graphql");

export default compose(
  withRouter,
  graphql(VIEWER_QUERY, {
    name: "VIEWER_QUERY",
  }),
  branch(
    (props) => props.VIEWER_QUERY && props.VIEWER_QUERY.loading,
    renderComponent(() => null)
  ),
  withProps((props) => {
    return {
      viewer: props.VIEWER_QUERY.viewer,
    };
  }),
  graphql(CANDIDATE_QUERY, {
    options: ({
      match: {
        params: { candidateId, teamId, positionId, orgId, candidateType },
      },
    }) => {
      return {
        variables: {
          user_id:
            candidateType === "candidate" ? parseInt(candidateId, 10) : null,
          person_id:
            candidateType === "person" ? parseInt(candidateId, 10) : null,
          team_id: parseInt(teamId, 10),
          position_id: parseInt(positionId, 10),
          org_id: parseInt(orgId, 10),
        },
        notifyOnNetworkStatusChange: true,
      };
    },
  }),
  handleLoading(() => (
    <div style={{ height: "100vh" }}>
      <Circles />
    </div>
  )),
  withState(
    "list",
    "setList",
    ({ data: { candidateInTeam: candidate }, viewer, selectedEvaluators }) =>
      createList(candidate, viewer, selectedEvaluators)
  ),
  scrollToTop
);
