import React from "react";
import compose from "./compose";
import Tour from "../Tour";
import UseCase from "../UseCase";
import Profile from "../Profile";
import Survey from "../Survey";

export default compose((props) => {
  switch (props.type) {
    case "useCase":
      return (
        <UseCase
          {...props}
          {...props.blocks[props.userOnboarding.currentSubstep]}
        />
      );
    case "tour":
      return (
        <Tour
          {...props}
          {...props.blocks[props.userOnboarding.currentSubstep]}
        />
      );
    case "profile":
      return (
        <Profile
          {...props}
          {...props.blocks[props.userOnboarding.currentSubstep]}
        />
      );
    case "surveys":
      return (
        <Survey
          {...props}
          {...props.blocks[props.userOnboarding.currentSubstep]}
        />
      );
    default:
      return null;
  }
});
