import React from 'react'
import { Link } from 'react-router-dom'
import Button from 'components/UI/Button'
import { ReactComponent as SuspendedIcon } from 'images/Suspended.svg'

import styles from './AccountSuspended.module.scss'

export default ({ account }) => (
  <div className={styles.wrapper}>
    <div className={styles.icon}>
      <SuspendedIcon />
    </div>
    <h4>Workspace suspended</h4>
    <p>Please upgrade your billing to continue using Wisnio.</p>
    <div className={styles.button}>
      <Link to={`/account/${account.id}/billing`}>
        <Button bStyle={['primary', 'small']} type="button">
          Subscribe
        </Button>
      </Link>
    </div>
  </div>
)
