import React from 'react'
import classNames from 'classnames'
import Button from 'components/UI/Button'
import UserAvatar from 'components/UserAvatar'
import { ReactComponent as GoalAlignment } from 'images/icons/toolkits/goal-alignment.svg'
import { ReactComponent as CompetencyMapping } from 'images/icons/toolkits/competency-mapping.svg'

import compose from './compose'

import styles from './FinishGoals.module.scss'

export default compose(
  ({
    TEAM_QUERY: { team },
    TEAM_GOAL_GROUPS: { teamGoalGroups },
    toolkit,
    history,
    handleFinishTeamPlay
  }) => (
    <div className={styles.main}>
      <div className={styles.content}>
        <p className={styles.subtitle}>
            Congratulations on successfully going through goal alignment. Your
            chosen goals are displayed below:
        </p>
        <div className={styles.finalGoalsWrapper}>
          {teamGoalGroups.length > 0 ? (
            teamGoalGroups.map(gg => (
              <div className={styles.goal} key={`goal-${gg.id}`}>
                <div className={styles.iconActive}>
                  <GoalAlignment />
                </div>
                <h5 className={!gg.title && styles.italic}>
                  {gg.title || 'No title'}
                </h5>
                <div className={styles.subGoals}>
                  {gg.goals.map(subgoal => (
                    <div className={styles.subGoal}>
                        <div className={styles.avatarWrapper}>
                          <UserAvatar member={subgoal.createdBy} size='small' />
                        </div>
                        <p className={styles.title}>{subgoal.title}</p>
                      </div>
                  ))}
                </div>
              </div>
            ))
          ) : (
            <p className={styles.noGoals}>No goals selected</p>
          )}
        </div>
        <div className={styles.section}>
          <h4>Next steps</h4>
          <p>
              To get the most from your goals, we suggest using the
            {' '}
            <strong>Competency benchmark and gap analysis</strong>
            {' '}
tool next.
            <br />
              It’s useful for understanding and bridging the possible competency
              gaps that could hold the team back from achieving its goals.
          </p>
          <div className={styles.boxes}>
            <Button bStyle={['none']} type='button' disabled>
              <div className={styles.box}>
                <div className={classNames(styles.icon, styles.competency)}>
                  <CompetencyMapping />
                </div>
                <h5>Competency benchmark and gap analysis</h5>
                <p className={styles.comingSoon}>Coming soon</p>
              </div>
            </Button>
            <Button
              bStyle={['none']}
              type='button'
              onClick={
                  toolkit.inProgress
                    ? handleFinishTeamPlay
                    : () => history.push(`/team/${team.id}/toolkit`)
                }
            >
              <div className={styles.box}>
                <div className={classNames(styles.icon, styles.finish)}>
                  <i className='far fa-check' />
                </div>
                <h5>Finish session</h5>
              </div>
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
)
