import {
  ACCOUNT_LIMIT_EXCEEDED,
  CLOSE_ACCOUNT_LIMIT_EXCEEDED
} from 'store/actionTypes'

const initialState = {
  modalOpen: false,
  account: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ACCOUNT_LIMIT_EXCEEDED:
      return Object.assign({}, state, {
        modalOpen: true,
        account: action.payload.account
      })
    case CLOSE_ACCOUNT_LIMIT_EXCEEDED:
      return Object.assign({}, state, {
        modalOpen: false,
        account: null
      })
    default:
      return state
  }
}
