import React from 'react'
import TeamMembers from 'components/TeamMembers'
import Wrapper from 'components/UI/Wrapper'
import Title from 'components/UI/Title'
import Subtitle from 'components/UI/Subtitle'

import styles from './MembersInTeam.module.scss'

export default ({ team, refetch }) => (
  <div className={styles.main}>
    <Wrapper>
      <Title text='Members in team' icon='fas fa-users' />
      <Subtitle
        verySmall
        text='Learn more about what makes each team member stand out, as well as their
        individual behavioural preferences, driving motivators, and how they
        contribute to the team’s diversity.'
      />
      <div className={styles.membersWrapper}>
        <TeamMembers team={team} refetch={refetch} />
      </div>
    </Wrapper>
  </div>
)
