import React from "react";
import Wrapper from "components/UI/Wrapper";
import Title from "components/UI/Title";
import Subtitle from "components/UI/Subtitle";
import TopValues from "components/TopValues";
import ValuesChart from "./Chart";
import styles from "./Values.module.scss";

export default ({ team, teamMember }) => (
  <div className={styles.main}>
    <Wrapper>
      <Title text="Values map" icon="fas fa-balance-scale" />
      <Subtitle text={`${teamMember.first_name}'s personal motivators.`} />
      <Subtitle
        verySmall
        text={`Learn about what really motivates ${teamMember.first_name}, how their expectations align with the rest of the team and where they may disagree.`}
      />
      <ValuesChart teamMember={teamMember} team={team} />
      <TopValues
        user={teamMember}
        data={team}
        members={team.members}
        resource="member"
      />
    </Wrapper>
  </div>
);
