import React, { useState } from "react";
import classNames from "classnames";
import Icon from "components/UI/Icon";
import styles from "./File.module.scss";

export default ({ handleAttachmentDownload, file, setShowTooltip }) => {
  const [disabled, toggleDisabled] = useState(false);

  const iconList = {
    "image/png": "JPG",
    "image/jpeg": "JPG",
    "application/pdf": "PDF",
    "application/msword": "DOC",
    "application/vnd.oasis.opendocument.text": "DOC",
  };

  const icon = iconList[file.content_type];

  let timeout = null;

  const handleShowTooltip = (id) => {
    timeout = setTimeout(() => {
      setShowTooltip(id);
    }, 800);

    return () => clearTimeout(timeout);
  };

  const handleHideTooltip = () => {
    clearTimeout(timeout);
    setShowTooltip(null);
  };

  return (
    <div
      className={styles.fileRow}
      onMouseEnter={() => handleShowTooltip(file.id)}
      onMouseLeave={() => handleHideTooltip(null)}
    >
      <li
        onClick={(e) => {
          e.preventDefault();
          handleAttachmentDownload(file);
        }}
        className={classNames(disabled && styles.disabled)}
      >
        <Icon icon={icon} />
        <span className={styles.fileName}>{file.file_name}</span>
      </li>
    </div>
  );
};
