import React from 'react'
import UserAvatar from 'components/UserAvatar'
import styles from './MemberAvatar.module.scss'

export default ({ member, setMemberHover }) => {
  if (member.competencyGraph.groups.every(group => group.score !== null)) {


    const scoresPathData = []

    member.competencyGraph.groups.sort((a,b) => a.dim_id - b.dim_id).map((scoreObject, key) => {
      const initialRadius = 30
      const sectionAngle = 360 / member.competencyGraph.groups.length
      const startAngle = sectionAngle * key
      const scoreAngle = startAngle + sectionAngle / 2
      let distance = (scoreObject.score * initialRadius) / 100
      if (distance === 0) distance = 0
      const scorePosX = Math.cos((scoreAngle * Math.PI) / 180) * distance + 50

      const scorePosY = Math.sin((scoreAngle * Math.PI) / 180) * distance + 50

      scoresPathData.push({ x: scorePosX, y: scorePosY })
    })

    const xCoordinates = scoresPathData.map(position => position.x)
    const yCoordinates = scoresPathData.map(position => position.y)
    const xSum = xCoordinates.reduce((a, b) => a + b, 0)
    const ySum = yCoordinates.reduce((a, b) => a + b, 0)

    const userCenterX = xSum / xCoordinates.length - 50
    const userCenterY = ySum / yCoordinates.length - 50

    const position = {
      x: userCenterX * 3 + 50,
      y: userCenterY * 3 + 50
    }

    return (
      <>
        <div
          className={styles.avatarPosition}
          style={{
            top: `calc(${position.y}% - 13px)`,
            left: `calc(${position.x}% - 13px)`
          }}

        >
          <div className={styles.avatarWrapper}>
            <UserAvatar member={member} size='small' showName/>
          </div>
        </div>
      </>
    )
  }
  return null
}
