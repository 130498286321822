import { ApolloLink } from "apollo-link";
import { addUserToRollbar } from "utils/confRollbar";
import store from "store";
import { SET_VIEWER } from "store/actionTypes";

export default new ApolloLink((operation, forward) =>
  forward(operation).map((res) => {
    const response = res.data[Object.keys(res.data)[0]];
    const responseType =
      response && res.data[Object.keys(res.data)[0]].__typename;

    // if (responseType === 'Account') {
    //   console.log({response})
    // }

    if (responseType === "Viewer") {
      store.dispatch({
        type: SET_VIEWER,
        payload: response,
      });

      addUserToRollbar({
        id: response.id,
        email: response.email,
        username: response.name,
      });
    }

    if (responseType === "AuthSucess") {
      addUserToRollbar({
        id: response.id,
        email: response.email,
      });
    }
    return res;
  })
);
