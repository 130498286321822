import React from "react";
import classNames from "classnames";
import Pagination from "components/UI/Pagination";
import Modal from "react-modal";
import Select from "components/UI/Select";
import Circles from "components/Loaders/Circles";
import RemoveMember from "components/Modals/RemoveMember";
import compose from "./compose";
import MemberRow from "./MemberRow";
import OrderBtn from "./OrderBtn";
import styles from "./UsersList.module.scss";

export default compose(
  ({
    TALENT_POOL_QUERY,
    TALENT_POOL_SEARCH,
    limit,
    setLimit,
    handleChangeOrgContract,
    removeMemberModal,
    updateRemoveMemberModal,
    handleRemoveUser,
    viewer,
    pageNr,
    setPageNr,
    setOrder,
    order,
    searchValue,
    updateMemberModal,
    role,
  }) => {
    if (
      (TALENT_POOL_QUERY && TALENT_POOL_QUERY.loading) ||
      (TALENT_POOL_SEARCH && TALENT_POOL_SEARCH.loading)
    ) {
      return (
        <div className={styles.sectionWrapper}>
          <div className={styles.loader}>
            <Circles />
          </div>
        </div>
      );
    }

    const talentPool = searchValue
      ? TALENT_POOL_SEARCH.searchTalentPool
      : TALENT_POOL_QUERY.organization.talentPool.sort((a, b) => {
          if (order.column === "profileComplete") {
            return b.profileComplete - a.profileComplete;
          }
          const aVar = a[order.column].toUpperCase();
          const bVar = b[order.column].toUpperCase();
          if (order.direction === "ASC") return aVar.localeCompare(bVar);
          return bVar.localeCompare(aVar);
        });

    return (
      <div className={styles.sectionWrapper}>
        <div className={styles.section}>
          <table>
            <tbody>
              <tr className={styles.tableHead}>
                <td className={classNames(styles.withButton, styles.name)}>
                  Name{" "}
                  <OrderBtn column="email" setOrder={setOrder} order={order} />
                </td>
                <td className={classNames(styles.withButton, styles.status)}>
                  Profile{" "}
                  <OrderBtn
                    column="profileComplete"
                    setOrder={setOrder}
                    order={order}
                  />
                </td>
                <td className={classNames(styles.withButton, styles.status)}>
                  Status{" "}
                  <OrderBtn column="state" setOrder={setOrder} order={order} />
                </td>

                <td
                  className={classNames(styles.withButton, styles.permissions)}
                >
                  Role{" "}
                  <OrderBtn
                    column="roleType"
                    setOrder={setOrder}
                    order={order}
                  />
                </td>
                <td />
                <td />
              </tr>
              {talentPool.map((user, i) => (
                <MemberRow
                  key={`${user.type}-${user.id}-${Math.random(10)}`}
                  user={user}
                  handleChangeOrgContract={handleChangeOrgContract}
                  removeMemberModal={removeMemberModal}
                  updateRemoveMemberModal={updateRemoveMemberModal}
                  handleRemoveUser={handleRemoveUser}
                  updateMemberModal={updateMemberModal}
                  viewer={viewer}
                  index={i + 1 + limit * pageNr}
                />
              ))}
            </tbody>
          </table>
          {!searchValue && (
            <div className={styles.paginationRow}>
              <div className={styles.show}>
                <p>Show:</p>
                <Select
                  small
                  value={limit}
                  onChange={(e) => setLimit(e.target.value)}
                >
                  <option value={15}>15</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </Select>
              </div>

              <Pagination
                pageNr={pageNr}
                setPageNr={setPageNr}
                limit={limit}
                indexesLength={
                  !role
                    ? TALENT_POOL_QUERY.organization.usersInTalentPool
                    : TALENT_POOL_QUERY.organization[`${role}sInTalentPool`]
                }
              />
            </div>
          )}
        </div>
        <Modal
          isOpen={!!removeMemberModal}
          onRequestClose={() => updateRemoveMemberModal(false)}
          shouldCloseOnOverlayClick
        >
          <RemoveMember
            member={removeMemberModal}
            type="organisation"
            updateRemoveMemberModal={updateRemoveMemberModal}
            handleRemoveUser={handleRemoveUser}
          />
        </Modal>
      </div>
    );
  }
);
