import React from "react";
import classNames from "classnames";
import Button from "components/UI/Button";
import Icon from "components/UI/Icon";

import styles from "./Kanban.module.scss";

export default ({ item, setOpenModal, resource }) => (
  <div
    className={classNames(
      styles.competency,
      item.coreComp && styles.coreComp,
      item.potGap && styles.potGap,
      resource === "survey" && styles.survey,
      styles[item.dimParent]
    )}
    key={item.id}
  >
    {(resource === "position" || resource === "template") && (
      <div className={styles.clusterIcon}>
        <Icon icon="CompCluster" />
      </div>
    )}
    <div className={styles.upper}>
      {item.coreComp && (
        <h4 className={classNames(styles.teamScoreTitle, styles.coreComps)}>
          <span className={styles.icon}>
            <Icon icon="Users" />
          </span>
          Team is most confident in
        </h4>
      )}
      {item.potGap && (
        <h4 className={classNames(styles.teamScoreTitle, styles.potGaps)}>
          <span className={styles.icon}>
            <Icon icon="Users" />
          </span>
          Team is least confident in
        </h4>
      )}
      <h4 className={styles.title}>{item.title}</h4>
      <p className={styles.desc}>{item.desc}</p>
    </div>
    {(resource === "position" || resource === "template") && (
      <div className={styles.modalIcon}>
        <Button
          bStyle={["none"]}
          type="button"
          onClick={() => setOpenModal(item.title)}
        >
          <Icon icon="Info" />
        </Button>
      </div>
    )}
  </div>
);
