import React from "react";
import { palette } from "style/general";

const Color = {
  primary: {
    main: palette.primary,
    secondary: palette.white,
  },
  secondary: {
    main: palette.secondary,
    secondary: palette.black,
  },
  green: {
    main: palette.green,
    secondary: palette.teal3,
  },
  orange: {
    main: palette.orange,
    secondary: palette.lightorange3,
  },
  grey: {
    main: palette.grey1,
    secondary: palette.grey3,
  },
  yellow: { main: palette.yellow, secondary: palette.lightyellow },
  red: { main: palette.red, secondary: palette.lightred },
  purple: { main: palette.purple, secondary: palette.lightpurple },
  blue: { main: palette.blue, secondary: palette.lightblue },
};

const Variant = {
  solid: (color) => ({
    color: Color[color].secondary,
    backgroundColor: Color[color].main,
    borderColor: Color[color].main,
  }),
  outlined: (color) => ({
    color: Color[color].main,
    backgroundColor: Color[color].secondary,
    borderColor: Color[color].main,
  }),
  light: (color) => ({
    color: Color[color].main,
    backgroundColor: Color[color].secondary,
    borderColor: Color[color].secondary,
  }),
};

const Chip = ({ color = "primary", variant = "solid", children }) => {
  const styles = {
    display: "flex",
    fontSize: 10,
    padding: "6px 8px",
    justifyContent: "center",
    alignItems: "center",
    flexShrink: 0,
    borderStyle: "solid",
    borderRadius: 8,
    fontWeight: 600,
    borderWidth: 1,
    fontFamily: "proxima-nova",
    ...Variant[variant](color),
  };

  return <div style={styles}>{children}</div>;
};

export default Chip;
