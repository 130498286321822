import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";

import styles from "./TabSwitch.module.scss";

export default ({ items }) => (
  <div className={styles.switch}>
    {items.map((item) => (
      <div
        key={"TabSwitch-" + item.link}
        className={classNames(styles.item, item.active ? styles.active : "")}
      >
        {item.link ? (
          <Link to={item.link}>{item.component}</Link>
        ) : (
          <a>{item.component}</a>
        )}
      </div>
    ))}
  </div>
);
