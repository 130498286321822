import React from "react";
import { compose, withState } from "recompose";
import { graphql, withApollo } from "react-apollo";
import { loader } from "graphql.macro";
import { withRouter } from "react-router-dom";
import handleLoading from "utils/loader";
import withStore from "utils/withStore";
import { UPDATE_ORG_NAME, RESET_ORG } from "store/actionTypes";
import scrollToTop from "utils/scrollToTop";
import FullPage from "components/Loaders/FullPage";
import handleChangeName from "./handleChangeName";

const ORGANIZATION_QUERY = loader("client/Queries/OrganizationQuery15.graphql");
const UPDATE_ORG = loader("./UpdateOrg1.graphql");
const ORGANIZATIONS = loader("client/Queries/OrganizationsQuery21.graphql");
const DELETE_ORG = loader("./DeleteOrg1.graphql");

export default compose(
  withRouter,
  withApollo,
  withStore("viewer", "selectedOrg", "account"),
  graphql(ORGANIZATION_QUERY, {
    options: (props) => {
      return {
        variables: {
          id: parseInt(props.match.params.orgId, 10),
        },
      };
    },
  }),
  graphql(DELETE_ORG, {
    name: "deleteOrg",
    options: ({ dispatch, account, history }) => {
      return {
        update: (proxy, { data: { deleteOrg } }) => {
          const data = proxy.readQuery({
            query: ORGANIZATIONS,
            variables: {
              account_id: account.id,
            },
          });

          data.organizations = data.organizations.filter(
            (o) => o.id !== deleteOrg.id
          );

          proxy.writeQuery({
            query: ORGANIZATIONS,
            variables: {
              account_id: account.id,
            },
            data,
          });

          window.localStorage.removeItem("recentOrg");
          dispatch({ type: RESET_ORG });
          history.push("/dashboard");
        },
      };
    },
  }),
  graphql(UPDATE_ORG, {
    name: "updateOrg",
    options: ({ dispatch }) => {
      return {
        update: (proxy, { data: { updateOrg } }) => {
          dispatch({
            type: UPDATE_ORG_NAME,
            payload: { name: updateOrg.name },
          });
        },
      };
    },
  }),
  handleLoading(() => <FullPage />),
  withState("orgName", "setOrgName", (props) => props.data.organization.name),
  withState("showDeletePrompt", "updateDeletePrompt", false),
  handleChangeName,
  scrollToTop
);
