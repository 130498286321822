import React from "react";
import classNames from "classnames";
import Answer from "../Answer";

import styles from "./CurrentQuestion.module.scss";

export default ({ survey, currentRowId, handleSetAnswer, answers }) => (
  <div className={styles.wrapper} key={"rowId-" + currentRowId}>
    <h3 className={classNames(styles.mainText, currentRowId % 2 && styles.fix)}>
      <span>“</span>
      {survey.survey_rows[currentRowId].dimension.question_tid}
      <span>”</span>
    </h3>
    <Answer
      handleSetAnswer={handleSetAnswer}
      answers={answers}
      currentRowId={currentRowId}
    />
    <div className={styles.underTexts}>
      <h3>
        {survey.type === "remote_work"
          ? "Strongly disagree"
          : "Not like me at all"}
      </h3>
      <h3>
        {survey.type === "remote_work" ? "Strongly agree" : "Very much like me"}
      </h3>
    </div>
  </div>
);
