import React from "react";
import { Link } from "react-router-dom";
import Popup from "reactjs-popup";
import { Form } from "react-final-form";
import classNames from "classnames";
import Icon from "components/UI/Icon";
import NewCard from "components/Card";
import DropDown from "components/UI/DropDown";
import Button from "components/UI/Button";
import FormInfo from "components/PositionTemplates/UseTemplatePopup";
import Position from "./Position";
import compose from "./compose";

import styles from "./ShowPositions.module.scss";

export default compose(
  ({
    team,
    left,
    handleCreatePosition,
    selectedTeam,
    setSelectedTeam,
    positionName,
    setPositionName,
    formStep,
    setFormStep,
    history,
  }) => {
    return (
      <div className={styles.wrapper}>
        <div className={styles.positionList}>
          {team.openPositions.map((position) => (
            <Position positionId={position.id} key={position.id} />
          ))}
          <div className="dropdownWrapper" id="dropdownMenu">
            <Popup
              keepTooltipInside="body"
              trigger={
                <div className={classNames(styles.positionRow, styles.new)}>
                  <Button bStyle={["none"]} type="button">
                    <Icon icon="Plus" />
                    Create new position
                  </Button>
                </div>
              }
              position="bottom left"
            >
              {(close) => (
                <DropDown>
                  <Form
                    autoComplete="new-password"
                    onSubmit={handleCreatePosition}
                    render={({ handleSubmit, pristine, values }) => {
                      return (
                        <div className={styles.popup}>
                          <form onSubmit={handleSubmit} autoComplete="off">
                            <FormInfo
                              data={team}
                              selectedTeam={team}
                              setSelectedTeam={setSelectedTeam}
                              selectedTemplate={null}
                              formStep={formStep}
                              setFormStep={setFormStep}
                              positionName={positionName}
                              setPositionName={setPositionName}
                            />
                            {formStep === 1 ? (
                              <div className={styles.buttonGroup}>
                                <Button
                                  bStyle={["primary", "small"]}
                                  type="button"
                                  onClick={() =>
                                    history.push(
                                      "/" +
                                        team.organization_id +
                                        "/scorecards?name=" +
                                        values.name +
                                        "&teamId=" +
                                        team.id
                                    )
                                  }
                                >
                                  Choose scorecard
                                </Button>
                                <Button
                                  bStyle={["secondary", "small"]}
                                  type="submit"
                                >
                                  Create blank position
                                </Button>
                              </div>
                            ) : (
                              <div className={styles.buttonGroup}>
                                <Button
                                  bStyle={["primary", "small"]}
                                  type="button"
                                  onClick={() => setFormStep(1)}
                                  disabled={
                                    pristine ||
                                    !values.name ||
                                    !selectedTeam ||
                                    selectedTeam === -1
                                  }
                                >
                                  Continue
                                </Button>
                                <Button
                                  bStyle={["secondary", "small"]}
                                  type="button"
                                  onClick={close}
                                >
                                  Cancel
                                </Button>
                              </div>
                            )}
                          </form>
                        </div>
                      );
                    }}
                  />
                </DropDown>
              )}
            </Popup>
          </div>
        </div>
      </div>
    );
  }
);
