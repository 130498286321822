import React, { useEffect } from "react";
import classNames from "classnames";
import Popup from "reactjs-popup";
import Modal from "react-modal";
import { Link } from "react-router-dom";
import queryString from "query-string";
import NewOrganization from "components/Modals/NewOrg";
import Field from "components/UI/Field";
import Icon from "components/UI/Icon";
import Button from "components/UI/Button";
import getAvatarColor from "utils/orgAvatarColor";
import compose from "./compose";
import { SELECT_ORG } from "store/actionTypes";
import styles from "./OrgSelect.module.scss";

export default compose(
  ({
    data: { organizations },
    selectedOrg,
    handleSelectOrg,
    searchValue,
    setSearchValue,
    modal,
    updateModal,
    account,
    location,
    dispatch,
    whitelabel,
  }) => {
    useEffect(() => {
      const params = queryString.parse(location.search);
      if (
        params &&
        params.org_id &&
        organizations.some((org) => org.id === parseInt(params.org_id, 10))
      ) {
        dispatch({
          type: SELECT_ORG,
          payload: organizations.find(
            (org) => org.id === parseInt(params.org_id, 10)
          ),
        });
      }

      if (
        window.localStorage.recentOrg &&
        organizations.some(
          (org) => org.id === parseInt(window.localStorage.recentOrg, 10)
        )
      ) {
        dispatch({
          type: SELECT_ORG,
          payload: organizations.find(
            (org) => org.id === parseInt(window.localStorage.recentOrg, 10)
          ),
        });
      }
    }, []);

    return (
      <div
        className={classNames(
          "dropdownWrapper",
          "orgDropdown",
          whitelabel && "whitelabel"
        )}
        id="dropdownMenu"
      >
        <Popup
          keepTooltipInside="body"
          trigger={(open) => (
            <div
              className={classNames(
                styles.organizationSelect,
                open && styles.open,
                whitelabel && styles.whitelabel
              )}
            >
              {selectedOrg.name !== "" && (
                <div
                  className={classNames(
                    styles.avatar,
                    styles[getAvatarColor(selectedOrg)]
                  )}
                >
                  <h5>{selectedOrg.name.charAt(0).toUpperCase()}</h5>
                </div>
              )}
              <p className={styles.orgName}>
                {selectedOrg.name || "Select organisation"}
              </p>
              {open && selectedOrg.adminRights && (
                <div className={styles.orgSettings}>
                  <Link to={"/org/" + selectedOrg.id + "/settings"}>
                    <Icon icon="Gear" />
                  </Link>
                </div>
              )}
              {open ? (
                <div className={classNames(styles.arrow, styles.up)}>
                  <Icon icon="Chevron" />
                </div>
              ) : (
                <div className={classNames(styles.arrow)}>
                  <Icon icon="Chevron" />
                </div>
              )}
            </div>
          )}
          position="center center"
          arrow={false}
        >
          {(close) => (
            <div
              className={classNames(
                styles.orgDropdown,
                account.adminRights && styles.padding
              )}
            >
              <ul>
                <div className={styles.search}>
                  <Field
                    white
                    small
                    noBorder
                    deleteIcon
                    icon="fas fa-search"
                    autoFocus
                    type="text"
                    name="search"
                    autoComplete="off"
                    value={searchValue}
                    setValue={setSearchValue}
                    placeholder="Search organisations"
                    onChange={(e) => setSearchValue(e.target.value)}
                  />
                </div>
                {organizations &&
                  organizations
                    .filter(
                      (o) =>
                        o.name
                          .toLowerCase()
                          .indexOf(searchValue.toLowerCase()) !== -1 &&
                        o.id !== selectedOrg.id
                    )
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((org, i) => (
                      <li
                        key={`${org.name}-${i}`}
                        className={classNames(
                          selectedOrg.id === org.id && styles.active
                        )}
                      >
                        <Button
                          bStyle={["none"]}
                          type="button"
                          onClick={() => {
                            handleSelectOrg(org.id);
                            close();
                          }}
                        >
                          <div
                            className={classNames(
                              styles.avatar,
                              styles[getAvatarColor(org)]
                            )}
                          >
                            <h5>{org.name.charAt(0).toUpperCase()}</h5>
                          </div>
                          <p className={styles.orgNameInList}>{org.name}</p>
                        </Button>
                      </li>
                    ))}
                {organizations &&
                  organizations.filter(
                    (o) =>
                      o.name
                        .toLowerCase()
                        .indexOf(searchValue.toLowerCase()) !== -1
                  ).length === 0 && (
                    <p className={styles.noOrg}>
                      No organisations matched your search.
                    </p>
                  )}
              </ul>
              {account.adminRights && (
                <>
                  <div className={styles.createNew}>
                    <Button
                      bStyle={["none"]}
                      type="button"
                      onClick={() => {
                        updateModal("newOrg");
                      }}
                    >
                      <i className="far fa-plus" />
                      New organisation
                    </Button>
                  </div>
                  <Modal
                    isOpen={modal === "newOrg" && true}
                    onRequestClose={() => updateModal("")}
                    shouldCloseOnOverlayClick
                    className={classNames(styles.modal, "create")}
                  >
                    <NewOrganization updateModal={updateModal} />
                  </Modal>
                </>
              )}
            </div>
          )}
        </Popup>
      </div>
    );
  }
);
