import { compose, withHandlers } from "recompose";
import { withRouter } from "react-router-dom";
import { withApollo } from "react-apollo";
// import { loader } from 'graphql.macro'
import axios from "utils/axios";
import { logoutRoute } from "client/authRoutes";
import withStore from "utils/withStore";
// const LOGOUT_MUTATION = loader('./LogoutMutation.graphql')

export default compose(
  withRouter,
  withApollo,
  withStore("viewer"),
  withHandlers({
    logOut: ({ history, client, dispatch }) => () => {
      axios.post(logoutRoute, {}, { withCredentials: true }).then(res => {
        history.replace("/login");
        client.resetStore();
        window.localStorage.removeItem("recentOrg");
        dispatch({ type: "USER_LOGOUT" });
        // console.log({res})
      });
    }
  })
);
