import { withHandlers } from "recompose";

export default withHandlers({
  handleRemoveCandidateLinkedin:
    ({
      removeCandidateLinkedin,
      CANDIDATE_QUERY: { candidateInTeam },
      match,
    }) =>
    async (setEditLinkedIn, close) => {
      const type = match.params.candidateType;
      try {
        await removeCandidateLinkedin({
          variables: {
            user_id: type === "candidate" ? candidateInTeam.id : null,
            person_id: type === "person" ? candidateInTeam.id : null,
            position_id: candidateInTeam.position.id,
          },
        });
        setEditLinkedIn(false);
        close();
      } catch (err) {
        throw new Error(err);
      }
    },
});
