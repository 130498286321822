import { withHandlers, compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { graphql } from 'react-apollo'
import { loader } from 'graphql.macro'
import { FORM_ERROR } from 'final-form'

const CHANGE_PASSWORD = loader('./ChangePassword1.graphql')

export default compose(
  withRouter,
  graphql(CHANGE_PASSWORD, { name: 'updatePassword' }),
  withHandlers({
    handleChangePassword:
      ({ updatePassword, location, history }) =>
      async (values) => {
        const { old_password, new_password, repeat_new_password } =
          values
        try {
          await updatePassword({
            variables: {
              old_password,
              new_password,
              repeat_new_password
            }
          })
          if (location.state && location.state.redirect)
            history.push(location.state.redirect)
          history.push('/dashboard')
        } catch (err) {
          if (err.graphQLErrors && err.graphQLErrors[0].message) {
            return { [FORM_ERROR]: err.graphQLErrors[0].message }
          }
        }
      }
  })
)
