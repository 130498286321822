import React from 'react'
import Button from 'components/UI/Button'

import styles from './RemoveMember.module.scss'

export default ({
  member,
  type,
  updateRemoveMemberModal,
  handleRemoveUser
}) => (
  <div className={styles.modalWrapper}>
    <h3 className={styles.title}>
      <i className='fas fa-trash-alt' />
      {member.name
        ? `Are you sure you wish to remove ${member.name} from this ${type}?`
        : `Are you sure you wish to remove ${member.email} from this ${type}?`}
    </h3>
    <p className={styles.subtitle}>
      {member.name
        ? `${member.name} will also be removed from all ${type === 'account' ? 'organisations,' : '' } teams and positions associated with this ${type}.`
        : `${member.email} will also be removed from all ${type === 'account' ? 'organisations,' : '' } teams and positions associated with this ${type}.`}
    </p>
    <div className={styles.buttonGroup}>
      <Button
        bStyle={['primary', 'large', 'red']}
        type='button'
        onClick={() => handleRemoveUser(member)}
      >
        <i className='fas fa-trash-alt' />
        Yes, remove
      </Button>
      <Button
        bStyle={['secondary', 'large']}
        type='button'
        onClick={() => updateRemoveMemberModal(false)}
      >
        No, cancel
      </Button>
    </div>
    <p className={styles.dangerous}>
      <i className='fas fa-exclamation-triangle' />
      All data will be lost and this action cannot be undone.
    </p>
  </div>
)
