import React, { useState } from "react";

import ValuesChart from "components/Charts/Values";
import ValuesModal from "components/Modals/Value";
import TeamMember from "./TeamMember";
import styles from "./Chart.module.scss";

export default ({ team, teamMember }) => {
  const [memberHover, setMemberHover] = useState(teamMember);
  const [openModal, updateModal] = useState({});

  const determineIfValueIsImportant = () => {
    if (!openModal.name) return false;
    return (
      teamMember.values
        .filter((dim) => dim.parent_id)
        .sort((a, b) => b.score - a.score)
        .slice(0, 3)
        .find((d) => d.name === openModal.name) && true
    );
  };

  console.log({ team, teamMember });
  return (
    <div className={styles.wrap}>
      <ValuesChart
        data={team}
        valuesChart={team.valuesChart}
        values={team.values.filter((dim) => dim.parent_id)}
        modal={ValuesModal}
        members={team.members}
        highlight={teamMember}
        isMemberHover={memberHover && memberHover.id}
        updateModal={updateModal}
        resource="member"
        team={team}
      />
      <div className={styles.avatarsWrap}>
        <TeamMember
          teamMember={teamMember}
          memberHover={memberHover}
          isCurrentUser={teamMember}
          setMemberHover={setMemberHover}
        />
      </div>
      <ValuesModal
        resource="member"
        valueGroups={team.valuesChart.valueGroups}
        values={team.values.filter((dim) => dim.parent_id)}
        dim={openModal}
        updateModal={updateModal}
        isImportant={determineIfValueIsImportant()}
        members={team.members}
        highlight={teamMember}
        team={team}
      />
    </div>
  );
};
