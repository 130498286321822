import React from "react";
import Button from "components/UI/Button";
import DistributionBarChart from "components/Charts/DistributionBarChart";

import styles from "../TopValues.module.scss";

export default ({
  teamWorkBest,
  data,
  updateModal,
  getAlignmentColor,
  getAlignmentStyle,
}) => {
  return (
    <>
      {teamWorkBest.map((dim) => {
        return (
          <div className={styles.row} key={dim.name}>
            <div className={styles.column}>
              <div className={styles.titleRow}>
                <h3 className={styles.title}>{dim.name}</h3>
                {data.members.filter((m) => m.valuesChart.readyToRender)
                  .length > 1 && (
                  <span className={getAlignmentStyle(dim.alignment)}>
                    {dim.alignment}
                  </span>
                )}
              </div>
              <p className={styles.subtitle}>{dim.definition}</p>

              <div className={styles.barChartWrapper}>
                <DistributionBarChart
                  dim={dim}
                  members={data.members}
                  resource="values"
                  colour={getAlignmentColor(dim.alignment)}
                />
              </div>
              <div className={styles.extremes}>
                <div className={styles.column}>
                  <h5 className={styles.title}>Less important</h5>
                  <p className={styles.text}>{dim.description_min}</p>
                </div>
                <div className={styles.column}>
                  <h5 className={styles.title}>Very important</h5>
                  <p className={styles.text}>{dim.description_max}</p>
                </div>
              </div>
            </div>
            <div className={styles.column}>
              <div className={styles.card}>
                <div className={styles.content}>
                  <h5 className={styles.title}>
                    <i className="fas fa-user-shield" />
                    Team's attitude
                  </h5>
                  <p className={styles.text}>{dim.valuesStrength}</p>
                </div>
                <div className={styles.footer}>
                  <Button
                    bStyle={["none", "icon"]}
                    type="button"
                    onClick={() => updateModal(dim)}
                  >
                    <i className="far fa-ellipsis-h" />
                    {`More on ${dim.name}`}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};
