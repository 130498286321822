import { withHandlers, compose } from "recompose";
import { withRouter } from "react-router-dom";
import { graphql, withApollo } from "react-apollo";
import { loader } from "graphql.macro";
import withStore from "utils/withStore";
import { SELECT_ORG } from "store/actionTypes";

const CREATE_ORGANIZATION = loader("./createOrganization5.graphql");
const ORGANIZATIONS = loader("client/Queries/OrganizationsQuery21.graphql");

export default compose(
  withRouter,
  withApollo,
  withStore("selectedOrg", "account"),
  graphql(CREATE_ORGANIZATION, {
    name: "createOrganization",
    options: ({ account }) => {
      return {
        update: (proxy, { data: { createOrganization } }) => {
          const data = proxy.readQuery({
            query: ORGANIZATIONS,
            variables: {
              account_id: account.id,
            },
          });
          data.organizations.push(createOrganization);
          proxy.writeQuery({
            query: ORGANIZATIONS,
            variables: {
              account_id: account.id,
            },
            data,
          });
        },
      };
    },
  }),
  withHandlers({
    handleCreateOrganization:
      ({
        createOrganization,
        updateModal,
        dispatch,
        account,
        history,
        location,
      }) =>
      ({ name }) => {
        createOrganization({
          variables: {
            name,
            account_id: account.id,
          },
        }).then((res) => {
          window.Intercom("trackEvent", "create-organization", {
            id: res.data.createOrganization.id,
          });
          dispatch({ type: SELECT_ORG, payload: res.data.createOrganization });
          window.localStorage.setItem(
            "recentOrg",
            res.data.createOrganization.id
          );
          history.push("/dashboard" + location.search);
          updateModal("");
        });
      },
  })
);
