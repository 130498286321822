import React from "react";
import { compose, branch, renderComponent } from "recompose";
import { withRouter } from "react-router-dom";
import { graphql } from "react-apollo";
import { loader } from "graphql.macro";
import FullPage from "components/Loaders/FullPage";
import scrollToTop from "utils/scrollToTop";

const TEAM_QUERY = loader("client/Queries/TeamQuery124.graphql");
const TOOLKITS_QUERY = loader("./toolkits7.graphql");

export default compose(
  withRouter,
  graphql(TEAM_QUERY, {
    name: "TEAM_QUERY",
    options: (props) => {
      return { variables: { id: parseInt(props.match.params.teamId, 10) } };
    },
  }),
  graphql(TOOLKITS_QUERY, {
    name: "TOOLKITS_QUERY",
    options: (props) => {
      return {
        variables: { team_id: parseInt(props.match.params.teamId, 10) },
      };
    },
  }),
  branch(
    (props) => {
      const condition =
        (props.TEAM_QUERY && props.TEAM_QUERY.loading) ||
        (props.TOOLKITS_QUERY && props.TOOLKITS_QUERY.loading);
      return condition;
    },
    renderComponent(() => <FullPage />)
  ),
  scrollToTop
);
