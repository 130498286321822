import { withHandlers } from "recompose";
import serializer from "components/TextEditor/editorSerializer";

export default withHandlers({
  handleSaveSummary:
    ({ closeModal, summary, saveSummary, match }) =>
    () => {
      const serialized = serializer.serialize(summary);
      // if (serialized === '<p></p>') return setEditSummary(false)

      saveSummary({
        variables: {
          id: parseInt(match.params.positionId, 10),
          summary: serialized,
        },
      }).then(() => {});
    },
});
