import React, { useState } from "react";
import classNames from "classnames";
import Popup from "reactjs-popup";
import Icon from "components/UI/Icon";
import Button from "components/UI/Button";
import compose from "./compose";
import styles from "./Share.module.scss";

export default compose(
  ({
    data: { candidatePublicSecret },
    createPublicSecret,
    match: { params },
  }) => {
    const [isCopied, setIsCopied] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const copyToClipboard = (value) => {
      navigator.clipboard.writeText(value);
      setIsCopied(true);
    };

    const handleCreateUrl = async () => {
      setIsLoading(true);

      const { createCandidatePublicSecret } = await createPublicSecret({
        variables: {
          user_id:
            params.candidateType === "candidate"
              ? parseInt(params.candidateId, 10)
              : null,
          person_id:
            params.candidateType === "person"
              ? parseInt(params.candidateId, 10)
              : null,
          teamId: parseInt(params.teamId, 10),
          positionId: parseInt(params.positionId, 10),
          orgId: parseInt(params.orgId, 10),
        },
      });

      setIsLoading(false);

      copyToClipboard(createCandidatePublicSecret);
    };
    return (
      <Popup
        trigger={
          <Button
            bStyle={["verySmall"]}
            type="button"
            id="share-candidate-button"
          >
            <Icon icon="Link" marginRight />
            Share profile
          </Button>
        }
        keepTooltipInside="body"
        position="bottom right"
        arrow={false}
      >
        {(close) => (
          <div className={styles.wrap}>
            {candidatePublicSecret && (
              <div className="smallPopupWrapper">
                <Popup
                  trigger={
                    <div className={styles.copyUrl}>
                      <Button
                        bStyle={["verySmall"]}
                        type="button"
                        onClick={() =>
                          copyToClipboard(
                            `${window.location.origin}/share/candidate/${candidatePublicSecret}`
                          )
                        }
                      >
                        <span className={styles.url}>
                          {`${window.location.origin}/share/candidate/${candidatePublicSecret}`}
                        </span>
                        <Icon icon="Copy" />
                      </Button>
                    </div>
                  }
                  className={styles.popup}
                  position="top center"
                  on="hover"
                  arrow
                  closeOnDocumentClick
                  repositionOnResize
                >
                  <div className={styles.tooltipPopover}>
                    <span>
                      {isCopied ? "Copied to clipboard!" : "Copy link"}
                    </span>
                  </div>
                </Popup>
              </div>
            )}
            <div
              className={classNames(
                styles.row,
                candidatePublicSecret && styles.marginTop
              )}
            >
              <p>Anyone with this link can access this profile</p>
              <div className={styles.right}>
                {!candidatePublicSecret && (
                  <div className={styles.generateLink}>
                    <Button
                      bStyle={["none"]}
                      type="button"
                      onClick={handleCreateUrl}
                      disabled={isLoading}
                    >
                      <Icon icon="Refresh" marginRight />
                      Generate link
                    </Button>
                  </div>
                )}
                <Button bStyle={["none"]} onClick={close}>
                  Close
                </Button>
              </div>
            </div>
          </div>
        )}
      </Popup>
    );
  }
);
