import React, { useEffect } from "react";
import SidebarWrapper from "components/UI/SidebarWrapper";
import Footer from "components/Footer";
import Field from "components/UI/Field";
import Button from "components/UI/Button";
import { useSidebar } from "utils/sidebarProvider";
import compose from "./compose";

import styles from "./General.module.scss";

export default compose(
  ({
    data: { account },
    history,
    handleUpdateAccount,
    name,
    updateName,
    isSaving,
  }) => {
    const { contentContext } = useSidebar();
    const [content, setContent] = contentContext;

    const sidebarContent = [
      {
        items: [
          {
            title: "General",
            action: () => history.push("/account/" + account.id + "/settings"),
            style: "active",
          },
          {
            title: "Members",
            action: () => history.push("/account/" + account.id + "/members"),
            style: "",
          },
          {
            title: "Organisations",
            action: () =>
              history.push("/account/" + account.id + "/organisations"),
            style: "",
          },
          {
            title: "Billing",
            action: () => history.push("/account/" + account.id + "/billing"),
            style: "",
          },
        ],
      },
    ];

    useEffect(() => {
      setContent(sidebarContent);
    }, []);

    return (
      <SidebarWrapper noHeader>
        <div className={styles.wrapper}>
          <div className={styles.topWrapper}>
            <h2 className={styles.title}>Workspace settings</h2>
            <p className={styles.name}>{account.name}</p>
          </div>
          <div className={styles.left}>
            <div className={styles.nameChange}>
              <form autoComplete="off" onSubmit={handleUpdateAccount}>
                <div className={styles.input}>
                  <h5 className={styles.label}>Workspace name</h5>
                  <Field
                    white
                    small
                    type="text"
                    name="name"
                    value={name}
                    onChange={(e) => updateName(e.target.value)}
                  />
                </div>
                <Button bStyle={["primary"]} type="submit" disabled={isSaving}>
                  Save changes
                </Button>
              </form>
            </div>
            <div className={styles.deleteOrg}>
              <h5 className={styles.title}>Wish to delete your workspace?</h5>
              <p>
                Chat with us or send an email to{" "}
                <a href="mailto:support@wisnio.com">support@wisnio.com</a>
              </p>
            </div>
          </div>
        </div>
      </SidebarWrapper>
    );
  }
);
