import React from "react"
import classNames from "classnames"
import UserAvatar from "components/UserAvatar"
import styles from "./Member.module.scss"

export default ({ member, isLoading }) => (
  <div
    className={classNames(
      styles.userWrapper,
      member.label && styles[member.label.style],
      isLoading && styles.isLoading,
      "teamMemberSingle"
    )}
  >
    {member.label && (
      <div className={classNames(styles.label, styles[member.label.style])}>
        {member.label.text}
      </div>
    )}
    <div className={styles.avatar}>
      <UserAvatar
        member={member}
        isInvite={member.isInvite}
        size="medium"
        pointer
      />
    </div>
    <h4>{member.first_name}</h4>
  </div>
)
