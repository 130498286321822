import { withHandlers } from "recompose";

export default withHandlers({
  handleUpsertCandidatePhone:
    ({ upsertCandidatePhone, CANDIDATE_QUERY: { candidateInTeam }, match }) =>
    async (phoneNumber, close = null) => {
      const type = match.params.candidateType;
      try {
        const response = await upsertCandidatePhone({
          variables: {
            user_id: type === "candidate" ? candidateInTeam.id : null,
            person_id: type === "person" ? candidateInTeam.id : null,
            position_id: candidateInTeam.position.id,
            phone: phoneNumber ? phoneNumber : "",
          },
        });
        if (close) {
          close();
        }
      } catch (err) {
        throw new Error(err);
      }
    },
});
