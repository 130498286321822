import React from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import Button from 'components/UI/Button'

import styles from './CompetencyPlaceholder.module.scss'

export default ({ resource, posNotConfed, posId }) => {
  const getText = () => {
    if (resource === 'personal') {
      return 'Please complete the competency survey.'
    }
    if (resource === 'team') {
      return 'Waiting for team members to complete the competency survey.'
    }
    if (resource === 'member') {
      return 'Waiting for team member to complete the competency survey.'
    }
    if (resource === 'candidate' && posNotConfed) {
      return 'Position competencies have not been mapped.'
    }
    if (resource === 'candidate') {
      return 'Waiting for candidate to complete the competency survey.'
    }
    return 'Competency survey not completed.'
  }
  return (
    <div
      className={classNames(
        styles.wrapper,
        resource === 'candidate' && styles.candidate
      )}
    >
      <div className={styles.noComplete}>
        <p>{getText()}</p>
      </div>
      {resource === 'candidate' && posNotConfed && (
        <Link to={`/position/${posId}/mapcompetencies`}>
          <Button bStyle={['primary', 'small']} type='button'>
            <i className='fas fa-plus' />
            Prioritize competencies
          </Button>
        </Link>
      )}
    </div>
  )
}
