import { iconsAndColors } from "..";

export default ({ candidate, viewer, assessmentRows }) => {
  const list = [];

  assessmentRows.map((row) => {
    const index = list.findIndex((al) => al.groupTitle === row.group_title);

    const evaluation = candidate.evaluation
      .filter((ev) => ev.item_id === row.id && ev.evaluatedBy.id === viewer.id)
      .reduce((a, b) => a + b.evaluation, 0);

    const totalEvaluation =
      candidate.evaluation
        .filter((ev) => ev.item_id === row.id)
        .reduce((a, b) => a + b.evaluation, 0) /
      candidate.evaluation.filter((ev) => ev.item_id === row.id).length;

    const evaluationInfo = candidate.evaluation
      .filter((ev) => ev.item_id === row.id)
      .map((e) => ({
        item_id: e.id,
        evaluatedBy: e.evaluatedBy,
        createdAt: e.created_at,
        evaluation: e.evaluation,
        total: totalEvaluation,
        type: e.type,
        comments: e.comments && e.comments.flat().filter(Boolean),
      }));

    if (index !== -1) {
      list[index].items = [
        ...list[index].items,
        {
          id: row.id,
          title: row.title,
          description: row.description,
          evaluation: evaluation,
          totalEvaluation: Math.round(totalEvaluation, 10),
          evaluationInfo: evaluationInfo,
          comments: candidate.comments.filter((c) => c.item_id === row.id),
        },
      ];
    } else {
      list.push({
        groupTitle: row.group_title,
        color: iconsAndColors[row.group_title]
          ? iconsAndColors[row.group_title].color
          : "grey",
        items: [
          {
            id: row.id,
            title: row.title,
            description: row.description,
            evaluation: evaluation,
            totalEvaluation: Math.round(totalEvaluation, 10),
            evaluationInfo: evaluationInfo,
            comments: candidate.comments.filter((c) => c.item_id === row.id),
          },
        ],
      });
    }
  });

  console.log({ list });

  return list;
};
