import React from "react";
import { compose, withState } from "recompose";
import { graphql } from "react-apollo";
import { loader } from "graphql.macro";
import handleSaveTemplate from "./handleSaveTemplate";

const CREATE_TEMPLATE_FROM_POSITION = loader(
  "./createTemplateFromPosition.graphql"
);

export default compose(
  withState("nameValue", "setNameValue", ""),
  withState("descValue", "setDescValue", ""),
  withState("saved", "setSaved", false),
  graphql(CREATE_TEMPLATE_FROM_POSITION, {
    name: "createTemplateFromPosition",
    options: ({ team }) => {
      return {
        refetchQueries: ["POSITION_TEMPLATES"]
      };
    }
  }),
  handleSaveTemplate
);
