import React from "react";
import classNames from "classnames";
import { useSidebar } from "utils/sidebarProvider";

import SecondHeader from "./SecondHeader";

import styles from "./SidebarHeader.module.scss";

export default ({
  title,
  subtitle,
  extrasubtitle,
  extraItems,
  noBreadcrumbs,
  children,
  noSidebar,
  noMargin,
}) => {
  const { collapsedContext } = useSidebar();
  const [collapsed] = collapsedContext;
  const whitelabel = document.whitelabel === "custom" ? true : false;

  const showTitle =
    window.innerWidth < 1550 && whitelabel && collapsed ? false : true;

  return (
    <>
      <div
        className={classNames(
          styles.main,
          noBreadcrumbs && styles.noBreadcrumbs,
          collapsed && styles.collapsed,
          noSidebar && styles.noSidebar,
          noMargin && styles.noMargin
        )}
      >
        <div className={styles.wrapper}>
          {window.innerWidth > 534 && (
            <div className={styles.left}>
              {title && showTitle && <h5 className={styles.title}>{title}</h5>}
              {subtitle && <p className={styles.subtitle}>{subtitle}</p>}
              {extrasubtitle && (
                <p className={styles.subtitle}>{extrasubtitle}</p>
              )}
            </div>
          )}
          <div className={styles.right}>{children}</div>
        </div>
      </div>
      {(extraItems || !noBreadcrumbs) && (
        <div
          className={classNames(
            styles.breadcrumbsMain,
            collapsed && styles.collapsed,
            noSidebar && styles.noSidebar
          )}
        >
          <div className={styles.breadcrumbs}>
            <SecondHeader extraItems={extraItems} />
          </div>
        </div>
      )}
    </>
  );
};
