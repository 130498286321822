import React, { useEffect, useState } from "react";
import Layout from "layouts";

import { typography } from "style/general";

import Button from "components/UI/Button";
import Icon from "components/UI/Icon";

import classNames from "classnames";
import styles from "./styles.module.scss";

import TextEditor from "components/TextEditor";
import deserialize from "utils/deserialize";
import serializer from "components/TextEditor/editorSerializer";
import UserAvatar from "components/UserAvatar";

const ReviewConclusion = ({
  currentStepIndex,
  setCurrentStepIndex,
  data: {
    assessmentReview: {
      id,
      selected_action_id,
      scorecard_actions: scorecardActions,
      conclusion,
      session: { employee_under_review },
    } = {},
  } = {},
  handleUpdateReview,
}) => {
  const [conclusionValue, setConclusionValue] = useState(
    deserialize(conclusion)
  );

  let timeoutId = null;

  const handleTextEditorChange = (value) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      setConclusionValue(value);
    }, 750);
  };

  useEffect(() => {
    handleUpdateReview(id, {
      conclusion: serializer.serialize(conclusionValue),
    });
  }, [conclusionValue]);

  const handleSubmit = () => {
    handleUpdateReview(id, {
      status: "COMPLETED",
    });
    setCurrentStepIndex(currentStepIndex + 1);
  };

  return (
    <div className={styles.reviewConclusion}>
      <Layout
        variant="2-col-page"
        title="Requested action"
        icon="360Assesment"
        rightColContent={
          <div className={styles.rightColContentStyle}>
            <div>
              <UserAvatar member={employee_under_review} size="small" />
            </div>

            <h4>{employee_under_review.name}</h4>
          </div>
        }
      >
        <p style={{ ...typography.body, ...typography.size18 }}>
          Please select the action requested at this time. Add your notes to
          help guide the development process.
        </p>
      </Layout>
      <div className={styles.mainDivStyle}>
        <div>
          {scorecardActions &&
            scorecardActions.map((option) => (
              <div
                key={"OptionKey-" + option.id}
                className={classNames(
                  styles.option,
                  selected_action_id === option.id && styles.selected
                )}
              >
                <input
                  type="radio"
                  id={"option-" + option.id}
                  name={option.action}
                  value={option.id}
                  checked={selected_action_id === option.id}
                  onChange={() => {
                    handleUpdateReview(id, {
                      selected_action_id: option.id,
                    });
                  }}
                ></input>
                <label htmlFor={"option-" + option.id}>{option.title}</label>
              </div>
            ))}
        </div>
        <div className={styles.conclusionTextbox}>
          <TextEditor
            value={conclusionValue}
            handleChange={handleTextEditorChange}
          />
        </div>
        <div>
          <Button
            bStyle={["verySmall", "icon", "primary"]}
            onClick={handleSubmit}
          >
            <Icon icon="Check" />
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ReviewConclusion;
