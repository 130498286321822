import React from "react";
import ReactMarkdown from "react-markdown";
import ViewerAvatar from "components/ViewerAvatar";
import MessageLoading from "components/UI/MessageLoading";
import styles from "./ChatInterface.module.scss";
import classNames from "classnames";
import Icon from "components/UI/Icon";
import Button from "components/UI/Button/index";

export default ({ role, isLoading, content }) => {
  if (role === "assistant") {
    return (
      <div className={classNames("message", styles.messageWrap)}>
        <div className={styles.message}>
          {isLoading && content.length < 1 ? (
            <MessageLoading />
          ) : (
            <ReactMarkdown>{content}</ReactMarkdown>
          )}
        </div>
      </div>
    );
  }

  if (role === "user") {
    return (
      <div className={classNames("message", styles.messageWrap, styles.user)}>
        <div className={styles.message}>
          <ReactMarkdown>{content}</ReactMarkdown>
        </div>
      </div>
    );
  }
};
