import React from 'react'
import Button from 'components/UI/Button'
import UserAvatar from 'components/UserAvatar'
import compose from './compose'

import styles from '../Billing.module.scss'

export default compose(
  ({
    isOwner,
    createCheckoutSession,
    createCustomerPortalSession,
    subscription,
    viewer,
    setSubscribeModal
  }) => {
    
    if (subscription.type === 'stripe') {
      if (viewer.id === subscription.billingUser.id) {
        return (
          <div>
            <Button
              bStyle={['primary', 'small']}
              type='button'
              onClick={createCustomerPortalSession}
            >
              Manage subscription
            </Button>
          </div>
        )
      }
      return (
        <div className={styles.owner}>
          <div className={styles.user}>
            <div className={styles.avatar}>
              <UserAvatar member={subscription.billingUser} size='small' />
            </div>
            <div className={styles.info}>
              <h5 className={styles.name}>{subscription.billingUser.name}</h5>
              <p className={styles.email}>
                <a href={`mailto:${subscription.billingUser.email}`}>
                  {subscription.billingUser.email}
                </a>
              </p>
            </div>
          </div>
          <Button
            bStyle={['none']}
            type='button'
            onClick={() => window.Intercom('show')}
          >
            <p>or contact sales</p>
          </Button>
        </div>
      )
    }
    if (subscription.type === 'custom') {
      return (
        <div>
          <Button
            bStyle={['primary', 'small']}
            type='button'
            onClick={() => window.Intercom('show')}
          >
            Contact us
          </Button>
        </div>
      )
    }
    return (
      <React.Fragment>
        {isOwner ? (
          <div className={styles.col}>
            <Button
              bStyle={['primary', 'small']}
              type='button'
              onClick={() => setSubscribeModal(true)}
            >
              Subscribe
            </Button>
            <Button
              bStyle={['none']}
              type='button'
              onClick={() => window.Intercom('show')}
            >
              <p>or contact sales for pricing</p>
            </Button>
          </div>
        ) : (
          <div className={styles.owner}>
            <div className={styles.user}>
              <div className={styles.avatar}>
                <UserAvatar member={subscription.billingUser} size='small' />
              </div>
              <div className={styles.info}>
                <h5 className={styles.name}>{subscription.billingUser.name}</h5>
                <p className={styles.email}>
                  <a href={`mailto:${subscription.billingUser.email}`}>
                    {subscription.billingUser.email}
                  </a>
                </p>
              </div>
            </div>
            <Button
              bStyle={['none']}
              type='button'
              onClick={() => window.Intercom('show')}
            >
              <p>or contact sales</p>
            </Button>
          </div>
        )}
      </React.Fragment>
    )
  }
)
