import { withHandlers } from 'recompose'

export default withHandlers({
  handleRemoveUser: ({
    removeFromAccount,
    match
  }) => async (resource) => {

    const { data: { removeFromAccount: { keys } } } = await removeFromAccount({
      variables: {
        resourceType: resource.type,
        resourceId: resource.id,
        accountId: parseInt(match.params.accountId, 10)
      }
    })
  }
})
