import {
  compose, withState, withProps, withHandlers
} from 'recompose'

export default compose(
  withProps(({ survey }) => ({
    blocks: [...new Set(survey.survey_rows.map(r => r.row_block_id))]
  })),
  withState('modal', 'updateModal', ''),
  withState('currentBlock', 'setCurrentBlock', 1),
  withState('answers', 'setAnswers', []),
  withState('columns', 'setColumns', ({ survey, currentBlock }) => {
    const cards = [...Array(10)].map((a, i) => ({
      obj_type_id: i,
      subject_type_id: null,
      subject_id: null,
      survey_row_id: null,
      question_dim_id: null,
      answer: null
    }))
    return {
      source: survey.survey_rows.filter(
        row => row.row_block_id === currentBlock
      ),
      selection: cards
    }
  }),
  withState(
    'blockItemsLength',
    'setBlockItemsLength',
    ({ columns: { source } }) => source.length
  ),
  withHandlers({
    handleFinish: ({ answers, handleSave }) => () => handleSave(answers)
  }),
  withHandlers({
    handleSetNextblock: ({
      handleFinish,
      blocks,
      setCurrentBlock,
      currentBlock,
      setBlockItemsLength,
      columns,
      setColumns,
      survey,
      answers,
      setAnswers
    }) => () => {
      columns.selection
        .map((row, index) => {
          if (!row.survey_row_id) return null
          return {
            obj_type_id: 2,
            subject_type_id: 2,
            subject_id: null,
            survey_row_id: row.survey_row_id,
            question_dim_id: row.question_dim_id,
            answer:
              (columns.selection.length - index)
              * (100 / columns.selection.length)
          }
        })
        .filter(Boolean)
        .forEach(answer => answers.push(answer))
      setAnswers(answers)
      const rows = survey.survey_rows.filter(
        row => row.row_block_id === currentBlock + 1
      )

      setCurrentBlock(currentBlock + 1)
      setBlockItemsLength(rows.length)
      setColumns({
        source: rows,
        selection: [
          ...Array(10)
            .fill()
            .map((r, i) => ({
              obj_type_id: i,
              subject_type_id: null,
              subject_id: null,
              survey_row_id: null,
              question_dim_id: null,
              answer: null
            }))
        ]
      })
      if (currentBlock === blocks.length) {
        handleFinish()
      }
    }
  })
)
