import React from "react";
import classNames from "classnames";
import UserAvatar from "components/UserAvatar";
import Icon from "components/UI/Icon";
import Button from "components/UI/Button";
import { ReactComponent as OnboardImg } from "images/toolkits/Onboard.svg";
import { ReactComponent as SurveyIntroImg } from "images/toolkits/SurveyIntro.svg";
import compose from "./compose";

import styles from "./TeamEffectivenessSurvey.module.scss";

export default compose(
  ({
    match: { params },
    history,
    location,
    viewer,
    data: { teamPlay: play },
    handleSendInviteEmail,
    remindLoading,
  }) => {
    const player = play.players.find((player) => player.id === viewer.id);

    const completedPlayers = play.players.filter((player2) =>
      player2.effectiveness.some((e) => e.score == null)
    );

    const hasViewerTakenSurvey =
      player && !player.effectiveness.some((e) => e.score === null);

    return (
      <div className={styles.wrapper}>
        <p className={styles.subtitle}>
          Complete your evaluation and send reminders to participants who have
          not done it yet.
        </p>
        <div className={styles.peopleInvited}>
          <p className={styles.title}>
            {play.players.length + " people invited"}
          </p>
          {play.players.length > 0 && (
            <div className={styles.avatars}>
              {play.players.map((player, i) => (
                <div key={`avatar-${i}`} className={styles.avatar}>
                  <UserAvatar member={player} size="mediumSmall" showName />
                </div>
              ))}
            </div>
          )}
        </div>
        <div className={styles.actions}>
          {player && (
            <div className={styles.action}>
              <div className={styles.img}>
                <OnboardImg />
              </div>
              <div className={styles.right}>
                <h5 className={styles.title}>
                  {hasViewerTakenSurvey
                    ? "If you'd like, you can retake the survey."
                    : "Take the survey"}
                </h5>
                <p className={styles.subtitle}>
                  {hasViewerTakenSurvey
                    ? "It takes ~15 minutes."
                    : "You've been invited to participate in a Wisnio Team Effectiveness Evaluation. The survey takes ~15 minutes."}
                </p>
                <Button
                  bStyle={["primary", "small"]}
                  onClick={() => {
                    history.push("/survey/32", {
                      from: location.pathname,
                      params: {
                        ...params,
                        obj_type_id: 6,
                        obj_id: player.player_id,
                      },
                    });
                  }}
                >
                  {hasViewerTakenSurvey
                    ? "Retake the survey"
                    : "Take the survey"}
                </Button>
              </div>
            </div>
          )}
          <div className={styles.action}>
            <div className={styles.img}>
              <SurveyIntroImg />
            </div>
            <div className={styles.right}>
              <h5 className={styles.title}>Send reminders</h5>
              <p className={styles.subtitle}>
                Send a reminder email to team members who have not yet completed
                their surveys.
              </p>
              <Button
                onClick={handleSendInviteEmail}
                bStyle={["secondary", "small"]}
                disabled={remindLoading !== ""}
              >
                {remindLoading === "" ? "Send reminders" : remindLoading}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
);
