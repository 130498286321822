import Icon from "components/UI/Icon/index";
import React from "react";
import styles from "./styles.module.scss";
import classNames from "classnames";

const Layout = ({
  title,
  description,
  children,
  variant,
  rightColContent,
  headerRight,
  icon,
  noMargin,
}) => {
  const layoutClasses = classNames(styles.layout, {
    [styles.noMargin]: noMargin,
  });

  if (variant === "2-col-header") {
    return (
      <div className={layoutClasses}>
        <div
          className={`${styles.layout__column} ${styles.layout__twoColHeader}`}
        >
          <div className={styles.layout__column}>
            <div className={styles.layout__title}>
              {title && (
                <div className={styles.layout__title}>
                  <span className={styles.layout__icon}>
                    {icon && <Icon icon={icon} large />}
                  </span>
                  <h2>{title}</h2>
                </div>
              )}
              <div className={styles.layout__headerRight}>{headerRight}</div>
            </div>
            <div className={styles.layout__column}>{children}</div>
          </div>
        </div>
      </div>
    );
  }

  if (variant === "2-col-page") {
    return (
      <div className={`${layoutClasses} ${styles.layout__twoColPage}`}>
        <div className={`${styles.layout__twoColPage__left}`}>
          <div className={styles.layout__column} id="test">
            {title && (
              <div className={styles.layout__title}>
                <span className={styles.layout__icon}>
                  <Icon icon="360Assesment" large />
                </span>
                <h2>{title}</h2>
              </div>
            )}

            <div>{children}</div>
          </div>
        </div>
        <div className={`${styles.layout__twoColPage__right}`}>
          {rightColContent}
        </div>
      </div>
    );
  }

  return (
    <div className={`${layoutClasses} ${styles.layout__default}`}>
      <div className={styles.layout__column}>
        {title && (
          <div className={styles.layout__titleWrapper}>
            <div className={styles.layout__title}>
              <span className={styles.layout__icon}>
                <Icon icon={icon} large />
              </span>
              <h2>{title}</h2>
            </div>
            {description && <p className={styles.description}>{description}</p>}
          </div>
        )}
      </div>
      <div>{children}</div>
    </div>
  );
};
export default Layout;
