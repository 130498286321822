import React from 'react'
import ViewerAvatar from 'components/ViewerAvatar'
import UserAvatar from 'components/UserAvatar'
import Button from 'components/UI/Button'

import styles from '../TopValues.module.scss'

export default ({
  personalWorkBest,
  updateModal,
  resource,
  user
}) => {
  return (
    <>
      {personalWorkBest.map((dim) => (
        <div className={styles.row} key={dim.name}>
          <div className={styles.column}>
            <h3 className={styles.title}>{dim.name}</h3>
            <p className={styles.subtitle}>{dim.definition}</p>
            <div className={styles.lineWrapper}>
              <div className={styles.line}>
                <div
                  className={styles.avatar}
                  style={{
                    left: `calc(${
                      dim.score > 93.12 ? 93.12 : dim.score
                    }% - 25px)`
                  }}
                >
                  {resource === 'orgUser' ? (
                    <UserAvatar member={user} size="small" />
                  ) : (
                    <ViewerAvatar size="small" />
                  )}
                </div>
              </div>
            </div>
            <div className={styles.extremes}>
              <div className={styles.column}>
                <h5 className={styles.title}>Less important</h5>
                <p className={styles.text}>{dim.description_min}</p>
              </div>
              <div className={styles.column}>
                <h5 className={styles.title}>Very important</h5>
                <p className={styles.text}>{dim.description_max}</p>
              </div>
            </div>
          </div>
          <div className={styles.column}>
            <div className={styles.card}>
              <div className={styles.content}>
                <h5 className={styles.title}>
                  <i className="fas fa-user-shield" />
                  Your attitude
                </h5>
                <p className={styles.text}>{dim.valuesStrength}</p>
              </div>
              <div className={styles.footer}>
                <Button
                  bStyle={['none', 'icon']}
                  type="button"
                  onClick={() => updateModal(dim)}
                >
                  <i className="far fa-ellipsis-h" />
                  {`More on ${dim.name}`}
                </Button>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  )
}
