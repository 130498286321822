import React from "react";
import Button from "components/UI/Button";
import compose from "./compose";

import styles from "./RemoveDemoDataModal.module.scss";

export default compose(
  ({ organization, updateRemoveDataModal, handleRemoveDemoData }) => (
    <div className={styles.modalWrapper}>
      <h3 className={styles.title}>
        <i className="fas fa-trash-alt" />
        Are you sure you wish to delete all demo data?
      </h3>
      <p className={styles.subtitle}>
        This action will delete the demo organisation and any teams and
        positions you have created within it.
        <br />
        Your Personal Profile <strong>will not be deleted</strong>.
      </p>
      <div className={styles.buttonGroup}>
        <Button
          bStyle={["primary", "large", "red"]}
          type="button"
          onClick={handleRemoveDemoData}
        >
          <i className="fas fa-trash-alt" />
          Yes, remove
        </Button>
        <Button
          bStyle={["secondary", "large"]}
          type="button"
          onClick={() => updateRemoveDataModal(false)}
        >
          No, cancel
        </Button>
      </div>
      <p className={styles.dangerous}>
        <i className="fas fa-exclamation-triangle" />
        All demo data will be lost and this action cannot be undone.
      </p>
    </div>
  )
);
