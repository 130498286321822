import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import Button from "components/UI/Button";
import Icon from "components/UI/Icon";
import compose from "./compose";

import styles from "./Survey.module.scss";

export default compose(
  ({
    data: { surveys },
    complete,
    title,
    text,
    button,
    color,
    image,
    history,
    handleNextStep,
  }) => {
    useEffect(() => {
      if(!surveys.some(survey => !survey.complete)) {
        window.Intercom('update', { 'profile-complete': true })
      }
    }, [surveys])
    return (
      <div className={styles.wrapper}>
        <div className={styles.left}>
          <h1 className={styles.title}>Complete your Personal Profile</h1>
          <p className={styles.subtitle}>All Wisnio profiles are based on the analysed results of three surveys on personality, values, and competencies. <br/> <br/> Let’s get your Personal Profile set up - continue by taking the first survey!</p>
          <div className={styles.list}>
            {surveys.map(survey => (
              <div
                key={"Survey-" + survey.id}
                className={classNames(
                  styles.card,
                  styles.active,
                  survey.complete && styles.complete
                )}
              >
                <div
                  className={styles.link}
                  onClick={() => history.push({pathname: `/survey/${survey.id}`, state: { from: '/surveys' }})}
                >
                  <div className={styles.cardLeft}>
                    <div className={styles.icon}>
                      {survey.complete ? (
                        <Icon icon="Check" />
                      ) : (
                        <Icon icon={survey.icon} />
                      )}
                    </div>
                    <h5>{survey.type}</h5>
                  </div>
                  <div className={styles.cardRight}>
                    <p className={styles.time}>{survey.duration}</p>
                    <Icon icon="Chevron" />
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className={styles.buttons}>
            <Button
              bStyle={["primary", "small"]}
              onClick={() => {
                if (surveys.some(s => !s.complete)) {
                  return history.push(`/survey/${surveys.find(s => !s.complete).id}`);
                } else { history.push(`/personal`) }
              }}
            >
              {complete ? 'Personal profile': 'Continue'}
            </Button>

          </div>
        </div>
        <div className={classNames(styles.right, styles.green)}>
          <div className={styles.circle}></div>
          <div className={styles.img}>
            <img src='https://storage.googleapis.com/iaeqpsxmmk/onboarding_images/onboarding_5.png' />
          </div>
        </div>
      </div>
    );
  }
);
