import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { ReactComponent as Home } from "images/icons/home.svg";
import Button from "components/UI/Button";
import compose from "./compose";
import TeamName from "./Data/teamName";
import MemberName from "./Data/memberName";
import PositionName from "./Data/positionName";
import CandidateName from "./Data/candidateName";
import ViewerName from "./Data/viewerName";
import OrgUserName from "./Data/orgUserName";
import ProfileComparisonName from "./Data/profileComparisonName";

import styles from "./SecondHeader.module.scss";

export default compose(({ match, extraItems }) => {
  const team = () => {
    if (match.path.includes("team")) {
      return (
        <React.Fragment>
          <div className={styles.arrow}>/</div>
          <Link
            className={styles.pageDataWrapper}
            to={`/team/${match.params.teamId}`}
          >
            <div className={styles.location}>
              <p className={styles.pageData}>
                <TeamName id={match.params.teamId} />
              </p>
            </div>
          </Link>
        </React.Fragment>
      );
    }
    return null;
  };
  const member = () => {
    if (match.path.includes("member") && !match.path.includes("members")) {
      return (
        <React.Fragment>
          <div className={styles.arrow}>/</div>
          <Link
            className={styles.pageDataWrapper}
            to={`/team/${match.params.teamId}/member/${match.params.memberId}`}
          >
            <div className={styles.location}>
              <p className={styles.pageData}>
                <MemberName id={match.params.memberId} />
              </p>
            </div>
          </Link>
        </React.Fragment>
      );
    }
    return null;
  };
  const position = () => {
    if (match.path.includes("position")) {
      return (
        <React.Fragment>
          <div className={styles.arrow}>/</div>
          <Link
            className={styles.pageDataWrapper}
            to={`/team/${match.params.teamId}/position/${match.params.positionId}`}
          >
            <div className={styles.location}>
              <p className={styles.pageData}>
                <PositionName id={match.params.positionId} />
              </p>
            </div>
          </Link>
        </React.Fragment>
      );
    }
    return null;
  };
  const candidate = () => {
    if (match.path.includes("candidate")) {
      return (
        <React.Fragment>
          <div className={styles.arrow}>/</div>
          <Link
            className={classNames(styles.pageDataWrapper, styles.candidate)}
            to={`/${match.params.orgId}/team/${match.params.teamId}/position/${match.params.positionId}/candidate/${match.params.candidateId}`}
          >
            <div className={styles.location}>
              <p className={styles.pageData}>
                <CandidateName id={match.params.positionId} />
              </p>
            </div>
          </Link>
        </React.Fragment>
      );
    }
    return null;
  };
  const interviewGuide = () => {
    if (match.path.includes("interviewguide")) {
      return (
        <React.Fragment>
          <div className={styles.arrow}>/</div>
          <div className={styles.location}>
            <p className={styles.pageData}>Evaluation</p>
          </div>
        </React.Fragment>
      );
    }
    return null;
  };
  const personal = () => {
    if (match.path.includes("personal")) {
      return (
        <React.Fragment>
          <div className={styles.arrow}>/</div>
          <Link className={styles.pageDataWrapper} to="/personal">
            <div className={styles.location}>
              <p className={styles.pageData}>
                <ViewerName />
              </p>
            </div>
          </Link>
        </React.Fragment>
      );
    }
    return null;
  };
  const accountSettings = () => {
    if (match.path.includes("settings") && match.path.includes("account")) {
      return (
        <React.Fragment>
          <div className={styles.arrow}>/</div>
          <Link className={styles.pageDataWrapper} to="/settings">
            <div className={styles.location}>
              <p className={styles.pageData}>Workspace settings</p>
            </div>
          </Link>
        </React.Fragment>
      );
    }
    return null;
  };
  const orgSettings = () => {
    if (
      match.path.includes("org") &&
      match.path.includes("settings") &&
      !match.path.includes("organisations")
    ) {
      return (
        <React.Fragment>
          <div className={styles.arrow}>/</div>
          <Link className={styles.pageDataWrapper} to="#">
            <div className={styles.location}>
              <p className={styles.pageData}>Organisation settings</p>
            </div>
          </Link>
        </React.Fragment>
      );
    }
    return null;
  };
  const orgMember = () => {
    if (match.path.includes("org") && match.path.includes("user")) {
      return (
        <React.Fragment>
          <div className={styles.arrow}>/</div>
          <Link className={styles.pageDataWrapper} to="#">
            <div className={styles.location}>
              <p className={styles.pageData}>
                <OrgUserName />
              </p>
            </div>
          </Link>
        </React.Fragment>
      );
    }
    return null;
  };
  const profileComparison = () => {
    if (match.path.includes("profileComparison")) {
      return (
        <React.Fragment>
          <div className={styles.arrow}>/</div>
          <Link
            className={styles.pageDataWrapper}
            to={`/profileCompariosn/${match.params.comparisonSecret}`}
          >
            <div className={styles.location}>
              <p className={styles.pageData}>
                <ProfileComparisonName
                  secret={match.params.comparisonProfile}
                />
              </p>
            </div>
          </Link>
        </React.Fragment>
      );
    }
    return null;
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.breadcrumbs}>
        <Link to="/dashboard">
          <Button bStyle={["none"]} type="button">
            <Home />
          </Button>
        </Link>
        {personal()}
        {team()}
        {position()}
        {candidate()}
        {interviewGuide()}
        {member()}
        {orgMember()}
        {accountSettings()}
        {orgSettings()}
        {profileComparison()}
      </div>
      <div className={styles.extraItems}>{extraItems}</div>
    </div>
  );
});
