import React from "react";
import TextareaAutosize from "react-autosize-textarea";
import Multiselect from "multiselect-react-dropdown";
import Icon from "components/UI/Icon";
import Button from "components/UI/Button";

import styles from "./AttachmentForm.module.scss";

export default ({
  description,
  setDescription,
  selectedTags,
  setSelectedTags,
  handleUploadAttachment,
}) => {
  const options = [
    { name: "CV", id: "cv" },
    { name: "Interview notes", id: "interview_notes" },
  ];

  const handleSelectTag = (selectedList, selectedItem) => {
    console.log({ selectedItem });
    let tags = selectedList;

    // selectedList.push(selectedItem);

    setSelectedTags(selectedList);
  };

  const handleRemoveTag = (selectedList, selectedItem) => {
    let tags = [...selectedList];

    // selectedList.filter((t) => t !== selectedItem);

    setSelectedTags(selectedList);
  };

  return (
    <div className={styles.attachmentForm}>
      <div className={styles.inputGroup}>
        <h5 className={styles.inputTitle}>Add tag(s)</h5>
        <p className={styles.inputDescription}>
          Add tags to give WisGPT a clear idea of what the uploaded file is
          about
        </p>
        <Multiselect
          className={styles.multiselect}
          options={options}
          selectedValues={selectedTags} // Preselected value to persist in dropdown
          onSelect={handleSelectTag} // Function will trigger on select event
          onRemove={handleRemoveTag} // Function will trigger on remove event
          customCloseIcon={<Icon icon="Close" />}
          placeholder="Select tags"
          avoidHighlightFirstOption={true}
          displayValue="name" // Property name to display in the dropdown options
          style={{
            multiselectContainer: {
              // To change css for multiselect (Width,height,etc..)
              height: "50px",
            },
            searchBox: {
              // To change search box element look
              border: "1px solid #e4e6e8",
              fontSize: "14px",
              padding: "5px 10px",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              maxHeight: "50px",
            },
            inputField: {
              // To change input field position or margin
              fontSize: "14px",
              lineHeight: "14px",
              ":placeholder": {
                fontSize: "14px",
                lineHeight: "14px",
              },
            },
            optionContainer: {
              // To change css for option container
              fontSize: "14px",
            },
            option: {
              // To change css for dropdown options
              fontSize: "15px",
            },
            chips: {
              // To change css chips(Selected options)
              color: "#454754",
              background: "#EDEDED",
              borderRadius: "35px",
              fontWeight: "300",
              padding: "4px 12px",
              marginBottom: "0",
            },
          }}
        />
      </div>
      <div className={styles.inputGroup}>
        <h5 className={styles.inputTitle}>Description</h5>
        <p className={styles.inputDescription}>Describe the file shortly</p>
        <TextareaAutosize
          maxRows={6}
          name="description"
          placeholder="Add a short description"
          className={styles.textarea}
          rows={2}
          onChange={(e) => setDescription(e.target.value)}
          value={description}
          data-gramm_editor="false"
        />
      </div>
      <div className={styles.buttonGroup}>
        <Button bStyle={["primary", "small"]} onClick={handleUploadAttachment}>
          Upload file
        </Button>
      </div>
    </div>
  );
};
