import React, { useState } from "react";

import classNames from "classnames";

import UserAvatar from "components/UserAvatar";

import Icon from "components/UI/Icon";

import KeyFigure from "./KeyFigure";

import File from "./File";
import styles from "./AdminSection.module.scss";
import DOMPurify from "dompurify";
import { authAttachment } from "client/authRoutes";
import axios from "utils/axios";

const getContentHtml = (content) => {
  return { __html: DOMPurify.sanitize(content) };
};

export default ({ candidate, secret }) => {
  const [showAttachmentTooltip, setShowAttachmentTooltip] = useState(null);
  const handleAttachmentDownload = async ({ id, file_name }) => {
    const {
      data: { attachmentReadSignedUrl },
    } = await axios({
      method: "get",
      url: `${authAttachment}/${secret}`,
      params: {
        file_name,
        id,
        secret,
      },
    });

    const link = document.createElement("a");
    link.href = attachmentReadSignedUrl;
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  return (
    <div className={styles.adminSection}>
      <div className={styles.user}>
        <div className={styles.left}>
          <div className={styles.avatarWrapper}>
            <UserAvatar member={candidate} size="almostLarge" />
          </div>
          <div className={styles.info}>
            <p className={styles.position}>{candidate.position.name}</p>
            <h2 className={styles.name}>{candidate.name}</h2>
            {candidate.description && candidate.description.short && (
              <h4 className={styles.short}>{candidate.description.short}</h4>
            )}
            {!candidate.readyToRender && (
              <p className={styles.incomplete}>Profile incomplete</p>
            )}
          </div>
        </div>
        <div className={styles.right}>
          <div className={styles.buttonGroup}>
            <div className={styles.left}>
              <div className={classNames(styles.button)}>
                <a
                  target="_blank"
                  href={`mailto:${candidate.email}`}
                  className={styles.row}
                >
                  <Icon icon="Mail" marginRight />
                  {candidate.email}
                </a>
              </div>
              {candidate.phone && (
                <div className={classNames(styles.button, candidate.phone)}>
                  <div className={styles.row}>
                    <Icon icon="Phone" marginRight />
                    {candidate.phone}
                  </div>
                </div>
              )}
              {candidate.linkedIn && (
                <a
                  target="_blank"
                  href={candidate.linkedIn}
                  className={styles.row}
                >
                  <Icon icon="LinkedIn" marginRight />
                  LinkedIn
                </a>
              )}
            </div>

            {candidate.attachments.length > 0 && (
              <div className={styles.right}>
                <div className={styles.attachmentsHeading}>
                  <Icon icon="Clipboard" marginRight />
                  <p>Attachments</p>
                </div>
                <div className={styles.attachmentsWrapper}>
                  <div className={styles.attachments}>
                    {[...candidate.attachments].reverse().map((file, i) => (
                      <File
                        key={`attachment-${file.id}-${i}`}
                        file={file}
                        handleAttachmentDownload={(file) =>
                          handleAttachmentDownload(file)
                        }
                        setShowTooltip={setShowAttachmentTooltip}
                      />
                    ))}
                  </div>
                  {[...candidate.attachments].map((attachment, i) => (
                    <div
                      data-id={attachment.id}
                      key={`tooltip-${attachment.id}-${i}`}
                      className={classNames(
                        styles.tooltip,
                        showAttachmentTooltip === attachment.id && styles.show
                      )}
                    >
                      {attachment.file_name}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        className={classNames(styles.summary)}
        dangerouslySetInnerHTML={getContentHtml(candidate.candidateSummary)}
      />

      {!!candidate.keyHighlights.length && (
        <div className={styles.section}>
          <div className={styles.sectionHeader}>
            <h5 className={styles.title}>Key highlights</h5>
            <p className={styles.subtitle}>Key highlights added by you</p>
          </div>
          <div className={styles.cards}>
            {candidate.keyHighlights.map((highlight) => (
              <div className={styles.card} key={"KeyHighlight-" + highlight.id}>
                <div className={styles.bottom}>
                  <div className={styles.title}>{highlight.title}</div>
                  <div className={styles.desc}>{highlight.description}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {!!candidate.notes.length && (
        <div className={styles.section}>
          <div className={styles.sectionHeader}>
            <h5 className={styles.title}>Notes</h5>
            <p className={styles.subtitle}>
              Additional notes and comments about the candidate
            </p>
          </div>
          <div className={styles.notesWrapper}>
            <div className={styles.notes}>
              {candidate.notes.map((note) => (
                <div key={"Note-" + note.id} className={styles.note}>
                  <div className={styles.left}>
                    <div className={styles.avatar}>
                      {note.createdBy && (
                        <UserAvatar member={note.createdBy} size="small" />
                      )}
                    </div>
                    <div className={styles.comment}>
                      <p>{note.text}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
