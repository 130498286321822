import React from 'react'
import Button from 'components/UI/Button'

import styles from './ConfirmationModal.module.scss'

export default ({
  confirmModal,
  setConfirmModal,
  setRemoveModal,
  removeCandidateFromOrganization
}) => (
  <div className={styles.modalWrapper}>
    <Button bStyle='close' type='button' onClick={() => setConfirmModal(false)}>
      <i className='fal fa-times' />
    </Button>
    <h2 className={styles.title}>
      <i className='fas fa-trash-alt' />
      {`Are you sure you wish to remove ${confirmModal.name
        || confirmModal.email} from this organization?`}
    </h2>
    <p className={styles.subtitle}>
      {`${confirmModal.name
        || confirmModal.email} will also be removed from all teams and positions associated with this organization.`}
    </p>
    <div className={styles.buttonGroup}>
      <Button
        bStyle={['primary', 'large', 'red']}
        type='button'
        onClick={() => removeCandidateFromOrganization({
          variables: {
            respurceType: confirmModal.type,
            resourceId: confirmModal.id,
            orgId: confirmModal.orgId
          }
        }).then((res) => {
          setConfirmModal(false)
          setRemoveModal(false)
        })
        }
      >
        <i className='fas fa-trash-alt' />
        Yes, remove
      </Button>
      <Button
        bStyle={['secondary', 'large']}
        type='button'
        onClick={() => setConfirmModal(false)}
      >
        No, cancel
      </Button>
    </div>
    <p className={styles.dangerous}>
      <i className='fas fa-exclamation-triangle' />
      All data will be lost and this action cannot be undone.
    </p>
  </div>
)
