import React from "react";
import classNames from "classnames";

import styles from "./Label.module.scss";

export default ({ size, color, type, children }) => (
  <div
    className={classNames(
      styles.label,
      styles[size],
      styles[color],
      styles[type]
    )}
  >
    {children}
  </div>
);
