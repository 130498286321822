export default ({ cache, keys, orgId, proxy }) => {
  const teams = Object.keys(cache.data).filter(
    (key) => key.match(/^TeamType:([0-9]+)$/) !== null
  );
  const positions = Object.keys(cache.data).filter(
    (key) => key.match(/^PositionType:([0-9]+)$/) !== null
  );

  keys.map((key) => {
    if (key.match(/^Invite:([0-9]+)$/)) {
      teams.forEach((team) => {
        const cachedTeam = cache.data[team];
        const condition =
          Object.keys(cachedTeam).includes("pendingInvites") &&
          cachedTeam.pendingInvites.some((invite) => invite.id === key);
        if (condition) {
          cache.data[team].pendingInvites = cache.data[
            team
          ].pendingInvites.filter((invite) => invite.id !== key);
        }
      });

      positions.forEach((position) => {
        const cachedPosition = cache.data[position];
        const condition =
          Object.keys(cachedPosition).includes("pendingInvites") &&
          cachedPosition.pendingInvites.some((invite) => invite.id === key);

        if (condition) {
          cache.data[position].pendingInvites = cache.data[
            position
          ].pendingInvites.filter((invite) => invite.id !== key);
        }
      });
    }
    if (key.match(/^UserType:([0-9]+)$/)) {
      teams.forEach((team) => {
        const cachedTeam = cache.data[team];
        const condition =
          Object.keys(cachedTeam).includes("members") &&
          cachedTeam.members.some((member) => member.id === key);

        if (condition) {
          cache.data[team].members = cache.data[team].members.filter(
            (invite) => invite.id !== key
          );

          delete cache.data[team].personality;
          delete cache.data[team].values;
        }
      });

      positions.forEach((position) => {
        const cachedPosition = cache.data[position];
        const condition =
          Object.keys(cachedPosition).includes("candidatesUnion") &&
          cachedPosition.candidatesUnion.some(
            (candidate) => candidate.id === key
          );

        if (condition) {
          const posKanbanColumnIds =
            cachedPosition.kanbanColumns &&
            cachedPosition.kanbanColumns.map((col) => col.id);
          if (posKanbanColumnIds) {
            const candId = parseInt(key.split(":")[1], 10);
            posKanbanColumnIds.forEach((col) => {
              cache.data[col].candidates = cache.data[
                col
              ].candidates.json.filter((c) => c !== candId);
            });
          }
          cache.data[position].candidatesUnion = cache.data[
            position
          ].candidatesUnion.filter((candidate) => candidate.id !== key);
        }
      });
    }

    if (key.match(/^TalentPoolType:([0-9]+)$/)) {
      const orgTalentPoolKeys = Object.keys(
        cache.data[`OrganizationType:${orgId}`]
      ).filter((orgKey) => orgKey.includes("talentPool"));

      orgTalentPoolKeys.forEach((tpKey) => {
        cache.data[`OrganizationType:${orgId}`][tpKey] = cache.data[
          `OrganizationType:${orgId}`
        ][tpKey].filter((tp) => tp.id !== key);
      });
      const rootQueryKeys = Object.keys(cache.data.ROOT_QUERY);
      const searchQueryKey = rootQueryKeys.findIndex(
        (rqKey) => rqKey.indexOf("searchTalentPool") !== -1
      );

      if (searchQueryKey !== -1) {
        cache.data.ROOT_QUERY[rootQueryKeys[searchQueryKey]] =
          cache.data.ROOT_QUERY[rootQueryKeys[searchQueryKey]].filter(
            (tpsKey) => tpsKey.id !== key
          );
      }
    }

    cache.delete(key);
    proxy.restore(cache.data);

    return true;
  });

  return true;
};
