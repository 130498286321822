import { compose, lifecycle, withState, withHandlers } from "recompose";
import { withRouter } from "react-router-dom";
import axios from "utils/axios";
import { atuhCallbackRoute, newUserFromSessionRoute } from "client/authRoutes";
import withStore from "utils/withStore";
import { SET_CASE_STUDY, ACTIVATE_ONBOARDING } from "store/actionTypes";
import handleLogout from "utils/logOut";

export default compose(
  withRouter,
  withState("loading", "setLoading", true),
  withState("data", "setData", null),

  withStore("onboarding"),
  lifecycle({
    async componentDidMount() {
      const {
        data: {
          success,
          redirect,
          caseStudy,
          shouldOnboard,
          callbackData,
          closeWindow,
        },
      } = await axios.post(
        atuhCallbackRoute,

        { withCredentials: true }
      );

      if (!success) {
        this.props.setData(callbackData);
        this.props.setLoading(false);
      }

      if (closeWindow) {
        window.close();
      }

      if (caseStudy && shouldOnboard) {
        this.props.dispatch({ type: ACTIVATE_ONBOARDING });
        this.props.dispatch({
          type: SET_CASE_STUDY,
          payload: caseStudy,
        });
      }
      if (success) {
        return window.location.replace(redirect);
      }
    },
  }),
  handleLogout,
  withHandlers({
    newUserFromSession:
      ({ setLoading }) =>
      async () => {
        setLoading(true);
        const {
          data: { success, redirect },
        } = await axios.post(
          newUserFromSessionRoute,

          { withCredentials: true }
        );
        if (success) {
          return window.location.replace(redirect);
        }
      },
  })
);
