import { compose, withProps, withState } from "recompose";
import { graphql, withApollo } from "react-apollo";
import { loader } from "graphql.macro";
import removeMemberFromOnboardingCache from "utils/removeMemberFromOnboardingCache";

const REMOVE_FROM_TEAM = loader("./removeFromTeam1.graphql");
const REMOVE_TEAM_FROM_INVITE = loader("./removeTeamFromInvite.graphql");

export default compose(
  withApollo,
  withState("overlayClose", "setOverlayClose", true),
  withProps(({ team: { members, pendingInvites, id, onboardings } }) => {
    const truncate = string => {
      if (string.length > 9) return `${string.substring(0, 9)}..`;
      return string;
    };

    const getLabel = member => {
      if (
        (member.contract && member.contract.state !== "active") ||
        member.__typename === "Invite"
      ) {
        return {
          style: "pending",
          text: "Pending"
        };
      }
      if (!member.readyToRender) {
        return {
          style: "incomplete",
          text: "Incomplete"
        };
      }
      if (onboardings && onboardings.some(o => member.id === o.candidate.id)) {
        return {
          style: "onboarding",
          text: "Onboarding"
        };
      }
      return null;
    };

    const getOrder = member => {
      if (member.readyToRender) return 1;
      if (
        !member.readyToRender &&
        member.contract &&
        member.contract.state === "active"
      )
        return 2;
      if (member.__typename === "Invite") return 4;
      return 3;
    };

    const membersMapped = [...members, ...pendingInvites].map(m => ({
      id: m.id,
      first_name:
        (m.first_name && truncate(m.first_name)) ||
        (m.invited_email && truncate(m.invited_email)),
      email: (m.email && truncate(m.email)) || null,
      avatar_url: m.avatar_url || null,
      initials: m.initials || null,
      isOnboarding:
        onboardings && onboardings.some(o => m.id === o.candidate.id),
      isInvite: m.__typename === "Invite",
      label: getLabel(m),
      link:
        members.filter(memb => memb.readyToRender).length > 1 && m.readyToRender
          ? `/team/${id}/member/${m.id}`
          : null,
      order: getOrder(m)
    }));
    return { members: membersMapped.sort((a, b) => a.order - b.order) };
  }),
  graphql(REMOVE_FROM_TEAM, {
    name: "removeFromTeam",
    options: ({ refetch, team, client }) => ({
      refetchQueries: ["TEAM", "OPENPOSITION", "CANDIDATEINTEAM"],
      update: async (proxy, { data: { removeFromTeam } }) => {
        removeMemberFromOnboardingCache({
          proxy,
          cache: proxy.data.data,
          team_id: removeFromTeam.team_id,
          candidate_id: removeFromTeam.member_id
        });
      }
    })
  }),
  graphql(REMOVE_TEAM_FROM_INVITE, {
    name: "removeTeamFromInvite",
    options: ({ refetch }) => ({
      update: () => refetch()
    })
  })
);
