import React, { useState } from "react";
import classNames from "classnames";
import Button from "components/UI/Button";
import Icon from "components/UI/Icon";
import { Player, BigPlayButton } from "video-react";
import Modal from "react-modal";
import NewAccount from "components/Modals/NewAccount";
import styles from "./NoRights.module.scss";
import AccessRequest from "./AccessRequest";

export default ({ pageTitle, emptyStateContent, admins = [], isCandidate }) => {
  const [modal, updateModal] = useState(null);
  return (
    <>
      <div className={styles.row}>
        <div className={styles.left}>
          <div className={styles.notice}>
            <div className={styles.icon}>
              <Icon icon="Bell" />
            </div>
            <div className={styles.text}>
              <h5 className={styles.noticeTitle}>
                {emptyStateContent.notice.title}
              </h5>
              <p className={styles.noticeDesc}>
                {emptyStateContent.notice.subtitle}
              </p>
            </div>
          </div>
        </div>
        {!isCandidate && (
          <div className={styles.right}>
            <div className={classNames(styles.notice, styles.blue)}>
              <div className={styles.icon}>
                <Icon icon="Lock" />
              </div>
              <div className={styles.text}>
                <a
                  className={styles.noticeLink}
                  onClick={() => updateModal("access")}
                >
                  Ask for access
                  <span>
                    <Icon icon="Chevron" />
                  </span>
                </a>
                <p className={styles.noticeDesc}>
                  This workspace is managed by{" "}
                  {admins.map(({ name }) => name).join(", ")}
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
      <h2 className={styles.pageTitle}>{pageTitle}</h2>
      <div className={styles.noRights}>
        <div className={styles.row}>
          <div className={styles.left}>
            <h5 className={styles.subtitle}>{emptyStateContent.subtitle}</h5>
            {emptyStateContent.list && emptyStateContent.list.length > 0 && (
              <div className={styles.list}>
                {emptyStateContent.list.map((listItem, index) => (
                  <div
                    key={"EmptyStateListItem-" + index}
                    className={styles.listItem}
                  >
                    <div className={styles.number}>
                      <span>{index + 1}</span>
                    </div>
                    <div className={styles.text}>
                      <h5>{listItem.title}</h5>
                      <p>{listItem.description}</p>
                      {listItem.button && (
                        <div className={styles.cta}>
                          <Button
                            bStyle={["blue", "verySmall"]}
                            onClick={listItem.button.action}
                          >
                            {listItem.button.title}
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className={styles.right}>
            <div className={styles.video}>
              <Player
                playsInline
                poster={emptyStateContent.thumbnail}
                src={emptyStateContent.video}
              >
                <BigPlayButton position="center" />
              </Player>
            </div>
          </div>
        </div>
        <div className={styles.ctaWrapper}>
          <h3 className={styles.ctaTitle}>Create your own workspace</h3>
          <p className={styles.ctaDescription}>
            Get access to the platform's full functionality and try it for free
            with up to 4 people - no credit card required.
          </p>
          <Button
            bStyle={["primary", "verySmall"]}
            onClick={() => updateModal("newWorkspace")}
          >
            <Icon icon="Plus" marginRight />
            Create workspace
          </Button>
        </div>
      </div>

      {modal === "newWorkspace" && (
        <Modal
          isOpen={modal === "newWorkspace"}
          onRequestClose={() => updateModal(null)}
          shouldCloseOnOverlayClick
          className={classNames(styles.modal, "create")}
        >
          <Button
            bStyle="close"
            type="button"
            onClick={() => updateModal(null)}
          >
            <i className="fal fa-times" />
          </Button>
          <NewAccount updateModal={updateModal} />
        </Modal>
      )}
      {modal === "access" && (
        <Modal
          isOpen={modal === "access"}
          onRequestClose={() => updateModal(null)}
          shouldCloseOnOverlayClick
          className={classNames(styles.modal, "create")}
        >
          <Button
            bStyle="close"
            type="button"
            onClick={() => updateModal(null)}
          >
            <i className="fal fa-times" />
          </Button>
          <AccessRequest updateModal={updateModal} admins={admins} />
        </Modal>
      )}
    </>
  );
};
