import { withRouter } from "react-router-dom"
import { compose, withState } from "recompose"
import withStore from "utils/withStore"

export default compose(
  withRouter,
  withStore("selectedOrg"),
  withState("modal", "updateModal", false),

)
