import { withHandlers } from 'recompose'

export default withHandlers({
  handleChangeName: ({ updatePosition, position, updateModal }) => (e) => {
    e.preventDefault()
    const name = e.target.name.value
    updatePosition({
      variables: {
        id: position.id,
        name
      }
    }).then(() => {
      updateModal('')
    })
  }
})
