import React, { useState } from "react";
import Field from "components/UI/Field";
import classNames from "classnames";
import Button from "components/UI/Button";
import UserAvatar from "components/UserAvatar";
import Select from "components/UI/Select";
import { ReactComponent as InviteRow } from "images/inviteRow.svg";
import styles from "./InvitedList.module.scss";
import compose from "./compose";

export default compose(
  ({
    list,
    updateList,
    teamId,
    teamLimit,
    isLoading,
    handleSubmit,
    require,
    handleUpdateList,
    setOverlayClose,
    noheight,
    updateModal,
    resource,
    askForName,
    currentStep,
  }) => {
    const handleChangeFirstName = (user, value) => {
      if (value) {
        user.first_name = value;
      }
    };

    const handleChangeLastName = (user, value) => {
      if (value) {
        user.last_name = value;
      }
    };

    return (
      <div
        className={classNames(
          styles.invitedListWrapper,
          noheight && styles.noHeight
        )}
      >
        {list && list.length > 0 ? (
          <div className={styles.tableWrapper}>
            <table>
              <thead>
                <tr>
                  <th className={styles.name}>Name</th>
                  {require && require.key && (
                    <>
                      <th className={styles.org}>{require.key}</th>
                      <th className={styles.role}>Org. role</th>
                    </>
                  )}
                  <th />
                </tr>
              </thead>
              <tbody>
                {list.map((user) => {
                  return (
                    <tr className={styles.line} key={`user${Math.random(10)}`}>
                      <td className={styles.name}>
                        <div className={styles.avatarWrapper}>
                          <UserAvatar
                            member={user}
                            isInvite={!user.avatar_url && !user.initials}
                            size="small"
                          />
                        </div>
                        <div className={styles.nameAndEmail}>
                          <p className={styles.userName}>
                            {user.name ? user.name : user.email}
                          </p>
                          <p className={styles.email}>
                            {!user.name ? null : user.email}
                          </p>
                          {!user.name && askForName && (
                            <div className={styles.inputs}>
                              <div className={styles.input}>
                                <Field
                                  white
                                  fullWidth
                                  small
                                  type="text"
                                  name="firstName"
                                  required
                                  placeholder="First name *"
                                  value={user.first_name}
                                  onChange={(e) => {
                                    handleChangeFirstName(user, e.target.value);
                                  }}
                                />
                              </div>
                              <div className={styles.input}>
                                <Field
                                  white
                                  fullWidth
                                  small
                                  type="text"
                                  name="lastName"
                                  required
                                  placeholder="Last name *"
                                  value={user.last_name}
                                  onChange={(e) => {
                                    handleChangeLastName(user, e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </td>
                      {require &&
                        require.values &&
                        require.values.map((value) => (
                          <td
                            className={classNames(styles.select, styles[value])}
                            key={`require:select:${value}`}
                          >
                            <Select
                              name={value}
                              noBorder
                              value={user[require.key][value]}
                              onChange={(e) =>
                                handleUpdateList({
                                  value: e.target.value,
                                  key: value,
                                  user,
                                })
                              }
                            >
                              {require[value].map((i) => (
                                <option
                                  key={`${require.key}:${i.id}:${i.name}`}
                                  value={i.id}
                                >
                                  {i.name}
                                </option>
                              ))}
                            </Select>
                          </td>
                        ))}

                      <td className={styles.remove}>
                        <div className={styles.remove}>
                          <Button
                            type="button"
                            bStyle={["none"]}
                            onClick={() => {
                              updateList(
                                list.filter((i) => i.email !== user.email)
                              );
                              if (
                                list.filter((i) => i.email !== user.email)
                                  .length === 0
                              ) {
                                setOverlayClose && setOverlayClose(true);
                              }
                            }}
                          >
                            <i className="fal fa-times" />
                          </Button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <div className={styles.tableWrapper}>
            <div className={styles.placeholderHead}>
              <h5>Name</h5>
              {require && require.key && (
                <>
                  <h5 className={styles.org}>{require.key}</h5>
                  <h5 className={styles.role}>Org. role</h5>
                </>
              )}
            </div>
            <div className={styles.placeholder}>
              <div className={styles.row}>
                <InviteRow />
              </div>
              <div className={styles.row}>
                <InviteRow />
              </div>
              <div className={styles.row}>
                <InviteRow />
              </div>
            </div>
          </div>
        )}

        {resource === "stakeholders" && (
          <div className={styles.infoBox}>
            <i className="fas fa-info-circle" />
            <p>
              You can remove position administrators from this page but they
              will still be members of the organisation. Organisation
              administrators can remove them entirely from the{" "}
              <a href={`/org/${teamId}/members`}>manage people</a> page of the
              organisation.
            </p>
          </div>
        )}

        {resource === "candidateEvaluators" && (
          <div className={styles.infoBox}>
            <i className="fas fa-info-circle" />
            <p>
              Adding an organisation member who does not have admin rights will
              give them access to this position and its candidates
            </p>
          </div>
        )}

        <div
          className={classNames(
            styles.submitWrapper,
            document.getElementById("inviteWrapper") &&
              document.getElementById("inviteWrapper").offsetHeight > 450 &&
              styles.relative,
            styles.relative
          )}
        >
          <Button
            bStyle={["green"]}
            type="submit"
            disabled={list.length < 1 || isLoading || teamLimit}
            onClick={() => {
              handleSubmit({ list });
              updateList([]);
              if (window.heap) {
                list.forEach((_, i) => {
                  // console.log("invite-sent", i);
                  window.heap.track("invite-sent");
                });
              }
            }}
          >
            {isLoading && (
              <div className={styles.loading}>
                <i className="far fa-spinner-third" />
              </div>
            )}
            {resource === "team"
              ? "Add to team"
              : resource === "candidate" && currentStep === "contact"
              ? "Add contacts"
              : "Invite"}
          </Button>
          <Button bStyle={["secondary"]} onClick={() => updateModal("")}>
            Cancel
          </Button>
        </div>
      </div>
    );
  }
);
