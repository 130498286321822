import { compose, withHandlers } from "recompose";
import { graphql } from "react-apollo";
import { loader } from "graphql.macro";

const SEND_INVITE_TO_PERSON = loader("./sendInviteToPerson.graphql");
const RESEND_INVITE = loader("./resendInvite.graphql");

export default compose(
  graphql(SEND_INVITE_TO_PERSON, {
    name: "sendInviteToPerson",
  }),
  graphql(RESEND_INVITE, {
    name: "resendInvite",
  }),
  withHandlers({
    handleSendInvite:
      ({
        match: {
          params: { candidateId, positionId },
        },
        CANDIDATE_QUERY: { candidateInTeam: person },
        sendInviteToPerson,
        resendInvite,
      }) =>
      async () => {
        if (person.invite)
          await resendInvite({
            variables: {
              inviteId: person.invite.id,
            },
          });
        else
          await sendInviteToPerson({
            variables: {
              person_id: parseInt(candidateId, 10),
              position_id: parseInt(positionId, 10),
            },
          });
      },
  })
);
