import { compose, withHandlers, withState } from 'recompose'
import { graphql } from 'react-apollo'
import { loader } from 'graphql.macro'

const RESEND_EVALUATION_INVITE = loader('./resendEvaluationInvite.graphql')

export default compose(
  withState('btnState', 'setBtnState', {
    text: 'fad fa-paper-plane',
    isLoading: false,
    hoverText: 'Resend invite'
  }),

  graphql(RESEND_EVALUATION_INVITE, {
    name: 'resendInvite'
  }),
  withHandlers({
    handleResendEvaluationInvite:
      ({ invite_id, resendInvite, setBtnState }) =>
      () => {
        setBtnState({
          text: 'fas fa-spinner-third',
          isLoading: true,
          disabled: true,
          hoverText: 'Sending...'
        })
        resendInvite({
          variables: {
            invite_id
          }
        })
          .then((res) => {
            setBtnState({
              text: 'fas fa-check',
              isLoading: false,
              disabled: true,
              hoverText: 'Reminder sent!'
            })
          })
          .catch((err) => {
            setBtnState({
              text: 'fas fa-exclamation-triangle',
              isLoading: false,
              disabled: true,
              hoverText: 'Error :('
            })
          })
      }
  })
)
