import React from "react";
import { compose, withState } from "recompose";
import { withRouter } from "react-router-dom";
import { graphql } from "react-apollo";
import { loader } from "graphql.macro";
import handleLoading from "utils/loader";
import Circles from "components/Loaders/Circles";

import handleUpdatePositionTemplate from "./handleUpdatePositionTemplate";
import deserialize from "./deserialize";

const UPDATE_POSITION_TEMPLATE = loader("../updatePositionTemplate6.graphql");

export default compose(
  withRouter,
  graphql(UPDATE_POSITION_TEMPLATE, {
    name: "updatePositionTemplate",
    options: (props) => ({
      update: (proxy, { data: { updatePositionTemplate } }) => {
        // console.log(updatePositionTemplate)
      },
    }),
  }),
  handleLoading(() => (
    <div style={{ height: "100vh" }}>
      <Circles />
    </div>
  )),
  withState("summary", "setSummary", ({ positionTemplate: { objectives } }) =>
    deserialize(objectives)
  ),
  withState("saved", "setSaved", false),
  handleUpdatePositionTemplate
);
