import { SET_VIEWER } from 'store/actionTypes'

const initialState = {
  id: null,
  email: ''
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_VIEWER:
      return Object.assign({}, state, action.payload)

    default:
      return state
  }
}
