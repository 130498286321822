import { withProps, withHandlers, withState, compose } from "recompose";
import { graphql } from "react-apollo";
import { loader } from "graphql.macro";
import handleLoading from "utils/loader";
import FullPage from "components/Loaders/FullPage";
import addMembersToTeamDim from "utils/addMembersToTeamDim";

const TEAM_QUERY = loader("client/Queries/TeamQuery124.graphql");
const GENERATE_TEAM_SUMMARY = loader("./generateTeamGPTSummary1.graphql");
const DELETE_TEAM_SUMMARY = loader("./deleteTeamGPTSummary.graphql");

export default compose(
  withState("isGenerating", "toggleGenerating", false),
  handleLoading(FullPage),
  withState("gptSummary", "setGptSummary", ({ team }) =>
    team.summary ? team.summary.text : ""
  ),
  withState("updatedAt", "setUpdatedAt", ({ team }) =>
    team.summary ? team.summary.updatedAt : null
  ),
  graphql(GENERATE_TEAM_SUMMARY, {
    name: "generateTeamGPTSummary",
    options: ({ toggleGenerating, setGptSummary, setUpdatedAt, team }) => {
      return {
        variables: { team_id: parseInt(team.id, 10) },
        update: (proxy, { data: { generateTeamProfileSummary } }) => {
          toggleGenerating(false);
          setGptSummary(generateTeamProfileSummary.text);

          console.log(generateTeamProfileSummary);
          setUpdatedAt(generateTeamProfileSummary.updatedAt);

          const data = proxy.readQuery({
            query: TEAM_QUERY,
            variables: {
              id: parseInt(team.id, 10),
            },
          });

          data.team.summary = generateTeamProfileSummary;

          proxy.writeQuery({
            query: TEAM_QUERY,
            variables: {
              id: parseInt(team.id, 10),
            },
            data,
          });
        },
      };
    },
  }),
  graphql(DELETE_TEAM_SUMMARY, {
    name: "deleteTeamProfileSummary",
    options: ({ setGptSummary, team }) => {
      return {
        variables: { team_id: parseInt(team.id, 10) },
        update: (proxy, { data: { deleteTeamProfileSummary } }) => {
          setGptSummary("");
        },
      };
    },
  }),
  withHandlers({
    handleGenerateSummary:
      ({ generateTeamGPTSummary, toggleGenerating }) =>
      async () => {
        toggleGenerating(true);
        await generateTeamGPTSummary();
        if (window.heap) {
          window.heap.track("create-summary-team");
        }
      },
    handleDeleteSummary:
      ({ deleteTeamProfileSummary }) =>
      async () => {
        await deleteTeamProfileSummary();
        if (window.heap) {
          window.heap.track("delete-summary-team");
        }
      },
  }),
  withProps(({ team: { personality, competencies, values, members } }) => {
    return {
      dimensions: [
        ...personality.map((dim) => ({
          ...dim,
          members: addMembersToTeamDim({
            dim,
            members,
            dimGroup: "personality",
          }),
        })),
        ...competencies.map((dim) => ({
          ...dim,
          members: addMembersToTeamDim({
            dim,
            members,
            dimGroup: "competencies",
          }),
        })),
        ...values.map((dim) => ({
          ...dim,
          members: addMembersToTeamDim({ dim, members, dimGroup: "values" }),
        })),
      ],
    };
  })
);
