import React from 'react'
import classNames from 'classnames'
import styles from './Answer.module.scss'

export default ({ handleSetAnswer, currentRowId, answers }) => {
  const isSelected = (score) => {
    const currentAnswer = answers[currentRowId] && answers[currentRowId].answer
    if (score === currentAnswer) return styles.hover
    return null
  }
  return (
    <div className={styles.wrapper}>
      <button
        type='button'
        data-answer="0"
        className={classNames(styles.dotL, isSelected(0))}
        onClick={() => handleSetAnswer(0)}
      />
      <button
        type='button'
        data-answer="20"
        className={classNames(styles.dotM, isSelected(20))}
        onClick={() => handleSetAnswer(20)}
      />
      <button
        type='button'
        data-answer="40"
        className={classNames(styles.dotS, isSelected(40))}
        onClick={() => handleSetAnswer(40)}
      />
      <div type='button' className={styles.separate} />
      <button
        type='button'
        data-answer="60"
        className={classNames(styles.dotS, isSelected(60))}
        onClick={() => handleSetAnswer(60)}
      />
      <button
        type='button'
        data-answer="80"
        className={classNames(styles.dotM, isSelected(80))}
        onClick={() => handleSetAnswer(80)}
      />
      <button
        type='button'
        data-answer="100"
        className={classNames(styles.dotL, isSelected(100))}
        onClick={() => handleSetAnswer(100)}
      />
    </div>
  )
}
