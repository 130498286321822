import React from 'react'
import KanbanDesktop from 'components/Kanban/Desktop'
import KanbanMobile from 'components/Kanban/Mobile'
import compose from './compose'

export default compose((props) => {
  const {
    columns,
    setColumns,
    handleSave,
    handleDiscard,
    data: { openPosition }
  } = props

  return (
    <>
      {window.innerWidth < 1056 ? (
        <KanbanMobile
          resource='position'
          position={openPosition}
          columns={columns}
          setColumns={setColumns}
          handleSave={handleSave}
          handleDiscard={handleDiscard}
        />
      ) : (
        <KanbanDesktop
          resource='position'
          position={openPosition}
          columns={columns}
          setColumns={setColumns}
          handleSave={handleSave}
          handleDiscard={handleDiscard}
        />
      )}
    </>
  )
})
