import React from 'react'
import UserAvatar from 'components/UserAvatar'
import styles from './MemberAvatar.module.scss'

export default ({ teamMember }) => (
  <>
    <div
      className={styles.avatarPosition}
      style={{
        top: `calc(${teamMember.valuesChart.avatarPosition.y}% - 13px)`,
        left: `calc(${teamMember.valuesChart.avatarPosition.x}% - 13px)`
      }}
    >
      <div className={styles.avatarWrapper}>
        <UserAvatar member={teamMember} size='medium' showName />
      </div>
    </div>
  </>
)
