import React from 'react'

import scoreGroups, { teamScoreGroupsByIndex } from './compeScoreGroups'

const groupIcons = {
  expert: <i className='far fa-fire-alt' />,
  strong: <i className='far fa-fire-alt' />,
  good: null,
  gap: <i className='fas fa-chart-network' />,
  lacking: null
}

const groupTitles = {
  expert: "The team's core competency",
  strong: "The team's core competency",
  good: null,
  gap: 'Least prioritized in the team',
  lacking: null
}

export default (team) => {
  const compes = [...team.competencies]
  const potGaps = compes.filter(c => c.score === 0).length < 6
    ? compes
      .sort((a, b) => b.score - a.score)
      .slice(0)
      .reverse()
      .slice(0, 6)
    : compes.filter(c => c.score === 0)

  const getGroup = ({ index, comp }) => {
    if (potGaps.some(g => g.dim_id === comp.dim_id)) return 'gap'
    return teamScoreGroupsByIndex[index] || 'lacking'
  }
  return compes
    .sort((a, b) => b.score - a.score)
    .map((c, i) => {
      c.group = getGroup({ index: i, comp: c })
      c.icon = team.members.some(m => m.readyToRender) && groupIcons[c.group]
      c.title = team.members.some(m => m.readyToRender) && groupTitles[c.group]
      c.members = team.members
        .filter(m => m.competencies.some(comps => comps.score !== null))
        .map((m) => {
          const memberCompeScore = m.competencies.find(mc => mc.name === c.name)
            .score
          return {
            id: m.id,
            score: memberCompeScore,
            group: scoreGroups[memberCompeScore]
          }
        })

      c.groups = [...new Set(Object.values(scoreGroups))].map(group => {
        if (c.members.length > 0) return c.members.filter(m => m.group === group)
        return []
      })

      c.insight = team.competencyInsights && team.competencyInsights.find(i => i.dim_id === c.dim_id)
      return c
    })
}
