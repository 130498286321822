import { compose, withProps } from "recompose";
import { graphql } from "react-apollo";
import { loader } from "graphql.macro";
import { withRouter } from "react-router-dom";
import handleLoading from "utils/handleLoading";

const SURVEYS = loader("./surveys3.graphql");

export default compose(
  withRouter,
  graphql(SURVEYS),
  handleLoading,
  withProps(({data: { surveys }}) => ({complete: !surveys.some(s => !s.complete)}))
);
