import React from "react";
import { compose, withState } from "recompose";
import { withRouter } from "react-router-dom";
import { graphql } from "react-apollo";
import { loader } from "graphql.macro";
import handleLoading from "utils/loader";
import Circles from "components/Loaders/Circles";

import handleSaveDescription from "./handleSaveDescription";
import deserialize from "./deserialize";

const POSITION_QUERY = loader("client/Queries/PositionQuery98.graphql");
const SAVE_DESCRIPTION = loader("./saveDescription.graphql");

export default compose(
  withRouter,
  graphql(POSITION_QUERY, {
    options: (props) => {
      return {
        notifyOnNetworkStatusChange: true,
        variables: {
          id: parseInt(props.match.params.positionId, 10),
        },
      };
    },
  }),
  graphql(SAVE_DESCRIPTION, {
    name: "savePositionDescription",
    options: (props) => {
      return {
        update: (proxy, { data: { savePositionDescription } }) => {
          const data = proxy.readQuery({
            query: POSITION_QUERY,
            variables: { id: parseInt(props.match.params.positionId, 10) },
          });

          data.openPosition.description = savePositionDescription.description;
          proxy.writeQuery({
            query: POSITION_QUERY,
            variables: { id: parseInt(props.match.params.positionId, 10) },
            data,
          });
        },
      };
    },
  }),
  handleLoading(() => (
    <div style={{ height: "100vh" }}>
      <Circles />
    </div>
  )),

  withState(
    "description",
    "setDescription",
    ({
      data: {
        openPosition: { description },
      },
    }) => deserialize(description)
  ),
  handleSaveDescription
);
