import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import { ApolloProvider, graphql } from "react-apollo";
import { loader } from "graphql.macro";
import { Provider } from "react-redux";
import { TourProvider } from "@reactour/tour";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import Utils from "utils/Utils";
import Icon from "components/UI/Icon";
import Button from "components/UI/Button";
import client from "./client/index";
import Routes from "./routes";
import store from "./store";
import { AppProvider } from "contexts/app.context";

require(`style/index.scss`);
require(`style/fontawesome/all.css`);

const disableBody = (target) => disableBodyScroll(target);
const enableBody = (target) => enableBodyScroll(target);

const steps = [];

const TOGGLE_SHOW_TEAM_TOUR = loader(
  "client/Mutations/toggleShowTeamTour.graphql"
);

const closeTour = (onClick) => {
  if (
    window.location.pathname.includes("team") &&
    !window.location.pathname.includes("position")
  ) {
    client.mutate({ mutation: TOGGLE_SHOW_TEAM_TOUR });
  }
  if (onClick) {
    onClick();
  }
};

function Close({ onClick }) {
  return (
    <div style={{ position: "absolute", right: 16, top: 16 }}>
      <Button
        onClick={() => closeTour(onClick)}
        bStyle={["none", "popoverclose"]}
      >
        <Icon icon="Close" />
      </Button>
    </div>
  );
}

document.whitelabel = "default";

function Navigation({ currentStep, setCurrentStep, setIsOpen, steps }) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: "1rem",
      }}
    >
      {currentStep > 0 && (
        <Button
          bStyle={["secondary", "small"]}
          onClick={() => setCurrentStep(currentStep - 1)}
        >
          Prev
        </Button>
      )}
      <Button
        bStyle={["primary", "small"]}
        onClick={() =>
          currentStep === steps.length - 1
            ? closeTour(() => setIsOpen(false))
            : setCurrentStep(currentStep + 1)
        }
      >
        {currentStep === steps.length - 1 ? "Finish" : "Next"}
      </Button>
    </div>
  );
}

ReactDOM.render(
  <Provider store={store}>
    <ApolloProvider client={client}>
      <Utils>
        <TourProvider
          steps={steps}
          afterOpen={disableBody}
          beforeClose={enableBody}
          components={{ Navigation, Close }}
          position="left"
          highlightedMaskClassName="mask"
          badgeContent={({ totalSteps, currentStep }) =>
            currentStep + 1 + "/" + totalSteps
          }
          disableInteraction={true}
          maskClassName="tourMask"
          onClickMask={() => null}
          scrollSmooth
          disableDotsNavigation={true}
        >
          <AppProvider>
            <Routes />
          </AppProvider>
        </TourProvider>
      </Utils>
    </ApolloProvider>
  </Provider>,
  document.getElementById("root")
);
