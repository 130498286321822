import React from 'react'

import { ReactComponent as IconExpert } from 'images/icons/competencies/colored-must.svg'
import { ReactComponent as IconStrong } from 'images/icons/competencies/colored-important.svg'
import { ReactComponent as IconGood } from 'images/icons/competencies/colored-good.svg'

import scoreGroups from './compeScoreGroups'

const groupIcons = {
  expert: <IconExpert />,
  strong: <IconStrong />,
  good: <IconGood />,
  lacking: <i className='fas fa-chart-network' />
}



export default (team, teamMember) => teamMember
  && teamMember.competencies
    .sort((a, b) => b.score - a.score)
    .map((c) => {
      const groupTitles = {
        expert: `${teamMember.first_name} is great at`,
        strong: `${teamMember.first_name} is strong at`,
        good: `${teamMember.first_name} is good at`,
        lacking: `${teamMember.first_name}'s learning opportunities`
      }

      c.group = scoreGroups[c.score]

      c.members = team.members
        .filter(m => m.competencies.some(comps => comps.score !== null))
        .map((m) => {
          const memberCompeScore = m.competencies.find(mc => mc.name === c.name)
            .score
          return {
            id: m.id,
            score: memberCompeScore,
            group: scoreGroups[memberCompeScore]
          }
        })

        c.icon = c.group === 'lacking' ? (c.members.some(m => m.group !== 'lacking') && groupIcons[c.group]) : groupIcons[c.group]
        c.title = c.group === 'lacking' ? (c.members.some(m => m.group !== 'lacking') && groupTitles[c.group]) : groupTitles[c.group]

      c.groups = [...new Set(Object.values(scoreGroups))].map(group => c.members.filter(m => m.group === group))
      return c
    })
