import React from "react";
import { compose } from "recompose";
import { graphql } from "react-apollo";
import { loader } from "graphql.macro";
import handleLoading from "utils/loader";
import FullPage from "components/Loaders/FullPage";

const INVITES = loader("client/Queries/Invites7.graphql");

export default compose(
  graphql(INVITES),
  handleLoading(() => <FullPage />)
);
