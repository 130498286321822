import React from "react";
import ValuesPlaceholder from "components/Placeholders/ValuesPlaceholder";
import Wrapper from "components/UI/Wrapper";
import Title from "components/UI/Title";
import Subtitle from "components/UI/Subtitle";
import TopValues from "components/TopValues";
import CommentWidget from "components/Comment";
import ValuesChart from "./Chart";
import styles from "./Values.module.scss";

export default ({ team, candidate }) => {
  return (
    <div className={styles.main}>
      <Wrapper>
        <CommentWidget
          comments={candidate.comments}
          objType="position"
          objId={candidate.position.id}
          subjectType="candidate"
          subjectId={candidate.id}
          itemType="values"
        />
        <Title text="Values map" icon="fas fa-balance-scale" />
        <Subtitle text={`${candidate.first_name}'s personal motivators.`} />
        <Subtitle
          verySmall
          text={`Think about whether the role and the environment are aligned with ${candidate.first_name}'s expectations.`}
        />
        {candidate.valuesChart.readyToRender ? (
          <>
            <ValuesChart candidate={candidate} team={team} />
            <TopValues
              user={candidate}
              data={team}
              resource="member"
              isCandidate
            />
          </>
        ) : (
          <ValuesPlaceholder resource="member" />
        )}
      </Wrapper>
    </div>
  );
};
