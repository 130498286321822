import React from "react";
import Icon from "components/UI/Icon";
import Button from "components/UI/Button";

import styles from "./DeleteModal.module.scss";

export default ({
  setRemoveModal,
  handleDeletePositionTemplate,
  templateId,
}) => (
  <div className={styles.modalWrapper}>
    <h3 className={styles.title}>
      <span className={styles.icon}>
        <Icon icon="Delete" epic />
      </span>
      Are you sure you wish to delete this position template?
    </h3>
    <p className={styles.subtitle}>This action cannot be undone.</p>
    <div className={styles.buttonGroup}>
      <Button
        bStyle={["primary", "red"]}
        type="button"
        onClick={() => handleDeletePositionTemplate(templateId)}
      >
        Yes, delete
      </Button>
      <Button
        bStyle={["secondary"]}
        type="button"
        onClick={() => setRemoveModal(false)}
      >
        No, cancel
      </Button>
    </div>
  </div>
);
