import { withHandlers } from 'recompose'

export default withHandlers({
  handleChangeOrgContract: ({
    updateOrgContractMutation,
    updateErrorPrompt,
    organization
  }) => ({ event, user, roleTypes }) => {
    updateOrgContractMutation({
      variables: {
        resourceId: user.type === 'user' ? user.contractId : user.inviteId,
        resourceType: user.type,
        org_id: organization.id,
        update: {
          role: roleTypes.find(t => t.name === event.target.value).role
        }
      }
    }).catch((err) => {
      updateErrorPrompt({
        error: true,
        message: err.message.replace('GraphQL error: ', '')
      })
    })
  }
})
