import React, { useState } from "react";
import classNames from "classnames";
import Button from "components/UI/Button";
import Icon from "components/UI/Icon";

import styles from "./SendInviteToWisnio.module.scss";

export default ({ action, text, icon, color }) => {
  const [inviteSent, setInviteSent] = useState(false);
  const handleAction = () => {
    setInviteSent(true);
    action();
  };

  return (
    <div className={classNames(styles.button, styles[color])}>
      <Button bStyle={["none"]} onClick={handleAction} disabled={inviteSent}>
        {icon && !inviteSent && <Icon icon={icon} marginRight />}
        {inviteSent && <Icon icon="CheckLarge" marginRight />}
        {inviteSent ? "Invite sent" : text}
      </Button>
    </div>
  );
};
