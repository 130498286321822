import React from 'react'

import scoreGroups from './compeScoreGroups'

const groupIcons = {
  must: (
    <div data-star='true'>
      <i className='fas fa-star' />
      <i className='fas fa-star' />
      <i className='fas fa-star' />
    </div>
  ),
  important: (
    <div data-star='true'>
      <i className='fas fa-star' />
      <i className='fas fa-star' />
    </div>
  ),
  good: (
    <div data-star='true'>
      <i className='fas fa-star' />
    </div>
  ),
  lacking: <i className='fas fa-chart-network' />
}

const groupTitles = {
  must: 'Must have',
  important: 'Important to have',
  good: 'Good to have',
  lacking: 'Least prioritized in the team'
}

const posCompeGroupsByIndex = {
  0: 'must',
  1: 'must',
  2: 'must',
  3: 'important',
  4: 'important',
  5: 'important',
  6: 'good',
  7: 'good',
  8: 'good'
}

export default position => position.parentTeam.competencies
  .sort((a, b) => b.score - a.score)
  .map((c, i) => {
    c.group = posCompeGroupsByIndex[
      position.competencies.findIndex(pc => pc.title === c.name)
    ] || 'lacking'
    c.icon = groupIcons[c.group]
    c.title = groupTitles[c.group]
    c.members = position.parentTeam.members
      .filter(m => m.competencies.some(comps => comps.score !== null))
      .map((m) => {
        const memberCompeScore = m.competencies.find(mc => mc.name === c.name)
          .score
        return {
          id: m.id,
          score: memberCompeScore,
          group: scoreGroups[memberCompeScore]
        }
      })
    c.groups = [...new Set(Object.values(scoreGroups))].map(group => c.members.filter(m => m.group === group))
    return c
  })
