import { withHandlers } from 'recompose'

export default withHandlers({
  handleSaveTemplate: ({
    createTemplateFromPosition,
    position,
    setSaved
  }) => (e) => {
    e.preventDefault()
    const title = e.target.name.value
    const description = e.target.description.value
    createTemplateFromPosition({
      variables: {
        position_id: position.id,
        org_id: position.parentOrganization.id,
        title,
        description
      }
    }).then(() => {
      setSaved(true)
    })
  }
})
