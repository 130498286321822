import React from "react";
import Icon from "components/UI/Icon";
import styles from "./AILabel.module.scss";

export default () => (
  <div className={styles.label}>
    <span className={styles.icon}>
      <Icon icon="Magicwand" />
    </span>
    <p>Enhanced with AI</p>
  </div>
);
