import React from "react";
import classNames from "classnames";

import styles from "./TextArea.module.scss";

export default ({
  type,
  name,
  required,
  value,
  onChange,
  placeholder,
  autoComplete,
  small,
  large,
  white,
  fullWidth,
  paddingRight,
  active,
  noBorder,
}) => (
  <div
    className={classNames(
      styles.wrapper,
      small && styles.small,
      large && styles.large,
      white && styles.white,
      fullWidth && styles.fullWidth,
      paddingRight && styles.paddingRight,
      active && styles.active,
      noBorder && styles.noBorder
    )}
  >
    <textarea
      type={type}
      name={name}
      value={value}
      onChange={onChange}
      required={required}
      placeholder={placeholder}
      autoComplete={autoComplete}
    />
  </div>
);
