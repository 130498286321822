import { compose, withHandlers } from "recompose";
import { graphql } from "react-apollo";
import { loader } from "graphql.macro";

const SET_NEW_ACCOUNT_ONBOARDING_USECASE = loader(
  "./setNewAccountOnboardingUseCase.graphql"
);

export default compose(
  graphql(SET_NEW_ACCOUNT_ONBOARDING_USECASE, {
    name: "setNewAccountOnboardingUseCase",
  }),
  withHandlers({
    handleSetNewAccountOnboardingUseCase:
      ({ setNewAccountOnboardingUseCase }) =>
      (selectedUseCase) => {
        console.log(selectedUseCase);
        setNewAccountOnboardingUseCase({
          variables: {
            usecase: selectedUseCase,
          },
        });
        if (window.Intercom) {
          window.Intercom("update", {
            onboarding_usecase: selectedUseCase,
          });
        }
        if (window.heap) {
          window.heap.addUserProperties({
            onboarding_usecase: selectedUseCase,
          });
        }
      },
  })
);
