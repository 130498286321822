import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { graphql } from "react-apollo";
import { loader } from "graphql.macro";

const GET_CANDIDATE_PUBLIC_SECRET = loader(
  "./getCandidatePublicSecret1.graphql"
);

const CREATE_CANDIDATE_PUBLIC_SECRET = loader(
  "./createCandidatePublicSecret2.graphql"
);

export default compose(
  withRouter,
  graphql(GET_CANDIDATE_PUBLIC_SECRET, {
    options: ({
      match: {
        params: { candidateId, teamId, positionId, orgId, candidateType },
      },
    }) => ({
      variables: {
        user_id:
          candidateType === "candidate" ? parseInt(candidateId, 10) : null,
        person_id:
          candidateType === "person" ? parseInt(candidateId, 10) : null,
        teamId: parseInt(teamId, 10),
        positionId: parseInt(positionId, 10),
        orgId: parseInt(orgId, 10),
      },
    }),
  }),
  graphql(CREATE_CANDIDATE_PUBLIC_SECRET, {
    name: "createPublicSecret",
    options: ({
      match: {
        params: { candidateId, teamId, positionId, orgId, candidateType },
      },
    }) => ({
      update: (proxy, { data: { createCandidatePublicSecret } }) => {
        const data = proxy.readQuery({
          query: GET_CANDIDATE_PUBLIC_SECRET,
          variables: {
            user_id:
              candidateType === "candidate" ? parseInt(candidateId, 10) : null,
            person_id:
              candidateType === "person" ? parseInt(candidateId, 10) : null,
            teamId: parseInt(teamId, 10),
            positionId: parseInt(positionId, 10),
            orgId: parseInt(orgId, 10),
          },
        });

        data.candidatePublicSecret = createCandidatePublicSecret;

        proxy.writeQuery({
          query: GET_CANDIDATE_PUBLIC_SECRET,
          variables: {
            user_id:
              candidateType === "candidate" ? parseInt(candidateId, 10) : null,
            person_id:
              candidateType === "person" ? parseInt(candidateId, 10) : null,
            teamId: parseInt(teamId, 10),
            positionId: parseInt(positionId, 10),
            orgId: parseInt(orgId, 10),
          },
          data,
        });
      },
    }),
  })
);
