import React from "react";
import KanbanDesktop from "components/Kanban/Desktop";
import KanbanMobile from "components/Kanban/Mobile";
import compose from "./compose";

export default compose(
  ({
    data: { positionTemplate },
    columns,
    setColumns,
    handleSave,
    handleDiscard,
  }) => {
    console.log(columns);
    return (
      <>
        {window.innerWidth < 1056 ? (
          <KanbanMobile
            resource="template"
            columns={columns}
            setColumns={setColumns}
            handleSave={handleSave}
            handleDiscard={handleDiscard}
            position={positionTemplate}
          />
        ) : (
          <KanbanDesktop
            resource="template"
            columns={columns}
            setColumns={setColumns}
            handleSave={handleSave}
            handleDiscard={handleDiscard}
            position={positionTemplate}
          />
        )}
      </>
    );
  }
);
