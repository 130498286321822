import { withHandlers } from "recompose";

export default withHandlers({
  handleUpsertCandidateLinkedIn:
    ({
      upsertCandidateLinkedin,
      CANDIDATE_QUERY: { candidateInTeam },
      match,
    }) =>
    async (linkedInValue, close = null) => {
      const type = match.params.candidateType;
      try {
        await upsertCandidateLinkedin({
          variables: {
            user_id: type === "candidate" ? candidateInTeam.id : null,
            person_id: type === "person" ? candidateInTeam.id : null,
            position_id: candidateInTeam.position.id,
            linkedIn: linkedInValue,
          },
        });

        if (close) {
          close();
        }
      } catch (err) {
        throw new Error(err);
      }
    },
});
