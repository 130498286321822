import React from "react";
import SidebarWrapper from "components/UI/SidebarWrapper";
import SelectedOrg from "./SelectedOrg";
import OrgSelect from "./OrgSelect";
import NoOrgs from "./NoOrgs";
import compose from "./compose";

import styles from "./Dashboard.module.scss";

export default compose(
  ({
    data: { organizations },
    selectedOrg,
    modal,
    updateModal,
    account,
    handleSelectOrg,
    handleSwitchAccount,
    viewer,
    inviteStore,
    dispatch,
  }) => {
    const noAccount =
      !account.id ||
      !organizations ||
      (organizations && organizations.length === 0 && !account.adminRights);

    return (
      <SidebarWrapper
        noSidebar={
          noAccount ||
          (!noAccount &&
            account &&
            account.id &&
            organizations &&
            !selectedOrg.id)
        }
        noHeader
      >
        {!noAccount &&
          account &&
          account.id &&
          organizations &&
          selectedOrg.id && (
            <SelectedOrg
              selectedOrg={selectedOrg}
              organizations={organizations}
            />
          )}
        {!noAccount &&
          account &&
          account.id &&
          organizations &&
          !selectedOrg.id && (
            <OrgSelect
              viewer={viewer}
              account={account}
              organizations={organizations}
              modal={modal}
              updateModal={updateModal}
              handleSelectOrg={handleSelectOrg}
              handleSwitchAccount={handleSwitchAccount}
              inviteStore={inviteStore}
              dispatch={dispatch}
            />
          )}
        {noAccount && <NoOrgs account={account} />}
      </SidebarWrapper>
    );
  }
);
