import React from "react";
import Button from "components/UI/Button";
import { Form, Field } from "react-final-form";
import compose from "./compose";
import validatePassword from "utils/validatePassword";

import styles from "./SignupForm.module.scss";

export default compose(({ onSubmit, email }) => (
  <Form
    initialValues={{ email: email && email }}
    onSubmit={onSubmit}
    validate={(state) => validatePassword(state)}
    render={({
      submitError,
      handleSubmit,
      form,
      submitting,
      pristine,
      errors,
      valid,
      values,
    }) => {
      return (
        <form onSubmit={handleSubmit}>
          <div className={styles.row}>
            <div>
              <p className={styles.label}>First name *</p>
              <Field
                name="firstname"
                component="input"
                type="firstname"
                placeholder="Enter first name"
                required
              />
            </div>
            <div>
              <p className={styles.label}>Last name *</p>
              <Field
                name="lastname"
                component="input"
                type="lastname"
                placeholder="Enter last name"
                required
              />
            </div>
          </div>
          <div className={styles.row}>
            <div>
              <p className={styles.label}>Email address *</p>
              <Field
                name="email"
                component="input"
                type="email"
                placeholder="Enter email"
                required
                disabled={email && email}
              />
            </div>
          </div>
          <div className={styles.row}>
            <div>
              <p className={styles.label}>Password *</p>
              <Field
                name="password"
                placeholder="Enter password"
                component="input"
                type="password"
                required
              />
            </div>
            <div>
              <p className={styles.label}>Repeat password *</p>
              <Field
                name="repeatpassword"
                component="input"
                placeholder="Repeat password"
                type="password"
                required
              />
            </div>
          </div>
          <div className={styles.row}>
            <label htmlFor="terms" className={styles.checkbox}>
              <Field
                id="terms"
                type="checkbox"
                component="input"
                name="terms"
              />
              <p className={styles.label}>
                I have read and agree to the{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://wisnio.com/privacy-policy/"
                >
                  terms & conditions
                </a>
              </p>
            </label>
          </div>
          <div className={styles.button}>
            <Button
              bStyle={["primary"]}
              type="submit"
              disabled={
                submitting ||
                pristine ||
                !values.repeatpassword ||
                !values.password ||
                !values.email ||
                !values.firstname ||
                !values.lastname ||
                !values.terms ||
                Object.values(errors).indexOf(
                  "Password must be at least 8 characters."
                ) > -1 ||
                Object.values(errors).indexOf("Passwords must match!") > -1
              }
            >
              Create account
            </Button>
          </div>
          {(!valid || submitError) && (
            <div className={styles.submitError}>
              {submitError && <p>{submitError}</p>}
              {Object.values(errors).map((err, key) => {
                return <p key={`form-error-${key}`}>{Object.values(err)}</p>;
              })}
            </div>
          )}
        </form>
      );
    }}
  />
));
