import React, { useState } from "react";
import classNames from "classnames";
import Modal from "react-modal";
import ModalTitle from "components/UI/ModalTitle";
import Icon from "components/UI/Icon";
import Button from "components/UI/Button";
import WisGPTSummary from "components/Modals/WisGPTSummary";
import EditorComponent from "./EditorComponent";
import compose from "./compose";

import styles from "./Summary.module.scss";

export default compose(
  ({
    content,
    candidate,
    summary,
    setSummary,
    editSummary,
    setEditSummary,
    readMore,
    setReadMore,
    handleSaveSummary,
    hasLinkedInData,
    handleGenerateSummary,
    isGenerating,
    gptModal,
    setGptModal,
  }) => {
    return (
      <div className={styles.summary}>
        {(content && content !== "<p></p>" && content.length > 0) ||
        editSummary ? (
          <EditorComponent
            content={content}
            candidate={candidate}
            value={summary}
            isVisible={editSummary}
            showEditor={setEditSummary}
            handleSave={handleSaveSummary}
            handleTextEditorChange={setSummary}
            readMore={readMore}
            setReadMore={setReadMore}
            setGptModal={setGptModal}
            addBtnTitle="Add summary"
            editBtnTitle="Edit summary"
          />
        ) : (
          <div className={styles.summaryHead}>
            <div className={styles.titleGroup}>
              <h4 className={styles.titleGroupTitle}>Profile summary</h4>
              <p className={styles.titleGroupSubtitle}>
                Additional notes and comments about the candidate
              </p>
            </div>
            <div className={styles.buttons}>
              <div className={styles.button}>
                <Button
                  bStyle={["verySmall", "purple"]}
                  onClick={() => setGptModal(true)}
                >
                  <Icon icon="Magicwand" marginRight />
                  GPT Summary
                </Button>
              </div>
              <div className={styles.button}>
                <Button
                  bStyle={["verySmall", "bluetext"]}
                  onClick={() => setEditSummary(true)}
                >
                  <Icon icon="Plus" marginRight />
                  Add summary
                </Button>
              </div>
            </div>
          </div>
        )}
        <Modal
          isOpen={!!gptModal}
          onRequestClose={() => setGptModal(false)}
          shouldCloseOnOverlayClick
          className={classNames(styles.modal, "verySmall")}
        >
          <Button
            bStyle="close"
            type="button"
            onClick={() => setGptModal(false)}
          >
            <i className="fal fa-times" />
          </Button>
          <ModalTitle icon="Magicwand" color="purple">
            WisGPT LinkedIn Summary
          </ModalTitle>
          <WisGPTSummary
            setGptModal={setGptModal}
            disabled={!hasLinkedInData}
            action={() => handleGenerateSummary()}
            isGenerating={isGenerating}
          />
        </Modal>
      </div>
    );
  }
);
