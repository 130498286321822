import React, { useEffect } from "react";
import Layout from "layouts";
import styles from "./styles.module.scss";
import SidebarWrapper from "components/UI/SidebarWrapper";
import SidebarHeader from "components/UI/SidebarHeader";
import Timestamp from "react-timestamp";
import compose from "./compose";
import Button from "components/UI/Button";
import Icon from "components/UI/Icon";
import Chip from "components/v2/UI/Chip";
import Table from "components/v2/UI/Table";
import AdminMainSessions from "./AdminMainSessions";
import { useResponsive } from "utils/hooks/useResponsive";

export default compose(({ selectedOrg, data: { reviews }, match }) => {
  const { isMobile, responsive } = useResponsive();
  useEffect(() => {
    responsive(768, "min");
  }, [responsive]);
  const { org_id } = match.params;
  const rightSideCol = (
    <>
      <ol className={styles.olStyle}>
        <li>1. Create a new session to group assessments by date and type.</li>
        <li>
          2. Select a session to set up new assessments and get status updates.
        </li>
        <li>
          3. Review previous sessions to evaluate progress and plan supportive
          actions.
        </li>
      </ol>
    </>
  );

  console.log(reviews);

  const Statuses = {
    NOT_STARTED: {
      color: "grey",
      text: "NOT STARTED",
    },
    IN_PROGRESS: {
      color: "orange",
      text: "IN PROGRESS",
    },
    COMPLETED: {
      color: "green",
      text: "COMPLETED",
    },
  };

  const columns = [
    {
      Header: "Action",
      accessor: "session",
      Cell: ({ row: { original } }) => {
        console.log(original);
        return (
          <a
            href={`/${org_id}/reviews/${original.id}`}
            className={styles.aStyle}
          >
            <div className={styles.divStyle}>
              <span>
                {`${original.session.group.title} for ${original.session.employee_under_review.name}`}
              </span>
              {isMobile && (
                <Chip color={Statuses[original.status].color} variant="light">
                  {Statuses[original.status].text}
                </Chip>
              )}
            </div>
          </a>
        );
      },
    },
  ];

  if (!isMobile) {
    columns.push(
      {
        Header: "Company",
        accessor: "company",
        Cell: ({ row: { original } }) => (
          <p className={styles.pStyle}>{original.session.company}</p>
        ),
      },
      {
        Header: "Job title",
        accessor: "job_title",
        Cell: ({ row: { original } }) => {
          return (
            <p style={{ minWidth: "50px" }} className={styles.pStyle}>
              {original.session.job_title}
            </p>
          );
        },
      },
      {
        Header: "Date",
        accessor: "created_at",
        Cell: ({ value }) => {
          const date = new Date(parseInt(value));
          const formattedDate = `${date.getDate()}.${
            date.getMonth() + 1
          }.${date.getFullYear()}`;
          return (
            <p className={styles.pStyle}>
              <Timestamp date={date} autoUpdate options={{ format: "date" }} />
            </p>
          );
        },
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => (
          <Chip color={Statuses[value].color} variant="light">
            {Statuses[value].text}
          </Chip>
        ),
      },
      {
        Header: "",
        accessor: "id",
        Cell: ({ value }) => {
          return (
            <a href={`/${org_id}/reviews/${value}`}>
              <Button bStyle={["verySmall"]}>
                <Icon icon="Arrow" />
              </Button>
            </a>
          );
        },
      }
    );
  }

  return (
    <SidebarWrapper>
      <SidebarHeader noBreadcrumbs title="360 Assessment Tool"></SidebarHeader>
      {reviews.length > 0 && (
        <div className={styles.sidebarWrapperStyle}>
          <Layout title="Talent & Org Health Check" icon="360Assesment">
            <p>The table below displays your pending action items.</p>
            <p>Select a row to share your feedback.</p>
          </Layout>
          <div className={styles.layoutStyle}>
            <h4 className={styles.h4Style}>Pending actions</h4>

            {reviews && <Table columns={columns} data={reviews} />}
          </div>
        </div>
      )}

      {selectedOrg.adminRights && (
        <>
          <Layout variant="2-col-page" rightColContent={rightSideCol}>
            <p>
              The table below displays the status of your assessment sessions
            </p>
          </Layout>
          <div className={styles.adminRightsStyle}>
            <div className={styles.adminRightsInnerStyle}>
              <span className={styles.adminRightsIconStyle}>
                <Icon icon="Session" large />
              </span>
              <h3>Session history</h3>
            </div>
            <div>
              <AdminMainSessions />
            </div>
          </div>
        </>
      )}
    </SidebarWrapper>
  );
});
