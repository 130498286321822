import { compose, withHandlers, withState } from 'recompose'
import { graphql } from 'react-apollo'
import { loader } from 'graphql.macro'

const RESEND_INVITE = loader('./resendInvite3.graphql')
const RESEND_EXISTING_USER_INVITE = loader(
  './resendExistingUserInvite10.graphql'
)

export default compose(
  withState('btnState', 'setBtnState', {
    text: 'fad fa-paper-plane',
    isLoading: false,
    hoverText: 'Resend invite'
  }),
  graphql(RESEND_INVITE, { name: 'resendInviteMutation' }),
  graphql(RESEND_EXISTING_USER_INVITE, {
    name: 'resendExistingUserInviteMutation'
  }),
  withHandlers({
    handleResendInvite: ({
      invite,
      resendInviteMutation,
      setBtnState
    }) => () => {
      setBtnState({
        text: 'fas fa-spinner-third',
        isLoading: true,
        disabled: true,
        hoverText: 'Sending...'
      })
      resendInviteMutation({
        variables: {
          inviteId: invite.id
        }
      })
        .then((res) => {
          setBtnState({
            text: 'fas fa-check',
            isLoading: false,
            disabled: true,
            hoverText: 'Reminder sent!'
          })
        })
        .catch((err) => {
          setBtnState({
            text: 'fas fa-exclamation-triangle',
            isLoading: false,
            disabled: true,
            hoverText: 'Error :('
          })
        })
    },
    handleResendExistingUserInvite: ({
      invite,
      resendExistingUserInviteMutation,
      setBtnState
    }) => () => {
      setBtnState({
        text: 'fas fa-spinner-third',
        isLoading: true,
        disabled: true,
        hoverText: 'Sending...'
      })
      resendExistingUserInviteMutation({
        variables: {
          contractId: invite.contractId || invite.contract.id
        }
      })
        .then((res) => {
          setBtnState({
            text: 'fas fa-check',
            isLoading: false,
            disabled: true,
            hoverText: 'Reminder sent!'
          })
        })
        .catch((err) => {
          setBtnState({
            text: 'fas fa-exclamation-triangle',
            isLoading: false,
            disabled: true,
            hoverText: 'Error :('
          })
        })
    }
  })
)
