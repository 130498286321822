import React from "react";
import Button from "components/UI/Button";
import { ReactComponent as SurveyIntro } from "images/onboarding/survey-intro.svg";
import { ReactComponent as LockIcon } from "images/onboarding/lock.svg";
import styles from "./Reminder.module.scss";
import compose from "./compose";
import classNames from "classnames";
import Icon from "components/UI/Icon";

import { ReactComponent as CheckIcon } from "images/icons/Check-small.svg";

export default compose(({ setOpenModal, proceed, history }) => (
  <div className={styles.wrapper}>
    <div className={styles.backButton}>
      <Button bStyle={["none"]} onClick={() => history.goBack()}>
        <div className={styles.icon}>
          <Icon icon="Arrow" />
        </div>
      </Button>
    </div>
    <div className={styles.left}>
      <div className={styles.content}>
        <h2>A friendly reminder...</h2>
        <p>
          To benefit most from these insights, we urge you to take the time to
          answer as objectively as possible.
        </p>
        <div className={styles.listWrap}>
          <div className={styles.listItem}>
            <div className={styles.icon}>
              <Icon icon="Check" />
            </div>

            <p>
              Find a space where you will not be interrupted for the next{" "}
              <strong>20-25 minutes</strong>.
            </p>
          </div>

          <div className={styles.listItem}>
            <div className={styles.icon}>
              <Icon icon="Check" />
            </div>

            <p>
              Make sure that you are not tired, in a bad mood or hungry, as this
              can affect your answers and results.
            </p>
          </div>

          <div className={styles.listItem}>
            <div className={styles.icon}>
              <Icon icon="Check" />
            </div>

            <p>
              There are <strong>no right or wrong answers</strong>, the scores
              you receive are not ranks but indications of your preferences
              across a spectrum.
            </p>
          </div>
        </div>
        <div className={styles.disclaimer}>
          <div className={styles.icon}>
            <LockIcon />
          </div>
          <p>
            Your answers will not be shared with anyone. Only your overall
            scores relative to the general population will be added to your
            Personal Profile.
          </p>
        </div>
      </div>

      <Button bStyle={["primary"]} type="button" onClick={proceed}>
        Continue
      </Button>
    </div>
    <div className={classNames(styles.right, styles.grey)}>
      <div className={styles.img}>
        <img
          src={
            "https://storage.googleapis.com/iaeqpsxmmk/onboarding_images/onboarding_8.png"
          }
        />
      </div>
    </div>
  </div>
));
