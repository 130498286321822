import React, { useState } from "react";
import classNames from "classnames";
import Field from "components/UI/Field";
import Button from "components/UI/Button";
import compose from "./compose";

import styles from "./SubscriptionModal.module.scss";

import DOMPurify from "dompurify";

export default compose(
  ({
    data: { stripeProducts },
    createCheckoutSession,
    setSubscribeModal,
    setCouponCode,
  }) => {
    const [selected, setSelected] = useState(0);
    const [loading, setLoading] = useState(false);

    const getContentHtml = (content) => ({
      __html: DOMPurify.sanitize(content),
    });

    return (
      <div className={styles.wrapper}>
        <div className={styles.left}>
          <div className={styles.content}>
            <h4 className={styles.title}>You are in good company</h4>
            <div className={styles.logosImg} />
            <p className={styles.footerText} />
          </div>
        </div>
        <div className={styles.right}>
          <h2 className={styles.title}>Billing cycle</h2>
          <p className={styles.subtitle}>
            Choose between annual and monthly subscriptions. NB: The annual
            subscription comes with a 50% discount!
          </p>
          <div className={styles.boxes}>
            {stripeProducts.prices.map((product, index) => (
              <Button
                bStyle={["none"]}
                onClick={() => setSelected(index)}
                key={`price-${index}`}
              >
                <div
                  className={classNames(
                    styles.product,
                    product.interval === "year" && styles.featured,
                    selected === index && styles.selected
                  )}
                >
                  <div className={styles.top}>
                    <h4 className={styles.billingTitle}>
                      {`Pay ${product.interval}ly`}
                    </h4>
                    <p className={styles.billing}>{product.title}</p>
                    {product.interval === "year" ? (
                      <h3 className={styles.price}>{`${product.price}€`}</h3>
                    ) : (
                      <h3 className={styles.price}>{`${product.price}€`}</h3>
                    )}
                    <p className={styles.perSeat}>per seat per month</p>
                    {product.interval === "year" && (
                      <div className={styles.discount}>
                        <p>50% off</p>
                      </div>
                    )}
                  </div>
                  <div className={styles.table}>
                    <div className={styles.row}>
                      <p>Seats</p>
                      <p>{product.seatsUsed}</p>
                    </div>
                    <div className={styles.row}>
                      <p>Save</p>
                      <p>{`€${product.save} / yearly`}</p>
                    </div>
                    <div className={styles.row}>
                      <p>Pay today</p>
                      <p>{`€${product.payToday}`}</p>
                    </div>
                    <div className={styles.row}>
                      <p>Billed</p>
                      <p>{`${product.interval}ly`}</p>
                    </div>
                  </div>
                </div>
              </Button>
            ))}
          </div>
          <div className={styles.coupon}>
            <h5>Coupon code</h5>
            <Field
              white
              placeholder="Enter a coupon code"
              onChange={(e) => setCouponCode(e.target.value)}
            />
          </div>
          <div className={styles.buttonGroup}>
            <Button
              bStyle={["primary", "green", "large"]}
              onClick={() => {
                setLoading(true);
                createCheckoutSession(stripeProducts.prices[selected]);
              }}
            >
              <div className={styles.innerButton}>
                {loading ? <i className="far fa-spinner-third" /> : "Checkout"}
              </div>
            </Button>
            <Button
              bStyle={["secondary", "large"]}
              onClick={() => setSubscribeModal(false)}
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    );
  }
);
