import React from "react";
import Button from "components/UI/Button";
import DistributionBarChart from "components/Charts/DistributionBarChart";
import getAlignment from "utils/getAlignment";

import styles from "../TopValues.module.scss";

export default ({ teamWorkBest, data, updateModal, comparison }) => (
  <>
    {teamWorkBest.map((dim) => {
      const { colour, className, label } = getAlignment({
        dim,
        team: data,
        teamMember: comparison,
        resource: "values",
      });
      return (
        <div className={styles.row} key={dim.name}>
          <div className={styles.column}>
            <div className={styles.titleRow}>
              <h3 className={styles.title}>{dim.name}</h3>
              <span className={styles[className]}>{label}</span>
            </div>
            <p className={styles.subtitle}>{dim.definition}</p>

            <div className={styles.barChartWrapper}>
              <DistributionBarChart
                dim={dim}
                members={data.members}
                resource="values"
                colour={colour}
                highlight={comparison}
                candidate={comparison}
              />
            </div>
            <div className={styles.extremes}>
              <div className={styles.column}>
                <h5 className={styles.title}>Less important</h5>
                <p className={styles.text}>{dim.description_min}</p>
              </div>
              <div className={styles.column}>
                <h5 className={styles.title}>Very important</h5>
                <p className={styles.text}>{dim.description_max}</p>
              </div>
            </div>
          </div>
          <div className={styles.column}>
            <div className={styles.card}>
              <div className={styles.content}>
                <h5 className={styles.title}>
                  <i className="fas fa-user-shield" />
                  {`${comparison.comparisonTeam.name}' attitude`}
                </h5>
                <p className={styles.text}>{dim.valuesStrength}</p>
              </div>
              <div className={styles.footer}>
                <Button
                  bStyle={["none", "icon"]}
                  type="button"
                  onClick={() => updateModal(dim)}
                >
                  <i className="far fa-ellipsis-h" />
                  {`More on ${dim.name}`}
                </Button>
              </div>
            </div>
          </div>
        </div>
      );
    })}
  </>
);
