import React from "react";
import { withRouter } from "react-router-dom";
import { compose, withProps } from "recompose";
import { withApollo, graphql } from "react-apollo";
import renderLoader from "utils/loader";
import { loader } from "graphql.macro";
import scrollToTop from "utils/scrollToTop";
import FullPage from "components/Loaders/FullPage";
import logRead from "utils/logRead";

const TEAM_QUERY = loader("client/Queries/TeamQuery124.graphql");

export default compose(
  withApollo,
  withRouter,
  graphql(TEAM_QUERY, {
    options: (props) => {
      return {
        variables: {
          id: parseInt(props.match.params.teamId, 10),
        },
      };
    },
  }),
  renderLoader(() => <FullPage />),
  scrollToTop,
  logRead({
    obj_type: "team",
    obj_id: "data.team.id",
    subject_type: "member",
    subject_id: "match.params.memberId",
  }),
  withProps(
    ({
      match: {
        params: { memberId },
      },
      data: { team },
    }) => {
      return {
        teamMember: team.members.find(
          (member) => member.id === parseInt(memberId, 10)
        ),
      };
    }
  )
);
