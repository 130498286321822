import React from 'react'
import classNames from 'classnames'

import styles from './Wrapper.module.scss'

export default ({ children, left, superWide }) => (
  <div
    className={classNames(
      styles.wrapper,
      left && styles.left,
      superWide && styles.superWide
    )}
  >
    {children}
  </div>
)
