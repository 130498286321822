import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { graphql } from 'react-apollo'
import handleLoading from 'utils/handleLoading'
import { loader } from 'graphql.macro'
import withStore from 'utils/withStore'
import handleSubmit from './handleSubmit'

export default compose(
  withRouter,
  withStore('onboarding'),
  handleSubmit,
  handleLoading
)
