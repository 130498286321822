import React from "react";
import { compose, withState, withHandlers } from "recompose";
import { withRouter } from "react-router-dom";
import { graphql } from "react-apollo";
import handleLoading from "utils/loader";
import { loader } from "graphql.macro";
import withStore from "utils/withStore";
import purgeTeamFromCache from "utils/purgeTeamFromCache";
import FullPage from "components/Loaders/FullPage";

const ORGANIZATION_TEAMS_AND_POSITIONS = loader(
  "client/Queries/OrganizationTeamsAndPositions.graphql"
);
const CREATE_POSITION_MUTATION = loader(
  "client/Mutations/createPosition6.graphql"
);

export default compose(
  withRouter,
  withStore("selectedOrg"),
  withState("showMore", "setShowMore", false),
  withState("modal", "updateModal", { open: false }),
  withState("selectedTeam", "setSelectedTeam", -1),
  withState("positionName", "setPositionName", ""),
  graphql(CREATE_POSITION_MUTATION, {
    name: "createPosition",
    options: ({ data, client, selectedOrg }) => ({
      update: (proxy, { data: { createNewPosition } }) => {
        const positions = proxy.readQuery({
          query: ORGANIZATION_TEAMS_AND_POSITIONS,
          variables: {
            id: selectedOrg.id,
          },
        });

        positions.organization.allOpenPositions = [
          ...positions.organization.allOpenPositions,
          createNewPosition,
        ];

        proxy.writeQuery({
          query: ORGANIZATION_TEAMS_AND_POSITIONS,
          variables: {
            id: createNewPosition.parent_org_id,
          },
          data: positions,
        });

        if (
          proxy.data.data[
            `${createNewPosition.parentTeam.__typename}:${createNewPosition.parentTeam.id}`
          ]
        ) {
          purgeTeamFromCache({
            cache: client.cache.data,
            team_id: createNewPosition.parentTeam.id,
            proxy,
          });
        }
      },
    }),
  }),
  withHandlers({
    handleCreatePosition:
      ({ selectedOrg, selectedTeam, createPosition, history }) =>
      (e) => {
        createPosition({
          variables: {
            name: e.name,
            parent_org: selectedOrg.id,
            team_id: parseInt(selectedTeam, 10),
            template_id: e.template || null,
          },
        }).then((res) => {
          if (window.Intercom) {
            window.Intercom("trackEvent", "create-position", {
              id: res.data.createNewPosition.id,
            });
          }
          history.push(
            `/team/${
              res.data.team ? res.data.team.id : selectedTeam
            }/position/${res.data.createNewPosition.id}/edit`
          );
        });
      },
    handleDeletePositionTemplate:
      ({ deletePositionTemplate, selectedOrg, setRemoveModal }) =>
      (id) => {
        deletePositionTemplate({
          variables: {
            id,
            org_id: parseInt(selectedOrg.id, 10),
          },
        }).then((res) => {
          setRemoveModal(false);
        });
      },
  })
);
