import React, { useEffect } from "react"
import { Form, Field } from "react-final-form"
import Button from "components/UI/Button"
import Icon from "components/UI/Icon"
import compose from "./compose"
import styles from "./NewTeam.module.scss"
import validate from "./validate"

export default compose(({ onSubmit, updateModal, history }) => {
  useEffect(() => {
    if (history.location.search) {
      history.replace(history.location.pathname)
    }
  }, history)

  return (
    <div className={styles.modalWrapper}>
      <div className={styles.modalHead}>
        <h3 className={styles.title}>
          <div className={styles.icon}>
            <Icon icon="Logo" />
          </div>
          Create a new team
        </h3>
        <p>Use the form below to create a new team.</p>
      </div>

      <div className={styles.newTeam}>
        <Form
          onSubmit={onSubmit}
          validate={(state) => validate(state)}
          autoComplete="new-password"
          render={({
            submitError,
            handleSubmit,
            values,
            form,
            submitting,
            pristine,
            errors,
            valid,
          }) => (
            <form onSubmit={() => null} autoComplete="off">
              <div className={styles.row}>
                <h5>Team name</h5>
                <Field
                  name="name"
                  component="input"
                  type="text"
                  placeholder="Enter team name"
                  autoComplete="off"
                  required
                />
              </div>
              {(!valid || submitError) && (
                <div className={styles.submitError}>
                  {submitError && <p>{submitError.graphQLErrors[0]}</p>}
                  {Object.values(errors).map((err, key) => (
                    <p key={key}>{err}</p>
                  ))}
                </div>
              )}
              <div className={styles.buttonGroup}>
                <Button
                  bStyle={["secondary", "small"]}
                  onClick={() => updateModal("")}
                >
                  Cancel
                </Button>
                <Button
                  bStyle={["primary", "small"]}
                  type="submit"
                  disabled={!values.name}
                  onClick={(e) => onSubmit(e, values)}
                >
                  Create team
                </Button>
              </div>
            </form>
          )}
        />
      </div>
    </div>
  )
})
