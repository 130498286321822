import React from "react";
import PropTypes from "prop-types";
import InputWrapper from "components/UI/InputWrapper/index";
import classnames from "classnames";
import styles from "./styles.module.scss";

const InputGroup = ({ children, label, description }) => {
  return (
    <>
      <div className={styles.inputGroupWrapper}>
        <p
          className={classnames(
            "design-system__weight--bold",
            "design-system__label"
          )}
        >
          {label}
        </p>
        <p
          className={classnames(
            "design-system__text",
            "design-system__size--14"
          )}
        >
          {description}
        </p>
      </div>
      <InputWrapper>{children}</InputWrapper>
    </>
  );
};

InputGroup.propTypes = {
  label: PropTypes.string.isRequired,
  description: PropTypes.string,
  children: PropTypes.node.isRequired,
};
export default InputGroup;
