import React from "react";
import classNames from "classnames";
import styles from "./index.module.scss";

export default ({ children, loadedMembers, isCurrentUser }) => {
  // const noTeamMembers = loadedMembers.length === 0
  // if (noTeamMembers) return null

  const scores = [[], [], [], [], []];

  loadedMembers.forEach((member) => {
    const big5 = member.personality.filter((dim) => !dim.parent_id);

    big5.forEach((dim, i) => {
      scores[i].push(dim.score);
    });
  });

  const scoresRange = scores.map((s) => {
    if (!s[0] && typeof s[0] !== "number") return { min: 50, max: 50 };
    return { min: Math.min(...s), max: Math.max(...s) };
  });

  const max = scoresRange.map((s) => s.max);
  const min = scoresRange.map((s) => s.min);
  const maxP = max.map((p, i) => {
    const x = parseFloat(((100 / 5 / 2 + (100 / 5) * i) * 570) / 100);
    const y = parseFloat(100 - p);
    const h1 = { x: x - parseFloat(((100 / 5 / 3) * 570) / 100), y };
    const h2 = { x: x + parseFloat(((100 / 5 / 3) * 570) / 100), y };
    return {
      x,
      y,
      h1,
      h2,
    };
  });
  const minP = min
    .map((p, i) => {
      const x = parseFloat(((100 / 5 / 2 + (100 / 5) * i) * 570) / 100);
      const y = parseFloat(100 - p);
      const h1 = { x: x + parseFloat(((100 / 5 / 3) * 570) / 100), y };
      const h2 = { x: x - parseFloat(((100 / 5 / 3) * 570) / 100), y };
      return {
        x,
        y,
        h1,
        h2,
      };
    })
    .reverse();

  const maxCurve = maxP.map((p, i) => {
    if (i + 1 === maxP.length) return `L${minP[0].x},${minP[0].y}`;
    const next = maxP[i + 1];
    if (i === 0) {
      return `M${p.x},${p.y} C${p.h2.x},${p.h2.y} ${next.h1.x},${next.h1.y} ${next.x},${next.y}`;
    }
    return `S${next.h1.x},${next.h1.y} ${next.x},${next.y}`;
  });

  const minCurve = minP.map((p, i) => {
    if (i + 1 === minP.length) return "Z";
    const next = minP[i + 1];
    if (i === 0) {
      return `C${p.h2.x},${p.h2.y} ${next.h1.x},${next.h1.y} ${next.x},${next.y}`;
    }
    return `S${next.h1.x},${next.h1.y} ${next.x},${next.y}`;
  });

  return (
    <svg
      viewBox="0 0 570 100"
      xmlns="http://www.w3.org/2000/svg"
      encoding="UTF-8"
      className={styles.distribution}
    >
      <path
        className={classNames(
          styles.distributionCurve,
          isCurrentUser && styles.highlight
        )}
        d={`${[...maxCurve, ...minCurve].join()}`}
      />
      {children}
    </svg>
  );
};
