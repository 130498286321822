import React from "react"
import classNames from "classnames"
import Title from "components/UI/Title"
import styles from "./HiringSuggestions.module.scss"

export default ({ team, noStyle }) => (
  <div className={styles.main}>
    <div className={styles.wrapper}>
      {!noStyle && (
        <Title text="Suggestions for hiring" icon="fas fa-users-medical" />
      )}
      <div className={classNames(styles.card, noStyle && styles.noStyle)}>
        <div className={styles.column}>
          <h3 className={styles.title}>
            <i className="far fa-puzzle-piece" />
            Diversify the team
          </h3>
          <h4 className={styles.subtitle}>
            Bring in additional strengths and new perspectives by hiring someone
            with the following traits:
          </h4>
          <ul className={styles.list}>
            {team.hiringSuggestions.personality.map((suggestion) => (
              <li key={suggestion.title}>
                <h4>{suggestion.title}</h4>
                <p>{suggestion.desc}</p>
              </li>
            ))}
          </ul>
        </div>
        <div className={styles.column}>
          <h3 className={styles.title}>
            <i className="fas fa-flag-checkered" />
            Keep the values aligned
          </h3>
          <h4 className={styles.subtitle}>
            Increase the team’s cohesiveness by hiring someone who emphasizes
            the following values:
          </h4>
          <ul className={styles.list}>
            {team.hiringSuggestions.values.map((suggestion) => (
              <li key={suggestion.title}>
                <h4>{suggestion.title}</h4>
                <p>{suggestion.desc}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  </div>
)
