import React from "react";
import ViewerAvatar from "components/ViewerAvatar";
import Button from "components/UI/Button";
import styles from "./NewComment.module.scss";

export default ({
  handleCreateComment,
  setComment,
  comment,
  disabled,
  disabledText,
}) => (
  <div className={styles.wrapper}>
    <div className={styles.avatarWrapper}>
      <ViewerAvatar size="small" />
    </div>
    <form onSubmit={handleCreateComment}>
      <div className={styles.inputWrapper}>
        <textarea
          rows="3"
          cols="70"
          placeholder="Leave a comment"
          onChange={(e) => setComment(e.target.value)}
          value={comment}
          id="commentTextarea"
          data-gramm_editor="false"
        />
      </div>
      {comment && (
        <>
          <div className={styles.buttons}>
            <Button
              bStyle={["primary", "small"]}
              type="submit"
              disabled={disabled}
            >
              Save
            </Button>
            <Button
              bStyle={["newsimple"]}
              type="button"
              onClick={() => setComment("")}
            >
              Cancel
            </Button>
          </div>
          <div className={styles.bottomText}>
            {disabled && disabledText && <p>{disabledText}</p>}
          </div>
        </>
      )}
    </form>
  </div>
);
