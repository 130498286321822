import React from "react";
import ValuesChart from "components/Charts/Values";
import ValuesModal from "components/Modals/Value";
import ValuesPlaceholder from "components/Placeholders/ValuesPlaceholder";
import compose from "./compose";
import styles from "./Chart.module.scss";

export default compose(
  ({
    data: { personalProfile },
    openModal,
    updateModal,
    determineIfValueIsImportant,
  }) => {
    if (personalProfile.valuesChart.readyToRender) {
      return (
        <div className={styles.wrap}>
          <ValuesChart
            data={personalProfile.valuesChart}
            highlight={personalProfile.valuesChart}
            valuesChart={personalProfile.valuesChart}
            values={personalProfile.valuesChart.values}
            updateModal={updateModal}
            resource="personal"
          />
          <ValuesModal
            resource="personal"
            valueGroups={personalProfile.valuesChart.valueGroups}
            values={personalProfile.valuesChart.values}
            dim={openModal}
            updateModal={updateModal}
            isImportant={determineIfValueIsImportant()}
          />
        </div>
      );
    }
    return <ValuesPlaceholder resource="personal" />;
  }
);
