import React, { useEffect } from "react";
import Modal from "react-modal";
import classNames from "classnames";
import SidebarWrapper from "components/UI/SidebarWrapper";
import Footer from "components/Footer";
import { useSidebar } from "utils/sidebarProvider";
import Row from "./Row";
import RemoveOrg from "./RemoveOrg";
import compose from "./compose";
import styles from "./Organizations.module.scss";

export default compose(
  ({
    data: { account },
    history,
    showDeletePrompt,
    updateDeletePrompt,
    deleteOrg,
  }) => {
    const { contentContext } = useSidebar();
    const [content, setContent] = contentContext;

    const sidebarContent = [
      {
        items: [
          {
            title: "General",
            action: () => history.push("/account/" + account.id + "/settings"),
            style: "",
          },
          {
            title: "Members",
            action: () => history.push("/account/" + account.id + "/members"),
            style: "",
          },
          {
            title: "Organisations",
            action: () =>
              history.push("/account/" + account.id + "/organisations"),
            style: "active",
          },
          {
            title: "Billing",
            action: () => history.push("/account/" + account.id + "/billing"),
            style: "",
          },
        ],
      },
    ];

    useEffect(() => {
      setContent(sidebarContent);
    }, []);

    return (
      <SidebarWrapper noHeader>
        <div className={styles.wrapper}>
          <div className={styles.topWrapper}>
            <h2 className={styles.title}>Organisations</h2>
            <p className={styles.name}>{account.name}</p>
          </div>
          <table>
            <tbody>
              <tr className={styles.tableHead}>
                <td className={classNames(styles.withButton, styles.name)}>
                  Name
                </td>
                <td className={classNames(styles.withButton, styles.members)}>
                  Members
                </td>
                <td className={classNames(styles.withButton, styles.teams)}>
                  Teams
                </td>
                <td className={classNames(styles.withButton, styles.positions)}>
                  Open positions
                </td>
                <td className={classNames(styles.withButton, styles.role)}>
                  Role
                </td>
                <td />
                <td />
              </tr>

              {account.organizations
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((org, i) => (
                  <Row
                    key={`${org.name}-${org.id}-${Math.random(10)}`}
                    organization={org}
                    updateDeletePrompt={updateDeletePrompt}
                    accountId={account.id}
                  />
                ))}
            </tbody>
          </table>

          <Modal
            isOpen={!!showDeletePrompt}
            onRequestClose={() => updateDeletePrompt(false)}
            shouldCloseOnOverlayClick
            className={styles.modal}
          >
            <RemoveOrg
              deleteOrg={deleteOrg}
              showDeletePrompt={showDeletePrompt}
              updateDeletePrompt={updateDeletePrompt}
            />
          </Modal>
        </div>
      </SidebarWrapper>
    );
  }
);
