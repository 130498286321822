import React from "react";
import { withRouter } from "react-router-dom";
import { compose, withState } from "recompose";
import { graphql } from "react-apollo";
import { loader } from "graphql.macro";
import handleLoading from "utils/loader";
import Circles from "components/Loaders/Circles";
import handleSaveSummary from "./handleSaveSummary";

const SAVE_CANDIDATE_SUMMARY = loader("./SaveText1.graphql");
const CANDIDATE_QUERY = loader("client/Queries/CandidateQuery198.graphql");

// Internet Explorer 6-11
const isIE = /* @cc_on!@ */ false || !!document.documentMode;

export default compose(
  withState("isIE", "setIsIE", !!isIE),
  withState("showSummary", "setShowSummary", false),
  withState("readMore", "setReadMore", false),
  withRouter,
  graphql(SAVE_CANDIDATE_SUMMARY, {
    name: "saveSummary",
    options: (props) => {
      return {
        update: (proxy, { data: { saveCandidateSummary } }) => {
          const type = props.match.params.candidateType;
          const data = proxy.readQuery({
            query: CANDIDATE_QUERY,
            variables: {
              user_id:
                type === "candidate"
                  ? parseInt(props.match.params.candidateId, 10)
                  : null,
              person_id:
                type === "person"
                  ? parseInt(props.match.params.candidateId, 10)
                  : null,
              team_id: parseInt(props.match.params.teamId, 10),
              position_id: parseInt(props.match.params.positionId, 10),
              org_id: parseInt(props.match.params.orgId, 10),
            },
          });

          data.candidateInTeam.candidateSummary =
            saveCandidateSummary.candidateSummary;
          proxy.writeQuery({
            query: CANDIDATE_QUERY,
            variables: {
              user_id:
                type === "candidate"
                  ? parseInt(props.match.params.candidateId, 10)
                  : null,
              person_id:
                type === "person"
                  ? parseInt(props.match.params.candidateId, 10)
                  : null,
              team_id: parseInt(props.match.params.teamId, 10),
              position_id: parseInt(props.match.params.positionId, 10),
              org_id: parseInt(props.match.params.orgId, 10),
            },
            data,
          });
        },
      };
    },
  }),
  handleLoading(() => <Circles />),
  handleSaveSummary
);
