import React from 'react'
import Wrapper from 'components/UI/Wrapper'
import Title from 'components/UI/Title'
import Subtitle from 'components/UI/Subtitle'
import styles from './Personality.module.scss'
import PersonalitySection from './PersonalitySection'

export default ({ user }) => (
  <div className={styles.main}>
    <Wrapper>
      <Title text="Personality map" icon="fas fa-dna" />
      <Subtitle text="Your unique traits and behavioural characteristics." />
      <Subtitle
        verySmall
        text="How you interact with other people and the surrounding environment."
      />
      <PersonalitySection user={user} />
    </Wrapper>
  </div>
)
