import { withState, compose, withHandlers } from 'recompose'
import { graphql, withApollo } from 'react-apollo'
import { withRouter } from 'react-router-dom'
import { loader } from 'graphql.macro'
import withStore from 'utils/withStore'

import { ACCOUNT_LIMIT_EXCEEDED } from 'store/actionTypes'

const ADD_TEAM_MEMBERS = loader('./addTeamMembers6.graphql')

export default compose(
  withRouter,
  withApollo,
  withState('isLoading', 'setLoading', false),
  withStore(['accountLimit', 'account']),
  graphql(ADD_TEAM_MEMBERS, {
    name: 'addTeamMembers',
    options: ({ refetch }) => {
      return {
        refetchQueries: ['TEAM', 'OPENPOSITION', 'CANDIDATEINTEAM'],
      }
    }
  }),
  withHandlers({
    handleAddTeamMembers: ({
      addTeamMembers,
      updateModal,
      team,
      setLoading,
      dispatch,
      account,
      refetch,
      client
    }) => (list) => {
      setLoading(true)
      const users = list.map((i) => {
        return {
          obj_id: i.id,
          type: i.type,
          email: i.email
        }
      })

      addTeamMembers({
        variables: {
          id: team.id,
          users,
          org_id: team.organization_id
        }
      }).then(({ data: { addTeamMembers: { success, message } } }) => {
        if (message === 'Account limit exceeded') {
          dispatch({ type: ACCOUNT_LIMIT_EXCEEDED, payload: { account } })
        }

        refetch()
        // client.reFetchObservableQueries(['CANDIDATEINTEAM'])
        updateModal('')
      })
    }
  })
)
