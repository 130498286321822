import { matchPath } from "react-router-dom";
import { loader } from "graphql.macro";
import setWhitelalbel from "utils/setWhitelabel";

import {
  SET_ACCOUNT,
  SET_ROUTE_PARAMS,
  SELECT_ORG,
  TOGGLE_STATE_CHANGE_IN_PROGRESS,
} from "store/actionTypes";

const GET_CURRENT_ORG_AND_ACCOUNT_DATA = loader(
  "./CurrentOrgAndAccountData.graphql"
);

const SWITCH_ACCOUNT = loader("client/Mutations/switchAccount.graphql");

export default ({
  route,
  client,
  children,
  dispatch,
  account,
  selectedOrg,
}) => {
  if (
    (!route.isDirty && !route.stateChangeInProgress) ||
    route.current === "/login"
  )
    return;

  if (route.isDirty) {
    const routes = children.map((child) =>
      Object.assign({}, child.props, { strict: true })
    );

    const { params } = routes
      .map((routerObj) => {
        return matchPath(route.current, {
          path: routerObj.path,
          exact: true,
          strict: true,
        });
      })
      .filter(Boolean)[0];

    const dispatchCondition =
      [
        "/dashboard",
        "/personal",
        "/teams",
        "/hiring",
        `/share/candidate/${params.secret}`,
      ].includes(route.current) ||
      ["teamId", "orgId", "positionId", "accountId"].some((key) =>
        Object.keys(params).includes(key)
      );

    if (dispatchCondition) {
      dispatch({
        type: SET_ROUTE_PARAMS,
        payload: {
          params,
          current: route.current,
        },
      });
    }
  }

  if (!route.isDirty && route.stateChangeInProgress) {
    client
      .query({
        query: GET_CURRENT_ORG_AND_ACCOUNT_DATA,
        variables: route,
        fetchPolicy: "network-only",
      })
      .then(({ data: { currentOrgAndAccountData } }) => {
        if (!currentOrgAndAccountData) return;
        const { whitelabel } = currentOrgAndAccountData.account;

        setWhitelalbel(whitelabel);

        dispatch({
          type: TOGGLE_STATE_CHANGE_IN_PROGRESS,
        });

        if (!currentOrgAndAccountData) return;

        if (account.id !== currentOrgAndAccountData.account.id) {
          // console.log('SETTING AND SWITCHING ACCOUNT')
          dispatch({
            type: SET_ACCOUNT,
            payload: currentOrgAndAccountData.account,
          });
          client.mutate({
            mutation: SWITCH_ACCOUNT,
            variables: {
              id: currentOrgAndAccountData.account.id,
            },
          });
        }

        if (
          currentOrgAndAccountData.organization &&
          selectedOrg.id !== currentOrgAndAccountData.organization.id
        ) {
          dispatch({
            type: SELECT_ORG,
            payload: currentOrgAndAccountData.organization,
          });
        }
      });
  }
};
