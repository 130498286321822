import { withHandlers, compose, withProps, withState } from "recompose";
import { graphql } from "react-apollo";
import { withRouter } from "react-router-dom";
import { loader } from "graphql.macro";
import withStore from "utils/withStore";

import handleLoading from "utils/handleLoading";

const PLAYERS_WITH_EFFECTIENESS_SCORES = loader(
  "./playWithEffectivenessScores2.graphql"
);
const SEND_EMAIL_TO_PLAYERS = loader("./sendEmailToPlayers.graphql");

export default compose(
  withRouter,
  withStore("viewer"),
  withState("remindLoading", "setRemindLoading", false),
  withState("selectedDimension", "setSelectedDimension", 0),
  graphql(PLAYERS_WITH_EFFECTIENESS_SCORES, {
    options: ({ play }) => ({
      variables: { play_id: play.id },
    }),
  }),
  handleLoading,
  graphql(SEND_EMAIL_TO_PLAYERS, {
    name: "sendInviteEmails",
  }),
  withHandlers({
    handleSendInviteEmail:
      ({ sendInviteEmails, play, setRemindLoading }) =>
      async () => {
        setRemindLoading(true);
        const success = await sendInviteEmails({
          variables: { play_id: play.id },
        });
        if (success) {
          setRemindLoading(false);
        }
      },
  })
);
