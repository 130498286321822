import React from "react";
import {
  compose,
  branch,
  renderComponent,
  withState,
  lifecycle,
} from "recompose";
import { withRouter } from "react-router-dom";

import axios from "utils/axios";
import Circles from "components/Loaders/Circles";
import { getInvite } from "client/authRoutes";
import withStore from "utils/withStore";
import { SET_ACCOUNT } from "store/actionTypes";

export default compose(
  withRouter,
  withState("loading", "setLoading", true),
  withState("invite", "setInvite", {}),
  withStore("account"),
  lifecycle({
    componentDidMount() {
      axios
        .get(`${getInvite}/${this.props.match.params.inviteSecret}`)
        .then(({ data }) => {
          this.props.setInvite(data);
          this.props.setLoading(false);
          this.props.dispatch({
            type: SET_ACCOUNT,
            payload: data.account,
          });
        })
        .catch((err) => {
          console.error(err);
          this.props.history.push("/login");
        });
    },
  }),
  branch(
    (props) => props.loading,
    renderComponent(() => (
      <div style={{ height: "100vh" }}>
        <Circles />
      </div>
    ))
  )
);
