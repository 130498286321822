import React from 'react'
import {
  compose,
  withState,
  withHandlers,
  branch,
  renderComponent,
  withProps
} from 'recompose'
import { graphql, withApollo } from 'react-apollo'
import { loader } from 'graphql.macro'
import { withRouter } from 'react-router-dom'
import Circles from 'components/Loaders/Circles'
import handleDragEnd from './handleDragEnd'

const TEAM_GOALS = loader('../SetGoals/TeamGoals1.graphql')
const TEAM_GOAL_GROUPS = loader('./TeamGoalGroups1.graphql')
const CREATE_TEAM_GOAL_GROUP = loader('./CreateTeamGoalGroup1.graphql')
const UPDATE_TEAM_GOAL_GROUP = loader('./UpdateTeamGoalGroup1.graphql')
const UPDATE_TEAM_GOAL_GROUP_ID = loader('./updateTeamGoalGroupId1.graphql')
const DELETE_TEAM_GOAL_GROUP = loader('./DeleteTeamGoalGroup.graphql')

export default compose(
  withRouter,
  withApollo,
  graphql(TEAM_GOALS, {
    name: 'TEAM_GOALS',
    options: (props) => {
      return {
        variables: {
          team_id: parseInt(props.match.params.teamId, 10),
          play_id: parseInt(props.match.params.toolId, 10)
        },
        notifyOnNetworkStatusChange: true
      }
    }
  }),
  graphql(TEAM_GOAL_GROUPS, {
    name: 'TEAM_GOAL_GROUPS',
    options: (props) => {
      return {
        variables: {
          team_id: parseInt(props.match.params.teamId, 10),
          play_id: parseInt(props.match.params.toolId, 10)
        },
        notifyOnNetworkStatusChange: true
      }
    }
  }),

  branch(
    (props) => {
      const condition = (props.TEAM_GOALS && props.TEAM_GOALS.loading)
        || (props.TEAM_GOAL_GROUPS && props.TEAM_GOAL_GROUPS.loading)
      return condition
    },
    renderComponent(() => (
      <div style={{ height: '100vh' }}>
        <Circles />
      </div>
    ))
  ),
  withProps(
    ({ TEAM_GOALS: { teamGoals }, TEAM_GOAL_GROUPS: { teamGoalGroups } }) => {
      return {
        teamGoals,
        teamGoalGroups
      }
    }
  ),
  withState('ungroupedGoals', 'setUngroupedGoals', ({ teamGoals }) => teamGoals.filter(g => g.groupId === null)),
  graphql(UPDATE_TEAM_GOAL_GROUP, {
    name: 'updateTeamGoalGroup'
  }),
  graphql(CREATE_TEAM_GOAL_GROUP, {
    name: 'createTeamGoalGroup',
    options: (props) => {
      return {
        update: (proxy, { data: { createTeamGoalGroup } }) => {
          const data = proxy.readQuery({
            query: TEAM_GOALS,
            variables: {
              team_id: parseInt(props.match.params.teamId, 10),
              play_id: parseInt(props.match.params.toolId, 10)
            }
          })

          const goalIndex = data.teamGoals.findIndex(
            g => g.id === createTeamGoalGroup.goals[0].id
          )

          data.teamGoals[goalIndex].groupId = createTeamGoalGroup.id

          props.setUngroupedGoals(
            data.teamGoals.filter(
              g => !g.groupId && g.id !== createTeamGoalGroup.goals[0].id
            )
          )

          proxy.writeQuery({
            query: TEAM_GOALS,
            variables: {
              team_id: parseInt(props.match.params.teamId, 10),
              play_id: parseInt(props.match.params.toolId, 10)
            },
            data
          })

          const groupsData = proxy.readQuery({
            query: TEAM_GOAL_GROUPS,
            variables: {
              team_id: parseInt(props.match.params.teamId, 10),
              play_id: parseInt(props.match.params.toolId, 10)
            }
          })

          groupsData.teamGoalGroups = [
            ...groupsData.teamGoalGroups,
            createTeamGoalGroup
          ]

          proxy.writeQuery({
            query: TEAM_GOAL_GROUPS,
            variables: {
              team_id: parseInt(props.match.params.teamId, 10),
              play_id: parseInt(props.match.params.toolId, 10)
            },
            data: groupsData
          })
        }
      }
    }
  }),
  graphql(UPDATE_TEAM_GOAL_GROUP_ID, {
    name: 'updateTeamGoalGroupId',
    options: (props) => {
      return {
        update: (proxy, { data: { updateTeamGoal } }) => {
          const goalsData = proxy.readQuery({
            query: TEAM_GOALS,
            variables: {
              team_id: parseInt(props.match.params.teamId, 10),
              play_id: parseInt(props.match.params.toolId, 10)
            }
          })

          const groupsData = proxy.readQuery({
            query: TEAM_GOAL_GROUPS,
            variables: {
              team_id: parseInt(props.match.params.teamId, 10),
              play_id: parseInt(props.match.params.toolId, 10)
            }
          })

          const inGroupIndex = groupsData.teamGoalGroups.findIndex(g => g.goals.some(goal => goal.id === updateTeamGoal.id))

          if (inGroupIndex > -1) {
            const goalIndex = groupsData.teamGoalGroups[
              inGroupIndex
            ].goals.findIndex(goal => goal.id === updateTeamGoal.id)
            groupsData.teamGoalGroups[inGroupIndex].goals.splice(goalIndex, 1)

            if (updateTeamGoal.groupId) {
              const groupIndex = groupsData.teamGoalGroups.findIndex(
                g => g.id === updateTeamGoal.groupId
              )
              groupsData.teamGoalGroups[groupIndex].goals.splice(
                0,
                0,
                updateTeamGoal
              )
            }
          } else if (updateTeamGoal.groupId) {
            const groupIndex = groupsData.teamGoalGroups.findIndex(
              g => g.id === updateTeamGoal.groupId
            )
            groupsData.teamGoalGroups[groupIndex].goals.splice(
              0,
              0,
              updateTeamGoal
            )
          }
          proxy.writeQuery({
            query: TEAM_GOAL_GROUPS,
            variables: {
              team_id: parseInt(props.match.params.teamId, 10),
              play_id: parseInt(props.match.params.toolId, 10)
            },
            data: groupsData
          })

          const goalIndex = goalsData.teamGoals.findIndex(
            g => g.id === updateTeamGoal.id
          )

          goalsData.teamGoals[goalIndex].groupId = updateTeamGoal.groupId

          proxy.writeQuery({
            query: TEAM_GOALS,
            variables: {
              team_id: parseInt(props.match.params.teamId, 10),
              play_id: parseInt(props.match.params.toolId, 10)
            },
            data: goalsData
          })

          props.setUngroupedGoals(goalsData.teamGoals.filter(g => !g.groupId))
        }
      }
    }
  }),
  graphql(DELETE_TEAM_GOAL_GROUP, {
    name: 'deleteTeamGoalGroup',
    options: (props) => {
      return {
        update: (proxy, { data: { deleteTeamGoalGroup } }) => {
          const goalsData = proxy.readQuery({
            query: TEAM_GOALS,
            variables: {
              team_id: parseInt(props.match.params.teamId, 10),
              play_id: parseInt(props.match.params.toolId, 10)
            }
          })

          const groupsData = proxy.readQuery({
            query: TEAM_GOAL_GROUPS,
            variables: {
              team_id: parseInt(props.match.params.teamId, 10),
              play_id: parseInt(props.match.params.toolId, 10)
            }
          })

          groupsData.teamGoalGroups = groupsData.teamGoalGroups.filter(
            g => g.id !== deleteTeamGoalGroup
          )

          groupsData.goalsData = goalsData.teamGoals.map((group) => {
            if (group.groupId === deleteTeamGoalGroup) group.groupId = null
            return group
          })

          proxy.writeQuery({
            query: TEAM_GOAL_GROUPS,
            variables: {
              team_id: parseInt(props.match.params.teamId, 10),
              play_id: parseInt(props.match.params.toolId, 10)
            },
            data: groupsData
          })

          proxy.writeQuery({
            query: TEAM_GOALS,
            variables: {
              team_id: parseInt(props.match.params.teamId, 10),
              play_id: parseInt(props.match.params.toolId, 10)
            },
            data: groupsData
          })

          props.setUngroupedGoals(goalsData.teamGoals.filter(g => !g.groupId))
        }
      }
    }
  }),
  withHandlers({
    handleAddGroupIdToGoal: ({ match, updateTeamGoalGroupId }) => ({
      group_id,
      goal_id
    }) => {
      updateTeamGoalGroupId({
        variables: {
          id: goal_id,
          team_id: parseInt(match.params.teamId, 10),
          play_id: parseInt(match.params.toolId, 10),
          group_id
        }
      })
    },
    handleDeleteTeamGoalGroup: ({ deleteTeamGoalGroup }) => (groupId) => {
      deleteTeamGoalGroup({
        variables: {
          groupId
        }
      })
    },
    handleCreateTeamGoalGroup: ({ match, createTeamGoalGroup }) => (goalId) => {
      createTeamGoalGroup({
        variables: {
          team_id: parseInt(match.params.teamId, 10),
          play_id: parseInt(match.params.toolId, 10),
          goal_id: goalId
        }
      })
    },
    handleUpdateTeamGoalGroup: ({ match, updateTeamGoalGroup }) => (
      groupId,
      title
    ) => {
      updateTeamGoalGroup({
        variables: {
          groupId,
          title
        }
      })
    }
  }),

  withHandlers({
    onDragEnd: ({
      ungroupedGoals,
      handleCreateTeamGoalGroup,
      teamGoals,
      teamGoalGroups,
      setUngroupedGoals,
      handleAddGroupIdToGoal
    }) => ({ source, destination, draggableId }) => handleDragEnd({
      setUngroupedGoals,
      ungroupedGoals,
      handleCreateTeamGoalGroup,
      source,
      destination,
      draggableId,
      teamGoals,
      teamGoalGroups,
      handleAddGroupIdToGoal
    })
  })
)
