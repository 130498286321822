import React from 'react'
import { compose, withState } from 'recompose'
import { graphql } from 'react-apollo'
import renderLoader from 'utils/loader'
import { loader } from 'graphql.macro'
import CardLoader from 'components/Loaders/CardLoader'

import styles from './Members.module.scss'

const ORG_MEMBERS = loader('client/Queries/TalentPoolQuery9.graphql')

const Skeleton = () => (
  <div className={styles.skeleton}>
    <div className={styles.cardLoaderWrapper}>
      <CardLoader small />
    </div>
    <div className={styles.cardLoaderWrapper}>
      <CardLoader small />
    </div>
    <div className={styles.cardLoaderWrapper}>
      <CardLoader small />
    </div>
    <div className={styles.cardLoaderWrapper}>
      <CardLoader small />
    </div>
    <div className={styles.cardLoaderWrapper}>
      <CardLoader small />
    </div>
  </div>
)

export default compose(
  withState('showMore', 'setShowMore', false),
  withState('inviteModal', 'updateInviteModal', false),
  withState('removeDataModal', 'updateRemoveDataModal', false),
  withState('overlayClose', 'setOverlayClose', true),
  withState('memberModal', 'updateMemberModal', ''),
  graphql(ORG_MEMBERS, {
    options: (props) => ({
      variables: {
        id: parseInt(props.selectedOrg.id, 10),
        offset: 0,
        limit: 10,
        orderDirection: 'ASC',
        orderColumn: 'state'
      },
      notifyOnNetworkStatusChange: true
    })
  }),
  renderLoader(Skeleton)
)
