import React from 'react'
import { compose, withState, withProps } from 'recompose'
import { withRouter } from 'react-router-dom'
import handleLoading from 'utils/loader'
import scrollToTop from 'utils/scrollToTop'
import Circles from 'components/Loaders/Circles'
import { ReactComponent as IconMust } from 'images/icons/competencies/colored-must.svg'
import { ReactComponent as IconImportant } from 'images/icons/competencies/colored-important.svg'
import { ReactComponent as IconGood } from 'images/icons/competencies/colored-good.svg'

const positionHeaders = [
  {
    title: 'Must have',
    style: 'green',
    icon: (
      <div data-star='true'>
        <i className='fas fa-star' />
        <i className='fas fa-star' />
        <i className='fas fa-star' />
      </div>
    )
  },
  {
    title: 'Important',
    style: 'primary',
    icon: (
      <div data-star='true'>
        <i className='fas fa-star' />
        <i className='fas fa-star' />
      </div>
    )
  },
  {
    title: 'Good to have',
    style: 'grey',
    icon: (
      <div data-star='true'>
        <i className='fas fa-star' />
      </div>
    )
  },

  { title: null, icon: null }
]

const surveyHeaders = [
  {
    title: "You're great at",
    style: 'green',
    icon: <IconMust />
  },
  {
    title: "You're strong at",
    style: 'primary',
    icon: <IconImportant />
  },
  {
    title: "You're good at",
    style: 'grey',
    icon: <IconGood />
  },

  { title: null, icon: null }
]

export default compose(
  withRouter,
  handleLoading(() => (
    <div style={{ height: '100vh' }}>
      <Circles />
    </div>
  )),
  withState('openModal', 'setOpenModal', ''),
  withState('showCompList', 'setShowCompList', false),
  withProps(({ resource }) => {
    if (resource === 'position' || resource === 'template') {
      return {
        headers: positionHeaders
      }
    }
    if (resource === 'survey') {
      return {
        headers: surveyHeaders
      }
    }
  }),
  withState('selectedColumns', 'setSelectedColumns', props => props.columns),
  scrollToTop
)
