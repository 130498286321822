import React, { useState } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Icon from "components/UI/Icon";
import Modal from "react-modal";
import { ReactComponent as Lines } from "./Lines.svg";
import Button from "components/UI/Button";
import CompetencyModal from "./CompetencyModal";
import compose from "./compose";

import styles from "./ForcedRankingMobile.module.scss";

export default compose(
  ({
    handleSetNextblock,
    columns,
    blocks,
    currentBlock,
    blockItemsLength,
    modal,
    updateModal
  }) => {
    const [showSkills, setShowSkills] = useState(false);
    const [slotIndex, setSlotIndex] = useState(null);

    const onDragEnd = result => {
      if (!result.destination) {
        return;
      }

      const sourceComp = columns.selection[result.destination.index];

      const destComp = columns.selection.find(
        col => col.survey_row_id === result.draggableId
      );

      columns.selection[result.source.index] = sourceComp;
      columns.selection[result.destination.index] = destComp;
    };

    const selectSkill = (skill, index) => {
      columns.selection[
        columns.selection.indexOf(
          columns.selection.find(
            sel => sel.survey_row_id === skill.survey_row_id
          )
        )
      ] = {
        obj_type_id: 3,
        subject_type_id: null,
        subject_id: null,
        survey_row_id: null,
        question_dim_id: null,
        answer: null
      };
      columns.selection[index] = skill;

      setShowSkills(false);
      setSlotIndex(null);
    };

    const getFirstEmpty = () => {
      for (var i = 0; i < columns.selection.length; i++) {
        if (!columns.selection[i].survey_row_id) {
          return i;
        }
      }
    };

    return (
      <>
        {showSkills && (
          <div className={styles.skillsWrapper}>
            <div className={styles.header}>
              <div className={styles.title}>
                <h5>Choose skill</h5>
              </div>
              <Button
                bStyle={["none"]}
                type="button"
                onClick={() => setShowSkills(false)}
              >
                <Icon icon="Close" />
              </Button>
            </div>
            <div className={styles.skills}>
              {columns.source.map(skill => (
                <div
                  key={`Skill-${skill.survey_row_id}`}
                  className={classNames(
                    styles.skill,
                    columns.selection.find(
                      sel => sel.survey_row_id === skill.survey_row_id
                    ) && styles.selected
                  )}
                >
                  <div
                    className={styles.top}
                    onClick={() => selectSkill(skill, slotIndex)}
                  >
                    <h5>{skill.dimension.name}</h5>
                    <p>{skill.dimension.description_short}</p>
                  </div>
                  <div className={styles.seeMore}>
                    <Button
                      bStyle={["newsimple", "nopadding"]}
                      onClick={() => updateModal(skill.dimension)}
                    >
                      See more
                    </Button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        <div className={styles.header}>
          <div className={styles.title}>
            <h5>Competency ranking</h5>
            <p>{`${currentBlock} out of 3`}</p>
          </div>
          <div className={styles.buttonGroup}>
            <Button
              bStyle={["green", "verySmall"]}
              type="button"
              onClick={handleSetNextblock}
              disabled={
                columns.selection.filter(r => r.survey_row_id).length !==
                blockItemsLength
              }
            >
              {currentBlock === blocks.length ? "Finish" : "Next"}
            </Button>
            <Link to="/">
              <Button bStyle={["secondary", "verySmall"]} type="button">
                Cancel
              </Button>
            </Link>
          </div>
          <div
            className={classNames(
              styles.progressLine,
              styles["step-" + currentBlock]
            )}
          />
        </div>
        <div className={styles.main}>
          <div className={styles.titleRow}>
            <h3>Highlight the competencies you are strongest at</h3>
            <p>
              Rank competencies based on your confidence in each area to help
              the team leverage your unique strengths.
            </p>
            <div className={styles.hints}>
              <div className={styles.hint}>
                <i className="fad fa-hand-pointer"></i>
                <p>Drag to rearrange</p>
              </div>
              <div className={styles.hint}>
                <Icon icon="Edit" marginRight />
                <p>Replace competency</p>
              </div>
            </div>
          </div>
          <div className={styles.surveyWrapper}>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className={classNames(
                      styles.droppable,
                      snapshot.isDraggingOver && styles.isDraggingOver
                    )}
                  >
                    {!showSkills && (
                      <div className={styles.slots}>
                        <p className={styles.legend}>Most like me</p>
                        <p className={styles.legend}>Least like me</p>
                        <div className={styles.bar} />
                        {columns.selection.map((skill, index) => {
                          if (skill.survey_row_id) {
                            return (
                              <Draggable
                                key={skill.survey_row_id}
                                draggableId={skill.survey_row_id}
                                index={index}
                              >
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    className={classNames(
                                      styles.draggable,
                                      snapshot.isDragging && styles.dragging
                                    )}
                                  >
                                    <div
                                      key={skill.survey_row_id}
                                      className={classNames(
                                        styles.slot,
                                        styles.card
                                      )}
                                    >
                                      <div className={styles.edit}>
                                        <Button
                                          bStyle={["none"]}
                                          onClick={() => {
                                            setShowSkills(true);
                                            window.scrollTo(0, 0);
                                            setSlotIndex(index);
                                          }}
                                        >
                                          <Icon icon="Edit" />
                                        </Button>
                                      </div>
                                      <h5>{skill.dimension.name}</h5>
                                      <p>{skill.dimension.description_short}</p>
                                      <div className={styles.seeMore}>
                                        <Button
                                          bStyle={["newsimple", "nopadding"]}
                                          onClick={() =>
                                            updateModal(skill.dimension)
                                          }
                                        >
                                          See more
                                        </Button>
                                      </div>
                                    </div>
                                    {provided.placeholder}
                                  </div>
                                )}
                              </Draggable>
                            );
                          }
                          return (
                            <Draggable
                              key={`Emptyslot-${index}`}
                              draggableId={`Emptyslot-${index}`}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  className={classNames(
                                    styles.draggable,
                                    snapshot.isDragging && styles.dragging
                                  )}
                                >
                                  <div
                                    key={`Emptyslot-${index}`}
                                    className={classNames(styles.slot, index)}
                                  >
                                    <Button
                                      bStyle={["none"]}
                                      onClick={() => {
                                        setShowSkills(true);
                                        window.scrollTo(0, 0);
                                        setSlotIndex(index);
                                      }}
                                    >
                                      {index === getFirstEmpty() ? (
                                        "Choose competencies"
                                      ) : index === getFirstEmpty() + 1 ? (
                                        <div
                                          className={classNames(
                                            styles.lines,
                                            styles.first
                                          )}
                                        >
                                          <Lines />
                                        </div>
                                      ) : index === getFirstEmpty() + 2 ? (
                                        <div
                                          className={classNames(
                                            styles.lines,
                                            styles.second
                                          )}
                                        >
                                          <Lines />
                                        </div>
                                      ) : index === getFirstEmpty() + 3 ? (
                                        <div
                                          className={classNames(
                                            styles.lines,
                                            styles.third
                                          )}
                                        >
                                          <Lines />
                                        </div>
                                      ) : null}
                                    </Button>
                                  </div>
                                  {provided.placeholder}
                                </div>
                              )}
                            </Draggable>
                          );
                        })}
                      </div>
                    )}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
          <Modal
            closeTimeoutMS={350}
            isOpen={!!modal}
            onRequestClose={() => updateModal("")}
            shouldCloseOnOverlayClick
            className={styles.modal}
          >
            <Button
              bStyle="close"
              type="button"
              onClick={() => updateModal("")}
            >
              <i className="fal fa-times" />
            </Button>
            <CompetencyModal comp={modal} />
          </Modal>
        </div>
      </>
    );
  }
);
