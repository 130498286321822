import React from "react";
import { compose, withProps, branch, renderComponent } from "recompose";
import { withRouter } from "react-router-dom";
import { graphql } from "react-apollo";

import { loader } from "graphql.macro";

import FullPage from "components/Loaders/FullPage";
import handleLoading from "utils/loader";

const ASSESSMENT_SESSION = loader("./assessmentSession5.graphql");

export default compose(
  withRouter,
  graphql(ASSESSMENT_SESSION, {
    options: ({
      match: {
        params: { session_id },
      },
    }) => ({
      variables: { id: parseInt(session_id, 10) },
    }),
  }),

  handleLoading(() => <FullPage />)
);
