import React from "react";
import classNames from "classnames";
import Button from "components/UI/Button";
import Icon from "components/UI/Icon";
import Highlight from "./Highlight";
import compose from "./compose";

import styles from "../EditTemplate.module.scss";

export default compose(
  ({
    highlights,
    handleCreatePositionTemplateHighlight,
    handleUpdatePositionTemplateHighlight,
    handleDeletePositionTemplateHighlight,
  }) => {
    return (
      <div className={styles.cards}>
        {highlights.map((highlight) => {
          return (
            <Highlight
              key={"Highlight-" + highlight.id}
              highlight={highlight}
              handleUpdatePositionTemplateHighlight={
                handleUpdatePositionTemplateHighlight
              }
              handleDeletePositionTemplateHighlight={
                handleDeletePositionTemplateHighlight
              }
            />
          );
        })}
        <div className={classNames(styles.card, styles.addNew)}>
          <Button
            bStyle={["verySmall", "bluetext"]}
            onClick={handleCreatePositionTemplateHighlight}
          >
            <Icon icon="Plus" marginRight />
            Add highlight
          </Button>
        </div>
      </div>
    );
  }
);
