import React from "react";
import classNames from "classnames";
import Wrapper from "components/UI/Wrapper";
import Container from "components/UI/Container";
import Button from "components/UI/Button";
import RequirementCard from "./RequirementCard/index";
import compose from "./compose";

import styles from "./Requirements.module.scss";

export default compose(
  ({
    position: { id, requirements },
    cardOpen,
    setCardOpen,
    newCard,
    setNewCard,
    orgId,
  }) => {
    return (
      <div className={styles.main}>
        <Wrapper left>
          <h2>
            <span className={styles.checkWrapper}>
              <i className="far fa-check" />
            </span>
            Position requirements
          </h2>
          <p>
            List here the skills, previous experiences, and qualifications
            crucial for achieving the key objectives in this role. Do not use
            this section to describe the kind of person you need, this is better
            done using the competencies section. The optimal amount is 3-6
            requirements. Read more{" "}
            <a
              target="_blank"
              href="https://support.wisnio.com/en/articles/4769000-stage-2-position-mapping-tools#h_827ad49598"
            >
              here
            </a>
            .
          </p>
          <div className={styles.content}>
            {requirements.length > 0 && (
              <>
                {requirements.map((card, index) => (
                  <RequirementCard
                    key={`requirement-card-${card.id}`}
                    index={`requirement-card-${card.id}`}
                    templateId={id}
                    orgId={orgId}
                    card={card}
                    cardOpen={cardOpen}
                    setCardOpen={setCardOpen}
                    setNewCard={setNewCard}
                    isNewCard={false}
                    newCard={newCard}
                  />
                ))}
              </>
            )}
            {newCard && (
              <RequirementCard
                index={
                  requirements.length > 0
                    ? requirements[requirements.length - 1].order + 1
                    : 0
                }
                card={newCard}
                templateId={id}
                orgId={orgId}
                isNewCard
                cardOpen={
                  requirements.length > 0
                    ? requirements[requirements.length - 1].order + 1
                    : 0
                }
                setCardOpen={setCardOpen}
                setNewCard={setNewCard}
              />
            )}
            <div className={classNames(styles.containerWrapper, styles.add)}>
              <Container size="large" cStyle={["noShadow"]}>
                <div className={styles.row}>
                  <Button
                    bStyle={["newsimple"]}
                    type="button"
                    onClick={() => {
                      setCardOpen(null);
                      setNewCard({
                        order:
                          requirements.length > 0
                            ? requirements[requirements.length - 1].order + 1
                            : 0,
                        title: "",
                        text: "",
                      });
                    }}
                  >
                    Add new requirement
                  </Button>
                </div>
              </Container>
            </div>
          </div>
        </Wrapper>
      </div>
    );
  }
);
