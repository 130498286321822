import React from "react";
import Button from "components/UI/Button";
import styles from "./SurveyFooter.module.scss";

export default ({
  survey,
  answers,
  currentRowId,
  setCurrentRowId,
  onFinishSurvey,
}) => {
  if (survey.type === "Competencies survey") return null;
  const isSurveyComplete = answers.length === survey?.survey_rows?.length;

  const handleNextClick = () => {
    if (isSurveyComplete && onFinishSurvey) {
      onFinishSurvey();
    } else {
      setCurrentRowId(currentRowId + 1);
    }
  };

  return (
    <div className={styles.wrapper}>
      {currentRowId === 0 && (
        <p className={styles.disclaimer}>
          Tap on the circle to select your answer. Don´t worry, you can go back
          to the previous question if needed.
        </p>
      )}
      <div className={styles.buttons}>
        <Button
          bStyle={["secondary"]}
          type="button"
          disabled={currentRowId == 0}
          onClick={() => setCurrentRowId(currentRowId - 1)}
        >
          <i className="fas fa-angle-left" />
          Back
        </Button>
        <Button
          bStyle={["secondary"]}
          type="button"
          disabled={!isSurveyComplete && currentRowId === answers.length}
          onClick={handleNextClick}
        >
          {isSurveyComplete ? "Finish" : "Next"}
          <i className="fas fa-angle-right" />
        </Button>
      </div>
    </div>
  );
};
