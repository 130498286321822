import React from "react";
import classNames from "classnames";
import ValueSectors from "./ValueSector";
import ValueGroups from "./ValueGroups";
import Blob from "./Blob";
import styles from "./index.module.scss";

export default ({
  data,
  valuesChart,
  values,
  isMemberHover,
  members,
  resource,
  highlight,
  candidate,
  isCandidate,
  team,
  casestudy,
  updateModal,
}) => {
  const wedgeAngle = 360 / valuesChart.values.length;
  const midX = 50;
  const midY = 50;
  const sectorRadius = 30;

  return (
    <svg
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2001/svg"
      encoding="UTF-8"
      className={classNames(styles.main, "valuesGraph")}
    >
      {members ? (
        <>
          <Blob
            valuesChart={valuesChart}
            wedgeAngle={wedgeAngle}
            midX={midX}
            midY={midY}
            resource={resource}
            isMemberHover={isMemberHover}
            sectorRadius={sectorRadius}
          />
        </>
      ) : (
        <Blob
          valuesChart={valuesChart}
          wedgeAngle={wedgeAngle}
          midX={midX}
          midY={midY}
          resource={resource}
          isMemberHover={isMemberHover}
          sectorRadius={sectorRadius}
        />
      )}
      {candidate && (
        <Blob
          valuesChart={candidate.valuesChart}
          wedgeAngle={wedgeAngle}
          midX={midX}
          midY={midY}
          isMember
          sectorRadius={sectorRadius}
        />
      )}
      {resource === "member" && highlight && (
        <Blob
          valuesChart={highlight.valuesChart}
          wedgeAngle={wedgeAngle}
          midX={midX}
          midY={midY}
          isMember
          sectorRadius={sectorRadius}
        />
      )}
      {isMemberHover && resource === "team" && (
        <Blob
          valuesChart={isMemberHover.valuesChart}
          wedgeAngle={wedgeAngle}
          midX={midX}
          midY={midY}
          isMember
          sectorRadius={sectorRadius}
        />
      )}
      <ValueGroups
        wedgeAngle={wedgeAngle}
        midX={midX}
        midY={midY}
        sectorRadius={sectorRadius}
        valuesChart={valuesChart}
        resource={resource}
        highlight={highlight}
        candidate={candidate}
      />
      <ValueSectors
        data={data}
        wedgeAngle={wedgeAngle}
        midX={midX}
        midY={midY}
        sectorRadius={sectorRadius}
        values={values}
        valuesChart={valuesChart}
        updateModal={updateModal}
        members={members}
        isCandidate={isCandidate}
        resource={resource}
        highlight={highlight}
        candidate={candidate}
        team={team && team}
        casestudy={casestudy}
      />
    </svg>
  );
};
