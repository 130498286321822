import React from "react";
import ViewerAvatar from "components/ViewerAvatar";
import Button from "components/UI/Button";
import styles from "./NewComment.module.scss";
import compose from "./compose";

export default compose(
  ({ handleCreateCandidateNote, setComment, comment, setNewNote }) => {
    return (
      <div className={styles.wrapper}>
        <div className={styles.avatarWrapper}>
          <ViewerAvatar size="small" />
        </div>
        <form onSubmit={handleCreateCandidateNote}>
          <div className={styles.inputWrapper}>
            <textarea
              rows="3"
              cols="70"
              placeholder="Add note"
              onChange={(e) => setComment(e.target.value)}
              value={comment}
              id="commentTextarea"
              data-gramm_editor="false"
            />
          </div>
          <div className={styles.buttons}>
            <Button
              bStyle={["newsimple"]}
              type="submit"
              disabled={comment.length === 0}
            >
              Save
            </Button>
            <Button
              bStyle={["newsimple"]}
              type="button"
              onClick={() => {
                setComment("");
                setNewNote(false);
              }}
            >
              Cancel
            </Button>
          </div>
        </form>
      </div>
    );
  }
);
