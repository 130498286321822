import React from 'react'
import { ReactComponent as GoalAlignmentBackground } from 'images/icons/toolkits/goal-alignment-bg.svg'

import styles from './Preparation.module.scss'

export default () => (
  <div className={styles.wrapper}>
    <p className={styles.subtitle}>
      The Goal Alignment tool involves the whole team working together by
      setting common team goals for the next 3 - 12 months (the specific
      time-period to be agreed within the team).
    </p>
    <div className={styles.imageWrapper}>
      <div className={styles.box} />
      <GoalAlignmentBackground />
    </div>
    <div className={styles.infoBox}>
      <h4>Goal setting process</h4>
      <p>The goal-setting process consists of three steps:</p>
      <ul>
        <li>
          Individual team member goal setting (1 - 3 common goals for the team
          each)
        </li>
        <li>Goal review and grouping thematically similar goals</li>
        <li>Selection of final team goal(s)</li>
      </ul>
    </div>
  </div>
)
