import { compose, withHandlers } from "recompose";
import { graphql } from "react-apollo";
import { loader } from "graphql.macro";
import { withRouter } from "react-router-dom";

const CANDIDATE_QUERY = loader("client/Queries/CandidateQuery198.graphql");
const UPSERT_CANDIDATE_KEY_HIGHLIGHT = loader(
  "./upsertCandidateKeyHighlight.graphql"
);
const DELETE_CANDIDATE_KEY_HIGHLIGHT = loader(
  "./deleteCandidateKeyHighlight.graphql"
);

export default compose(
  withRouter,
  graphql(UPSERT_CANDIDATE_KEY_HIGHLIGHT, {
    name: "upsertCandidateKeyHighlight",
    options: (props) => {
      return {
        update: (proxy, { data: { upsertCandidateKeyHighlight } }) => {
          const type = props.match.params.candidateType;
          const candidate = proxy.readQuery({
            query: CANDIDATE_QUERY,
            variables: {
              user_id:
                type === "candidate"
                  ? parseInt(props.match.params.candidateId, 10)
                  : null,
              person_id:
                type === "person"
                  ? parseInt(props.match.params.candidateId, 10)
                  : null,
              team_id: parseInt(props.match.params.teamId, 10),
              position_id: parseInt(props.match.params.positionId, 10),
              org_id: parseInt(props.match.params.orgId, 10),
            },
          });

          if (
            candidate.candidateInTeam.keyHighlights.filter(
              (f) => f.id === upsertCandidateKeyHighlight.id
            ).length === 0
          ) {
            candidate.candidateInTeam.keyHighlights = [
              ...candidate.candidateInTeam.keyHighlights,
              upsertCandidateKeyHighlight,
            ];
          }

          proxy.writeQuery({
            query: CANDIDATE_QUERY,
            variables: {
              cand_id:
                type === "candidate"
                  ? parseInt(props.match.params.candidateId, 10)
                  : null,
              person_id:
                type === "person"
                  ? parseInt(props.match.params.candidateId, 10)
                  : null,
              team_id: parseInt(props.match.params.teamId, 10),
              position_id: parseInt(props.match.params.positionId, 10),
              org_id: parseInt(props.match.params.orgId, 10),
            },
            data: candidate,
          });
        },
      };
    },
  }),
  graphql(DELETE_CANDIDATE_KEY_HIGHLIGHT, {
    name: "deleteCandidateKeyHighlight",
    options: (props) => {
      return {
        update: (proxy, { data: { deleteCandidateKeyHighlight } }) => {
          const type = props.match.params.candidateType;
          const candidate = proxy.readQuery({
            query: CANDIDATE_QUERY,
            variables: {
              user_id:
                type === "candidate"
                  ? parseInt(props.match.params.candidateId, 10)
                  : null,
              person_id:
                type === "person"
                  ? parseInt(props.match.params.candidateId, 10)
                  : null,
              team_id: parseInt(props.match.params.teamId, 10),
              position_id: parseInt(props.match.params.positionId, 10),
              org_id: parseInt(props.match.params.orgId, 10),
            },
          });

          candidate.candidateInTeam.keyHighlights = [
            ...candidate.candidateInTeam.keyHighlights.filter(
              (figure) => figure.id !== deleteCandidateKeyHighlight
            ),
          ];

          proxy.writeQuery({
            query: CANDIDATE_QUERY,
            variables: {
              user_id:
                type === "candidate"
                  ? parseInt(props.match.params.candidateId, 10)
                  : null,
              person_id:
                type === "person"
                  ? parseInt(props.match.params.candidateId, 10)
                  : null,
              team_id: parseInt(props.match.params.teamId, 10),
              position_id: parseInt(props.match.params.positionId, 10),
              org_id: parseInt(props.match.params.orgId, 10),
            },
            data: candidate,
          });
        },
      };
    },
  }),
  withHandlers({
    handleCreateCandidateKeyHighlight:
      ({
        match: {
          params: { candidateType },
        },
        upsertCandidateKeyHighlight,
        candidate,
      }) =>
      async () => {
        try {
          await upsertCandidateKeyHighlight({
            variables: {
              cand_id: candidateType === "candidate" ? candidate.id : null,
              person_id: candidateType === "person" ? candidate.id : null,
              position_id: candidate.position.id,
            },
          });
        } catch (err) {
          throw new Error(err);
        }
      },
    handleUpdateCandidateKeyHighlight:
      ({
        match: {
          params: { candidateType },
        },
        upsertCandidateKeyHighlight,
        candidate,
      }) =>
      async (figure_id, title, desc) => {
        try {
          await upsertCandidateKeyHighlight({
            variables: {
              id: figure_id,
              cand_id: candidateType === "candidate" ? candidate.id : null,
              person_id: candidateType === "person" ? candidate.id : null,
              position_id: candidate.position.id,
              title: title,
              description: desc,
            },
          });
        } catch (err) {
          throw new Error(err);
        }
      },
    handleDeleteCandidateKeyHighlight:
      ({ deleteCandidateKeyHighlight, candidate }) =>
      (figure_id) => {
        try {
          deleteCandidateKeyHighlight({
            variables: {
              id: figure_id,
              position_id: candidate.position.id,
            },
          });
        } catch (err) {
          throw new Error(err);
        }
      },
  })
);
