import React from "react";
import PersonalityPlaceholder from "components/Placeholders/PersonalityPlaceholder";
import PersonalityChart from "../Chart";
import Dimensions from "../Dimensions";
import compose from "./compose";
import styles from "../Personality.module.scss";

export default compose(
  ({
    data: {
      personalProfile: { personality },
    },
  }) => {
    if (personality.some((dim) => dim.score === null)) {
      return <PersonalityPlaceholder resource="personal" />;
    }
    return (
      <>
        <PersonalityChart personality={personality} />
        <div className={styles.personalityTraits}>
          <Dimensions personality={personality} />
        </div>
      </>
    );
    // {atLeastOneSurveyComplete ? (
    //   <PersonalityChart />
    // ) : (
    //   <PersonalityPlaceholder resource='personal' />
    // )}
    // {atLeastOneSurveyComplete && (
    //   <div className={styles.personalityTraits}>
    //     <Dimensions />
    //   </div>
    // )}
  }
);
