import React from "react";
import { withRouter } from "react-router-dom";
import {
  compose,
  branch,
  renderComponent,
  withState,
  withProps,
} from "recompose";
import { graphql, withApollo } from "react-apollo";
import { loader } from "graphql.macro";
import scrollToTop from "utils/scrollToTop";
import FullPage from "components/Loaders/FullPage";
import purgeTeamAndCandidateFromCache from "utils/purgeTeamAndCandidateFromCache";
import { printoutRoute } from "client/authRoutes";
import withStore from "utils/withStore";
import handleAddCandidateToTeam from "./handleAddCandidateToTeam";
import handleSetCandidateToOnboard from "./handleSetCandidateToOnboard";
import handleUpsertPerson from "./handleUpsertPerson";

import handleUpsertCandidateLinkedin from "./handleUpsertCandidateLinkedin";
import handleRemoveCandidateLinkedin from "./handleRemoveCandidateLinkedin";

import handleUpsertCandidatePhone from "./handleUpsertCandidatePhone";
import handleSendInvite from "./handleSendInvite";

const CANDIDATE_QUERY = loader("client/Queries/CandidateQuery198.graphql");
const POSITION_QUERY = loader("client/Queries/PositionQuery98.graphql");
const SET_CANDIDATE_TO_ONBOARD = loader("./setCandidateToOnboard7.graphql");
const ADD_CANDIDATE_TO_TEAM = loader(
  "client/Mutations/AddCandidateToTeam7.graphql"
);
const UPSERT_PERSON = loader("./upsertPerson1.graphql");
const UPSERT_CANDIDATE_LINKEDIN = loader("./upsertCandidateLinkedin1.graphql");
const REMOVE_CANDIDATE_LINKEDIN = loader("./removeCandidateLinkedin.graphql");
const UPSERT_CANDIDATE_PHONE = loader("./upsertCandidatePhone1.graphql");

export default compose(
  withRouter,
  withApollo,
  withState("summary", "setSummary", false),
  withState("openModal", "setOpenModal", ""),
  graphql(CANDIDATE_QUERY, {
    name: "CANDIDATE_QUERY",
    options: ({
      match: {
        params: { candidateId, teamId, positionId, orgId, candidateType },
      },
    }) => ({
      variables: {
        user_id:
          candidateType === "candidate" ? parseInt(candidateId, 10) : null,
        person_id:
          candidateType === "person" ? parseInt(candidateId, 10) : null,
        team_id: parseInt(teamId, 10),
        position_id: parseInt(positionId, 10),
        org_id: parseInt(orgId, 10),
      },
    }),
  }),
  graphql(ADD_CANDIDATE_TO_TEAM, {
    name: "addCandidateToTeam",
    options: (props) => {
      return {
        update: (proxy, { data: { addCandidateToTeam } }) => {
          purgeTeamAndCandidateFromCache({
            cache: props.client.cache.data,
            candId: addCandidateToTeam.cand_id,
            team_id: parseInt(props.match.params.teamId, 10),
            proxy,
          });

          const data = proxy.readQuery({
            query: POSITION_QUERY,
            variables: {
              id: parseInt(props.match.params.positionId, 10),
            },
          });

          data.openPosition.onboardings = [
            ...data.openPosition.onboardings,
            data.openPosition.candidates.find(
              (c) => c.id === addCandidateToTeam.cand_id
            ),
          ];

          data.openPosition.candidates = [
            ...data.openPosition.candidates.filter(
              (c) => c.id !== addCandidateToTeam.cand_id
            ),
          ];

          proxy.writeQuery({
            query: POSITION_QUERY,
            variables: {
              id: parseInt(props.match.params.positionId, 10),
            },
            data,
          });

          props.setOpenModal(false);
        },
      };
    },
  }),
  graphql(SET_CANDIDATE_TO_ONBOARD, {
    name: "setCandidateToOnboard",
  }),
  graphql(UPSERT_PERSON, {
    name: "upsertPerson",
    options: ({
      match: {
        params: { candidateId, teamId, positionId, orgId, candidateType },
      },
    }) => {
      return {
        update: (proxy, { data: { upsertPerson } }) => {
          const data = proxy.readQuery({
            query: CANDIDATE_QUERY,
            variables: {
              user_id:
                candidateType === "candidate"
                  ? parseInt(candidateId, 10)
                  : null,
              person_id:
                candidateType === "person" ? parseInt(candidateId, 10) : null,
              team_id: parseInt(teamId, 10),
              position_id: parseInt(positionId, 10),
              org_id: parseInt(orgId, 10),
            },
          });

          data.candidateInTeam.linkedIn = upsertPerson.linkedIn;
          data.candidateInTeam.email = upsertPerson.email;

          proxy.writeQuery({
            query: CANDIDATE_QUERY,
            variables: {
              user_id:
                candidateType === "candidate"
                  ? parseInt(candidateId, 10)
                  : null,
              person_id:
                candidateType === "person" ? parseInt(candidateId, 10) : null,
              team_id: parseInt(teamId, 10),
              position_id: parseInt(positionId, 10),
              org_id: parseInt(orgId, 10),
            },
            data,
          });
        },
      };
    },
  }),
  graphql(UPSERT_CANDIDATE_LINKEDIN, {
    name: "upsertCandidateLinkedin",
  }),
  graphql(REMOVE_CANDIDATE_LINKEDIN, {
    name: "removeCandidateLinkedin",
  }),
  graphql(UPSERT_CANDIDATE_PHONE, {
    name: "upsertCandidatePhone",
  }),
  withStore("newposition"),
  branch(
    (props) => {
      const condition = props.CANDIDATE_QUERY && props.CANDIDATE_QUERY.loading;
      return condition;
    },
    renderComponent(() => <FullPage />)
  ),
  withProps(
    ({
      setOpenModal,
      CANDIDATE_QUERY: { candidateInTeam },
      history,
      match: {
        params: { candidateType },
      },
    }) => ({
      actionHandlers: {
        reviewAnalysis: () => console.log("review"),
        printInterviewGuide: () => {
          const win = window.open(
            `${printoutRoute}/printout/candidate/${candidateInTeam.position.parent_org_id}/${candidateInTeam.position.parent_team_id}/${candidateInTeam.position.id}/${candidateInTeam.id}/${candidateInTeam.first_name}_${candidateInTeam.last_name}_candidate_profile.pdf`,
            "_blank"
          );
          win.focus();
        },
        evaluate: () =>
          history.push(
            `/${candidateInTeam.position.parent_org_id}/team/${candidateInTeam.position.parent_team_id}/position/${candidateInTeam.position.id}/${candidateType}/${candidateInTeam.id}/interviewguide`
          ),
        compare: () =>
          history.push(
            `/team/${candidateInTeam.position.parent_team_id}/position/${candidateInTeam.position.id}/compare`
          ),
        startOnboarding: () => setOpenModal("addToTeam"),
      },
    })
  ),
  handleAddCandidateToTeam,
  handleSetCandidateToOnboard,
  handleUpsertPerson,
  handleUpsertCandidateLinkedin,
  handleRemoveCandidateLinkedin,
  handleUpsertCandidatePhone,
  handleSendInvite,
  scrollToTop
  // logRead({
  //   obj_type: "position",
  //   obj_id: "CANDIDATE_QUERY.candidateInTeam.position.id",
  //   subject_type: "props.match.params.candidateType",
  //   subject_id: "CANDIDATE_QUERY.candidateInTeam.id",
  // })
);
