import { withHandlers } from "recompose";
import { SELECT_ORG } from "store/actionTypes";

export default withHandlers({
  handleSelectOrg: (props) => (name) => {
    const organization = props.data.organizations.find(
      (org) => org.name === name
    );
    props.dispatch({ type: SELECT_ORG, payload: organization });
    window.localStorage.setItem("recentOrg", organization.id);
    if (
      window.location.pathname !== "/dashboard" &&
      window.location.pathname !== "/"
    ) {
      window.location.replace("/dashboard");
    }
  },
});
