import React from "react";
import { ReactComponent as PositionImg } from "images/toolkits/Position.svg";

import styles from "./TeamEffectivenessPreperation.module.scss";

export default () => (
  <div className={styles.wrapper}>
    <p className={styles.subtitle}>
      The Team Effectiveness tool allows your team to evaluate your internal
      processes and team engagement level in five dimensions.
    </p>
    <p className={styles.smallSubtitle}>
      Results will be presented to all participants, so it’s best to use this
      tool in preparation for a team workshop to identify potential improvement
      areas and have an open discussion about how you can perform better as a
      team.
      <br />
      The methodology has been developed in collaboration with Paul Ter Linden
      and Margreet van de Griend.
    </p>
    <div className={styles.imageWrapper}>
      <PositionImg />
    </div>
    <div className={styles.infoBox}>
      <h4>In preparation of the session</h4>
      <ul>
        <li>
          Please inform your team in advance so they would know to expect the
          invitation email
        </li>
        <li>Set a deadline for the team to complete their evaluation</li>
        <li>Schedule a meeting with the team to review the results together</li>
      </ul>
    </div>
  </div>
);
