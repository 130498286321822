import React from "react"
import classNames from "classnames"
import Button from "components/UI/Button"
import Icon from "components/UI/Icon"
import KeyHighlight from "./KeyHighlight"
import compose from "./compose"
import styles from "./KeyHighlights.module.scss"

export default compose(
  ({
    keyHighlights,
    handleCreateCandidateKeyHighlight,
    handleUpdateCandidateKeyHighlight,
    handleDeleteCandidateKeyHighlight,
  }) => (
    <div className={styles.cards}>
      {keyHighlights.map((highlight) => (
        <KeyHighlight
          key={"KeyHighlight-" + highlight.id}
          highlight={highlight}
          handleUpdateCandidateKeyHighlight={handleUpdateCandidateKeyHighlight}
          handleDeleteCandidateKeyHighlight={handleDeleteCandidateKeyHighlight}
        />
      ))}
      <div className={classNames(styles.card, styles.addNew)}>
        <Button
          bStyle={["verySmall", "bluetext"]}
          onClick={handleCreateCandidateKeyHighlight}
        >
          <Icon icon="Plus" marginRight />
          Add highlight
        </Button>
      </div>
    </div>
  )
)
