import React from "react";
import { withRouter } from "react-router-dom";
import { graphql } from "react-apollo";
import { compose } from "recompose";
import handleLoading from "utils/handleLoading";
import { loader } from "graphql.macro";

const USER_IN_ORG = loader("client/Queries/userInOrg.graphql");

export default compose(
  withRouter,
  graphql(USER_IN_ORG, {
    options: (props) => {
      return {
        fetchPolicy: "network-only",
        variables: {
          resource_type: "user",
          resource_id: parseInt(props.match.params.userId, 10),
          org_id: parseInt(props.match.params.orgId, 10),
        },
      };
    },
  }),
  handleLoading
)(
  ({
    data: {
      teamsAndPositionsInOrgByResouceType: { user },
    },
  }) => {
    const truncate = (string) => {
      if (string.length > 14) return `${string.substring(0, 14)}...`;
      return string;
    };

    return <React.Fragment>{user.name}</React.Fragment>;
  }
);
