import React from "react";
import classNames from "classnames";
import styles from "./index.module.scss";

// const getTitleXY = (start) => {
//   if (start >= 0 && start <= 45) return [100, 100]
//   if (start > 45 && start <= 115) return [0, 100]
//   if (start > 115 && start <= 250) return [0, 0]
//   if (start > 250 && start <= 360) return [100, 0]
// }

const colors = ["primary", "green", "yellow", "grey"];

export default ({
  wedgeAngle,
  midX,
  midY,
  sectorRadius,
  groups,
  member,
  type,
}) => {
  const scores = groups.map((g) => g.score);

  const maxRange = Math.max(...scores);
  const minRange = Math.min(...scores);

  const groupsOrderedByScore = groups.sort((a, b) => b.score - a.score);

  return (
    <>
      {groups
        .sort((a, b) => a.dim_id - b.dim_id)
        .map((group, i) => {
          const groupColor =
            colors[
              groupsOrderedByScore.findIndex((g) => g.dim_id === group.dim_id)
            ];

          const renderedGroups = groups.filter((g, ri) => ri < i);

          let renderedGroupsLength;
          if (renderedGroups.length === 0) renderedGroupsLength = 0;
          if (renderedGroups.length === 1)
            renderedGroupsLength = renderedGroups[0].dimensions.length;
          if (renderedGroups.length > 1) {
            renderedGroupsLength = renderedGroups
              .map((g) => g.dimensions.length)
              .reduce((a, b) => a + b);
          }

          const groupAngle = wedgeAngle * group.dimensions.length;
          const groupStartAngle = renderedGroupsLength * wedgeAngle;
          const groupEndAngle = groupStartAngle + groupAngle;
          const radius = sectorRadius;
          const scoreRadius = radius + 10;
          const scoreRadiusFlipped = radius + 11.5;
          const titleCurveR = sectorRadius + 13;
          const titleCurveRflipped = sectorRadius + 15;
          const groupLineEndR = 2 * radius - (40 * radius) / 100;
          const flipText = groupEndAngle >= 0 && groupEndAngle <= 180;

          const lx1 =
            midX + radius * Math.cos((Math.PI * groupStartAngle) / 180);
          const ly1 =
            midY + radius * Math.sin((Math.PI * groupStartAngle) / 180);
          const lx2 =
            midX + groupLineEndR * Math.cos((Math.PI * groupStartAngle) / 180);
          const ly2 =
            midY + groupLineEndR * Math.sin((Math.PI * groupStartAngle) / 180);
          // const l2x2 = midX + groupLineEndR * Math.cos((Math.PI * groupEndAngle) / 180)
          // const l2y2 = midY + groupLineEndR * Math.sin((Math.PI * groupEndAngle) / 180)
          // const l2x1 = midX + radius * Math.cos((Math.PI * groupEndAngle) / 180)
          // const l2y1 = midY + radius * Math.sin((Math.PI * groupEndAngle) / 180)

          const tx1 =
            midX + titleCurveR * Math.cos((Math.PI * groupStartAngle) / 180);
          const ty1 =
            midY + titleCurveR * Math.sin((Math.PI * groupStartAngle) / 180);
          const tx2 =
            midX + titleCurveR * Math.cos((Math.PI * groupEndAngle) / 180);
          const ty2 =
            midY + titleCurveR * Math.sin((Math.PI * groupEndAngle) / 180);

          const sx1 =
            midX + scoreRadius * Math.cos((Math.PI * groupStartAngle) / 180);
          const sy1 =
            midY + scoreRadius * Math.sin((Math.PI * groupStartAngle) / 180);
          const sx2 =
            midX + scoreRadius * Math.cos((Math.PI * groupEndAngle) / 180);
          const sy2 =
            midY + scoreRadius * Math.sin((Math.PI * groupEndAngle) / 180);

          const sx1f =
            midX +
            scoreRadiusFlipped * Math.cos((Math.PI * groupStartAngle) / 180);
          const sy1f =
            midY +
            scoreRadiusFlipped * Math.sin((Math.PI * groupStartAngle) / 180);
          const sx2f =
            midX +
            scoreRadiusFlipped * Math.cos((Math.PI * groupEndAngle) / 180);
          const sy2f =
            midY +
            scoreRadiusFlipped * Math.sin((Math.PI * groupEndAngle) / 180);

          // const title = getTitleXY(groupStartAngle)
          //
          // const tcx = midX
          //   + scoreRadius
          //     * Math.cos((Math.PI * (groupEndAngle - groupAngle / 2)) / 180)
          // const tcy = midY
          //   + scoreRadius
          //     * Math.sin((Math.PI * (groupEndAngle - groupAngle / 2)) / 180)

          const tx1f =
            midX +
            titleCurveRflipped *
              Math.cos((Math.PI * (groupStartAngle + 4)) / 180);
          const ty1f =
            midY +
            titleCurveRflipped *
              Math.sin((Math.PI * (groupStartAngle + 4)) / 180);
          const tx2f =
            midX +
            titleCurveRflipped *
              Math.cos((Math.PI * (groupEndAngle - 4)) / 180);
          const ty2f =
            midY +
            titleCurveRflipped *
              Math.sin((Math.PI * (groupEndAngle - 4)) / 180);

          return (
            <g className={styles.groupWrap} key={group.dim_id}>
              <path
                className={styles.groupLine}
                d={`M${lx1} ${ly1} L${lx2} ${ly2}`}
              />
              <path
                className={styles.titleline}
                id={`${group.dim_id}-title-path`}
                d={
                  flipText
                    ? `M${tx2f} ${ty2f}  A${titleCurveRflipped} ${titleCurveRflipped} 0 0 0 ${tx1f} ${ty1f}`
                    : `M${tx1} ${ty1}  A${titleCurveR} ${titleCurveR} 0 0 1 ${tx2} ${ty2}`
                }
              />
              <path
                className={styles.titleline}
                id={`${group.dim_id}-score-path`}
                d={
                  flipText
                    ? `M${sx2f} ${sy2f}  A${scoreRadiusFlipped} ${scoreRadiusFlipped} 0 0 0 ${sx1f} ${sy1f}`
                    : `M${sx1} ${sy1}  A${scoreRadius} ${scoreRadius} 0 0 1 ${sx2} ${sy2}`
                }
              />
              <text
                className={styles.titleWrap}
                textAnchor="middle"
                alignmentBaseline="central"
              >
                <textPath
                  className={styles.groupTitle}
                  xlinkHref={`#${group.dim_id}-title-path`}
                  startOffset="50%"
                  textAnchor="middle"
                >
                  <tspan className={styles.titleName} cy={1}>
                    {group.name}
                  </tspan>
                </textPath>
              </text>
              <text
                className={styles.titleWrap}
                textAnchor="middle"
                alignmentBaseline="central"
              >
                <textPath
                  className={styles.score}
                  xlinkHref={`#${group.dim_id}-score-path`}
                  startOffset="50%"
                  textAnchor="middle"
                >
                  <tspan className={styles.number} cy={1}>
                    {type === "memberInTeam"
                      ? parseInt(
                          member.groups.find((g) => g.name === group.name)
                            .score,
                          10
                        )
                      : parseInt(group.score, 10)}
                  </tspan>
                </textPath>
              </text>
            </g>
          );
        })}
    </>
  );
};
