import React from 'react'
import classNames from 'classnames'
import Score from 'components/Score'
import UserAvatar from 'components/UserAvatar'
import ViewerAvatar from 'components/ViewerAvatar'

import styles from './BigChart.module.scss'

export default ({ members, dim, min, neutral, max, minimal }) => {
  const score = dim.score

  return (
    <div
      className={classNames(
        styles.graphWrapper,
        minimal && styles.minimal
      )}
    >
      <div className={classNames(styles.lineWrapper)}>
        <div className={styles.line}>
          <div
            className={classNames(styles.distribution)}
            style={{
              left: `${0}%`
            }}
          />
          <div
            className={classNames(styles.teamScore)}
            style={{ left: `${dim.score > 98 ? 98 : dim.score}%` }}
          >
            <div className={styles.number}>
              <Score score={score} scoreStyle={styles} />
            </div>
          </div>
          {members &&
            members.map((member) => {
              const memberScore = member.effectiveness.find(
                (e) => e.name === dim.name
              ).score

              if (memberScore || memberScore === 0) {
                return (
                  <div
                    key={member.id}
                    className={classNames(
                      styles.wrapper,
                      styles.large
                    )}
                    style={{
                      left: `${memberScore > 98 ? 98 : memberScore}%`
                    }}
                  >
                    <UserAvatar
                      member={member}
                      size="medium"
                      showName
                    />
                  </div>
                )
              }
            })}
        </div>
      </div>
      {min && neutral && max && (
        <div className={styles.compGraphLegends}>
          <p className={styles.legend}>{min}</p>
          <p className={styles.legend}>{neutral}</p>
          <p className={styles.legend}>{max}</p>
        </div>
      )}
    </div>
  )
}
