import React from "react";
import styles from "./index.module.scss";

export default () => (
  <div className={styles.wrapper}>
    <div className={styles.loader}>
      <div className={styles.logo}></div>
    </div>
  </div>
);
