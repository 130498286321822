import React, { useEffect } from "react";
import classNames from "classnames";
import { useSidebar } from "utils/sidebarProvider";
import WorkflowHeader from "./WorkflowHeader";
import SidebarWrapper from "components/UI/SidebarWrapper";
import Workflow from "./Workflow";

import styles from "./TeamPlaybook.module.scss";
import compose from "./compose";

export default compose(
  ({
    TEAM_PLAY_QUERY: { teamPlay },
    closed,
    setClosed,
    list,
    currentIndex,
    currentTab,
    setCurrentTab,
    setCurrentIndex,
    match,
    history,
    handleFinishTeamPlay,
  }) => {
    const currentObj = list[currentTab][currentIndex];

    const setObj = (tab, index) => {
      setCurrentTab(tab);
      setCurrentIndex(index);
    };

    const { contentContext } = useSidebar();
    const [content, setContent] = contentContext;

    const mappedList = [];

    if (teamPlay.type === match.params.toolType) {
      teamPlay.toolkit.steps.map((section, index) => {
        const substeps = [];

        section.substeps.map((substep, index2) => {
          substeps.push({
            title: substep.title,
            action: () => setObj(index, index2),
            style: currentObj.title === substep.title ? "active" : null,
          });
        });

        mappedList.push({
          sectionTitle: section.title,
          items: substeps,
        });
      });
    }

    const sidebarContent = mappedList;

    useEffect(() => {
      setContent(sidebarContent);
    }, [currentObj]);

    if (teamPlay.type === match.params.toolType) {
      return (
        <div className={styles.main}>
          <WorkflowHeader
            toolkit={teamPlay.toolkit}
            list={list}
            currentTab={currentTab}
            currentIndex={currentIndex}
            setCurrentTab={setCurrentTab}
            setCurrentIndex={setCurrentIndex}
            teamId={match.params.teamId}
            history={history}
            handleFinishTeamPlay={handleFinishTeamPlay}
          />
          <SidebarWrapper noHeader>
            <div
              className={classNames(styles.wrapper, closed && styles.closed)}
            >
              <div
                className={classNames(
                  styles.evalWrapper,
                  window.innerWidth < 1050 && styles.mobile
                )}
              >
                <Workflow
                  currentObj={currentObj}
                  toolkit={teamPlay.toolkit}
                  teamPlay={teamPlay}
                />
              </div>
            </div>
          </SidebarWrapper>
        </div>
      );
    }
    return null;
  }
);
