import { withState, compose, withHandlers } from 'recompose'
import { withRouter } from 'react-router-dom'
import withStore from 'utils/withStore'
import { SELECT_ORG } from 'store/actionTypes'
import { graphql } from 'react-apollo'
import { loader } from 'graphql.macro'
import account from 'store/account/index'
const SET_ORG_CONTRACT_AS_ACCOUNT_ADMIN = loader(
  'client/Mutations/setOrgContractAsAccountAdmin.graphql'
)

export default compose(
  withState('confirmModal', 'setConfirmModal', false),
  graphql(SET_ORG_CONTRACT_AS_ACCOUNT_ADMIN, {
    name: 'setContract',
    options: ({ organization, accountId }) => ({
      update: (proxy, { data: { setOrgContractAsAccountAdmin } }) => {
        window.location.reload()
      }
    })
  }),

  withHandlers({
    setRole:
      ({ organization, setContract }) =>
      async ({ roleType }) => {
        await setContract({
          variables: {
            org_id: organization.id,
            roleType
          }
        })
      }
  }),
  withHandlers({
    handleRoleChange:
      ({ setRole, setConfirmModal }) =>
      ({ roleType }) => {
        if (roleType === 'None') {
          setConfirmModal(true)
        } else {
          setRole({ roleType })
        }
      }
  })
)
