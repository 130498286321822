import React from "react";
import { withRouter } from "react-router-dom";
import { graphql } from "react-apollo";
import { compose } from "recompose";
import handleLoading from "utils/handleLoading";
import { loader } from "graphql.macro";

const CANDIDATE_QUERY = loader("./Queries/CandidateQuery1.graphql");

export default compose(
  withRouter,
  graphql(CANDIDATE_QUERY, {
    options: ({
      match: {
        params: { candidateId, teamId, positionId, orgId, candidateType },
      },
    }) => ({
      variables: {
        user_id:
          candidateType === "candidate" ? parseInt(candidateId, 10) : null,
        person_id:
          candidateType === "person" ? parseInt(candidateId, 10) : null,
        team_id: parseInt(teamId, 10),
        position_id: parseInt(positionId, 10),
        org_id: parseInt(orgId, 10),
      },
      notifyOnNetworkStatusChange: true,
    }),
  }),
  handleLoading
)(({ data }) => {
  const truncate = (string) => {
    if (string.length > 14) return `${string.substring(0, 14)}...`;
    return string;
  };

  return <React.Fragment>{data.candidateInTeam.name}</React.Fragment>;
});
