import React, { useRef, useState, useEffect } from "react";
import classNames from "classnames";
import Popup from "reactjs-popup";
import AILabel from "components/UI/AILabel";
import AIContentTag from "./AIContentTag";

import styles from "./Summary.module.scss";

function getDimGroup(dim) {
  if (dim.groupId && dim.groupId.includes("team_value")) {
    return "values";
  }
  if (dim.groupId && dim.groupId.includes("team_big5")) {
    return "big5";
  }
  if (dim.code && dim.code.includes("team_compe")) {
    return "competencies";
  }
}

const colors = {
  values: "yellow",
  big5: "blue",
  competencies: "green",
};

const getContentHtml = (content) => {
  return {
    __html: content,
  };
};

export default ({ isGenerating, gptSummary, dimensions }) => {
  const summaryRef = useRef();

  const [activeTag, setActiveTag] = useState(null);
  const [activeTagDim, setActiveTagDim] = useState(null);
  const [container, setContainer] = useState(null);

  const handleTagHover = ({ span, dim }) => {
    setActiveTag(span);
    setActiveTagDim(dim);
  };

  useEffect(() => {
    const spans = summaryRef.current.querySelectorAll("span");

    spans.forEach((span, index) => {
      const dim_id = parseInt(span.getAttribute("dim_id"), 10);
      const dim = dimensions.find((dim) => dim.dim_id === dim_id);

      if (dim) {
        span.classList.add(styles.textLabel, styles[colors[getDimGroup(dim)]]);
        span.addEventListener("mouseover", () => {
          handleTagHover({ span, dim });
        });
        span.addEventListener("mouseout", () => {
          setActiveTag(null);
          setActiveTagDim(null);
        });
      }
    });
  }, [summaryRef, gptSummary]);

  useEffect(() => {
    setContainer(summaryRef.current);
  }, [summaryRef]);

  return (
    <div className={styles.wrapper}>
      {gptSummary !== "" && (
        <div className={styles.summaryHeader}>
          <div className={styles.left}>
            <div className="aiPopupWrapper">
              <Popup
                trigger={
                  <div className={styles.aiLabel}>
                    <AILabel />
                  </div>
                }
                on="hover"
                keepTooltipInside="body"
                position="bottom left"
                arrow={false}
              >
                <div className={styles.aiPopup}>
                  <p>
                    Just a heads-up: the content in this section is enhanced by
                    Artificial Intelligence. While this technology adds a
                    cutting-edge touch, it may occasionally lead to unexpected
                    or unconventional statements. Your feedback on it would be
                    much appreciated, so please let us know if you have any
                    questions or concerns.
                  </p>
                </div>
              </Popup>
            </div>
          </div>
        </div>
      )}
      <div
        className={classNames(
          styles.contentWrapper,
          isGenerating && styles.generating
        )}
      >
        <div
          className={classNames(
            styles.content,
            isGenerating && styles.generating
          )}
          ref={summaryRef}
          id="summary-container"
        >
          <div dangerouslySetInnerHTML={getContentHtml(gptSummary)} />
          {activeTag && activeTagDim && (
            <AIContentTag
              dim={activeTagDim}
              tag={activeTag}
              container={container}
            />
          )}
        </div>
      </div>
    </div>
  );
};
