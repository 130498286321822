import { compose, withHandlers } from 'recompose'
import { graphql } from 'react-apollo'
import { loader } from 'graphql.macro'
import { loadStripe } from '@stripe/stripe-js'
import { withRouter } from 'react-router-dom'
import withStore from 'utils/withStore'

const CREATE_CUSTOMER_PORTAL_SESSION = loader(
  './createCustomerPortalSession.graphql'
)

export default compose(
  withRouter,
  graphql(CREATE_CUSTOMER_PORTAL_SESSION, {
    name: 'createCustomerPortalSessionMutation'
  }),
  withStore('viewer'),
  withHandlers({

    createCustomerPortalSession: ({
      createCustomerPortalSessionMutation,
      match
    }) => async () => {
      const {
        data: { createCustomerPortalSession: sessionUrl }
      } = await createCustomerPortalSessionMutation({
        variables: {
          accountId: parseInt(match.params.accountId, 10)
        }
      })

      // console.log({ sessionUrl })
      window.location.replace(sessionUrl)
    }
  })
)
