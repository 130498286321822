export const ACTIVATE_ONBOARDING = "ACTIVATE_ONBOARDING";
export const SET_CASE_STUDY = "SET_CASE_STUDY";
export const SET_ONBOARDING = "SET_ONBOARDING";
export const SET_ONBOARDING_STEP = "SET_ONBOARDING_STEP";
export const NEXT_ONBOARDING_STEP = "NEXT_ONBOARDING_STEP";
export const PREV_ONBOARDING_STEP = "PREV_ONBOARDING_STEP";
export const SKIP_ONBOARDING_STEP = "SKIP_ONBOARDING_STEP";
export const SURVEY_COMPLETED = "SURVEY_COMPLETED";
export const TOGGLE_SURVEYS_REMINDER = "TOGGLE_SURVEYS_REMINDER";
export const FINISH_ONBOARDING = "FINISH_ONBOARDING";
export const SET_VIEWER = "SET_VIEWER";
export const SELECT_ORG = "SELECT_ORG";
export const UPDATE_ORG_NAME = "UPDATE_ORG_NAME";
export const RESET_ORG = "RESET_ORG";
export const INVITE = "INVITE";
export const CLOSE_INVITE = "CLOSE_INVITE";
export const FINISH_NEWPOSITION = "FINISH_NEWPOSITION";
export const TOGGLE_EDITING_COMPETENCIES = "TOGGLE_EDITING_COMPETENCIES";
export const DEACTIVATE_CANDIDATE_ONBOARDING =
  "DEACTIVATE_CANDIDATE_ONBOARDING";
export const TEAMDEV_STEP = "TEAMDEV_STEP";
export const ACCOUNT_LIMIT_EXCEEDED = "ACCOUNT_LIMIT_EXCEEDED";
export const CLOSE_ACCOUNT_LIMIT_EXCEEDED = "ClOSE_ACCOUNT_LIMIT_POPUP";
export const SET_ACCOUNT = "SET_ACCOUNT";
export const ROUTE_CHANGE = "ROUTE_CHANGE";
export const SET_ROUTE_PARAMS = "SET_ROUTE_PARAMS";
export const TOGGLE_STATE_CHANGE_IN_PROGRESS =
  "TOGGLE_STATE_CHANGE_IN_PROGRESS";
