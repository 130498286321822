import React from "react";
import Field from "components/UI/Field";
import Button from "components/UI/Button";
import Icon from "components/UI/Icon";
import SearchTalentPool from "components/Invite/SearchTalentPool";
import UserAvatar from "components/UserAvatar";
import styles from "./Invite.module.scss";

export default ({
  nameOrEmail,
  setNameOrEmail,
  setError,
  addToPlayersList,
  error,
  team,
  players
}) => (
  <div className={styles.searchForm}>
    <div className={styles.smallRow}>
      <p>To:</p>
      <Field
        fullWidth
        white
        name="nameOrEmail"
        type="text"
        placeholder={"Enter email address or name"}
        autoComplete="off"
        value={nameOrEmail}
        onChange={e => {
          setNameOrEmail(e.target.value);
        }}
        paddingRight={nameOrEmail.length > 0}
      />
      {nameOrEmail.length > 0 && (
        <>
          <div className={styles.delete}>
            <Button
              bStyle={["none"]}
              type="button"
              onClick={() => {
                setError("");
                setNameOrEmail("");
              }}
            >
              <Icon icon="Close" />
            </Button>
          </div>
        </>
      )}
      {nameOrEmail.length > 0 && (
        <SearchTalentPool
          handleValidateAndUpdateList={member => {
            if (!players.some(player => player.id === member.id)) {
              addToPlayersList(member);
            }
            setError("");
            setNameOrEmail("");
          }}
          setError={setError}
          error={error}
          where={{
            searchValue: nameOrEmail,
            org_id: team.organization_id,
            team_id: team.id,
            validationCase: "teamPlay",
            invites: false
          }}
        />
      )}
    </div>
  </div>
);
