import { withRouter } from "react-router-dom";
import { compose, withHandlers } from "recompose";
import { graphql } from "react-apollo";
import { loader } from "graphql.macro";
import withStore from "utils/withStore";
import handleLoading from "utils/loader";

const LEADERSHIP_SPAN_EVALUATION = loader(
  "./leadershipSpanEvaluation2.graphql"
);

export default compose(
  withRouter,
  withStore("viewer"),
  graphql(LEADERSHIP_SPAN_EVALUATION, {
    options: ({
      match: {
        params: { candidateId, positionId, candidateType },
      },
      item,
    }) => ({
      variables: {
        user_id:
          candidateType === "candidate" ? parseInt(candidateId, 10) : null,
        person_id:
          candidateType === "person" ? parseInt(candidateId, 10) : null,
        position_id: parseInt(positionId, 10),
        span_id: item.span.id,
      },
    }),
  }),
  handleLoading(() => null)
);
