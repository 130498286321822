import React from "react";
import UserAvatar from "components/UserAvatar";
import Button from "components/UI/Button";
import ModalTitle from "components/UI/ModalTitle";

import styles from "./EvaluatorsModal.module.scss";

export default ({ position, evaluators, candidates, updateModal }) => (
  <div className={styles.wrapper}>
    <Button bStyle="close" type="button" onClick={() => updateModal("")}>
      <i className="fal fa-times" />
    </Button>
    <div className={styles.modalWrapper}>
      <div className={styles.modalHead}>
        <ModalTitle>Evaluators</ModalTitle>
        <h4 className={styles.subtitle}>
          People who have evaluated candidates.
        </h4>
      </div>
      <div className={styles.evaluatorsWrapper}>
        {evaluators.map(e => {
          const evaluated = [];

          candidates.map(c => {
            c.evaluation.some(e1 => e1.evaluatedBy.id === e.id) &&
              !evaluated.find(e2 => e2.id === c.id) &&
              evaluated.push(c);
          });

          return (
            <div key={e.name} className={styles.evaluatorBox}>
              <div className={styles.header}>
                <div className={styles.left}>
                  <div className={styles.avatar}>
                    <UserAvatar member={e} size="mediumSmall" />
                  </div>
                  <div className={styles.info}>
                    <h5>{e.name}</h5>
                    <p>{e.email}</p>
                  </div>
                </div>
                <p className={styles.status}>
                  {`${evaluated.length} out of ${candidates.length} evaluated`}
                </p>
              </div>
              <div className={styles.evaluatedBox}>
                {evaluated.map(ev => {
                  const evaluatedNo = ev.evaluation.filter(
                    ev1 => ev1.evaluatedBy.id === e.id && ev1.evaluation
                  ).length;

                  return (
                    <div key={ev.name} className={styles.evaluated}>
                      <div className={styles.left}>
                        <div className={styles.avatar}>
                          <UserAvatar member={ev} size="verySmall" />
                        </div>
                        <h5>{ev.name}</h5>
                      </div>
                      <div className={styles.status}>
                        {evaluatedNo < position.requirements.length + 6 && (
                          <p>
                            {`${evaluatedNo} of ${position.requirements.length +
                              6}`}
                          </p>
                        )}
                        {evaluatedNo >= position.requirements.length + 6 && (
                          <div className={styles.icon}>
                            <i className="far fa-check" />
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  </div>
);
