import React, { useState } from "react";
import Button from "components/UI/Button";
import classNames from "classnames";
import UserAvatar from "components/UserAvatar";
import Timestamp from "react-timestamp";

import styles from "./CompareCandidates.module.scss";

export default ({
  position,
  total,
  evaluation,
  evaluationInfo,
  candidateTotals,
}) => {
  const getEvaluators = () => {
    const evaluators = [];
    evaluationInfo.map((e) => {
      if (
        (e.type === "requirement" &&
          position.requirements.find((r) => r.id === e.item_id)) ||
        (e.type === "competency" &&
          position.competencies.slice(0, 3).find((r) => r.id === e.item_id)) ||
        (e.type !== "requirement" && e.type !== "competency")
      ) {
        if (!evaluators.includes(e.evaluatedBy)) {
          evaluators.push(e.evaluatedBy);
        }
      }
    });
    return evaluators;
  };

  const [selectedEvaluator, setSelectedEvaluator] = useState("");

  const types = {
    personality: "Behavioural diversity",
    values: "Values alignment",
    intuition: "Gut feeling",
  };

  const getTitle = ({ type, index, item_id }) => {
    if (total === "Fit Factors") {
      return types[type];
    }
    if (total === "Total for All") {
      if (index === 0) {
        return "Position requirements";
      }
      if (index === 1) {
        return "Differentiating competencies";
      }
      if (index === 2) {
        return "Team fit";
      }
    }
    if (type === "requirement") {
      const posReq = position.requirements.find((r) => r.id === item_id);
      return posReq.title;
    }
    if (type === "competency") {
      const posComp = position.competencies.find((r) => r.id === item_id);
      return posComp.title;
    }
  };

  const getEvaluations = selectedEvaluator
    ? evaluationInfo.filter((ev) => ev.evaluatedBy.id === selectedEvaluator.id)
    : evaluationInfo;

  const getFromType = {
    requirement: "Position requirements",
    competency: "Differentiating competencies",
    fitFactors: "Fit Factors",
  };

  return (
    <div className={styles.totalDrilldown}>
      <div className={styles.members}>
        {getEvaluators().map((evaluator) => (
          <Button
            key={Math.random(10)}
            bStyle={["none"]}
            onClick={() => {
              if (selectedEvaluator !== evaluator) {
                setSelectedEvaluator(evaluator);
              } else {
                setSelectedEvaluator("");
              }
            }}
          >
            <div
              className={classNames(
                styles.avatar,
                selectedEvaluator === evaluator && styles.selected
              )}
            >
              <UserAvatar member={evaluator} size="small" />
            </div>
          </Button>
        ))}
      </div>
      <p className={styles.title}>{total}</p>
      <div className={styles.total}>
        <p>Total</p>
        <p>
          {selectedEvaluator
            ? candidateTotals.find(
                (ct) => ct.evaluator.id === selectedEvaluator.id
              ) &&
              candidateTotals.find(
                (ct) => ct.evaluator.id === selectedEvaluator.id
              ).total
            : evaluation > 0
            ? evaluation
            : "N/A"}
        </p>
      </div>
      {selectedEvaluator ? (
        <div className={styles.evaluations}>
          {getEvaluations.map((e, index) => {
            if (
              e.type === "competency" &&
              !position.competencies.find((c) => c.id === e.item_id)
            )
              return "LostComp";
            if (
              e.type === "requirement" &&
              !position.requirements.find((c) => c.id === e.item_id)
            )
              return "LostReq";

            return (
              <div
                className={styles.evaluation}
                key={`Evaluator-${Math.random(10)}`}
              >
                <div className={styles.top}>
                  <p>{getTitle({ type: e.type, index, item_id: e.item_id })}</p>
                  <p>{e.evaluation > 0 ? e.evaluation : "N/A"}</p>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className={styles.evaluations}>
          {candidateTotals &&
            candidateTotals
              .filter((ct) => getFromType[ct.type] === total)
              .map((ct) => (
                <div
                  className={styles.evaluation}
                  key={`Evaluator-${Math.random(10)}`}
                >
                  <div className={styles.top}>
                    <p>{ct.evaluator.name}</p>
                    <p>{ct.total}</p>
                  </div>
                </div>
              ))}
        </div>
      )}
    </div>
  );
};
