import React from "react";
import classNames from "classnames";
import Timestamp from "react-timestamp";
import { Link } from "react-router-dom";
import Button from "components/UI/Button";
import Icon from "components/UI/Icon";
import TeamMemberAvatars from "components/TeamMemberAvatars";
import compose from "./compose";

import styles from "./StartTool.module.scss";

export default compose(
  ({ team, tool, handleStartNewWorkflow, prevRuns, setPrevRuns }) => {
    return (
      <div className={styles.wrapper}>
        <h2>{tool.name}</h2>
        <h4>{tool.description}</h4>
        <div className={styles.buttonGroup}>
          <Button
            bStyle={["green", "large"]}
            type="button"
            onClick={handleStartNewWorkflow}
          >
            <i className="fas fa-play" />
            {tool.inProgress ? "Join ongoing session" : "Start new session"}
          </Button>
          {team.plays.filter((p) => p.toolkit.name === tool.name).length >
            0 && (
            <Button
              bStyle={["secondary", "large"]}
              type="button"
              onClick={() =>
                prevRuns ? setPrevRuns(false) : setPrevRuns(true)
              }
            >
              {prevRuns ? (
                <>
                  <i className="fas fa-chevron-up" />
                  {"Previous sessions • " +
                    team.plays.filter((p) => p.toolkit.name === tool.name)
                      .length}
                </>
              ) : (
                <>
                  <i className="fas fa-chevron-down" />
                  {"Previous sessions • " +
                    team.plays.filter((p) => p.toolkit.name === tool.name)
                      .length}
                </>
              )}
            </Button>
          )}
        </div>
        {prevRuns && (
          <div className={styles.playsWrapper}>
            {team.plays
              .filter((p) => p.toolkit.name === tool.name)
              .sort((a, b) => b.createdAt - a.createdAt)
              .map((p, index) => {
                const date = new Date(p.createdAt * 1000);
                return (
                  <div className={styles.play} key={p.id}>
                    <Link to={`/team/${team.id}/workflow/${p.id}/${p.type}`}>
                      <Button bStyle={["newsimple", "nopadding"]} type="button">
                        <div className={styles.mainLeft}>
                          <div
                            className={classNames(
                              styles.icon,
                              tool.inProgress && index === 0 && styles.progress
                            )}
                          >
                            <Icon
                              icon={
                                tool.inProgress && index === 0
                                  ? "Progress"
                                  : "Check"
                              }
                            />
                          </div>
                          <div className={styles.right}>
                            <div className={styles.top}>
                              <h5>
                                {"Session " +
                                  (team.plays.filter(
                                    (p) => p.toolkit.name === tool.name
                                  ).length -
                                    index)}
                              </h5>
                            </div>
                            {tool.inProgress && index === 0 ? (
                              <p className={styles.time}>In progress</p>
                            ) : (
                              <p className={styles.time}>
                                Completed -
                                <Timestamp relative date={date} autoUpdate />
                              </p>
                            )}
                          </div>
                        </div>
                        <div className={styles.mainRight}>
                          <TeamMemberAvatars
                            members={p.players}
                            length={5}
                            small
                          />
                        </div>
                      </Button>
                    </Link>
                  </div>
                );
              })}
          </div>
        )}
        <div className={styles.imageWrapper}>
          <img src={tool.coverImage} />
        </div>
        <h5 className={styles.descTitle}>Use this tool for</h5>
        <ul className={styles.desc}>
          {tool.useFor &&
            tool.useFor
              .split(";")
              .map((line) => <li key={`list-${Math.random(10)}`}>{line}</li>)}
        </ul>
        <div className={styles.footer}>
          <div className={styles.col}>
            <div className={styles.icon}>
              <i className="fas fa-clock" />
            </div>
            <div className={styles.text}>
              <h5 className={styles.time}>{tool.time}</h5>
              <p>Time</p>
            </div>
          </div>
          <div className={styles.col}>
            <div className={styles.icon}>
              <i className="fas fa-hat-cowboy" />
            </div>
            <div className={styles.text}>
              <h5 className={styles.difficulty}>{tool.difficulty}</h5>
              <p>Difficulty</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
);
