import InputUserAvatar from "components/UI/InputUserAvatar/index";
import React, { useState } from "react";
import Popover from "../Popover/index";
import Icon from "components/UI/Icon/index";
import { typography } from "style/general";
import Chip from "../Chip/index";

const UserRowStatus = ({ user, status, actions, actionText, icon }) => {
  const [popoverOpen, setPopoverOpen] = useState(false);

  const statusVariant = {
    COMPLETED: {
      variant: "light",
      color: "green",
      text: "Completed",
    },
    IN_PROGRESS: {
      variant: "light",
      color: "orange",
      text: "In progress",
    },
    NOT_STARTED: {
      variant: "light",
      color: "grey",
      text: "Not started",
    },
  };

  return (
    <div
      style={{
        paddingTop: 12,
        paddingBottom: 12,
        display: "flex",
        alignItems: "center",
        gap: 16,
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flex: 1,
          gap: 12,
        }}
      >
        <InputUserAvatar user={user} />
      </div>
      {actions && (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              gap: 4,
            }}
          >
            {icon && <Icon icon={icon} />}
            {status && (
              <Chip
                variant={statusVariant[status].variant}
                color={statusVariant[status].color}
              >
                {statusVariant[status].text.toUpperCase()}
              </Chip>
            )}

            {actionText && (
              <p
                style={{
                  ...typography.body,
                  ...typography.primary,
                  ...typography.bold,
                }}
              >
                {actionText}
              </p>
            )}
          </div>
          <Popover
            open={popoverOpen}
            setOpen={setPopoverOpen}
            popOverActions={actions}
            popLeft
          />
        </>
      )}
    </div>
  );
};

export default UserRowStatus;
