import React from 'react'
import {
  compose, withState, branch, withHandlers
} from 'recompose'
import { graphql } from 'react-apollo'
import { withRouter } from 'react-router-dom'
import { loader } from 'graphql.macro'
import handleLoading from 'utils/loader'
import Circles from 'components/Loaders/Circles'
import { loadStripe } from '@stripe/stripe-js'

const GET_STRIPE_PRODUCTS = loader('./getStripeProducts2.graphql')
const CREATE_CHECKOUT_SESSION = loader('./createCheckoutSession3.graphql')

export default compose(
  withRouter,
  withState('couponCode', 'setCouponCode', null),
  graphql(GET_STRIPE_PRODUCTS, {
    options: props => ({
      variables: { account_id: parseInt(props.match.params.accountId, 10) }
    })
  }),
  graphql(CREATE_CHECKOUT_SESSION, {
    name: 'createCheckoutSessionMutation'
  }),
  handleLoading(() => (
    <div style={{ height: '100vh' }}>
      <Circles />
    </div>
  )),

  withHandlers({
    createCheckoutSession: ({
      createCheckoutSessionMutation,
      match,
      couponCode
    }) => async (selection) => {
      const {
        data: { createCheckoutSession: sessionId }
      } = await createCheckoutSessionMutation({
        variables: {
          accountId: parseInt(match.params.accountId, 10),
          priceId: selection.id,
          couponCode
        }
      })
      const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PK)
      stripe.redirectToCheckout({ sessionId })
    }
  })
)
