import React from "react";
import classNames from "classnames";
import styles from "./Button.module.scss";

export default ({
  bStyle,
  type,
  onClick,
  disabled,
  children,
  dataTour,
  id,
}) => (
  <button
    className={
      typeof bStyle === "object"
        ? classNames(bStyle.map((s) => styles[s]))
        : bStyle
    }
    type={type || "button"}
    data-tour={dataTour}
    onClick={onClick}
    disabled={disabled}
    id={id}
  >
    {children}
  </button>
);
