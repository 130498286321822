import React, { useState } from "react";
import Popup from "reactjs-popup";
import classNames from "classnames";
import Timestamp from "react-timestamp";
import UserAvatar from "components/UserAvatar";
import Icon from "components/UI/Icon";
import Button from "components/UI/Button";
import DOMPurify from "dompurify";
import { ReactComponent as HighDevImage } from "images/position/deviation.svg";
import TotalDrilldown from "./TotalDrilldown";

import styles from "./CompareCandidates.module.scss";

const sectionTotals = [];

const truncate = (string) => {
  if (string.length > 196) return `${string.substring(0, 196)}...`;
  return string;
};

const getContentHtml = (content) => ({ __html: DOMPurify.sanitize(content) });

const cellComponent = (title, desc, tooltip, updateDescModal) => {
  const bool = !desc || desc === "<p></p>";

  return (
    <div className={styles.basicCell}>
      <div className={styles.left}>
        <p
          className={styles.cellTitle}
          onClick={() => updateDescModal({ title, desc })}
        >
          {title}
        </p>
      </div>
      {tooltip && (
        <div className="tooltipPopupWrapper">
          <Popup
            trigger={
              <div className={styles.tooltipButton}>
                <Button bStyle={["none"]} type="button">
                  <i className="fas fa-info-circle" />
                </Button>
              </div>
            }
            className={styles.popup}
            position="right center"
            on="hover"
            arrow
            keepTooltipInside="table"
            closeOnDocumentClick
            repositionOnResize
          >
            <div className={styles.tooltipPopover}>
              <p>{tooltip}</p>
            </div>
          </Popup>
        </div>
      )}
    </div>
  );
};

const trafficLight = (evaluation, deviation) => {
  const lights = (
    <div
      className={classNames(
        styles.lights,
        styles["lights-" + Math.round(evaluation)],
        deviation >= 2 && styles.highDev
      )}
    >
      {[...Array(5)].map((e, i) => (
        <div
          key={"Array-" + i}
          className={classNames(
            styles.light,
            Math.floor(evaluation) > i && styles.active,
            Math.floor(evaluation) === i &&
              evaluation - Math.floor(evaluation) > 0.3 &&
              styles.half
          )}
        ></div>
      ))}
    </div>
  );

  return lights;
};

const cellComponent2 = ({
  title,
  evaluation,
  deviation,
  showComments,
  evaluationInfo,
  total,
  candidates,
  position,
  candidateTotals,
  className,
}) => {
  return (
    <div className={styles.cell}>
      <div
        className={classNames(styles.eval, deviation >= 2 && styles.deviation)}
      >
        <div className="tooltipPopupWrapper">
          <Popup
            trigger={
              <div className={styles.cellTrigger}>
                <div className={styles.trafficLight}>
                  {className === "totalRow" || className === "epicTotal"
                    ? evaluation
                    : trafficLight(evaluation, deviation)}
                </div>
                {deviation >= 2 && (
                  <div className={styles.highDevNotice}>
                    <Icon icon="Warning" />
                  </div>
                )}
                {!evaluation && <div className={styles.noInfo}>N/A</div>}
              </div>
            }
            className={styles.popup}
            position="right center"
            on="hover"
            mouseEnterDelay={300}
            arrow
            keepTooltipInside="table"
            closeOnDocumentClick
            repositionOnResize
          >
            <div className={styles.popover}>
              <div className={classNames(styles.comments)}>
                <div className={styles.top}>
                  <h5 className={styles.commentsTitle}>{title}</h5>
                  <div className={styles.trafficLight}>
                    {trafficLight(evaluation, deviation)}
                    <p className={styles.score}>
                      {evaluation > 0 ? evaluation + " out of 5" : "N/A"}
                    </p>
                  </div>
                </div>
                {deviation >= 2 && (
                  <div className={styles.highDev}>
                    <h5>High deviation</h5>
                    <p>
                      Evaluations vary greatly. Further discussion is strongly
                      recommended.
                    </p>
                  </div>
                )}
                {total ? (
                  <TotalDrilldown
                    evaluation={evaluation}
                    evaluationInfo={evaluationInfo}
                    candidates={candidates}
                    position={position}
                    total={total}
                    candidateTotals={candidateTotals}
                  />
                ) : (
                  <div className={styles.evaluationsBox}>
                    {evaluationInfo.map((c) => {
                      const date = new Date(c.createdAt * 1000);
                      return (
                        <div
                          key={`comment-${Math.random(10)}`}
                          className={styles.evaluationRow}
                        >
                          <div className={styles.left}>
                            <div className={styles.avatarWrapper}>
                              <UserAvatar member={c.evaluatedBy} size="small" />
                            </div>
                          </div>
                          <div className={styles.right}>
                            <div className={styles.scores}>
                              <div className={styles.upper}>
                                <p className={styles.name}>
                                  {c.evaluatedBy.name}
                                </p>
                                <p className={styles.time}>
                                  <Timestamp date={date} autoUpdate />
                                </p>
                              </div>
                              <div className={styles.evaluationScore}>
                                <div className={styles.trafficLight}>
                                  {trafficLight(c.evaluation)}
                                  <p className={styles.score}>
                                    {c.evaluation > 0
                                      ? c.evaluation + " out of 5"
                                      : "N/A"}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className={styles.commentsColumn}>
                              {c.comments &&
                                c.comments.length > 0 &&
                                c.comments.map((comment) => {
                                  const date2 = new Date(
                                    comment.created_at * 1000
                                  );
                                  return (
                                    <div
                                      className={styles.commentWrapper}
                                      key={comment.id}
                                    >
                                      <p
                                        key={comment.id}
                                        className={styles.commentMessage}
                                      >
                                        {comment.comment}
                                      </p>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </Popup>
        </div>
      </div>
    </div>
  );
};

const getCandidates = (candidates, selectedEvaluators) => {
  const candidatesList = [];

  if (selectedEvaluators.length > 0) {
    candidates.map((candidate) => {
      selectedEvaluators.map((evaluator) => {
        if (
          candidate.evaluation.some((e) => e.evaluatedBy.id === evaluator.id) &&
          !candidatesList.find((cl) => cl.id === candidate.id)
        ) {
          candidatesList.push(candidate);
        }
      });
    });
  } else {
    candidatesList.push(...candidates);
  }

  return candidatesList;
};

const filterEvaluation = (evaluation, selectedEvaluators) => {
  if (selectedEvaluators.length > 0) {
    return evaluation.filter((e) =>
      selectedEvaluators.some((se) => e.evaluatedBy.id === se.id)
    );
  }
  return evaluation;
};

const createRow = ({
  candidates,
  position,
  evalSource,
  col0,
  className,
  selectedEvaluators,
  total,
  updateDescModal,
  orderBy,
}) => {
  const row = {
    col1: cellComponent(col0.title, col0.desc, col0.tooltip, updateDescModal),
    colLast: "",
    className,
    comment: null,
  };

  const evaluators = [];
  candidates.map((c) => {
    c.evaluation.map((e) => {
      if (!evaluators.find((c1) => c1.id === e.evaluatedBy.id)) {
        evaluators.push(e.evaluatedBy);
      }
    });
  });

  const getEvaluators =
    selectedEvaluators.length > 0 ? selectedEvaluators : evaluators;

  getCandidates(candidates, selectedEvaluators).forEach((cand, cIndex) => {
    const candidateTotals = [];
    const source = !evalSource
      ? false
      : evalSource === "total"
      ? filterEvaluation(cand.evaluation, selectedEvaluators)
      : evalSource === "fitFactors"
      ? filterEvaluation(cand.evaluation, selectedEvaluators).filter(
          (e) =>
            e.type === "personality" ||
            e.type === "values" ||
            e.type === "intuition"
        )
      : evalSource === "competency"
      ? filterEvaluation(cand.evaluation, selectedEvaluators).filter((comp) =>
          position.competencies.slice(0, 3).some((op) => comp.item_id === op.id)
        )
      : typeof evalSource === "string"
      ? filterEvaluation(cand.evaluation, selectedEvaluators).filter(
          (e) => e.type === evalSource
        )
      : filterEvaluation(cand.evaluation, selectedEvaluators).filter(
          (e) =>
            evalSource.source === e.type && e.item_id === evalSource.item_id
        );

    const comments =
      source &&
      source
        .map((e) => e.comments)
        .flat()
        .filter(Boolean);

    const evaluationInfo =
      source &&
      source.map((e) => ({
        item_id: e.item_id,
        title: col0.title,
        evaluatedBy: e.evaluatedBy,
        createdAt: e.created_at,
        evaluation: e.evaluation,
        type: e.type,
        comments: e.comments && e.comments.flat().filter(Boolean),
      }));

    if (evalSource !== "fitFactors" && evalSource !== "total") {
      getEvaluators.map((se) => {
        source.length > 0 &&
          Math.round(
            (source
              .filter((s) => s.evaluatedBy.id === se.id && s.evaluation !== 0)
              .map((r) => r.evaluation)
              .reduce((a, b) => a + b, 0) /
              source
                .filter((s) => s.evaluatedBy.id === se.id && s.evaluation !== 0)
                .map((r) => r.evaluation).length) *
              100
          ) /
            100 >
            0 &&
          candidateTotals.push({
            type: evalSource,
            evaluator: se,
            total:
              Math.round(
                (source
                  .filter(
                    (s) => s.evaluatedBy.id === se.id && s.evaluation !== 0
                  )
                  .map((r) => r.evaluation)
                  .reduce((a, b) => a + b, 0) /
                  source
                    .filter(
                      (s) => s.evaluatedBy.id === se.id && s.evaluation !== 0
                    )
                    .map((r) => r.evaluation).length) *
                  100
              ) / 100,
          });
      });

      if (
        source.length > 0 &&
        (col0.title === "Total for Requirements" ||
          col0.title === "Total for Competencies")
      ) {
        sectionTotals.push({
          candidate: cand.name,
          section: col0.title,
          score:
            Math.round(
              (candidateTotals.reduce((a, v) => (a = a + v.total), 0) /
                candidateTotals.length) *
                100
            ) / 100,
        });
      }

      row[`col${cIndex + 2}`] =
        source.length > 0 ? (
          cellComponent2({
            title: col0.title,
            evaluation:
              Math.round(
                (candidateTotals.reduce((a, v) => (a = a + v.total), 0) /
                  candidateTotals.length) *
                  100
              ) / 100,
            deviation:
              className === "defaultRow" &&
              Math.max(
                ...source
                  .filter((s) => s.evaluation !== 0)
                  .map((r) => r.evaluation)
              ) -
                Math.min(
                  ...source
                    .filter((s) => s.evaluation !== 0)
                    .map((r) => r.evaluation)
                ),
            showComments: className !== "headerRow",
            comments,
            source,
            col0,
            evaluationInfo,
            total,
            candidates,
            position,
            candidateTotals,
            className,
          })
        ) : (
          <p
            className={
              className === "defaultRow" ? styles.notEvaluated : undefined
            }
          >
            {className === "defaultRow" ? <Icon icon="Close" /> : ""}
          </p>
        );
    }

    if (evalSource === "fitFactors") {
      getEvaluators.map((se) => {
        const personality =
          filterEvaluation(cand.evaluation, selectedEvaluators).filter(
            (e) => e.type === "personality" && e.evaluatedBy.id === se.id
          ).length > 0
            ? filterEvaluation(cand.evaluation, selectedEvaluators)
                .filter(
                  (e) =>
                    e.type === "personality" &&
                    e.evaluatedBy.id === se.id &&
                    e.evaluation !== 0
                )
                .map((r) => r.evaluation)
                .reduce((a, b) => a + b, 0) /
              filterEvaluation(cand.evaluation, selectedEvaluators)
                .filter(
                  (e) =>
                    e.type === "personality" &&
                    e.evaluatedBy.id === se.id &&
                    e.evaluation !== 0
                )
                .map((r) => r.evaluation).length
            : 0;
        const values =
          filterEvaluation(cand.evaluation, selectedEvaluators).filter(
            (e) => e.type === "values" && e.evaluatedBy.id === se.id
          ).length > 0
            ? filterEvaluation(cand.evaluation, selectedEvaluators)
                .filter(
                  (e) =>
                    e.type === "values" &&
                    e.evaluatedBy.id === se.id &&
                    e.evaluation !== 0
                )
                .map((r) => r.evaluation)
                .reduce((a, b) => a + b, 0) /
              filterEvaluation(cand.evaluation, selectedEvaluators)
                .filter(
                  (e) =>
                    e.type === "values" &&
                    e.evaluatedBy.id === se.id &&
                    e.evaluation !== 0
                )
                .map((r) => r.evaluation).length
            : 0;
        const intuition =
          filterEvaluation(cand.evaluation, selectedEvaluators).filter(
            (e) => e.type === "intuition" && e.evaluatedBy.id === se.id
          ).length > 0
            ? filterEvaluation(cand.evaluation, selectedEvaluators)
                .filter(
                  (e) =>
                    e.type === "intuition" &&
                    e.evaluatedBy.id === se.id &&
                    e.evaluation !== 0
                )
                .map((r) => r.evaluation)
                .reduce((a, b) => a + b, 0) /
              filterEvaluation(cand.evaluation, selectedEvaluators)
                .filter(
                  (e) =>
                    e.type === "intuition" &&
                    e.evaluatedBy.id === se.id &&
                    e.evaluation !== 0
                )
                .map((r) => r.evaluation).length
            : 0;

        var fitFactorsTotal = 0;
        var fitFactorsLength = 0;

        [personality, values, intuition].map((s) => {
          if (s && s !== 0) {
            fitFactorsLength++;
            fitFactorsTotal += s;
          }
        });

        if (fitFactorsTotal > 0 && fitFactorsLength > 0) {
          candidateTotals.push({
            type: evalSource,
            evaluator: se,
            total: Math.round((fitFactorsTotal / fitFactorsLength) * 100) / 100,
          });
        }
      });

      const personality =
        filterEvaluation(cand.evaluation, selectedEvaluators).filter(
          (e) => e.type === "personality"
        ).length > 0
          ? filterEvaluation(cand.evaluation, selectedEvaluators)
              .filter((e) => e.type === "personality")
              .map((r) => r.evaluation)
              .reduce((a, b) => a + b, 0) /
            filterEvaluation(cand.evaluation, selectedEvaluators)
              .filter((e) => e.type === "personality")
              .map((r) => r.evaluation).length
          : 0;
      const values =
        filterEvaluation(cand.evaluation, selectedEvaluators).filter(
          (e) => e.type === "values"
        ).length > 0
          ? filterEvaluation(cand.evaluation, selectedEvaluators)
              .filter((e) => e.type === "values")
              .map((r) => r.evaluation)
              .reduce((a, b) => a + b, 0) /
            filterEvaluation(cand.evaluation, selectedEvaluators)
              .filter((e) => e.type === "values")
              .map((r) => r.evaluation).length
          : 0;
      const intuition =
        filterEvaluation(cand.evaluation, selectedEvaluators).filter(
          (e) => e.type === "intuition"
        ).length > 0
          ? filterEvaluation(cand.evaluation, selectedEvaluators)
              .filter((e) => e.type === "intuition")
              .map((r) => r.evaluation)
              .reduce((a, b) => a + b, 0) /
            filterEvaluation(cand.evaluation, selectedEvaluators)
              .filter((e) => e.type === "intuition")
              .map((r) => r.evaluation).length
          : 0;

      var calc = 0;

      if (personality > 0) {
        calc++;
      }
      if (values > 0) {
        calc++;
      }
      if (intuition > 0) {
        calc++;
      }

      if (
        personality + values + intuition > 0 &&
        col0.title === "Total for Team fit"
      ) {
        sectionTotals.push({
          candidate: cand.name,
          section: col0.title,
          score:
            Math.round(
              (candidateTotals.reduce((a, v) => (a = a + v.total), 0) /
                candidateTotals.length) *
                100
            ) / 100,
        });
      }

      row[`col${cIndex + 2}`] =
        personality + values + intuition > 0 ? (
          cellComponent2({
            title: col0.title,
            evaluation:
              Math.round(
                (candidateTotals.reduce((a, v) => (a = a + v.total), 0) /
                  candidateTotals.length) *
                  100
              ) / 100,
            showComments: className !== "headerRow",
            comments,
            source,
            col0,
            evaluationInfo,
            total,
            className,
            candidates,
            position,
            candidateTotals,
          })
        ) : (
          <p className={className === "defaultRow" ? styles.yellow : undefined}>
            {className === "defaultRow" ? "Not evaluated" : ""}
          </p>
        );
    }

    if (
      evalSource === "total" &&
      sectionTotals.filter(
        (t) =>
          t.candidate === cand.name && t.section === "Total for Requirements"
      ).length > 0 &&
      sectionTotals.filter(
        (t) =>
          t.candidate === cand.name && t.section === "Total for Competencies"
      ).length > 0 &&
      sectionTotals.filter(
        (t) => t.candidate === cand.name && t.section === "Total for Team fit"
      ).length > 0
    ) {
      const score =
        Math.round(
          ((sectionTotals.find(
            (t) =>
              t.candidate === cand.name &&
              t.section === "Total for Requirements"
          ).score +
            sectionTotals.find(
              (t) =>
                t.candidate === cand.name &&
                t.section === "Total for Competencies"
            ).score +
            sectionTotals.find(
              (t) =>
                t.candidate === cand.name && t.section === "Total for Team fit"
            ).score) /
            3) *
            100
        ) / 100;

      const candidateTotals = [];

      getEvaluators.map((se) => {
        source.length > 0 &&
          Math.round(
            (source
              .filter((s) => s.evaluatedBy.id === se.id && s.evaluation !== 0)
              .map((r) => r.evaluation)
              .reduce((a, b) => a + b, 0) /
              source
                .filter((s) => s.evaluatedBy.id === se.id && s.evaluation !== 0)
                .map((r) => r.evaluation).length) *
              100
          ) /
            100 >
            0 &&
          candidateTotals.push({
            type: evalSource,
            evaluator: se,
            total:
              Math.round(
                (source
                  .filter(
                    (s) => s.evaluatedBy.id === se.id && s.evaluation !== 0
                  )
                  .map((r) => r.evaluation)
                  .reduce((a, b) => a + b, 0) /
                  source
                    .filter(
                      (s) => s.evaluatedBy.id === se.id && s.evaluation !== 0
                    )
                    .map((r) => r.evaluation).length) *
                  100
              ) / 100,
          });
      });

      row[`col${cIndex + 2}`] = score ? (
        cellComponent2({
          title: col0.title,
          evaluation: score > 0 ? score : "Not evaluated",
          showComments: false,
          comments,
          source,
          col0,
          evaluationInfo,
          total,
          className,
          candidates,
          position,
          candidateTotals,
        })
      ) : (
        <p className={className === "defaultRow" ? styles.yellow : undefined}>
          {className === "defaultRow" ? "Not evaluated" : ""}
        </p>
      );
    }
  });

  return row;
};

export default ({
  candidates,
  openPosition,
  showComments,
  setShowComments,
  selectedEvaluators,
  updateDescModal,
  orderBy,
}) => {
  const rows = React.useMemo(() => [
    {
      id: "reqHeader",
      className: "headerRow",
      col0: {
        title: "Position requirements",
        tooltip:
          "Rate the candidates' experiences, qualifications, and skills in relation to the role.",
      },
    },
    openPosition.requirements.map((r, index) => ({
      id: `requirements-row-${index + 1}`,
      className: "defaultRow",
      col0: { title: r.title, desc: r.description },
      evalSource: { source: "requirement", item_id: r.id },
    })),
    {
      id: "reqHeader",
      className: "totalRow",
      col0: {
        title: "Total for Requirements",
      },
      evalSource: "requirement",
      total: "Position requirements",
    },
    openPosition.competenciesHaveBeenConfed && {
      id: "compHeader",
      className: "headerRow",
      col0: {
        title: "Differentiating competencies",
        tooltip:
          "Rate the candidates' compliance with the key competencies of the role.",
      },
    },
    openPosition.competenciesHaveBeenConfed &&
      openPosition.competencies.slice(0, 3).map((r, index) => ({
        id: `competencies-row-${index + 1}`,
        className: "defaultRow",
        col0: { title: r.title },
        evalSource: { source: "competency", item_id: r.id },
      })),
    openPosition.competenciesHaveBeenConfed && {
      id: "compHeader",
      className: "totalRow",
      col0: {
        title: "Total for Competencies",
      },
      evalSource: "competency",
      total: "Differentiating competencies",
    },
    {
      id: "fitFactorsHeader",
      className: "headerRow",
      col0: {
        title: "Team fit",
        tooltip: "Rate the candidates' overall fit to the team.",
      },
    },
    {
      id: "personality",
      className: "defaultRow",
      col0: { title: "Behavioural diversity" },
      evalSource: "personality",
    },
    {
      id: "values",
      className: "defaultRow",
      col0: { title: "Values alignment" },
      evalSource: "values",
    },
    {
      id: "intuition",
      className: "defaultRow",
      col0: { title: "Gut feeling" },
      evalSource: "intuition",
    },
    {
      id: "fitFactorsHeader",
      className: "totalRow",
      col0: {
        title: "Total for Team fit",
      },
      evalSource: "fitFactors",
      total: "Fit Factors",
    },
    {
      id: "totalHeader",
      className: "epicTotal",
      col0: {
        title: "Total score",
        tooltip:
          "Candidates' total score for requirements, competencies and team fit.",
      },
      evalSource: "total",
      total: "Total for All",
    },
  ])
    .filter(Boolean)
    .flat();
  return rows.map((row) =>
    createRow({
      ...row,
      orderBy,
      candidates,
      position: openPosition,
      showComments,
      setShowComments,
      selectedEvaluators,
      updateDescModal,
    })
  );
};
