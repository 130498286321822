import React, { useState } from "react";
import classNames from "classnames";
import Modal from "react-modal";
import Icon from "components/UI/Icon";
import PercentageCircle from "components/UI/PercentageCircle";
import DimensionsNavigation from "./DimensionsNavigation";
import Button from "components/UI/Button";
import UserAvatar from "components/UserAvatar";
import PlayDimension from "./PlayDimension";
import OnboardCol from "images/position/OnboardCol";
import MiniChart from "./MiniChart";
import LineChart from "./LineChart";

import compose from "./compose";

import styles from "./TeamEffectivenessProgress.module.scss";

export default compose(
  ({
    data: { teamPlay: play },
    handleSendInviteEmail,
    match: { params },
    history,
    location,
    viewer,
    remindLoading,
    selectedDimension,
    setSelectedDimension,
  }) => {
    const [modal, setModal] = useState("");

    const completedPlayers = play.players.filter((player) =>
      player.effectiveness.some((e) => e.score !== null)
    );

    const currentPlayer = play.players.find(
      (player) => player.id === viewer.id
    );

    const totalScore =
      play.effectiveness
        .filter((e) => e.groupId === "team_play_effectiveness")
        .reduce((prev, current) => prev + current.score, 0) / 5;

    const items = [
      "Purpose",
      "People",
      "Power",
      "Protocols",
      "Presence",
    ].filter(Boolean);

    return (
      <div className={styles.wrapper}>
        <p className={styles.subtitle}>
          Review the results with your team and discuss how you can improve.
          <br />
          <br />
          Store your comments under each section to create a summary report and
          agree on action items.
        </p>
        <div className={styles.peopleInvited}>
          <p className={styles.title}>
            {completedPlayers.length +
              " out of " +
              play.players.length +
              " have completed the survey"}
          </p>
          {play.players.length > 0 && (
            <div className={styles.avatars}>
              {play.players.map((player, i) => (
                <div
                  key={`avatar-${player.id}`}
                  className={classNames(
                    styles.avatar,
                    completedPlayers.find((cp) => cp.id === player.id) &&
                      styles.completed
                  )}
                >
                  <UserAvatar member={player} size="mediumSmall" showName />
                  {completedPlayers.find((cp) => cp.id === player.id) && (
                    <div className={styles.completedIcon}>
                      <Icon icon="Check" />
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
        {completedPlayers.length > 0 && (
          <div className={styles.bigScoreWrapper}>
            <div className={styles.bigScore}>
              <div className={styles.score}>
                <PercentageCircle
                  percentage={parseInt(totalScore, 10)}
                  className={styles.scoreCircle}
                />
              </div>
              <div className={styles.right}>
                <h5>Team effectiveness score</h5>
                <p>The total average score for the team</p>
              </div>
            </div>
          </div>
        )}
        <DimensionsNavigation
          selectedDimension={selectedDimension}
          setSelectedDimension={setSelectedDimension}
          items={items}
        />
        {completedPlayers.length > 0 && (
          <div className={styles.chart}>
            <LineChart
              play={play}
              selectedDimension={selectedDimension}
              setSelectedDimension={setSelectedDimension}
            />
          </div>
        )}
        <div className={classNames(styles.dimensionsWrapper, "playDimension")}>
          {play.effectiveness.some((e) => e.score !== null) &&
            play.effectiveness
              .filter((e) => e.groupId === "team_play_effectiveness")
              .map((dim, index) => (
                <div
                  key={"dim-" + dim.dim_id}
                  className={styles.dimensionWrapper}
                >
                  <PlayDimension
                    show={index === selectedDimension && true}
                    play={play}
                    dimension={dim}
                    facets={play.effectiveness.filter(
                      (e) =>
                        e.parentGroup ===
                          play.effectiveness.find(
                            (e) => e.dim_id === dim.dim_id
                          ).parentGroup &&
                        e.groupId !== "team_play_effectiveness"
                    )}
                  />
                </div>
              ))}
        </div>
      </div>
    );
  }
);
