import React from "react";
import classNames from "classnames";
import styles from "./index.module.scss";

export default ({ dimensions, players, isTeam, isPlayer }) => {
  const curve = dimensions.map((d, i) => {
    if (i + 1 === dimensions.length) return null;
    const x = parseFloat(((100 / 5 / 2 + (100 / 5) * i) * 570) / 100);
    const y = parseFloat(100 - d.score);
    const nextX = parseFloat(x + ((100 / 5) * 570) / 100);
    const nextY = parseFloat(100 - dimensions[i + 1].score);

    if (i === 0) {
      return `M${x},${y} C${x + ((100 / 5 / 3) * 570) / 100},${y} ${
        nextX - ((100 / 5 / 3) * 570) / 100
      },${nextY} ${nextX},${nextY}`;
    }
    return `S${nextX - ((100 / 5 / 3) * 570) / 100},${nextY} ${nextX},${nextY}`;
  });
  return (
    <>
      {dimensions.map((dim, i) => {
        const cx = ((100 / 5 / 2 + (100 / 5) * i) * 570) / 100;
        const cy = 100 - parseInt(dim.score, 10);

        return (
          <g
            key={dim.name}
            className={classNames(isPlayer && styles.teamMember)}
          >
            <text
              className={classNames(
                styles.score,
                isPlayer && styles.teamMember
              )}
              x={`${cx}`}
              y={`${cy - 10}`}
              textAnchor="middle"
            >
              {parseInt(dim.score, 10)}
            </text>
            <circle
              className={classNames(
                styles.scoreCircle,
                isPlayer && styles.teamMember
              )}
              cx={`${cx}`}
              cy={`${cy}`}
              r={4}
            />
          </g>
        );
      })}
      <path
        className={classNames(
          styles.curve,
          isTeam && styles.teamAvarage,
          isPlayer && styles.teamMember
        )}
        d={`${curve.join(" ")}`}
      />
    </>
  );
};
