import React from "react";
import Button from "components/UI/Button";

import styles from "./RemovePosition.module.scss";

export default ({ deletePosition, position, updateDeletePrompt, history }) => (
  <div className={styles.modalWrapper}>
    <h2 className={styles.title}>
      <i className="fas fa-trash-alt" />
      Are you sure you wish to archive this position?
    </h2>
    <p className={styles.subtitle}>
      Access to data will be lost. For unarchiving, contact{" "}
      <a href="mailto:support@wisnio.com">support@wisnio.com</a>
    </p>
    <div className={styles.buttonGroup}>
      <Button
        bStyle={["primary", "large", "red"]}
        type="button"
        onClick={() =>
          deletePosition({
            variables: {
              id: position.id,
            },
          }).then((res) => {
            history.push("/hiring");
          })
        }
      >
        <i className="fas fa-trash-alt" />
        Yes, archive
      </Button>
      <Button
        bStyle={["secondary", "large"]}
        type="button"
        onClick={() => updateDeletePrompt(false)}
      >
        No, cancel
      </Button>
    </div>
  </div>
);
