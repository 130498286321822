import React from "react";
import classNames from "classnames";
import Score from "components/Score";
import UserAvatar from "components/UserAvatar";

import styles from "./PopupChart.module.scss";

export default ({ score, type, dim, highlightId }) => {
  const getMinLabel = () => {
    if (dim.groupId && dim.groupId.includes("value")) {
      return "Less important";
    } else if (
      (dim.code && dim.code.includes("compe")) ||
      dim.__typename === "CompetencyDimension"
    ) {
      return "Highly confident";
    } else {
      return (
        dim.label_min &&
        dim.label_min.charAt(0).toUpperCase() + dim.label_min.slice(1)
      );
    }
  };

  const getMaxLabel = () => {
    if (dim.groupId && dim.groupId.includes("value")) {
      return "Very important";
    } else if (
      (dim.code && dim.code.includes("compe")) ||
      dim.__typename === "CompetencyDimension"
    ) {
      return "Highly confident";
    } else {
      return (
        dim.label_max &&
        dim.label_max.charAt(0).toUpperCase() + dim.label_max.slice(1)
      );
    }
  };

  return (
    <div className={styles.lineWrapper}>
      <div
        className={classNames(
          styles.line,
          type === "personal" && styles.personal
        )}
      >
        {type === "personal" ? (
          <div className={styles.teamScore} style={{ left: `${score}%` }}>
            <div className={styles.number}>
              <Score score={score} scoreStyle={styles} />
            </div>
          </div>
        ) : (
          <div className={styles.teamScore} style={{ left: `${score}%` }}>
            <div className={styles.number}>
              <Score score={score} scoreStyle={styles} />
            </div>
          </div>
        )}
        <div className={styles.avatars}>
          {dim.members &&
            dim.members.map((member) => {
              return (
                <div
                  key={`avatar-${member.id}`}
                  className={classNames(
                    styles.avatar,
                    highlightId === member.id && styles.highlight
                  )}
                  style={{
                    left: `calc(${
                      member.score > 94 ? 94 : member.score
                    }% - 25px)`,
                  }}
                >
                  <UserAvatar member={member} size="small" />
                </div>
              );
            })}
        </div>
      </div>
      <div className={styles.opposites}>
        <div className={styles.left}>
          <h5 className={styles.label}>{getMinLabel()}</h5>
          <p className={styles.labelDesc}>{dim.description_min}</p>
        </div>
        <div className={styles.right}>
          <h5 className={styles.label}>{getMaxLabel()}</h5>
          <p className={styles.labelDesc}>{dim.description_max}</p>
        </div>
      </div>
    </div>
  );
};
