import React from "react";
import { Link } from "react-scroll";

import styles from "./ExploreMore.module.scss";

export default ({ scrollTo }) => (
  <div className={styles.wrapper}>
    <div className={styles.explore}>
      <Link to={scrollTo} smooth={true}>
        <div className={styles.iconWrapper}>
          <i className="far fa-long-arrow-down" />
        </div>
        <h5>Explore more</h5>
      </Link>
    </div>
  </div>
);
