import { withHandlers } from "recompose";

export default withHandlers({
  handleUpsertPerson:
    ({ upsertPerson, CANDIDATE_QUERY: { candidateInTeam } }) =>
    async (data, close = null) => {
      try {
        const response = await upsertPerson({
          variables: {
            person_id: candidateInTeam.id,
            position_id: candidateInTeam.position.id,
            ...data,
          },
        });

        if (close) {
          close();
        }
      } catch (err) {
        throw new Error(err);
      }
    },
});
