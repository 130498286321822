import React from "react";
import compose from "./compose";
import Button from "components/UI/Button";
import { ReactComponent as MicrosoftIcon } from "images/icons/Microsoft.svg";
import { ReactComponent as GoogleIcon } from "images/icons/Google.svg";
import { ReactComponent as LinkedinIcon } from "images/icons/LinkedIn.svg";
import classNames from "classnames";
import styles from "./SSObuttons.module.scss";
import QueryString from "query-string";

import {
  microsoftAuthLoginRoute,
  googleAuthLoginRoute,
  linkedInAuthLoginRoute,
} from "client/authRoutes";

export default compose(({ authStrategies, location, match, column, type }) => {
  const searchTerms = {
    ...QueryString.parse(location.search),
    ...match.params,
  };
  const searchString = Object.keys(searchTerms)
    .filter((key) => key !== "error")
    .map((key) => `${key}=${searchTerms[key]}`)
    .join("&");

  return (
    <div
      className={classNames(
        styles.ssoButtons,
        column && styles.column,
        styles[type]
      )}
    >
      {authStrategies.some((strategy) => strategy.name === "linkedIn") && (
        <>
          <Button
            bStyle={["secondary", "linkedin"]}
            type="button"
            onClick={() => {
              window.location = `${linkedInAuthLoginRoute}${
                searchString && searchString !== "" ? `?${searchString}` : ""
              }`;
            }}
          >
            <LinkedinIcon />
            {type === "longTitle" && "Continue with "}
            LinkedIn
          </Button>
        </>
      )}
      {authStrategies.some((strategy) => strategy.name === "google") && (
        <>
          <Button
            bStyle={["secondary", "linkedin"]}
            type="button"
            onClick={() => {
              window.location = `${googleAuthLoginRoute}${
                searchString && searchString !== "" ? `?${searchString}` : ""
              }`;
            }}
          >
            <GoogleIcon />
            {type === "longTitle" && "Continue with "}
            Google
          </Button>
        </>
      )}

      {authStrategies.some((strategy) => strategy.name === "microsoft") && (
        <>
          <Button
            bStyle={["secondary", "microsoft"]}
            type="button"
            onClick={() => {
              window.location = `${microsoftAuthLoginRoute}${
                searchString && searchString !== "" ? `?${searchString}` : ""
              }`;
            }}
          >
            <MicrosoftIcon />
            {type === "longTitle" && "Continue with "}
            Microsoft
          </Button>
        </>
      )}
    </div>
  );
});
