import React from "react";
import classNames from "classnames";

import styles from "./Select.module.scss";

export default ({
  children,
  onChange,
  value,
  icon,
  disabled,
  large,
  fullWidth,
  name,
  small,
  noBorder,
  cStyle,
  defaultValue,
  placeholder,
}) => (
  <div
    aria-label="selectWrapper"
    className={classNames(
      styles.selectWrapper,
      large && styles.large,
      small && styles.small,
      cStyle && styles[cStyle],
      icon && styles.withIcon,
      noBorder && styles.noBorder,
      fullWidth && styles.fullWidth
    )}
  >
    {icon && (
      <div className={styles.icon}>
        <i className={icon} />
      </div>
    )}
    <select
      onChange={onChange}
      value={value}
      className={styles.select}
      disabled={disabled && disabled}
      name={name}
      defaultValue={defaultValue}
      placeholder={placeholder}
    >
      {children}
    </select>
    <div className={styles.selectArrow}>
      <i className="fas fa-chevron-down" />
    </div>
  </div>
);
