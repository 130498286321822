import { Value } from 'slate'
import serializer from 'components/TextEditor/editorSerializer'

export default (initialState) => {
  if (initialState) return serializer.deserialize(initialState)
  return Value.fromJSON({
    document: {
      nodes: [
        {
          object: 'block',
          type: 'paragraph',
          nodes: [
            {
              object: 'text',
              text: ''
            }
          ]
        }
      ]
    }
  })
}
