import React from "react";
import { compose, withState } from "recompose";
import { steps } from "./steps";
import { loader } from "graphql.macro";
import FullPage from "components/Loaders/FullPage";
import handleLoading from "utils/loader";
import { graphql } from "react-apollo";
import handleCreateAssessmentSession from "./handleCreateAssessmentSession";
import { withRouter } from "react-router-dom";

const ASSESSMENT_SCORECARDS = loader("./assessmentScorecardsQuery2.graphql");
const CREATE_SESSION = loader("./createAssessmentSession7.graphql");
// const ASSESSMENT_GROUP = loader("../assessmentGroup3.graphql");

export default compose(
  withRouter,
  withState("currentStepIndex", "setCurrentStepIndex", 0),

  withState("sessionData", "setSessionData", (props) => ({
    assessment_group_id: parseInt(props.match.params.group_id, 10),
    org_id: parseInt(props.match.params.org_id, 10),
  })),
  graphql(ASSESSMENT_SCORECARDS),
  graphql(CREATE_SESSION, {
    name: "createAssessmentSession",
  }),
  handleCreateAssessmentSession,
  handleLoading(() => <FullPage />)
);
