import React from 'react'
import { Link } from 'react-router-dom'
import Button from 'components/UI/Button'
import { ReactComponent as TeamMembers } from 'images/teamMemberCards.svg'

import styles from './Candidate.module.scss'

export default ({ teamId }) => (
  <div className={styles.inviteTeam}>
    <TeamMembers />
    <h4>
      To see how the candidate fits into the team, invite your team to Wisnio
    </h4>
    <p>
      Once they have completed their profiles, the full candidate + team profile
      will be unlocked.
    </p>
    <Link to={`/team/${teamId}`}>
      <Button bStyle={['primary', 'small']} type='button'>
        Invite team members
      </Button>
    </Link>
  </div>
)
