import React from "react"
import styles from "./index.module.scss"

export default () => (
  <div className={styles.overlay_loader}>
    <div className={styles.loader}>
      <div className={styles.logo}></div>
    </div>
  </div>
)
