import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { Link } from "react-router-dom";
import AIChat from "components/AIChat";
import SidebarWrapper from "components/UI/SidebarWrapper";
import SidebarHeader from "components/UI/SidebarHeader";
import Button from "components/UI/Button";
import Icon from "components/UI/Icon";
import sortCandidateCompetencies from "utils/sortCandidateCompetencies";
import replaceAll from "utils/replaceAll";
import { Element } from "react-scroll";
import { InviteToWisnio } from "components/UI/CTA";
import { useSidebar } from "utils/sidebarProvider";
import { ReactComponent as WisnioLogo } from "images/icons/Wisnio.svg";
import { printoutRoute } from "client/authRoutes";
import Competencies from "./Competencies";
import OtherCandidates from "./OtherCandidates";
import compose from "./compose";
import User from "./User";
import Personality from "./Personality";
import Values from "./Values";
import AddToTeamModal from "./AddToTeamModal";
import InviteYourTeam from "./InviteYourTeam";
import AdminSection from "./AdminSection";
import ShareProfileButton from "./ShareProfileButton";

import styles from "./Candidate.module.scss";

export default compose(
  ({
    CANDIDATE_QUERY: { candidateInTeam },
    openModal,
    setOpenModal,
    handleAddCandidateToTeam,
    handleUpsertPerson,
    handleUpsertCandidateLinkedIn,
    handleRemoveCandidateLinkedin,
    handleUpsertCandidatePhone,
    actionHandlers,
    handleSendInvite,
  }) => {
    const { contentContext } = useSidebar();
    const [content, setContent] = contentContext;

    let step =
      candidateInTeam.position.kanbanColumns.findIndex((col) =>
        col.candidates.includes(candidateInTeam.id)
      ) - 1;
    if (!++step || step < 0) step = 0;

    const isContact = candidateInTeam.__typename == "LonglistedPersonType";

    const sidebarContent = [
      !isContact && {
        sectionTitle: "Navigation",
        items: [
          {
            title: "Summary",
            scrollTo: true,
          },
          {
            title: "Competencies",
            scrollTo: true,
          },
          {
            title: "Personality",
            scrollTo: true,
          },
          {
            title: "Values",
            scrollTo: true,
          },
        ],
      },
    ];

    useEffect(() => {
      setContent(sidebarContent);
    }, []);

    return (
      <SidebarWrapper>
        <SidebarHeader
          title={candidateInTeam.name}
          subtitle={candidateInTeam.position.parentTeam.name}
        >
          <Link
            to={`/${candidateInTeam.position.parent_org_id}/team/${
              candidateInTeam.position.parentTeam.id
            }/position/${candidateInTeam.position.id}/${
              isContact ? "person" : "candidate"
            }/${candidateInTeam.id}/interviewguide${
              candidateInTeam.position.assessmentScorecardId
                ? "/" + candidateInTeam.position.assessmentScorecardId
                : ""
            }`}
          >
            <Button bStyle={["verySmall", "primary"]}>
              <span className={styles.clipboardWrapper}>
                <div className={styles.clipboard}>
                  <Icon icon="Clipboard" marginRight />
                </div>
              </span>
              Interview & Evaluate
            </Button>
          </Link>
          <ShareProfileButton />
          <Button
            bStyle={["verySmall"]}
            onClick={() =>
              window.open(
                `${printoutRoute}/printout/${
                  isContact ? "person" : "candidate"
                }/${candidateInTeam.position.parent_org_id}/${
                  candidateInTeam.position.parentTeam.id
                }/${candidateInTeam.position.id}/${candidateInTeam.id}/${
                  candidateInTeam.name
                    ? candidateInTeam.name.replace(" ", "_")
                    : ""
                }_candidate_profile.pdf`,
                "_blank"
              )
            }
          >
            <Icon icon="Print" marginRight />
            Print
          </Button>
        </SidebarHeader>
        <div className={styles.wrapper}>
          <AIChat
            sequence={"candidate_profile_chat_start"}
            contexts={["user", "team"]}
            variables={[
              { key: "team_id", value: candidateInTeam.position.parentTeam.id },
              { key: "user_id", value: candidateInTeam.id },
            ]}
            description="An AI assistant trained to help you make sense of the Candidate's Profile and give actionable advice."
            examplePrompts={[
              `What kind of culture would ${candidateInTeam.first_name} find motivating?`,
              `Propose three things I should discuss with ${candidateInTeam.first_name} in an interview?`,
              `How to make a job offer compelling for ${candidateInTeam.first_name}?`,
              `What’s the one thing I should avoid to work effectively with ${candidateInTeam.first_name}?`,
              `How does ${candidateInTeam.first_name} learn most effectively?`,
            ]}
            heapEvent="create-candidate-chatbot-prompt"
            isReady={candidateInTeam.readyToRender}
            disabledText="Candidate has to complete their surveys to use this feature."
          />
          <AdminSection
            candidate={candidateInTeam}
            actionHandlers={actionHandlers}
            handleUpsertPerson={handleUpsertPerson}
            handleUpsertCandidateLinkedIn={handleUpsertCandidateLinkedIn}
            handleUpsertCandidatePhone={handleUpsertCandidatePhone}
            handleRemoveCandidateLinkedin={handleRemoveCandidateLinkedin}
            step={step}
          />
          {candidateInTeam.type === "person" && (
            <InviteToWisnio
              action={handleSendInvite}
              disabled={!candidateInTeam.email}
              inviteSent={candidateInTeam.invite}
            />
          )}
          {candidateInTeam.type !== "person" && (
            <>
              <div className={styles.sectionHeader}>
                <div className={styles.logo}>
                  <WisnioLogo />
                </div>
                <div className={styles.right}>
                  <h5 className={styles.title}>Generated profile</h5>
                  <p className={styles.subtitle}>
                    Data and analysis provided by Wisnio
                  </p>
                </div>
              </div>

              <User
                position={candidateInTeam}
                candidate={candidateInTeam}
                setOpenModal={setOpenModal}
              />
              {!candidateInTeam.position.parentTeam.members.some(
                (m) => m.readyToRender
              ) && (
                <InviteYourTeam
                  teamId={candidateInTeam.position.parentTeam.id}
                />
              )}
              <Element name="competencies">
                <Competencies
                  candidate={candidateInTeam}
                  team={candidateInTeam.position.parentTeam}
                  competencies={sortCandidateCompetencies(
                    candidateInTeam.position.parentTeam,
                    candidateInTeam
                  )}
                />
              </Element>
              <Element name="personality">
                <Personality
                  candidate={candidateInTeam}
                  team={candidateInTeam.position.parentTeam}
                />
              </Element>
              <Element name="values">
                <Values
                  candidate={candidateInTeam}
                  team={candidateInTeam.position.parentTeam}
                />
              </Element>
              <OtherCandidates
                team={candidateInTeam.position.parentTeam}
                candidateInTeam={candidateInTeam}
                position={candidateInTeam.position}
              />
            </>
          )}
        </div>

        <Modal
          isOpen={openModal === "addToTeam"}
          onRequestClose={() => setOpenModal(false)}
          shouldCloseOnOverlayClick
          className={styles.modal}
        >
          <Button
            bStyle="close"
            type="button"
            onClick={() => setOpenModal(false)}
          >
            <i className="fal fa-times" />
          </Button>
          <AddToTeamModal
            candidate={candidateInTeam}
            team={candidateInTeam.position.parentTeam}
            setModal={setOpenModal}
            handleAddCandidateToTeam={handleAddCandidateToTeam}
          />
        </Modal>
      </SidebarWrapper>
    );
  }
);
