import React from "react";
import { Link } from "react-router-dom";
import PositionCard from "components/Card";

import styles from "./PositionCard.module.scss";

export default ({ position, narrow }) => {
  const profilesComplete = position.candidates.filter(
    (candidate) => candidate.readyToRender
  ).length;

  return (
    <Link
      to={`/team/${position.parentTeam.id}/position/${position.id}`}
      className={styles.link}
    >
      <PositionCard cardStyle={["position", narrow && "narrow"]}>
        <div aria-label="top">
          <h4>{position.name}</h4>
          <p className={styles.team}>
            {position.parentTeam ? position.parentTeam.name : "No team"}
          </p>

          {position.candidates.length + position.pendingInvites.length > 0 ? (
            <div aria-label="upperLine">
              <p>
                {position.candidates.filter(
                  (c) => c.contract.state === "active"
                ).length > 0
                  ? `${
                      position.candidates.filter(
                        (c) => c.contract.state === "active"
                      ).length
                    } candidate${position.candidates.length === 1 ? "" : "s"}`
                  : "No candidates"}
              </p>
              {position.candidates.length > 0 && (
                <p
                  aria-label={
                    position.candidates.length - profilesComplete > 0 &&
                    "yellow"
                  }
                >
                  {position.candidates.length - profilesComplete > 0
                    ? `${
                        position.candidates.length - profilesComplete
                      } profile${
                        position.candidates.length - profilesComplete === 1
                          ? ""
                          : "s"
                      } incomplete`
                    : "All profiles complete"}
                </p>
              )}
            </div>
          ) : (
            <div aria-label="upperLine">
              <p>Waiting for candidates</p>
            </div>
          )}
        </div>
        <div aria-label="bottom">
          <p>
            {position.pendingInvites.length +
              position.candidates.filter((c) => c.contract.state !== "active")
                .length >
            0
              ? `${
                  position.pendingInvites.length +
                  position.candidates.filter(
                    (c) => c.contract.state !== "active"
                  ).length
                } pending invite${
                  position.pendingInvites.length +
                    position.candidates.filter(
                      (c) => c.contract.state !== "active"
                    ).length ===
                  1
                    ? ""
                    : "s"
                }`
              : "No pending invites"}
          </p>
        </div>
      </PositionCard>
    </Link>
  );
};
