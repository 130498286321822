import { FORM_ERROR } from 'final-form'

// const regex = new RegExp(
//   '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})'
// )
// const length = new RegExp('(?=.{8,})')
// const uppercase = new RegExp('(?=.*[A-Z])')
// const lowercase = new RegExp('(?=.*[a-z])')
// const digit = new RegExp('(?=.*[0-9])')

export const rules = [
  {
    regex: new RegExp('(?=.{8,})'),
    message: 'Password must be at least 8 characters.'
  },
  {
    regex: new RegExp('(?=.*[A-Z])'),
    message: 'Password must have at least one uppercase letter.'
  },
  {
    regex: new RegExp('(?=.*[a-z])'),
    message: 'Password must have at least one lowercase letter.'
  },
  {
    regex: new RegExp('(?=.*[0-9])'),
    message: 'Password must have at least one digit.'
  }
]

export default ({ password, repeatpassword }) => {
  if (password) {
    const errors = []
    rules.forEach(({ regex, message }) => {
      if (!regex.test(password)) {
        errors.push({ [FORM_ERROR]: message })
      }
    })
    if (repeatpassword && password !== repeatpassword) {
      errors.push({ [FORM_ERROR]: 'Passwords must match!' })
    }

    if (errors.length) return errors
  }
}
