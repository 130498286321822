import { withHandlers } from "recompose";
import { FORM_ERROR } from "final-form";
import axios from "utils/axios";
import { emailLoginRoute } from "client/authRoutes";
import QueryString from "query-string";

export default withHandlers({
  onSubmit:
    ({ history, match, location }) =>
    async (values) => {
      delete values.repeatpassword;
      const queryString = QueryString.parse(location.search);

      try {
        const {
          data: { success, redirect, setNewPassword, closeWindow },
        } = await axios.post(
          emailLoginRoute,
          {
            ...values,
            inviteSecret:
              match.params.inviteSecret && match.params.inviteSecret,
            positionTemplate: queryString && queryString.positionTemplate,
            caseStudy:
              match.params.caseStudySecret && match.params.caseStudySecret,
            isDefferredFromChromePlugin:
              queryString && queryString.isDefferredFromChromePlugin,
          },
          { withCredentials: true }
        );

        if (!success) {
          throw new Error("Username or password incorrect!");
        }
        if (success && setNewPassword) {
          return history.push("/newPassword", {
            redirect,
          });
        }
        if (closeWindow) {
          window.close();
        }

        // console.log({ success, redirect, setNewPassword, closeWindow });
        return history.replace(redirect);
      } catch ({ response }) {
        if (response.status === 401) {
          return { [FORM_ERROR]: "Username or password incorrect!" };
        }
      }
    },
});
