import { branch, compose, withProps, renderComponent } from "recompose";
import { graphql } from "react-apollo";
import { loader } from "graphql.macro";

const SEARCH_TALENT_POOL = loader("./searchTalentPool3.graphql");

export default compose(
  graphql(SEARCH_TALENT_POOL, {
    options: ({ where, setError }) => {
      return {
        variables: where,
        fetchPolicy: "no-cache",
        notifyOnNetworkStatusChange: true,
      };
    },
  })
);
