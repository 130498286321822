import { withHandlers } from "recompose";
import serializer from "components/TextEditor/editorSerializer";

export default withHandlers({
  handleSaveDescription:
    ({ closeModal, description, savePositionDescription, match }) =>
    () => {
      const serialized = serializer.serialize(description);
      // if (serialized === '<p></p>') return setEditSummary(false)

      savePositionDescription({
        variables: {
          id: parseInt(match.params.positionId, 10),
          description: serialized,
        },
      }).then(() => {});
    },
});
