import React from "react";
import classNames from "classnames";
import styles from "./index.module.scss";

// const getTitleXY = (start) => {
//   if (start >= 0 && start <= 45) return [100, 100]
//   if (start > 45 && start <= 115) return [0, 100]
//   if (start > 115 && start <= 250) return [0, 0]
//   if (start > 250 && start <= 360) return [100, 0]
// }

function scaleBetween(unscaledNum, minAllowed, maxAllowed, min, max) {
  return (
    ((maxAllowed - minAllowed) * (unscaledNum - min)) /
      (max + 0.01 - min - 0.01) +
    minAllowed
  );
}

export default ({
  valuesChart,
  wedgeAngle,
  midX,
  midY,
  sectorRadius,
  highlight,
  resource,
}) => {
  const scores =
    resource === "member"
      ? highlight.valuesChart.valueGroups.map((g) => g.score)
      : valuesChart.valueGroups.map((g) => g.score);

  const maxRange = Math.max(...scores);
  const minRange = Math.min(...scores);

  const scaledScoreSize = scores.map((score) =>
    scaleBetween(score, 4, 9, minRange, maxRange)
  );

  return (
    <>
      {valuesChart.valueGroups.map((group, i) => {
        const renderedGroups = valuesChart.valueGroups.filter(
          (g, ri) => ri < i
        );

        let renderedGroupsLength;
        if (renderedGroups.length === 0) renderedGroupsLength = 0;
        if (renderedGroups.length === 1)
          renderedGroupsLength = renderedGroups[0].childComponents;
        if (renderedGroups.length > 1) {
          renderedGroupsLength = renderedGroups
            .map((g) => g.childComponents)
            .reduce((a, b) => a + b);
        }

        // .reduce((a, b) => a.childComponents + b.childComponents)
        const groupAngle = wedgeAngle * group.childComponents;
        const groupStartAngle = renderedGroupsLength * wedgeAngle;
        const groupEndAngle = groupStartAngle + groupAngle;
        const radius = sectorRadius;
        const scoreRadius = radius + 8;
        const scoreRadiusFlipped = radius + 9.5;
        const titleCurveR = sectorRadius + 11;
        const titleCurveRflipped = sectorRadius + 13;
        const groupLineEndR = 2 * radius - (40 * radius) / 100;
        const flipText = groupEndAngle >= 0 && groupEndAngle <= 180;

        const lx1 = midX + radius * Math.cos((Math.PI * groupStartAngle) / 180);
        const ly1 = midY + radius * Math.sin((Math.PI * groupStartAngle) / 180);
        const lx2 =
          midX + groupLineEndR * Math.cos((Math.PI * groupStartAngle) / 180);
        const ly2 =
          midY + groupLineEndR * Math.sin((Math.PI * groupStartAngle) / 180);
        // const l2x2 = midX + groupLineEndR * Math.cos((Math.PI * groupEndAngle) / 180)
        // const l2y2 = midY + groupLineEndR * Math.sin((Math.PI * groupEndAngle) / 180)
        // const l2x1 = midX + radius * Math.cos((Math.PI * groupEndAngle) / 180)
        // const l2y1 = midY + radius * Math.sin((Math.PI * groupEndAngle) / 180)

        const tx1 =
          midX + titleCurveR * Math.cos((Math.PI * groupStartAngle) / 180);
        const ty1 =
          midY + titleCurveR * Math.sin((Math.PI * groupStartAngle) / 180);
        const tx2 =
          midX + titleCurveR * Math.cos((Math.PI * groupEndAngle) / 180);
        const ty2 =
          midY + titleCurveR * Math.sin((Math.PI * groupEndAngle) / 180);

        const sx1 =
          midX + scoreRadius * Math.cos((Math.PI * groupStartAngle) / 180);
        const sy1 =
          midY + scoreRadius * Math.sin((Math.PI * groupStartAngle) / 180);
        const sx2 =
          midX + scoreRadius * Math.cos((Math.PI * groupEndAngle) / 180);
        const sy2 =
          midY + scoreRadius * Math.sin((Math.PI * groupEndAngle) / 180);

        const sx1f =
          midX +
          scoreRadiusFlipped * Math.cos((Math.PI * groupStartAngle) / 180);
        const sy1f =
          midY +
          scoreRadiusFlipped * Math.sin((Math.PI * groupStartAngle) / 180);
        const sx2f =
          midX + scoreRadiusFlipped * Math.cos((Math.PI * groupEndAngle) / 180);
        const sy2f =
          midY + scoreRadiusFlipped * Math.sin((Math.PI * groupEndAngle) / 180);

        // const title = getTitleXY(groupStartAngle)
        //
        // const tcx = midX
        //   + scoreRadius
        //     * Math.cos((Math.PI * (groupEndAngle - groupAngle / 2)) / 180)
        // const tcy = midY
        //   + scoreRadius
        //     * Math.sin((Math.PI * (groupEndAngle - groupAngle / 2)) / 180)

        const tx1f =
          midX +
          titleCurveRflipped *
            Math.cos((Math.PI * (groupStartAngle + 4)) / 180);
        const ty1f =
          midY +
          titleCurveRflipped *
            Math.sin((Math.PI * (groupStartAngle + 4)) / 180);
        const tx2f =
          midX +
          titleCurveRflipped * Math.cos((Math.PI * (groupEndAngle - 4)) / 180);
        const ty2f =
          midY +
          titleCurveRflipped * Math.sin((Math.PI * (groupEndAngle - 4)) / 180);

        return (
          <g className={styles.groupWrap} key={group.groupId}>
            <path
              className={styles.groupLine}
              d={`M${lx1} ${ly1} L${lx2} ${ly2}`}
            />
            <path
              className={styles.titleline}
              id={`${group.groupId}-title-path`}
              d={
                flipText
                  ? `M${tx2f} ${ty2f}  A${titleCurveRflipped} ${titleCurveRflipped} 0 0 0 ${tx1f} ${ty1f}`
                  : `M${tx1} ${ty1}  A${titleCurveR} ${titleCurveR} 0 0 1 ${tx2} ${ty2}`
              }
            />
            <path
              className={styles.titleline}
              id={`${group.groupId}-score-path`}
              d={
                flipText
                  ? `M${sx2f} ${sy2f}  A${scoreRadiusFlipped} ${scoreRadiusFlipped} 0 0 0 ${sx1f} ${sy1f}`
                  : `M${sx1} ${sy1}  A${scoreRadius} ${scoreRadius} 0 0 1 ${sx2} ${sy2}`
              }
            />
            <text
              className={styles.titleWrap}
              textAnchor="middle"
              alignmentBaseline="central"
            >
              <textPath
                className={styles.groupTitle}
                xlinkHref={`#${group.groupId}-title-path`}
                startOffset="50%"
                textAnchor="middle"
              >
                <tspan className={styles.titleName} cy={1}>
                  {group.name}
                </tspan>
              </textPath>
            </text>
            <text
              className={styles.titleWrap}
              textAnchor="middle"
              alignmentBaseline="central"
            >
              <textPath
                className={styles.score}
                xlinkHref={`#${group.groupId}-score-path`}
                startOffset="50%"
                textAnchor="middle"
              >
                <tspan className={styles.number} cy={1}>
                  {resource === "member"
                    ? highlight.valuesChart.valueGroups.find(
                        (g) => g.name === group.name
                      ).score
                    : group.score}
                </tspan>
              </textPath>
            </text>
          </g>
        );
      })}
    </>
  );
};
