import React, { useState } from "react";
import styles from "./SignupSidebar.module.scss";
import classNames from "classnames";
import { Link } from "react-router-dom";
import Button from "components/UI/Button";
import Icon from "components/UI/Icon";
import { ReactComponent as WisnioLogo } from "images/icons/Wisnio.svg";

export default ({ children }) => {
  const whitelabel = document.whitelabel === "custom" ? true : false;
  const [open, setOpen] = useState(false);

  return (
    <div
      id="sidebarWrapper"
      className={classNames(
        styles.wrap,
        !open && styles.sidebarCollapsed,
        !open && "collapsed"
      )}
    >
      {window.innerWidth < 960 &&
        !document.body.classList.contains("forceDesktop") && (
          <div className={classNames(styles.mobileHeader, "mobileHeader")}>
            {whitelabel ? (
              <div className={classNames(styles.logo, styles.mobile)}>
                <div className={styles.clientLogo} />
              </div>
            ) : (
              <div className={classNames(styles.logo, styles.mobile)}>
                <Link to="/dashboard">
                  <WisnioLogo />
                </Link>
              </div>
            )}
            <div className={classNames(styles.burgerMenu, open && styles.open)}>
              <Button
                bStyle={["none"]}
                onClick={() => {
                  if (open) {
                    setOpen(false);
                  } else {
                    setOpen(true);
                  }
                }}
              >
                <div className={styles.line}></div>
                <div className={styles.line}></div>
              </Button>
            </div>
          </div>
        )}
      <div
        id="sidebar"
        className={classNames(
          styles.sidebar,
          "sidebar",
          open && styles.open,
          window.innerWidth < 960 &&
            !document.body.classList.contains("forceDesktop") &&
            styles.mobile
        )}
      >
        {open && <div style={{ top: "60px" }} className={styles.overLay}></div>}
        {whitelabel && (
          <div className={styles.whitelabelLogo}>
            <div className={styles.clientLogo} />
          </div>
        )}
        <div className={styles.left}>
          <div
            className={classNames(styles.top, whitelabel && styles.whitelabel)}
          >
            <div className={styles.logo}>
              <Link to="/">
                <WisnioLogo />
              </Link>
            </div>
            <div className={styles.section}>
              <div className={styles.icon}>
                <Icon icon="House" />
              </div>
            </div>
            <div className={styles.section}>
              <div className={styles.icon}>
                <Icon icon="User" />
              </div>
              <div className={styles.icon}>
                <Icon icon="Users" />
              </div>
              <div className={styles.icon}>
                <Icon icon="Kanban" />
              </div>
              <div className={styles.icon}>
                <Icon icon="Clipboard" />
              </div>
            </div>
          </div>
          <div className={styles.bottom}>
            <div className={styles.section}>
              <div className={styles.icon}>
                <Icon icon="User" />
              </div>
              <div className={styles.icon}>
                <Icon icon="Bell" />
              </div>
            </div>
            <div className={styles.section}>
              <div className={styles.avatarWrapper}></div>
            </div>
          </div>
        </div>
        <div className={styles.pane}>
          <h5>Sign up</h5>
          <p>
            Register your account to get access to the full functionality of
            Wisnio
          </p>
          <Link to="/signup">
            <Button bStyle={["primary", "verySmall"]}>Sign up</Button>
          </Link>
        </div>
      </div>
      {children}
    </div>
  );
};
