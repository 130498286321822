import React, { useEffect } from "react";
import { Element } from "react-scroll";
import AIChat from "components/AIChat";
import SidebarWrapper from "components/UI/SidebarWrapper";
import SidebarHeader from "components/UI/SidebarHeader";
import Button from "components/UI/Button";
import Icon from "components/UI/Icon";
import ExploreMore from "components/UI/ExploreMore";
import { printoutRoute } from "client/authRoutes";
import compose from "./compose";
import Summary from "./Summary";
import Personality from "./Personality";
import Values from "./Values";
import Competencies from "./Competencies";
import { useSidebar } from "utils/sidebarProvider";

import styles from "./Personal.module.scss";

export default compose(({ viewer }) => {
  const { contentContext } = useSidebar();
  const [content, setContent] = contentContext;

  const sidebarContent = [
    {
      sectionTitle: "Navigation",
      items: [
        {
          title: "Competencies",
          scrollTo: true,
        },
        {
          title: "Personality",
          scrollTo: true,
        },
        {
          title: "Values",
          scrollTo: true,
        },
      ],
    },
  ];

  useEffect(() => {
    setContent(sidebarContent);
  }, []);

  const noSidebar = !(
    viewer.accounts.length > 0 &&
    viewer.accounts.some((a) => a.id === viewer.default_account_id)
  );

  return (
    <SidebarWrapper noSidebar={noSidebar}>
      <SidebarHeader
        noSidebar={noSidebar}
        title={viewer.name}
        subtitle="Personal profile"
      >
        <Button
          bStyle={["verySmall"]}
          onClick={() =>
            window.open(
              `${printoutRoute}/printout/personal/${
                viewer.id
              }/${viewer.name.replace(" ", "_")}_personal_profile.pdf`,
              "_blank"
            )
          }
        >
          <Icon icon="Print" marginRight />
          Print
        </Button>
      </SidebarHeader>
      <div className={styles.wrapper}>
        <AIChat
          sequence={"personal_profile_chat_start"}
          contexts={["user"]}
          variables={[{ key: "user_id", value: viewer.id }]}
          isReady={viewer.ready_to_render}
          disabledText="Please complete your surveys to use this feature."
          description="An AI assistant trained to help you make sense of your Personal Profile and give actionable advice."
          examplePrompts={[
            "What 3 career paths would suit me best?",
            "Recommend three books for my personal development",
            "How should I plan my weekly calendar based on my personality?",
            "Explain my assessment results to me",
            "Based on my personality, what animal would I be?",
          ]}
          heapEvent="create-personal-chatbot-prompt"
        />
        <Summary viewer={viewer} />
        <ExploreMore scrollTo="competencies" />
        <Element name="competencies">
          <Competencies viewer={viewer} />
        </Element>
        <Element name="personality">
          <Personality viewer={viewer} />
        </Element>
        <Element name="values">
          <Values />
        </Element>
      </div>
    </SidebarWrapper>
  );
});
