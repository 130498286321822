import React from "react";
import classNames from "classnames";
import Button from "components/UI/Button";
import Icon from "components/UI/Icon";
import styles from "../Summary.module.scss";
import deserialize from "utils/deserialize";

export default ({
  content,
  isVisible,
  showEditor,
  handleSave,
  editBtnTitle,
  setGptModal,
  handleTextEditorChange,
}) => (
  <div className={classNames(styles.header, !isVisible && styles.marginBottom)}>
    {isVisible ? (
      <div className={styles.buttons}>
        <Button
          bStyle={["secondary", "verySmall"]}
          onClick={() => {
            showEditor(false);
            handleTextEditorChange(deserialize(content));
          }}
        >
          Discard
        </Button>
        <Button bStyle={["primary", "verySmall"]} onClick={handleSave}>
          Save
        </Button>
      </div>
    ) : (
      <div className={styles.summaryHead}>
        <div className={styles.titleGroup}>
          <h4>Profile summary</h4>
          <p>Additional notes and comments about the candidate</p>
        </div>
        <div className={styles.buttons}>
          <div className={styles.button}>
            <Button
              bStyle={["verySmall", "purple"]}
              onClick={() => setGptModal(true)}
            >
              <Icon icon="Magicwand" marginRight />
              GPT Summary
            </Button>
          </div>
          {content && (
            <div className={styles.buttonNText}>
              <Button
                bStyle={["bluetext", "verySmall"]}
                onClick={() => showEditor(true)}
              >
                <Icon icon="Edit" marginRight />
                {editBtnTitle}
              </Button>
            </div>
          )}
        </div>
      </div>
    )}
  </div>
);
