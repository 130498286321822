import React from 'react'
import classNames from 'classnames'
import Button from 'components/UI/Button'

import styles from './Pagination.module.scss'

export default ({
  pageNr, setPageNr, limit, indexesLength
}) => {
  const pages = [...Array(Math.ceil(indexesLength / limit))]

  return (
    <div className={styles.wrapper}>
      <div className={styles.pagination}>
        {pageNr !== 0 && (
          <div className={styles.buttonWrapper}>
            <Button
              bStyle={['none']}
              type='button'
              disabled={pageNr === 0}
              onClick={() => setPageNr(pageNr - 1)}
            >
              Prev
            </Button>
          </div>
        )}

        {pages.map((_, i) => (
          <div
            key={`page-${i + 1}`}
            className={classNames(
              styles.buttonWrapper,
              i === pageNr && styles.active
            )}
          >
            <Button
              bStyle={['none']}
              type='button'
              onClick={() => setPageNr(i)}
            >
              {i + 1}
            </Button>
          </div>
        ))}
        {pageNr !== pages.length - 1 && (
          <div className={styles.buttonWrapper}>
            <Button
              bStyle={['none']}
              type='button'
              disabled={pageNr === pages.length - 1}
              onClick={() => setPageNr(pageNr + 1)}
            >
              Next
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

// <div className={classNames(styles.buttonWrapper, styles.active)}>
//   <Button bStyle={['none']} type='button'>
//     1
//   </Button>
// </div>
// <div className={styles.buttonWrapper}>
//   <Button bStyle={['none']} type='button'>
//     2
//   </Button>
// </div>
// <div className={styles.buttonWrapper}>
//   <Button bStyle={['none']} type='button'>
//     3
//   </Button>
// </div>
// <div className={styles.buttonWrapper}>
//   <Button bStyle={['none']} type='button'>
//     Next
//   </Button>
// </div>
