export default ({ teamPlay }) => {
  const list = [[], []]

  teamPlay.toolkit.steps[0].substeps.forEach((step, key) => {
    list[0].push({
      id: key,
      title: step.title,
      component: step.component,
    })
  })

  teamPlay.toolkit.steps[1].substeps.forEach((step, key) => {
    list[1].push({
      id: key,
      title: step.title,
      component: step.component,
    })
  })

  return list
}
