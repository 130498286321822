import React from "react";
import { compose, withHandlers, withState } from "recompose";
import scrollToTop from "utils/scrollToTop";
import { withRouter } from "react-router-dom";
import { graphql } from "react-apollo";
import { loader } from "graphql.macro";
import handleLoading from "utils/loader";
import FullPage from "components/Loaders/FullPage";
import createList from "./createList";
import deserialize from "utils/deserialize";
import serializer from "components/TextEditor/editorSerializer";

const CANDIDATE_QUERY = loader("client/Queries/CandidateQuery198.graphql");
const SAVE_EVALUATION = loader("./saveEvaluation4.graphql");
const REMOVE_EVALUATION = loader("./removeEvaluation4.graphql");
const CREATE_COMMENT = loader("./createComment3.graphql");
const REMOVE_COMMENT = loader("./removeComment1.graphql");
const CANDIDATE_ASSESSMENT_SUMMARY = loader(
  "./candidateAssessmentSummary.graphql"
);
const UPSERT_CANDIDATE_ASSESSMENT_SUMMARY = loader(
  "./upsertCandidateAssessmentSummary.graphql"
);

export default compose(
  withRouter,
  graphql(CANDIDATE_ASSESSMENT_SUMMARY, {
    options: ({
      assessmentScorecard,
      match: {
        params: { candidateId, positionId, candidateType },
      },
    }) => ({
      variables: {
        position_id: parseInt(positionId, 10),
        user_id:
          candidateType === "candidate" ? parseInt(candidateId, 10) : null,
        person_id:
          candidateType === "person" ? parseInt(candidateId, 10) : null,
        scorecard_id: assessmentScorecard.id,
      },
    }),
  }),
  handleLoading(() => <FullPage />),
  withState("list", "setList", ({ candidate, viewer, assessmentRows }) =>
    createList({ candidate, viewer, assessmentRows })
  ),
  withState("executiveSummary", "setExecutiveSummary", (props) =>
    props.data.candidateAssessmentSummary
      ? deserialize(props.data.candidateAssessmentSummary.summary)
      : deserialize("")
  ),
  withState("saved", "setSaved", false),
  graphql(UPSERT_CANDIDATE_ASSESSMENT_SUMMARY, {
    name: "upsertCandidateAssessmentSummary",
  }),
  graphql(SAVE_EVALUATION, {
    name: "saveEvaluation",
    options: ({
      viewer,
      setList,
      match: {
        params: { candidateId, teamId, positionId, orgId, candidateType },
      },
    }) => ({
      update: (proxy, { data: { evaluateCandidate } }) => {
        const data = proxy.readQuery({
          query: CANDIDATE_QUERY,
          variables: {
            user_id:
              candidateType === "candidate" ? parseInt(candidateId, 10) : null,
            person_id:
              candidateType === "person" ? parseInt(candidateId, 10) : null,
            team_id: parseInt(teamId, 10),
            position_id: parseInt(positionId, 10),
            org_id: parseInt(orgId, 10),
          },
        });

        data.candidateInTeam.evaluation = [
          ...data.candidateInTeam.evaluation.filter(
            (e) =>
              !(
                e.item_id === evaluateCandidate.item_id &&
                e.evaluatedBy.id === viewer.id
              )
          ),
          evaluateCandidate,
        ];

        proxy.writeQuery({
          query: CANDIDATE_QUERY,
          variables: {
            user_id:
              candidateType === "candidate" ? parseInt(candidateId, 10) : null,
            person_id:
              candidateType === "person" ? parseInt(candidateId, 10) : null,
            team_id: parseInt(teamId, 10),
            position_id: parseInt(positionId, 10),
            org_id: parseInt(orgId, 10),
          },
          data,
        });

        setList(
          createList({
            candidateInTeam: data.candidateInTeam,
            viewer,
          })
        );
      },
    }),
  }),
  graphql(REMOVE_EVALUATION, {
    name: "removeEvaluation",
    options: ({
      match: {
        params: { candidateId, teamId, positionId, orgId, candidateType },
      },
      setList,
      viewer,
    }) => ({
      update: (proxy, { data: { removeCandidateEvaluation } }) => {
        const data = proxy.readQuery({
          query: CANDIDATE_QUERY,
          variables: {
            user_id:
              candidateType === "candidate" ? parseInt(candidateId, 10) : null,
            person_id:
              candidateType === "person" ? parseInt(candidateId, 10) : null,
            team_id: parseInt(teamId, 10),
            position_id: parseInt(positionId, 10),
            org_id: parseInt(orgId, 10),
          },
        });

        data.candidateInTeam.evaluation =
          data.candidateInTeam.evaluation.filter(
            (i) => i.id !== removeCandidateEvaluation.id
          );

        proxy.writeQuery({
          query: CANDIDATE_QUERY,
          variables: {
            user_id:
              candidateType === "candidate" ? parseInt(candidateId, 10) : null,
            person_id:
              candidateType === "person" ? parseInt(candidateId, 10) : null,
            team_id: parseInt(teamId, 10),
            position_id: parseInt(positionId, 10),
            org_id: parseInt(orgId, 10),
          },
          data,
        });

        setList(
          createList({
            candidateInTeam: data.candidateInTeam,
            viewer,
          })
        );
      },
    }),
  }),
  graphql(CREATE_COMMENT, {
    name: "createComment",
    options: ({
      match: {
        params: { candidateId, teamId, positionId, orgId, candidateType },
      },
      setList,
      viewer,
    }) => ({
      update: (proxy, { data: { createComment } }) => {
        const data = proxy.readQuery({
          query: CANDIDATE_QUERY,
          variables: {
            user_id:
              candidateType === "candidate" ? parseInt(candidateId, 10) : null,
            person_id:
              candidateType === "person" ? parseInt(candidateId, 10) : null,
            team_id: parseInt(teamId, 10),
            position_id: parseInt(positionId, 10),
            org_id: parseInt(orgId, 10),
          },
        });

        const evalKey = data.candidateInTeam.evaluation.findIndex(
          (ev) => ev.id === parseInt(createComment.item_id, 10)
        );

        if (data.candidateInTeam.evaluation[evalKey].comments) {
          data.candidateInTeam.evaluation[evalKey].comments = [
            ...data.candidateInTeam.evaluation[evalKey].comments,
            createComment,
          ];
        } else {
          data.candidateInTeam.evaluation[evalKey].comments = [createComment];
        }

        proxy.writeQuery({
          query: CANDIDATE_QUERY,
          variables: {
            user_id:
              candidateType === "candidate" ? parseInt(candidateId, 10) : null,
            person_id:
              candidateType === "person" ? parseInt(candidateId, 10) : null,
            team_id: parseInt(teamId, 10),
            position_id: parseInt(positionId, 10),
            org_id: parseInt(orgId, 10),
          },
          data,
        });

        setList(
          createList({
            candidateInTeam: data.candidateInTeam,
            viewer,
          })
        );
      },
    }),
  }),
  graphql(REMOVE_COMMENT, {
    name: "removeComment",
    options: ({
      match: {
        params: { candidateId, teamId, positionId, orgId, candidateType },
      },
      setList,
      viewer,
      assessmentRows,
    }) => ({
      update: (proxy, { data: { removeComment } }) => {
        const data = proxy.readQuery({
          query: CANDIDATE_QUERY,
          variables: {
            user_id:
              candidateType === "candidate" ? parseInt(candidateId, 10) : null,
            person_id:
              candidateType === "person" ? parseInt(candidateId, 10) : null,
            team_id: parseInt(teamId, 10),
            position_id: parseInt(positionId, 10),
            org_id: parseInt(orgId, 10),
          },
        });

        let evalKey;

        data.candidateInTeam.evaluation.forEach((e, eKey) => {
          if (e.comments) {
            e.comments.forEach((c, cKey) => {
              if (c.id === removeComment.id) {
                evalKey = eKey;
              }
            });
          }
        });

        data.candidateInTeam.evaluation[evalKey].comments = [
          ...data.candidateInTeam.evaluation[evalKey].comments.filter(
            (c) => c.id !== removeComment.id
          ),
        ];

        proxy.writeQuery({
          query: CANDIDATE_QUERY,
          variables: {
            user_id:
              candidateType === "candidate" ? parseInt(candidateId, 10) : null,
            person_id:
              candidateType === "person" ? parseInt(candidateId, 10) : null,
            team_id: parseInt(teamId, 10),
            position_id: parseInt(positionId, 10),
            org_id: parseInt(orgId, 10),
          },
          data,
        });

        setList(
          createList({
            candidateInTeam: data.candidateInTeam,
            viewer,
            assessmentRows,
          })
        );
      },
    }),
  }),
  withHandlers({
    handleRemoveRating:
      ({ removeEvaluation }) =>
      async ({ id }) => {
        await removeEvaluation({
          variables: {
            id,
          },
        });
      },
    handleSaveRating:
      ({
        saveEvaluation,
        match: {
          params: { candidateId, positionId, candidateType },
        },
      }) =>
      async ({ evaluation_type, item_id, evaluation }) => {
        await saveEvaluation({
          variables: {
            cand_id:
              candidateType === "candidate" ? parseInt(candidateId, 10) : null,
            person_id:
              candidateType === "person" ? parseInt(candidateId, 10) : null,

            pos_id: parseInt(positionId, 10),
            evaluation_type,
            item_id,
            evaluation,
          },
        });
      },
    handleRemoveComment:
      ({ removeComment }) =>
      async (comment) => {
        await removeComment({
          variables: {
            id: comment.id,
          },
        });
      },
    handleUpsertCandidateAssessmentSummary:
      ({
        upsertCandidateAssessmentSummary,
        match: {
          params: { candidateId, positionId, candidateType },
        },
        assessmentScorecard,
        saved,
        setSaved,
      }) =>
      async (summary) => {
        await upsertCandidateAssessmentSummary({
          variables: {
            position_id: parseInt(positionId, 10),
            user_id:
              candidateType === "candidate" ? parseInt(candidateId, 10) : null,
            person_id:
              candidateType === "person" ? parseInt(candidateId, 10) : null,
            scorecard_id: assessmentScorecard.id,
            summary: serializer.serialize(summary),
          },
        });
        setSaved(true);
        setTimeout(() => setSaved(false), 4000);
      },
  }),
  scrollToTop
);
