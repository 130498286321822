import React, { useRef, useState, useEffect } from "react";
import classNames from "classnames";
import Popup from "reactjs-popup";
import AILabel from "components/UI/AILabel";
import Icon from "components/UI/Icon";
import LoaderBar from "components/LoaderBar";
import AIContentTag from "./AIContentTag";
import Button from "components/UI/Button";
import Timestamp from "react-timestamp";
import GPTSummaryEmptyState from "components/UI/GPTSummaryEmptyState";
import compose from "./compose";

import styles from "./Summary.module.scss";

function getDimGroup(dim) {
  if (dim.groupId && dim.groupId.includes("values")) {
    return "values";
  }
  if (dim.groupId && dim.groupId.includes("personality")) {
    return "big5";
  }
  if (dim.__typename && dim.__typename === "CompetencyDimension") {
    return "competencies";
  }
}

const colors = {
  values: "yellow",
  big5: "blue",
  competencies: "green",
};

const getContentHtml = (content) => {
  return {
    __html: content,
  };
};

export default compose(
  ({
    team,
    isGenerating,
    gptSummary,
    updatedAt,
    dimensions,
    handleGenerateSummary,
    handleDeleteSummary,
  }) => {
    const summaryRef = useRef();

    const [activeTag, setActiveTag] = useState(null);
    const [activeTagDim, setActiveTagDim] = useState(null);
    const [container, setContainer] = useState(null);

    const handleTagHover = ({ span, dim }) => {
      setActiveTag(span);
      setActiveTagDim(dim);
    };

    useEffect(() => {
      const spans = summaryRef.current.querySelectorAll("span");

      spans.forEach((span, index) => {
        const dim_id = parseInt(span.getAttribute("dim_id"), 10);
        const dim = dimensions.find((dim) => dim.dim_id === dim_id);

        if (dim) {
          span.classList.add(
            styles.textLabel,
            styles[colors[getDimGroup(dim)]]
          );
          span.addEventListener("mouseover", () => {
            handleTagHover({ span, dim });
          });
          span.addEventListener("mouseout", () => {
            setActiveTag(null);
            setActiveTagDim(null);
          });
        }
      });
    }, [summaryRef, gptSummary]);

    useEffect(() => {
      setContainer(summaryRef.current);
    }, [summaryRef]);

    return (
      <div className={styles.wrapper}>
        {gptSummary === "" ? (
          <GPTSummaryEmptyState
            readyToRender={
              team.readyToRender &&
              team.members.filter((m) =>
                m.competencies.some((c) => c.score === null)
              ).length !== team.members.length
            }
            action={handleGenerateSummary}
            isLoading={isGenerating}
            title="AI-Powered Insights for Your Team Profile"
            description="Dive deep into your Wisnio Team Profile with AI. Harnessing over 200 data points, we distill essential insights, allowing you to quickly grasp your team's strengths and dynamics. Ready to see your team in a new light?"
            disabledMessage={
              team.members.length === 0
                ? "Invite team members to use this feature."
                : "Team members need to complete their Profiles to use this feature."
            }
          />
        ) : (
          <div className={styles.summaryHeader}>
            <div className={styles.left}>
              <div className="aiPopupWrapper">
                <Popup
                  trigger={
                    <div className={styles.aiLabel}>
                      <AILabel />
                    </div>
                  }
                  on="hover"
                  keepTooltipInside="body"
                  position="bottom left"
                  arrow={false}
                >
                  <div className={styles.aiPopup}>
                    <p>
                      Just a heads-up: the content in this section is enhanced
                      by Artificial Intelligence. While this technology adds a
                      cutting-edge touch, it may occasionally lead to unexpected
                      or unconventional statements. Your feedback on it would be
                      much appreciated, so please let us know if you have any
                      questions or concerns.
                    </p>
                  </div>
                </Popup>
              </div>
              {updatedAt && (
                <p className={styles.updatedAt}>
                  Last updated:
                  <span className={styles.time}>
                    <Timestamp date={new Date(updatedAt)} autoUpdate />
                  </span>
                </p>
              )}
            </div>
            <div className={styles.buttonGroup}>
              <div
                className={classNames(
                  styles.generateButton,
                  isGenerating && styles.generating
                )}
              >
                <Button
                  bStyle={["primary", "verySmall"]}
                  onClick={() => handleGenerateSummary()}
                >
                  {isGenerating ? (
                    <span className={styles.loadingIcon}>
                      <i className="far fa-spinner-third" />
                    </span>
                  ) : (
                    <Icon icon="Refresh" marginRight />
                  )}
                  {isGenerating
                    ? "Generating..."
                    : team.summary && team.summary.text
                    ? "Regenerate summary"
                    : "Generate summary"}
                </Button>
              </div>
              {!isGenerating && gptSummary && (
                <Button
                  bStyle={["secondary", "verySmall"]}
                  onClick={() => handleDeleteSummary()}
                >
                  <Icon icon="Close" marginRight />
                  Clear summary
                </Button>
              )}
            </div>
          </div>
        )}
        <div
          className={classNames(
            styles.contentWrapper,
            isGenerating && styles.generating
          )}
        >
          {isGenerating && (
            <div
              className={classNames(
                styles.loaderBar,
                !gptSummary && styles.firstTime
              )}
            >
              <LoaderBar isGenerating={isGenerating} />
              <div className={styles.text}>
                <p className={styles.loadingText}>Generating summary</p>
              </div>
            </div>
          )}
          <div
            className={classNames(
              styles.content,
              isGenerating && styles.generating
            )}
            ref={summaryRef}
            id="summary-container"
          >
            <div dangerouslySetInnerHTML={getContentHtml(gptSummary)} />
            {activeTag && activeTagDim && (
              <AIContentTag
                dim={activeTagDim}
                tag={activeTag}
                container={container}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
);
