import React from "react"
import classNames from "classnames"
import UserAvatar from "components/UserAvatar"
import Score from "components/Score"

import styles from "./MiniChart.module.scss"

export default ({ resource, dim, min, max, candidate, team }) => {
  const membersList = []

  team.members.filter(m => m.readyToRender).map((m) => {
    membersList.push({
      member: m,
      score: m[resource].find((d) => d.name === dim || d.title === dim).score,
    })
  })

  const candScore =
    candidate && candidate.readyToRender && 
    candidate[resource].find((d) => d.name === dim || d.title === dim).score

  return (
    <div className={styles.miniChartWrapper}>
      <div className={styles.miniChart}>
        <div className={styles.chart}>
          {membersList.map((m) => (
            <div
              style={{ left: `${m.score}%` }}
              className={styles.avatar}
              key={`user-${m.member.id}`}
            >
              <UserAvatar member={m.member} size="verySmall" showName />
            </div>
          ))}
          {candidate && (
            <div
              style={{
                left: `${candScore > 92 ? 92 : candScore}%`,
              }}
              className={classNames(styles.avatar, styles.highlight)}
              key={`user-${candidate.id}`}
            >
              <p className={styles.highlightScore}>
                <Score
                  score={
                    candidate[resource].find(
                      (d) => d.name === dim || d.title === dim
                    ).score
                  }
                />
              </p>
              <UserAvatar member={candidate} size="verySmall" showName />
            </div>
          )}
        </div>
      </div>
      {min && max ? (
        <div className={styles.legend}>
          <p>{min}</p>
          <p>{max}</p>
        </div>
      ) : (
        <div className={styles.legend}>
          <p>Less confident</p>
          <p>Highly confident</p>
        </div>
      )}
    </div>
  )
}
