import {
  withProps,
  compose,
  withState,
  withHandlers,
  lifecycle,
  branch,
  renderComponent,
} from "recompose";
import { withRouter } from "react-router-dom";
import { printoutRoute } from "client/authRoutes";
import { graphql, withApollo } from "react-apollo";
import { loader } from "graphql.macro";
import handleQueryLoading from "utils/loader";
import Circles from "components/Loaders/Circles";
import purgeTeamPositionTalentpoolCache from "utils/purgeTeamPositionTalentpoolCache";
import withStore from "utils/withStore";
import setColumnsFromPosition from "./utils/setColumnsFromPosition";
import onDragEnd from "./utils/onDragEnd";

const POSITION_QUERY = loader("client/Queries/PositionQuery98.graphql");
const REMOVE_CANDIDATE_FROM_POSITION = loader(
  "./removeCandidateFromPosition6.graphql"
);
const REMOVE_CANDIDATE_FROM_POSITION_LONGLIST = loader(
  "./removeCandidateFromPositionLonglist3.graphql"
);
const REMOVE_CANDIDATE_FROM_ORGANIZATION = loader(
  "./removeCandidateFromOrganization9.graphql"
);
const REMOVE_TEAM_FROM_INVITE = loader("./removeTeamFromInvite2.graphql");
const REORDER_KANBAN = loader("./reorderPositionKanban7.graphql");
const VIEWER_QUERY = loader("client/Queries/ViewerQuery25.graphql");
const POSITION_TEMPLATE = loader("./PositionTemplate4.graphql");
const SEND_INVITE_TO_PERSON = loader("./sendInviteToPerson.graphql");
const RESEND_INVITE = loader("./resendInvite.graphql");

export default compose(
  withRouter,
  withApollo,
  withStore("account"),
  withState("isLoading", "setIsLoading", false),
  withState("removeModal", "setRemoveModal", false),
  withState("confirmModal", "setConfirmModal", false),
  graphql(REORDER_KANBAN, {
    name: "reorderPositionKanban",
  }),
  graphql(VIEWER_QUERY, {
    name: "VIEWER_QUERY",
  }),
  graphql(POSITION_TEMPLATE, {
    name: "POSITION_TEMPLATE",
    skip: ({ position }) => !position.createdFromTemplate,
    options: ({ position }) => ({
      variables: {
        id: position.createdFromTemplate,
        org_id: position.parent_org_id,
      },
    }),
  }),
  branch(
    (props) => {
      const condition =
        (props.VIEWER_QUERY && props.VIEWER_QUERY.loading) ||
        (props.POSITION_TEMPLATE && props.POSITION_TEMPLATE.loading);
      return condition;
    },
    renderComponent(() => null)
  ),
  withState("columns", "setColumns", ({ position, VIEWER_QUERY: { viewer } }) =>
    setColumnsFromPosition({ position, viewer })
  ),
  withProps(
    ({ updateModal, position, history, VIEWER_QUERY, POSITION_TEMPLATE }) => ({
      template: POSITION_TEMPLATE ? POSITION_TEMPLATE.positionTemplate : null,
      isTour: VIEWER_QUERY.viewer.showPositionTour,
      actionHandlers: {
        pdf: () =>
          window.open(
            `${printoutRoute}/printout/position/${position.id}/${position.name
              .match(
                /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
              )
              .map((x) => x.toLowerCase())
              .join("_")}_position_profile.pdf`,
            "_blank"
          ),
        invite: () => updateModal("invite"),
        addCandidate: () => updateModal("addCandidate"),
        compareCandidates: () =>
          history.push(
            `/team/${position.parentTeam.id}/position/${position.id}/compare`
          ),
      },
    })
  ),
  graphql(REMOVE_CANDIDATE_FROM_POSITION, {
    name: "removeCandidateFromPosition",
    options: (props) => ({
      update: (proxy, { data: { removeCandidateFromPosition } }) => {
        const data = proxy.readQuery({
          query: POSITION_QUERY,
          variables: {
            id: parseInt(props.match.params.positionId, 10),
          },
        });

        data.openPosition.candidatesUnion =
          data.openPosition.candidatesUnion.filter(
            (cand) => cand.id !== removeCandidateFromPosition
          );

        data.openPosition.kanbanColumns = data.openPosition.kanbanColumns.map(
          (c) => {
            c.candidates = c.candidates.filter(
              (candId) => candId !== removeCandidateFromPosition
            );
            return c;
          }
        );

        if (
          data.openPosition.onboarding &&
          data.openPosition.onboarding.candidate.id ===
            removeCandidateFromPosition
        ) {
          data.openPosition.onboarding = null;
        }

        proxy.writeQuery({
          query: POSITION_QUERY,
          variables: {
            id: parseInt(props.match.params.positionId, 10),
          },
          data,
        });
      },
    }),
  }),
  graphql(REMOVE_CANDIDATE_FROM_POSITION_LONGLIST, {
    name: "removeCandidateFromPositionLonglist",
    options: (props) => ({
      update: (proxy, { data: { removeCandidateFromPositionLonglist } }) => {
        const data = proxy.readQuery({
          query: POSITION_QUERY,
          variables: {
            id: parseInt(props.match.params.positionId, 10),
          },
        });

        data.openPosition.candidatesUnion =
          data.openPosition.candidatesUnion.filter(
            (cand) => cand.id !== removeCandidateFromPositionLonglist
          );

        data.openPosition.kanbanColumns = data.openPosition.kanbanColumns.map(
          (c) => {
            c.candidates = c.candidates.filter(
              (candId) => candId !== removeCandidateFromPositionLonglist
            );
            return c;
          }
        );

        if (
          data.openPosition.onboarding &&
          data.openPosition.onboarding.candidate.id ===
            removeCandidateFromPositionLonglist
        ) {
          data.openPosition.onboarding = null;
        }

        proxy.writeQuery({
          query: POSITION_QUERY,
          variables: {
            id: parseInt(props.match.params.positionId, 10),
          },
          data,
        });
      },
    }),
  }),
  graphql(REMOVE_CANDIDATE_FROM_ORGANIZATION, {
    name: "removeCandidateFromOrganization",
    options: (props) => ({
      update: (proxy, { data: { removeFromOrganization } }) => {
        purgeTeamPositionTalentpoolCache({
          cache: props.client.cache.data,
          keys: removeFromOrganization.keys,
          orgId: props.position.parent_org_id,
          proxy,
        });
      },
    }),
  }),
  graphql(REMOVE_TEAM_FROM_INVITE, {
    name: "removeTeamFromInvite",
    options: (props) => ({
      update: (proxy, { data: { removeTeamFromInvite } }) => {
        const data = proxy.readQuery({
          query: POSITION_QUERY,
          variables: {
            id: props.position.id,
          },
        });

        data.openPosition.candidatesUnion =
          data.openPosition.candidatesUnion.filter(
            (invite) => invite.id !== removeTeamFromInvite.id
          );

        proxy.writeQuery({
          query: POSITION_QUERY,
          data,
          variables: {
            id: props.position.id,
          },
        });
      },
    }),
  }),
  graphql(SEND_INVITE_TO_PERSON, {
    name: "sendInviteToPerson",
    options: ({ match, setColumns, VIEWER_QUERY }) => ({
      update: (proxy, { data: { sendInviteToPerson } }) => {
        const data = proxy.readQuery({
          query: POSITION_QUERY,
          variables: {
            id: parseInt(match.params.positionId, 10),
          },
        });

        data.openPosition.candidatesUnion.find(
          (c) => c.id === sendInviteToPerson.id
        ).invite = sendInviteToPerson.invite;

        proxy.writeQuery({
          query: POSITION_QUERY,
          variables: {
            id: parseInt(match.params.positionId, 10),
          },
          data,
        });

        const position = data.openPosition;

        setColumns(
          setColumnsFromPosition({
            position,
            viewer: VIEWER_QUERY.viewer,
          })
        );
      },
    }),
  }),
  graphql(RESEND_INVITE, {
    name: "resendInvite",
  }),
  withHandlers({
    onDragEnd:
      ({ columns, setColumns, reorderPositionKanban, position }) =>
      ({ source, destination, draggableId }) =>
        onDragEnd({
          position,
          columns,
          setColumns,
          reorderPositionKanban,
          source,
          destination,
          draggableId,
        }),
    handleSendInvite:
      ({
        match: {
          params: { positionId },
        },
        sendInviteToPerson,
        resendInvite,
      }) =>
      async (item) => {
        if (item.inviteSent) {
          await resendInvite({
            variables: {
              inviteId: item.inviteSent.id,
            },
          });
        } else
          await sendInviteToPerson({
            variables: {
              person_id: parseInt(item.id, 10),
              position_id: parseInt(positionId, 10),
            },
          });
      },
  }),
  handleQueryLoading(Circles),
  lifecycle({
    componentWillUpdate(newProps) {
      const newCandidate =
        newProps.position.candidatesUnion.length !==
        this.props.position.candidatesUnion.length;

      const updatedRequirements =
        newProps.position.requirements
          .map((r) => r.id)
          .sort()
          .toString() !==
        this.props.position.requirements
          .map((r) => r.id)
          .sort()
          .toString();

      const updatedCompetencies =
        newProps.position.competencies
          .slice(0, 3)
          .map((c) => c.id)
          .sort()
          .toString() !==
        this.props.position.competencies
          .slice(0, 3)
          .map((c) => c.id)
          .sort()
          .toString();
      if (newCandidate || updatedRequirements || updatedCompetencies) {
        const { position, setColumns } = newProps;
        setColumns(
          setColumnsFromPosition({
            position,
            viewer: this.props.VIEWER_QUERY.viewer,
          })
        );
      }
    },
  })
);
