import React from "react";
import styles from "./EvaluationGroup.module.scss";
import classnames from "classnames";

const ButtonsRow = ({ activeValue, minValue = 1, maxValue, handleScore }) => {
  const labels = {
    1: "Never",
    2: "Rarely",
    3: "Sometimes",
    4: "Always",
  };

  return (
    <div className={styles.buttonRow}>
      {Array.from(
        { length: maxValue - minValue + 1 },
        (_, index) => minValue + index
      ).map((value) => (
        <div className={styles.buttonWrapper}>
          <span className={styles.tooltip}>
            <p>{labels[value]}</p>
          </span>
          <button
            key={value}
            onClick={() => handleScore(value)}
            className={classnames(styles.buttonRow__button, {
              [styles.active]: value === activeValue,
              [styles.inactive]: value !== activeValue,
            })}
          >
            {value}
          </button>
        </div>
      ))}
      <button
        key="NA"
        onClick={() => handleScore(0)}
        className={classnames(styles.buttonRow__NAButton, {
          [styles.active]: null === activeValue,
          [styles.inactive]: null !== activeValue,
        })}
      >
        Not enough information
      </button>
    </div>
  );
};

export default ButtonsRow;
