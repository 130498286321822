import React, { useState, useEffect } from "react";
import classNames from "classnames";
import Modal from "react-modal";
import SidebarWrapper from "components/UI/SidebarWrapper";
import Button from "components/UI/Button";
import Footer from "components/Footer";
import Wrapper from "components/UI/Wrapper";
import Container from "components/UI/Container";
import { ReactComponent as GoalAlignment } from "images/icons/toolkits/goal-alignment.svg";
import { ReactComponent as CompetencyMapping } from "images/icons/toolkits/competency-mapping.svg";
import { ReactComponent as ValuesAlignment } from "images/icons/toolkits/values-alignment.svg";
import { ReactComponent as Retrospective } from "images/icons/toolkits/retrospective.svg";
import { ReactComponent as UsersIcon } from "images/icons/Users.svg";
import StartTool from "./StartTool";
import { useSidebar } from "utils/sidebarProvider";

import styles from "./TeamPlaybook.module.scss";
import compose from "./compose";

export default compose(
  ({ TOOLKITS_QUERY: { toolkits }, TEAM_QUERY: { team } }) => {
    const [modal, setModal] = useState(false);

    const icon = {
      "goal-alignment": GoalAlignment,
      "competency-mapping": CompetencyMapping,
      "values-alignment": ValuesAlignment,
      retrospective: Retrospective,
      "team-effectiveness": UsersIcon,
    };

    const { contentContext } = useSidebar();
    const [content, setContent] = contentContext;

    const sidebarContent = [];

    useEffect(() => {
      setContent(sidebarContent);
    }, []);

    return (
      <SidebarWrapper noHeader>
        <h2 className={styles.topTitle}>Toolkit</h2>
        <h4 className={styles.subtitle}>
          The toolkit includes an ever-expanding selection of team development
          tools for team leaders, team members, coaches, and HR professionals.
          All toolkit tools can run on their own or be combined into a more
          comprehensive development workflow.
        </h4>
        <div className={styles.toolkits}>
          {toolkits.map((t) => (
            <div
              key={t.name}
              className={classNames(
                styles.toolkitWrapper,
                !t.active && styles.notActive
              )}
            >
              <Button
                bStyle={["none"]}
                type="button"
                onClick={() => t.active && setModal(t)}
              >
                <div className={styles.toolkit}>
                  <Container size="large">
                    <div className={styles.innerWrapper}>
                      <div className={classNames(styles.icon, styles[t.color])}>
                        {React.createElement(icon[t.icon], null)}
                      </div>
                      <h5 className={styles.title}>{t.name}</h5>
                      <p className={styles.desc}>{t.subtitle}</p>
                      {!t.active && (
                        <p className={styles.comingSoon}>Coming soon</p>
                      )}
                      {t.name === "Team effectiveness" && (
                        <p className={styles.beta}>BETA</p>
                      )}
                    </div>
                  </Container>
                </div>
              </Button>
            </div>
          ))}
        </div>
        <Modal
          closeTimeoutMS={350}
          isOpen={modal.active}
          onRequestClose={() => setModal(false)}
          shouldCloseOnOverlayClick
          className={styles.modal}
        >
          <Button bStyle="close" type="button" onClick={() => setModal(false)}>
            <i className="fal fa-times" />
          </Button>
          <StartTool team={team} tool={modal} />
        </Modal>
      </SidebarWrapper>
    );
  }
);
