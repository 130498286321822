import React from 'react'
import { useTour } from '@reactour/tour'
import classNames from 'classnames'
import Modal from 'react-modal'
import Button from 'components/UI/Button'
import getAlignment from 'utils/getAlignment'
import styles from './index.module.scss'
import PersonalTemplate from './Templates/Personal'
import OrgUserTemplate from './Templates/OrgUser'
import TeamTemplate from './Templates/Team'
import MemberTemplate from './Templates/Member'

const renderTemplate = ({
  resource,
  dim,
  members,
  highlight,
  candidate,
  alignment,
  casestudy,
  labelClass
}) => {
  switch (resource) {
    case 'personal':
      return <PersonalTemplate styles={styles} dim={dim} />
    case 'orgUser':
      return (
        <OrgUserTemplate styles={styles} dim={dim} user={highlight} />
      )
    case 'team':
      return (
        <TeamTemplate
          styles={styles}
          dim={dim}
          members={members}
          labelClass={labelClass}
        />
      )
    case 'member':
      return (
        <MemberTemplate
          styles={styles}
          dim={dim}
          candidate={candidate}
          members={members}
          highlight={highlight}
          alignment={alignment}
          casestudy={casestudy}
        />
      )

    default:
      return null
  }
}

const getColor = (alignment) => {
  if (alignment === 'Very diverse') {
    return 'green'
  }
  if (alignment === 'Diverse') {
    return 'grey'
  }
  if (alignment === 'Similar') {
    return 'yellow'
  }
  if (alignment === 'Very similar') {
    return 'red'
  }
  return null
}

export default ({
  team,
  data,
  dim,
  openModal,
  updateModal,
  resource,
  members,
  highlight,
  candidate,
  casestudy
}) => {
  if (openModal === '') return null

  const { isOpen, setIsOpen } = useTour()

  const currentDimIndex = data.findIndex((d) => d.name === dim.name)
  const incrementedDimIndex = currentDimIndex + 1
  const decrementedDimIndex = currentDimIndex - 1
  const next =
    incrementedDimIndex === data.length ? 0 : incrementedDimIndex
  const prev =
    decrementedDimIndex === -1 ? data.length - 1 : decrementedDimIndex
  const nextDim = data[next]
  const prevDim = data[prev]

  const labelClass = getColor(dim.alignment)

  const { label } =
    resource === 'member' &&
    getAlignment({
      dim,
      team,
      teamMember: highlight,
      resource: 'personality'
    })

  const alignment = label

  return (
    <Modal
      closeTimeoutMS={350}
      isOpen={openModal.name === dim.name}
      onAfterOpen={() => resource === 'team' && setIsOpen(false)}
      onAfterClose={() => resource === 'team' && setIsOpen(true)}
      onRequestClose={() => updateModal('')}
      shouldCloseOnOverlayClick
      className={styles.modal}
    >
      <Button
        bStyle="close"
        type="button"
        onClick={() => updateModal('')}
      >
        <i className="fal fa-times" />
      </Button>

      <div className={styles.desktop}>
        <div className={styles.prevButton}>
          <Button
            bstyle={['arrowButton', 'left']}
            type="button"
            onClick={() => updateModal(prevDim)}
          >
            <i className="fas fa-chevron-left" />
          </Button>
        </div>
      </div>

      <div className={classNames(styles.modalWrapper)}>
        <div className={styles.info}>
          <h4 className={styles.facet}>
            <i className="fas fa-dna" />
            Personality
          </h4>
          <span className={styles.dimIcon}>
            <i className={dim.icon} />
          </span>
          <div className={styles.titleNButtons}>
            <div className={styles.mobile}>
              <Button
                bstyle={['arrowButton', 'left']}
                type="button"
                onClick={() => updateModal(prevDim)}
              >
                <i className="fas fa-chevron-left" />
              </Button>
            </div>
            <h2>{dim.name}</h2>
            <div className={styles.mobile}>
              <Button
                bstyle={['arrowButton', 'right']}
                type="button"
                onClick={() => updateModal(nextDim)}
              >
                <i className="fas fa-chevron-right" />
              </Button>
            </div>
          </div>
          <h4 className={styles.subtitle}>{dim.desc}</h4>
        </div>
        {renderTemplate({
          resource,
          dim,
          members,
          highlight,
          candidate,
          alignment,
          casestudy,
          labelClass
        })}

        <div className={styles.footerButtons}>
          <Button
            bstyle={['none']}
            type="button"
            onClick={() => updateModal(prevDim)}
          >
            <i className="fas fa-chevron-left" />
            <div className={styles.text}>
              <span>Previous trait</span>
              <p>{prevDim.name}</p>
            </div>
          </Button>
          <Button
            bstyle={['none']}
            type="button"
            onClick={() => updateModal(nextDim)}
          >
            <i className="fas fa-chevron-right" />
            <div className={styles.text}>
              <span>Next trait</span>
              <p>{nextDim.name}</p>
            </div>
          </Button>
        </div>
      </div>

      <div className={styles.desktop}>
        <div className={styles.nextButton}>
          <Button
            bstyle={['arrowButton', 'right']}
            type="button"
            onClick={() => updateModal(nextDim)}
          >
            <i className="fas fa-chevron-right" />
          </Button>
        </div>
      </div>
    </Modal>
  )
}
