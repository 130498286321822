import React from "react";
import { Link } from "react-router-dom";
import Button from "components/UI/Button";
import Modal from "react-modal";
import sortPositionCompetencies from "utils/sortPositionCompetencies";
import sortTeamCompetenciesForPosition from "utils/sortTeamCompetenciesForPosition";
import CompetencyModal from "components/Competencies/CompetencyModal";
import CompColumn from "./CompColumn";
import compose from "./compose";

import styles from "./MapCompetencies.module.scss";

export default compose(
  ({ positionTemplate, showModal, setShowModal, toggleEditCompes, orgId }) => {
    return (
      <div className={styles.wrapper}>
        <div className={styles.row}>
          {positionTemplate.competencies.filter(
            (comp) => typeof comp.answer === "number"
          ).length > 0 ? (
            <div className={styles.competencies}>
              <div className={styles.compWrapper}>
                <CompColumn
                  competencies={positionTemplate.competencies.slice(0, 3)}
                  allComps={positionTemplate.competencies.slice(0, 9)}
                  setShowModal={setShowModal}
                  showModal={showModal}
                  columnTitle="Must have"
                  group="expert"
                  colStyle="mustHave"
                  amountOfStars={3}
                />
                <CompColumn
                  competencies={positionTemplate.competencies.slice(3, 6)}
                  allComps={positionTemplate.competencies.slice(0, 9)}
                  setShowModal={setShowModal}
                  showModal={showModal}
                  columnTitle="Important to have"
                  group="strong"
                  colStyle="important"
                  amountOfStars={2}
                />
                <CompColumn
                  competencies={positionTemplate.competencies.slice(6, 9)}
                  allComps={positionTemplate.competencies.slice(0, 9)}
                  setShowModal={setShowModal}
                  showModal={showModal}
                  columnTitle="Good to have"
                  group="good"
                  colStyle="goodToHave"
                  amountOfStars={1}
                />
                <Modal
                  isOpen={showModal !== ""}
                  onRequestClose={() => setShowModal("")}
                  shouldCloseOnOverlayClick
                >
                  <Button
                    bStyle="close"
                    type="button"
                    onClick={() => setShowModal("")}
                  >
                    <i className="fal fa-times" />
                  </Button>
                  <CompetencyModal
                    resource="position"
                    competencies={sortPositionCompetencies(positionTemplate)}
                    setShowModal={setShowModal}
                    currentCompName={showModal}
                  />
                </Modal>
              </div>
            </div>
          ) : (
            <div className={styles.competenciesPlaceholder}>
              <div className={styles.competencies}>
                <div className={styles.columnWrapper}>
                  <h3 className={styles.title}>
                    <div className={styles.stars}>
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                    </div>
                    Must have
                  </h3>
                  <div className={styles.column} />
                </div>
                <div className={styles.columnWrapper}>
                  <h3 className={styles.title}>
                    <div className={styles.stars}>
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                    </div>
                    Important to have
                  </h3>
                  <div className={styles.column} />
                </div>
                <div className={styles.columnWrapper}>
                  <h3 className={styles.title}>
                    <div className={styles.stars}>
                      <i className="fas fa-star" />
                    </div>
                    Good to have
                  </h3>
                  <div className={styles.column} />
                </div>
              </div>
              <div className={styles.button}>
                <Link
                  to={`/${orgId}/scorecard/${positionTemplate.id}/mapcompetencies`}
                >
                  <Button
                    bStyle={["primary", "large"]}
                    onClick={toggleEditCompes}
                  >
                    <i className="fas fa-plus" />
                    Prioritize competencies
                  </Button>
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
);
