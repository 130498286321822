import React from "react";
import PropTypes from "prop-types";

const InputWrapper = ({ children }) => {
  return (
    <div
      style={{
        borderRadius: 8,
        border: "0.5px solid var(--greys-grey-4, #E4E6E8)",
        boxShadow:
          "0px 0px 5px 0px rgba(152, 152, 152, 0.12),0px 22px 24px 0px rgba(153, 153, 153, 0.10)",
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 12,
        paddingBottom: 12,
      }}
    >
      {children}
    </div>
  );
};

InputWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default InputWrapper;
