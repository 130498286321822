import React from "react";
import { withRouter } from "react-router-dom";
import { graphql } from "react-apollo";
import { compose } from "recompose";
import handleLoading from "utils/handleLoading";
import { loader } from "graphql.macro";

const VIEWER_QUERY = loader("client/Queries/ViewerQuery25.graphql");

export default compose(
  withRouter,
  graphql(VIEWER_QUERY),
  handleLoading
)(({ data: { viewer } }) => {
  const truncate = (string) => {
    if (string.length > 14) return `${string.substring(0, 14)}...`;
    return string;
  };

  return <React.Fragment>{viewer.name}</React.Fragment>;
});
