import axios from "utils/axios";
import { compose, withState, lifecycle } from "recompose";
import { authStrategies } from "client/authRoutes";
import { withRouter } from "react-router-dom";

export default compose(
  withRouter,
  withState("loading", "setLoading", true),
  withState("authStrategies", "setAuthStrategies", false),
  lifecycle({
    componentDidMount() {
      let getRoute = authStrategies;

      if (this.props.match.params.caseStudySecret) {
        getRoute = `${authStrategies}?caseStudy=${this.props.match.params.caseStudySecret}`;
      }
      axios.get(getRoute).then(({ data }) => {
        this.props.setAuthStrategies(data);
        this.props.setLoading(false);
      });
    }
  })
);
