import React, { useState } from "react";
import DOMPurify from "dompurify";
import classNames from "classnames";
import Icon from "components/UI/Icon";
import Timestamp from "react-timestamp";
import UserAvatar from "components/UserAvatar";
import Button from "components/UI/Button";

import styles from "./ScorecardModal.module.scss";

const titleBarColors = ["primary", "green", "yellow", "grey"];

export default ({ assessmentScorecard }) => {
  const [showMore, setShowMore] = useState(false);
  const [showMoreDetail, setShowMoreDetail] = useState("");

  const scorecardRows = [];

  assessmentScorecard.assessment_rows.map((row) => {
    const index = scorecardRows.findIndex(
      (sr) => sr.groupTitle === row.group_title
    );

    if (index !== -1) {
      scorecardRows[index].items.push(row);
    } else {
      scorecardRows.push({
        groupTitle: row.group_title,
        color: titleBarColors[scorecardRows.length],
        items: [row],
      });
    }
  });

  console.log(titleBarColors[2]);

  const getContentHtml = (content) => ({
    __html: DOMPurify.sanitize(content),
  });

  return (
    <React.Fragment>
      <div className={styles.header}>
        <h2 className={styles.title}>
          {assessmentScorecard.title.match(/(\w+)/g).length === 2
            ? `${assessmentScorecard.title.split(" ")[0]} \n ${
                assessmentScorecard.title.split(" ")[1]
              }`
            : assessmentScorecard.title}
        </h2>
        <div className={styles.labels}>
          {assessmentScorecard.category && (
            <p className={styles.label}>{assessmentScorecard.category}</p>
          )}
          {assessmentScorecard.stage && (
            <p className={classNames(styles.label, styles.border)}>
              {assessmentScorecard.stage}
            </p>
          )}
        </div>
      </div>
      <div className={classNames(styles.wrapper)}>
        <div className={classNames(styles.content)}>
          <div className={styles.table}>
            {scorecardRows.map((row) => (
              <>
                <div className={classNames(styles.titleBar, styles[row.color])}>
                  <h5>{row.groupTitle}</h5>
                </div>
                <div className={styles.list}>
                  {row.items.map((item) => (
                    <li
                      className={styles.hoverable}
                      onClick={() => {
                        if (showMoreDetail.title === item.title) {
                          setShowMoreDetail("");
                        } else if (item.description) {
                          setShowMoreDetail(item);
                        }
                      }}
                    >
                      <div className={styles.listRow}>
                        {item.title}
                        {item.description && (
                          <span
                            className={classNames(
                              styles.arrow,
                              showMoreDetail.title === item.title && styles.open
                            )}
                          >
                            <Button bStyle={["none"]}>
                              <Icon icon="Chevron" />
                            </Button>
                          </span>
                        )}
                      </div>
                      <div
                        className={classNames(
                          styles.moreDetail,
                          showMoreDetail.title === item.title && styles.show
                        )}
                      >
                        <div
                          className={styles.rowDescription}
                          dangerouslySetInnerHTML={getContentHtml(
                            item.description
                          )}
                        ></div>
                      </div>
                    </li>
                  ))}
                </div>
              </>
            ))}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
