import React from 'react'
import AvatarEditor from 'react-avatar-editor'
import Button from 'components/UI/Button'
import ModalTitle from 'components/UI/ModalTitle'
import Loading from 'components/Loaders/Circles'
import compose from './compose'

import styles from './AvatarModal.module.scss'

export default compose(
  ({
    avatarFile,
    avatarScale,
    setAvatarScale,
    avatarEditorRef,
    finishAvatarEdit,
    editorIsLoading
  }) => (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        <ModalTitle>Scale image</ModalTitle>
      </div>
      <AvatarEditor
        ref={avatarEditorRef}
        image={avatarFile}
        width={250}
        height={250}
        borderRadius={125}
        color={[0, 0, 0, 0.6]}
        scale={avatarScale}
      />
      {editorIsLoading && (
        <div className={styles.loaderWrapper}>
          <Loading />
        </div>
      )}
      <div>
        <input
          type='range'
          name='scale'
          min='1.0'
          max='3.0'
          step='0.1'
          value={avatarScale}
          onChange={e => setAvatarScale(parseFloat(e.target.value))}
        />
      </div>
      <Button type='button' onClick={finishAvatarEdit}>
        <i className='fas fa-save' />
        Save
      </Button>
    </div>
  )
)
