import { compose, withHandlers, withState } from "recompose";
import { graphql } from "react-apollo";
import { loader } from "graphql.macro";

const REMOVE_REVIEWER_FROM_ASSESSMENT_SESSION = loader(
  "./removeReviewFromAssessmentSession.graphql"
);
const SEND_REMINDER_TO_REVIEWER = loader("./sendReminderToReviewer.graphql");
export default compose(
  graphql(REMOVE_REVIEWER_FROM_ASSESSMENT_SESSION, {
    name: "removeReviewerFromAssessmentSession",
  }),
  graphql(SEND_REMINDER_TO_REVIEWER, {
    name: "sendReminderToReviewer",
  }),
  withState("btnState", "setBtnState", {
    faIcon: "fad fa-paper-plane",
    isLoading: false,
    hoverText: "Resend invite",
  }),
  withHandlers({
    handleRemoveReview:
      ({ assessmentSession, removeReviewerFromAssessmentSession }) =>
      async ({ review }) => {
        await removeReviewerFromAssessmentSession({
          variables: {
            session_id: assessmentSession.id,
            review_id: review.id,
          },
        });
      },
    handleSendReminder:
      ({ assessmentSession, sendReminderToReviewer, setBtnState }) =>
      async ({ review }) => {
        setBtnState({
          faIcon: "fas fa-spinner-third",
          isLoading: true,
          disabled: true,
          hoverText: "Sending...",
        });
        await sendReminderToReviewer({
          variables: {
            session_id: assessmentSession.id,
            review_id: review.id,
          },
        });
        setBtnState({
          faIcon: "fas fa-check",
          isLoading: false,
          disabled: true,
          hoverText: "Reminder sent!",
        });
      },
  })
);
