import React from "react";
import classNames from "classnames";
import { ReactComponent as InviteRow } from "images/inviteRow.svg";
import Select from "components/UI/Select";
import ModalTitle from "components/UI/ModalTitle";
import Button from "components/UI/Button";
import Field from "components/UI/Field";
import AccountLimitExceeded from "components/AccountLimitExceeded";
import Icon from "components/UI/Icon";

import compose from "./compose";
import styles from "./Modal.module.scss";

export default compose(
  ({
    organization,
    updateModal,
    handleSubmit,
    error,
    setError,
    success,
    email,
    setEmail,
    invitesList,
    setInvitesList,
    handleValidateEmail,
    isLoading,
    limitExceededMessage,
    setLimitExceededMessage,
    setOverlayClose,
  }) => {
    const roleTypes = [
      { name: "Member", role: 2 },
      { name: "Admin", role: 4 },
    ];

    const changeRoleType = (user, event) => {
      const index = invitesList.findIndex((i) => i.email === user.email);

      invitesList[index].roleType = parseInt(event.target.value, 10);

      setInvitesList(invitesList);
    };

    return (
      <div className={styles.wrapper}>
        <Button bStyle="close" type="button" onClick={() => updateModal(false)}>
          <i className="fal fa-times" />
        </Button>
        <div className={styles.modalWrapper}>
          <div className={styles.modalHead}>
            <ModalTitle>{`Invite new people to join ${organization.name}`}</ModalTitle>
            <p>
              Invited people will appear in the talent pool and you can later
              add them to teams or positions.
            </p>
          </div>
          <div className={styles.inviteAdmin}>
            <div className={styles.row}>
              <div className={styles.smallRow}>
                <p>To:</p>
                <Field
                  fullWidth
                  white
                  type="text"
                  name="email"
                  required
                  value={email}
                  placeholder="Enter email address"
                  onChange={(e) => setEmail(e.target.value)}
                />
                {email.length > 0 && (
                  <>
                    <div className={styles.delete}>
                      <Button
                        bStyle={["none"]}
                        type="button"
                        onClick={() => {
                          setEmail("");
                          setError(false);
                        }}
                      >
                        <Icon icon="Close" />
                      </Button>
                    </div>
                    <div className={styles.addButton}>
                      <Button
                        bStyle={["primary"]}
                        type="button"
                        disabled={invitesList.find((i) => i === email)}
                        onClick={() => handleValidateEmail(email)}
                      >
                        Add
                      </Button>
                    </div>
                  </>
                )}
              </div>
              {error && (
                <div className={styles.error}>
                  <p>{error}</p>
                </div>
              )}
              {limitExceededMessage && limitExceededMessage.message && (
                <AccountLimitExceeded
                  limitExceededMessage={limitExceededMessage}
                  setLimitExceededMessage={setLimitExceededMessage}
                />
              )}
            </div>
            <div className={styles.tableWrapper}>
              <table>
                <thead>
                  <tr>
                    <th className={styles.name}>Name</th>
                    <th className={styles.role}>Role</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {invitesList.length > 0 &&
                    invitesList.map((user) => (
                      <tr key={`userRow-${user.email}`}>
                        <td className={styles.name}>{user.email}</td>
                        <td className={styles.role}>
                          <Select
                            name="roleType"
                            noBorder
                            value={user.roleType}
                            onChange={(e) => changeRoleType(user, e)}
                          >
                            {roleTypes.map((rt) => (
                              <option key={rt.name} value={rt.role}>
                                {rt.name}
                              </option>
                            ))}
                          </Select>
                        </td>
                        <td className={styles.remove}>
                          <Button
                            bStyle={["none"]}
                            type="button"
                            onClick={() => {
                              setInvitesList([
                                ...invitesList.filter((i) => i !== user),
                              ]);
                              if (
                                [...invitesList.filter((i) => i !== user)]
                                  .length === 0
                              ) {
                                setOverlayClose && setOverlayClose(true);
                              }
                            }}
                          >
                            <i className="fal fa-times" />
                          </Button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            {invitesList.length === 0 && (
              <div className={styles.placeholder}>
                <div className={styles.row}>
                  <InviteRow />
                </div>
                <div className={styles.row}>
                  <InviteRow />
                </div>
                <div className={styles.row}>
                  <InviteRow />
                </div>
              </div>
            )}
          </div>
          <div
            className={classNames(
              styles.buttonGroup,
              document.getElementById("inviteWrapper") &&
                document.getElementById("inviteWrapper").offsetHeight > 550 &&
                styles.relative
            )}
          >
            <Button
              bStyle={["green", "large"]}
              disabled={invitesList.length < 1 || isLoading}
              onClick={() => handleSubmit()}
            >
              {isLoading && (
                <div className={styles.loading}>
                  <i className="far fa-spinner-third" />
                </div>
              )}
              Invite people
            </Button>
            <Button
              bStyle={["secondary", "large"]}
              onClick={() => updateModal(false)}
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    );
  }
);
