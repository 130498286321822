import React from "react";
import { compose, withState } from "recompose";
import { withRouter } from "react-router-dom";
import Cookies from "cookies-js";
import pathToRegexp from "path-to-regexp";
import AuthenticatedUtils from "./AuthenticatedUtils";
import SidebarProvider from "utils/sidebarProvider";
import SignupSidebar from "components/Sidebar/Signup";

export default compose(
  withRouter,
  withState("collapsed", "setCollapsed", false)
)(({ children, location, history, collapsed, setCollapsed }) => {
  const isAuthenticated = Cookies.get("_s") && true;

  if (
    children.some((child) => {
      return pathToRegexp(child.props.path).exec(location.pathname);
    })
  ) {
    if (!isAuthenticated) {
      return (
        <SidebarProvider collapsed={collapsed} setCollapsed={setCollapsed}>
          <SignupSidebar>{children}</SignupSidebar>
        </SidebarProvider>
      );
    }

    return <AuthenticatedUtils>{children}</AuthenticatedUtils>;
  }
  return null;
});
