import React from "react"
import classNames from "classnames"
import Button from "components/UI/Button"
import { ReactComponent as PersonalityImg } from "images/PersonalityMap.svg"
import { ReactComponent as TeamMembersImg } from "images/TeamMembers.svg"

import styles from "./LearnMoreModal.module.scss"

export default ({ updateModal }) => (
  <div className={styles.wrapper}>
    <h2 className={styles.title}>What you can do with Wisnio</h2>
    <p className={styles.subtitle}>
      Create your own workspace to access the full capacity of Wisnio hiring and
      team development tools.
    </p>
    <div className={styles.section}>
      <div className={styles.row}>
        <div className={styles.column}>
          <div className={styles.image}>
            <TeamMembersImg />
          </div>
          <h5>Build winning teams</h5>
          <p>
            Learn how to leverage the unique strengths of each team member and
            how to shape a culture where everyone can perform at their peak.
          </p>
        </div>
        <div className={styles.column}>
          <div className={classNames(styles.image, styles.blue)}>
            <PersonalityImg />
          </div>
          <h5>Make data-driven hiring decisions</h5>
          <p>
            Understand how each candidate would complement your team and make
            objective, data-driven hiring decisions.
          </p>
        </div>
      </div>
      <div className={styles.cta}>
        <Button bStyle={["primary"]} onClick={() => updateModal("newAccount")}>
          Create workspace
        </Button>
      </div>
    </div>
  </div>
)
