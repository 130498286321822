import React from "react";
import Button from "components/UI/Button";
import Icon from "components/UI/Icon";
import compose from "./compose";
import styles from "./OrgInvitation.module.scss";

export default compose(({ invite, acceptInvite, declineInvite }) => {
  if (!invite) return null;

  return (
    <div className={styles.wrapper}>
      <div className={styles.icon}>
        <Icon icon="Mail" />
      </div>
      <h2 className={styles.title}>
        {`Invitation to ${invite.organizations[0].name}`}
      </h2>
      <p className={styles.subtitle}>
        {invite.teams[0] && invite.teams[0].position
          ? `${invite.inviter_name} has invited to you to ${
              invite.teams[0].position.name
            } as ${invite.teams[0].role === "admin" ? "an" : "a"} ${
              invite.teams[0].role
            }. Would you like to join?`
          : `${invite.inviter_name} has invited to you to ${
              invite.organizations[0].name
            } as ${invite.organizations[0].role === "admin" ? "an" : "a"} ${
              invite.organizations[0].role
            }. Would you like to join?`}
      </p>
      <div className={styles.buttonGroup}>
        <Button bStyle={["primary"]} type="button" onClick={acceptInvite}>
          Accept
        </Button>
        <Button bStyle={["secondary"]} type="button" onClick={declineInvite}>
          Decline
        </Button>
      </div>
    </div>
  );
});
