import { compose, withHandlers, withState } from 'recompose'
import { loader } from 'graphql.macro'
import { graphql } from 'react-apollo'
import handleLoading from 'utils/handleLoading'
import { withRouter } from 'react-router-dom'

const COMPETENCIES = loader('./competencies.graphql')
const SAVE_ANSWER = loader('./saveCompeAnswer1.graphql')

export default compose(
  withRouter,
  graphql(COMPETENCIES),
  graphql(SAVE_ANSWER, { name: 'saveCompeAnswer' }),
  withState('selectedComp', 'setSelectedComp', null),
  withState('isFinished', 'setIsFinished', false),
  withHandlers({
    handleSave: ({ selectedComp, saveCompeAnswer, setIsFinished }) => () => {
      saveCompeAnswer({
        variables: {
          dim_id: selectedComp
        }
      }).then((res) => {
        setIsFinished(true)
      })
    },
    handleRedirect: ({ history }) => () => {
      history.push('/personal')
    }
  }),

  handleLoading
)
