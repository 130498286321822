import { withHandlers } from "recompose";
import { FORM_ERROR } from "final-form";
import axios from "utils/axios";
import { signupRoute } from "client/authRoutes";
import { SET_CASE_STUDY, ACTIVATE_ONBOARDING } from "store/actionTypes";
import QueryString from "query-string";

export default withHandlers({
  onSubmit:
    ({ history, match, location, dispatch }) =>
    async (values) => {
      const queryString = QueryString.parse(location.search);
      delete values.repeatpassword;

      try {
        const {
          data: {
            success,
            message,
            redirect,
            shouldOnboard,
            caseStudy,
            positionTemplate,
          },
        } = await axios.post(
          signupRoute,
          {
            ...values,
            inviteSecret:
              match.params.inviteSecret && match.params.inviteSecret,
            positionTemplate: queryString && queryString.positionTemplate,
            ref: queryString && queryString.ref,
            caseStudy:
              match.params.caseStudySecret && match.params.caseStudySecret,
          },
          { withCredentials: true }
        );

        if (!success) {
          throw new Error(message);
        }

        if (caseStudy && shouldOnboard) {
          // console.log({success, message, redirect, shouldOnboard, caseStudy, positionTemplate})
          dispatch({ type: ACTIVATE_ONBOARDING });
          dispatch({
            type: SET_CASE_STUDY,
            payload: caseStudy,
          });
        }
        history.replace(redirect);
      } catch (err) {
        return { [FORM_ERROR]: err.message };
      }
    },
});
