import React from "react";
import { withState, compose, withHandlers, lifecycle } from "recompose";
import { graphql } from "react-apollo";
import { loader } from "graphql.macro";
import handleLoading from "utils/loader";
import Circles from "components/Loaders/Circles";
import isEmail from "utils/isEmail";
import withStore from "utils/withStore";

const VALIDATE_EMAIL = loader("client/Mutations/validateInviteEmail1.graphql");

export default compose(
  withStore("account"),
  handleLoading(() => <Circles />),
  graphql(VALIDATE_EMAIL, {
    name: "validateEmail",
  }),
  withState("nameOrEmail", "setNameOrEmail", ""),
  withState("currentStep", "setCurrentStep", null),
  withState("list", "updateList", []),
  withState("error", "setError", null),
  withState("limitExceededMessage", "setLimitExceededMessage", null),
  withHandlers({
    handleValidateAndUpdateList:
      ({
        team_id,
        position_id,
        account_id,
        org_id,
        validationCase,
        setNameOrEmail,
        list,
        updateList,
        setOverlayClose,
        validateEmail,
        error,
        setError,
        require,
        setLimitExceededMessage,
        offset_amount,
        assessment_session_id,
        resource,
        currentStep,
      }) =>
      async (user) => {
        const email = user.email.replace(/\s/g, "");

        if (list.some((l) => l.email === email)) {
          setError("User is already added to the list.");
          return;
        }
        if (!isEmail(email)) {
          setError("Must be a valid email.");
          return;
        }
        const {
          data: {
            validateInviteEmail: {
              success,
              message,
              response,
              validationResult,
            },
          },
        } = await validateEmail({
          variables: {
            validationCase,
            account_id,
            org_id,
            team_id,
            position_id,
            assessment_session_id,
            offset_amount: list.length,
            email: email,
          },
        });
        if (validationResult) {
          setLimitExceededMessage(validationResult);
        }

        // console.log({
        //   success,
        //   message,
        //   response,
        //   validationResult
        // })
        if (!success) {
          setError(message);
          return;
        }

        if (success) {
          updateList(
            [...list, response].map((i) => {
              if (require) {
                const requiredValues = {};
                require.values.forEach(
                  (value) => (requiredValues[value] = require[value][0].id)
                );
                i[require.key] = requiredValues;
              }
              if (resource === "candidate" && currentStep === "contact") {
                i.sendInvite = false;
              } else {
                i.sendInvite = true;
              }
              return i;
            })
          );
          setOverlayClose && setOverlayClose(false);
          setNameOrEmail("");
          setError(null);
        }
      },
  })
);
