import React from 'react'
import Wrapper from 'components/UI/Wrapper'
import classnames from 'classnames'
import Button from 'components/UI/Button'
import Modal from 'react-modal'
import styles from './styles.module.scss'
import compose from './compose'

export default compose(
  ({
    surveyRows,
    setSelectedComp,
    selectedComp,
    handleSave,
    isFinished,
    handleRedirect
  }) => (
    <Wrapper>
      <h4 className={styles.header}>
        Please choose one competency that you would like to develop
      </h4>
      <div className={styles.competencies}>
        {surveyRows.map(row => (
          <div
            key={`comp-${row.question_dim_id}`}
            className={classnames(
              styles.compWrapper,
              selectedComp === row.question_dim_id && styles.selected
            )}
            onClick={() => setSelectedComp(row.question_dim_id)}
          >
            <h4>{row.dimension.name}</h4>
            <p>{row.dimension.description_tid}</p>
          </div>
        ))}
      </div>
      {selectedComp && (
        <div className={styles.footer}>
          You have selected:
          {' '}
          <strong>
            {
              surveyRows.find(row => row.question_dim_id === selectedComp)
                .dimension.name
            }
          </strong>
          <Button
            bStyle={['primary', 'large']}
            type='button'
            onClick={handleSave}
          >
            Finish
          </Button>
        </div>
      )}
      <Modal
        closeTimeoutMS={350}
        onRequestClose={() => handleRedirect()}
        isOpen={isFinished}
        shouldCloseOnOverlayClick
        className={styles.modal}
      >
        <Button bStyle='close' type='button' onClick={() => handleRedirect()}>
          <i className='fal fa-times' />
        </Button>
        <div className={styles.doneWrapper}>
          <h3>Thank you!</h3>

          <p className={styles.content}>
            We will match you with a suitable Mentor and share guidelines for
            the first mentoring meeting. Stay with us, it might take up to a few
            days to craft your customized learning journey. In case of any
            questions or concerns, please contact us:
            {' '}
            <a href='mailto: kei@wisnio.com'>kei@wisnio.com</a>
          </p>
          <Button
            bStyle={['primary', 'large']}
            type='button'
            onClick={() => handleRedirect()}
          >
            Back to personal profile
          </Button>
        </div>
      </Modal>
    </Wrapper>
  )
)
