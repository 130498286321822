import { withHandlers } from 'recompose'
import serializer from 'components/TextEditor/editorSerializer'

export default withHandlers({
  handleUpdatePositionTemplate: ({
    summary,
    updatePositionTemplate,
    setSaved,
    positionTemplate: { id }
  }) => () => {
    const serialized = serializer.serialize(summary)

    updatePositionTemplate({
      variables: {
        template_id: id,
        objectives: serialized
      }
    }).then(() => {
      setSaved(true);
      setTimeout(() => setSaved(false), 4000);
    })
  }
})
