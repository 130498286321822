import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import SidebarWrapper from "components/UI/SidebarWrapper";
import Icon from "components/UI/Icon";
import Footer from "components/Footer";
import Field from "components/UI/Field";
import Button from "components/UI/Button";
import { useSidebar } from "utils/sidebarProvider";
import Invite from "./Invite";
import styles from "./Members.module.scss";
import UsersList from "./UsersList";
import compose from "./compose";

export default compose(
  ({
    data: { account },
    history,
    openModal,
    updateModal,
    overlayClose,
    setOverlayClose,
  }) => {
    const [searchValue, setSearchValue] = useState(null);
    const [debouncedValue, setDebouncedValue] = useState(null);

    useEffect(() => {
      const delayDebounceFn = setTimeout(() => {
        setSearchValue(debouncedValue);
      }, 500);

      return () => clearTimeout(delayDebounceFn);
    }, [debouncedValue]);

    const { contentContext } = useSidebar();
    const [content, setContent] = contentContext;

    const sidebarContent = [
      {
        items: [
          {
            title: "General",
            action: () => history.push("/account/" + account.id + "/settings"),
            style: "",
          },
          {
            title: "Members",
            action: () => history.push("/account/" + account.id + "/members"),
            style: "active",
          },
          {
            title: "Organisations",
            action: () =>
              history.push("/account/" + account.id + "/organisations"),
            style: "",
          },
          {
            title: "Billing",
            action: () => history.push("/account/" + account.id + "/billing"),
            style: "",
          },
        ],
      },
    ];

    useEffect(() => {
      setContent(sidebarContent);
    }, []);

    return (
      <SidebarWrapper noHeader>
        <div className={styles.wrapper}>
          <div className={styles.topWrapper}>
            <h2 className={styles.title}>Workspace members</h2>
            <p className={styles.name}>{account.name}</p>
          </div>
          <div className={styles.searchrow}>
            <div className={styles.search}>
              <Field
                white
                small
                noBorder
                deleteIcon
                icon="fas fa-search"
                type="text"
                name="search"
                value={debouncedValue || ""}
                setValue={setDebouncedValue}
                placeholder="Search members"
                autoComplete="off"
                onChange={(e) => setDebouncedValue(e.target.value)}
              />
            </div>
            <Button
              bStyle={["primary", "small"]}
              type="button"
              onClick={() => updateModal("invite")}
            >
              <Icon icon="AddUser" marginRight />
              Invite admins
            </Button>
          </div>
          <UsersList account={account} searchValue={searchValue} />
          <Modal
            closeTimeoutMS={350}
            isOpen={openModal === "invite"}
            onRequestClose={() => updateModal("")}
            shouldCloseOnOverlayClick={overlayClose}
            shouldCloseOnEsc={overlayClose}
            className={styles.modal}
          >
            <Invite
              account={account}
              updateModal={updateModal}
              setOverlayClose={setOverlayClose}
            />
          </Modal>
        </div>
      </SidebarWrapper>
    );
  }
);
