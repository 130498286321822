import React from "react";
import {
  compose,
  withState,
  withHandlers,
  withProps,
  lifecycle,
} from "recompose";
import { graphql } from "react-apollo";
import withStore from "utils/withStore";
import handleLoading from "utils/loader";
import { loader } from "graphql.macro";
import Circles from "components/Loaders/Circles";

const UPDATE_USER = loader("./updateUser5.graphql");
const occupations = [
  "Role and industry",
  "Enterprise talent acquisition",
  "Executive search",
  "Private equity / VC",
  "Business leader",
];

export default compose(
  graphql(UPDATE_USER, { name: "updateUserMutation" }),
  withStore("viewer"),
  withState("firstName", "setFirstName", (props) => props.viewer.first_name),
  withState("lastName", "setLastName", (props) => props.viewer.last_name),
  withState("occupation", "setOccupation", (props) => props.viewer.occupation),
  withState("linkedInUrl", "setLinkedInUrl", (props) => props.viewer.linkedIn),
  withState(
    "companySize",
    "setCompanySize",
    (props) => props.viewer.setCompanySize
  ),

  withState(
    "showOtherOccupationField",
    "toggleOtherOccupation",
    ({ viewer: { occupation } }) =>
      occupation && !occupations.includes(occupation)
  ),
  withState(
    "otherOccupation",
    "setOtherOccupation",
    ({ viewer: { occupation } }) =>
      !occupations.includes(occupation) ? "" : occupation
  ),
  withState("fileIsDragging", "setFileIsDragging", false),
  withState("avatarFile", "setAvatarFile", ""),
  withState("avatarEditor", "toggleAvatarEditor", false),
  withState("userError", "setUserError", null),
  withProps({
    avatarEditorRef: React.createRef(),
    fileDropRef: React.createRef(),
  }),
  withHandlers({
    handleSelectOccupation:
      ({ occupation, showOtherOccupationField, toggleOtherOccupation }) =>
      ({ target: { value } }) => {
        if (value === "Other" && !showOtherOccupationField) {
          toggleOtherOccupation(true);
        } else {
          toggleOtherOccupation(false);
        }
      },
    updateUser:
      ({ updateUserMutation, setUserError, handleNextStep }) =>
      (e) => {
        e.preventDefault();
        const validLinkedInUrl = "linkedin.com";
        const first_name = e.target.first_name.value;
        const last_name = e.target.last_name.value;
        const occupation = e.target.occupation.value;
        const otherOccupation =
          e.target.otherOccupation && e.target.otherOccupation.value;
        const linkedIn = e.target.linkedIn.value;
        const companySize = e.target.companySize && e.target.companySize.value;

        if (first_name.length < 1 || last_name.length < 1) {
          setUserError("Name cannot be empty");
          return null;
        }

        if (linkedIn && !linkedIn.includes(validLinkedInUrl)) {
          setUserError("Please enter a valid LinkedIn URL.");
          return null;
        }

        e.target.reset();
        updateUserMutation({
          variables: {
            first_name,
            last_name,
            occupation: otherOccupation || occupation,
            linkedIn,
            companySize,
          },
        })
          .then((res) => {
            handleNextStep();
          })
          .catch((err) => {
            setUserError(err.message.replace("GraphQL error: ", ""));
          });
      },
    handleUploadAvatar:
      ({ toggleAvatarEditor, setAvatarFile }) =>
      ({
        target: {
          validity,
          files: [File],
        },
      }) => {
        if (validity.valid && File) {
          const reader = new FileReader();
          reader.onload = () => {
            setAvatarFile(reader.result);
          };
          reader.readAsDataURL(File);
          toggleAvatarEditor(true);
        }
      },
    handleFileDragEnter:
      ({ setFileIsDragging }) =>
      (e) => {
        e.preventDefault();
        e.stopPropagation();
        setFileIsDragging(true);
      },
    handleFileDragOver:
      ({ setFileIsDragging }) =>
      (e) => {
        e.preventDefault();
        e.stopPropagation();
        setFileIsDragging(true);
      },
    handleFileDragLeave:
      ({ setFileIsDragging }) =>
      (e) => {
        e.preventDefault();
        e.stopPropagation();
        setFileIsDragging(false);
      },
    handleFileDrop:
      ({ setAvatarFile, toggleAvatarEditor, setFileIsDragging }) =>
      (e) => {
        e.preventDefault();
        e.stopPropagation();
        const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
        const [File] = e.dataTransfer.files;
        if (validImageTypes.includes(File.type)) {
          const reader = new FileReader();
          reader.onload = () => {
            setAvatarFile(reader.result);
          };
          reader.readAsDataURL(File);
          toggleAvatarEditor(true);
          setFileIsDragging(false);
        }
      },
  }),
  handleLoading(Circles),
  lifecycle({
    componentDidMount() {
      this.props.fileDropRef.current.addEventListener(
        "dragenter",
        this.props.handleFileDragEnter
      );
      this.props.fileDropRef.current.addEventListener(
        "dragover",
        this.props.handleFileDragOver
      );
      this.props.fileDropRef.current.addEventListener(
        "dragleave",
        this.props.handleFileDragLeave
      );
      this.props.fileDropRef.current.addEventListener(
        "drop",
        this.props.handleFileDrop
      );
    },
    componentWillUnmount() {
      this.props.fileDropRef.current.removeEventListener(
        "dragenter",
        this.props.handleFileDragEnter
      );
      this.props.fileDropRef.current.removeEventListener(
        "dragover",
        this.props.handleFileDragOver
      );
      this.props.fileDropRef.current.removeEventListener(
        "dragleave",
        this.props.handleFileDragLeave
      );
      this.props.fileDropRef.current.removeEventListener(
        "drop",
        this.props.handleFileDrop
      );
    },
  })
);
