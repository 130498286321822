import React, { useEffect } from "react";
import classNames from "classnames";
import TextEditor from "components/TextEditor";
import styles from "../AboutPosition.module.scss";

export default ({ handleSave, value, setValue, saved }) => {
  useEffect(() => {
    let timer = setTimeout(() => handleSave(value), 500);

    return () => {
      clearTimeout(timer);
    };
  }, [value]);

  return (
    <>
      <div className={styles.bigWrapper}>
        <div className={styles.inputWrapper}>
          <p className={classNames(styles.autosave, saved && styles.show)}>
            Auto-saved
          </p>
          <TextEditor
            value={value}
            handleChange={setValue}
            placeholder="List 3-5 key objectives"
          />
        </div>
      </div>
    </>
  );
};
