import { withState, compose, withHandlers } from "recompose";
import { graphql, withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import { loader } from "graphql.macro";

const ADD_CANDIDATES = loader("./addCandidatesToPosition14.graphql");

export default compose(
  withRouter,
  withApollo,
  withState("isLoading", "setLoading", false),
  graphql(ADD_CANDIDATES, {
    name: "addCandidatesToPosition",
  }),
  withHandlers({
    handleAddCandidatesToPosition:
      ({ addCandidatesToPosition, updateModal, position, setLoading }) =>
      (list) => {
        setLoading(true);
        const users = list.map((i) => {
          return {
            obj_id: i.id,
            type: i.type,
            email: i.email,
            first_name: i.first_name,
            last_name: i.last_name,
            sendInvite: i.sendInvite,
          };
        });

        addCandidatesToPosition({
          variables: {
            users,
            org_id: position.parent_org_id,
            position_id: position.id,
            team_id: position.parent_team_id,
          },
        }).then((res) => {
          updateModal("");
        });
      },
  })
);
