import React from "react";
import { compose, withHandlers, withState } from "recompose";
import { withRouter } from "react-router-dom";
import Circles from "components/Loaders/Circles";
import { graphql } from "react-apollo";
import { loader } from "graphql.macro";
import handleLoading from "utils/loader";

const SEND_EVALUATION_INVITE = loader("./sendEvaluationInvite.graphql");
const REMOVE_CANDIDATE_EVALUATION_INVITE = loader(
  "./removeCandidateEvaluationInvite.graphql"
);
const POSITON_EVALUATORS = loader("./positionEvaluators.graphql");

export default compose(
  withRouter,
  withState("isLoading", "setLoading", false),
  graphql(POSITON_EVALUATORS, {
    options: (props) => ({
      variables: {
        id: parseInt(props.match.params.positionId, 10),
      },
    }),
  }),
  handleLoading(() => <Circles />),
  graphql(SEND_EVALUATION_INVITE, {
    name: "sendEvaluationInvite",
  }),
  graphql(REMOVE_CANDIDATE_EVALUATION_INVITE, {
    name: "removeEvaluationInvite",
  }),
  withHandlers({
    sendEvaluationInvites:
      ({ match: { params }, position, sendEvaluationInvite, setLoading }) =>
      async (list) => {
        setLoading(true);
        const users = list.map((i) => {
          return {
            obj_id: i.id,
            type: i.type,
            email: i.email,
          };
        });

        sendEvaluationInvite({
          variables: {
            team_id: parseInt(params.teamId, 10),
            org_id: parseInt(params.orgId, 10),
            cand_id: parseInt(params.candidateId, 10),
            position_id: parseInt(params.positionId, 10),
            users,
          },
        }).then((res) => {
          setLoading(false);
        });
      },
    handleRemoveEvaluationInvite:
      ({ removeEvaluationInvite }) =>
      async ({ invite_id }) => {
        await removeEvaluationInvite({
          variables: {
            invite_id,
          },
        });
      },
  })
);
