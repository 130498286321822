import React from "react";
import Wrapper from "components/UI/Wrapper";
import Title from "components/UI/Title";
import Subtitle from "components/UI/Subtitle";
import styles from "./Personality.module.scss";
import PersonalityChart from "./Chart";
import Dimensions from "./Dimensions";

export default ({ team }) => (
  <div className={styles.main}>
    <Wrapper>
      <Title text="Personality map" icon="fas fa-dna" />
      <Subtitle text="The behavioural characteristics of team members." />
      <Subtitle
        verySmall
        text="Understand the preferences and unique perspective of team members and find ways to diversify the team in characteristics where people might be too similar."
      />
      <PersonalityChart team={team} />
      <Dimensions team={team} />
    </Wrapper>
  </div>
);
