import React from "react"
import { Link } from "react-scroll"

export default ({ to, className, activeClass, smooth, spy, children }) => (
  <Link
    to={to}
    className={className}
    activeClass={activeClass}
    smooth={smooth}
    spy={spy}
  >
    {children}
  </Link>
)
