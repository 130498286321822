import React, { useEffect } from "react";
import Button from "components/UI/Button";
import Icon from "components/UI/Icon";
import compose from "./compose";
import Step from "./Step/index";
import styles from "./Onboarding.module.scss";
import QueryString from "query-string";

import { SET_ONBOARDING } from "store/actionTypes";

export default compose(
  ({
    location,
    data: { onboarding },
    userOnboarding: { currentStep, currentSubstep },
    SURVEYS: { surveys },
    handleNextStep,
    handlePrevStep,
    handleSkipStep,
    dispatch,
    actions,
    viewer,
    client,
  }) => {
    const stepsFromUrl = new URLSearchParams(location.search).get("step");
    let steps =
      stepsFromUrl && stepsFromUrl.split(".").map((i) => parseInt(i, 10));
    const stepsFromURLareValid = !!(
      steps &&
      onboarding.steps[steps[0]] &&
      onboarding.steps[steps[0]].blocks &&
      onboarding.steps[steps[0]].blocks[steps[1]]
    );

    if (currentStep !== 0 || currentSubstep !== 0) {
      steps = [currentStep, currentSubstep];
    } else if (!stepsFromURLareValid) {
      steps = [0, 0];
    }

    const [step, substep] = steps;

    useEffect(() => {
      const queryString = QueryString.parse(location.search);

      dispatch({
        type: SET_ONBOARDING,
        payload: {
          active: true,
          onboarding: onboarding.steps,
          currentStep: step,
          currentSubstep: substep,
          positionTemplate: queryString && queryString.positionTemplate,
        },
      });
    }, [onboarding, step, substep]);

    useEffect(() => {
      window.history.pushState(
        null,
        null,
        `${location.pathname}?step=${currentStep}.${currentSubstep}`
      );
    }, [currentStep, currentSubstep]);

    useEffect(() => {
      window.Intercom("trackEvent", "started-onboarding", {
        type: onboarding.name,
      });
      window.Intercom("update", {
        onboarding_type: onboarding.name,
      });
      if (window.heap) {
        window.heap.addUserProperties({ onboarding_type: onboarding.name });
      }
    }, []);

    const { type, blocks } = onboarding.steps[currentStep];

    return (
      <div className={styles.wrapper}>
        {(!!onboarding.steps[currentStep - 1] ||
          !!onboarding.steps[currentStep].blocks[currentSubstep - 1]) && (
          <div className={styles.backButton}>
            <Button bStyle={["none"]} onClick={handlePrevStep}>
              <div className={styles.icon}>
                <Icon icon="Arrow" />
              </div>
            </Button>
          </div>
        )}
        <Step
          actions={actions}
          type={type}
          blocks={blocks}
          handleNextStep={handleNextStep}
          handlePrevStep={handlePrevStep}
          handleSkipStep={handleSkipStep}
          viewer={viewer}
          client={client}
          surveys={surveys}
        />
      </div>
    );
  }
);
