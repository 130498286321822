import React from 'react'
import { compose, withState } from 'recompose'
import { graphql, withApollo } from 'react-apollo'
import { loader } from 'graphql.macro'
import { withRouter } from 'react-router-dom'
import handleLoading from 'utils/loader'

import Circles from 'components/Loaders/Circles'

export default compose(
  withRouter,
  withApollo,
  withState('cardOpen', 'setCardOpen', null),
  withState('newCard', 'setNewCard', null)
)
