import React from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { graphql, withApollo } from "react-apollo";
import handleLoading from "utils/loader";
import { loader } from "graphql.macro";
import scrollToTop from "utils/scrollToTop";
import FullPage from "components/Loaders/FullPage";

const USER_IN_ORG = loader("./userInOrg2.graphql");

export default compose(
  withRouter,
  withApollo,
  graphql(USER_IN_ORG, {
    options: (props) => {
      return {
        fetchPolicy: "network-only",
        variables: {
          resource_type: "user",
          resource_id: parseInt(props.match.params.userId, 10),
          org_id: parseInt(props.match.params.orgId, 10),
        },
      };
    },
  }),
  handleLoading(() => <FullPage />),
  scrollToTop
);
