import React from "react";
import classNames from "classnames";
import Popup from "reactjs-popup";
import Icon from "components/UI/Icon";
import Button from "components/UI/Button";
import DropDown from "components/UI/DropDown";
import { Link } from "react-router-dom";
import getAvatarColor from "utils/orgAvatarColor";
import compose from "./compose";
import styles from "./AvatarDropdown.module.scss";

export default compose(
  ({
    viewer,
    account,
    setAccountModal,
    handleSwitchAccount,
    close,
    logOut,
  }) => (
    <div className={styles.avatarDropdown}>
      <ul className={styles.dropdownSection}>
        <p className={styles.sectionTitle}>My profile</p>
        <li>
          <Link to="/personal">
            <Button type="button" bStyle={["none"]} onClick={() => close()}>
              <Icon icon="User" />
              My profile
            </Button>
          </Link>
        </li>
        <li>
          <Link to="/profile/settings">
            <Button type="button" bStyle={["none"]} onClick={() => close()}>
              <Icon icon="Gear" />
              Profile settings
            </Button>
          </Link>
        </li>
      </ul>
      <ul className={styles.dropdownSection}>
        <p className={styles.sectionTitle}>Workspace</p>
        {account && account.adminRights && (
          <li>
            <Link to={`/account/${account.id}/settings`}>
              <Button type="button" bStyle={["none"]} onClick={() => close()}>
                <Icon icon="Gear" />
                Manage workspace
              </Button>
            </Link>
          </li>
        )}
        <li>
          <Button
            type="button"
            bStyle={["none"]}
            onClick={() => {
              setAccountModal(true);
              close();
            }}
          >
            <Icon icon="Plus" />
            Create new workspace
          </Button>
        </li>
        <div
          className={classNames("dropdownWrapper", "nested")}
          id="dropdownMenu"
        >
          <Popup
            keepTooltipInside="body"
            on={["hover"]}
            trigger={
              <li>
                <Button type="button" bStyle={["none"]}>
                  <Icon icon="Kebab" />
                  Browse workspaces
                  <div className={styles.arrowIcon}>
                    <Icon icon="Chevron" />
                  </div>
                </Button>
              </li>
            }
            position="right bottom"
            arrow={false}
          >
            {() => (
              <DropDown>
                <div className={styles.accountsWrapper}>
                  {viewer.accounts
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((a) => (
                      <li
                        className={classNames(
                          a.name === account.name && styles.selected
                        )}
                        key={"account-" + a.id}
                      >
                        <Button
                          type="button"
                          bStyle={["none"]}
                          onClick={() => {
                            handleSwitchAccount(a.id);
                            close();
                          }}
                        >
                          <div
                            className={classNames(
                              styles.avatar,
                              styles[getAvatarColor(a)]
                            )}
                          >
                            <h5>{a.name[0].toUpperCase()}</h5>
                          </div>
                          {a.name}
                        </Button>
                      </li>
                    ))}
                </div>
              </DropDown>
            )}
          </Popup>
        </div>
      </ul>
      <ul className={styles.dropdownSection}>
        <div className={styles.logOut}>
          <Button bStyle={["none"]} type="button" onClick={(e) => logOut(e)}>
            <Icon icon="Arrow" />
            Log out
          </Button>
        </div>
      </ul>
    </div>
  )
);
