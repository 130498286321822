import React from 'react'
import Wrapper from 'components/UI/Wrapper'
import classnames from 'classnames'
import Button from 'components/UI/Button'
import Modal from 'react-modal'
import styles from './styles.module.scss'
import compose from './compose'

export default compose(
  ({
    data: {
      personalProfile: { competencies }
    },
    setSelectedComp,
    selectedComp,
    handleSave,
    isFinished,
    handleRedirect
  }) => {
    competencies.sort((a, b) => b.score - a.score)

    return (
      <Wrapper>
        <h4 className={styles.header}>
          Please choose one competency that you would like to develop
        </h4>
        <div className={styles.competencies}>
          {competencies.map(comp => (
            <div
              key={`comp-${comp.dim_id}`}
              className={classnames(
                styles.compWrapper,
                selectedComp === comp.dim_id && styles.selected
              )}
              onClick={() => setSelectedComp(comp.dim_id)}
            >
              <h4>{comp.name}</h4>
              <p>{comp.description}</p>
            </div>
          ))}
        </div>
        {selectedComp && (
          <div className={styles.footer}>
            You have selected:
            {' '}
            <strong>
              {competencies.find(comp => comp.dim_id === selectedComp).name}
            </strong>
            <Button
              bStyle={['primary', 'large']}
              type='button'
              onClick={handleSave}
            >
              Finish
            </Button>
          </div>
        )}
        <Modal
          closeTimeoutMS={350}
          onRequestClose={() => handleRedirect()}
          isOpen={isFinished}
          shouldCloseOnOverlayClick
          className={styles.modal}
        >
          <Button bStyle='close' type='button' onClick={() => handleRedirect()}>
            <i className='fal fa-times' />
          </Button>
          <div className={styles.doneWrapper}>
            <h3>Thank you for choosing your growth area.</h3>

            <p className={styles.content}>
              We will contact you soon and start your development journey!
            </p>
            <Button
              bStyle={['primary', 'large']}
              type='button'
              onClick={() => handleRedirect()}
            >
              Back to personal profile
            </Button>
          </div>
        </Modal>
      </Wrapper>
    )
  }
)
