import { withHandlers, compose } from "recompose";
import { withRouter } from "react-router-dom";
import { graphql, withApollo } from "react-apollo";
import { loader } from "graphql.macro";
import withStore from "utils/withStore";
import { SELECT_ORG } from "store/actionTypes";

const CREATE_ACCOUNT = loader("./createAccount.graphql");

export default compose(
  withRouter,
  withApollo,
  withStore("selectedOrg", "account"),
  graphql(CREATE_ACCOUNT, {
    name: "createAccount",
  }),
  withHandlers({
    handleCreateAccount:
      ({ createAccount, updateModal, dispatch, history }) =>
      ({ name }) => {
        createAccount({
          variables: {
            name,
          },
        }).then((res) => {
          window.Intercom("trackEvent", "create-account", {
            id: res.data.createAccount.id,
          });
          window.localStorage.removeItem("recentOrg");
          dispatch({ type: SELECT_ORG, payload: null });

          window.location = "/dashboard";
          updateModal("");
        });
      },
  })
);
