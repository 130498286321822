import React, { useState } from "react";
import classNames from "classnames";
import Popup from "reactjs-popup";
import Button from "components/UI/Button";
import Icon from "components/UI/Icon";
import styles from "./Attachments.module.scss";

export default ({
  file,
  handleAttachmentDownload,
  handleDeleteAttachment,
  setShowTooltip,
}) => {
  const [disabled, toggleDisabled] = useState(false);

  const iconList = {
    "image/png": "JPG",
    "image/jpeg": "JPG",
    "application/pdf": "PDF",
    "application/msword": "DOC",
    "application/vnd.oasis.opendocument.text": "DOC",
  };

  const icon = iconList[file.content_type];

  let timeout = null;

  const handleShowTooltip = (id) => {
    timeout = setTimeout(() => {
      setShowTooltip(id);
    }, 800);

    return () => clearTimeout(timeout);
  };

  const handleHideTooltip = () => {
    clearTimeout(timeout);
    setShowTooltip(null);
  };

  return (
    <div
      className={styles.fileRow}
      onMouseEnter={() => handleShowTooltip(file.id)}
      onMouseLeave={() => handleHideTooltip(null)}
    >
      <li
        onClick={() => handleAttachmentDownload(file)}
        className={classNames(disabled && styles.disabled)}
      >
        <Icon icon={icon} />
        <span className={styles.fileName}>{file.file_name}</span>
        <span className={styles.remove}>
          <Button
            bStyle={["none"]}
            disabled={disabled}
            onClick={(e) => {
              e.stopPropagation();
              toggleDisabled(true);
              handleDeleteAttachment(file);
            }}
          >
            <Icon icon="Close" />
          </Button>
        </span>
      </li>
    </div>
  );
};
