import React from "react"
import { compose, withState } from "recompose"
import { graphql } from "react-apollo"
import { loader } from "graphql.macro"
import handleLoading from "utils/loader"
import FullPage from "components/Loaders/FullPage"
import { withRouter } from "react-router-dom"

const ACCOUNT_QUERY = loader("./AccountQuery1.graphql")

export default compose(
  withRouter,
  graphql(ACCOUNT_QUERY, {
    options: (props) => {
      return {
        variables: { id: parseInt(props.match.params.accountId, 10) },
      }
    },
  }),
  handleLoading(() => <FullPage />),
  withState("overlayClose", "setOverlayClose", true),
  withState("openModal", "updateModal", "")
)
