import { compose, withState } from "recompose";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";

export default compose(
  withRouter,
  withApollo,
  withState("cardOpen", "setCardOpen", null),
  withState("newCard", "setNewCard", null)
);
