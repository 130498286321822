import React, { useState } from "react";
import classNames from "classnames";
import Popup from "reactjs-popup";
import { ReactComponent as IconExpert } from "images/icons/competencies/colored-must.svg";
import addMembersToTeamDim from "utils/addMembersToTeamDim";
import InfoPopup from "./Popup";

import styles from "./TopDimensions.module.scss";

export default ({
  topDimensions,
  dimensions,
  titles,
  emptyStates,
  highlight,
  resource,
  left,
  pos,
}) => {
  if (!dimensions) return null;

  return (
    <div
      className={classNames(
        styles.wrapper,
        left && styles.left,
        pos && styles.pos
      )}
    >
      <div className={styles.box}>
        <div className={classNames(styles.icon, styles.green)}>
          <IconExpert />
        </div>
        <div className={styles.right}>
          <h5 className={styles.listTitle}>{titles.competencies}</h5>
          {topDimensions.competencies ? (
            topDimensions.competencies.map((competency, i) => {
              return (
                <p key={`top-competency-${i}`} className={styles.listName}>
                  {competency.name}
                </p>
              );
            })
          ) : (
            <p className={styles.emptyState}>{emptyStates.competencies}</p>
          )}
        </div>
      </div>
      <div className={styles.box}>
        <div className={classNames(styles.icon, styles.primary)}>
          <i className="fas fa-bolt" />
        </div>
        <div className={styles.right}>
          <h5 className={styles.listTitle}>{titles.personality}</h5>
          {topDimensions.personality ? (
            topDimensions.personality.map((strength, i) => {
              return (
                <p key={`strength-${i}`} className={styles.listName}>
                  {strength}
                </p>
              );
            })
          ) : (
            <p className={styles.emptyState}>{emptyStates.personality}</p>
          )}
        </div>
      </div>
      <div className={styles.box}>
        <div className={classNames(styles.icon, styles.yellow)}>
          <i className="far fa-compass" />
        </div>
        <div className={styles.right}>
          <h5 className={styles.listTitle}>{titles.values}</h5>
          {topDimensions.values ? (
            topDimensions.values.map((motivator, i) => {
              return (
                <p key={`motivator-${i}`} className={styles.listName}>
                  {motivator.name}
                </p>
              );
            })
          ) : (
            <p className={styles.emptyState}>{emptyStates.values}</p>
          )}
        </div>
      </div>
    </div>
  );
};
