import { withHandlers, compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { graphql, withApollo } from 'react-apollo'
import { loader } from 'graphql.macro'

const SET_ORG_CONTRACT_AS_ACCOUNT_ADMIN = loader(
  'client/Mutations/setOrgContractAsAccountAdmin.graphql'
)

export default compose(
  withRouter,
  withApollo,
  graphql(SET_ORG_CONTRACT_AS_ACCOUNT_ADMIN, {
    name: 'setContract',
    options: () => ({
      update: () => {
        window.location.reload()
      }
    })
  }),
  withHandlers({
    handleLeaveOrg:
      ({ organization, setContract }) =>
      async () => {
        const response = await setContract({
          variables: {
            org_id: organization.id,
            roleType: 'None'
          }
        })
      }
  })
)
