import React from "react";
import {
  withState,
  compose,
  lifecycle,
  branch,
  renderComponent,
} from "recompose";
import { loader } from "graphql.macro";
import { graphql, withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import gqlLoader from "utils/loader";
import FullPageLoader from "components/Loaders/FullPage";
import purgeViewerInformationFromCache from "utils/purgeViewerInformationFromCache";
import handleFinish from "./handleFinish";
import CustomSurveyHandler from "./CustomSurveyHandler";
import withStore from "utils/withStore";
import Reminder from "./Reminder";

const SURVEY = loader("./survey16.graphql");
const FINISH_SURVEY_MUTATION = loader("./finishSurvey2.graphql");

export default compose(
  withRouter,
  withApollo,
  withStore("userOnboarding"),
  withState("loading", "setLoading", false),
  graphql(SURVEY, {
    options: (props) => {
      return {
        fetchPolicy: "network-only",
        variables: {
          id: parseInt(props.match.params.surveyId, 10),
        },
      };
    },
  }),

  withState("currentRowId", "setCurrentRowId", 0),
  graphql(FINISH_SURVEY_MUTATION, {
    name: "handleFinishMutation",
    options: (props) => {
      return {
        update: (proxy, { data: { finishSurveyMutation } }) => {
          purgeViewerInformationFromCache({
            cache: props.client.cache.data,
            survey: finishSurveyMutation,
            proxy,
          });
        },
        onError: (error) => {
          console.log("Survey error: ", error);
          if (window.rollbar) {
            window.rollbar.error(error);
          }
          return error;
        },
      };
    },
  }),
  gqlLoader(FullPageLoader),

  withState("answers", "setAnswers", (props) => {
    if (parseInt(props.match.params.surveyId, 10) !== 23) return [];
    if (props.data.survey.complete) {
      return props.data.survey.survey_rows
        .map((row) => row.dimension.answer)
        .sort((a, b) => b.answer - a.answer);
    }
    return [];
  }),
  handleFinish,
  lifecycle({
    componentWillMount() {
      try {
        const {
          data: { survey },
          location: { state },
        } = this.props;

        if (
          survey.id === 32 &&
          (!state ||
            !state.params ||
            !state.params.toolType === "effectiveness" ||
            !state.params.obj_id ||
            !state.params.obj_type_id)
        ) {
          throw new Error();
        }
      } catch (error) {
        console.log({ error });
        throw new Error("Survey 32 must be initiated from a team toolkit");
      }
    },
    componentDidUpdate(prevProps) {
      if (
        prevProps.answers.length !== this.props.answers.length &&
        this.props.answers.length === this.props.data.survey.survey_rows.length
      ) {
        this.props.handleFinishSurvey();
      }
    },
  }),
  // branch(
  //   ({ userOnboarding: { active, surveysReminderShown } }) =>
  //     active && !surveysReminderShown,
  //   renderComponent(() => <Reminder />)
  // ),
  branch(
    ({ data }) => data.survey.type === "custom",
    renderComponent(({ data: { survey } }) => (
      <CustomSurveyHandler survey={survey} />
    ))
  ),

  withState(
    "openModal",
    "setOpenModal",
    ({ data: { survey } }) => survey.modal
  ),
  withState("startModal", "setStartModal", true)
);
