import React, { useState } from "react";
import Layout from "layouts";
import Field from "components/UI/Field/index";

import InputUserAvatar from "components/UI/InputUserAvatar/index";
import Icon from "components/UI/Icon/index";
import Button from "components/UI/Button/index";

import InputGroup from "components/v2/UI/InputGroup/index";
import Invite from "components/Invite";
import AddNewPerson from "./AddNewPerson";

import compose from "./compose";
import styles from "./styles.module.scss";
import SearchTalentPool from "./SearchTalentPool/index";

const StepReviewEmployeeSelection = compose(
  ({
    match: {
      params: { org_id },
    },
    personUnderReview,
    currentStepIndex,
    orgMembers = [],
    selectedOrg,
    sessionData,
    setSessionData,
    handleAddNewPerson,
    addUserFromList,
    nameOrEmail,
    setNameOrEmail,
  }) => {
    return (
      <Layout title="Select a person" icon="User">
        <div className={styles.underText}>
          Choose the person you would like to assess
        </div>
        <div className={styles.selectPersonForm}>
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}
          >
            {" "}
            {sessionData.employee_under_review?.obj_id ? (
              <div className={styles.userRow}>
                <InputUserAvatar user={sessionData.employee_under_review} />
                <Button
                  bStyle={["none"]}
                  onClick={() =>
                    setSessionData({
                      ...sessionData,
                      employee_under_review: null,
                    })
                  }
                  type="button"
                >
                  <Icon icon="Close" size={16} />
                </Button>
              </div>
            ) : (
              <div className={styles.search}>
                <Field
                  white
                  fullWidth
                  type="text"
                  name="nameOrEmail"
                  required
                  placeholder="Add a new person"
                  value={nameOrEmail}
                  onChange={(e) => {
                    setNameOrEmail(e.target.value);
                  }}
                />
                {nameOrEmail && (
                  <SearchTalentPool
                    addUserFromList={addUserFromList}
                    sessionData={sessionData}
                    personName={nameOrEmail}
                    handleAddNewPerson={({ person }) =>
                      handleAddNewPerson({ person })
                    }
                    where={{
                      searchValue: nameOrEmail,
                      org_id: selectedOrg.id,
                      account_id: selectedOrg.account.id,
                      validationCase: "assessmentSessionEmployeeUnderReview",
                    }}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </Layout>
    );
  }
);

export default StepReviewEmployeeSelection;
