import React from "react";
import KanbanDesktop from "components/Kanban/Desktop";
import KanbanMobile from "components/Kanban/Mobile";
import SingleColumn from "./SingleColumn";
import OppositeColumn from "./OppositeColumn";
import OppositeColumnWithStatement from "./OppositeColumnWithStatement";
import ForcedRanking from "./ForcedRanking";
import ForcedRankingMobile from "./ForcedRankingMobile";

import compose from "./compose";

export default compose(
  ({
    survey,
    answers,
    currentRowId,
    handleSetAnswer,
    columns,
    setColumns,
    handleSave,
    handleDiscard,
  }) => {
    if (survey.ui_template === "singleColumn") {
      return (
        <SingleColumn
          survey={survey}
          answers={answers}
          currentRowId={currentRowId}
          handleSetAnswer={handleSetAnswer}
        />
      );
    }
    if (survey.ui_template === "oppositeColumn") {
      return (
        <OppositeColumn
          survey={survey}
          currentRowId={currentRowId}
          answers={answers}
          handleSetAnswer={handleSetAnswer}
        />
      );
    }
    if (survey.ui_template === "oppositeColumnWithStatement") {
      return (
        <OppositeColumnWithStatement
          survey={survey}
          currentRowId={currentRowId}
          answers={answers}
          handleSetAnswer={handleSetAnswer}
        />
      );
    }
    if (survey.ui_template === "forcedRanking") {
      if (window.innerWidth < 768) {
        return (
          <ForcedRankingMobile
            survey={survey}
            currentRowId={currentRowId}
            answers={answers}
            handleSetAnswer={handleSetAnswer}
            handleSave={handleSave}
          />
        );
      }
      return (
        <ForcedRanking
          survey={survey}
          currentRowId={currentRowId}
          answers={answers}
          handleSetAnswer={handleSetAnswer}
          handleSave={handleSave}
        />
      );
    }
    if (survey.ui_template === "dragdrop_dims") {
      if (window.innerWidth < 1056) {
        return (
          <KanbanMobile
            columns={columns}
            setColumns={setColumns}
            resource="survey"
            handleSave={handleSave}
            handleDiscard={handleDiscard}
            subtitle={
              survey.id === 26 &&
              "Select and prioritize nine competencies from the Pipedrive competency library that have helped you most to succeed as a professional"
            }
          />
        );
      }
      return (
        <KanbanDesktop
          columns={columns}
          setColumns={setColumns}
          resource="survey"
          handleSave={handleSave}
          handleDiscard={handleDiscard}
          subtitle={
            survey.id === 26 &&
            "Select and prioritize nine competencies from the Pipedrive competency library that have helped you most to succeed as a professional"
          }
        />
      );
    }
    return (
      <SingleColumn
        survey={survey}
        answers={answers}
        currentRowId={currentRowId}
        handleSetAnswer={handleSetAnswer}
      />
    );
  }
);
