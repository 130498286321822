import React from "react";
import classNames from "classnames";
import Button from "components/UI/Button";
import Icon from "components/UI/Icon";
import styles from "./SurveyHeader.module.scss";

export default ({ survey, history, answers, currentRowId }) => {
  if (survey.type === "Competencies survey" && window.innerWidth < 1056)
    return null;

  const progress = parseInt(
    (100 * answers.length) / survey.survey_rows.length,
    10
  );

  return (
    <div
      className={classNames(
        styles.wrapper,
        survey.type === "Competencies survey" &&
          window.innerWidth > 1056 &&
          "surveyHeaderMargin"
      )}
    >
      <div className={styles.backButton}>
        <Button bStyle={["none"]} onClick={() => history.goBack()}>
          <div className={styles.icon}>
            <Icon icon="Arrow" />
          </div>
        </Button>
      </div>
      <div className={styles.middle}>
        <p>{`Question ${currentRowId + 1} out of ${
          survey.survey_rows.length
        }`}</p>
        <div className={styles.progressBar}>
          <div className={styles.progress} style={{ width: `${progress}%` }} />
        </div>
      </div>
    </div>
  );
};
