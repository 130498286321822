import React from "react";
import classNames from "classnames";
import QueryString from "query-string";
import IENotSupported from "components/UI/IENotSupported";
import Select from "components/UI/Select";
import { Field } from "react-final-form";
import Modal from "react-modal";
import Button from "components/UI/Button";
import NewTeam from "components/Modals/NewTeam";
import compose from "./compose";

import styles from "./Form.module.scss";

export default compose(
  ({
    history,
    data,
    isIE,
    setSelectedTeam,
    updateModal,
    modal,
    selectedTeam,
    selectedTemplate,
    formStep,
  }) => {
    const { name } = QueryString.parse(history.location.search);

    const getTitle = () => {
      if (formStep === 0) {
        if (selectedTemplate) {
          return "Use scorecard";
        }
        return "Create new position";
      } else if (formStep === 1) {
        if (selectedTemplate) {
          return "Use scorecard";
        }
        return "Choose scorecard";
      }
    };

    const getSubtitle = () => {
      if (formStep === 0) {
        if (selectedTemplate) {
          return "Link the hiring scorecard to a team where the new employee will work most of the time.";
        }
        return "Link the new position to a team where the new employee will work most of the time.";
      } else if (formStep === 1) {
        if (selectedTemplate) {
          return "Link the hiring scorecard to a team where the new employee will work most of the time.";
        }
        return "All scorecards include pre-filled key objectives, position requirements, and competencies needed to be successful.";
      }
    };

    return (
      <React.Fragment>
        {!isIE ? (
          <div className={styles.wrapper}>
            <div
              className={classNames(
                styles.topStuff,
                formStep === 1 && styles.noPadding
              )}
            >
              <h4 className={styles.title}>{getTitle()}</h4>
              <p className={styles.subtitle}>{getSubtitle()}</p>
              {formStep === 1 ? (
                <p></p>
              ) : (
                <>
                  <Field
                    name="template"
                    component="input"
                    type="number"
                    defaultValue={selectedTemplate}
                    autoComplete="off"
                    hidden
                  />
                  <div className={styles.inputRow}>
                    <p className={styles.label}>Position title</p>
                    <Field
                      name="name"
                      component="input"
                      type="text"
                      placeholder="Enter position title"
                      initialValue={name}
                      autoComplete="off"
                      required
                    />
                  </div>
                  <div className={styles.inputRow}>
                    <p className={styles.label}>Select team</p>
                    {data.team ||
                      (data.__typename === "TeamType" && (
                        <Select
                          cStyle="fullWidth"
                          defaultValue={data.team?.name || data.name || ""}
                          disabled
                        >
                          <option>{data.team?.name || data.name}</option>
                        </Select>
                      ))}
                    {data.organization && data.__typename !== "TeamType" && (
                      <>
                        <Select
                          name="teamSelect"
                          cStyle="fullWidth"
                          value={selectedTeam || -1}
                          onChange={(e) => {
                            setSelectedTeam(e.target.value);
                          }}
                          required
                        >
                          <optgroup label="Select team">
                            <option value={-1} disabled>
                              Select team
                            </option>
                            {data.organization.teams.map((team) => (
                              <option key={team.id} value={team.id}>
                                {team.name}
                              </option>
                            ))}
                          </optgroup>
                        </Select>
                        <div className={styles.mainButton}>
                          <Button
                            type="button"
                            bStyle={["newsimple", "nopadding"]}
                            onClick={() => updateModal({ open: true })}
                          >
                            Create a new team
                          </Button>
                        </div>
                        <Modal
                          isOpen={modal.open}
                          onRequestClose={() => updateModal({ open: false })}
                          shouldCloseOnOverlayClick
                          className={classNames(styles.modal, "create")}
                        >
                          <Button
                            bStyle="close"
                            type="button"
                            onClick={() => updateModal({ open: false })}
                          >
                            <i className="fal fa-times" />
                          </Button>
                          <NewTeam
                            organization={data.organization}
                            data={data}
                            noReroute
                            setTeam={setSelectedTeam}
                            updateModal={updateModal}
                          />
                        </Modal>
                      </>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        ) : (
          <IENotSupported />
        )}
      </React.Fragment>
    );
  }
);
