import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import { ReactComponent as EditIcon } from "images/icons/Edit.svg";
import { ReactComponent as LinkIcon } from "images/icons/Link.svg";
import { ReactComponent as InfoIcon } from "images/icons/Info.svg";
import { ReactComponent as DeleteIcon } from "images/icons/delete.svg";
import { ReactComponent as CompareIcon } from "images/icons/Layout-compare.svg";
import { ReactComponent as PrintIcon } from "images/icons/Print.svg";
import { ReactComponent as PlusIcon } from "images/icons/Add.svg";
import { ReactComponent as UserIcon } from "images/icons/User.svg";
import { ReactComponent as AddUserIcon } from "images/icons/User-add.svg";
import { ReactComponent as UsersIcon } from "images/icons/Users.svg";
import { ReactComponent as FlagIcon } from "images/icons/Flag.svg";
import { ReactComponent as CloseIcon } from "images/icons/Close.svg";
import { ReactComponent as ClipboardIcon } from "images/icons/Clipboard.svg";
import { ReactComponent as SignpostIcon } from "images/icons/Open-position.svg";
import { ReactComponent as StarIcon } from "images/icons/Star.svg";
import { ReactComponent as GearIcon } from "images/icons/Gear.svg";
import { ReactComponent as BuildingIcon } from "images/icons/Building.svg";
import { ReactComponent as BulbIcon } from "images/icons/Bulb.svg";
import { ReactComponent as HelpIcon } from "images/icons/Help.svg";
import { ReactComponent as ChatIcon } from "images/icons/Chat.svg";
import { ReactComponent as ArrowIcon } from "images/icons/arrow-right.svg";
import { ReactComponent as ChevronIcon } from "images/icons/chevron.svg";
import { ReactComponent as ChevronPrimaryIcon } from "images/icons/ChevronPrimary.svg";
import { ReactComponent as CheckIcon } from "images/icons/Check-small.svg";
import { ReactComponent as CheckLargeIcon } from "images/icons/Check.svg";
import { ReactComponent as HouseIcon } from "images/icons/House.svg";
import { ReactComponent as WarningIcon } from "images/icons/Warning.svg";
import { ReactComponent as ProgressIcon } from "images/icons/Progress.svg";
import { ReactComponent as LogoIcon } from "images/icons/Logo.svg";
import { ReactComponent as MailIcon } from "images/icons/Mail.svg";
import { ReactComponent as BellIcon } from "images/icons/bell.svg";
import { ReactComponent as EllipsisIcon } from "images/icons/Ellipsis.svg";
import { ReactComponent as KebabIcon } from "images/icons/Kebab.svg";
import { ReactComponent as KebabVerticalIcon } from "images/icons/KebabVertical.svg";
import { ReactComponent as RestartIcon } from "images/icons/Time-back.svg";
import { ReactComponent as PlayIcon } from "images/icons/Play.svg";
import { ReactComponent as LinkedInIcon } from "images/icons/LinkedIn.svg";
import { ReactComponent as DragIcon } from "images/icons/DragIcon.svg";
import { ReactComponent as PDFIcon } from "images/icons/PDF.svg";
import { ReactComponent as JPGIcon } from "images/icons/jpg-3191211.svg";
import { ReactComponent as PPTXIcon } from "images/icons/pptx-3191194.svg";
import { ReactComponent as DOCIcon } from "images/icons/doc-3191212.svg";
import { ReactComponent as KanbanIcon } from "images/icons/Layout-columns.svg";
import { ReactComponent as BillingIcon } from "images/icons/Billing.svg";
import { ReactComponent as SearchIcon } from "images/icons/Search.svg";
import { ReactComponent as ThumbIcon } from "images/icons/Thumb-Up.svg";
import { ReactComponent as PhoneIcon } from "images/icons/Phone.svg";
import { ReactComponent as EmailIcon } from "images/icons/Email.svg";
import { ReactComponent as CompClusterIcon } from "images/icons/competencyCluster.svg";
import { ReactComponent as CopyIcon } from "images/icons/Copy.svg";
import { ReactComponent as RefreshIcon } from "images/icons/Refresh.svg";
import { ReactComponent as MagicwandIcon } from "images/icons/Magicwand.svg";
import { ReactComponent as OpenessIcon } from "images/icons/Openess.svg";
import { ReactComponent as FlowerIcon } from "images/icons/Flower.svg";
import { ReactComponent as MuscleIcon } from "images/icons/Muscle.svg";
import { ReactComponent as IdeaIcon } from "images/icons/Idea.svg";
import { ReactComponent as SplitLinesIcon } from "images/icons/SplitLines.svg";
import { ReactComponent as FullscreenIconPurple } from "images/icons/FullscreenPurple.svg";
import { ReactComponent as FullscreenIconMinimizePurple } from "images/icons/FullscreenMinimizePurple.svg";
import { ReactComponent as ChevronIconPurple } from "images/icons/ChevronPurple.svg";
import { ReactComponent as SendIcon } from "images/icons/Send.svg";
import { ReactComponent as SendEmptyIcon } from "images/icons/SendEmpty.svg";
import { ReactComponent as FullscreenIcon } from "images/icons/Fullscreen.svg";
import { ReactComponent as LockIcon } from "images/icons/Lock.svg";
import { ReactComponent as BusinessmanIcon } from "images/icons/Businessman.svg";
import { ReactComponent as PlantIcon } from "images/icons/Plant.svg";
import { ReactComponent as MasksIcon } from "images/icons/Masks.svg";
import { ReactComponent as ToolsAssesmentIcon } from "images/icons/ToolsAssesment.svg";
import { ReactComponent as Assesment360Icon } from "images/icons/360Assesments.svg";
import { ReactComponent as ProfileSummaryIcon } from "images/icons/ProfileSummary.svg";
import { ReactComponent as SessionIcon } from "images/icons/Session.svg";
import { ReactComponent as DownloadIcon } from "images/icons/Download.svg";
import { ReactComponent as ExtraversionIcon } from "images/icons/Extraversion.svg";
import { ReactComponent as MinusIcon } from "images/icons/Minus.svg";
import { ReactComponent as FileIcon } from "images/icons/File.svg";
import { ReactComponent as HighRankIcon } from "images/icons/HighRank.svg";
import { ReactComponent as MediumRankIcon } from "images/icons/MediumRank.svg";
import { ReactComponent as LowRankIcon } from "images/icons/LowRank.svg";
import { ReactComponent as AtomIcon } from "images/icons/Atom.svg";
import { ReactComponent as ChatBubbleIcon } from "images/icons/ChatBubble.svg";
import { ReactComponent as WindowIcon } from "images/icons/Window.svg";
import { ReactComponent as DatabaseIcon } from "images/icons/Database.svg";
import { ReactComponent as Circle } from "images/icons/Circle.svg";
import { ReactComponent as Bolt } from "images/icons/Bolt.svg";

import styles from "./Icon.module.scss";

const Icon = ({
  icon,
  marginRight,
  large,
  epic,
  white,
  purple,
  primary,
  color,
}) => (
  // TODO: Fix color handling for icons
  <span
    className={classNames(
      styles.icon,
      marginRight && styles.marginRight,
      white && styles.white,
      purple && styles.white,
      primary && styles.primary,
      large && styles.large,
      epic && styles.epic,
      styles[icon]
    )}
  >
    {icon === "Edit" && <EditIcon />}
    {icon === "Link" && <LinkIcon />}
    {icon === "Info" && <InfoIcon />}
    {icon === "Delete" && <DeleteIcon />}
    {icon === "Compare" && <CompareIcon />}
    {icon === "Print" && <PrintIcon />}
    {icon === "User" && <UserIcon />}
    {icon === "AddUser" && <AddUserIcon />}
    {icon === "Users" && <UsersIcon />}
    {icon === "Flag" && <FlagIcon />}
    {icon === "Close" && <CloseIcon />}
    {icon === "Clipboard" && <ClipboardIcon />}
    {icon === "Plus" && <PlusIcon />}
    {icon === "Signpost" && <SignpostIcon />}
    {icon === "Star" && <StarIcon />}
    {icon === "Gear" && <GearIcon />}
    {icon === "Building" && <BuildingIcon />}
    {icon === "Bulb" && <BulbIcon />}
    {icon === "Help" && <HelpIcon />}
    {icon === "Chat" && <ChatIcon />}
    {icon === "Arrow" && <ArrowIcon />}
    {icon === "Chevron" && <ChevronIcon />}
    {icon === "ChevronPrimary" && <ChevronPrimaryIcon />}
    {icon === "Check" && <CheckIcon />}
    {icon === "CheckLarge" && <CheckLargeIcon />}
    {icon === "House" && <HouseIcon />}
    {icon === "Warning" && <WarningIcon />}
    {icon === "Progress" && <ProgressIcon />}
    {icon === "Logo" && <LogoIcon />}
    {icon === "Mail" && <MailIcon />}
    {icon === "Bell" && <BellIcon />}
    {icon === "Ellipsis" && <EllipsisIcon />}
    {icon === "Kebab" && <KebabIcon />}
    {icon === "KebabVertical" && <KebabVerticalIcon />}
    {icon === "Restart" && <RestartIcon />}
    {icon === "Play" && <PlayIcon />}
    {icon === "LinkedIn" && <LinkedInIcon />}
    {icon === "Drag" && <DragIcon />}
    {icon === "PDF" && <PDFIcon />}
    {icon === "Kanban" && <KanbanIcon />}
    {icon === "JPG" && <JPGIcon />}
    {icon === "PPTX" && <PPTXIcon />}
    {icon === "DOC" && <DOCIcon />}
    {icon === "Billing" && <BillingIcon />}
    {icon === "Search" && <SearchIcon />}
    {icon === "Thumb" && <ThumbIcon />}
    {icon === "Phone" && <PhoneIcon />}
    {icon === "Email" && <EmailIcon />}
    {icon === "CompCluster" && <CompClusterIcon />}
    {icon === "Copy" && <CopyIcon />}
    {icon === "Refresh" && <RefreshIcon />}
    {icon === "Magicwand" && <MagicwandIcon />}
    {icon === "Openess" && <OpenessIcon />}
    {icon === "Flower" && <FlowerIcon />}
    {icon === "Muscle" && <MuscleIcon />}
    {icon === "Idea" && <IdeaIcon />}
    {icon === "SplitLines" && <SplitLinesIcon />}
    {icon === "Send" && <SendIcon />}
    {icon === "SendEmtpy" && <SendEmptyIcon />}
    {icon === "Fullscreen" && <FullscreenIcon />}
    {icon === "Masks" && <MasksIcon />}
    {icon === "FullscreenPurple" && <FullscreenIconPurple />}
    {icon === "FullscreenMinimizePurple" && <FullscreenIconMinimizePurple />}
    {icon === "ChevronPurple" && <ChevronIconPurple />}
    {icon === "Businessman" && <BusinessmanIcon />}
    {icon === "Plant" && <PlantIcon />}
    {icon === "ToolsAssesment" && <ToolsAssesmentIcon />}
    {icon === "360Assesment" && <Assesment360Icon />}
    {icon === "ProfileSummary" && <ProfileSummaryIcon />}
    {icon === "Lock" && <LockIcon />}
    {icon === "Session" && <SessionIcon />}
    {icon === "Download" && <DownloadIcon />}
    {icon === "Extraversion" && <ExtraversionIcon />}
    {icon === "Minus" && <MinusIcon />}
    {icon === "File" && <FileIcon />}
    {icon === "HighRank" && <HighRankIcon />}
    {icon === "MediumRank" && <MediumRankIcon />}
    {icon === "LowRank" && <LowRankIcon />}
    {icon === "Atom" && <AtomIcon />}
    {icon === "ChatBubble" && <ChatBubbleIcon />}
    {icon === "Window" && <WindowIcon />}
    {icon === "Database" && <DatabaseIcon />}
    {icon === "Circle" && <Circle />}
    {icon === "Bolt" && <Bolt />}
  </span>
);

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  marginRight: PropTypes.bool,
  large: PropTypes.bool,
  epic: PropTypes.bool,
  white: PropTypes.bool,
  purple: PropTypes.bool,
};

export default Icon;
