import React from 'react'
import Button from 'components/UI/Button'
import compose from './compose'

import styles from './ForgotPassword.module.scss'

export default compose(
  ({
    handleSubmit, setEmailSent, emailSent, closeModal
  }) => (
    <div className={styles.wrapper}>
      {emailSent ? (
        <div className={styles.emailSent}>
          <h2 className={styles.title}>Email sent!</h2>
          <p className={styles.subtitle}>
            One more step - please check your inbox to find a link and set your
            new password.
          </p>
          <Button
            bStyle={['primary']}
            type='button'
            onClick={() => closeModal()}
          >
            I understand
          </Button>
          <p className={styles.footerText}>
            It might take a few minutes to get there. If you still didn't
            receive it, close this and try again, or contact us at
            {' '}
            <a href='mailto: support@wisnio.com'>support@wisnio.com</a>
          </p>
        </div>
      ) : (
        <>
          <div className={styles.header}>
            <div className={styles.defaultLogo} />
            <h3 className={styles.subtitle}>
              Talent analytics for winning teams
            </h3>
          </div>
          <div className={styles.resetPassword}>
            <h3 className={styles.title}>Reset password</h3>
            <p className={styles.subtitle}>
              Please enter your email address and we'll send you a link to reset
              your password.
            </p>
            <form onSubmit={handleSubmit}>
              <label htmlFor='email'>
                Email address *
                <input
                  type='email'
                  name='email'
                  placeholder='Enter email'
                  required
                />
              </label>
              <Button bStyle={['primary']} type='submit'>
                Send
              </Button>
            </form>
          </div>
        </>
      )}
    </div>
  )
)
