import React from "react";
import DropDown from "components/UI/DropDown";
import Button from "components/UI/Button";
import Icon from "components/UI/Icon";

import styles from "./SupportDropdown.module.scss";

export default () => (
  <div className={styles.helpDropdown}>
    <DropDown>
      <div className={styles.insideWrapper}>
        <div className={styles.imageWrapper}>
          <div className={styles.image} />
          <h5>Explore Wisnio support</h5>
          <p>
            Reach out to{" "}
            <a href="mailto:support@wisnio.com">support@wisnio.com</a> or learn
            more from our Help Centre
          </p>
        </div>
        <ul>
          <li>
            <a href="https://support.wisnio.com/" target="_blank">
              <Button type="button" bStyle={["none"]}>
                <Icon icon="Help" />
                Help Centre
              </Button>
            </a>
          </li>
          <li>
            <Button
              type="button"
              bStyle={["none"]}
              onClick={() => window.Intercom("show")}
            >
              <Icon icon="Chat" />
              Contact us
            </Button>
          </li>
        </ul>
      </div>
    </DropDown>
  </div>
);
