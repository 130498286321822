import React, { useState } from "react";
import Popup from "reactjs-popup";
import Button from "components/UI/Button";
import EllipsisButton from "components/UI/EllipsisButton";
import { ReactComponent as LinkIcon } from "images/icons/Link.svg";
import { ReactComponent as BellIcon } from "images/icons/bell.svg";
import Select from "components/UI/Select";
import classNames from "classnames";
import UserAvatar from "components/UserAvatar";
import DropDown from "components/UI/DropDown";

import ResendInvite from "./ResendInvite";
import SendProfileReminder from "components/SendProfileReminder";

import styles from "./MemberRow.module.scss";

const ContractTypeSelect = ({ roleTypes, user, handleChangeOrgContract }) => (
  <Select
    onChange={(e) => handleChangeOrgContract({ event: e, user, roleTypes })}
    value={user.roleType}
    noBorder
  >
    {roleTypes
      .filter((rt) => rt.name !== "candidate")
      .map((rt) => (
        <option key={rt.name} value={rt.name}>
          {rt.name.charAt(0).toUpperCase() + rt.name.slice(1)}
        </option>
      ))}
    <option key="candidate" value="candidate" disabled>
      Candidate
    </option>
  </Select>
);

export default ({
  user,
  handleChangeOrgContract,
  updateRemoveMemberModal,
  updateMemberModal,

  viewer,
  ownersLength,
}) => {
  const [isCopied, setIsCopied] = useState(false);

  const copyClipboard = (value) => {
    const tempInput = document.createElement("input");
    tempInput.value = value;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);
    setIsCopied(true);
  };

  const isInviteOrPending = () => {
    if (user.type === "invite") return true;
    if (user.type && user.type === "invite") return true;
    if (user.state && user.state === "waiting_user_acceptance") return true;
    if (user.contract && user.contract.state === "waiting_user_acceptance")
      return true;
    return false;
  };

  const roleTypes = [
    { name: "candidate", role: 1 },
    { name: "member", role: 2 },
    { name: "admin", role: 4 },
  ];

  return (
    <tr className={styles.row}>
      <td className={styles.avatarCol}>
        <div className={styles.avatar}>
          <UserAvatar
            avatarStyle={styles}
            member={user}
            isInvite={user.type === "invite"}
            size="small"
          />
        </div>
        <Button
          bStyle={["none"]}
          type="button"
          onClick={() => updateMemberModal(user)}
        >
          <div className={styles.nameAndEmail}>
            {user.name && <p className={styles.name}>{user.name}</p>}
            <p className={styles.email}>{user.email}</p>
          </div>
        </Button>
      </td>
      <td
        className={classNames(
          styles.profile,
          styles[user.profileComplete ? `complete` : `incomplete`]
        )}
      >
        <div className={styles.stateFlex}>
          {!isInviteOrPending() && (
            <p>{user.profileComplete ? `Complete` : `Incomplete`}</p>
          )}
          {!user.profileComplete && !isInviteOrPending() && (
            <div className="smallPopupWrapper">
              <Popup
                trigger={
                  <div className={styles.shareLink}>
                    <Button
                      bStyle={["none"]}
                      type="button"
                      onClick={() => {
                        copyClipboard(`${window.location.origin}/surveys`);
                      }}
                    >
                      <LinkIcon />
                    </Button>
                  </div>
                }
                className={styles.popup}
                position="top center"
                on="hover"
                arrow
                keepTooltipInside="table"
                closeOnDocumentClick
                repositionOnResize
              >
                <div className={styles.tooltipPopover}>
                  <span>
                    {isCopied ? "Copied to clipboard!" : "Copy surveys link"}
                  </span>
                </div>
              </Popup>
            </div>
          )}
        </div>
      </td>
      <td className={classNames(styles.state, styles[user.state])}>
        <div className={styles.stateFlex}>
          <p>
            {user.state === "pending"
              ? `Pending`
              : user.state.charAt(0).toUpperCase() + user.state.slice(1)}
          </p>
          {isInviteOrPending() && (
            <div className="smallPopupWrapper">
              <Popup
                trigger={
                  <div className={styles.shareLink}>
                    <Button
                      bStyle={["none"]}
                      type="button"
                      onClick={() => {
                        copyClipboard(user.inviteLink);
                      }}
                    >
                      <LinkIcon />
                    </Button>
                  </div>
                }
                className={styles.popup}
                position="top center"
                on="hover"
                arrow
                keepTooltipInside="table"
                closeOnDocumentClick
                repositionOnResize
              >
                <div className={styles.tooltipPopover}>
                  <span>
                    {isCopied ? "Copied to clipboard!" : "Copy invitation link"}
                  </span>
                </div>
              </Popup>
            </div>
          )}
        </div>
      </td>
      <td className={styles.contractType}>
        {viewer.id === user.id ||
        (ownersLength && ownersLength === 1 && user.roleType === "owner") ? (
          <>
            <Select noBorder disabled>
              <option>
                {user.roleType.charAt(0).toUpperCase() + user.roleType.slice(1)}
              </option>
            </Select>
            <div style={{ width: "12px" }} />
          </>
        ) : (
          <>
            <ContractTypeSelect
              roleTypes={roleTypes}
              user={user}
              handleChangeOrgContract={handleChangeOrgContract}
            />
          </>
        )}
      </td>
      <td className={styles.lastCol}>
        <div className="dropdownWrapper" id="dropdownMenu">
          <Popup
            keepTooltipInside="body"
            trigger={
              <div className={styles.avatarWrapper}>
                <EllipsisButton />
              </div>
            }
            position="bottom right"
            arrowStyle={{ display: "none" }}
          >
            <DropDown>
              {!user.profileComplete && !isInviteOrPending() && (
                <li>
                  <SendProfileReminder user={user} />
                </li>
              )}
              {isInviteOrPending() && (
                <li>
                  <ResendInvite invite={user} />
                </li>
              )}
              <li>
                <Button
                  type="button"
                  bStyle={["none"]}
                  onClick={() => updateRemoveMemberModal(user)}
                >
                  <i className="fas fa-user-slash" />
                  Remove
                </Button>
              </li>
            </DropDown>
          </Popup>
        </div>
      </td>
    </tr>
  );
};
