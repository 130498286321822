import React, { useState } from "react";
import classNames from "classnames";
import Button from "components/UI/Button";
import compose from "./compose";

import styles from "../Scoring.module.scss";

const spanScores = [4, 3, 2, 1, 1, 2, 3, 4];

export default compose(
  ({
    data: { leadershipSpanEvaluation },
    viewer,
    item,
    handleSaveSpanRating,
  }) => {
    const [selectedSpan, setSelectedSpan] = useState(
      leadershipSpanEvaluation &&
        leadershipSpanEvaluation.find((se) => se.created_by === viewer.id)
        ? leadershipSpanEvaluation.find((se) => se.created_by === viewer.id)
            .evaluation
        : []
    );

    const handleSelectedSpanScore = (scoreIndex) => {
      let selectedSpanClone = [...selectedSpan];

      if (selectedSpan.length === 0 || selectedSpan.length === 1) {
        if (scoreIndex > selectedSpanClone[0]) {
          selectedSpanClone.push(scoreIndex);
        } else {
          if (scoreIndex === selectedSpan[0]) {
            selectedSpanClone = [];
          } else {
            selectedSpanClone = [scoreIndex];
          }
        }
      }

      if (selectedSpan.length === 2) {
        selectedSpanClone = [scoreIndex];
      }

      setSelectedSpan(selectedSpanClone);

      if (selectedSpanClone.length === 2) {
        handleSaveSpanRating(item.span.id, selectedSpanClone);
      }
    };

    return (
      <div className={styles.scoreSpan}>
        <p className={styles.label}>{item.span.left}</p>
        <div className={styles.scores}>
          {spanScores.map((score, i) => {
            const isHighlighted = i > selectedSpan[0] && i < selectedSpan[1];
            const isHighlightFirst = isHighlighted && i === selectedSpan[0] + 1;
            const isHighlightLast = isHighlighted && i === selectedSpan[1] - 1;
            const isOnlyHighlight =
              isHighlighted &&
              i === selectedSpan[0] + 1 &&
              i === selectedSpan[1] - 1;

            return (
              <div
                key={`scoreSpanBtn-${i}`}
                className={classNames(
                  styles.button,
                  selectedSpan.includes(i) && styles.selected,
                  isHighlighted && styles.highlighted,
                  isHighlightFirst && styles.highlightedFirst,
                  isHighlightLast && styles.highlightedLast,
                  isOnlyHighlight && styles.highlightedOnly
                )}
              >
                <Button
                  bStyle={["none"]}
                  type="button"
                  onClick={() => {
                    handleSelectedSpanScore(i);
                  }}
                >
                  {score}
                </Button>
              </div>
            );
          })}
        </div>
        <p className={styles.label}>{item.span.right}</p>
      </div>
    );
  }
);
