import { combineReducers, createStore } from "redux";
import userOnboarding from "./userOnboarding";
import viewer from "./viewer";
import selectedOrg from "./selectedOrg";
import inviteStore from "./invite";
import teamdevelopment from "./teamdevelopment";
import accountLimit from "./accountLimit";
import account from "./account";
import route from "./route";

const appReducer = combineReducers({
  userOnboarding,
  viewer,
  selectedOrg,
  inviteStore,
  teamdevelopment,
  accountLimit,
  account,
  route
});

const rootReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default createStore(rootReducer);
