import React, { useState } from "react";
import Button from "components/UI/Button";
import ViewerAvatar from "components/ViewerAvatar";
import UserAvatar from "components/UserAvatar";
import Icon from "components/UI/Icon";
import classNames from "classnames";
import { Element } from "react-scroll";
import DistributionBarChart from "components/Charts/DistributionBarChart";
import SwarmPlot from "components/Charts/SwarmPlot";
import Modal from "components/Modals/Personality";
import getAlignment from "utils/getAlignment";

import styles from "./Dimensions.module.scss";

export default ({ data, team, highlight, resource, candidate, casestudy }) => {
  const [openModal, updateModal] = useState("");

  const noTeamMembers =
    (team && team.members.length === 0) ||
    (team && team.members.filter((m) => m.readyToRender).length === 0);

  const labelClass = (alignment) => {
    if (alignment === "Very diverse") {
      return styles.veryDiverse;
    }
    if (alignment === "Diverse") {
      return styles.diverse;
    }
    if (alignment === "Similar") {
      return styles.similar;
    }
    if (alignment === "Very similar") {
      return styles.verySimilar;
    }
    return null;
  };

  const getColor = (alignment) => {
    if (alignment === "Very diverse") {
      return "green";
    }
    if (alignment === "Diverse") {
      return "grey";
    }
    if (alignment === "Similar") {
      return "yellow";
    }
    if (alignment === "Very similar") {
      return "red";
    }
    return null;
  };

  const getReverseStyle = (className) => {
    if (className === "veryDiverse") {
      return styles.verySimilar;
    }
    if (className === "Diverse") {
      return styles.similar;
    }
    if (className === "verySimilar") {
      return styles.veryDiverse;
    }
    if (className === "Similar") {
      return styles.diverse;
    }
    return null;
  };

  return (
    <div className={styles.wrapper}>
      {data.map((dim) => {
        const { className, label, colour } =
          resource === "member" &&
          getAlignment({
            dim,
            team,
            teamMember: highlight,
            resource: "personality",
          });

        const strength =
          resource !== "orgUser" && highlight
            ? highlight.personality.find((d) => d.dim_id === dim.dim_id)
                .inTeamStrengths[0]
            : dim.strengths[0]
            ? dim.strengths[0]
            : null;

        const derailer =
          resource !== "orgUser" && highlight
            ? highlight.personality.find((d) => d.dim_id === dim.dim_id)
                .inTeamDerailers[0]
            : dim.derailers[0]
            ? dim.derailers[0]
            : null;

        return (
          <Element name={dim.name} key={dim.name}>
            <div
              className={classNames(styles.personalityRow, "personalityRow")}
            >
              <div className={styles.leftSide}>
                <div className={styles.rowHeader}>
                  <div className={styles.titleRow}>
                    <div className={styles.column}>
                      <h3>
                        <i className={dim.icon} />
                        {dim.name}
                      </h3>
                    </div>
                    <div className={styles.column}>
                      {resource === "member" &&
                        !casestudy &&
                        !noTeamMembers && (
                          <p
                            className={classNames(
                              styles.label,
                              styles[className]
                            )}
                          >
                            {label}
                          </p>
                        )}
                      {resource === "member" && casestudy && (
                        <p
                          className={classNames(
                            styles.label,
                            getReverseStyle(className)
                          )}
                        >
                          {label}
                        </p>
                      )}
                      {resource === "team" &&
                        team &&
                        team.members.filter(
                          (m) => m.personalityChart.readyToRender
                        ).length > 1 && (
                          <p
                            className={classNames(
                              styles.label,
                              labelClass(dim.alignment)
                            )}
                          >
                            {dim.alignment}
                          </p>
                        )}
                    </div>
                  </div>
                  <p className={styles.subtitle}>{dim.desc}</p>
                </div>
                <div className={styles.lineWrapper}>
                  {resource === "personal" || resource === "orgUser" ? (
                    <div className={styles.line}>
                      <div
                        className={styles.avatar}
                        style={{
                          left: `calc(${
                            dim.score > 94 ? 94 : dim.score
                          }% - 25px)`,
                        }}
                      >
                        {resource !== "personal" ? (
                          <UserAvatar member={highlight} size="small" />
                        ) : (
                          <ViewerAvatar size="small" />
                        )}
                      </div>
                    </div>
                  ) : (
                    <DistributionBarChart
                      dim={dim}
                      members={team && team.members}
                      highlight={highlight && highlight}
                      resource="personality"
                      candidate={candidate && candidate}
                      colour={colour}
                      labelClass={!highlight ? getColor(dim.alignment) : colour}
                      casestudy={casestudy}
                    />
                  )}
                  <div className={styles.opposites}>
                    <div className={styles.left}>
                      <h5 className={styles.label}>{dim.label_min}</h5>
                      <p className={styles.labelDesc}>{dim.description_min}</p>
                    </div>
                    <div className={styles.right}>
                      <h5 className={styles.label}>{dim.label_max}</h5>
                      <p className={styles.labelDesc}>{dim.description_max}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.rightSide}>
                <div className={styles.card}>
                  {strength && (
                    <div className={styles.motivator}>
                      <div className={styles.description}>
                        <div className={styles.titleRow}>
                          <i className="fas fa-bolt" />
                          <h5>{strength.short}</h5>
                        </div>

                        <p>{strength.long}</p>
                      </div>
                    </div>
                  )}
                  {derailer && (
                    <div className={styles.derailer}>
                      <div className={styles.description}>
                        <div className={styles.titleRow}>
                          <i className="fas fa-exclamation-triangle" />
                          <h5>{derailer.short}</h5>
                        </div>

                        <p>{derailer.long}</p>
                      </div>
                    </div>
                  )}

                  <div
                    className={classNames(
                      styles.more,
                      "personalityRowMoreInfo"
                    )}
                  >
                    <Button
                      type="button"
                      dataTour="1"
                      bStyle={["none", "large"]}
                      onClick={() => updateModal(dim)}
                    >
                      {`More on ${dim.name}`}
                    </Button>
                  </div>
                </div>
                {resource !== "orgUser" &&
                  highlight &&
                  highlight.personality.find((d) => d.name === dim.name)
                    .socDes &&
                  highlight.personality.find((d) => d.name === dim.name).socDes
                    .length > 0 && (
                    <div className={styles.card}>
                      <div className={styles.motivator}>
                        <div className={styles.description}>
                          <div className={styles.titleRow}>
                            <Icon icon="Bulb" marginRight />
                            <h5>Self-awareness check</h5>
                          </div>
                          <p>
                            Use the interview questions to validate whether the
                            candidate has given a fair representation of their
                            behavioural traits and is able to evaluate their
                            strengths and potential blind spots objectively.
                          </p>
                        </div>
                      </div>
                      <div className={styles.more}>
                        <Button
                          type="button"
                          bStyle={["none", "large"]}
                          onClick={() => updateModal(dim)}
                        >
                          See interview questions
                        </Button>
                      </div>
                    </div>
                  )}
              </div>
            </div>
          </Element>
        );
      })}
      <Modal
        team={team}
        data={data}
        dim={data.find((d) => d.name === openModal.name)}
        members={team && team.members}
        highlight={highlight}
        openModal={openModal}
        candidate={candidate && candidate}
        updateModal={updateModal}
        resource={resource}
        casestudy={casestudy}
      />
    </div>
  );
};
