import React from "react";
import classNames from "classnames";
import Button from "components/UI/Button";
import Icon from "components/UI/Icon";

import styles from "./Field.module.scss";

export default ({
  type,
  name,
  required,
  value,
  setValue,
  onChange,
  placeholder,
  autoComplete,
  small,
  large,
  noBorder,
  white,
  deleteIcon,
  autoFocus,
  fullWidth,
  paddingRight,
  icon,
  active,
  disabled,
  onKeyDown,
}) => (
  <div
    className={classNames(
      styles.wrapper,
      small && styles.small,
      large && styles.large,
      white && styles.white,
      fullWidth && styles.fullWidth,
      paddingRight && styles.paddingRight,
      icon && styles.icon,
      active && styles.active,
      noBorder && styles.noBorder,
      value && value.length > 0 && styles.writing,
      disabled && styles.disabled
    )}
  >
    {icon && (
      <div className={styles.icon}>
        <i className={icon} />
      </div>
    )}
    <input
      key={name}
      type={type}
      name={name}
      value={value}
      onChange={onChange}
      required={required}
      placeholder={placeholder}
      autoComplete={autoComplete}
      autoFocus={autoFocus}
      disabled={disabled}
      onKeyDown={onKeyDown}
    />
    {deleteIcon && value && value.length > 0 && (
      <div className={styles.delete}>
        <Button bStyle={["none"]} type="button" onClick={() => setValue("")}>
          <Icon icon="Close" />
        </Button>
      </div>
    )}
  </div>
);
