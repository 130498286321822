import React from "react";
import classNames from "classnames";
import ModalTitle from "components/UI/ModalTitle";

import styles from "./HoganScoresModal.module.scss";

export default ({
  candidate,
  test,
  handleUpsertCandidateAccountDimScore,
  handleDeleteCandidateAccountDimScore,
}) => {
  let debounced = null;

  const handleSaveDimScore = (score, dim_id) => {
    if (debounced) {
      clearTimeout(debounced);
    }

    debounced = setTimeout(() => {
      if (score) {
        handleUpsertCandidateAccountDimScore(score, dim_id);
      } else {
        handleDeleteCandidateAccountDimScore(dim_id);
      }
    }, 200);

    return () => clearTimeout(debounced);
  };

  return (
    <div className={styles.hoganScores}>
      <div className={styles.modalHead}>
        <div className={styles.title}>
          <ModalTitle icon="Window" color="grey">
            {test.groupTitle}
          </ModalTitle>
        </div>
        <p>Add scores from the test to the candidate's Wisnio profile</p>
      </div>
      {test.groups.map((group) => (
        <>
          <h5 className={styles.dimGroup}>{group.dimGroup}</h5>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>
                  <p>Dimension</p>
                </th>
                <th>
                  <p>Score</p>
                </th>
              </tr>
            </thead>
            <tbody>
              {group.dimensions.map((dim) => (
                <tr key={"Dim-" + dim.dim_id}>
                  <td>
                    <p>{dim.name}</p>
                  </td>
                  <td
                    className={classNames(
                      styles.inputCell,
                      !dim.score && styles.empty
                    )}
                  >
                    <input
                      type="number"
                      onChange={(e) =>
                        handleSaveDimScore(e.target.value, dim.dim_id)
                      }
                      defaultValue={dim.score && dim.score}
                    ></input>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      ))}
    </div>
  );
};
