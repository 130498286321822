import React from 'react'
import { DragDropContext } from 'react-beautiful-dnd'
import styles from './GroupGoals.module.scss'
import NewGroupDroppable from './NewGroupDroppable'
import GroupDroppable from './GroupDroppable'
import GoalsDroppable from './GoalsDroppable'

import compose from './compose'

export default compose(
  ({
    ungroupedGoals,
    teamGoalGroups,
    isOpen,
    onDragEnd,
    handleUpdateTeamGoalGroup,
    handleDeleteTeamGoalGroup,
    TEAM_GOALS: { teamGoals },
    titles,
    setTitles
  }) => (
    <div className={styles.wrapper}>
      <p className={styles.subtitle}>
        Review all the goals in the table below and group them into thematically
        similar ones. Agree on a common name for each goal group.
      </p>
      <DragDropContext onDragEnd={onDragEnd}>
        <div className={styles.listBoxes}>
          {teamGoalGroups
            && teamGoalGroups.map(group => (
              <GroupDroppable
                key={`goal-group-${group.id}`}
                group={group}
                handleUpdateTeamGoalGroup={handleUpdateTeamGoalGroup}
                handleDeleteTeamGoalGroup={handleDeleteTeamGoalGroup}
                isOpen={isOpen}
                teamGoals={teamGoals}
                titles={titles}
                setTitles={setTitles}
              />
            ))}
        </div>
        <NewGroupDroppable isOpen={isOpen} />
        <GoalsDroppable goals={ungroupedGoals} />
      </DragDropContext>
    </div>
  )
)
