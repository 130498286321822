import {
  withHandlers,
  compose,
  withState,
  lifecycle,
  branch,
  renderComponent
} from 'recompose'
import { withRouter } from 'react-router-dom'
import React from 'react'
import Circles from 'components/Loaders/Circles'
import axios from 'utils/axios'
import {
  validatePasswordResetSecret,
  updatePasswordRoute
} from 'client/authRoutes'

export default compose(
  withRouter,
  withState('loading', 'setLoading', true),
  withState('secretIsValid', 'setSecretIsValid', false),

  lifecycle({
    componentDidMount() {
      axios
        .post(validatePasswordResetSecret, {
          secret: this.props.match.params.resetSecret
        })
        .then(({ data: { passwordResetSecretIsValid } }) => {
          this.props.setSecretIsValid(passwordResetSecretIsValid)
          this.props.setLoading(false)
        })
    }
  }),
  branch(
    ({ loading }) => loading && true,
    renderComponent(() => (
      <div style={{ height: '100vh' }}>
        <Circles />
      </div>
    ))
  ),
  withState('pwError', 'setPwError', null),
  withState('pwSuccess', 'setPwSuccess', false),
  withHandlers({
    handleChangePassword:
      ({ setPwError, setPwSuccess, match, setSecretIsValid }) =>
      ({ password, repeatpassword }) => {
        axios
          .post(updatePasswordRoute, {
            secret: match.params.resetSecret,
            new_password: password,
            repeat_new_password: repeatpassword
          })
          .then(({ data: { success, message } }) => {
            if (success) {
              setPwSuccess(true)
            } else {
              if (message === 'Invalid secret!')
                return setSecretIsValid(false)
              return setPwError(message)
            }
          })
      }
  })
)
