import React from "react"
import { compose, withState } from "recompose"
import { graphql } from "react-apollo"
import { withRouter } from "react-router-dom"
import { loader } from "graphql.macro"
import handleLoading from "utils/loader"
import withStore from "utils/withStore"
import FullPage from "components/Loaders/FullPage"

const ACCOUNT_QUERY = loader("./AccountQuery6.graphql")

export default compose(
  withRouter,
  withStore("viewer"),
  withState("subscribeModal", "setSubscribeModal", false),
  graphql(ACCOUNT_QUERY, {
    options: (props) => ({
      variables: { id: parseInt(props.match.params.accountId, 10) },
    }),
  }),
  handleLoading(() => <FullPage />)
)
