import React, { useState } from "react";
import ValuesChart from "components/Charts/Values";
import ValuesModal from "components/Modals/Value";
import ValuesPlaceholder from "components/Placeholders/ValuesPlaceholder";
import TeamMember from "./TeamMember";

import styles from "./Chart.module.scss";

export default ({ team }) => {
  const [memberHover, setMemberHover] = useState();
  const [openModal, updateModal] = useState({});

  const determineIfValueIsImportant = () => {
    if (!openModal.name) return false;
    return (
      team.values
        .filter((d) => d.groupId === "team_value_facets")
        .sort((a, b) => b.score - a.score)
        .slice(0, 3)
        .find((d) => d.name === openModal.name) && true
    );
  };
  if (
    team.readyToRender &&
    team.members.some((m) => m.readyToRender) &&
    team.members.length > 0
  ) {
    return (
      <div className={styles.wrap}>
        <ValuesChart
          data={team}
          valuesChart={team.valuesChart}
          values={team.values.filter((v) => v.groupId === "team_value_facets")}
          members={team.members}
          isMemberHover={memberHover && memberHover}
          resource="team"
          team={team}
          updateModal={updateModal}
        />

        <div className={styles.avatarsWrap}>
          {team.members.map((member) => (
            <TeamMember
              key={member.id}
              member={member}
              userId={member.id}
              memberHover={memberHover}
              setMemberHover={setMemberHover}
            />
          ))}
        </div>
        <ValuesModal
          resource="team"
          valueGroups={team.valuesChart.valueGroups}
          values={team.values.filter((v) => v.groupId === "team_value_facets")}
          dim={openModal}
          updateModal={updateModal}
          isImportant={determineIfValueIsImportant()}
          members={team.members}
          team={team}
        />
      </div>
    );
  }
  return <ValuesPlaceholder resource="team" />;
};
