import React, { useState } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import Button from "components/UI/Button";
import Icon from "components/UI/Icon";
import { useSidebar } from "utils/sidebarProvider";
import OrgSelect from "./OrgSelect";
import ScrollLink from "./ScrollLink";

import styles from "./Pane.module.scss";

export default ({
  collapsed,
  account,
  pageTitle,
  viewer,
  selectedOrg,
  invites,
  whitelabel,
}) => {
  const { contentContext } = useSidebar();
  const [content, setContent] = contentContext;

  const [isDropped, setIsDropped] = useState([]);

  const isRR =
    selectedOrg.id === parseInt(process.env.REACT_APP_RUSSELL_DEMO_ORG, 10);

  let contentFiltered = content;

  if (isRR && pageTitle === "Candidate") {
    pageTitle = "Candidate profile";
    contentFiltered = [];
  }

  if (isRR && pageTitle === "Position") {
    pageTitle = "CEO Assessment";
  }

  const mouseEnter = (page) => {
    if (pageTitle === "Dashboard" || pageTitle === "Settings") {
      if (document.getElementsByClassName(page)[0]) {
        document
          .getElementsByClassName(page)[0]
          .classList.add(styles.iconActive);
      }
      if (page === "Learning Centre") {
        document.getElementsByClassName(page)[0].classList.add(styles.primary);
      }
    }
  };

  const mouseLeave = (page) => {
    (pageTitle === "Dashboard" || pageTitle === "Settings") &&
      document.getElementsByClassName(page)[0] &&
      document
        .getElementsByClassName(page)[0]
        .classList.remove(styles.iconActive);
  };

  return (
    <div
      className={classNames(
        styles.pane,
        whitelabel && styles.whitelabel,
        collapsed && styles.collapsed
      )}
    >
      <div className={styles.top}>
        <div className={styles.orgSelect}>
          <OrgSelect
            viewer={viewer}
            selectedOrg={selectedOrg}
            whitelabel={whitelabel}
          />
        </div>
        <div className={styles.section}>
          <div className={classNames(styles.navTitle, styles.pageTitle)}>
            <h4>{pageTitle}</h4>
          </div>
        </div>
        <div
          className={classNames(
            styles.content,
            whitelabel && styles.whitelabel
          )}
        >
          {contentFiltered.length > 0 &&
            contentFiltered.constructor === Array &&
            contentFiltered.map((section, index) => (
              <div className={styles.section} key={"section-" + index}>
                {section.sectionTitle && (
                  <p className={styles.sectionTitle}>{section.sectionTitle}</p>
                )}
                {section.items &&
                  section.items.length > 0 &&
                  section.items
                    .filter((i) => i)
                    .map((item) => (
                      <div
                        key={"navItem-" + Math.random(10)}
                        className={classNames(
                          styles.navTitle,
                          styles[item.style],
                          item.sticky && styles.sticky
                        )}
                        onMouseEnter={() => mouseEnter(item.title)}
                        onMouseLeave={() => mouseLeave(item.title)}
                      >
                        <div className={styles.backdrop}></div>
                        <div className={styles.left}>
                          <Button bStyle={["none"]} onClick={item.action}>
                            {item.initial && (
                              <div
                                className={classNames(
                                  styles.avatar,
                                  styles[item.color]
                                )}
                              >
                                {item.initial}
                              </div>
                            )}
                            {item.icon && (
                              <div className={styles.icon}>
                                <Icon icon={item.icon} />
                              </div>
                            )}
                            {item.dot && (
                              <div
                                className={classNames(
                                  styles.dot,
                                  styles[item.dot]
                                )}
                              ></div>
                            )}
                            {!item.scrollTo && item.children && (
                              <p className={styles.dropdownTitle}>
                                {item.title}
                                {(item.amount === 0 || item.amount) && (
                                  <span className={styles.amount}>
                                    {`(${item.amount})`}
                                  </span>
                                )}
                              </p>
                            )}
                            {!item.scrollTo &&
                              !item.children && ( // default
                                <p
                                  className={classNames(
                                    styles.default,
                                    styles[item.style],
                                    "Label-" + item.title
                                  )}
                                >
                                  {item.title}
                                  {(item.amount === 0 || item.amount) && (
                                    <span className={styles.amount}>
                                      {`(${item.amount})`}
                                    </span>
                                  )}
                                </p>
                              )}
                            {item.scrollTo && (
                              <ScrollLink
                                to={item.title.toLowerCase()}
                                className={styles.link}
                                activeClass={styles.activeLink}
                                smooth
                                spy
                              >
                                {item.title}
                              </ScrollLink>
                            )}
                          </Button>
                        </div>
                        {item.children && item.children.length > 0 && (
                          <div
                            className={classNames(
                              styles.dropdownIcon,
                              isDropped.includes(item.title) && styles.down
                            )}
                          >
                            <Button
                              bStyle={["none"]}
                              onClick={() =>
                                !isDropped.includes(item.title)
                                  ? setIsDropped([...isDropped, item.title])
                                  : setIsDropped(
                                      [...isDropped].filter(
                                        (e) => e !== item.title
                                      )
                                    )
                              }
                            >
                              <Icon icon="Chevron" />
                            </Button>
                          </div>
                        )}
                        {item.children && isDropped.includes(item.title) && (
                          <div
                            className={classNames(
                              styles.dropdown,
                              styles[item.style]
                            )}
                          >
                            {item.children.map((child) => (
                              <div
                                className={classNames(
                                  styles.dropdownItem,
                                  styles[child.style]
                                )}
                              >
                                <Button
                                  key={"navChild-" + child.title}
                                  bStyle={["none"]}
                                  onClick={child.action}
                                >
                                  {child.title}
                                </Button>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    ))}
              </div>
            ))}
        </div>
      </div>
      <div className={styles.bottom}>
        <div className={styles.section}>
          <div
            className={styles.navTitle}
            onMouseEnter={() => mouseEnter("Personal")}
            onMouseLeave={() => mouseLeave("Personal")}
          >
            <div className={styles.left}>
              <Link to="/personal">
                <Button bStyle={["none"]}>
                  <p className={classNames(styles.default, "Label-Personal")}>
                    My profile
                  </p>
                </Button>
              </Link>
            </div>
          </div>
          <div
            className={styles.navTitle}
            onMouseEnter={() => mouseEnter("Notifications")}
            onMouseLeave={() => mouseLeave("Notifications")}
          >
            <div className={styles.left}>
              <Button
                bStyle={["none"]}
                onClick={() =>
                  document.getElementById("notificationsButton").click()
                }
              >
                <p
                  className={classNames(
                    styles.default,
                    styles.notifications,
                    "Label-Notifications"
                  )}
                >
                  Notifications
                  {invites.length > 0 && <span>{invites.length}</span>}
                </p>
              </Button>
            </div>
          </div>
        </div>
        <div className={styles.section}>
          <div className={styles.myUser}>
            <div className={styles.left}>
              {viewer.name}
              <span className={styles.currentOrg}>{account.name}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
