import React, { useState } from 'react'
import { Droppable, Draggable } from 'react-beautiful-dnd'
import classNames from 'classnames'
import Button from 'components/UI/Button'
import UserAvatar from 'components/UserAvatar'
import Container from 'components/UI/Container'
import { ReactComponent as GoalAlignment } from 'images/icons/toolkits/goal-alignment.svg'
import styles from './GroupGoals.module.scss'

export default ({
  group,
  isOpen,
  handleUpdateTeamGoalGroup,
  handleDeleteTeamGoalGroup,
  teamGoals
}) => {
  const [title, setTitle] = useState(group.title || '')
  return (
    <div className={styles.list}>
      <div className={styles.containerWrapper}>
        <Container size='large' cStyle={['noShadow', isOpen && 'active']}>
          <Droppable
            droppableId={`group-${group.id}`}
            className={styles.droppableBlock}
          >
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className={classNames(
                  styles.droppableGroup,
                  snapshot.isDraggingOver && styles.dragging
                )}
                style={{
                  height: `${
                    group.goals.length > 0
                      ? group.goals.length * 79 + 52
                      : '52 + 79'
                  }px`
                }}
              >
                <div className={styles.row}>
                  <div className={styles.col}>
                    <span className={styles.icon}>
                      <GoalAlignment />
                    </span>
                  </div>
                  <div className={styles.col}>
                    <div className={styles.text}>
                      <input
                        type='text'
                        placeholder='Enter group title...'
                        value={title}
                        onChange={e => setTitle(e.target.value)}
                        onBlur={() => handleUpdateTeamGoalGroup(group.id, title)
                        }
                      />
                    </div>
                  </div>
                  <div className={styles.remove}>
                    <Button
                      bStyle={['none']}
                      type='button'
                      onClick={() => handleDeleteTeamGoalGroup(group.id)}
                    >
                      <i className='fas fa-trash-alt' />
                    </Button>
                  </div>
                </div>
                <div className={classNames(styles.row, styles.alsoColumn)}>
                  {group.goals.length > 0
                    && group.goals.map((ggg, index3) => {
                      const goal = teamGoals.find(tg => tg.id === ggg.id)
                      if (goal) {
                        return (
                          <Draggable
                            key={`group-goal-${ggg.id}`}
                            draggableId={`${ggg.id}`}
                            index={index3}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className={classNames(
                                  styles.draggable,
                                  snapshot.isDragging && styles.draggingBlock
                                )}
                              >
                                <div className={styles.goal}>
                                  <div className={styles.col}>
                                    <div className={styles.avatar}>
                                      <UserAvatar
                                        member={goal.createdBy}
                                        size='small'
                                        showName
                                      />
                                    </div>
                                  </div>
                                  <div className={styles.col}>
                                    <div className={styles.text}>
                                      <h5 className={styles.title}>
                                        {goal.title}
                                      </h5>
                                      <p>{goal.description}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        )
                      }
                    })}
                </div>
              </div>
            )}
          </Droppable>
        </Container>
      </div>
    </div>
  )
}
