import * as Yup from "yup";

export const mainSessionValidationSchema = Yup.object().shape({
  sessionName: Yup.string().required("Session name is required"),
  sessionDescription: Yup.string().required("Session description is required"),
});

export const scorecardValidationSchema = Yup.object().shape({
  selectedScoreCard: Yup.number().required("Scorecard selection is required"),
});

export const personUnderReviewValidationSchema = Yup.object().shape({
  employee_under_review: Yup.number().required("Employee ID is required"),
});

export const invitedUsersValidationSchema = Yup.array().of(
  Yup.object().shape({
    id: Yup.number().required("User ID is required"),
    name: Yup.string().required("Name is required"),
    email: Yup.string().email().required("Email is required"),
    userStatus: Yup.string(),
    avatar_url: Yup.string().nullable(),
  })
);
