import React from 'react'
import { Link } from 'react-router-dom'
import Button from 'components/UI/Button'
import Modal from 'react-modal'
import sortPositionCompetencies from 'utils/sortPositionCompetencies'
import sortTeamCompetenciesForPosition from 'utils/sortTeamCompetenciesForPosition'
import CompetencyModal from 'components/Competencies/CompetencyModal'
import CompColumn from './CompColumn'
import compose from './compose'

import styles from './MapCompetencies.module.scss'

export default compose(
  ({
    position, showModal, setShowModal, toggleEditCompes
  }) => (
    <div className={styles.wrapper}>
      <div className={styles.row}>
        {position.competenciesHaveBeenConfed ? (
          <div className={styles.competencies}>
            <div className={styles.compWrapper}>
              <CompColumn
                competencies={position.competencies.slice(0, 3)}
                allComps={position.competencies.slice(0, 9)}
                team={
                  position.parentTeam.members.length > 0
                  && position.parentTeam.members.some(m => m.readyToRender)
                  && position.parentTeam
                }
                setShowModal={setShowModal}
                showModal={showModal}
                columnTitle='Must have'
                group='expert'
                colStyle='mustHave'
                amountOfStars={3}
              />
              <CompColumn
                competencies={position.competencies.slice(3, 6)}
                allComps={position.competencies.slice(0, 9)}
                team={
                  position.parentTeam.members.length > 0
                  && position.parentTeam.members.some(m => m.readyToRender)
                  && position.parentTeam
                }
                setShowModal={setShowModal}
                showModal={showModal}
                columnTitle='Important to have'
                group='strong'
                colStyle='important'
                amountOfStars={2}
              />
              <CompColumn
                competencies={position.competencies.slice(6, 9)}
                allComps={position.competencies.slice(0, 9)}
                team={
                  position.parentTeam.members.length > 0
                  && position.parentTeam.members.some(m => m.readyToRender)
                  && position.parentTeam
                }
                setShowModal={setShowModal}
                showModal={showModal}
                columnTitle='Good to have'
                group='good'
                colStyle='goodToHave'
                amountOfStars={1}
              />
              <Modal
                isOpen={showModal !== ''}
                onRequestClose={() => setShowModal('')}
                shouldCloseOnOverlayClick
              >
                <Button
                  bStyle='close'
                  type='button'
                  onClick={() => setShowModal('')}
                >
                  <i className='fal fa-times' />
                </Button>
                <CompetencyModal
                  resource='position'
                  members={position.parentTeam.members}
                  team={position.parentTeam}
                  competencies={
                    position.parentTeam.members.some(m => m.readyToRender)
                      ? sortTeamCompetenciesForPosition(position)
                      : sortPositionCompetencies(position)
                  }
                  setShowModal={setShowModal}
                  currentCompName={showModal}
                />
              </Modal>
            </div>
          </div>
        ) : (
          <div className={styles.competenciesPlaceholder}>
            <div className={styles.competencies}>
              <div className={styles.columnWrapper}>
                <h3 className={styles.title}>
                <div className={styles.stars}>
                    <i className='fas fa-star' />
                    <i className='fas fa-star' />
                    <i className='fas fa-star' />
                  </div>
                  Must have
              </h3>
                <div className={styles.column} />
              </div>
              <div className={styles.columnWrapper}>
                <h3 className={styles.title}>
                <div className={styles.stars}>
                    <i className='fas fa-star' />
                    <i className='fas fa-star' />
                  </div>
                  Important to have
              </h3>
                <div className={styles.column} />
              </div>
              <div className={styles.columnWrapper}>
                <h3 className={styles.title}>
                <div className={styles.stars}>
                    <i className='fas fa-star' />
                  </div>
                  Good to have
              </h3>
                <div className={styles.column} />
              </div>
            </div>
            <div className={styles.button}>
              <Link to={`/position/${position.id}/mapcompetencies`}>
                <Button
                bStyle={['primary', 'large']}
                onClick={toggleEditCompes}
              >
                <i className='fas fa-plus' />
                  Prioritize competencies
              </Button>
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  )
)
