import React from 'react'
import classNames from 'classnames'
import DistributionBarChart from 'components/Charts/BarChart'
import SwarmPlot from 'components/Charts/SwarmPlot'
import UserAvatar from 'components/UserAvatar'
import Score from 'components/Score'
import compose from './compose'
import styles from './index.module.scss'

export default compose(
  ({
    dim,
    members,
    minScore,
    maxScore,
    resource,
    colour,
    size,
    highlight,
    candidate,
    casestudy,
    modal,
    labelClass
  }) => {
    const getColour = () => {
      if (candidate && members.length === 0) {
        return styles.green
      }
      if (casestudy === true && !modal) {
        if (colour === 'green') {
          return styles.red
        }
        if (colour === 'yellow') {
          return styles.grey
        }
        if (colour === 'grey') {
          return styles.yellow
        }
        if (colour === 'red') {
          return styles.green
        }
        return styles.primary
      }
      if (colour === 'green') {
        return styles.green
      }
      if (colour === 'yellow') {
        return styles.yellow
      }
      if (colour === 'grey') {
        return styles.grey
      }
      if (colour === 'red') {
        return styles.red
      }
      return styles.primary
    }
    // return <SwarmPlot dim={dim} members={members} resource={resource} />
    return (
      <>
        <DistributionBarChart
          dim={dim}
          minScore={minScore}
          maxScore={maxScore}
          colour={colour}
          labelClass={labelClass}
          size={size}
          highlight={highlight}
          candidate
          members={members && members}
        >
          {members.map((member) => {
            const currentDim = member[resource].find(
              value => value.name === dim.name
            )

            if (member[`${resource}Chart`].readyToRender) {
              return (
                <div
                  key={member.id}
                  className={classNames(
                    styles.wrapper,
                    size && size === 'large' && styles.large,
                    highlight && member.id === highlight.id && styles.highlight,
                    highlight && styles.notmember,
                    getColour()
                  )}
                  style={{ left: `${currentDim.score}%` }}
                >
                  <UserAvatar
                    member={member}
                    size={
                      highlight && member.id === highlight.id
                        ? size && size === 'large'
                          ? 'mediumLarge'
                          : 'medium'
                        : size && size === 'large'
                          ? 'medium'
                          : 'small'
                    }
                    showName
                  />
                  {highlight && member.id === highlight.id && (
                    <div
                      className={classNames(styles.scoreWrapper, getColour())}
                    >
                      <Score score={currentDim.score} />
                    </div>
                  )}
                </div>
              )
            }
            return null
          })}
          {candidate && highlight && highlight.readyToRender && (
            <div
              className={classNames(
                styles.wrapper,
                size && size === 'large' && styles.large,
                styles.highlight,
                getColour()
              )}
              style={{
                left: `${
                  highlight[resource].find(value => value.name === dim.name)
                    .score
                }%`
              }}
            >
              <UserAvatar
                member={highlight}
                size={size && size === 'large' ? 'mediumLarge' : 'medium'}
                showName
              />
              <div className={classNames(styles.scoreWrapper, getColour())}>
                <Score
                  score={
                    highlight[resource].find(value => value.name === dim.name)
                      .score
                  }
                />
              </div>
            </div>
          )}
        </DistributionBarChart>
      </>
    )
  }
)
