import React from 'react'
import classNames from 'classnames'
import Container from 'components/UI/Container'
import UserAvatar from 'components/UserAvatar'
import Button from 'components/UI/Button'
import GoalCard from './GoalCard/index'
import compose from './compose'

import styles from './SetGoals.module.scss'

export default compose(
  ({
    TEAM_GOALS: { teamGoals },
    TEAM_QUERY: { team },
    VIEWER_QUERY: { viewer },
    cardOpen,
    setCardOpen,
    newCard,
    setNewCard,
    list,
    setList
  }) => (
    <div className={styles.main}>
      <div className={styles.content}>
        <p className={styles.subtitle}>
          Taking into account the organisations goals and vision, what are your
          teams most important goals for the next 3 - 12 months?
          <br />
          <br />
          Pick three goals and write them below.
        </p>
        <div className={styles.avatarsWrapper}>
          {team.members.map(member => (
            <div className={styles.avatar} key={`member-${member.id}`}>
              <UserAvatar member={member} size='mediumSmall' showName />
              {teamGoals
                && teamGoals.filter(g => g.createdBy.id === member.id).length
                  > 0 && (
                  <div className={styles.completeIcon}>
                    <i className='fas fa-check' />
                  </div>
              )}
            </div>
          ))}
        </div>
        {list.length > 0 && (
          <>
            {list.map((card, index) => (
              <GoalCard
                key={`goal-card-${card.id}`}
                index={`goal-card-${card.id}`}
                card={card}
                cardOpen={cardOpen}
                setCardOpen={setCardOpen}
                setNewCard={setNewCard}
                isNewCard={false}
                newCard={newCard}
                list={list}
                setList={setList}
                viewer={viewer}
              />
            ))}
          </>
        )}
        {newCard && (
          <GoalCard
            index={list.length}
            card={newCard}
            cardOpen={list.length}
            isNewCard
            setCardOpen={setCardOpen}
            setNewCard={setNewCard}
            viewer={viewer}
            list={list}
            setList={setList}
          />
        )}
        <div className={classNames(styles.containerWrapper, styles.add)}>
          <Container size='large' cStyle={['noShadow']}>
            <div className={styles.row}>
              <Button
                bStyle={['newsimple']}
                type='button'
                disabled={newCard && true}
                onClick={() => {
                  setNewCard({
                    order: list.length,
                    title: '',
                    text: ''
                  })
                }}
              >
                Add new goal
              </Button>
            </div>
          </Container>
        </div>
      </div>
    </div>
  )
)
