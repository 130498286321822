import React from 'react'
import classNames from 'classnames'
import compose from './compose'

import styles from './ViewerAvatar.module.scss'

export default compose(({ data: { viewer }, size }) => {
  if (viewer.avatar_url) {
    return (
      <div className={classNames(styles.wrapper, size && styles[size])}>
        <img alt='avatar' aria-label='img' src={viewer.avatar_url} />
      </div>
    )
  }
  return (
    <div
      className={classNames(styles.wrapper, styles.blue, size && styles[size])}
    >
      <div aria-label='initials' className={styles.initials}>
        <p>{viewer.initials}</p>
      </div>
    </div>
  )
})
