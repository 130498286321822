import React, { useState } from "react";
import Modal from "react-modal";
import Button from "components/UI/Button";
import CompetencyModal from "components/Competencies/CompetencyModal";
import CompColumn from "./CompColumn";

import styles from "./CompetenciesList.module.scss";

export default ({ candidate, competencies, team }) => {
  const [showModal, setShowModal] = useState("");

  return (
    <div className={styles.wrapper}>
      <div className={styles.compWrapper}>
        <CompColumn
          competencies={competencies}
          posCompetencies={candidate.positionCompetencies.slice(0, 3)}
          allPosComps={candidate.positionCompetencies.slice(0, 9)}
          candidate={candidate}
          setShowModal={setShowModal}
          showModal={showModal}
          columnTitle="Must have"
          columnIcon="HighRank"
          posPriority="high"
          color="green"
        />
        <CompColumn
          competencies={competencies}
          posCompetencies={candidate.positionCompetencies.slice(3, 6)}
          allPosComps={candidate.positionCompetencies.slice(0, 9)}
          candidate={candidate}
          setShowModal={setShowModal}
          showModal={showModal}
          columnTitle="Important to have"
          columnIcon="MediumRank"
          posPriority="medium"
          color="green"
        />
        <CompColumn
          competencies={competencies}
          posCompetencies={candidate.positionCompetencies.slice(6, 9)}
          allPosComps={candidate.positionCompetencies.slice(0, 9)}
          candidate={candidate}
          setShowModal={setShowModal}
          showModal={showModal}
          columnTitle="Nice to have"
          columnIcon="LowRank"
          posPriority="low"
          color="green"
        />
      </div>
      <Modal
        isOpen={showModal !== ""}
        onRequestClose={() => setShowModal("")}
        shouldCloseOnOverlayClick
      >
        <Button bStyle="close" type="button" onClick={() => setShowModal("")}>
          <i className="fal fa-times" />
        </Button>
        <CompetencyModal
          resource="candidate"
          competencies={
            team.members.filter((m) => m.readyToRender).length > 0
              ? team.competencies
              : competencies
          }
          members={candidate.position.parentTeam.members}
          candidate={candidate}
          candidateCompetencies={competencies}
          setShowModal={setShowModal}
          currentCompName={showModal}
        />
      </Modal>
    </div>
  );
};
