import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { ReactComponent as ArrowRightIcon } from "images/icons/arrow-right.svg";
import { ReactComponent as DragIcon } from "images/icons/DragIcon.svg";
import Button from "components/UI/Button";
import CompetencyModal from "./CompetencyModal";
import compose from "./compose";

import styles from "./ForcedRanking.module.scss";

export default compose(
  ({
    handleSetNextblock,
    columns,
    blocks,
    currentBlock,
    blockItemsLength,
    onDragEnd,
    modal,
    updateModal,
    noTransitionIndex,
    onDragUpdate,
    currentDropHover,
    currentDraggable,
    setCurrentDraggable,
    setNoTransitionIndex,
    isDraggingInSelectionColumn,
    isDragging,
    setIsDragging
  }) => (
    // console.log({columns})
    <>
      <div className={styles.header}>
        <div className={styles.title}>
          <h5>Competency ranking</h5>
          <p>{`${currentBlock} out of 3`}</p>
        </div>
        <div className={styles.buttonGroup}>
          <Button
            bStyle={["green", "verySmall"]}
            type="button"
            onClick={handleSetNextblock}
            disabled={
              columns.selection.filter(r => r.survey_row_id).length !==
              blockItemsLength
            }
          >
            {currentBlock === blocks.length ? "Finish" : "Next"}
          </Button>
          <Link to="/">
            <Button bStyle={["secondary", "verySmall"]} type="button">
              Cancel
            </Button>
          </Link>
        </div>
        <div
          className={classNames(
            styles.progressLine,
            styles[`block-${currentBlock}`]
          )}
        />
      </div>
      <div className={styles.main}>
        <div className={styles.titleRow}>
          <h3>Highlight the competencies you are strongest at</h3>
          <p>
            Rank competencies based on your confidence in each area to help the
            team leverage your unique strengths.
          </p>
        </div>
        <div className={styles.surveyWrapper}>
          <DragDropContext
            onDragEnd={onDragEnd}
            onDragUpdate={onDragUpdate}
            onBeforeDragStart={({ source }) => {
              setIsDragging(true);

              if (source.droppableId === "selection") {
                setCurrentDraggable(source.index);
                return setNoTransitionIndex(source.index);
              }
              return setCurrentDraggable(null);
            }}
          >
            <div className={styles.droppableLeft}>
              <Droppable droppableId="source" className={styles.leftDroppable}>
                {(provided, snapshot) => (
                  <div
                    className={classNames(
                      styles.inner,
                      snapshot.isDraggingOver && styles.isDraggingOver
                    )}
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {columns.source.map((row, index) => (
                      <Draggable
                        key={row.survey_row_id}
                        draggableId={`${row.survey_row_id}`}
                        index={index}
                      >
                        {(provided, snapshot) => {
                          if (snapshot.isDragging) {
                            provided.draggableProps.style.height = "52px";
                          }

                          return (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className={classNames(
                                styles.draggable,
                                snapshot.isDragging && styles.dragging
                              )}
                            >
                              <div
                                className={styles.draggableCard}
                                onClick={() => updateModal(row.dimension)}
                              >
                                <h5 className={styles.title}>
                                  {row.dimension.name}
                                </h5>
                                <p className={styles.desc}>
                                  {row.dimension.description_short}
                                </p>
                              </div>
                            </div>
                          );
                        }}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                    {columns.source.length === 0 && (
                      <div className={styles.thatsit}>
                        <p>
                          {currentBlock === blocks.length
                            ? "That's it, survey done!"
                            : "Proceed to the next step."}
                        </p>
                        <Button
                          bStyle={["green", "verySmall"]}
                          type="button"
                          onClick={handleSetNextblock}
                          disabled={
                            columns.selection.filter(r => r.survey_row_id)
                              .length !== blockItemsLength
                          }
                        >
                          {currentBlock === blocks.length ? "Finish" : "Next"}
                        </Button>
                      </div>
                    )}
                  </div>
                )}
              </Droppable>
            </div>
            <div className={styles.middle}>
              <div className={styles.icon}>
                <ArrowRightIcon />
              </div>
              <p>Drag competencies to the right</p>
            </div>
            <div className={styles.droppableRight}>
              <div className={styles.bg}>
                {[...Array(10).fill()].map((s, i) => (
                  <div
                    key={`slot-background-${i}`}
                    className={classNames(
                      styles.bgslot,
                      currentDropHover === i && styles.hover
                    )}
                  />
                ))}
              </div>
              <Droppable
                droppableId="selection"
                styles={{ width: "345px", height: "624px" }}
                className={styles.rightDroppable}
              >
                {(provided, snapshot) => (
                  <div
                    className={classNames(
                      styles.inner,
                      snapshot.isDraggingOver && styles.isDraggingOver
                    )}
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {columns.selection.map((card, i) => {
                      const row = columns.selection[i] && columns.selection[i];

                      return (
                        <Draggable
                          key={`right-${i}`}
                          draggableId={`right-${i}`}
                          index={i}
                          isDragDisabled={!row.survey_row_id}
                        >
                          {(provided, snapshot) => {
                            const shouldNotTransition =
                              i !== currentDraggable &&
                              i >= noTransitionIndex &&
                              isDragging;
                            let transform = shouldNotTransition
                              ? null
                              : provided.draggableProps.style.transform;
                            if (
                              !shouldNotTransition &&
                              !transform &&
                              isDraggingInSelectionColumn &&
                              row.survey_row_id &&
                              currentDropHover <= i
                            ) {
                              transform = "translate(0, 68px)";
                            }
                            if (
                              provided.draggableProps.style.transform ===
                              "translate(0px, 104px)"
                            ) {
                              transform = "translate(0px, 68px)";
                            }

                            return (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className={classNames(
                                  styles.draggableCardWrapper,
                                  !row.survey_row_id && styles.invisible,
                                  snapshot.isDragging && styles.dragging
                                  // shouldNotTransition && styles.noTransition
                                )}
                                style={{
                                  transform
                                }}
                              >
                                <div
                                  className={styles.draggableCard}
                                  onClick={() => updateModal(row.dimension)}
                                >
                                  <h5
                                    className={classNames(
                                      styles.title,
                                      !row.dimension && styles.placeholder
                                    )}
                                  >
                                    {row.dimension ? row.dimension.name : "  "}
                                  </h5>
                                </div>
                              </div>
                            );
                          }}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
              <div className={styles.legends}>
                <p>Most like me</p>
                <p>Neutral</p>
                <p>Least like me</p>
              </div>
            </div>
          </DragDropContext>
        </div>
        <Modal
          closeTimeoutMS={350}
          isOpen={!!modal}
          onRequestClose={() => updateModal("")}
          shouldCloseOnOverlayClick
          className={styles.modal}
        >
          <Button bStyle="close" type="button" onClick={() => updateModal("")}>
            <i className="fal fa-times" />
          </Button>
          <CompetencyModal comp={modal} />
        </Modal>
      </div>
    </>
  )
);

//
//
// (
//   <Draggable
//     key={`right-${
//       row.survey_row_id
//         ? row.survey_row_id
//         : card.obj_type_id
//     }`}
//     draggableId={`right-${
//       row.survey_row_id
//         ? row.survey_row_id
//         : card.obj_type_id
//     }`}
//     index={i}
//     isDragDisabled={!row.survey_row_id}
//
//   >
//     {(provided, snapshot) => (
//       <div
//         ref={provided.innerRef}
//         {...provided.draggableProps}
//         {...provided.dragHandleProps}
//         className={classNames(
//           styles.draggableCardWrapper,
//           !row.survey_row_id && styles.invisible,
//           snapshot.isDragging && styles.dragging,
//           i === 5 && styles.invisibleRed
//         )}
//       >
//         <div
//           className={styles.draggableCard}
//           onClick={() => updateModal(row.dimension)}
//         >
//           {row.dimension && (
//             <h5 className={styles.title}>
//               {row.dimension.name}
//             </h5>
//           )}
//           <div className={styles.dragIcon}>
//             <DragIcon />
//           </div>
//         </div>
//       </div>
//     )}
//   </Draggable>
// )
