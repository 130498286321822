import React from "react";
import classNames from "classnames";
import Button from "components/UI/Button";
import Icon from "components/UI/Icon";
import { LinkedInPluginCTA } from "components/UI/CTA";

import styles from "./CandidateOptions.module.scss";

export default ({
  selectedOption,
  setSelectedOption,
  setCurrentStep,
  isRR,
}) => {
  return (
    <>
      <h5 className={styles.title}>
        Set up new candidate profiles and invite candidates to complete Wisnio
        assessments.
      </h5>
      <div className={styles.options}>
        <Button bStyle={["none"]} onClick={() => setSelectedOption("invite")}>
          <div
            className={classNames(
              styles.option,
              selectedOption === "invite" && styles.selected
            )}
          >
            <div className={styles.left}>
              <div
                className={classNames(
                  styles.checkbox,
                  selectedOption === "invite" && styles.checked
                )}
              ></div>
              <div className={styles.icon}>
                <Icon icon="Mail" />
              </div>
              <h5>Invite candidate to Wisnio</h5>
            </div>
            <p className={styles.instruction}>
              Invite candidate to complete Wisnio assessments
            </p>
          </div>
        </Button>
        <Button bStyle={["none"]} onClick={() => setSelectedOption("contact")}>
          <div
            className={classNames(
              styles.option,
              selectedOption === "contact" && styles.selected
            )}
          >
            <div className={styles.left}>
              <div className={styles.checkbox}></div>
              <div className={styles.icon}>
                <Icon icon="User" />
              </div>
              <h5>Add candidate as a contact</h5>
            </div>
            <p className={styles.instruction}>
              Create candidate profile without Wisnio assessments
            </p>
          </div>
        </Button>
      </div>
      <div className={styles.cta}>
        <LinkedInPluginCTA />
      </div>
      <div
        className={classNames(
          styles.submitWrapper,
          document.getElementById("inviteWrapper") &&
            document.getElementById("inviteWrapper").offsetHeight > 450 &&
            styles.relative
        )}
      >
        <Button
          bStyle={["primary"]}
          disabled={!selectedOption}
          onClick={() => {
            const step = selectedOption;
            setCurrentStep(step);
          }}
        >
          Next
        </Button>
      </div>
    </>
  );
};
