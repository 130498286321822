import React from "react";
import classNames from "classnames";
import ModalTitle from "components/UI/ModalTitle";
import Button from "components/UI/Button";
import { ReactComponent as ToolsIcon } from "images/icons/Tools.svg";

import styles from "./RemoveMember.module.scss";

export default ({
  position,
  removeModal,
  setRemoveModal,
  setConfirmModal,
  removeCandidateFromPosition,
  removeCandidateFromPositionLonglist,
  viewer,
}) => {
  return (
    <div className={styles.modalWrapper}>
      <Button
        bStyle="close"
        type="button"
        onClick={() => setRemoveModal(false)}
      >
        <i className="fal fa-times" />
      </Button>
      <ModalTitle>
        {`Remove ${removeModal.name || removeModal.email}?`}
      </ModalTitle>
      <h4 className={styles.subtitle}>
        Please specify if you would like to remove this user from this
        organisation entirely or only from this position.
      </h4>
      <div className={styles.buttonGroup}>
        {removeModal.type === "person" && (
          <Button
            type="button"
            bStyle={["none"]}
            onClick={() => {
              removeCandidateFromPositionLonglist({
                variables: {
                  position_id: position.id,
                  person_id: removeModal.id,
                },
              }).then((res) => {
                setRemoveModal(false);
              });
            }}
          >
            <div className={styles.card}>
              <div className={classNames(styles.icon, styles.blue)}>
                <ToolsIcon />
              </div>
              <div className={styles.text}>
                <h5>Remove contact from position</h5>
                <p>Remove the contact from the position</p>
              </div>
            </div>
          </Button>
        )}
        {removeModal.type !== "person" && (
          <Button
            type="button"
            bStyle={["none"]}
            onClick={() => {
              removeCandidateFromPosition({
                variables: {
                  position_id: position.id,
                  candidate_id: removeModal.id,
                  team_id: position.parentTeam.id,
                },
              }).then((res) => {
                setRemoveModal(false);
              });
            }}
          >
            <div className={styles.card}>
              <div className={classNames(styles.icon, styles.blue)}>
                <ToolsIcon />
              </div>
              <div className={styles.text}>
                <h5>Remove candidate from position</h5>
                <p>
                  You will still be able to access this user's profile data and
                  add them to future positions and teams
                </p>
              </div>
            </div>
          </Button>
        )}
        {(position.parentOrganization.contract.role === "owner" ||
          position.parentOrganization.contract.role === "admin") &&
          removeModal.type !== "person" &&
          viewer.id !== removeModal.id && (
            <Button
              type="button"
              bStyle={["none"]}
              onClick={() => setConfirmModal(removeModal)}
            >
              <div className={styles.card}>
                <div className={classNames(styles.icon, styles.red)}>
                  <i className="far fa-times" />
                </div>
                <div className={styles.text}>
                  <h5>Remove candidate from organisation</h5>
                  <p>
                    This frees up a seat if they are not in any other
                    organisation in this workspace
                  </p>
                </div>
              </div>
            </Button>
          )}
      </div>
    </div>
  );
};
