import React, { useState } from "react";
import classNames from "classnames";
import { useTour } from "@reactour/tour";
import Wrapper from "components/UI/Wrapper";
import Title from "components/UI/Title";
import Subtitle from "components/UI/Subtitle";
import Button from "components/UI/Button";
import CompetencyPlaceholder from "components/Placeholders/CompetencyPlaceholder";
import Modal from "react-modal";
import CompetencyModal from "components/Competencies/CompetencyModal";
import CompetencyChart from "components/Charts/CompetencyChart";
import Insights from "./Insights";
import TeamMember from "./TeamMember";

import styles from "./Competencies.module.scss";

export default ({ team, competencies, competencyGraph }) => {
  const { isOpen, setIsOpen } = useTour();

  const [showModal, setShowModal] = useState("");
  const [memberHover, setMemberHover] = useState(null);

  return (
    <div className={styles.main}>
      <Wrapper>
        <Title text="Competency map" icon="fas fa-clipboard-check" />
        <Subtitle text="The competencies the team is most confident in." />
        <Subtitle
          verySmall
          text="Leverage the unique strengths of each team member and assess potential development opportunities."
        />
        {competencies &&
        competencies.length > 0 &&
        competencies.some((c) => c.members.length > 0) &&
        !competencies.some((c) => c.score === null) ? (
          <>
            <div className={styles.chart}>
              <div
                className={classNames(styles.tourViewbox, "competencyGraph")}
              ></div>
              <CompetencyChart
                member={
                  memberHover &&
                  team.members.find((m) => m.id === memberHover).competencyGraph
                }
                competencies={competencyGraph}
                setShowModal={setShowModal}
                type="team"
              />
              <div className={styles.avatarsWrap}>
                {team.members.map((member) => (
                  <TeamMember
                    key={member.id}
                    member={member}
                    userId={member.id}
                    teamCompetencies={competencyGraph}
                    setMemberHover={setMemberHover}
                  />
                ))}
              </div>
            </div>

            <h3>Competency insights</h3>
            <Subtitle
              verySmall
              text="A quick overview of your team's strengths, potential skills gaps, and development opportunities."
            />
            <Insights team={team} />

            {showModal !== "" && (
              <Modal
                isOpen={showModal !== ""}
                onRequestClose={() => setShowModal("")}
                onAfterOpen={() => setIsOpen(false)}
                onAfterClose={() => setIsOpen(true)}
                shouldCloseOnOverlayClick
              >
                <Button
                  bStyle="close"
                  type="button"
                  onClick={() => setShowModal("")}
                >
                  <i className="fal fa-times" />
                </Button>
                <CompetencyModal
                  resource="team"
                  members={team.members}
                  competencies={competencies}
                  setShowModal={setShowModal}
                  currentCompName={showModal}
                />
              </Modal>
            )}
          </>
        ) : (
          <>
            <CompetencyPlaceholder resource="team" />
          </>
        )}
      </Wrapper>
    </div>
  );
};
