import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import classNames from "classnames";
import ModalTitle from "components/UI/ModalTitle";
import Icon from "components/UI/Icon";
import Field from "components/UI/Field";
import Button from "components/UI/Button";
import UserAvatar from "components/UserAvatar";
import compose from "./compose";

import styles from "./SearchTalentPool.module.scss";

export default compose(
  ({
    addUserFromList,
    personName,
    handleAddNewPerson,
    data: { loading, searchTalentPool: results },
    error,
  }) => {
    // if (!results || loading || results.length < 1 || error) return null;

    const [modal, openModal] = useState(false);
    const [first_name, setFirstName] = useState(null);
    const [last_name, setLastName] = useState(null);
    const [email, setEmail] = useState(null);

    useEffect(() => {
      if (personName) {
        if (personName.includes("@")) {
          setEmail(personName);
        } else {
          const names = personName.split(" ");
          if (names.length === 1) {
            setFirstName(names[0]);
          } else if (names.length === 2) {
            setFirstName(names[0]);
            setLastName(names[1]);
          } else if (names.length > 2) {
            let firstNames = "";
            names.map((name, i) => {
              if (i + 1 !== names.length) {
                firstNames += name + " ";
              } else {
                setLastName(name);
              }
              setFirstName(firstNames);
            });
          }
        }
      }
    }, [personName]);

    return (
      <>
        <ul className={styles.searchResults}>
          <li className={styles.searchWrapper} onClick={() => openModal(true)}>
            <div className={styles.avatarWrapper}>
              <div className={styles.icon}>
                <Icon icon="AddUser" />
              </div>
            </div>
            <div className={styles.info}>
              <p
                className={styles.userName}
              >{`Add new person "${personName}"`}</p>
            </div>
          </li>
          {results &&
            results.map((res) => (
              <li
                className={styles.searchWrapper}
                key={`${res.type}:${res.id}`}
                onClick={() => addUserFromList(res)}
              >
                <div className={styles.avatarWrapper}>
                  <UserAvatar
                    member={res}
                    isInvite={res.type === "invite"}
                    size="small"
                  />
                </div>
                <div className={styles.info}>
                  <p className={styles.userName}>
                    {res.name ? res.name : res.email}
                  </p>
                  {res.name && <p className={styles.userEmail}>{res.email}</p>}
                </div>
              </li>
            ))}
        </ul>
        <Modal
          isOpen={modal}
          onRequestClose={() => openModal(false)}
          shouldCloseOnOverlayClick
        >
          <Button bStyle="close" type="button" onClick={() => openModal(false)}>
            <i className="fal fa-times" />
          </Button>
          <div id="inviteWrapper" className={styles.wrapper}>
            <div className={styles.modalHead}>
              <div className={styles.title}>
                <div className={styles.icon}>
                  <Icon icon="AddUser" />
                </div>
                <ModalTitle icon="AddUser">Add a new person</ModalTitle>
              </div>
              <p>
                Add a new individual whose work performance you want to assess.
              </p>
            </div>
            <div className={styles.inputs}>
              <div className={styles.inputRow}>
                <div className={styles.input}>
                  <Field
                    white
                    fullWidth
                    type="text"
                    name="first_name"
                    required
                    placeholder="First name *"
                    value={first_name}
                    onChange={(e) => {
                      setFirstName(e.target.value);
                    }}
                  />
                </div>
                <div className={styles.input}>
                  <Field
                    white
                    fullWidth
                    type="text"
                    name="last_name"
                    required
                    placeholder="Last name *"
                    value={last_name}
                    onChange={(e) => {
                      setLastName(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className={styles.input}>
                <Field
                  white
                  fullWidth
                  type="text"
                  name="email"
                  required
                  placeholder="Email *"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </div>
            </div>
            <div
              className={classNames(
                styles.buttonGroup,
                document.getElementById("inviteWrapper") &&
                  document.getElementById("inviteWrapper").offsetHeight > 450 &&
                  styles.relative
              )}
            >
              <Button
                bStyle={["green", "large"]}
                type="submit"
                onClick={() => {
                  handleAddNewPerson({
                    person: { first_name, last_name, email },
                  });
                  setFirstName(null);
                  setLastName(null);
                  setEmail(null);
                  openModal(false);
                }}
              >
                Add person
              </Button>
              <Button
                bStyle={["secondary", "large"]}
                onClick={() => openModal(false)}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Modal>
      </>
    );
  }
);
