import React, { useState } from "react";
import classNames from "classnames";
import Modal from "react-modal";
import Icon from "components/UI/Icon";
import Button from "components/UI/Button";
import resolveTemplates from "../resolveTemplates";
import compose from "./compose";
import { Player, BigPlayButton } from "video-react";

import styles from "./UseCase.module.scss";

export default compose(
  ({
    title,
    text,
    color,
    fields,
    viewer,
    surveys,
    handleSetNewAccountOnboardingUseCase,
    handleNextStep,
  }) => {
    const [selectedOption, setSelectedOption] = useState(null);
    const [modal, updateModal] = useState(null);

    const createMarkup = (text) => {
      return { __html: resolveTemplates({ text, user: viewer, surveys }) };
    };

    const useCases = {
      0: {
        id: 0,
        title: "Hiring",
        icon: "Businessman",
        color: "primary",
        usecase: "hiring",
        thumbnail:
          "https://storage.googleapis.com/iaeqpsxmmk/onboarding_images/hiring.png",
        video: "https://storage.googleapis.com/iaeqpsxmmk/Hiring.mp4",

        description:
          "A decision tool designed to provide team leaders with the insights they need to make great hiring decisions and build high-performing teams.",
        features: [
          {
            title: "Powerful talent data",
            description:
              "Scientific assessments capture critical talent data on the team, the job, and shortlisted candidates to give to give you the insights you need.",
          },
          {
            title: "AI-powered insights",
            description:
              "The talent data is enhanced by AI-powered tools, making it immediately actionable for you and the candidates.",
          },
        ],
      },
      1: {
        id: 1,
        title: "Team development",
        icon: "Plant",
        color: "green",
        usecase: "team development",
        thumbnail:
          "https://storage.googleapis.com/iaeqpsxmmk/onboarding_images/team_dev.png",
        video: "https://storage.googleapis.com/iaeqpsxmmk/team.mp4",
        description:
          "A decision tool designed to give team leaders the insights they need to make great hiring decisions and build high-performing teams.",
        features: [
          {
            title: "Fast, actionable results",
            description:
              "Scientific assessments capture the critical talent data you need to conduct team evaluations in just 25 minutes.",
          },
          {
            title: "AI-powered insights",
            description:
              "AI-powered tools intrepert the talent data and make it immediately actionable, helping to speed up the process.",
          },
        ],
      },
      2: {
        id: 2,
        title: "Personal profile",
        icon: "User",
        color: "yellow",
        usecase: "personal",
        thumbnail:
          "https://storage.googleapis.com/iaeqpsxmmk/onboarding_images/personal_profile.png",
        video: "https://storage.googleapis.com/iaeqpsxmmk/personal.mp4",
        description:
          "Your Personal Profile gives detailed insights into your behavioural strengths, key motivators, and core competencies.",
        features: [
          {
            title: "Fast, actionable results",
            description:
              "Scientific assessments capture the critical talent data and give insights into  your strengths and development areas in just 25 minutes.",
          },
          {
            title: "AI-powered insights",
            description:
              "The talent data is enhanced with AI-powered tools, making it immediately actionable and understandable, you just need to ask the right questions.",
          },
        ],
      },
      3: {
        id: 3,
        title: "Power of Wisnio",
        color: "purple",
        icon: "Bulb",
        usecase: "discovering",
        thumbnail:
          "https://storage.googleapis.com/iaeqpsxmmk/onboarding_images/discovering.png",
        video: "https://storage.googleapis.com/iaeqpsxmmk/just_discovering.mp4",
        description:
          "A decision tool designed to give team leaders the insights they need to make great hiring decisions and build high-performing teams.",
        features: [
          {
            title: "Powerful team and talent data",
            description:
              "Scientific assessments capture the critical talent data you need to conduct team, personal, or candidate evaluations in just 25 minutes.",
          },
          {
            title:
              "AI-powered tools for hiring, team development, and succession planning ",
            description:
              "Consult AI-powered WisGPT for insights that help you understand the talent data and make it immediately actionable. You can use it to hire the best talent or develop the team you already have.",
          },
        ],
      },
    };

    return (
      <>
        <div className={styles.wrapper}>
          <div className={styles.left}>
            <h1 className={styles.title}>
              {resolveTemplates({ text: title, user: viewer, surveys })}
            </h1>
            <div
              className={styles.subtitle}
              dangerouslySetInnerHTML={createMarkup(text)}
            />
            {fields && (
              <div className={styles.fields}>
                {fields.map((field, index) => (
                  <div key={"FieldKey-" + index} className={styles.field}>
                    <h3>{field.title}</h3>
                    <p>{field.text}</p>
                    {field.options && field.options.length > 0 && (
                      <div className={styles.options}>
                        {field.options.map((option, index) => (
                          <div
                            key={"OptionKey-" + index}
                            className={classNames(
                              styles.option,
                              selectedOption?.id === index && styles.selected
                            )}
                          >
                            <input
                              type="radio"
                              id={"option-" + index}
                              name={option}
                              value={option}
                              checked={selectedOption?.id === index}
                              onChange={() => {
                                setSelectedOption(useCases[index]);
                              }}
                            ></input>
                            <label
                              for={"option-" + index}
                              dangerouslySetInnerHTML={{ __html: option }}
                            ></label>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
            <div className={styles.buttons}>
              <Button
                bStyle={["primary", "small"]}
                onClick={() => {
                  if (fields && fields.length > 0) {
                    handleSetNewAccountOnboardingUseCase(selectedOption.title);
                    updateModal(selectedOption.title);
                  } else {
                    handleNextStep();
                  }
                }}
                disabled={
                  fields && fields.length > 0 && selectedOption === null
                }
              >
                Next
              </Button>
            </div>
          </div>
          <div className={classNames(styles.right, styles[color])}>
            <div className={styles.floatingLogo}></div>
            <div className={styles.floatingLogo}></div>
            <div className={styles.floatingLogo}></div>
          </div>
        </div>
        {modal && (
          <Modal
            isOpen={modal}
            onRequestClose={() => updateModal(null)}
            className={classNames(styles.modal, "fullModal")}
          >
            <Button
              bStyle="close"
              type="button"
              onClick={() => updateModal(null)}
            >
              <i className="fal fa-times" />
            </Button>
            <div className={styles.modalContent}>
              <div className={styles.left}>
                <div className={styles.logoWrapper}>
                  <div className={styles.defaultLogo} />
                </div>
                <div className={styles.content}>
                  <div className={styles.useCaseTitle}>
                    <div
                      className={classNames(
                        styles.icon,
                        styles[selectedOption.color]
                      )}
                    >
                      <Icon icon={selectedOption.icon} />
                    </div>
                    <h3>{selectedOption.title}</h3>
                  </div>
                  <p className={styles.description}>
                    {selectedOption.description}
                  </p>
                  <div className={styles.list}>
                    {selectedOption.features.map((feature, index) => (
                      <div className={styles.listItem}>
                        <div className={styles.number}>
                          <span>{index + 1}</span>
                        </div>
                        <div className={styles.text}>
                          <h5>{feature.title}</h5>
                          <p>{feature.description}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className={styles.buttons}>
                    <Button
                      bStyle={["primary", "small"]}
                      onClick={() => {
                        handleNextStep();
                        updateModal(null);
                      }}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              </div>
              <div
                className={classNames(
                  styles.right,
                  styles[selectedOption.color]
                )}
              >
                <div className={styles.video}>
                  <Player
                    playsInline
                    poster={selectedOption.thumbnail}
                    src={selectedOption.video}
                  >
                    <BigPlayButton position="center" />
                  </Player>
                </div>
              </div>
            </div>
          </Modal>
        )}
      </>
    );
  }
);
