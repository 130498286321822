import { withRouter } from "react-router-dom";
import { withApollo } from "react-apollo";
import { compose, withState } from "recompose";
// Internet Explorer 6-11
const isIE = /* @cc_on!@ */ false || !!document.documentMode;

export default compose(
  withRouter,
  withApollo,
  withState("isIE", "setIsIE", !!isIE),
  withState("modal", "updateModal", () => {
    return {
      open: false,
    };
  })
);
