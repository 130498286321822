import React, { useState } from "react";
import classNames from "classnames";
import ModalTitle from "components/UI/ModalTitle";
import Field from "components/UI/Field";
import Button from "components/UI/Button";
import AccountLimitExceeded from "components/AccountLimitExceeded";
import Icon from "components/UI/Icon";
import SearchTalentPool from "./SearchTalentPool";
import List from "./List";
import CandidateOptions from "./CandidateOptions";
import compose from "./compose";
import styles from "./Invite.module.scss";

export default compose(
  ({
    org_id,
    account_id,
    team_id,
    team,
    position_id,
    searchEnabled,
    updateModal,
    resource,
    nameOrEmail,
    setNameOrEmail,
    setOverlayClose,
    list,
    updateList,
    handleValidateAndUpdateList,
    color,
    error,
    setError,
    handleSubmit,
    require,
    isLoading,
    noheight,
    validationCase,
    limitExceededMessage,
    setLimitExceededMessage,
    askForName,
    assessment_session_id,
    currentStep,
    setCurrentStep,
  }) => {
    const [selectedOption, setSelectedOption] = useState(null);
    const addContact = resource === "candidate" && currentStep === "contact";

    const teamLimit = () => {
      if (!team) return false;
      if (
        team &&
        team.members.length + team.pendingInvites.length + list.length > 20
      )
        return true;
      if (
        team &&
        team.members.length + team.pendingInvites.length + list.length < 20
      )
        return false;
    };

    const onFormSubmit = (e) => {
      e.preventDefault();

      handleValidateAndUpdateList({
        email: nameOrEmail,
      });
    };

    const isRR =
      org_id === parseInt(process.env.REACT_APP_RUSSELL_DEMO_ORG, 10);

    if (resource === "candidate" && !currentStep) {
      return (
        <>
          <div className={styles.modalHead}>
            <ModalTitle icon="AddUser">Add candidates</ModalTitle>
          </div>
          <CandidateOptions
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            isRR={isRR}
          />
        </>
      );
    }

    return (
      <>
        {resource === "candidate" && (
          <div className={styles.modalHead}>
            <ModalTitle
              icon={currentStep === "contact" ? "User" : "Mail"}
              color={currentStep === "contact" && "grey"}
            >
              {currentStep === "contact" ? "Add contacts" : "Invite candidates"}
            </ModalTitle>
          </div>
        )}
        <div
          id="inviteWrapper"
          className={classNames(styles.wrapper, noheight && styles.noHeight)}
        >
          {teamLimit() && (
            <div className={styles.limit}>
              <h5>
                You have reached the team size limit of 20 members. To proceed,
                remove member(s) or create a new team.
              </h5>
            </div>
          )}
          <form onSubmit={onFormSubmit} className={styles.searchForm}>
            <div
              className={classNames(
                styles.smallRow,
                teamLimit() && styles.disabled
              )}
            >
              <Field
                fullWidth
                white
                name="nameOrEmail"
                type="text"
                placeholder={
                  searchEnabled && !addContact
                    ? "Enter email address or name"
                    : "Enter email address"
                }
                autoComplete="off"
                value={nameOrEmail}
                onChange={(e) => {
                  setNameOrEmail(e.target.value);
                }}
                paddingRight={nameOrEmail.length > 0}
                disabled={teamLimit()}
              />
              {nameOrEmail.length > 0 && (
                <>
                  <div className={styles.delete}>
                    <Button
                      bStyle={["none"]}
                      type="button"
                      onClick={() => {
                        setError("");
                        setNameOrEmail("");
                      }}
                    >
                      <Icon icon="Close" />
                    </Button>
                  </div>
                  <div className={styles.addButton}>
                    <Button
                      bStyle={["primary"]}
                      type="submit"
                      onClick={() =>
                        handleValidateAndUpdateList({
                          email: nameOrEmail,
                        })
                      }
                    >
                      Add
                    </Button>
                  </div>
                </>
              )}
              {searchEnabled && nameOrEmail.length > 0 && (
                <SearchTalentPool
                  handleValidateAndUpdateList={handleValidateAndUpdateList}
                  addContact={addContact}
                  setError={setError}
                  error={error}
                  where={{
                    searchValue: nameOrEmail,
                    org_id,
                    account_id,
                    team_id,
                    position_id,
                    validationCase,
                    assessment_session_id,
                  }}
                />
              )}
            </div>
            {error && (
              <div className={styles.error}>
                <p>{error}</p>
              </div>
            )}
            {limitExceededMessage && limitExceededMessage.message && (
              <AccountLimitExceeded
                limitExceededMessage={limitExceededMessage}
                setLimitExceededMessage={setLimitExceededMessage}
              />
            )}
          </form>
          <List
            isLoading={isLoading}
            require={require}
            list={list}
            updateList={updateList}
            color={color}
            handleSubmit={handleSubmit}
            setOverlayClose={setOverlayClose}
            noheight={noheight}
            updateModal={updateModal}
            resource={resource}
            currentStep={currentStep}
            teamId={team_id}
            teamLimit={teamLimit()}
            askForName={
              resource === "candidate" && currentStep === "invite"
                ? false
                : askForName
            }
          />
        </div>
      </>
    );
  }
);
