import React from 'react'
import Button from 'components/UI/Button'

export default ({
  team,
  member,
  removeFromTeam,
  removeTeamFromInvite,
  client,
  isLoading,
  setIsLoading,
  refetch
}) => (
  <Button
    aria-label='removeButton'
    bStyle={['none']}
    type='button'
    onClick={() => {
      setIsLoading(member)
      if (member.isInvite) {

        removeTeamFromInvite({
          variables: {
            team_id: team.id,
            invite_id: member.id
          }
        }).then((res) => {
          // refetch()
          // client.reFetchObservableQueries(['TEAM'])
        })
      } else {
        removeFromTeam({
          variables: {
            id: team.id,
            user_id: member.id,
            org_id: team.organization.id
          }
        }).then((res) => {
          
          refetch()
          // client.reFetchObservableQueries(['CANDIDATEINTEAM'])
        })
      }
    }}
  >
    {isLoading && isLoading === member ? (
      <i className='far fa-spinner-third' />
    ) : (
      <i className='fal fa-times' />
    )}
  </Button>
)
