import React from 'react'
import { compose, withState, withHandlers } from 'recompose'
import { withRouter } from 'react-router-dom'
import { graphql } from 'react-apollo'
import { loader } from 'graphql.macro'
import scrollToTop from 'utils/scrollToTop'

const START_TEAM_PLAY = loader('./startTeamPlay1.graphql')

export default compose(
  withRouter,
  withState('prevRuns', 'setPrevRuns', false),
  graphql(START_TEAM_PLAY, {
    name: 'startNewWorkflow'
  }),
  withHandlers({
    handleStartNewWorkflow: ({
      startNewWorkflow,
      team,
      tool,
      history
    }) => async () => {
      await startNewWorkflow({
        variables: {
          team_id: team.id,
          toolkit_id: tool.id
        }
      }).then((res) => {
        history.push(
          `/team/${team.id}/workflow/${res.data.startTeamPlay.id}/${res.data.startTeamPlay.type}`
        )
      })
    }
  }),
  scrollToTop
)
