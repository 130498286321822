import { withHandlers, compose } from "recompose";
import { withRouter } from "react-router-dom";
import { graphql, withApollo } from "react-apollo";
import { loader } from "graphql.macro";

const REMOVE_DEMO_DATA = loader("./removeDemoData.graphql");

export default compose(
  withRouter,
  withApollo,
  graphql(REMOVE_DEMO_DATA, {
    name: "removeDemoData",
  }),
  withHandlers({
    handleRemoveDemoData:
      ({ removeDemoData, organization, updateRemoveDataModal }) =>
      ({ removeDemoDataFromOrg }) => {
        removeDemoData({
          variables: {
            org_id: organization.id,
          },
        }).then((res) => {
          updateRemoveDataModal(false);
          window.location.reload();
        });
      },
  })
);
