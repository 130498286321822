import React from "react";
import classNames from "classnames";

import styles from "./Title.module.scss";

export default ({ text, icon, white, small }) => (
  <h2
    className={classNames(
      styles.title,
      white && styles.white,
      small && styles.small
    )}
  >
    {icon && <i className={icon} />}
    {text}
  </h2>
);
