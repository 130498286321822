import { withRouter } from "react-router-dom";
import { compose, withHandlers, withState } from "recompose";
import { graphql } from "react-apollo";
import renderLoader from "utils/loader";
import { loader } from "graphql.macro";
import scrollToTop from "utils/scrollToTop";

const PERSONAL_QUERY = loader("./PersonalQuery2.graphql");
const CHANGE_PASSWORD = loader("./ChangePassword1.graphql");
const UPDATE_USER = loader("./updateUser5.graphql");

export default compose(
  withRouter,
  graphql(PERSONAL_QUERY),
  graphql(CHANGE_PASSWORD, { name: "updatePassword" }),
  graphql(UPDATE_USER, { name: "updateUserMutation" }),
  renderLoader(() => null),
  withState(
    "firstName",
    "setFirstName",
    (props) => props.data.viewer.first_name
  ),
  withState("lastName", "setLastName", (props) => props.data.viewer.last_name),
  withState("email", "setEmail", (props) => props.data.viewer.email),
  withState("phone", "setPhone", (props) => props.data.viewer.phone || ""),
  withState(
    "linkedIn",
    "setLinkedIn",
    (props) => props.data.viewer.linkedIn || ""
  ),
  withState("userError", "setUserError", null),
  withState("userSuccess", "setUserSuccess", false),
  withState("pwError", "setPwError", null),
  withState("pwSuccess", "setPwSuccess", false),
  withHandlers({
    updateUser:
      ({ updateUserMutation, setUserSuccess, setUserError, phone, linkedIn }) =>
      (e) => {
        e.preventDefault();
        const validLinkedInUrl = "linkedin.com";
        const first_name = e.target.first_name.value;
        const last_name = e.target.last_name.value;
        if (first_name.length < 1 || last_name.length < 1) {
          setUserSuccess(null);
          setUserError("Name cannot be empty");
          return null;
        }
        if (linkedIn !== "" && !linkedIn.includes(validLinkedInUrl)) {
          setUserSuccess(null);
          setUserError("Please enter a valid LinkedIn URL.");
          return null;
        }

        const email = e.target.email.value;
        e.target.reset();
        updateUserMutation({
          variables: {
            first_name,
            last_name,
            email,
            phone: phone || "",
            linkedIn: linkedIn || "",
          },
        })
          .then((res) => {
            setUserError(null);
            setUserSuccess(true);
          })
          .catch((err) => {
            setUserError(err.message.replace("GraphQL error: ", ""));
          });
      },
    handleChangePassword:
      ({ updatePassword, setPwError, setPwSuccess }) =>
      (e) => {
        e.preventDefault();
        e.persist();
        setPwError(null);
        const { old_password, new_password, repeat_new_password } = e.target;
        if (new_password.value !== repeat_new_password.value) {
          return setPwError("Passwords must match!");
        }
        if (new_password.value.length < 8) {
          return setPwError("Password too short!");
        }
        if (new_password.value.length > 32) {
          return setPwError("Password too long!");
        }
        updatePassword({
          variables: {
            old_password: old_password.value,
            new_password: new_password.value,
            repeat_new_password: repeat_new_password.value,
          },
        })
          .then((res) => {
            setPwSuccess(true);
            e.target.reset();
          })
          .catch((err) => {
            const message = err.message.split("GraphQL error: ")[1];
            setPwError(message);
          });
      },
  }),
  scrollToTop
);
