import React from 'react'
import Button from 'components/UI/Button'
import compose from './compose'

import styles from './Modal.module.scss'

export default compose(({ setConfirmModal, handleLeaveOrg }) => (
  <div className={styles.modalWrapper}>
    <h3 className={styles.title}>
      <i className="fas fa-trash-alt" />
      Are you sure you wish to remove yourself from this organisation?
    </h3>
    <p className={styles.subtitle}>
      You will also be removed from all teams and positions in this
      organisation.
    </p>
    <div className={styles.buttonGroup}>
      <Button
        bStyle={['primary', 'large', 'red']}
        type="button"
        onClick={handleLeaveOrg}
      >
        <i className="fas fa-trash-alt" />
        Yes, remove
      </Button>
      <Button
        bStyle={['secondary', 'large']}
        type="button"
        onClick={() => setConfirmModal(false)}
      >
        No, cancel
      </Button>
    </div>
  </div>
))
