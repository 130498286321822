import React from "react";
import { Link } from "react-router-dom";
import { useTour } from "@reactour/tour";

export default ({ link, children, readOnly, isTour, toggleShowTeamTour }) => {
  const { isOpen, setIsOpen } = useTour();

  if (link && !readOnly) {
    return (
      <Link
        onClick={() => {
          window.scrollTo(0, 0);
          if (isOpen) {
            setIsOpen(false);
          }
        }}
        to={link}
      >
        {children}
      </Link>
    );
  }
  return children;
};
