import { withHandlers, compose } from "recompose";
import { withApollo } from "react-apollo";
import withStore from "utils/withStore";

import { loader } from "graphql.macro";

const PERSONAL_PROFILE = loader("client/Queries/PersonalProfile3.graphql");
const VIEWER_TEAMS = loader("client/Queries/ViewerTeams.graphql");
const VIEWER = loader("client/Queries/ViewerQuery25.graphql");

export default compose(
  withApollo,
  withStore("userOnboarding"),
  withHandlers({
    handleFinishSurvey:
      ({
        answers,
        handleFinishMutation,
        data: { survey },
        history,
        location,
        userOnboarding,
        setLoading,
      }) =>
      async (competencyAnswers) => {
        try {
          setLoading(true);
          await handleFinishMutation({
            variables: {
              surveyId: survey.id,
              answers: competencyAnswers || answers,
            },
            refetchQueries: [
              {
                query: PERSONAL_PROFILE,
                fetchPolicy: "network-only",
              },
              { query: VIEWER, fetchPolicy: "network-only" },
              { query: VIEWER_TEAMS, fetchPolicy: "network-only" },
            ],
          });

          if (survey.id === 31) {
            // console.log('updating intercom after survey update')
            window.Intercom("update", {
              app_id: process.env.REACT_APP_INTERCOM_APP_ID,
              hasCompletedNewCompeSurvey: true,
            });
          }
          if (survey.id === 26) {
            history.push("/survey/27");
            return null;
          }
          if (userOnboarding.active) {
            history.push("/onboarding");
            return null;
          }
          if (location.state && location.state.from) {
            history.push(location.state.from);
            if (survey.id === 32) {
              window.location.reload();
            }

            return null;
          }

          history.push("/personal");
        } catch (err) {
          console.error(err);
        }
      },
  })
);
