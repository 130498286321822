import { ApolloClient } from "apollo-client";
import { ApolloLink } from "apollo-link";
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from "apollo-cache-inmemory";
import { createUploadLink } from "apollo-upload-client";
import { patch } from "smart-cache-invalidation";
import { apiRoute } from "client/authRoutes";
import authSuccess from "./authSuccess";
import errorHandler from "./error";
import reduxStore from "../store";
import introspectionQueryResultData from "./fragmentTypes.js";

patch(ApolloClient, InMemoryCache);

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

const cache = new InMemoryCache({ fragmentMatcher });

const httpLink = createUploadLink({
  uri: apiRoute,
  credentials: "include",
  headers: {
    client: window.location.hostname.includes("localhost")
      ? `${window.location.protocol}//${window.location.hostname}:${window.location.port}`
      : `https://${window.location.hostname}`,
  },
});

const client = new ApolloClient({
  cache,
  link: ApolloLink.from([errorHandler(reduxStore), authSuccess, httpLink]),
  queryDeduplication: true,
  connectToDevTools: process.env.NODE_ENV === "development",
  defaultOptions: {
    query: {
      fetchPolicy: "cache-first",
    },
    watchQuery: {
      fetchPolicy: "cache-first",
    },
  },
});

export default client;
