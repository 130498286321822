import { withProps, withHandlers, withState, compose } from "recompose";
import { graphql } from "react-apollo";
import { loader } from "graphql.macro";
import handleLoading from "utils/loader";
import FullPage from "components/Loaders/FullPage";
import addMembersToTeamDim from "utils/addMembersToTeamDim";

const TEAM_QUERY = loader("client/Queries/TeamQuery124.graphql");
const GENERATE_TEAM_MEMBER_SUMMARY = loader(
  "./generateTeamMemberGPTSummary.graphql"
);
const DELETE_TEAM_MEMBER_SUMMARY = loader(
  "./deleteTeamMemberGPTSummary.graphql"
);

export default compose(
  withState("isGenerating", "toggleGenerating", false),
  handleLoading(FullPage),
  withState("gptSummary", "setGptSummary", ""),
  graphql(GENERATE_TEAM_MEMBER_SUMMARY, {
    name: "generateUserInTeamProfileSummary",
    options: ({ toggleGenerating, setGptSummary, team, teamMember }) => {
      return {
        variables: {
          team_id: parseInt(team.id, 10),
          user_id: parseInt(teamMember.id, 10),
        },
        update: (proxy, { data: { generateUserInTeamProfileSummary } }) => {
          toggleGenerating(false);
          setGptSummary(generateUserInTeamProfileSummary.text);

          const data = proxy.readQuery({
            query: TEAM_QUERY,
            variables: {
              id: parseInt(team.id, 10),
            },
          });

          data.team.members.find((m) => m.id === teamMember.id).summary =
            generateUserInTeamProfileSummary;

          proxy.writeQuery({
            query: TEAM_QUERY,
            variables: {
              id: parseInt(team.id, 10),
            },
            data,
          });
        },
      };
    },
  }),
  graphql(DELETE_TEAM_MEMBER_SUMMARY, {
    name: "deleteMemberInTeamProfileSummary",
    options: ({ setGptSummary, team, teamMember }) => {
      return {
        variables: {
          team_id: parseInt(team.id, 10),
          user_id: parseInt(teamMember.id, 10),
        },
        update: (proxy, { data: { deleteMemberInTeamProfileSummary } }) => {
          setGptSummary("");

          const data = proxy.readQuery({
            query: TEAM_QUERY,
            variables: {
              id: parseInt(team.id, 10),
            },
          });

          data.team.members.find((m) => m.id === teamMember.id).summary = null;

          proxy.writeQuery({
            query: TEAM_QUERY,
            variables: {
              id: parseInt(team.id, 10),
            },
            data,
          });
        },
      };
    },
  }),
  withHandlers({
    handleGenerateSummary:
      ({ generateUserInTeamProfileSummary, toggleGenerating }) =>
      async () => {
        toggleGenerating(true);
        await generateUserInTeamProfileSummary();
        if (window.heap) {
          window.heap.track("create-summary-team-member");
        }
      },
    handleDeleteSummary:
      ({ deleteMemberInTeamProfileSummary }) =>
      async () => {
        await deleteMemberInTeamProfileSummary();
        if (window.heap) {
          window.heap.track("delete-summary-team-member");
        }
      },
  }),
  withProps(({ team, teamMember: { personality, competencies, values } }) => {
    return {
      dimensions: [
        ...personality,
        ...team.personality,
        ...competencies,
        ...team.competencies,
        ...values,
        ...team.values,
      ],
    };
  }),

  withProps(({ team: { personality, competencies, values, members } }) => {
    return {
      dimensions: [
        ...personality.map((dim) => ({
          ...dim,
          members: addMembersToTeamDim({
            dim,
            members,
            dimGroup: "personality",
          }),
        })),
        ...competencies.map((dim) => ({
          ...dim,
          members: addMembersToTeamDim({
            dim,
            members,
            dimGroup: "competencies",
          }),
        })),
        ...values.map((dim) => ({
          ...dim,
          members: addMembersToTeamDim({ dim, members, dimGroup: "values" }),
        })),
      ],
    };
  })
);
