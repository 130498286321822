export default (comp) => {
  return {
    id: `item-${comp.id}`,
    dim_id: comp.id,
    title: comp.title,
    desc: comp.desc,
    behaviours: comp.behaviours,
    descShort: comp.descShort,
    survey_row_id: comp.surveyRowId,
    question_dim_id: comp.id,
    coreComp: false,
    potGap: false,
    dimParent: comp.dimParent,
  };
};
