import React from "react";
import Dimensions from "components/Dimensions";

export default ({ team, member }) => {
  const teamBig5 = team.personality.filter((dim) => !dim.parentGroup);
  return (
    <Dimensions
      team={team}
      data={teamBig5}
      highlight={member}
      resource="member"
    />
  );
};
