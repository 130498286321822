import React, { useState } from "react";
import classNames from "classnames";
import TextareaAutosize from "react-autosize-textarea";
import Icon from "components/UI/Icon";
import Button from "components/UI/Button";

import styles from "./PromptInput.module.scss";

export default ({
  isFullscreen,
  isLoading,
  isReady = true,
  isEnlarged,
  handlePrompt,
}) => {
  const [value, setValue] = useState("");
  const handleEnterPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey && value && value !== "") {
      e.preventDefault();
      e.stopPropagation();
      handlePrompt(value);
      setValue("");
    }
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handlePrompt(value);
        setValue("");
      }}
    >
      <div
        className={classNames(
          styles.inputWrapper,
          isEnlarged && styles.isEnlarged
        )}
      >
        <TextareaAutosize
          maxRows={4}
          name="comment"
          autoFocus={isFullscreen}
          placeholder="Ask WisGPT anything"
          onChange={(e) => {
            setValue(e.target.value);
          }}
          className={classNames(
            styles.textarea,
            value.length > 0 && styles.active
          )}
          rows={1}
          id="cpromptTextarea"
          data-gramm_editor="false"
          onKeyPress={(e) => handleEnterPress(e)}
          disabled={!isReady || isLoading}
          value={value}
        />
        <div className={styles.actionButtons}>
          <span className={styles.sendIcon}>
            <Button
              bStyle={["none"]}
              type="submit"
              disabled={value === "" || isLoading}
            >
              <Icon icon="Send" />
            </Button>
          </span>
        </div>
      </div>
    </form>
  );
};
