import Button from "components/UI/Button";
import EllipsisButton from "components/UI/EllipsisButton";
import Icon from "components/UI/Icon";
import DropDown from "components/UI/DropDown";
import React, { useState } from "react";
import Popup from "reactjs-popup";
import PropTypes from "prop-types";

const ActionShape = PropTypes.shape({
  action: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
    PropTypes.string,
  ]),
  icon: PropTypes.string,
  name: PropTypes.string.isRequired,
});

const Popover = ({ popOverActions, popLeft = false }) => {
  const [_, setOpen] = useState(false);

  return (
    <div>
      <div className="dropdownWrapper" id="dropdownMenu">
        <Popup
          keepTooltipInside="body"
          trigger={<EllipsisButton onClick={() => setOpen((o) => !o)} />}
          position="bottom right"
        >
          <DropDown>
            {popOverActions?.map((action, index) => (
              <li key={index}>
                <Button
                  bStyle={["none"]}
                  onClick={() => action.action()}
                  disabled={action.disabled}
                >
                  {action.icon && <Icon icon={action.icon} marginRight />}
                  {action.faIcon && <i className={action.faIcon} />}
                  {action.name}
                </Button>
              </li>
            ))}
          </DropDown>
        </Popup>
      </div>
    </div>
  );
};

Popover.propTypes = {
  popOverActions: PropTypes.arrayOf(ActionShape).isRequired,
  popLeft: PropTypes.bool,
};

export default Popover;
