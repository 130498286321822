import React from "react";

import LoginForm from "components/Forms/LoginForm";
import SSOButtons from "components/SSOButtons";
import compose from "./compose";
import styles from "./Login.module.scss";

export default compose(({ setLoading, modal, updateModal, authStrategies }) => {
  return (
    <div className={styles.login}>
      <div className={styles.smallerWrapper}>
        <SSOButtons column authStrategies={authStrategies} type="longTitle" />
        {authStrategies.some((strategy) => strategy.name === "local") && (
          <div className={styles.form}>
            <LoginForm />
          </div>
        )}
      </div>
    </div>
  );
});
