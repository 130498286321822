export default (whitelabel) => {
  if (whitelabel && whitelabel.logo && whitelabel.id !== 1) {
    document.whitelabel = 'custom'

    document.documentElement.style.setProperty(
      '--logo_url',
      `url(${whitelabel.logo})`
    )
    Object.keys(whitelabel.styles).forEach((key) => {
      Object.keys(whitelabel.styles[key]).forEach((style) => {
        document.documentElement.style.setProperty(
          `${key}-${style}`,
          whitelabel.styles[key][style]
        )
      })
    })
  } else {
    document.whitelabel = 'default'
  }
}
