import React, { createContext, useContext, useState, useEffect } from "react";

// Step 1: Create a Context
const AppContext = createContext();

// Step 3: Create a Provider
export const AppProvider = ({ children }) => {
  // Step 2: Initialize State from Session Storage
  const initialState = sessionStorage.getItem("appSettings")
    ? JSON.parse(sessionStorage.getItem("appSettings"))
    : { showWisGptPreview: false };

  const [appSettings, setAppSettings] = useState(initialState);

  // Effect to keep sessionStorage in sync
  useEffect(() => {
    sessionStorage.setItem("appSettings", JSON.stringify(appSettings));
  }, [appSettings]);

  return (
    <AppContext.Provider value={{ appSettings, setAppSettings }}>
      {children}
    </AppContext.Provider>
  );
};

// Step 4: Custom Hook
export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("useAppContext must be used within an AppProvider");
  }
  return context;
};
