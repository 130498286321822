import React from "react";
import { useTable } from "react-table";
import classNames from "classnames";
import CustomSelect from "components/UI/CustomSelect";
import Select from "components/UI/Select";
import Placeholder from "../Placeholder";

import styles from "../CompareCandidates.module.scss";

export default ({
  openPosition,
  filter,
  setFilter,
  setOrder,
  getEvaluators,
  selectedEvaluators,
  setSelectedEvaluators,
  columns,
  data,
}) => {
  const tableInstance = useTable({ columns, data });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  return (
    <>
      <div className={styles.bottomInfoWrapper}>
        <div className={styles.bottomInfo}>
          <div className={styles.toolbarButtons}>
            {getEvaluators().length > 0 && (
              <div className={styles.selectWrapper}>
                <CustomSelect
                  cStyle="verySmall"
                  icon="fas fa-user"
                  onChange={(e) => setFilter(e.target.value)}
                  placeholder="Evaluated by"
                  options={getEvaluators()}
                  selectedEvaluators={selectedEvaluators}
                  setSelectedEvaluators={setSelectedEvaluators}
                />
              </div>
            )}
            <div className={styles.selectWrapper}>
              <Select
                cStyle="verySmall"
                icon="fas fa-columns"
                onChange={(e) => setFilter(e.target.value)}
                defaultValue="compare"
              >
                <option value="compare">In compare</option>
                <option value="all">All profiles</option>
              </Select>
            </div>
            <div className={styles.selectWrapper}>
              <Select
                className={styles.orderSelect}
                cStyle="verySmall"
                icon="fas fa-sort"
                onChange={(e) => setOrder(e.target.value)}
              >
                <option value="" disabled selected>
                  Order by
                </option>
                <option value="requirements">Requirements</option>
                <option value="competencies">Competencies</option>
                <option value="team_fit">Team fit</option>
                <option value="total">Total</option>
              </Select>
            </div>
          </div>
        </div>
      </div>
      <div
        className={classNames(
          styles.tableWrapper,
          (filter === "compare" &&
            openPosition.kanbanColumns[3].candidatesUnion.length < 1) ||
            (filter === "all" && openPosition.candidatesUnion.length < 1)
            ? styles.center
            : styles.something
        )}
      >
        {(filter === "compare" &&
          openPosition.kanbanColumns[3].candidatesUnion.length > 0) ||
        (filter === "all" && openPosition.candidatesUnion.length > 0) ? (
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);

                return (
                  <tr
                    {...row.getRowProps()}
                    className={styles[row.original.className]}
                  >
                    {row.cells.map((cell) => (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <Placeholder type={filter} />
        )}
      </div>
    </>
  );
};
