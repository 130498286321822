import React from 'react'
import Wrapper from 'components/UI/Wrapper'
import Title from 'components/UI/Title'
import Subtitle from 'components/UI/Subtitle'
import TopValues from 'components/TopValues'
import ValuesChart from './Chart'
import styles from './Values.module.scss'

export default ({ user }) => {
  return (
    <div className={styles.main}>
      <Wrapper>
        <Title text="Values map" icon="fas fa-balance-scale" />
        <Subtitle text="Your personal motivators." />
        <Subtitle
          verySmall
          text="The long-term goals and principles that drive your actions."
        />
        <ValuesChart user={user} />
        {user.personalProfile.valuesChart.readyToRender && (
          <TopValues
            data={user.personalProfile}
            user={user.personalProfile}
            member={user}
            resource="orgUser"
          />
        )}
      </Wrapper>
    </div>
  )
}
