export default ({ cache, proxy, team_id }) => {
  // const positions = Object.keys(cache.data).filter(
  //   key => key.match(/^TeamType:([0-9]+)$/) !== null
  // )

  const teamKeys = Object.keys(cache.data).filter(key => key.includes(`TeamType:${team_id}`))
  // console.log(teamKeys)
  teamKeys.forEach((key) => {
    delete cache.data[key]
  })

  proxy.restore(cache.data)
  return true
}
