import React from "react";
import Button from "components/UI/Button";

import styles from "./ErrorPage.module.scss";

export default () => (
  <div className={styles.main}>
    <div className={styles.wrapper}>
      <div className={styles.logo} />
      <i className="far fa-times-octagon" />
      <h1>Oops, something went wrong.</h1>
      <h1 className={styles.second}>Refresh page to try again.</h1>
      <p className={styles.subtitle}>
        If the problem persists, please contact us at{" "}
        <a href="mailto:support@wisnio.com">support@wisnio.com</a> or use chat.
      </p>
      <Button
        bStyle={["primary", "large"]}
        type="button"
        onClick={() => {
          window.location.replace("/dashboard");
        }}
      >
        Back to safety
      </Button>
    </div>
  </div>
);
