import React, { useState } from "react";
import Button from "components/UI/Button";
import Modal from "react-modal";
import classNames from "classnames";
import Icon from "components/UI/Icon";
import LinkHandler from "./Member/LinkHandler";
import RemoveButton from "./Member/RemoveButton";
import InviteMembers from "./InviteMembers";
import compose from "./compose";

import Member from "./Member";

import styles from "./TeamMembers.module.scss";

export default compose(
  ({
    team,
    teamMember,
    members,
    showRemoveBtn,
    pos,
    removeFromTeam,
    removeTeamFromInvite,
    overlayClose,
    setOverlayClose,
    client,
    refetch,
    isTour,
    readOnly,
    toggleShowTeamTour,
  }) => {
    const [openModal, updateModal] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const membersMapped = teamMember
      ? members.filter((m) => m.id !== teamMember.id)
      : members;

    return (
      <div className={classNames(styles.wrapper, "teamMembers")}>
        {team.adminRights && !pos && !readOnly && (
          <>
            <Button
              type="button"
              bStyle={["none"]}
              onClick={() => updateModal("inviteMembers")}
            >
              <div className={styles.addMember}>
                <div className={styles.icon}>
                  <Icon icon="AddUser" />
                </div>
                <h4>Add</h4>
              </div>
            </Button>
            <Modal
              closeTimeoutMS={350}
              isOpen={openModal === "inviteMembers"}
              onRequestClose={() => updateModal("")}
              shouldCloseOnOverlayClick={overlayClose}
              shouldCloseOnEsc={overlayClose}
              className={styles.modal}
            >
              <InviteMembers
                team={team}
                updateModal={updateModal}
                setOverlayClose={setOverlayClose}
                refetch={refetch}
              />
            </Modal>
          </>
        )}
        {membersMapped.map((m) => (
          <div
            key={`member-${m.id}`}
            className={classNames(
              styles.memberWrapper,
              isLoading && isLoading === m && styles.isLoading
            )}
          >
            {team.adminRights && showRemoveBtn && !readOnly && (
              <div className={styles.removeWrapper}>
                <RemoveButton
                  team={team}
                  member={m}
                  removeFromTeam={removeFromTeam}
                  removeTeamFromInvite={removeTeamFromInvite}
                  client={client}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  refetch={refetch}
                />
              </div>
            )}
            <LinkHandler
              link={m.link}
              key={`candidate${Math.random(10)}`}
              isTour={isTour}
              toggleShowTeamTour={toggleShowTeamTour}
              readOnly={readOnly}
            >
              <Member member={m} key={m.id} team={team} isLoading={isLoading} />
            </LinkHandler>
          </div>
        ))}
        {team.members.filter((m) => m.readyToRender).length > 1 &&
          !pos &&
          !readOnly && (
            <p className={styles.legend}>
              <i className="fal fa-hand-pointer" />
              Click on a team member to see their profile compared to the team.
            </p>
          )}
      </div>
    );
  }
);
