import React from "react";
import { useTour } from "@reactour/tour";
import classNames from "classnames";
import Modal from "react-modal";
import getAlignment from "utils/getAlignment";
import Button from "components/UI/Button";
import styles from "./index.module.scss";
import Template from "./Template";

export default ({
  values,
  valueGroups,
  dim,
  updateModal,
  isStruggle,
  resource,
  members,
  highlight,
  candidate,
  color,
  casestudy,
  isCandidate,
  isImportant,
  team,
  member,
}) => {
  if (!dim || !dim.name) return null;

  const { isOpen, setIsOpen } = useTour();

  const currentDimIndex = values.findIndex((d) => d.name === dim.name);
  const currentDim = values[currentDimIndex];
  const incrementedDimIndex = currentDimIndex + 1;
  const decrementedDimIndex = currentDimIndex - 1;
  const next = incrementedDimIndex === values.length ? 0 : incrementedDimIndex;
  const prev =
    decrementedDimIndex === -1 ? values.length - 1 : decrementedDimIndex;
  const nextDim = values[next];
  const prevDim = values[prev];

  const parentTitle = valueGroups.find((group) => {
    return group.dim_id === dim.parent_id;
  }).name;

  const alignment =
    resource === "team"
      ? dim.alignment
      : resource === "member"
      ? getAlignment({
          dim,
          team,
          teamMember: highlight || candidate,
          resource: "values",
        }).label
      : null;

  return (
    <Modal
      closeTimeoutMS={350}
      isOpen={dim.name && true}
      onAfterOpen={() => resource === "team" && setIsOpen(false)}
      onAfterClose={() => resource === "team" && setIsOpen(true)}
      onRequestClose={() => updateModal({})}
      shouldCloseOnOverlayClick
      className={styles.modal}
    >
      <Button bStyle="close" type="button" onClick={() => updateModal({})}>
        <i className="fal fa-times" />
      </Button>

      <div className={styles.desktop}>
        <div className={styles.prevButton}>
          <Button
            bstyle={["arrowButton", "left"]}
            type="button"
            onClick={() => updateModal(prevDim)}
          >
            <i className="fas fa-chevron-left" />
          </Button>
        </div>
      </div>

      <div
        className={classNames(
          styles.modalWrapper,
          isImportant ? styles.important : isStruggle ? styles.struggle : null
        )}
      >
        <div className={styles.info}>
          <h4 className={styles.facet}>{parentTitle}</h4>
          <div className={styles.titleNButtons}>
            <div className={styles.mobile}>
              <Button
                bstyle={["none"]}
                type="button"
                onClick={() => updateModal(prevDim)}
              >
                <i className="fas fa-chevron-left" />
              </Button>
            </div>
            <h2>{dim.name}</h2>
            <div className={styles.mobile}>
              <Button
                bstyle={["none"]}
                type="button"
                onClick={() => updateModal(nextDim)}
              >
                <i className="fas fa-chevron-right" />
              </Button>
            </div>
          </div>
          <h4 className={styles.subtitle}>{dim.definition}</h4>
        </div>

        <Template
          styles={styles}
          dim={resource === "member" ? currentDim : dim}
          members={members}
          highlight={highlight}
          candidate={candidate}
          isCandidate={isCandidate}
          isImportant={isImportant}
          resource={resource}
          alignment={alignment}
          color={color && color}
          casestudy={casestudy}
          member={member}
        />

        <div className={styles.footerButtons}>
          <Button
            bstyle={["none"]}
            type="button"
            onClick={() => updateModal(prevDim)}
          >
            <i className="fas fa-chevron-left" />
            <div className={styles.text}>
              <span>Previous value</span>
              <p>{prevDim.name}</p>
            </div>
          </Button>
          <Button
            bstyle={["none"]}
            type="button"
            onClick={() => updateModal(nextDim)}
          >
            <i className="fas fa-chevron-right" />
            <div className={styles.text}>
              <span>Next value</span>
              <p>{nextDim.name}</p>
            </div>
          </Button>
        </div>
      </div>

      <div className={styles.desktop}>
        <div className={styles.nextButton}>
          <Button
            bstyle={["arrowButton", "right"]}
            type="button"
            onClick={() => updateModal(nextDim)}
          >
            <i className="fas fa-chevron-right" />
          </Button>
        </div>
      </div>
    </Modal>
  );
};
