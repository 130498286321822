import React from "react";
import { Link } from "react-scroll";
import Headroom from "react-headroom";
import Button from "components/UI/Button";
import classNames from "classnames";
import compose from "./compose";

import styles from "./DimensionsNavigation.module.scss";

export default compose(
  ({ scrolling, items, selectedDimension, setSelectedDimension }) => (
    <Headroom className="profileNav">
      <div className={classNames(styles.main, scrolling && styles.scrolling)}>
        <div className={styles.wrapper}>
          <div className={styles.row}>
            {items.map((item, i) => (
              <p
                key={`item-${item}`}
                className={classNames(
                  styles.listItem,
                  selectedDimension === i && styles.activeLink
                )}
              >
                <Button
                  onClick={() => {
                    document
                      .querySelectorAll(".playDimension")[0]
                      .scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                      });
                    setSelectedDimension(i);
                  }}
                >
                  {item}
                </Button>
              </p>
            ))}
          </div>
        </div>
      </div>
    </Headroom>
  )
);
