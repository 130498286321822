import React from "react";
import Button from "components/UI/Button";

import styles from "./ConfirmationPopup.module.scss";

export default ({ title, action, close }) => {
  return (
    <div className={styles.confirmationPopup}>
      {title && <h5 className={styles.title}>{title}</h5>}
      <div className={styles.buttons}>
        <Button bStyle={["verySmall", "primary"]} onClick={action}>
          Yes
        </Button>
        <Button bStyle={["verySmall", "newsimple"]} onClick={close}>
          Cancel
        </Button>
      </div>
    </div>
  );
};
