import {
  compose, withState, withHandlers, lifecycle
} from 'recompose'

export default compose(
  withState('open', 'setOpen', false),
  withHandlers({
    manageSelect: ({ open, setOpen }) => (e) => {
      const select = document.querySelector('.customSelect')
      const option = document.querySelector('.option')
      if (open && !select.contains(e.target) && !option.contains(e.target)) {
        setOpen(false)
      }
    }
  }),
  lifecycle({
    componentWillMount() {
      window.addEventListener('click', this.props.manageSelect)
    },
    componentWillUnmount() {
      window.removeEventListener('click', this.props.manageSelect)
    }
  })
)
