import React, { useState } from "react";
import classNames from "classnames";
import Popup from "reactjs-popup";
import Timestamp from "react-timestamp";
import Button from "components/UI/Button";
import UserAvatar from "components/UserAvatar";
import PercentageCircle from "components/UI/PercentageCircle";
import { ReactComponent as HighDevImage } from "images/position/deviation.svg";
import NewComment from "components/NewComment";
import BigChart from "../BigChart";
import compose from "./compose";

import styles from "./PlayDimension.module.scss";

export default compose(
  ({
    show,
    play,
    dimension,
    facets,
    viewer,
    comment,
    setComment,
    handleCreateComment,
    handleRemoveComment,
    showAccordion,
    setShowAccordion,
  }) => {
    return (
      <div className={classNames(styles.wrapper, show && styles.show)}>
        <div className={styles.top}>
          <div className={styles.left}>
            <h2 className={styles.title}>{dimension.name}</h2>
            <p className={styles.description}>{dimension.description}</p>
          </div>
          <div className={styles.chart}>
            <BigChart
              members={play.players}
              dim={dimension}
              min="Low"
              neutral="Neutral"
              max="High"
            />
          </div>
        </div>
        {facets.length > 0 && (
          <div className={styles.facetsWrapper}>
            {facets
              .sort((a, b) => b.score - a.score)
              .map((facet) => {
                const playerScores = [];

                play.players.map((p) => {
                  typeof p.effectiveness.find((e) => e.name === facet.name)
                    .score === "number" &&
                    playerScores.push(
                      p.effectiveness.find((e) => e.name === facet.name).score
                    );
                });

                const highDev =
                  Math.max(...playerScores) - facet.score >= 40 ||
                  facet.score - Math.min(...playerScores) >= 40;

                return (
                  <div key={"facet-" + facet.dim_id} className={styles.facet}>
                    <div className={styles.left}>
                      <div className={styles.text}>
                        <h5>{facet.name}</h5>
                        <p>{facet.description}</p>
                      </div>
                      <div className={styles.facetChart}>
                        <BigChart
                          members={play.players}
                          dim={facet}
                          min="Low"
                          neutral="Neutral"
                          max="High"
                          minimal
                        />
                      </div>
                    </div>
                    {highDev && (
                      <div className={styles.right}>
                        <h5>High deviation</h5>
                        <p>
                          Scores between team members vary greatly. Discuss this
                          item with your team to understand the cause.
                        </p>
                      </div>
                    )}
                  </div>
                );
              })}
          </div>
        )}
        <div className={styles.commentsWrapper}>
          <h4 className={styles.title}>
            Comments
            <span>
              {
                play.comments.filter((c) => c.item_id === dimension.dim_id)
                  .length
              }
            </span>
          </h4>
          <div className={styles.comments}>
            {play.comments
              .filter((c) => c.item_id === dimension.dim_id)
              .map((comment) => {
                const date = new Date(comment.created_at * 1000);

                return (
                  <div key={"Comment-" + comment.id} className={styles.comment}>
                    <div className={styles.avatar}>
                      <UserAvatar member={comment.createdBy} size="small" />
                    </div>
                    <div className={styles.right}>
                      <div className={styles.top}>
                        <h5 className={styles.name}>
                          {comment.createdBy.name}
                        </h5>
                        <p className={styles.time}>
                          <Timestamp date={date} autoUpdate />
                        </p>
                        {viewer.id === comment.createdBy.id && (
                          <div className={styles.removeButton}>
                            <Button
                              bStyle={["none"]}
                              onClick={() => handleRemoveComment(comment)}
                            >
                              <i className="far fa-times" />
                            </Button>
                          </div>
                        )}
                      </div>
                      <p className={styles.message}>{comment.comment}</p>
                    </div>
                  </div>
                );
              })}
          </div>
          <div className={styles.newComment}>
            <NewComment
              handleCreateComment={handleCreateComment}
              comment={comment}
              setComment={setComment}
              disabled={false}
            />
          </div>
        </div>
      </div>
    );
  }
);
