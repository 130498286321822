import React from "react";
import classNames from "classnames";
import UserAvatar from "components/UserAvatar";
import styles from "./TeamMemberAvatars.module.scss";

export default ({ members, small, length, size, showName }) => (
  <div
    className={classNames(
      styles.wrapper,
      small && styles.small,
      size && styles[size],
      members.length > 5 && styles.more
    )}
  >
    {members.filter((m) => m.avatar_url).length >= 5 &&
      members
        .filter((m) => m.avatar_url !== null)
        .slice(0, 5)
        .map((member) => (
          <div
            className={styles.avatarWrapper}
            key={member.id + Math.random(10)}
          >
            <UserAvatar member={member} size={size} showName={showName} />
          </div>
        ))}
    {members.filter((m) => m.avatar_url).length < 5 &&
      members.slice(0, 5).map((member) => (
        <div className={styles.avatarWrapper} key={member.id + Math.random(10)}>
          <UserAvatar member={member} size={size} showName={showName} />
        </div>
      ))}
    {members.length > 5 && (
      <div className={styles.andMore}>
        <p>{`+${length - 5 || members.length - 5}`}</p>
      </div>
    )}
    {members.length === 0 && (
      <div className={styles.placeholder}>
        <i className="fas fa-users" />
      </div>
    )}
  </div>
);
