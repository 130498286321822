import React from 'react'
import classNames from 'classnames'
import CandidateCard from 'components/Card'
import UserAvatar from 'components/UserAvatar'

import styles from './Candidate.module.scss'

export default ({ candidate }) => (
  <CandidateCard
    cardStyle={['candidate', candidate.label && candidate.label.style]}
  >
    <div aria-label='avatarWrapper'>
      <div aria-label='avatarWrapper'>
        <UserAvatar
          member={candidate}
          isInvite={candidate.isInvite}
          size='medium'
        />
      </div>
    </div>
    <h4>{candidate.name}</h4>
    {candidate.description && <p>{candidate.description}</p>}
    {candidate.label && (
      <p className={classNames(styles.label, styles[candidate.label.style])}>
        {candidate.label.text}
      </p>
    )}
  </CandidateCard>
)
