import React from "react"
import { compose, withState } from "recompose"
import { graphql } from "react-apollo"
import { loader } from "graphql.macro"
import handleLoading from "utils/loader"
import FullPage from "components/Loaders/FullPage"
import { withRouter } from "react-router-dom"

const ACCOUNT_ORGANIZATIONS = loader("./AccountOrganizations1.graphql")
const DELETE_ORG = loader("./DeleteOrg1.graphql")

export default compose(
  withRouter,
  withState("showDeletePrompt", "updateDeletePrompt", false),
  graphql(ACCOUNT_ORGANIZATIONS, {
    options: (props) => {
      return {
        variables: { id: parseInt(props.match.params.accountId, 10) },
      }
    },
  }),
  graphql(DELETE_ORG, {
    name: "deleteOrg",
    options: (props) => {
      return {
        update: () => {
          props.data.refetch()
          props.updateDeletePrompt(false)
        },
      }
    },
  }),
  handleLoading(() => <FullPage />)
)
