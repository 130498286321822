import React from 'react'
import Button from 'components/UI/Button'
import { Form, Field } from 'react-final-form'
import { Link } from 'react-router-dom'
import Modal from 'react-modal'
import classNames from 'classnames'
import compose from './compose'
import ForgotPassword from './ForgotPassword'

import styles from './LoginForm.module.scss'

export default compose(
  ({
    onSubmit,
    forgotModal,
    setForgotModal,
    caseStudy,
    showPassword,
    setShowPassword
  }) => {
    const togglePassword = (e) => {
      const password = document.getElementById('password')
      if (password.type === 'password') {
        password.type = 'text'
        setShowPassword(true)
      } else {
        password.type = 'password'
        setShowPassword(false)
      }
    }
    return (
      <Form
        onSubmit={onSubmit}
        render={({
          submitError,
          handleSubmit,
          form,
          submitting,
          pristine,
          errors,
          valid,
          values
        }) => (
          <>
            <form onSubmit={handleSubmit}>
              <div className={styles.row}>
                <div>
                  <div className={styles.inputTop}>
                    <p className={styles.label}>Email address *</p>
                  </div>
                  <Field
                    name='email'
                    component='input'
                    placeholder='Enter email'
                    type='email'
                    required
                  />
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.passwordField}>
                  <div className={styles.inputTop}>
                    <p className={styles.label}>Password *</p>
                    <p
                      className={styles.forgot}
                      onClick={() => setForgotModal(true)}
                    >
                      Forgot password?
                    </p>
                  </div>
                  <Field
                    id='password'
                    name='password'
                    component='input'
                    type='password'
                    placeholder='Enter password'
                    required
                  />
                  <div className={styles.showPassword}>
                    <Button
                      bStyle={['none']}
                      type='button'
                      onClick={e => togglePassword(e)}
                    >
                      {showPassword ? (
                        <i className='far fa-eye-slash' />
                      ) : (
                        <i className='far fa-eye' />
                      )}
                    </Button>
                  </div>
                </div>
              </div>
              {(!valid || submitError) && (
                <div className={styles.submitError}>
                  {submitError && <p>{submitError}</p>}
                  {errors
                    && Object.values(errors).map((err, key) => (
                      <p key={key}>{err}</p>
                    ))}
                </div>
              )}
              <div className={styles.row}>
                <label htmlFor='rememberMe' className={styles.rememberMe}>
                  <Field
                    id='rememberMe'
                    type='checkbox'
                    component='input'
                    name='rememberMe'
                  />
                  <p className={styles.label}>Remember me</p>
                </label>
              </div>
              <div
                className={classNames(
                  styles.loginButtons,
                  caseStudy && styles.caseStudy
                )}
              >
                <Button
                  bStyle={['primary', 'large']}
                  type='submit'
                  disabled={
                    submitting || pristine || !values.email || !values.password
                  }
                >
                  Log in
                </Button>
              </div>
            </form>
            <Modal
              closeTimeoutMS={350}
              isOpen={forgotModal}
              onRequestClose={() => setForgotModal(false)}
              shouldCloseOnOverlayClick
              className='fullModal'
            >
              <Button
                bStyle='close'
                type='button'
                onClick={() => setForgotModal(false)}
              >
                <i className='fal fa-times' />
              </Button>
              <ForgotPassword closeModal={() => setForgotModal(false)} />
            </Modal>
          </>
        )}
      />
    )
  }
)
