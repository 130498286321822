import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import Popup from "reactjs-popup";
import Timestamp from "react-timestamp";
import AILabel from "components/UI/AILabel";
import Icon from "components/UI/Icon";
import LoaderBar from "components/LoaderBar";
import AIContentTag from "./AIContentTag";
import User from "./User";
import ProfileBasedOn from "./ProfileBasedOn";
import Button from "components/UI/Button";
import styles from "./Summary.module.scss";
import compose from "./compose";
import GPTSummaryEmptyState from "components/UI/GPTSummaryEmptyState";

function getDimGroup(dim) {
  if (dim.groupId && dim.groupId.includes("values")) {
    return "values";
  }
  if (dim.groupId && dim.groupId.includes("personality")) {
    return "big5";
  }
  if (dim.__typename && dim.__typename === "CompetencyDimension") {
    return "competencies";
  }
}

const colors = {
  values: "yellow",
  big5: "blue",
  competencies: "green",
};

const getContentHtml = (content) => {
  return {
    __html: content,
  };
};

export default compose(
  ({
    handleGenerateSummary,
    isGenerating,
    viewer,
    dimensions,
    gptSummary,
    handleDeleteSummary,
  }) => {
    const summaryRef = useRef();

    const [activeTag, setActiveTag] = useState(null);
    const [activeTagDim, setActiveTagDim] = useState(null);
    const [container, setContainer] = useState(null);

    const handleTagHover = ({ span, dim }) => {
      setActiveTag(span);
      setActiveTagDim(dim);
    };

    useEffect(() => {
      const spans = summaryRef.current.querySelectorAll("span");

      spans.forEach((span, index) => {
        const dim_id = parseInt(span.getAttribute("dim_id"), 10);
        const dim = dimensions.find((dim) => dim.dim_id === dim_id);

        if (dim) {
          span.classList.add(
            styles.textLabel,
            styles[colors[getDimGroup(dim)]]
          );
          span.addEventListener("mouseover", () => {
            handleTagHover({ span, dim });
          });
          span.addEventListener("mouseout", () => {
            setActiveTag(null);
            setActiveTagDim(null);
          });
        }
      });
    }, [summaryRef, gptSummary]);

    useEffect(() => {
      setContainer(summaryRef.current);
    }, [summaryRef]);

    return (
      <div className={styles.wrapper}>
        <div className={styles.row}>
          <div className={styles.left}>
            <User />
          </div>
          <div className={styles.right}>
            <ProfileBasedOn />
          </div>
        </div>

        {gptSummary === "" ? (
          <GPTSummaryEmptyState
            readyToRender={
              viewer.ready_to_render &&
              !dimensions
                .filter((d) => d.__typename === "CompetencyDimension")
                .some((c) => c.score === null)
            }
            action={handleGenerateSummary}
            isLoading={isGenerating}
            title="Explain your profile with AI"
            description="Our AI assistant extracts and analyses vital insights from over 200 data points of your Wisnio Profile, helping you understand key insights and the most important aspects faster."
            disabledMessage="Please complete the surveys to use this feature."
          />
        ) : (
          <div className={styles.summaryHeader}>
            <div className={styles.left}>
              <div className="aiPopupWrapper">
                <Popup
                  trigger={
                    <div className={styles.aiLabel}>
                      <AILabel />
                    </div>
                  }
                  on="hover"
                  keepTooltipInside="body"
                  position="bottom left"
                  arrow={false}
                >
                  <div className={styles.aiPopup}>
                    <p>
                      Just a heads-up: the content in this section is enhanced
                      by Artificial Intelligence. While this technology adds a
                      cutting-edge touch, it may occasionally lead to unexpected
                      or unconventional statements. Your feedback on it would be
                      much appreciated, so please let us know if you have any
                      questions or concerns.
                    </p>
                  </div>
                </Popup>
              </div>
              {viewer.summary && viewer.summary.updatedAt && (
                <p className={styles.updatedAt}>
                  Last updated:
                  <span className={styles.time}>
                    <Timestamp
                      date={new Date(viewer.summary.updatedAt)}
                      autoUpdate
                    />
                  </span>
                </p>
              )}
            </div>
            <div className={styles.buttonGroup}>
              <div
                className={classNames(
                  styles.generateButton,
                  isGenerating && styles.generating
                )}
              >
                <Button
                  bStyle={["primary", "verySmall"]}
                  onClick={() => handleGenerateSummary()}
                >
                  {isGenerating ? (
                    <span className={styles.loadingIcon}>
                      <i className="far fa-spinner-third" />
                    </span>
                  ) : (
                    <Icon icon="Refresh" marginRight />
                  )}
                  {isGenerating
                    ? "Generating..."
                    : viewer.summary && viewer.summary.text
                    ? "Regenerate summary"
                    : "Generate summary"}
                </Button>
              </div>
              {!isGenerating && gptSummary && (
                <Button
                  bStyle={["secondary", "verySmall"]}
                  onClick={() => handleDeleteSummary()}
                >
                  <Icon icon="Close" marginRight />
                  Clear summary
                </Button>
              )}
            </div>
          </div>
        )}
        <div
          className={classNames(
            styles.contentWrapper,
            isGenerating && styles.generating
          )}
        >
          {isGenerating && (
            <div
              className={classNames(
                styles.loaderBar,
                !gptSummary && styles.firstTime
              )}
            >
              <LoaderBar isGenerating={isGenerating} />
              <div className={styles.text}>
                <p className={styles.loadingText}>Generating summary</p>
              </div>
            </div>
          )}
          <div
            className={classNames(
              styles.content,
              isGenerating && styles.generating
            )}
            ref={summaryRef}
            id="summary-container"
          >
            <div dangerouslySetInnerHTML={getContentHtml(gptSummary)} />
            {activeTag && activeTagDim && (
              <AIContentTag
                dim={activeTagDim}
                tag={activeTag}
                viewer={viewer}
                container={container}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
);
