import React from "react";
import Button from "components/UI/Button";
import NewCard from "components/Card";
import Icon from "components/UI/Icon";
import ModalTitle from "components/UI/ModalTitle";
import Modal from "react-modal";
import Invite from "components/Invite";
import compose from "./compose";
import Candidate from "./Candidate";
import LinkHandler from "./Candidate/LinkHandler";
import styles from "./Candidates.module.scss";

export default compose(
  ({
    position,
    candidates,
    handleAddCandidatesToPosition,
    isLoading,
    overlayClose,
    setOverlayClose,
    modal,
    updateModal,
  }) => (
    <div className={styles.positionList}>
      <div className={styles.createPositionButton}>
        <Button
          bStyle={["none"]}
          type="button"
          onClick={() => updateModal("addCandidates")}
        >
          <NewCard cardStyle={["new", "candidate"]}>
            <Icon icon="Plus" />
            <h4>Add candidates</h4>
          </NewCard>
        </Button>
        <Modal
          isOpen={modal === "addCandidates"}
          onRequestClose={() => updateModal("")}
          shouldCloseOnOverlayClick={overlayClose}
          shouldCloseOnEsc={overlayClose}
          className={styles.modal}
        >
          <Button bStyle="close" type="button" onClick={() => updateModal("")}>
            <i className="fal fa-times" />
          </Button>
          <div className={styles.modalHead}>
            <ModalTitle icon="AddUser">Add candidates</ModalTitle>
            <p>Use the form below to add new candidates to the position.</p>
          </div>
          <Invite
            org_id={position.parentOrganization.id}
            searchEnabled={position.parentOrganization.adminRights}
            updateModal={updateModal}
            setOverlayClose={setOverlayClose}
            position_id={position.id}
            team_id={position.parentTeam.id}
            handleSubmit={({ list }) => handleAddCandidatesToPosition(list)}
            color="green"
            isLoading={isLoading}
          />
        </Modal>
      </div>

      {candidates.map((candidate) => (
        <LinkHandler link={candidate.link} key={`candidate${Math.random(10)}`}>
          <Candidate candidate={candidate} />
        </LinkHandler>
      ))}
    </div>
  )
);
