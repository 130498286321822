import { compose, withHandlers, withState } from 'recompose'
import { withRouter } from 'react-router-dom'
import { graphql } from 'react-apollo'
import { loader } from 'graphql.macro'

const ADD_POSITION_ADMINS = loader('./addPositionAdmins4.graphql')
const REMOVE_POSITION_ADMIN = loader('./removePositionAdmin3.graphql')

export default compose(
  withRouter,
  withState('isLoading', 'setLoading', false),
  graphql(ADD_POSITION_ADMINS, {
    name: 'addPositionAdmins'
  }),
  graphql(REMOVE_POSITION_ADMIN, {
    name: 'removePositonAdmin'
  }),
  withHandlers({
    addAdminsToPosition: ({
      position,
      addPositionAdmins,
      setLoading
    }) => async (list) => {
      setLoading(true)
      const users = list.map((i) => {
        return {
          obj_id: i.id,
          type: i.type,
          email: i.email
        }
      })

      addPositionAdmins({
        variables: {
          team_id: position.parent_team_id,
          org_id: position.parent_org_id,
          position_id: position.id,
          users
        }
      }).then(() => {
        setLoading(false)
      })
    },
    handleRemoveAdmin: ({ removePositonAdmin, position }) => async ({
      contractId,
      inviteId,
      type
    }) => {
      await removePositonAdmin({
        variables: {
          contract_id: contractId,
          invite_id: inviteId,
          position_id: position.id,
          type
        }
      })
    }
  })
)
