import React from 'react'
import { Droppable, Draggable } from 'react-beautiful-dnd'
import classNames from 'classnames'
import UserAvatar from 'components/UserAvatar'
import styles from './GroupGoals.module.scss'

export default ({ goals }) => (
  <div className={styles.goalsWrapper}>
    <div className={styles.teamGoals}>
      <Droppable
        droppableId='ungroupedGoals'
        direction='horizontal'
        className={styles.droppableBlock}
        key={Math.random(10)}
      >
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className={classNames(
              styles.droppable,
              snapshot.isDraggingOver && styles.isDragging
            )}
          >
            {goals.map((g, index2) => (
              <Draggable key={g.id} draggableId={`${g.id}`} index={index2}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className={classNames(
                      styles.draggable,
                      snapshot.isDragging && styles.draggingBlock
                    )}
                  >
                    <div className={styles.goal}>
                      <div className={styles.avatarWrapper}>
                        <UserAvatar
                          member={g.createdBy}
                          size='small'
                          showName
                        />
                      </div>
                      <div className={styles.text}>
                        <h5>{g.title}</h5>
                        <p>{g.description}</p>
                      </div>
                      <h5 className={styles.name}>{g.createdBy.first_name}</h5>
                    </div>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  </div>
)
