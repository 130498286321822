import { lifecycle } from 'recompose'

export default lifecycle({
  componentWillUpdate(newProps) {
    // const shouldSetInitialResults = this.props.TALENT_POOL_QUERY.loading
    //   && !newProps.TALENT_POOL_QUERY.loading
    //   && newProps.results.length === 0
    //
    // if (shouldSetInitialResults) {
    //   this.props.setResults(newProps.TALENT_POOL_QUERY.organization.talentPool)
    // }
    const shouldFetchMore =
      newProps.role !== this.props.role ||
      newProps.limit !== this.props.limit ||
      newProps.order.direction !== this.props.order.direction ||
      newProps.order.column !== this.props.order.column ||
      newProps.limit * newProps.pageNr !==
        this.props.limit * this.props.pageNr

    if (shouldFetchMore) {
      const variables = {
        id: parseInt(this.props.match.params.orgId, 10),
        offset: newProps.limit * newProps.pageNr,
        limit: parseInt(newProps.limit, 10),
        orderDirection: newProps.order.direction,
        orderColumn: newProps.order.column,
        role: newProps.role
      }

      this.props.TALENT_POOL_QUERY.fetchMore({
        variables,
        updateQuery: (cache, { fetchMoreResult }) => {
          // const newCache = Object.assign({}, cache, {
          //   organization: Object.assign({}, cache.organization, {
          //     talentPool: [
          //       ...cache.organization.talentPool,
          //       ...fetchMoreResult.organization.talentPool
          //         .map((user) => {
          //           if (
          //             !cache.organization.talentPool.some(
          //               u => `${u.type}:${u.id}` === `${user.type}:${user.id}`
          //             )
          //           ) {
          //             return user
          //           }
          //         })
          //         .filter(Boolean)
          //     ]
          //   })
          // })
          // if (variables.limit > newCache.organization.usersInTalentPool) {
          //   this.props.setPageNr(0)
          // }
          return fetchMoreResult
        }
      })
    }
    return newProps
  }
})
