import getAlignment from "utils/getAlignment";

export default (candidate, viewer, selectedEvaluators) => {
  const list = [];

  list.push({
    bigTitle: "Position requirements",
    description:
      "Use these evaluation scores and stakeholder comments to understand if the candidate has the right experience, qualifications, and skills required to achieve the key objectives of the role?",
    color: "primary",
  });

  if (candidate.position.requirements.length < 1) {
    list.push({
      message: "Position requirements have not been defined.",
    });
  }

  candidate.position.requirements.map((req) => {
    const evaluations = candidate.evaluation.filter(
      (e) => e.item_id === req.id && e.type === "requirement"
    );

    const filteredEvaluations =
      selectedEvaluators.length > 0
        ? evaluations.filter((e) =>
            selectedEvaluators.some((se) => se.id === e.evaluatedBy.id)
          )
        : evaluations;

    const bigScore =
      filteredEvaluations && filteredEvaluations.length > 0
        ? Math.round(
            (filteredEvaluations
              .filter((e) => e.evaluation !== 0)
              .reduce((a, v) => (a += v.evaluation), 0) /
              filteredEvaluations.filter((e) => e.evaluation !== 0).length) *
              100
          ) / 100
        : null;

    list.push({
      type: "Position requirements",
      resource: "requirements",
      evType: "requirement",
      item_id: req.id,
      color: "primary",
      title: req.title,
      desc: null,
      evaluations: filteredEvaluations && filteredEvaluations,
      deviation:
        Math.max(
          ...filteredEvaluations
            .filter((e) => e.evaluation !== 0)
            .map((r) => r.evaluation)
        ) -
        Math.min(
          ...filteredEvaluations
            .filter((e) => e.evaluation !== 0)
            .map((r) => r.evaluation)
        ),
      score: bigScore,
    });
  });

  if (candidate.type !== "person") {
    list.push({
      bigTitle: "Competencies",
      description:
        "Use these evaluation scores and stakeholder comments to understand if the candidate has the leadership skills and competencies required to achieve superior performance in the role. ",
      color: "green",
    });
    if (!candidate.position.competenciesHaveBeenConfed) {
      // pos comps defined, cand comps missing
      list.push({
        message: "Position competencies have not been mapped.",
      });
    }
    candidate.position.competenciesHaveBeenConfed &&
      candidate.position.competencies.slice(0, 3).map((comp) => {
        const evaluations = candidate.evaluation.filter(
          (e) => e.item_id === comp.id && e.type === "competency"
        );

        const filteredEvaluations =
          selectedEvaluators.length > 0
            ? evaluations.filter((e) =>
                selectedEvaluators.some((se) => se.id === e.evaluatedBy.id)
              )
            : evaluations;

        const bigScore =
          filteredEvaluations && filteredEvaluations.length > 0
            ? Math.round(
                (filteredEvaluations
                  .filter((e) => e.evaluation !== 0)
                  .reduce((a, v) => (a += v.evaluation), 0) /
                  filteredEvaluations.filter((e) => e.evaluation !== 0)
                    .length) *
                  100
              ) / 100
            : null;

        list.push({
          type: "Competencies",
          resource: "competencies",
          evType: "competency",
          item_id: comp.id,
          color: "green",
          dim: candidate.competencies.find((cc) => cc.name === comp.title),
          label: null,
          title: comp.title,
          desc: comp.desc,
          evaluations: filteredEvaluations && filteredEvaluations,
          deviation:
            Math.max(
              ...filteredEvaluations
                .filter((e) => e.evaluation !== 0)
                .map((r) => r.evaluation)
            ) -
            Math.min(
              ...filteredEvaluations
                .filter((e) => e.evaluation !== 0)
                .map((r) => r.evaluation)
            ),
          score: bigScore,
        });
      });

    const personalityDims = [];
    const personality = candidate.evaluation.filter(
      (e) => e.type === "personality"
    );

    const filteredPersonality =
      selectedEvaluators.length > 0
        ? personality.filter((e) =>
            selectedEvaluators.some((se) => se.id === e.evaluatedBy.id)
          )
        : personality;

    const personalityScore =
      filteredPersonality && filteredPersonality.length > 0
        ? Math.round(
            (filteredPersonality
              .filter((e) => e.evaluation !== 0)
              .reduce((a, v) => (a += v.evaluation), 0) /
              filteredPersonality.filter((e) => e.evaluation !== 0).length) *
              100
          ) / 100
        : null;

    list.push({
      id: filteredPersonality && filteredPersonality.id,
      type: "personality",
      evType: "personality",
      item_id: 3,
      evaluation: filteredPersonality && filteredPersonality.evaluation,
      deviation:
        Math.max(
          ...filteredPersonality
            .filter((e) => e.evaluation !== 0)
            .map((r) => r.evaluation)
        ) -
        Math.min(
          ...filteredPersonality
            .filter((e) => e.evaluation !== 0)
            .map((r) => r.evaluation)
        ),
      comments: filteredPersonality && filteredPersonality.comments,
      bigTitle: "Trait diversity",
      description:
        "Use these evaluation scores and stakeholder comments to understand candidate unique traits, behavioural characteristics, and their usefulness to the team and company.",
      color: "yellow",
      score: personalityScore,
    });

    if (candidate.type !== "person") {
      candidate.personality
        .filter((pe) => pe.groupId === "user_big5")
        .map((p) => {
          const { label, colour } = getAlignment({
            dim: p,
            team: candidate.position.parentTeam,
            teamMember: candidate,
            resource: "personality",
          });

          personalityDims.push({
            dim: candidate.personality.find((cc) => cc.name === p.name),
            label,
            color: colour,
          });
        });

      list.push({
        id: filteredPersonality ? filteredPersonality.id : null,
        type: "Personality",
        resource: "personality",
        evType: "personality",
        item_id: 3,
        color: "yellow",
        dims: personalityDims,
        title: "Behavioural diversity",
        desc: "Use these evaluation scores and stakeholder comments to understand candidate unique traits, behavioural characteristics, and their usefulness to the team and company.",
        candidate,
        team: candidate.position.parentTeam,
        label: null,
        evaluations: filteredPersonality && filteredPersonality,
        deviation:
          Math.max(
            ...filteredPersonality
              .filter((e) => e.evaluation !== 0)
              .map((r) => r.evaluation)
          ) -
          Math.min(
            ...filteredPersonality
              .filter((e) => e.evaluation !== 0)
              .map((r) => r.evaluation)
          ),
        score: personalityScore,
      });

      const valuesDims = [];
      const values = candidate.evaluation.filter((e) => e.type === "values");

      const filteredValues =
        selectedEvaluators.length > 0
          ? values.filter((e) =>
              selectedEvaluators.some((se) => se.id === e.evaluatedBy.id)
            )
          : values;

      const valuesScore =
        filteredValues && filteredValues.length > 0
          ? Math.round(
              (filteredValues
                .filter((e) => e.evaluation !== 0)
                .reduce((a, v) => (a += v.evaluation), 0) /
                filteredValues.filter((e) => e.evaluation !== 0).length) *
                100
            ) / 100
          : null;

      list.push({
        id: filteredValues && filteredValues.id,
        type: "values",
        evType: "values",
        item_id: 4,
        evaluation: filteredValues && filteredValues.evaluation,
        deviation:
          Math.max(
            ...filteredValues
              .filter((e) => e.evaluation !== 0)
              .map((r) => r.evaluation)
          ) -
          Math.min(
            ...filteredValues
              .filter((e) => e.evaluation !== 0)
              .map((r) => r.evaluation)
          ),
        comments: values && values.comments,
        bigTitle: "Values alignment",
        description:
          "Use these evaluation scores and stakeholder comments to understand the match between candidate and team values.",
        color: "red",
        score: valuesScore,
      });

      candidate.values
        .filter((dim) => dim.groupId === "user_value_facets")
        .sort((a, b) => b.score - a.score)
        .slice(0, 3)
        .map((p) => {
          const { label, colour } = getAlignment({
            dim: p,
            team: candidate.position.parentTeam,
            teamMember: candidate,
            resource: "values",
          });

          valuesDims.push({
            dim: candidate.values.find((cc) => cc.name === p.name),
            label,
            color: colour,
          });
        });

      candidate.values
        .filter((dim) => dim.groupId === "user_value_facets")
        .sort((a, b) => a.score - b.score)
        .slice(0, 3)
        .map((p) => {
          const { label, colour } = getAlignment({
            dim: p,
            team: candidate.position.parentTeam,
            teamMember: candidate,
            resource: "values",
          });

          valuesDims.push({
            dim: candidate.values.find((cc) => cc.name === p.name),
            label,
            color: colour,
          });
        });

      list.push({
        id: filteredValues ? filteredValues.id : null,
        type: "Values",
        resource: "values",
        evType: "values",
        item_id: 4,
        color: "red",
        dims: valuesDims,
        title: "Values fit",
        desc: "Use these evaluation scores and stakeholder comments to understand the match between candidate and team values.",
        candidate,
        team: candidate.position.parentTeam,
        label: null,
        evaluations: filteredValues && filteredValues,
        deviation:
          Math.max(
            ...filteredValues
              .filter((e) => e.evaluation !== 0)
              .map((r) => r.evaluation)
          ) -
          Math.min(
            ...filteredValues
              .filter((e) => e.evaluation !== 0)
              .map((r) => r.evaluation)
          ),
        score: valuesScore,
      });
    }
  }

  const intuitions = candidate.evaluation.filter(
    (e) => e.type === "intuition" && e.item_id === 5
  );

  const filteredIntuition =
    selectedEvaluators.length > 0
      ? intuitions.filter((e) =>
          selectedEvaluators.some((se) => se.id === e.evaluatedBy.id)
        )
      : intuitions;

  const intScore =
    filteredIntuition && filteredIntuition.length > 0
      ? Math.round(
          (filteredIntuition
            .filter((e) => e.evaluation !== 0)
            .reduce((a, v) => (a += v.evaluation), 0) /
            filteredIntuition.filter((e) => e.evaluation !== 0).length) *
            100
        ) / 100
      : null;

  list.push({
    id: filteredIntuition && filteredIntuition.id,
    type: "intuition",
    evType: "intuition",
    item_id: 5,
    evaluation: filteredIntuition && filteredIntuition.evaluation,
    deviation:
      Math.max(...filteredIntuition.map((r) => r.evaluation)) -
      Math.min(...filteredIntuition.map((r) => r.evaluation)),
    comments: filteredIntuition && filteredIntuition.comments,
    bigTitle: "Gut feeling",
    description:
      "Use these evaluation scores and stakeholder comments to understand if the candidate would be a good fit for the role and the team?",
    color: "purple",
    score: intScore,
  });

  list.push({
    type: "Gut feeling",
    resource: "intuition",
    evType: "intuition",
    color: "purple",
    item_id: 5,
    title:
      "Use these evaluation scores and stakeholder comments to understand if the candidate would be a good fit for the role and the team?",
    desc: null,
    questions: null,
    evaluations: filteredIntuition && filteredIntuition,
    deviation:
      Math.max(...filteredIntuition.map((r) => r.evaluation)) -
      Math.min(...filteredIntuition.map((r) => r.evaluation)),
    score: intScore,
  });

  return list;
};
