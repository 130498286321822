import React from "react";
import { compose, withState, withHandlers, branch } from "recompose";
import { graphql, withApollo } from "react-apollo";
import QueryString from "query-string";
import { withRouter } from "react-router-dom";
import handleLoading from "utils/loader";
import withStore from "utils/withStore";
import { loader } from "graphql.macro";
import scrollToTop from "utils/scrollToTop";
import purgeTeamFromCache from "utils/purgeTeamFromCache";
import CardLoader from "components/Loaders/CardLoader";

import styles from "./ChooseTemplate.module.scss";

const POSITION_TEMPLATES = loader("client/Queries/PositionTemplates15.graphql");
const DELETE_POSITION_TEMPLATE = loader("./deletePositionTemplate.graphql");
const ORGANIZATION_TEAMS_AND_POSITIONS = loader(
  "client/Queries/OrganizationTeamsAndPositions.graphql"
);
const CREATE_POSITION_MUTATION = loader(
  "client/Mutations/createPosition6.graphql"
);
const TEAM_QUERY = loader("client/Queries/TeamQuery124.graphql");

const Skeleton = () => (
  <div className={styles.skeleton}>
    <div className={styles.cardLoaderWrapper}>
      <CardLoader template />
    </div>
    <div className={styles.cardLoaderWrapper}>
      <CardLoader template />
    </div>
    <div className={styles.cardLoaderWrapper}>
      <CardLoader template />
    </div>
  </div>
);

export default compose(
  withApollo,
  withRouter,
  withStore("viewer", "account", "selectedOrg"),
  withState("removeModal", "setRemoveModal", false),
  withState("selectedCategory", "setSelectedCategory", null),
  withState("selectedStage", "setSelectedStage", null),
  withState("list", "setList", ["Featured by Wisnio", "Workspace templates"]),
  graphql(POSITION_TEMPLATES, {
    name: "POSITION_TEMPLATES",
    skip: ({ match }) => match.params.teamId && true,
    options: ({ match }) => ({
      variables: { org_id: parseInt(match.params.orgId, 10) },
    }),
  }),
  graphql(TEAM_QUERY, {
    skip: ({ match }) => match.params.orgId && true,
    options: ({ match }) => ({
      fetchPolicy: "network-only",
      variables: {
        id: parseInt(match.params.teamId, 10),
      },
    }),
  }),
  graphql(ORGANIZATION_TEAMS_AND_POSITIONS, {
    skip: ({ match }) => match.params.teamId && true,
    options: ({ match }) => ({
      variables: { id: parseInt(match.params.orgId, 10) },
    }),
  }),
  branch(
    (props) => {
      const condition =
        (props.POSITION_TEMPLATES && props.POSITION_TEMPLATES.loading) ||
        (props.data && props.data.loading);
      return condition;
    },
    handleLoading(() => <Skeleton />)
  ),
  withState("selectedTeam", "setSelectedTeam", ({ history }) => {
    const { teamId } = QueryString.parse(history.location.search);
    if (teamId) {
      return teamId;
    }
    return null;
  }),
  graphql(CREATE_POSITION_MUTATION, {
    name: "createPosition",
    options: ({ data, client, selectedOrg }) => ({
      update: (proxy, { data: { createNewPosition } }) => {
        const positions = proxy.readQuery({
          query: ORGANIZATION_TEAMS_AND_POSITIONS,
          variables: {
            id: selectedOrg.id,
          },
        });

        if (positions.organization && positions.organization.allOpenPositions) {
          positions.organization.allOpenPositions.push(createNewPosition);
          proxy.writeQuery({
            query: ORGANIZATION_TEAMS_AND_POSITIONS,
            variables: {
              id: createNewPosition.parent_org_id,
            },
            data: positions,
          });
        }

        if (
          proxy.data.data[
            `${createNewPosition.parentTeam.__typename}:${createNewPosition.parentTeam.id}`
          ]
        ) {
          purgeTeamFromCache({
            cache: client.cache.data,
            team_id: createNewPosition.parentTeam.id,
            proxy,
          });
        }
      },
    }),
  }),
  graphql(DELETE_POSITION_TEMPLATE, {
    name: "deletePositionTemplate",
    options: ({ data, client, selectedOrg }) => ({
      update: (proxy, { data: { deletePositionTemplate } }) => {
        const data = proxy.readQuery({
          query: POSITION_TEMPLATES,
          variables: { org_id: parseInt(selectedOrg.id, 10) },
        });

        data.positionTemplates = [
          ...data.positionTemplates.filter(
            (pt) => pt.id !== deletePositionTemplate.id
          ),
        ];

        proxy.writeQuery({
          query: POSITION_TEMPLATES,
          variables: { org_id: parseInt(selectedOrg.id, 10) },
          data,
        });
      },
    }),
  }),
  withHandlers({
    handleCreatePosition:
      ({ data, selectedTeam, createPosition, selectedOrg, history }) =>
      (e) => {
        createPosition({
          variables: {
            name: e.name,
            parent_org: selectedOrg.id,
            team_id: data.team ? data.team.id : parseInt(selectedTeam, 10),
            template_id: e.template || null,
          },
        }).then((res) => {
          if (window.Intercom) {
            window.Intercom("trackEvent", "create-position", {
              id: res.data.createNewPosition.id,
            });
          }
          history.push(
            `/team/${data.team ? data.team.id : selectedTeam}/position/${
              res.data.createNewPosition.id
            }`
          );
        });
      },
    handleDeletePositionTemplate:
      ({ deletePositionTemplate, selectedOrg, setRemoveModal }) =>
      (id) => {
        deletePositionTemplate({
          variables: {
            id,
            org_id: parseInt(selectedOrg.id, 10),
          },
        }).then((res) => {
          setRemoveModal(false);
        });
      },
  }),
  scrollToTop
);
