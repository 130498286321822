import React from "react"
import { withRouter } from "react-router-dom"
import { graphql } from "react-apollo"
import { compose } from "recompose"
import handleLoading from "utils/handleLoading"
import { loader } from "graphql.macro"

const POSITION_QUERY = loader("./Queries/PositionQuery29.graphql")

export default compose(
  withRouter,
  graphql(POSITION_QUERY, {
    options: (props) => {
      return {
        variables: {
          id: parseInt(props.match.params.positionId, 10),
        },
      }
    },
  }),
  handleLoading
)(({ data: { openPosition } }) => {
  const truncate = (string) => {
    if (string.length > 14) return `${string.substring(0, 14)}...`
    return string
  }

  return <React.Fragment>{openPosition.name}</React.Fragment>
})
