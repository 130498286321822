import { compose, withState } from "recompose";
import { graphql, withApollo } from "react-apollo";
import { loader } from "graphql.macro";
import { withRouter } from "react-router-dom";
import handleQueryLoading from "utils/loader";
import Circles from "components/Loaders/Circles";
import handleChangeName from "./handleChangeName";

const UPDATE_POSITION = loader("./updatePosition.graphql");
const REMOVE_CANDIDATE_FROM_POSITION = loader(
  "./removeCandidateFromPosition2.graphql"
);
const REMOVE_TEAM_FROM_INVITE = loader("./removeTeamFromInvite.graphql");
const POSITION_QUERY = loader("client/Queries/PositionQuery98.graphql");
const DELETE_POSITION = loader("./deletePosition.graphql");
const ORGANIZATION_TEAMS_AND_POSITIONS = loader(
  "client/Queries/OrganizationTeamsAndPositions.graphql"
);

export default compose(
  withRouter,
  withApollo,
  graphql(UPDATE_POSITION, { name: "updatePosition" }),
  graphql(REMOVE_CANDIDATE_FROM_POSITION, {
    name: "removeCandidateFromPosition",
    options: (props) => {
      return {
        update: (proxy, { data: { removeCandidateFromPosition } }) => {
          const data = proxy.readQuery({
            query: POSITION_QUERY,
            variables: {
              id: parseInt(props.match.params.positionId, 10),
            },
          });
          data.openPosition.candidates = data.openPosition.candidates.filter(
            (cand) => cand.id !== removeCandidateFromPosition
          );
          proxy.writeQuery({
            query: POSITION_QUERY,
            variables: {
              id: parseInt(props.match.params.positionId, 10),
            },
            data,
          });
        },
      };
    },
  }),
  graphql(REMOVE_TEAM_FROM_INVITE, {
    name: "removeTeamFromInvite",
    options: (props) => {
      return {
        update: (proxy, { data: { removeTeamFromInvite } }) => {
          const data = proxy.readQuery({
            query: POSITION_QUERY,
            variables: {
              id: props.position.id,
            },
          });

          data.openPosition.pendingInvites =
            data.openPosition.pendingInvites.filter(
              (invite) => invite.id !== removeTeamFromInvite.id
            );

          proxy.writeQuery({
            query: POSITION_QUERY,
            data,
            variables: {
              id: props.position.id,
            },
          });
        },
      };
    },
  }),
  graphql(DELETE_POSITION, {
    name: "deletePosition",
    options: (props) => {
      return {
        update: (proxy, { data: { deletePosition } }) => {
          const data = proxy.readQuery({
            query: ORGANIZATION_TEAMS_AND_POSITIONS,
            variables: {
              id: props.position.parentOrganization.id,
            },
          });
          data.organization.allOpenPositions =
            data.organization.allOpenPositions.filter(
              (pos) => pos.id !== deletePosition.id
            );
          proxy.writeQuery({
            query: ORGANIZATION_TEAMS_AND_POSITIONS,
            variables: {
              id: props.position.parentOrganization.id,
            },
            data,
          });
        },
      };
    },
  }),
  handleQueryLoading(Circles),
  withState("nameValue", "setNameValue", (props) => props.position.name),
  withState("selectedOptions", "setSelectedOptions", []),
  withState("showDeletePrompt", "updateDeletePrompt", false),
  handleChangeName
);
