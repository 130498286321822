export default ({ columns, setColumns, noTransitionIndex, setNoTransitionIndex, setCurrentDraggable, setIsDragging, setIsDraggingInSelectionColumn }) => ({
  source,
  destination,
  draggableId
}) => {
  if (!destination) return

  if (source.droppableId === destination.droppableId && source.droppableId === 'source') {
    // console.log('source === destiantion ')
    setIsDraggingInSelectionColumn(false)
    return columns[source.droppableId].splice(
      destination.index,
      0,
      columns[source.droppableId].splice(source.index, 1)[0]
    )
  }
  if (
    source.droppableId === 'source'
    && destination.droppableId === 'selection'
  ) {
    // console.log({destination})

    if(destination.index === 10) return
    const draggable = columns[source.droppableId][source.index]
    const sourceColumn = columns.source.filter(
      r => r.survey_row_id !== parseInt(draggableId, 10)
    )
    // console.log(columns.selection)
    // console.log(destination.index)

    const selectionClone = noTransitionIndex ? [...columns.selection].slice(0, noTransitionIndex) : [...columns.selection]
    const stickySelection = noTransitionIndex ? [...columns.selection].slice(noTransitionIndex + 1, columns.selection.length) : []

    columns.selection.forEach((s, i) => {
      if (i === destination.index) {
        if (s.survey_row_id) {
          selectionClone.splice(destination.index, 0, draggable)
        } else {
          selectionClone[i] = draggable
        }
      }
    })

    const finalSelection = noTransitionIndex ? selectionClone.concat(stickySelection) : selectionClone

    if (finalSelection[10] && finalSelection[10].survey_row_id) {
      sourceColumn.splice(0, 0, finalSelection[10])
    }

    setIsDraggingInSelectionColumn(false)
    setNoTransitionIndex(0)
    setCurrentDraggable(null)
    setColumns({ source: sourceColumn, selection: finalSelection.slice(0, 10) })
    setIsDragging(false)
  }
   else if (
    source.droppableId === 'selection'
    && destination.droppableId === 'source'
  ) {
    const draggable = columns.selection[parseInt(draggableId.split('-')[1], 10)]
    const dragId = parseInt(draggableId.split('-')[1], 10)

    const selection = columns.selection.map((s, i) => {
      if (i === dragId) {
        return {
          obj_type_id: i,
          subject_type_id: null,
          subject_id: null,
          survey_row_id: null,
          question_dim_id: null,
          answer: null
        }
      }
      return s
    })

    columns.source.splice(destination.index, 0, draggable)

    setIsDraggingInSelectionColumn(false)
    setColumns({ source: columns.source, selection })
    setIsDragging(false)

  }
  else if (
    source.droppableId.includes('selection')
    && destination.droppableId.includes('selection')
  ) {
    const draggable = columns.selection[parseInt(draggableId.split('-')[1], 10)]



    const selectionClone = [...columns.selection]
    let finalSelection
    if (!selectionClone[destination.index].survey_row_id) {
      selectionClone[destination.index] = draggable
      selectionClone[source.index] = {
        obj_type_id: source.index,
        subject_type_id: null,
        subject_id: null,
        survey_row_id: null,
        question_dim_id: null,
        answer: null
      }

      finalSelection = selectionClone
    } else if (destination.index < source.index) {
      const topSelection = [...columns.selection].slice(0, destination.index)
      const bottomSelection = [...columns.selection].slice(destination.index, columns.selection.length)
      const sourceIndex = source.index - topSelection.length


      // console.log('initial', [...topSelection, ...bottomSelection])

      bottomSelection.splice(sourceIndex, 1, {
        obj_type_id: 0,
        subject_type_id: null,
        subject_id: null,
        survey_row_id: null,
        question_dim_id: null,
        answer: null
      })
      // console.log('source index removed', [...topSelection, ...bottomSelection])
      bottomSelection.splice(0, 0, draggable)
      // console.log('draggable added', [topSelection, bottomSelection])

      let nextEmptyIndex = 9

      for (let i = 0; i < bottomSelection.length - 1; ++i) {

        if (bottomSelection[i + 1].survey_row_id === null) {

          nextEmptyIndex = i + 1
          break
        }
      }


      // console.log('removing next empty index', nextEmptyIndex)

      bottomSelection.splice(nextEmptyIndex, 1)
      // console.log('next empty removed/added', [topSelection, bottomSelection])

      // console.log('final', [...topSelection, ...bottomSelection])
      finalSelection = [...topSelection, ...bottomSelection]


      if (finalSelection[10] && finalSelection[10].survey_row_id) {
        const itemBeingSentToSource = finalSelection[10]
        finalSelection.splice(10, 1)
        columns.source.splice(0, 0, itemBeingSentToSource)
      }
    } else {
      const topSelection = [...columns.selection].slice(0, source.index)
      const bottomSelection = [...columns.selection].slice(source.index, columns.selection.length)
      const destIndex = destination.index - topSelection.length

      // console.log('initial', [[...topSelection], [...bottomSelection]])

      bottomSelection.splice(0, 1, {
        obj_type_id: 0,
        subject_type_id: null,
        subject_id: null,
        survey_row_id: null,
        question_dim_id: null,
        answer: null
      })

      // console.log('source removed', [[...topSelection], [...bottomSelection]])

      bottomSelection.splice(destIndex, 0, draggable)

      // console.log('draggable added', [[...topSelection], [...bottomSelection]])
      let nextEmptyIndex

      for (let i = destIndex; i < bottomSelection.length - 1; ++i) {

        if (bottomSelection[i + 1].survey_row_id === null) {

          nextEmptyIndex = i + 1
          break
        }
      }
      // console.log('removing next empty index', nextEmptyIndex)
      if(nextEmptyIndex) bottomSelection.splice(nextEmptyIndex, 1)
      // console.log('next empty removed', [[...topSelection], [...bottomSelection]])



      finalSelection = [...topSelection, ...bottomSelection]
      // console.log({finalSelection})

      if (finalSelection[10] && finalSelection[10].survey_row_id) {
        const itemBeingSentToSource = finalSelection[10]
        finalSelection.splice(10, 1)
        columns.source.splice(0, 0, itemBeingSentToSource)
      }

    }

    setIsDraggingInSelectionColumn(false)
    setNoTransitionIndex(0)
    setCurrentDraggable(null)
    setColumns({ source: columns.source, selection: finalSelection })
    setIsDragging(false)

    // const stickySelection = noTransitionIndex ? [...columns.selection].slice(noTransitionIndex, columns.selection.length) : []


    // const middleSelection = [...columns.selection].slice(source.index, noTransitionIndex)
    // const stickySelection = [...columns.selection].slice(noTransitionIndex, columns.selection.length)
    //
    // console.log({topSelection, middleSelection, stickySelection})
    //
    // // columns.selection.forEach((s, i) => {
    // //   if (i === destination.index) {
    // //     if (s.survey_row_id) {
    // //       selectionClone.splice(destination.index, 0, draggable)
    // //     } else {
    // //       console.log('setting ', i, 'to draggable')
    // //       selectionClone[i] = draggable
    // //     }
    // //   }
    // // })
    //
    //  console.log({  draggable, source, destination, noTransitionIndex })
    //
    //  const finalSelection = topSelection.concat(middleSelection).concat(stickySelection)
    //
    //  if (finalSelection[10] && finalSelection[10].survey_row_id) {
    //    columns.source.splice(0, 0, finalSelection[10])
    //  }
    //
    //  setIsDraggingInSelectionColumn(false)
    //  setNoTransitionIndex(0)
    //  setCurrentDraggable(null)
    //  setColumns({ source: columns.source, selection: finalSelection.slice(0, 10) })
    //  setIsDragging(false)
  }

}
