import { compose, withHandlers, withState } from "recompose";
import { withApollo } from "react-apollo";
import { loader } from "graphql.macro";
import { withRouter } from "react-router-dom";
import axios from "utils/axios";

const ATTACHMENT_WRITE_SIGNED_URL = loader(
  "./attachmentWriteSignedUrl.graphql"
);
const CANDIDATE_ATTACHMENTS = loader(
  "client/Queries/candidateAttachments1.graphql"
);

const START_FILE_EMBEDDING = loader("./startFileEmbedding2.graphql");

export default compose(
  withRouter,
  withApollo,
  withState("uploading", "setUploading", null),
  withState("openModal", "setOpenModal", false),
  withState("description", "setDescription", ""),
  withState("selectedTags", "setSelectedTags", []),
  withState("uploadFiles", "setUploadFiles", null),
  withHandlers({
    handleUploadAttachment:
      ({
        match: {
          params: { candidateType },
        },
        client,
        candidate,
        setUploading,
        setOpenModal,
        description,
        selectedTags,
        uploadFiles,
      }) =>
      async (files) => {
        console.log(files);
        const [file] = files;
        console.log(file);
        setUploading(file.name);
        const {
          data: {
            attachmentWriteSignedUrl: { file_id, write_url },
          },
        } = await client.query({
          query: ATTACHMENT_WRITE_SIGNED_URL,
          variables: {
            user_id: candidateType === "candidate" ? candidate.id : null,
            person_id: candidateType === "person" ? candidate.id : null,
            position_id: candidate.position.id,
            file_name: file.name,
            content_type: file.type,
          },
          fetchPolicy: "network-only",
        });

        try {
          const { status } = await axios.put(write_url, file, {
            headers: {
              "Content-Type": file.type,
            },
          });

          if (status === 200) {
            // const fileEmbedded = await client.mutate({
            //   mutation: START_FILE_EMBEDDING,
            //   variables: {
            //     file_id,
            //     description: description,
            //     tags,
            //   },
            // });

            // console.log(fileEmbedded);

            await client.query({
              query: CANDIDATE_ATTACHMENTS,
              variables: {
                user_id: candidateType === "candidate" ? candidate.id : null,
                person_id: candidateType === "person" ? candidate.id : null,
                position_id: candidate.position.id,
                team_id: candidate.position.parent_team_id,
                org_id: candidate.position.parent_org_id,
              },
              fetchPolicy: "network-only",
            });
            setUploading(null);

            // setOpenModal(false);
          }
        } catch (err) {
          console.log("attachment error:", err);
        }
      },
  })
);
