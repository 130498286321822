import React from 'react'
import classNames from 'classnames'

import styles from './LearnMoreModal.module.scss'

export default ({ title, subtitle, values }) => (
  <div className={classNames(styles.wrapper, styles[values.color])}>
    <h2 className={styles.title}>{title}</h2>
    <p className={styles.subtitle}>{subtitle}</p>
    <div className={styles.imageWrapper}>{values.image}</div>
    <div className={styles.section}>
      <div className={styles.row}>
        <div className={styles.column}>
          <div className={styles.icon}>{values.value1.icon}</div>
          <h5>{values.value1.title}</h5>
          <p>{values.value1.desc}</p>
        </div>
        <div className={styles.column}>
          <div className={styles.icon}>{values.value2.icon}</div>
          <h5>{values.value2.title}</h5>
          <p>{values.value2.desc}</p>
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.column}>
          <div className={styles.icon}>{values.value3.icon}</div>
          <h5>{values.value3.title}</h5>
          <p>{values.value3.desc}</p>
        </div>
        <div className={styles.column}>
          <div className={styles.icon}>{values.value4.icon}</div>
          <h5>{values.value4.title}</h5>
          <p>{values.value4.desc}</p>
        </div>
      </div>
    </div>
  </div>
)
