import React, { useEffect } from 'react'
import allowedRoutesWhileAccountIsSuspended from 'utils/allowedRoutesWhileAccountIsSuspended'
import styles from './AccountFrozenOverlay.module.scss'


export default ({ account, route, children }) => {
  useEffect(() => {
    if (account.frozen) {
      const root = document.getElementById('root')
      root.classList.add("suspended");
    }
  })
  const routeIsBlocked = !allowedRoutesWhileAccountIsSuspended.includes(route.current.split('/')[1])

  if (account.frozen && routeIsBlocked) {
    return (
      <div className={styles.frozenWrapper} >
        {children}
      </div>

    )
  }
  return children

}
