import { withHandlers } from 'recompose'

export default withHandlers({
  handleChangeOrgContract: ({
    updateOrgContractMutation,
    updateErrorPrompt,
    match
  }) => ({ event, user, roleTypes }) => {
    updateOrgContractMutation({
      variables: {
        resourceId: user.type === 'user' ? user.contractId : user.inviteId,
        resourceType: user.type,
        org_id: parseInt(match.params.orgId, 10),
        update: {
          role: roleTypes.find(t => t.name === event.target.value).role
        }
      }
    })
      .catch((err) => {
        updateErrorPrompt({
          error: true,
          message: err.message.replace('GraphQL error: ', '')
        })
      })
  }
})
