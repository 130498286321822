import React, { useState } from "react";
import { Link } from "react-router-dom";
import QueryString from "query-string";
import IENotificationBar from "components/Header/IENotificationBar";
import Footer from "components/Footer";
import LoginBox from "components/LoginBox";
import compose from "./compose";

import styles from "./Login.module.scss";

export default compose(({ location }) => {
  // Internet Explorer 6-11
  const isIE = /* @cc_on!@ */ false || !!document.documentMode;
  const queryString = QueryString.parse(location.search);

  const [notificationBar, setNotificationBar] = useState(!!isIE);

  return (
    <React.Fragment>
      <div className={styles.mainWrapper}>
        {notificationBar && (
          <IENotificationBar setNotificationBar={setNotificationBar} />
        )}
        <div className={styles.main}>
          <div className={styles.login}>
            <div className={styles.header}>
              <div className={styles.logo} />
            </div>
            <h3 className={styles.subtitle}>
              Talent analytics for winning teams
            </h3>
            <LoginBox />
            <div className={styles.footer}>
              <p className={styles.alreadyAccount}>
                Don't have an account?
                <Link
                  to={`signup${
                    queryString && queryString.positionTemplate
                      ? `?positionTemplate=${queryString.positionTemplate}`
                      : ""
                  }`}
                >
                  Create a free account
                </Link>
              </p>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </React.Fragment>
  );
});
