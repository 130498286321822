import React, { useState } from "react"
import UserAvatar from "components/UserAvatar"
import Button from "components/UI/Button"
import Icon from "components/UI/Icon"
import styles from "./Notes.module.scss"
import handleUpdate from "routes/OrganizationSettings/ManagePeople/UsersList/handleUpdate"

export default ({
  note,
  editNote,
  setEditNote,
  handleUpdateCandidateNote,
  handleDeleteCandidateNote,
  viewer,
}) => {
  const [comment, setComment] = useState(note.text)

  return (
    <div className={styles.note}>
      <div className={styles.left}>
        <div className={styles.avatar}>
          {note.createdBy && (
            <UserAvatar member={note.createdBy} size="small" />
          )}
        </div>
        <div className={styles.comment}>
          {editNote && editNote === note.id ? (
            <textarea
              rows="3"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              placeholder="Leave note"
              spellCheck="false"
            />
          ) : (
            <p>{note.text}</p>
          )}
        </div>
      </div>
      {editNote && editNote === note.id && (
        <div className={styles.actions}>
          <Button
            bStyle={["newsimple"]}
            onClick={() => {
              handleUpdateCandidateNote(note.id, comment)
              setEditNote(false)
            }}
          >
            Save
          </Button>
          <Button
            bStyle={["newsimple"]}
            onClick={() => {
              setEditNote(false)
              setComment(note.text)
            }}
          >
            Cancel
          </Button>
        </div>
      )}
      {!editNote && note.createdBy && note.createdBy.id === viewer.id && (
        <div className={styles.actions}>
          <Button bStyle={["none"]} onClick={() => setEditNote(note.id)}>
            <Icon icon="Edit" />
          </Button>
          <Button
            bStyle={["none"]}
            onClick={() => handleDeleteCandidateNote(note.id)}
          >
            <Icon icon="Close" />
          </Button>
        </div>
      )}
    </div>
  )
}
