export default (name) => {
  if (!name) {
    return 'blue'
  }
  const colors = {
    0: 'blue',
    1: 'green',
    2: 'yellow',
    3: 'purple'
  }
  return colors[Array.from(name.charCodeAt(0).toString()).map(Number)[0] % 5]
}
