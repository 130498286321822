import React, { useEffect } from "react";
import classNames from "classnames";
import Modal from "react-modal";
import Popup from "reactjs-popup";
import { printoutRoute } from "client/authRoutes";
import Icon from "components/UI/Icon";
import EllipsisButton from "components/UI/EllipsisButton";
import DropDown from "components/UI/DropDown";
import TeamMemberAvatars from "components/TeamMemberAvatars";
import DOMPurify from "dompurify";
import "react-base-table/styles.css";
import SidebarWrapper from "components/UI/SidebarWrapper";
import SidebarHeader from "components/UI/SidebarHeader";
import Button from "components/UI/Button";
import { useSidebar } from "utils/sidebarProvider";
import TabSwitch from "components/UI/TabSwitch";
import EvaluatorsModal from "./EvaluatorsModal";
import RemovePosition from "../EditPosition/RemovePosition";
import EditPosition from "../EditPosition";
import ShareAccess from "../ShareAccess";
import compose from "./compose";

import CompareTable from "./CompareTable/index";

import styles from "./CompareCandidates.module.scss";

export default compose(
  ({
    data: { openPosition },
    match,
    account,
    dataRows,
    columns,
    setFilter,
    setOrder,
    filter,
    modal,
    updateModal,
    descModal,
    updateDescModal,
    selectedEvaluators,
    setSelectedEvaluators,
    deleteModal,
    deletePosition,
    setDeleteModal,
    history,
  }) => {
    const data = dataRows;

    const getContentHtml = (content) => ({
      __html: DOMPurify.sanitize(content),
    });

    const candidates =
      filter === "compare"
        ? openPosition.kanbanColumns[3].candidatesUnion
            .map((cand) =>
              openPosition.candidatesUnion.find((c) => {
                const [type, id] = cand.split("-");
                return c.type === type && c.id === parseInt(id, 10);
              })
            )
            .filter(Boolean)
        : openPosition.candidatesUnion;

    const getEvaluators = () => {
      const evaluators = [];
      candidates.map((c) => {
        c.evaluation.map((e) => {
          if (!evaluators.find((c1) => c1.id === e.evaluatedBy.id)) {
            evaluators.push(e.evaluatedBy);
          }
        });
      });
      return evaluators;
    };

    const { contentContext } = useSidebar();
    const [content, setContent] = contentContext;

    const sidebarContent = [];

    useEffect(() => {
      setContent(sidebarContent);
    }, []);

    return (
      <SidebarWrapper>
        <SidebarHeader
          title={openPosition.name}
          subtitle={`${openPosition.parentTeam.name} • ${openPosition.candidatesUnion.length} candidates`}
          extrasubtitle={
            openPosition.template &&
            ` • Based on ${openPosition.template.title}`
          }
          extraItems={
            <div className={styles.extraButtons}>
              <TabSwitch
                items={[
                  {
                    active: false,
                    link: `/team/${openPosition.parentTeam.id}/position/${openPosition.id}`,
                    component: (
                      <div className={styles.item}>
                        <span className={styles.icon}>
                          <Icon icon="Kanban" />
                        </span>
                        <p>List view</p>
                      </div>
                    ),
                  },
                  {
                    active: true,
                    link: false,
                    component: (
                      <div className={styles.item}>
                        <span className={styles.icon}>
                          <Icon icon="Compare" />
                        </span>
                        <p>Comparison view</p>
                      </div>
                    ),
                  },
                ]}
              />
            </div>
          }
        >
          <Button
            bStyle={["verySmall"]}
            onClick={() => {
              history.push(
                `/team/${parseInt(match.params.teamId, 10)}/position/${parseInt(
                  match.params.positionId,
                  10
                )}/edit`
              );
            }}
          >
            <Icon icon="Edit" marginRight />
            Edit position
          </Button>
          <div className={styles.printBtn}>
            <a
              href={`${printoutRoute}/printout/compare/${
                openPosition.id
              }/${openPosition.name
                .match(
                  /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
                )
                .map((x) => x.toLowerCase())
                .join("_")}_position_profile.pdf`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button bStyle={["secondary", "verySmall"]} type="button">
                <Icon icon="Print" marginRight />
                Print
              </Button>
            </a>
          </div>

          <div className={styles.moreBtn}>
            <div className="dropdownWrapper" id="dropdownMenu">
              <Popup
                keepTooltipInside="body"
                trigger={<EllipsisButton large />}
                position="bottom right"
                nested
              >
                <DropDown>
                  <li>
                    <Button
                      type="button"
                      bStyle={["none"]}
                      onClick={() => updateModal("shareAccess")}
                    >
                      <Icon icon="Lock" marginRight />
                      Share access
                    </Button>
                  </li>
                  <li>
                    <Button
                      type="button"
                      bStyle={["none"]}
                      onClick={() => setDeleteModal(true)}
                    >
                      <Icon icon="Delete" marginRight />
                      Delete position
                    </Button>
                  </li>
                </DropDown>
              </Popup>
            </div>
          </div>
        </SidebarHeader>
        <div className={styles.main}>
          <CompareTable
            account={account}
            openPosition={openPosition}
            filter={filter}
            setFilter={setFilter}
            setOrder={setOrder}
            getEvaluators={getEvaluators}
            selectedEvaluators={selectedEvaluators}
            setSelectedEvaluators={setSelectedEvaluators}
            updateModal={updateModal}
            setDeleteModal={setDeleteModal}
            columns={columns}
            data={data}
          />
          <Modal
            closeTimeoutMS={350}
            isOpen={modal === "shareAccess"}
            onRequestClose={() => updateModal("")}
            shouldCloseOnOverlayClick
            className={styles.modal}
          >
            <ShareAccess position={openPosition} updateModal={updateModal} />
          </Modal>
          <Modal
            closeTimeoutMS={350}
            isOpen={modal === "editPosition"}
            onRequestClose={() => updateModal("")}
            shouldCloseOnOverlayClick
            className={styles.modal}
          >
            <EditPosition position={openPosition} updateModal={updateModal} />
          </Modal>
          <Modal
            closeTimeoutMS={350}
            isOpen={modal === "evaluators"}
            onRequestClose={() => updateModal("")}
            shouldCloseOnOverlayClick
            className={styles.modal}
          >
            <EvaluatorsModal
              position={openPosition}
              evaluators={
                selectedEvaluators.length > 0
                  ? selectedEvaluators
                  : getEvaluators()
              }
              candidates={candidates}
              updateModal={updateModal}
            />
          </Modal>
          {descModal && (
            <Modal
              closeTimeoutMS={350}
              isOpen={descModal !== ""}
              onRequestClose={() => updateDescModal("")}
              shouldCloseOnOverlayClick
              className={styles.modal}
            >
              <Button
                bStyle="close"
                type="button"
                onClick={() => updateDescModal("")}
              >
                <i className="fal fa-times" />
              </Button>
              <div className={styles.descModal}>
                <h2>{descModal.title}</h2>
                <div
                  className={styles.desc}
                  dangerouslySetInnerHTML={getContentHtml(descModal.desc)}
                />
              </div>
            </Modal>
          )}
          <Modal
            closeTimeoutMS={350}
            isOpen={!!deleteModal}
            onRequestClose={() => setDeleteModal(false)}
            shouldCloseOnOverlayClick
            className={styles.modal}
          >
            <RemovePosition
              updateDeletePrompt={setDeleteModal}
              deletePosition={deletePosition}
              position={openPosition}
              history={history}
            />
          </Modal>
        </div>
      </SidebarWrapper>
    );
  }
);
