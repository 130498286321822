import React from "react";
import UserAvatar from "components/UserAvatar";

import styles from "./Evaluations.module.scss";

export default ({ item }) => {
  return (
    <div className={styles.evaluations}>
      {item.scores &&
        item.scores.map((ev) => {
          if (!ev.score) return null;
          return (
            <div
              key={`evaluation-${ev.score.id}`}
              className={styles.evaluation}
            >
              <div className={styles.left}>
                <div className={styles.avatar}>
                  <UserAvatar member={ev.reviewer} size="small" showName />
                </div>
                <div className={styles.comment}>
                  {ev.score.comments &&
                    ev.score.comments
                      .filter((c) => c.createdBy.id === ev.reviewer.id)
                      .map((comment) => (
                        <p
                          key={`comment-${comment.id}`}
                          className={styles.comment}
                        >
                          {comment.comment}
                        </p>
                      ))}
                  {ev.score.comments &&
                    ev.score.comments.filter(
                      (c) => c.createdBy.id === ev.reviewer.id
                    ).length < 1 && (
                      <p className={styles.nocomment}>No comment added.</p>
                    )}
                  {!ev.score.comments && (
                    <p className={styles.nocomment}>No comment added.</p>
                  )}
                </div>
              </div>
              <div className={styles.score}>
                <h5>{ev.score.score !== undefined ? ev.score.score : "N/A"}</h5>
              </div>
            </div>
          );
        })}
      {(!item.scores || item.scores.length === 0) && (
        <p className={styles.noeval}>
          This section has not been evaluated yet.
        </p>
      )}
    </div>
  );
};
