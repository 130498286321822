import React, {
  useEffect,
  createContext,
  useState,
  useContext,
  useCallback,
} from "react";
import io from "socket.io-client";
import { GPTGatewaySocetUrl } from "client/authRoutes";

const SocketContext = createContext();

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    if (!socket) {
      const newSocket = io(GPTGatewaySocetUrl, {
        path: process.env.REACT_APP_GPT_GATEWAY_PATH,
        withCredentials: true,
      });

      newSocket.on("connect", () => {
        console.log("Socket connected:", newSocket.id);
      });

      newSocket.on("disconnect", () => {
        console.error("Socket disconnected", socket);
        newSocket.connect();
      });

      newSocket.on("connect_error", (err) => {
        console.error("Connection error:", err);
        newSocket.disconnect();
      });

      setSocket(newSocket);

      return () => {
        newSocket.close();
      };
    }
  }, [socket]);

  const [responses, setResponses] = useState({});

  const addResponse = useCallback(
    (response) => {
      setResponses((prevResponses) => ({
        ...prevResponses,
        [response.conversationId]: response,
      }));
    },
    [responses]
  );

  const clearResponse = useCallback((conversationId) => {
    setResponses((prevResponses) => {
      const newResponses = { ...prevResponses };
      delete newResponses[conversationId];
      return newResponses;
    });
  }, []);

  useEffect(() => {
    if (socket) {
      if (!socket.connected) {
        console.log("Socket not connected, connecting");
        socket.connect();
      }

      socket.on("GWResponse", (response) => {
        addResponse(response);
      });

      return () => {
        socket.off("GWResponse");
      };
    }
  }, [socket, addResponse]);

  return (
    <SocketContext.Provider value={{ socket, responses, clearResponse }}>
      {children}
    </SocketContext.Provider>
  );
};

export const useSocket = () => useContext(SocketContext);
