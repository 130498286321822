import React from "react";

import styles from "./Workflow.module.scss";

export default ({ currentObj, toolkit, teamPlay }) => {
  const component = require(`./Components/${currentObj.component}`);

  return (
    <div className={styles.wrapper}>
      <h2>{currentObj.title}</h2>
      {component &&
        React.createElement(component.default, { toolkit, play: teamPlay })}
    </div>
  );
};
