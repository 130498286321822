import React from "react";
import UserAvatar from "components/UserAvatar";

import styles from "./Evaluations.module.scss";

export default ({ item }) => (
  <div className={styles.evaluations}>
    {item.evaluations &&
      item.evaluations.map(ev => (
        <div key={`evaluation-${ev.id}`} className={styles.evaluation}>
          <div className={styles.left}>
            <div className={styles.avatar}>
              <UserAvatar member={ev.evaluatedBy} size="small" showName />
            </div>
            <div className={styles.comment}>
              {ev.comments &&
                ev.comments
                  .filter(c => c.createdBy.id === ev.evaluatedBy.id)
                  .map(comment => (
                    <p key={`comment-${comment.id}`} className={styles.comment}>
                      {comment.comment}
                    </p>
                  ))}
              {ev.comments &&
                ev.comments.filter(c => c.createdBy.id === ev.evaluatedBy.id)
                  .length < 1 && (
                  <p className={styles.nocomment}>No comment added.</p>
                )}
              {!ev.comments && (
                <p className={styles.nocomment}>No comment added.</p>
              )}
            </div>
          </div>
          <div className={styles.score}>
            <h5>{ev.evaluation !== 0 ? ev.evaluation : "N/A"}</h5>
          </div>
        </div>
      ))}
    {(!item.evaluations || item.evaluations.length === 0) && (
      <p className={styles.noeval}>This section has not been evaluated yet.</p>
    )}
  </div>
);
