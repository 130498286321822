import React from "react";
import classNames from "classnames";
import styles from "./Indicator.module.scss";

export default ({ dim, team, highlight, casestudy }) => {
  const members = team.members.filter((member) => member.id !== highlight.id);

  const memberScores = members
    .map((member) => {
      return member.personality.find((d) => d.dim_id === dim.dim_id).score;
    })
    .filter(Boolean);
  // console.log({ members, memberScores });
  // console.log('scores length', memberScores.length)
  const userScore = highlight.personality.find(
    (d) => d.dim_id === dim.dim_id
  ).score;
  const scoreDiffs = memberScores.map((s) => Math.abs(s - userScore));
  const alignmentScore =
    scoreDiffs.reduce((a, b) => a + b, 0) / memberScores.length;

  const getlabel = () => {
    if (alignmentScore < 10) return "Very similar";
    if (alignmentScore < 25) return "Similar";
    if (alignmentScore < 50) return "Diverse";
    if (alignmentScore < 100) return "Very diverse";
    return null;
  };

  const getLabelClass = () => {
    if (casestudy === true) {
      if (alignmentScore < 10) return styles.veryDiverse;
      if (alignmentScore < 25) return styles.diverse;
      if (alignmentScore < 50) return styles.similar;
      if (alignmentScore < 100) return styles.verySimilar;
    }
    if (alignmentScore < 10) return styles.verySimilar;
    if (alignmentScore < 25) return styles.similar;
    if (alignmentScore < 50) return styles.diverse;
    if (alignmentScore < 100) return styles.veryDiverse;
    return null;
  };

  return (
    <p className={classNames(styles.label, getLabelClass())}>{getlabel()}</p>
  );
};
