import React, { useState } from "react";
import ValuesChart from "components/Charts/Values";
import ValuesModal from "components/Modals/Value";
import TeamMember from "./TeamMember";
import styles from "./Chart.module.scss";

export default ({ team, candidate }) => {
  const [memberHover, setMemberHover] = useState(candidate);
  const { valuesChart, members, values } = team;
  const [openModal, updateModal] = useState({});

  const determineIfValueIsImportant = () => {
    if (!openModal.name) return false;
    return (
      candidate.values
        .filter((d) => d.parent_id)
        .sort((a, b) => b.score - a.score)
        .slice(0, 3)
        .find((d) => d.name === openModal.name) && true
    );
  };

  return (
    <div className={styles.wrap}>
      <ValuesChart
        data={team}
        valuesChart={valuesChart}
        values={values.filter((v) => v.parent_id)}
        members={members}
        highlight={candidate}
        candidate={candidate}
        isMemberHover={memberHover && memberHover.id}
        resource="member"
        isCandidate
        team={team}
        updateModal={updateModal}
      />
      <div className={styles.avatarsWrap}>
        <TeamMember
          teamMember={candidate}
          memberHover={memberHover}
          isCurrentUser={candidate}
          setMemberHover={setMemberHover}
        />
      </div>

      <ValuesModal
        resource="member"
        valueGroups={team.valuesChart.valueGroups}
        values={team.values.filter((v) => v.parent_id)}
        dim={openModal}
        updateModal={updateModal}
        isImportant={determineIfValueIsImportant()}
        members={team.members}
        highlight={candidate}
        candidate={candidate}
        team={team}
      />
    </div>
  );
};
