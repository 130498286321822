import React from "react";
import classNames from "classnames";
import Evaluations from "./Evaluations";

import styles from "./SummarySection.module.scss";

export default ({ assessmentSession }) => {
  const { assessment_rows } = assessmentSession;

  const results = assessment_rows?.reduce((acc, row) => {
    const scores = assessmentSession.reviews.map(({ reviewer, scores }) => {
      const scoreObj = scores
        ? scores.find(({ assessment_row_id }) => assessment_row_id === row.id)
        : null;

      return {
        reviewer,
        score: scoreObj,
      };
    });

    if (!acc[row.group_title]) {
      acc[row.group_title] = [];
    }

    acc[row.group_title].push({
      ...row,
      scores,
    });

    return acc;
  }, {});

  const getAverageScore = (scores) => {
    const sum = scores.reduce((a, b) => a + b, 0);
    const avg = sum / scores.length;
    return Math.round(avg * 2) / 2;
  };

  return (
    <>
      <div className={styles.scoreContainer}>
        {Object.entries(results).map(([groupTitle, items]) => (
          <React.Fragment key={groupTitle}>
            <div className={styles.wrapper}>
              <h3>{groupTitle}</h3>
              {items.map((item, index) => {
                const mappedScores = item.scores
                  .map(({ score }) => score?.score)
                  .filter(Number.isFinite);

                return (
                  <React.Fragment key={item.id}>
                    <div
                      className={classNames(
                        styles.section,
                        styles.sectionStyle
                      )}
                    >
                      <div className={styles.numberStyle}>
                        <p className={styles.number}>{index + 1}</p>
                      </div>

                      <div
                        className={classNames(styles.section, styles.primary)}
                      >
                        <div className={styles.rightWrapper}>
                          <div className={styles.right}>
                            <div
                              className={classNames(
                                styles.titleRow,
                                styles.titleRowStyle
                              )}
                            >
                              <h5 className={styles.title}>{item.title}</h5>
                              {item.scores && item.scores.length > 0 && (
                                <div className={styles.bigScoreWrapper}>
                                  <p className={styles.legend}>
                                    Average
                                    <br />
                                    score
                                  </p>
                                  <div className={styles.bigScore}>
                                    <h5>{getAverageScore(mappedScores)}</h5>
                                    <p>out of 5</p>
                                  </div>
                                </div>
                              )}
                            </div>
                            <Evaluations item={item} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
          </React.Fragment>
        ))}
      </div>
    </>
  );
};
