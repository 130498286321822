import React, { useState } from "react";
import classNames from "classnames";
import Modal from "react-modal";
import Button from "components/UI/Button";
import Icon from "components/UI/Icon";
import UserAvatar from "components/UserAvatar";
import CompetencyModal from "components/Competencies/CompetencyModal";
import Wrapper from "components/UI/Wrapper";
import Title from "components/UI/Title";
import Subtitle from "components/UI/Subtitle";
import CompetencyPlaceholder from "components/Placeholders/CompetencyPlaceholder";
import CompetencyChart from "components/Charts/CompetencyChart";
import CompetenciesList from "./CompetenciesList";
import TeamMember from "./TeamMember";
import styles from "./Competencies.module.scss";

export default ({ team, candidate, competencies }) => {
  const [showModal, setShowModal] = useState("");
  const [showChart, setShowChart] = useState(
    candidate.position.competenciesHaveBeenConfed !== true
  );

  return (
    <div className={styles.main}>
      <Wrapper>
        <Title text="Competency map" icon="fas fa-clipboard-check" />
        <Subtitle
          text={`Use this section to identify key synergies and potential development areas during interviews. Note that this assessment is based on forced ranking, so lower prioritised competencies do not imply low ability, just a lesser focus for the candidate.`}
          verySmall
        />
        {candidate.position.competenciesHaveBeenConfed === true &&
        competencies ? (
          <>
            <CompetenciesList
              candidate={candidate}
              team={team}
              competencies={competencies}
            />
          </>
        ) : (
          <CompetencyPlaceholder
            resource="candidate"
            posNotConfed={
              candidate.position.competenciesHaveBeenConfed === false
            }
            teamId={team.id}
            posId={candidate.position.id}
          />
        )}
        {competencies && (
          <div className={styles.chartButton}>
            {showChart ? (
              <Button
                bStyle={["blue", "small"]}
                type="button"
                onClick={() => setShowChart(false)}
              >
                <Icon icon="Logo" marginRight />
                Hide candidate competencies
                <span className={classNames(styles.arrowIcon, styles.open)}>
                  <Icon icon="Chevron" />
                </span>
              </Button>
            ) : (
              <Button
                bStyle={["blue", "small"]}
                type="button"
                onClick={() => setShowChart(true)}
              >
                <Icon icon="Logo" marginRight />
                Show candidate competencies
                <span className={styles.arrowIcon}>
                  <Icon icon="Chevron" />
                </span>
              </Button>
            )}
          </div>
        )}
        {competencies && (
          <div className={styles.chartSection}>
            {showChart && (
              <>
                <div className={styles.candLegend}>
                  <div className={styles.avatar}>
                    <UserAvatar member={candidate} size="small" />
                  </div>
                  <p>{`${candidate.first_name}'s competency map`}</p>
                </div>
                <div className={styles.candCompChart}>
                  {team.members.filter((m) => m.readyToRender).length > 0 ? (
                    <>
                      <CompetencyChart
                        member={candidate.competencyGraph}
                        competencies={team.competencyGraph}
                        setShowModal={setShowModal}
                        type="memberInTeam"
                      />
                      <div className={styles.avatarsWrap}>
                        <TeamMember
                          key={candidate.id}
                          member={candidate}
                          userId={candidate.id}
                          teamCompetencies={team.competencyGraph}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <CompetencyChart
                        competencies={candidate.competencyGraph}
                        setShowModal={setShowModal}
                        type="candidate"
                      />
                    </>
                  )}
                </div>
                <Modal
                  isOpen={showModal !== ""}
                  onRequestClose={() => setShowModal("")}
                  shouldCloseOnOverlayClick
                >
                  <Button
                    bStyle="close"
                    type="button"
                    onClick={() => setShowModal("")}
                  >
                    <i className="fal fa-times" />
                  </Button>
                  <CompetencyModal
                    resource="candidate"
                    competencies={
                      team.members.filter((m) => m.readyToRender).length > 0
                        ? team.competencies
                        : competencies
                    }
                    members={candidate.position.parentTeam.members}
                    candidate={candidate}
                    setShowModal={setShowModal}
                    currentCompName={showModal}
                  />
                </Modal>
              </>
            )}
          </div>
        )}
      </Wrapper>
    </div>
  );
};
