import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import AddEvaluator from "./AddEvaluator";
import SidebarWrapper from "components/UI/SidebarWrapper";
import SidebarHeader from "components/UI/SidebarHeader";
import Button from "components/UI/Button";
import Icon from "components/UI/Icon";
import UserAvatar from "components/UserAvatar";
import { printoutRoute } from "client/authRoutes";
import SimpleEvaluationSection from "./SimpleEvaluationSection";
import SummarySection from "./SummarySection";
import { useSidebar } from "utils/sidebarProvider";
import compose from "./compose";

import styles from "./Interviewguide.module.scss";

export default compose(
  ({
    data: { candidateInTeam },
    currentTab,
    setCurrentTab,
    list,
    selectedEvaluators,
    setSelectedEvaluators,
    overlayClose,
    setOverlayClose,
    openModal,
    updateModal,
    match: {
      params: { candidateType },
    },
  }) => {
    const getEvaluatorIds = () => {
      var evaluators = [];
      selectedEvaluators.map((se) => {
        evaluators.push(se.id);
      });

      if (evaluators.length > 0) {
        return "?evaluators=" + evaluators.join();
      } else {
        return "";
      }
    };

    const { contentContext } = useSidebar();
    const [content, setContent] = contentContext;

    const listItems = [];

    list.map((item, index) => {
      listItems.push({
        title: item,
        action: () => setCurrentTab(index),
        style: currentTab === index ? "active" : "",
      });
    });

    const sidebarContent = [
      {
        items: listItems,
      },
    ];

    useEffect(() => {
      setContent(sidebarContent);
    }, [currentTab]);

    return (
      <>
        <div className={styles.main}>
          <SidebarWrapper>
            <SidebarHeader title={"Interview guide"}>
              <Button
                bStyle={["verySmall"]}
                type="button"
                onClick={() => updateModal("addEvaluators")}
              >
                Add interviewers
              </Button>
              <Modal
                closeTimeoutMS={350}
                isOpen={openModal === "addEvaluators"}
                onRequestClose={() => updateModal("")}
                shouldCloseOnOverlayClick={overlayClose}
                shouldCloseOnEsc={overlayClose}
                className={styles.modal}
              >
                <AddEvaluator
                  updateModal={updateModal}
                  setOverlayClose={setOverlayClose}
                />
              </Modal>
              {currentTab === 0 && (
                <>
                  <Button
                    bStyle={["primary", "verySmall"]}
                    type="button"
                    onClick={() => setCurrentTab(1)}
                  >
                    <Icon icon="CheckLarge" marginRight />
                    {window.innerWidth > 534 ? "Finish evaluation" : "Finish"}
                  </Button>
                  <a
                    href={`${printoutRoute}/printout/interview_guide/${candidateType}/${candidateInTeam.position.parent_org_id}/${candidateInTeam.position.parent_team_id}/${candidateInTeam.position.id}/${candidateInTeam.id}/${candidateInTeam.first_name}_${candidateInTeam.last_name}_interview_guide.pdf`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button type="button" bStyle={["secondary", "verySmall"]}>
                      <Icon icon="Print" marginRight />
                      {window.innerWidth > 534 ? "Print PDF" : "Print"}
                    </Button>
                  </a>
                  <Link
                    to={`/team/${candidateInTeam.position.parent_team_id}/position/${candidateInTeam.position.id}`}
                  >
                    <Button bStyle={["none"]}>
                      <span className={styles.close}>
                        <Icon icon="Close" />
                      </span>
                    </Button>
                  </Link>
                </>
              )}
              {currentTab === 1 && (
                <>
                  <Link
                    to={`/team/${candidateInTeam.position.parent_team_id}/position/${candidateInTeam.position.id}`}
                  >
                    <Button
                      bStyle={["primary", "verySmall"]}
                      type="button"
                      onClick={() => setCurrentTab(2)}
                    >
                      <span className={styles.arrow}>
                        <Icon icon="Arrow" />
                      </span>
                      Back to position
                    </Button>
                  </Link>
                  <a
                    href={`${printoutRoute}/printout/evaluation_summary/${
                      candidateInTeam.position.parent_org_id
                    }/${candidateInTeam.position.parent_team_id}/${
                      candidateInTeam.position.id
                    }/${candidateInTeam.id}/${candidateInTeam.first_name}_${
                      candidateInTeam.last_name
                    }_evaluation_summary${getEvaluatorIds()}.pdf`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button type="button" bStyle={["secondary", "verySmall"]}>
                      <Icon icon="Print" marginRight />
                      Print PDF
                    </Button>
                  </a>
                  <Link
                    to={`/team/${candidateInTeam.position.parent_team_id}/position/${candidateInTeam.position.id}`}
                  >
                    <Button bStyle={["none"]}>
                      <span className={styles.close}>
                        <Icon icon="Close" />
                      </span>
                    </Button>
                  </Link>
                </>
              )}
            </SidebarHeader>
            <div className={styles.content}>
              {currentTab === 0 && (
                <>
                  <div className={styles.head}>
                    <div className={styles.left}>
                      <h2 className={styles.title}>
                        <span className={styles.icon}>
                          <Icon icon="Clipboard" />
                        </span>
                        Interview & Evaluate
                      </h2>
                      <div className={styles.candidate}>
                        <UserAvatar member={candidateInTeam} size="medium" />
                        <div className={styles.right}>
                          <h4 className={styles.name}>
                            {candidateInTeam.name}
                          </h4>
                          <p className={styles.email}>
                            {candidateInTeam.email}
                          </p>
                        </div>
                      </div>
                    </div>
                    <p className={styles.subtitle}>
                      Use this as your guide during the interview to have more
                      in-depth conversations. Focus on facts and past behaviours
                      and avoid asking about hypothetical situations. If the
                      answers seem vague, ask follow-up questions to understand
                      if the candidate can explain the details.
                      <br />
                      <br />
                      After the interview, immediately write down your interview
                      notes and ratings. To avoid group thinking, evaluate
                      individually before discussing the candidate with others.
                    </p>
                  </div>
                  <SimpleEvaluationSection candidate={candidateInTeam} />
                </>
              )}
              {currentTab === 1 && (
                <>
                  <div className={styles.head}>
                    <div className={styles.left}>
                      <h2 className={styles.title}>
                        <span className={styles.icon}>
                          <Icon icon="Clipboard" />
                        </span>
                        Summary
                      </h2>
                      <div className={styles.candidate}>
                        <UserAvatar member={candidateInTeam} size="medium" />
                        <div className={styles.right}>
                          <h4 className={styles.name}>
                            {candidateInTeam.name}
                          </h4>
                          <p className={styles.email}>
                            {candidateInTeam.email}
                          </p>
                        </div>
                      </div>
                    </div>
                    <p className={styles.subtitle}>
                      The summary includes all scores and comments given by all
                      stakeholders to the candidate. If all looks good, you can
                      download a PDF version of the summary or go back to the
                      Hiring Dashboard.
                    </p>
                  </div>
                  <SummarySection
                    candidate={candidateInTeam}
                    selectedEvaluators={selectedEvaluators}
                    setSelectedEvaluators={setSelectedEvaluators}
                  />
                </>
              )}
            </div>
          </SidebarWrapper>
        </div>
      </>
    );
  }
);
