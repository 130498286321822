import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import Button from "components/UI/Button";
import Icon from "components/UI/Icon";
import compose from "./compose";

import styles from "./Survey.module.scss";

function createMarkup(text) {
  return { __html: text };
}

export default compose(
  ({
    data: { surveys },
    title,
    text,
    button,
    color,
    image,
    skippable,
    history,
    handlePrevStep,
    handleNextStep,
    handleSkipStep
  }) => {
    useEffect(() => {
      if (!surveys.some(survey => !survey.complete)) {
        window.Intercom("update", { "profile-complete": true });
      }
    }, [surveys]);
    return (
      <div className={styles.wrapper}>
        <div className={styles.left}>
          <h1 className={styles.title}>{title}</h1>
          <p
            className={styles.subtitle}
            dangerouslySetInnerHTML={createMarkup(text)}
          />
          <div className={styles.list}>
            {surveys.map(survey => (
              <div
                key={"Survey-" + survey.id}
                className={classNames(
                  styles.card,
                  styles.active,
                  survey.complete && styles.complete
                )}
              >
                <div
                  className={styles.link}
                  onClick={() => history.push(`/survey/${survey.id}`)}
                >
                  <div className={styles.cardLeft}>
                    <div
                      className={classNames(
                        styles.icon,
                        survey.complete && styles.complete
                      )}
                    >
                      {survey.complete ? (
                        <Icon icon="Check" />
                      ) : (
                        <Icon icon={survey.icon} />
                      )}
                    </div>
                    <h5>{survey.type}</h5>
                  </div>
                  <div className={styles.cardRight}>
                    <p className={styles.time}>{survey.duration}</p>
                    <Icon icon="Chevron" />
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className={styles.buttons}>
            <Button
              bStyle={["primary", "small"]}
              onClick={() => {
                if (surveys.some(s => !s.complete))
                  return history.push(
                    `/survey/${surveys.find(s => !s.complete).id}`
                  );
                handleNextStep();
              }}
            >
              Continue
            </Button>

            {surveys.some(s => !s.complete) && skippable && (
              <Button bStyle={["secondary", "small"]} onClick={handleSkipStep}>
                Skip
              </Button>
            )}
          </div>
        </div>
        <div className={classNames(styles.right, styles[color])}>
          <div className={styles.circle}></div>
          <div className={styles.img}>
            <img src={image} />
          </div>
        </div>
      </div>
    );
  }
);
