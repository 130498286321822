import React from "react";
import { compose } from "recompose";
import { graphql } from "react-apollo";
import handleLoading from "utils/loader";
import Circles from "components/Loaders/Circles";
import { withRouter } from "react-router-dom";
import withStore from "utils/withStore";
import { loader } from "graphql.macro";

const POSITION_TEMPLATE = loader("client/Queries/PositionTemplate8.graphql");

export default compose(
  withRouter,
  withStore("viewer"),
  withStore("account"),
  graphql(POSITION_TEMPLATE, {
    options: ({ orgId, templateId, templateSecret }) => ({
      variables: {
        id: templateId,
        secret: templateSecret,
        org_id: parseInt(orgId, 10),
      },
    }),
  }),
  handleLoading(() => <Circles />)
);
