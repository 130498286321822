import React from "react";
import Layout from "layouts";

const StepIntro = () => {
  return (
    <Layout
      variant="2-col-page"
      icon="360Assesment"
      title="Create a new session"
    ></Layout>
  );
};

export default StepIntro;
