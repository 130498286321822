import React, { useState } from "react";
import compose from "./compose";
import Invite from "components/Invite";
import Button from "components/UI/Button";
import TeamMembers from "./TeamMembers";
import Search from "./Search";
import Players from "./Players";
import styles from "./Invite.module.scss";

export default compose(
  ({
    data: { team },
    play,
    addToPlayersList,
    removePlayer,
    players,
    nameOrEmail,
    setNameOrEmail,
    setError,
    error,
    list,
    updateList,
    handleSendInviteEmail,
    remindLoading,
  }) => {
    return (
      <div className={styles.wrapper}>
        <p className={styles.subtitle}>
          Send an invitation to team members who should participate in the
          evaluation.
        </p>
        <div className={styles.inviteWrapper}>
          <Search
            team={team}
            nameOrEmail={nameOrEmail}
            setNameOrEmail={setNameOrEmail}
            error={error}
            setError={setError}
            addToPlayersList={addToPlayersList}
            players={play.players}
          />
          <TeamMembers addToPlayersList={addToPlayersList} list={list} />
          <Players players={play.players} removePlayer={removePlayer} />
          {play.players.length > 0 && (
            <div className={styles.inviteButton}>
              <Button
                onClick={handleSendInviteEmail}
                bStyle={["primary", "small"]}
                disabled={remindLoading !== ""}
              >
                {remindLoading === "" ? "Send invites" : remindLoading}
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  }
);
