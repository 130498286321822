import React, { useState } from "react";
import classNames from "classnames";
import Popup from "reactjs-popup";
import { Form } from "react-final-form";
import DropDown from "components/UI/DropDown";
import Button from "components/UI/Button";
import Icon from "components/UI/Icon";
import FormInfo from "components/PositionTemplates/UseTemplatePopup";

import styles from "./Actions.module.scss";

export default ({
  selectedOrg,
  updateModal,
  history,
  handleCreatePosition,
  data,
  selectedTeam,
  setSelectedTeam,
}) => {
  const [formStep, setFormStep] = useState(0);
  const [positionName, setPositionName] = useState("");

  const actions = {
    admin: [
      {
        icon: "User",
        color: "green",
        title: "Add people",
        subtitle: "Invite new member",
        action: () => updateModal("invitePeople"),
      },
      {
        icon: "Users",
        color: "primary",
        title: "Add teams",
        subtitle: "Create a new team",
        action: () => updateModal("newTeam"),
      },
      {
        icon: "Signpost",
        color: "yellow",
        title: "Start hiring",
        subtitle: "Create new position",
        action: "createPosition",
      },
    ],
    member: [
      {
        icon: "User",
        color: "green",
        title: "Add people",
        subtitle: "Invite new member",
        action: () => {},
        disabled: true,
      },
      {
        icon: "Users",
        color: "primary",
        title: "Add teams",
        subtitle: "Create a new team",
        action: () => {},
        disabled: true,
      },
      {
        icon: "Signpost",
        color: "yellow",
        title: "Start hiring",
        subtitle: "Create new position",
        action: "createPosition",
        disabled: true,
      },
    ],
    candidate: [],
  };

  return (
    <div className={styles.wrapper}>
      {actions[selectedOrg.contract.role].map((action) => {
        if (action.action !== "createPosition") {
          return (
            <div
              key={"Action-" + action.title}
              className={classNames(
                styles.actionButton,
                action.disabled && styles.disabled
              )}
            >
              <Button
                bStyle={["none"]}
                onClick={action.action}
                disabled={action.disabled}
              >
                <div className={styles.card}>
                  <div
                    className={classNames(styles.icon, styles[action.color])}
                  >
                    <Icon icon={action.icon} />
                  </div>
                  <div className={styles.right}>
                    <h4 className={styles.actionTitle}>{action.title}</h4>
                    <p className={styles.action}>{action.subtitle}</p>
                  </div>
                </div>
              </Button>
              {action.disabled && (
                <span className={styles.tooltip}>
                  <p>
                    You do not have admin rights under this organization. Please
                    contact your workspace admin to access this functionality.
                  </p>
                </span>
              )}
            </div>
          );
        } else {
          return (
            <div
              key="Action-CreatePosition"
              className={classNames(
                "dropdownWrapper",
                action.disabled && styles.disabled
              )}
              id="dropdownMenu"
            >
              <Popup
                keepTooltipInside="body"
                disabled={action.disabled}
                trigger={
                  <div
                    className={classNames(styles.actionButton, styles.noMargin)}
                  >
                    <Button bStyle={["none"]} disabled={action.disabled}>
                      <div className={styles.card}>
                        <div
                          className={classNames(
                            styles.icon,
                            styles[action.color]
                          )}
                        >
                          <Icon icon={action.icon} />
                        </div>
                        <div className={styles.right}>
                          <h4 className={styles.actionTitle}>{action.title}</h4>
                          <p className={styles.action}>{action.subtitle}</p>
                        </div>
                      </div>
                    </Button>
                    {action.disabled && (
                      <span className={styles.tooltip}>
                        <p>
                          You do not have admin rights under this organization.
                          Please contact your workspace admin to access this
                          functionality.
                        </p>
                      </span>
                    )}
                  </div>
                }
                position="bottom left"
              >
                {(close) => (
                  <DropDown>
                    <Form
                      autoComplete="new-password"
                      onSubmit={handleCreatePosition}
                      render={({ handleSubmit, pristine, values }) => {
                        return (
                          <div className={styles.popup}>
                            <form onSubmit={handleSubmit} autoComplete="off">
                              <FormInfo
                                data={data}
                                selectedTeam={selectedTeam}
                                setSelectedTeam={setSelectedTeam}
                                selectedTemplate={null}
                                formStep={formStep}
                                setFormStep={setFormStep}
                                positionName={positionName}
                                setPositionName={setPositionName}
                              />
                              {formStep === 1 ? (
                                <div className={styles.buttonGroup}>
                                  <Button
                                    bStyle={["primary", "small"]}
                                    type="button"
                                    onClick={() =>
                                      history.push(
                                        selectedOrg.id +
                                          "/scorecards?name=" +
                                          values.name +
                                          "&teamId=" +
                                          selectedTeam
                                      )
                                    }
                                  >
                                    Choose scorecard
                                  </Button>
                                  <Button
                                    bStyle={["secondary", "small"]}
                                    type="submit"
                                  >
                                    Create blank position
                                  </Button>
                                </div>
                              ) : (
                                <div className={styles.buttonGroup}>
                                  <Button
                                    bStyle={["primary", "small"]}
                                    type="button"
                                    onClick={() => setFormStep(1)}
                                    disabled={
                                      pristine ||
                                      !values.name ||
                                      !selectedTeam ||
                                      selectedTeam === -1
                                    }
                                  >
                                    Continue
                                  </Button>
                                  <Button
                                    bStyle={["secondary", "small"]}
                                    type="button"
                                    onClick={close}
                                  >
                                    Cancel
                                  </Button>
                                </div>
                              )}
                            </form>
                          </div>
                        );
                      }}
                    />
                  </DropDown>
                )}
              </Popup>
            </div>
          );
        }
      })}
    </div>
  );
};
