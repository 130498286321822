import { withRouter } from "react-router-dom";
import { compose, withState, withHandlers } from "recompose";
import { graphql, withApollo } from "react-apollo";
import { loader } from "graphql.macro";

const CANDIDATE_QUERY = loader("client/Queries/CandidateQuery198.graphql");
const UPSERT_CANDIDATE_ACCOUNT_DIM = loader(
  "./upsertCandidateAccountDimScore.graphql"
);
const DELETE_CANDIDATE_ACCOUNT_DIM = loader(
  "./deleteCandidateAccountDimScore1.graphql"
);
const DELETE_CANDIDATE_ATTACHMENT = loader(
  "./deleteCandidateAttachment.graphql"
);
const CANDIDATE_ATTACHMENTS = loader(
  "client/Queries/candidateAttachments1.graphql"
);
const ATTACHMENT_READ_SIGNED_URL = loader("./attachmentReadSignedUrl.graphql");

export default compose(
  withRouter,
  withApollo,
  graphql(DELETE_CANDIDATE_ATTACHMENT, {
    name: "deleteCandidateAttachment",
  }),
  graphql(UPSERT_CANDIDATE_ACCOUNT_DIM, {
    name: "upsertCandidateAccountDimScore",
    options: (props) => {
      return {
        update: (proxy, { data: { upsertCandidateAccountDimScore } }) => {
          const type = props.match.params.candidateType;
          const data = proxy.readQuery({
            query: CANDIDATE_QUERY,
            variables: {
              user_id:
                type === "candidate"
                  ? parseInt(props.match.params.candidateId, 10)
                  : null,
              person_id:
                type === "person"
                  ? parseInt(props.match.params.candidateId, 10)
                  : null,
              team_id: parseInt(props.match.params.teamId, 10),
              position_id: parseInt(props.match.params.positionId, 10),
              org_id: parseInt(props.match.params.orgId, 10),
            },
          });

          data.candidateInTeam.accountDimGroups.map((dg, index) => {
            dg.dimensions.map((dim, index2) => {
              if (dim.dim_id === upsertCandidateAccountDimScore.dim_id) {
                data.candidateInTeam.accountDimGroups[index].dimensions[
                  index2
                ].score = upsertCandidateAccountDimScore.score;
              }
            });
          });

          proxy.writeQuery({
            query: CANDIDATE_QUERY,
            variables: {
              user_id:
                type === "candidate"
                  ? parseInt(props.match.params.candidateId, 10)
                  : null,
              person_id:
                type === "person"
                  ? parseInt(props.match.params.candidateId, 10)
                  : null,
              team_id: parseInt(props.match.params.teamId, 10),
              position_id: parseInt(props.match.params.positionId, 10),
              org_id: parseInt(props.match.params.orgId, 10),
            },
            data: data,
          });
        },
      };
    },
  }),
  graphql(DELETE_CANDIDATE_ACCOUNT_DIM, {
    name: "deleteCandidateAccountDimScore",
    options: (props) => {
      return {
        update: (proxy, { data: { deleteCandidateAccountDimScore } }) => {
          const type = props.match.params.candidateType;
          const data = proxy.readQuery({
            query: CANDIDATE_QUERY,
            variables: {
              user_id:
                type === "candidate"
                  ? parseInt(props.match.params.candidateId, 10)
                  : null,
              person_id:
                type === "person"
                  ? parseInt(props.match.params.candidateId, 10)
                  : null,
              team_id: parseInt(props.match.params.teamId, 10),
              position_id: parseInt(props.match.params.positionId, 10),
              org_id: parseInt(props.match.params.orgId, 10),
            },
          });

          data.candidateInTeam.accountDimGroups.map((dg, index) => {
            dg.dimensions.map((dim, index2) => {
              if (dim.dim_id === deleteCandidateAccountDimScore.dim_id) {
                data.candidateInTeam.accountDimGroups[index].dimensions[
                  index2
                ].score = null;
              }
            });
          });

          proxy.writeQuery({
            query: CANDIDATE_QUERY,
            variables: {
              user_id:
                type === "candidate"
                  ? parseInt(props.match.params.candidateId, 10)
                  : null,
              person_id:
                type === "person"
                  ? parseInt(props.match.params.candidateId, 10)
                  : null,
              team_id: parseInt(props.match.params.teamId, 10),
              position_id: parseInt(props.match.params.positionId, 10),
              org_id: parseInt(props.match.params.orgId, 10),
            },
            data: data,
          });
        },
      };
    },
  }),
  withHandlers({
    handleDeleteAttachment:
      ({
        match: {
          params: { candidateType },
        },
        deleteCandidateAttachment,
        candidate,
        client,
      }) =>
      async ({ id, file_name, content_type }) => {
        await deleteCandidateAttachment({
          variables: {
            id,
            position_id: candidate.position.id,
            user_id: candidateType === "candidate" ? candidate.id : null,
            person_id: candidateType === "person" ? candidate.id : null,
          },
        });
        await client.query({
          query: CANDIDATE_ATTACHMENTS,
          variables: {
            user_id: candidateType === "candidate" ? candidate.id : null,
            person_id: candidateType === "person" ? candidate.id : null,
            position_id: candidate.position.id,
            team_id: candidate.position.parent_team_id,
            org_id: candidate.position.parent_org_id,
          },
          fetchPolicy: "network-only",
        });
      },
    handleUpsertCandidateAccountDimScore:
      ({
        upsertCandidateAccountDimScore,
        match: {
          params: { candidateId, positionId, candidateType },
        },
      }) =>
      async (score, dim_id) => {
        try {
          const response = await upsertCandidateAccountDimScore({
            variables: {
              dim_id: dim_id,
              position_id: parseInt(positionId, 10),
              user_id:
                candidateType === "candidate"
                  ? parseInt(candidateId, 10)
                  : null,
              person_id:
                candidateType === "person" ? parseInt(candidateId, 10) : null,
              score: parseInt(score, 10),
            },
          });
        } catch (err) {
          throw new Error(err);
        }
      },
    handleDeleteCandidateAccountDimScore:
      ({
        deleteCandidateAccountDimScore,
        match: {
          params: { candidateId, positionId, candidateType },
        },
      }) =>
      async (dim_id) => {
        try {
          const response = await deleteCandidateAccountDimScore({
            variables: {
              dim_id: dim_id,
              position_id: parseInt(positionId, 10),
              user_id:
                candidateType === "candidate"
                  ? parseInt(candidateId, 10)
                  : null,
              person_id:
                candidateType === "person" ? parseInt(candidateId, 10) : null,
            },
          });
        } catch (err) {
          throw new Error(err);
        }
      },
    handleAttachmentDownload:
      ({
        match: {
          params: { candidateType },
        },
        client,
        candidate,
      }) =>
      async ({ id, file_name }) => {
        console.log({ id, file_name });
        const {
          data: { attachmentReadSignedUrl },
        } = await client.query({
          query: ATTACHMENT_READ_SIGNED_URL,
          variables: {
            user_id: candidateType === "candidate" ? candidate.id : null,
            person_id: candidateType === "person" ? candidate.id : null,
            position_id: candidate.position.id,
            file_name,
            id,
          },
          fetchPolicy: "network-only",
        });

        const link = document.createElement("a");
        link.href = attachmentReadSignedUrl;
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      },
  })
);
