import { useState, useEffect, useCallback } from "react";
import { useSocket } from "./SocketContext";
import { v4 as uuidv4 } from "uuid";

const useSocketResponse = (conversationId) => {
  const { socket, responses, clearResponse } = useSocket();
  const [response, setResponse] = useState(null);

  const sendRequest = useCallback(
    (event, data) => {
      socket.emit(event, {
        ...data,
        conversationId,
        requestId: uuidv4(),
      });
    },
    [socket]
  );

  useEffect(() => {
    if (responses[conversationId]) {
      setResponse(responses[conversationId]);
      clearResponse(conversationId);
    }
  }, [conversationId, responses, clearResponse]);

  return { sendRequest, response };
};

export default useSocketResponse;
