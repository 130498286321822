import React from "react";
import classNames from "classnames";
import Modal from "react-modal";
import Pagination from "components/UI/Pagination";
import Circles from "components/Loaders/Circles";
import Select from "components/UI/Select";
import RemoveMember from "components/Modals/RemoveMember";
import compose from "./compose";
import styles from "./UsersList.module.scss";
import MemberRow from "./MemberRow";
import OrderButton from "./OrderButton";

export default compose(
  ({
    account,
    ACCOUNT_MEMBERS,
    setOrder,
    order,
    handleChangeAccountContract,
    viewer,
    limit,
    setLimit,
    pageNr,
    setPageNr,
    searchValue,
    removeMemberModal,
    updateRemoveMemberModal,
    handleRemoveUser,
    accountMembers,
  }) => {
    if (ACCOUNT_MEMBERS && ACCOUNT_MEMBERS.loading) {
      return (
        <div className={styles.sectionWrapper}>
          <div className={styles.loader}>
            <Circles />
          </div>
        </div>
      );
    }

    // console.log({accountMembers})
    return (
      <div className={styles.wrapper}>
        <table>
          <tbody>
            <tr className={styles.tableHead}>
              <td className={classNames(styles.withButton, styles.name)}>
                Name
                <OrderButton column="email" setOrder={setOrder} order={order} />
              </td>
              <td
                className={classNames(styles.withButton, styles.organizations)}
              >
                Organisations
              </td>
              <td className={classNames(styles.withButton, styles.status)}>
                Status
              </td>
              <td className={styles.guest} />
              <td className={classNames(styles.withButton, styles.permissions)}>
                Role
                <OrderButton
                  column="account_role"
                  setOrder={setOrder}
                  order={order}
                />
              </td>
              <td />
              <td />
            </tr>
            {accountMembers.map((user, i) => (
              <MemberRow
                key={`${user.type}-${user.id}-${Math.random(10)}`}
                user={user}
                viewer={viewer}
                handleChangeAccountContract={handleChangeAccountContract}
                updateRemoveMemberModal={updateRemoveMemberModal}
              />
            ))}
          </tbody>
        </table>
        {!searchValue && (
          <div className={styles.paginationRow}>
            <div className={styles.show}>
              <p>Show:</p>
              <Select
                small
                value={limit}
                onChange={(e) => setLimit(e.target.value)}
              >
                <option value={15}>15</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </Select>
            </div>
            <Pagination
              pageNr={pageNr}
              setPageNr={setPageNr}
              limit={limit}
              indexesLength={account.membersInAccount}
            />
          </div>
        )}
        <Modal
          isOpen={!!removeMemberModal}
          onRequestClose={() => updateRemoveMemberModal(false)}
          shouldCloseOnOverlayClick
        >
          <RemoveMember
            member={removeMemberModal}
            type="account"
            updateRemoveMemberModal={updateRemoveMemberModal}
            handleRemoveUser={handleRemoveUser}
          />
        </Modal>
      </div>
    );
  }
);
