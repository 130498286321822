import React, { useEffect } from "react";
import Modal from "react-modal";
import classNames from "classnames";
import TextareaAutosize from "react-autosize-textarea";
import SidebarWrapper from "components/UI/SidebarWrapper";
import SidebarHeader from "components/UI/SidebarHeader";
import Icon from "components/UI/Icon";
import Button from "components/UI/Button";
import Field from "components/UI/Field";
import KeyObjectives from "./KeyObjectives";
import Requirements from "./Requirements";
import Competencies from "./Competencies";
import compose from "./compose";

import styles from "./EditPosition.module.scss";

export default compose(
  ({
    data: { openPosition },
    nameValue,
    setNameValue,
    descValue,
    setDescValue,
    saved,
    setSaved,
    openModal,
    updateModal,
    handleUpdatePosition,
    history,
    match,
  }) => {
    useEffect(() => {
      let timer = setTimeout(
        () => handleUpdatePosition(nameValue, descValue),
        500
      );

      return () => {
        clearTimeout(timer);
      };
    }, [nameValue, descValue]);

    console.log({ openPosition });

    return (
      <>
        <form autoComplete="off">
          <SidebarWrapper>
            <SidebarHeader title="Edit position">
              <Button
                bStyle={["green", "small"]}
                onClick={() => {
                  history.push(
                    `/team/${parseInt(
                      match.params.teamId,
                      10
                    )}/position/${parseInt(match.params.positionId, 10)}`
                  );
                }}
              >
                Save
              </Button>
              <Button
                bStyle={["secondary", "small"]}
                onClick={() => {
                  history.push(
                    `/team/${parseInt(
                      match.params.teamId,
                      10
                    )}/position/${parseInt(match.params.positionId, 10)}`
                  );
                }}
              >
                Cancel
              </Button>
            </SidebarHeader>
            <div className={styles.contentWrapper}>
              <h2 className={styles.title}>
                <div className={styles.icon}>
                  <Icon icon="Edit" />
                </div>
                Edit Position
              </h2>
              <p className={styles.subtitle}>
                After making changes to the position, don't forget to hit the
                green "Save" button on the top right-hand side corner!
              </p>
              <div className={styles.row}>
                <p
                  className={classNames(styles.autosave, saved && styles.show)}
                >
                  Auto-saved
                </p>
                <h5>Position name</h5>
                <div className={styles.smallRow}>
                  <Field
                    white
                    fullWidth
                    type="text"
                    name="name"
                    required
                    placeholder="Position name"
                    value={nameValue}
                    onChange={(e) => {
                      setSaved(false);
                      setNameValue(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className={styles.row}>
                <h5>Position description</h5>
                <div className={styles.smallRow}>
                  <TextareaAutosize
                    rows={4}
                    maxRows={24}
                    name="comment"
                    placeholder="Add a description"
                    className={styles.textarea}
                    onChange={(e) => {
                      setSaved(false);
                      setDescValue(e.target.value);
                    }}
                    value={descValue}
                    data-gramm_editor="false"
                  />
                </div>
              </div>
              <div className={styles.editParts}>
                <Button
                  bStyle={["none"]}
                  onClick={() => updateModal("objectives")}
                >
                  <div className={styles.card}>
                    <div className={styles.icon}>
                      <Icon icon="Signpost" />
                    </div>
                    <div className={styles.text}>
                      <h5>Key objectives</h5>
                      <p>Define key objectives of the position</p>
                    </div>
                  </div>
                </Button>
                <Button
                  bStyle={["none"]}
                  onClick={() => updateModal("requirements")}
                >
                  <div className={styles.card}>
                    <div className={styles.icon}>
                      <Icon icon="Edit" />
                    </div>
                    <div className={styles.text}>
                      <h5>Basic requirements</h5>
                      <p>
                        Make a list of required experiences, qualifications, and
                        skills
                      </p>
                    </div>
                  </div>
                </Button>
                <Button
                  bStyle={["none"]}
                  onClick={() => updateModal("competencies")}
                >
                  <div className={styles.card}>
                    <div className={styles.icon}>
                      <Icon icon="Clipboard" />
                    </div>
                    <div className={styles.text}>
                      <h5>Competencies</h5>
                      <p>
                        Map competencies that distinguish superior performers
                      </p>
                    </div>
                  </div>
                </Button>
              </div>
            </div>
          </SidebarWrapper>
        </form>
        <Modal
          closeTimeoutMS={350}
          isOpen={openModal === "objectives"}
          onRequestClose={() => updateModal("")}
          shouldCloseOnOverlayClick
          className={styles.modal}
        >
          <Button bStyle="close" type="button" onClick={() => updateModal("")}>
            <i className="fal fa-times" />
          </Button>
          <KeyObjectives
            position={openPosition}
            closeModal={() => updateModal("")}
            orgId={parseInt(match.params.orgId, 10)}
          />
        </Modal>
        <Modal
          closeTimeoutMS={350}
          isOpen={openModal === "requirements"}
          onRequestClose={() => updateModal("")}
          shouldCloseOnOverlayClick
          className={styles.modal}
        >
          <Button bStyle="close" type="button" onClick={() => updateModal("")}>
            <i className="fal fa-times" />
          </Button>
          <Requirements
            position={openPosition}
            closeModal={() => updateModal("")}
            orgId={parseInt(match.params.orgId, 10)}
          />
        </Modal>
        <Modal
          closeTimeoutMS={350}
          isOpen={openModal === "competencies"}
          onRequestClose={() => updateModal("")}
          shouldCloseOnOverlayClick
          className={styles.modal}
        >
          <Button bStyle="close" type="button" onClick={() => updateModal("")}>
            <i className="fal fa-times" />
          </Button>
          <Competencies
            position={openPosition}
            orgId={parseInt(match.params.orgId, 10)}
            closeModal={() => updateModal("")}
          />
        </Modal>
      </>
    );
  }
);
