import { SELECT_ORG, RESET_ORG, UPDATE_ORG_NAME } from 'store/actionTypes'

const initialState = {
  id: null,
  name: '',
  adminRights: false,
  account: null,
  contract: {}
}

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_ORG_NAME:
      return Object.assign({}, state, action.payload)

    case SELECT_ORG:
      return Object.assign({}, state, action.payload)

    case RESET_ORG:
      return Object.assign({}, state, initialState)

    default:
      return state
  }
}
