import React from 'react'

import styles from './IENotSupported.module.scss'

export default () => (
  <div className={styles.wrapper}>
    <div className={styles.ieOverlay}>
      <h3 className={styles.title}>
        <i className='far fa-frown' />
        This feature is not supported on Internet Explorer.
      </h3>
      <p className={styles.subtitle}>
        Microsoft has ended support for Internet Explorer. Please consider using
        a modern web browser (i.e
        {' '}
        <a
          target='_blank'
          rel='noopener noreferrer'
          href='https://www.google.com/chrome/'
        >
          Chrome
        </a>
        ,
        {' '}
        <a
          target='_blank'
          rel='noopener noreferrer'
          href='https://www.mozilla.org/en-US/firefox/new/'
        >
          Firefox
        </a>
        ).
      </p>
    </div>
  </div>
)
