import React, { useEffect } from "react";
import AIChat from "components/AIChat";
import SidebarWrapper from "components/UI/SidebarWrapper";
import SidebarHeader from "components/UI/SidebarHeader";
import Icon from "components/UI/Icon";
import { Element } from "react-scroll";
import ExploreMore from "components/UI/ExploreMore";
import Button from "components/UI/Button";
import { printoutRoute } from "client/authRoutes";
import { useSidebar } from "utils/sidebarProvider";
import compose from "./compose";
import User from "./User";
import Summary from "./Summary";
import Personality from "./Personality";
import Values from "./Values";
import Competencies from "./Competencies";

import styles from "./Personal.module.scss";

export default compose(
  ({
    data,
    match: {
      params: { orgId, userId },
    },
  }) => {
    const {
      teamsAndPositionsInOrgByResouceType: { user },
    } = data;

    const { contentContext } = useSidebar();
    const [content, setContent] = contentContext;

    const sidebarContent = [
      {
        sectionTitle: "Navigation",
        items: [
          {
            title: "Competencies",
            scrollTo: true,
          },
          {
            title: "Personality",
            scrollTo: true,
          },
          {
            title: "Values",
            scrollTo: true,
          },
        ],
      },
    ];

    useEffect(() => {
      setContent(sidebarContent);
    }, []);

    return (
      <SidebarWrapper>
        <SidebarHeader title={user.name} subtitle="Member profile">
          <Button
            bStyle={["verySmall"]}
            onClick={() =>
              window.open(
                `${printoutRoute}/printout/orguser/${orgId}/${userId}/${user.name.replace(
                  " ",
                  "_"
                )}_personal_profile.pdf`,
                "_blank"
              )
            }
          >
            <Icon icon="Print" marginRight />
            Print
          </Button>
        </SidebarHeader>
        <div className={styles.wrapper}>
          <AIChat
            sequence={"org_user_profile_chat_start"}
            contexts={["user"]}
            variables={[{ key: "user_id", value: user.id }]}
            description="An AI assistant trained to help you make sense of the user's Personal Profile and give actionable advice."
            examplePrompts={[
              "What 3 career paths would suit " + user.first_name + " best?",
              "Recommend three books for " +
                user.first_name +
                "'s personal development",
              "How should " +
                user.first_name +
                " plan their weekly calendar based on their personality?",
              "Explain " + user.first_name + "'s assessment results to me",
              "Based on " +
                user.first_name +
                "'s personality, what animal would they be?",
            ]}
            heapEvent="create-org-user-chatbot-prompt"
            isReady={user.profileComplete}
            disabledText={
              user.first_name +
              " needs to complete the surveys to use this feature"
            }
          />
          <User user={user} />
          <Summary user={user} />
          <ExploreMore scrollTo="competencies" />
          <Element name="competencies">
            <Competencies user={user} />
          </Element>
          <Element name="personality">
            <Personality user={user} />
          </Element>
          <Element name="values">
            <Values user={user} />
          </Element>
        </div>
      </SidebarWrapper>
    );
  }
);
