import React from "react";
import Button from "components/UI/Button";
import { Link } from "react-router-dom";
import addMembersToTeamDim from "utils/addMembersToTeamDim";
import TopDimensions from "components/TopDimensions";
import UserAvatar from "components/UserAvatar";
import Wrapper from "components/UI/Wrapper";
import Summary from "./Summary";

import styles from "./User.module.scss";

export default ({ team, teamMember }) => {
  let nextId;
  let prevId;

  team.members
    .filter((m) => m.readyToRender)
    .forEach((member, key) => {
      if (member.id === teamMember.id) {
        const nextKey =
          team.members.filter((m) => m.readyToRender).length === key + 1
            ? 0
            : key + 1;
        const prevKey =
          key === 0
            ? team.members.filter((m) => m.readyToRender).length - 1
            : key - 1;

        nextId = team.members.filter((m) => m.readyToRender)[nextKey].id;
        prevId = team.members.filter((m) => m.readyToRender)[prevKey].id;
      }
    });

  const topDimensions = {
    competencies: !teamMember.competencies.some((c) => c.score === null)
      ? teamMember.competencies
          .filter((c) => c.dim_parent !== null)
          .sort((a, b) => b.score - a.score)
          .slice(0, 3)
      : null,
    personality:
      teamMember.inTeamStrengths.short.length > 0 &&
      !teamMember.personality.some((dim) => dim.score === null) &&
      teamMember.inTeamStrengths.short.slice(0, 3),
    values:
      teamMember.readyToRender &&
      teamMember.motivators.length > 0 &&
      teamMember.motivators.slice(0, 3),
  };

  const teamMembers = team.members;

  const dimensions = [
    ...team.personality.map((dim) => ({
      ...dim,
      members: addMembersToTeamDim({
        dim,
        members: teamMembers,
        dimGroup: "personality",
      }),
    })),
    ...team.competencies.map((dim) => ({
      ...dim,
      members: addMembersToTeamDim({
        dim,
        members: teamMembers,
        dimGroup: "competencies",
      }),
    })),
    ...team.values.map((dim) => ({
      ...dim,
      members: addMembersToTeamDim({
        dim,
        members: teamMembers,
        dimGroup: "values",
      }),
    })),
  ];

  return (
    <div className={styles.main}>
      <Wrapper>
        <div className={styles.initialWrapper}>
          {teamMember.id !== prevId && (
            <Link to={`/team/${team.id}/member/${prevId}`}>
              <Button bStyle={["arrowButton", "left"]} type="button">
                <i className="fas fa-chevron-left" />
              </Button>
            </Link>
          )}
          <div className={styles.avatarWrapper}>
            <UserAvatar member={teamMember} size="large" />
          </div>
          {teamMember.id !== nextId && (
            <Link to={`/team/${team.id}/member/${nextId}`}>
              <Button bStyle={["arrowButton", "right"]} type="button">
                <i className="fas fa-chevron-right" />
              </Button>
            </Link>
          )}
        </div>
        <h2 className={styles.name}>{teamMember.name}</h2>
        <TopDimensions
          topDimensions={topDimensions}
          dimensions={dimensions}
          highlight={teamMember}
          titles={{
            competencies: "Top competencies",
            personality: "Behavioural strengths",
            values: "Motivators",
          }}
          emptyStates={{
            competencies:
              "Waiting for user to complete the competencies survey",
            personality: "Waiting for user to complete the personality survey",
            values: "Waiting for user to complete the values survey",
          }}
        />
        <Summary team={team} teamMember={teamMember} />
      </Wrapper>
    </div>
  );
};
