import { compose, withProps, withState, withHandlers } from "recompose";
import { graphql } from "react-apollo";
import { loader } from "graphql.macro";
import handleLoading from "utils/handleLoading";
import deserialize from "utils/deserialize";
import serializer from "components/TextEditor/editorSerializer";

const PLAYERS_WITH_EFFECTIENESS_SCORES = loader(
  "./playWithEffectivenessScores2.graphql"
);

const CREATE_OR_UPDATE_TEAM_PLAY_CONCLUSION = loader(
  "./createOrUpdateTeamPlayConclusion2.graphql"
);

export default compose(
  withState("saved", "setSaved", false),
  graphql(PLAYERS_WITH_EFFECTIENESS_SCORES, {
    options: ({ play }) => ({
      variables: { play_id: play.id },
    }),
  }),
  graphql(CREATE_OR_UPDATE_TEAM_PLAY_CONCLUSION, {
    name: "createOrUpdateTeamPlayConclusion",
  }),
  handleLoading,
  withState("conclusion", "setConclusion", ({ data: { teamPlay: play } }) =>
    play.conclusion ? deserialize(play.conclusion.conclusion) : deserialize("")
  ),
  withHandlers({
    handleMutateConclusion:
      ({ play, createOrUpdateTeamPlayConclusion, setSaved }) =>
      (conclusion) => {
        createOrUpdateTeamPlayConclusion({
          variables: {
            play_id: play.id,
            conclusion: serializer.serialize(conclusion),
          },
        }).then(() => {
          setSaved(true);
          setTimeout(() => setSaved(false), 4000);
        });
      },
  })
);
