import React from 'react'
import classNames from 'classnames'
import Button from 'components/UI/Button'
import Container from 'components/UI/Container'
import compose from './compose'

import styles from '../SetGoals.module.scss'

export default compose(
  ({
    handleUpdate,
    handleRemove,
    handleCreate,
    index,
    card,
    setCardOpen,
    cardTitle,
    setCardTitle,
    cardDesc,
    setCardDesc,
    isNewCard,
    setNewCard,
    isOpen,
    newCard
  }) => (
    <div
      role='button'
      tabIndex={index}
      className={classNames(
        styles.containerWrapper,
        isOpen ? styles.open : styles.closed
      )}
      onKeyPress={() => !isOpen && !newCard && setCardOpen(card.order)}
      onClick={() => !isOpen && !newCard && setCardOpen(card.order)}
    >
      <Container size='large' cStyle={['noShadow', isOpen && 'active']}>
        <div className={styles.row}>
          <div className={styles.col}>
            <span className={styles.number}>{card.order + 1}</span>
          </div>
          <div className={styles.col}>
            <div className={styles.text}>
              <input
                className={styles.title}
                type='text'
                placeholder='Goal title'
                value={cardTitle}
                onChange={e => setCardTitle(e.target.value)}
                disabled={!isOpen}
              />
              {isOpen && (
                <textarea
                  className={styles.text}
                  rows='2'
                  type='text'
                  placeholder='Goal description'
                  value={cardDesc}
                  onChange={e => setCardDesc(e.target.value)}
                />
              )}
            </div>
          </div>
          <div className={styles.col} />
        </div>
        {isOpen && (
          <div className={styles.row}>
            <div className={styles.col} />
            <div className={styles.col}>
              <div className={styles.buttons}>
                <Button
                  bStyle={['newsimple']}
                  type='button'
                  disabled={!cardTitle}
                  onClick={isNewCard ? handleCreate : handleUpdate}
                >
                  Save
                </Button>
                <Button
                  bStyle={['newsimple']}
                  type='button'
                  onClick={() => {
                    if (isNewCard) {
                      setCardOpen(false)
                      setNewCard(null)
                    } else {
                      setCardTitle(card.title)
                      setCardDesc(card.description)
                      setCardOpen(false)
                    }
                  }}
                >
                  {card.id ? 'Cancel' : 'Discard'}
                </Button>
              </div>
            </div>
            <div className={styles.col}>
              {card.id && (
                <Button
                  bStyle={['newsimple', 'red']}
                  type='button'
                  onClick={handleRemove}
                >
                  Remove
                </Button>
              )}
            </div>
          </div>
        )}
      </Container>
    </div>
  )
)
