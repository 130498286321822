import { withState, withProps, compose, withHandlers } from "recompose";
import { graphql } from "react-apollo";
import { loader } from "graphql.macro";

const ADD_CANDIDATES = loader("./addCandidatesToPosition14.graphql");

export default compose(
  withState("overlayClose", "setOverlayClose", true),
  withState("isLoading", "setLoading", false),
  withState("modal", "updateModal", ""),
  graphql(ADD_CANDIDATES, {
    name: "addCandidatesToPosition",
  }),
  withHandlers({
    handleAddCandidatesToPosition:
      ({ addCandidatesToPosition, updateModal, position, setLoading }) =>
      (list) => {
        setLoading(true);
        const users = list.map((i) => {
          return {
            obj_id: i.id,
            type: i.type,
            email: i.email,
          };
        });

        addCandidatesToPosition({
          variables: {
            users,
            org_id: position.parent_org_id,
            position_id: position.id,
            team_id: position.parent_team_id,
          },
        }).then((res) => {
          updateModal("");
        });
      },
  }),
  withProps(
    ({
      position: {
        parent_org_id,
        parent_team_id,
        candidates,

        parentTeam,
        id,
      },
    }) => {
      const truncate = (string) => {
        if (string.length > 12) return `${string.substring(0, 12)}...`;
        return string;
      };

      const getLabel = (candidate) => {
        if (
          (candidate.contract && candidate.contract.state !== "active") ||
          candidate.__typename === "Invite"
        ) {
          return {
            style: "pending",
            text: "Pending invite",
          };
        }
        if (!candidate.readyToRender) {
          return {
            style: "incomplete",
            text: "Incomplete profile",
          };
        }
        return null;
      };

      const getOrder = (candidate) => {
        if (
          candidate.readyToRender &&
          candidate.contract &&
          candidate.contract.state === "active"
        )
          return 1;
        if (
          !candidate.readyToRender &&
          candidate.contract &&
          candidate.contract.state === "active"
        )
          return 2;
        if (candidate.__typename === "Invite") return 4;
        return 3;
      };

      const candidatesMapped = [...candidates].map((c) => {
        return {
          id: c.id,
          name:
            (c.name && truncate(c.name)) ||
            (c.invited_email && truncate(c.invited_email)),
          email: c.email || null,
          avatar_url: c.avatar_url || null,
          initials: c.initials || null,
          description:
            (c.description &&
              c.description.short !== "false" &&
              c.description.short) ||
            null,
          isInvite:
            (c.contract && c.contract.state !== "active") ||
            c.__typename === "Invite",
          label: getLabel(c),
          link:
            c.readyToRender &&
            c.contract &&
            c.contract.state === "active" &&
            parentTeam.members.some((m) => m.readyToRender)
              ? `/${parent_org_id}/team/${parent_team_id}/position/${id}/candidate/${c.id}`
              : null,
          order: getOrder(c),
        };
      });
      return { candidates: candidatesMapped.sort((a, b) => a.order - b.order) };
    }
  )
);
