import React, { useEffect } from "react";
import classNames from "classnames";
import Popup from "reactjs-popup";
import Icon from "components/UI/Icon";
import Button from "components/UI/Button";
import CustomSelect from "components/UI/CustomSelect";
import SidebarWrapper from "components/UI/SidebarWrapper";
import SidebarHeader from "components/UI/SidebarHeader";
import Wrapper from "components/UI/Wrapper";
import { useSidebar } from "utils/sidebarProvider";
import ChooseTemplate from "./ChooseTemplate";
import CreateTemplate from "./CreateTemplatePopup";
import compose from "./compose";

import styles from "./CreatePosition.module.scss";

export default compose(
  ({
    viewer,
    account,
    selectedOrg,
    setStep,
    currentTab,
    setCurrentTab,
    modal,
    updateModal,
    selectedTemplate,
    setSelectedTemplate,
    selectedCategory,
    setSelectedCategory,
    setTemplatesState,
    categories,
    setCategories,
    searchTerm,
    setSearchTerm,
    stages,
    setStages,
    selectedTags,
    setSelectedTags,
    templateSecret,
    view,
    setView,
  }) => {
    const { contentContext } = useSidebar();
    const [content, setContent] = contentContext;

    const sidebarContent = [];

    if (categories.length > 0) {
      sidebarContent.push({
        sectionTitle: "Filter by category",
        items: categories,
      });
    }

    useEffect(() => {
      setContent(sidebarContent);
    }, [currentTab, categories]);

    const handleTagsFilter = (state) => {};

    return (
      <SidebarWrapper>
        <SidebarHeader title="Hiring scorecards">
          <Popup
            keepTooltipInside="body"
            trigger={
              <div className={styles.createTemplateButton}>
                <Button
                  type="button"
                  bStyle={["primary", "verySmall"]}
                  disabled={!account.adminRights}
                >
                  <Icon icon="Plus" marginRight />
                  Create a new scorecard
                </Button>
                {!account.adminRights && (
                  <span className={styles.tooltip}>
                    <p>Only workspace admins can create new scorecards</p>
                  </span>
                )}
              </div>
            }
            closeOnDocumentClick
            closeOnEscape
            position="top left"
            nested
          >
            {(close) => (
              <div className={styles.createTemplateWrapper}>
                <CreateTemplate selectedOrg={selectedOrg} close={close} />
              </div>
            )}
          </Popup>
        </SidebarHeader>
        <div className={styles.wrapper}>
          <Wrapper left>
            <div className={styles.head}>
              <div className={styles.top}>
                <h2 className={styles.title}>
                  <div className={styles.icon}>
                    <Icon icon="Edit" />
                  </div>
                  Hiring scorecards
                </h2>
                <h4 className={styles.subtitle}>
                  All scorecards include pre-filled key objectives, position
                  requirements, and competencies needed to be successful.
                </h4>
              </div>
              <div className={styles.bottom}>
                <div className={styles.left}>
                  <div className={styles.tabs}>
                    <div
                      className={classNames(
                        styles.tab,
                        currentTab === 0 && styles.selected
                      )}
                    >
                      <Button
                        bStyle={["none"]}
                        onClick={() => {
                          setCurrentTab(0);
                          setSelectedCategory(null);
                          setSelectedTags([]);
                        }}
                      >
                        Featured scorecards
                      </Button>
                    </div>

                    {selectedOrg.adminRights && (
                      <div
                        className={classNames(
                          styles.tab,
                          currentTab === 1 && styles.selected
                        )}
                      >
                        <Button
                          bStyle={["none"]}
                          onClick={() => {
                            setCurrentTab(1);
                            setSelectedCategory(null);
                            setSelectedTags([]);
                          }}
                        >
                          Workspace scorecards
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
                <div className={styles.right}>
                  <div className={styles.switchView}>
                    <Button bStyle={["none"]} onClick={() => setView("cards")}>
                      <div
                        className={classNames(
                          styles.icon,
                          styles.cards,
                          view === "cards" && styles.active
                        )}
                      >
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </Button>
                    <Button bStyle={["none"]} onClick={() => setView("list")}>
                      <div
                        className={classNames(
                          styles.icon,
                          styles.list,
                          view === "list" && styles.active
                        )}
                      >
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </Button>
                  </div>
                  <div className={styles.tagSelect}>
                    <CustomSelect
                      cStyle="verySmall"
                      icon="fas fa-tags"
                      customFunction={handleTagsFilter}
                      placeholder="Filter by tags"
                      options={stages}
                      selectedEvaluators={selectedTags}
                      setSelectedEvaluators={setSelectedTags}
                      disabled={stages.length === 0}
                      noAvatar={true}
                    />
                  </div>
                  <div className={styles.search}>
                    <div className={styles.searchIcon}>
                      <Icon icon="Search" />
                    </div>
                    <input
                      type="text"
                      placeholder="Search"
                      onChange={(e) => setSearchTerm(e.target.value)}
                    ></input>
                  </div>
                </div>
              </div>
            </div>
            <ChooseTemplate
              modal={modal}
              updateModal={updateModal}
              templateSecret={templateSecret}
              setStep={setStep}
              setSelectedTemplate={setSelectedTemplate}
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
              selectedTemplate={selectedTemplate}
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
              setTemplatesState={setTemplatesState}
              categories={categories}
              setCategories={setCategories}
              selectedOrg={selectedOrg}
              searchTerm={searchTerm}
              setStages={setStages}
              selectedTags={selectedTags}
              view={view}
            />
          </Wrapper>
        </div>
      </SidebarWrapper>
    );
  }
);
