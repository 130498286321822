import React from "react";
import classNames from "classnames";
import Answer from "../Answer";

import styles from "./CurrentQuestion.module.scss";

export default ({ survey, currentRowId, handleSetAnswer, answers }) => (
  <div className={styles.wrapper} key={"rowId-" + currentRowId}>
    <h3 className={styles.mainText}>
      <span>“</span>
      {survey.survey_rows[currentRowId].dimension.name}
      <span>”</span>
    </h3>
    <Answer
      handleSetAnswer={handleSetAnswer}
      answers={answers}
      currentRowId={currentRowId}
    />
    <div className={styles.underTexts}>
      <h3 className={classNames(styles.answer, currentRowId % 2 && styles.fix)}>
        {survey.survey_rows[currentRowId].dimension.question_left}
      </h3>
      <h3 className={classNames(styles.answer, currentRowId % 2 && styles.fix)}>
        {survey.survey_rows[currentRowId].dimension.question_right}
      </h3>
    </div>
  </div>
);
