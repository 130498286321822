export default (organization) => {
  if (!organization.name) {
    return 'blue'
  }

  const colors = {
    0: 'blue',
    1: 'green',
    2: 'purple',
    3: 'yellow',
    4: 'red'
  }
  return colors[
    Array.from(organization.name.charCodeAt(0).toString()).map(Number)[0] % 5
  ]
}
