import React from "react";
import classNames from "classnames";
import PersonalityChart from "components/Charts/Personality";
import Curve from "components/Charts/Personality/curve";
import Distribution from "components/Charts/Personality/Distribution";
import PersonalityPlaceholder from "components/Placeholders/PersonalityPlaceholder";

import styles from "./index.module.scss";

export default ({ team }) => {
  if (
    team.readyToRender &&
    team.members.some((m) => m.readyToRender) &&
    team.members.length > 0
  ) {
    return (
      <div className={classNames(styles.wrap, "personalityChart")}>
        <PersonalityChart
          big5={team.personality.filter((dim) => dim.groupId === "team_big5")}
          team={team}
        />
        <Distribution
          loadedMembers={team.members.filter(
            (m) => m.personalityChart.readyToRender
          )}
        >
          {team.members
            .filter((m) => m.personalityChart.readyToRender)
            .map((member) => (
              <Curve
                key={member.id}
                big5={member.personality.filter(
                  (dim) => dim.groupId === "user_big5"
                )}
                isTeamMember
              />
            ))}
        </Distribution>
      </div>
    );
  }
  return <PersonalityPlaceholder resource="team" />;
};
