import React, { useEffect } from "react";
import classNames from "classnames";
import AIChat from "components/AIChat";
import sortMemberCompetencies from "utils/sortMemberCompetencies";
import sortTeamCompetencies from "utils/sortTeamCompetencies";
import SidebarWrapper from "components/UI/SidebarWrapper";
import SidebarHeader from "components/UI/SidebarHeader";
import Button from "components/UI/Button";
import Icon from "components/UI/Icon";
import { Link } from "react-router-dom";
import { Element } from "react-scroll";
import ExploreMore from "components/UI/ExploreMore";
import { printoutRoute } from "client/authRoutes";
import { useSidebar } from "utils/sidebarProvider";
import compose from "./compose";
import User from "./User";
import Competencies from "./Competencies";
import Personality from "./Personality";
import Values from "./Values";
import OtherMembersInTeam from "./OtherMembersInTeam";

import styles from "./MemberInTeam.module.scss";

export default compose(({ data: { team }, teamMember, match, history }) => {
  const { contentContext } = useSidebar();
  const [content, setContent] = contentContext;

  const sidebarContent = [
    {
      sectionTitle: "Navigation",
      items: [
        {
          title: "Competencies",
          scrollTo: true,
        },
        {
          title: "Personality",
          scrollTo: true,
        },
        {
          title: "Values",
          scrollTo: true,
        },
      ],
    },
  ];

  useEffect(() => {
    setContent(sidebarContent);
  }, []);

  return (
    <SidebarWrapper>
      <SidebarHeader title={teamMember.name} subtitle={team.name}>
        <Button
          bStyle={["primary", "verySmall"]}
          onClick={() => history.push(`/team/${team.id}`, "_self")}
        >
          Back to team
        </Button>
        {team.members.length > 0 && team.members.some((m) => m.readyToRender) && (
          <Button
            type="button"
            bStyle={["verySmall"]}
            onClick={() =>
              window.open(
                `${printoutRoute}/printout/member/${team.id}/${
                  teamMember.id
                }/${teamMember.name.replace(" ", "_")}_team_member_profile.pdf`,
                "_blank"
              )
            }
          >
            <Icon icon="Print" marginRight />
            Print
          </Button>
        )}
      </SidebarHeader>
      <div className={classNames(styles.main, "memberInTeam")}>
        <div className={styles.wrapper}>
          <AIChat
            sequence={"team_member_profile_chat_start"}
            contexts={["user", "team"]}
            description="An AI assistant trained to help you make sense of the Team Member's Profile and give actionable advice."
            variables={[
              { key: "team_id", value: team.id },
              { key: "user_id", value: teamMember.id },
            ]}
            examplePrompts={[
              `What’s the one thing I should avoid to work effectively with ${teamMember.first_name}?`,
              `How can ${teamMember.first_name} support this team most effectively?`,
              `What conflicts might arise between ${teamMember.first_name} and the team?`,
              `What’s the one skill ${teamMember.first_name} could learn from the team?`,
              `Which Winnie the Pooh character would ${teamMember.first_name} be?`,
            ]}
            heapEvent="create-team-member-chatbot-prompt"
            isReady={teamMember.readyToRender}
            disabledText="The team member has to complete their surveys to use this feature."
          />
          <div className={styles.teamName}>
            <h4>
              Member in
              <Link to={`/team/${team.id}`}>{team.name}</Link>
            </h4>
          </div>
          <User team={team} teamMember={teamMember} />
          <div className={styles.exploreWrapper}>
            <ExploreMore scrollTo="competencies" />
          </div>
          <Element name="competencies">
            <Competencies
              team={team}
              teamMember={teamMember}
              competencies={sortMemberCompetencies(team, teamMember)}
              teamCompetencies={sortTeamCompetencies(team)}
            />
          </Element>
          <Element name="personality">
            <Personality team={team} teamMember={teamMember} />
          </Element>
          <Element name="values">
            <Values team={team} teamMember={teamMember} />
          </Element>
          <OtherMembersInTeam team={team} teamMember={teamMember} />
          <Link className={styles.bigButton} to={`/team/${team.id}`}>
            <Button bStyle={["primary", "large"]} type="button">
              <i className="fas fa-chevron-left" />
              Back to team profile
            </Button>
          </Link>
        </div>
      </div>
    </SidebarWrapper>
  );
});
