import { compose, withProps, withState } from 'recompose'

import { ReactComponent as ExpertIcon } from 'images/icons/competencies/colored-must.svg'
import { ReactComponent as GoodIcon } from 'images/icons/competencies/colored-important.svg'
import { ReactComponent as OkayIcon } from 'images/icons/competencies/colored-good.svg'
import scoreGroups from 'utils/compeScoreGroups'

export default compose(
  withState('showModal', 'setShowModal', ''),
  withProps(
    ({
      user: {
        personalProfile: { competencies, competencyGraph }
      }
    }) => {
      const compes = {
        expert: competencies.filter(
          (comp) => scoreGroups[comp.score] === 'expert'
        ),
        good: competencies.filter(
          (comp) => scoreGroups[comp.score] === 'strong'
        ),
        okay: competencies.filter(
          (comp) => scoreGroups[comp.score] === 'good'
        )
      }

      const headers = {
        expert: {
          icon: ExpertIcon,
          title: 'You are great at'
        },
        good: {
          icon: GoodIcon,
          title: 'You are strong at'
        },
        okay: {
          icon: OkayIcon,
          title: 'You are good at'
        }
      }
      return {
        competencies: compes,
        competencyGraph,
        headers
      }
    }
  )
)
