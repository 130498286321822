export default ({ dim, team, teamMember, resource }) => {
  const members = team.members.filter(
    (member) => member.readyToRender && member.id !== teamMember.id
  );

  const memberScores = members
    .map(
      (member) => member[resource].find((d) => d.dim_id === dim.dim_id).score
    )
    .filter(Boolean);
  const userScore = teamMember[resource].find(
    (d) => d.dim_id === dim.dim_id
  ).score;
  const scoreDiffs = memberScores.map((s) => Math.abs(s - userScore));
  const alignmentScore =
    members.length !== 0 &&
    scoreDiffs.reduce((a, b) => a + b, 0) / memberScores.length;

  if (resource === "values") {
    if (!alignmentScore) {
      return {
        label: "",
        className: "",
        colour: "grey",
        score: alignmentScore,
      };
    }
    if (alignmentScore <= 15) {
      return {
        label: "Super aligned",
        className: "superAligned",
        colour: "green",
        score: alignmentScore,
      };
    }
    if (alignmentScore > 15 && alignmentScore <= 35) {
      return {
        label: "Well aligned",
        className: "wellAligned",
        colour: "grey",
        score: alignmentScore,
      };
    }
    return {
      label: "May disagree",
      className: "disagree",
      colour: "yellow",
      score: alignmentScore,
    };
  }

  if (resource === "personality") {
    if (!alignmentScore) {
      return {
        label: "",
        className: "",
        colour: "grey",
        score: alignmentScore,
      };
    }
    if (alignmentScore <= 10) {
      return {
        label: "Very similar",
        className: "verySimilar",
        colour: "red",
        score: alignmentScore,
      };
    }
    if (alignmentScore > 10 && alignmentScore <= 25) {
      return {
        label: "Similar",
        className: "similar",
        colour: "yellow",
        score: alignmentScore,
      };
    }
    if (alignmentScore > 20 && alignmentScore <= 50) {
      return {
        label: "Diverse",
        className: "diverse",
        colour: "grey",
        score: alignmentScore,
      };
    }
    return {
      label: "Very diverse",
      className: "veryDiverse",
      colour: "green",
      score: alignmentScore,
    };
  }
  return null;
};
