import React from "react"
import Button from "components/UI/Button"
import { INVITE } from "store/actionTypes"
import styles from "./Notifications.module.scss"

export default ({ invites, dispatch }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <p className={styles.notifications}>Notifications</p>
        {invites.length > 0 && (
          <p className={styles.number}>{`${invites.length} new`}</p>
        )}
      </div>
      <div className={styles.notificationsWrapper}>
        {invites.length > 0 ? (
          invites.map((invite) => (
            <Button
              bStyle={["none"]}
              type="button"
              onClick={() => dispatch({ type: INVITE, payload: invite })}
              key={invite.secret}
            >
              <div className={styles.invite}>
                <i className="fas fa-building" />
                <div className={styles.right}>
                  <p className={styles.text}>
                    You have been invited to the organisation
                    {` ${invite.organizations[0].name}. `}
                  </p>
                </div>
              </div>
            </Button>
          ))
        ) : (
          <p className={styles.none}>No notifications.</p>
        )}
      </div>
    </div>
  )
}
