export default ({ cache, proxy, survey }) => {
  const viewerKey = Object.keys(cache.data).find(key => key.includes('Viewer'))

  if (viewerKey === undefined) return null
  const viewerId = cache.data[viewerKey].id

  const positions = Object.keys(cache.data).filter(
    key => key.match(/^PositionType:([0-9]+)$/) !== null
  )
  const teams = Object.keys(cache.data).filter(
    key => key.match(/^TeamType:([0-9]+)$/) !== null
  )

  positions.forEach((position) => {
    const cachedPosition = cache.data[position]
    const condition = Object.keys(cachedPosition).includes('candidates')
      && cachedPosition.candidates.some(
        candidate => candidate.id === `UserType:${viewerId}`
      )
    if (condition) {
      const allPosKeys = Object.keys(cache.data).filter(key => key.includes(position))
      allPosKeys.forEach(key => delete cache.data[key])
      delete cache.data[`UserType:${viewerId}`]
    }
  })

  teams.forEach((team) => {
    const cachedTeam = cache.data[team]
    const condition = Object.keys(cachedTeam).includes('members')
      && cachedTeam.members.some(members => members.id === `UserType:${viewerId}`)
    if (condition) {
      const allTeamKeys = Object.keys(cache.data).filter(key => key.includes(team))
      allTeamKeys.forEach(key => delete cache.data[key])
      delete cache.data.ROOT_QUERY.viewerTeams
      delete cache.data[`UserType:${viewerId}`]
    }
  })

  const personalProfileKeys = Object.keys(cache.data).filter(key => key.includes('personalProfile'))
  personalProfileKeys.forEach((key) => {
    delete cache.data[key]
  })
  delete cache.data['$ROOT_QUERY.personalProfile']
  delete cache.data.ROOT_QUERY.personalProfile

  const surveyKeys = Object.keys(cache.data).filter(key => key.includes(`${survey._typename}:${survey.id}`))
  surveyKeys.forEach((key) => {
    delete cache.data[key]
  })

  delete cache.data.ROOT_QUERY.personalProfile

  proxy.restore(cache.data)

  return true
}
