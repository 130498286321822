import React, { useState } from "react";
import NewComment from "./NewComment";
import Comment from "./Comment";

import styles from "./Comments.module.scss";

export default ({
  comment,
  setComment,
  handleCreateComment,
  handleUpdateComment,
  handleRemoveComment,
  editNote,
  setEditNote,
  comments,
  viewer,
}) => {
  return (
    <div className={styles.wrapper}>
      <NewComment
        handleCreateComment={handleCreateComment}
        comment={comment}
        setComment={setComment}
      />
      <div className={styles.notes}>
        {[...comments].reverse().map((comment) => {
          return (
            <Comment
              key={"Comment-" + comment.id}
              note={comment}
              viewer={viewer}
              editNote={editNote}
              setEditNote={setEditNote}
              handleUpdateCandidateNote={handleUpdateComment}
              handleDeleteCandidateNote={handleRemoveComment}
            />
          );
        })}
      </div>
    </div>
  );
};
