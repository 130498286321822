import React, { useState } from "react";
import classNames from "classnames";
import Wrapper from "components/UI/Wrapper";
import ValuesModal from "components/Modals/Value";
import PersonalWorkBest from "./PersonalWorkBest";
import PersonalMightStruggle from "./PersonalMightStruggle";
import TeamWorkBest from "./TeamWorkBest";
import TeamMightStruggle from "./TeamMightStruggle";
import MemberWorkBest from "./MemberWorkBest";
import MemberMightStruggle from "./MemberMightStruggle";
import ComparisonWorkBest from "./ComparisonWorkBest";
import ComparisonMightStruggle from "./ComparisonMightStruggle";

import styles from "./TopValues.module.scss";

export default ({ data, user, resource, isCandidate, member, onlyBest }) => {
  const [openModal, updateModal] = useState({});

  const personalWorkBest = data.values
    .filter((dim) => dim.parent_id)
    .sort((a, b) => b.score - a.score)
    .slice(0, 3);

  const personalMightStruggle = data.values
    .filter((dim) => dim.parent_id)
    .sort((a, b) => a.score - b.score)
    .slice(0, 3);

  const teamWorkBest = data.values
    .filter((dim) => dim.parent_id)
    .sort((a, b) => b.score - a.score)
    .slice(0, 3);

  const teamMightStruggle = data.values
    .filter((dim) => dim.parent_id)
    .sort((a, b) => a.score - b.score)
    .slice(0, 3);

  const memberWorkBest =
    user &&
    user.values
      .filter((dim) => dim.parent_id)
      .sort((a, b) => b.score - a.score)
      .slice(0, 3);

  const memberMightStruggle =
    user &&
    user.values
      .filter((dim) => dim.parent_id)
      .sort((a, b) => a.score - b.score)
      .slice(0, 3);

  const candidateWorkBest =
    user &&
    user.values
      .filter((dim) => dim.parent_id)
      .sort((a, b) => b.score - a.score)
      .slice(0, 3);

  const candidateMightStruggle =
    user &&
    user.values
      .filter((dim) => dim.parent_id)
      .sort((a, b) => a.score - b.score)
      .slice(0, 3);

  const getAlignmentStyle = (alignment) => {
    if (alignment === "May disagree") return styles.disagree;
    if (alignment === "Well aligned") return styles.wellAligned;
    if (alignment === "Super aligned") return styles.superAligned;
  };

  const getAlignmentColor = (alignment) => {
    if (alignment === "May disagree") return "yellow";
    if (alignment === "Well aligned") return "grey";
    if (alignment === "Super aligned") return "green";
  };

  const getValues = () => {
    if (resource === "team") {
      return [...teamWorkBest, ...teamMightStruggle];
    }
    if (resource === "comparison") {
      return [...teamWorkBest, ...teamMightStruggle];
    }
    if (resource === "personal" || resource === "orgUser") {
      return [...personalWorkBest, ...personalMightStruggle];
    }
    if (resource === "member") {
      return [...memberWorkBest, ...memberMightStruggle].map((d1) => {
        const teamDim = data.values.find((d2) => d2.name === d1.name);

        return Object.assign({}, d1, {
          valuesStrength: teamDim.valuesStrength,
          valuesDerailer: teamDim.valuesDerailer,
          keywords: teamDim.keywords,
          science: teamDim.science,
          score: teamDim.score,
        });
      });
    }
  };

  return (
    <Wrapper>
      <div className={classNames(styles.bigWrapper, styles.motivators)}>
        <h3
          className={classNames(
            styles.TopTitle,
            resource === "team" && "topMotivatorsTitle"
          )}
        >
          <i className="far fa-compass" />
          {resource === "team" && "Top motivators of the team"}
          {(resource === "personal" || resource === "orgUser") &&
            "Your top motivators"}
          {resource === "member" &&
            `${user && user.first_name}'s top motivators`}
        </h3>
        <h4 className={styles.subtitle}>
          {resource === "team" &&
            "The most important values that shape the team's judgments, goals, and culture."}
          {(resource === "personal" || resource === "orgUser") &&
            "You are driven by and tend to act according to your most important values."}
          {resource === "member" &&
            "Most motivating and would thrive when aligned with the team."}
          {resource === "comparison" &&
            `The most important values that shape ${data.name}' judgments, goals, and culture.`}
        </h4>
        <div className={styles.arrowLine} />
        {resource === "comparison" && (
          <ComparisonWorkBest
            teamWorkBest={teamWorkBest}
            data={data}
            comparison={user}
            openModal={openModal}
            updateModal={updateModal}
            getAlignmentColor={getAlignmentColor}
            getAlignmentStyle={getAlignmentStyle}
          />
        )}
        {resource === "team" && (
          <TeamWorkBest
            teamWorkBest={teamWorkBest}
            data={data}
            openModal={openModal}
            updateModal={updateModal}
            getAlignmentColor={getAlignmentColor}
            getAlignmentStyle={getAlignmentStyle}
          />
        )}
        {(resource === "personal" || resource === "orgUser") && (
          <PersonalWorkBest
            personalWorkBest={personalWorkBest}
            data={data}
            openModal={openModal}
            updateModal={updateModal}
            resource={resource}
            user={member}
          />
        )}
        {resource === "member" && (
          <MemberWorkBest
            memberWorkBest={isCandidate ? candidateWorkBest : memberWorkBest}
            data={data}
            teamMember={user}
            isCandidate={isCandidate}
            candidate={user}
            openModal={openModal}
            updateModal={updateModal}
          />
        )}
      </div>

      {!onlyBest && (
        <div className={classNames(styles.bigWrapper, styles.derailers)}>
          <h3 className={styles.TopTitle}>
            <i className="far fa-compass" />
            {resource === "team" && "Lowest priority to the team"}
            {(resource === "personal" || resource === "orgUser") &&
              "Lowest priority"}
            {resource === "member" &&
              `Lowest priority to ${user && user.first_name}`}
          </h3>
          <h4 className={styles.subtitle}>
            {resource === "team" &&
              "The values that the team considers unimportant or might oppose."}
            {(resource === "personal" || resource === "orgUser") &&
              "The values that you consider unimportant or might oppose."}
            {resource === "member" &&
              "Might want to avoid or even oppose when not aligned with the team."}
            {resource === "comparison" &&
              `The values that ${data.name} consider unimportant or might oppose.`}
          </h4>
          <div className={styles.arrowLine} />

          {resource === "comparison" && (
            <ComparisonMightStruggle
              teamMightStruggle={teamMightStruggle}
              data={data}
              comparison={user}
              openModal={openModal}
              updateModal={updateModal}
            />
          )}

          {resource === "team" && (
            <TeamMightStruggle
              teamMightStruggle={teamMightStruggle}
              data={data}
              openModal={openModal}
              updateModal={updateModal}
              getAlignmentColor={getAlignmentColor}
              getAlignmentStyle={getAlignmentStyle}
            />
          )}
          {(resource === "personal" || resource === "orgUser") && (
            <PersonalMightStruggle
              personalMightStruggle={personalMightStruggle}
              data={data}
              openModal={openModal}
              updateModal={updateModal}
              resource={resource}
              member={member}
            />
          )}
          {resource === "member" && (
            <MemberMightStruggle
              memberMightStruggle={
                isCandidate ? candidateMightStruggle : memberMightStruggle
              }
              data={data}
              teamMember={user}
              isCandidate={isCandidate}
              candidate={user}
              openModal={openModal}
              updateModal={updateModal}
            />
          )}
        </div>
      )}

      <ValuesModal
        values={getValues()}
        valueGroups={data.values
          .filter((v) => !v.parent_id)
          .map((g) =>
            Object.assign({}, g, {
              groupId: g.parentGroup,
            })
          )}
        resource={resource}
        highlight={user}
        teamMember={user}
        candidate={user}
        isCandidate={isCandidate}
        dim={openModal}
        members={data && data.members}
        openModal={openModal}
        updateModal={updateModal}
        team={data}
        member={member}
        isImportant={data.values
          .filter((dim) => dim.parent_id)
          .sort((a, b) => b.score - a.score)
          .slice(0, 3)
          .find((d) => d.name === openModal.name)}
      />
    </Wrapper>
  );
};
