import React from "react"
import { withRouter } from "react-router-dom"
import { graphql } from "react-apollo"
import { compose } from "recompose"
import handleLoading from "utils/handleLoading"
import { loader } from "graphql.macro"

const PROFILE_COMPARISON = loader("./Queries/ProfileComparison2.graphql")

export default compose(
  withRouter,
  graphql(PROFILE_COMPARISON, {
    options: (props) => {
      return {
        variables: {
          profileSecret: props.match.params.profileSecret,
        },
      }
    },
  }),
  handleLoading
)(({ data: { profileComparison } }) => {
  const truncate = (string) => {
    if (string.length > 14) return `${string.substring(0, 14)}...`
    return string
  }

  return (
    <React.Fragment>{profileComparison.comparisonPosition.name}</React.Fragment>
  )
})
