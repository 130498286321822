import { loader } from "graphql.macro";

const SURVEYS = loader("./Survey/surveys3.graphql");

export default ({ text, user, surveys }) => {
  const templates = [
    {
      find: "{{first_name}}",
      replace:
        user.first_name.charAt(0).toUpperCase() + user.first_name.slice(1)
    },
    {
      find: "{{surveys_complete_check_and_message}}",
      replace: surveys.every(survey => survey.complete)
        ? "You have finished all the surveys. Click continue to see your Personal Profile!"
        : "Your profile is incomplete! You can finish setting it up at a later time."
    }
  ];

  return text
    .split(" ")
    .map(word => {
      const temp = templates.find(temp => word.indexOf(temp.find) !== -1);
      if (temp) return word.replace(temp.find, temp.replace);
      return word;
    })
    .join(" ");
};
