import { compose, withHandlers } from 'recompose'
import { withRouter } from 'react-router-dom'
import withStore from 'utils/withStore'
import { TOGGLE_EDITING_COMPETENCIES } from 'store/actionTypes'

export default compose(
  withRouter,
  withStore('newposition'),
  withHandlers({
    toggleEditCompes: ({ dispatch }) => () => {
      dispatch({ type: TOGGLE_EDITING_COMPETENCIES })
    }
  })
)
