import queryString from 'query-string'
import { INVITE, CLOSE_INVITE } from 'store/actionTypes'

const initialState = {
  isOpen: false,
  invite: null
}

export default (state = initialState, action) => {
  const { invite } = queryString.parse(window.location.search)
  switch (action.type) {
    case INVITE:
      return Object.assign({}, initialState, {
        isOpen: true,
        invite: action.payload
      })
    case CLOSE_INVITE:
      if (invite) {
        window.history.pushState(
          {},
          '',
          `${window.location.origin}${window.location.pathname}`
        )
      }
      return initialState
    default:
      return state
  }
}
