import { compose, withHandlers } from "recompose";

import { withRouter } from "react-router-dom";

export default compose(
  withRouter,
  withHandlers({
    handleCreateCandidateNote:
      ({
        match,
        candidate,
        comment,
        createCandidateNote,
        setComment,
        setNewNote,
      }) =>
      async (e) => {
        e.preventDefault();
        if (comment && comment.length > 0) {
          const type = match.params.candidateType;
          await createCandidateNote({
            variables: {
              cand_id: type === "candidate" ? candidate.id : null,
              person_id: type === "person" ? candidate.id : null,
              position_id: candidate.position.id,
              text: comment,
            },
          }).then(() => {
            setComment("");
            setNewNote(false);
          });
        }
      },
  })
);
