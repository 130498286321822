import React from 'react'
import { Link } from 'react-router-dom'

export default ({ link, children }) => {
  if (link) {
    return (
      <Link onClick={() => window.scrollTo(0, 0)} to={link}>
        {children}
      </Link>
    )
  }
  return children
}
