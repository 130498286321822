import { withHandlers } from "recompose";

export default withHandlers({
  handleChangeName:
    ({ updateOrg, data: { organization } }) =>
    (e) => {
      e.preventDefault();
      const name = e.target.name.value;

      updateOrg({
        variables: {
          name,
          org_id: organization.id,
        },
      }).then(() => {});
    },
});
