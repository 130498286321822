import React from 'react'
import { Link } from 'react-router-dom'
import Modal from 'react-modal'
import classNames from 'classnames'
import Icon from 'components/UI/Icon'
import UserAvatar from 'components/UserAvatar'
import InvitePeople from 'components/Modals/InvitePeopleToOrg'
import RemoveDemoDataModal from 'components/Modals/RemoveDemoDataModal'
import Button from 'components/UI/Button'
import RenderMemberModal from 'components/RenderMemberModal'
import compose from './compose'
import styles from './Members.module.scss'

export default compose(
  ({
    data: { organization },
    selectedOrg,
    inviteModal,
    updateInviteModal,
    memberModal,
    updateMemberModal,
    overlayClose,
    setOverlayClose,
    removeDataModal,
    updateRemoveDataModal
  }) => {
    if (selectedOrg.id && organization && organization.talentPool) {
      return (
        <div className={styles.showMembers}>
          {selectedOrg.id && organization && (
            <div className={styles.membersList}>
              {organization.talentPool.length > 8 ? (
                <div className={styles.members}>
                  {organization.talentPool
                    .slice(0, 8)
                    .map((member) => (
                      <Button
                        key={member.id}
                        bStyle={['none']}
                        type="button"
                        onClick={() => updateMemberModal(member)}
                      >
                        <div className={styles.member}>
                          <div className={styles.avatar}>
                            <UserAvatar
                              member={member}
                              size="mediumSmall"
                              isInvite={!member.name}
                              pointer
                            />
                          </div>
                          <h5>
                            {member.name
                              ? member.name.split(' ')[0]
                              : member.email}
                          </h5>
                        </div>
                      </Button>
                    ))}
                  <Link to={`/org/${organization.id}/members`}>
                    <Button bStyle={['none']} type="button">
                      <div
                        className={classNames(
                          styles.member,
                          styles.more
                        )}
                      >
                        <div className={styles.avatarLapped}>
                          <div className={styles.avatar}>
                            <UserAvatar
                              member={organization.talentPool[8]}
                              size="mediumSmall"
                              isInvite={
                                !organization.talentPool[8].name
                              }
                            />
                          </div>
                          <div className={styles.moreAvatar}>
                            <p>
                              <i className="far fa-plus" />
                              {organization.usersInTalentPool - 7}
                            </p>
                          </div>
                        </div>
                        <h5>View all</h5>
                      </div>
                    </Button>
                  </Link>
                </div>
              ) : (
                <>
                  <div className={styles.members}>
                    {organization.talentPool
                      .slice(0, 8)
                      .map((member) => (
                        <Button
                          key={member.id}
                          bStyle={['none']}
                          type="button"
                          onClick={() => updateMemberModal(member)}
                        >
                          <div className={styles.member}>
                            <div className={styles.avatar}>
                              <UserAvatar
                                member={member}
                                size="mediumSmall"
                                isInvite={!member.name}
                              />
                            </div>
                            <h5>
                              {member.name
                                ? member.name.split(' ')[0]
                                : member.email}
                            </h5>
                          </div>
                        </Button>
                      ))}
                    <Link to={`/org/${organization.id}/members`}>
                      <Button bStyle={['none']} type="button">
                        <div
                          className={classNames(
                            styles.member,
                            styles.more
                          )}
                        >
                          <div className={styles.icon}>
                            <Icon icon="User" />
                          </div>
                          <h5>View all</h5>
                        </div>
                      </Button>
                    </Link>
                  </div>
                </>
              )}
            </div>
          )}
          <Modal
            isOpen={inviteModal && true}
            onRequestClose={() => updateInviteModal(false)}
            shouldCloseOnOverlayClick={overlayClose}
            shouldCloseOnEsc={overlayClose}
            className={styles.modal}
          >
            <InvitePeople
              organization={organization}
              updateModal={updateInviteModal}
              setOverlayClose={setOverlayClose}
            />
          </Modal>
          <Modal
            isOpen={memberModal !== ''}
            onRequestClose={() => updateMemberModal('')}
            shouldCloseOnOverlayClick
            className={styles.modal}
          >
            {memberModal !== '' && (
              <RenderMemberModal
                updateMemberModal={updateMemberModal}
                member={memberModal}
                organization={organization}
              />
            )}
          </Modal>
          {selectedOrg.hasDemoData && (
            <Modal
              isOpen={removeDataModal && true}
              onRequestClose={() => updateRemoveDataModal(false)}
              shouldCloseOnOverlayClick
              className={styles.modal}
            >
              <RemoveDemoDataModal
                organization={organization}
                updateRemoveDataModal={updateRemoveDataModal}
              />
            </Modal>
          )}
        </div>
      )
    }
    return null
  }
)
