import React, { useState, useEffect } from "react";
import classNames from "classnames";
import Icon from "components/UI/Icon";
import Button from "components/UI/Button";
import UserAvatar from "components/UserAvatar";
import PercentageCircle from "components/UI/PercentageCircle";
import TextEditor from "components/TextEditor";
import deserialize from "utils/deserialize";

import compose from "./compose";

import styles from "./TeamEffectivenessFinish.module.scss";

export default compose(
  ({
    data: { teamPlay: play },
    conclusion,
    setConclusion,
    handleMutateConclusion,
    saved,
    setSaved,
  }) => {
    useEffect(() => {
      let timer = setTimeout(() => handleMutateConclusion(conclusion), 500);

      return () => {
        clearTimeout(timer);
      };
    }, [conclusion]);

    return (
      <div className={styles.wrapper}>
        <p className={styles.subtitle}>
          Review the notes and comments from your session and agree on 3-5
          action items for the team.
        </p>
        <div className={styles.conclude}>
          <h5>Agreed action items</h5>
          <p>List 3-5 action items for the team.</p>
          <div className={styles.conclusion}>
            <p className={classNames(styles.autosave, saved && styles.show)}>
              Auto-saved
            </p>
            <TextEditor value={conclusion} handleChange={setConclusion} />
          </div>
        </div>
        <div className={styles.summary}>
          <h5>Summary of comments</h5>
          <div className={styles.dims}>
            {play.effectiveness
              .filter((e) => e.groupId === "team_play_effectiveness")
              .map((dim, index) => (
                <div key={"DimCard-" + dim.dim_id} className={styles.card}>
                  <div className={styles.title}>
                    <div className={styles.icon}>
                      <Icon icon="Chat" />
                    </div>
                    <h5>{index + 1 + ". " + dim.name}</h5>
                  </div>
                  <div className={styles.comments}>
                    {play.comments
                      .filter((c) => c.item_id === dim.dim_id)
                      .map((comment) => (
                        <div
                          key={"Comment-" + comment.id}
                          className={classNames(
                            styles.comment,
                            comment.comment.length > 27 && styles.flexstart
                          )}
                        >
                          <div className={styles.avatar}>
                            <UserAvatar
                              member={comment.createdBy}
                              size="small"
                              showName
                            />
                          </div>
                          <p className={styles.message}>{comment.comment}</p>
                        </div>
                      ))}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    );
  }
);
