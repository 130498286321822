import React, { useEffect } from "react";
import classNames from "classnames";
import Popup from "reactjs-popup";
import TabSwitch from "components/UI/TabSwitch";
import Modal from "react-modal";
import Button from "components/UI/Button";
import Icon from "components/UI/Icon";
import EllipsisButton from "components/UI/EllipsisButton";
import DropDown from "components/UI/DropDown";
import TeamMemberAvatars from "components/TeamMemberAvatars";
import SidebarWrapper from "components/UI/SidebarWrapper";
import SidebarHeader from "components/UI/SidebarHeader";
import InterviewImage from "images/position/Interview";
import CompareScene from "images/position/CompareScene";
import OnboardScene from "images/position/OnboardScene";
import { ReactComponent as LayoutIcon } from "images/icons/Layout-compare.svg";
import { ReactComponent as CommentIcon } from "images/icons/Chat.svg";
import { ReactComponent as UsersIcon } from "images/icons/Users.svg";
import { ReactComponent as ClipboardIcon } from "images/icons/Project.svg";
import { ReactComponent as SignpostIcon } from "images/icons/signpost.svg";
import { ReactComponent as StarIcon } from "images/icons/Star.svg";
import { ReactComponent as FlagIcon } from "images/icons/Flag.svg";
import { useSidebar } from "utils/sidebarProvider";
import { printoutRoute } from "client/authRoutes";
import Description from "./Description";
import KeyObjectives from "./KeyObjectives";
import Competencies from "./Competencies";
import Requirements from "./Requirements";
import Kanban from "./Kanban";
import ShareAccess from "./ShareAccess";
import AddToTeamModal from "../Candidate/AddToTeamModal";
import Invite from "./Invite";
import EditPosition from "./EditPosition";
import LearnMoreModal from "./LearnMoreModal";
import SaveTemplate from "./SaveTemplate";
import RemovePosition from "./EditPosition/RemovePosition";
import compose from "./compose";

import styles from "./Position.module.scss";

const evaluateValues = {
  color: "blue",
  image: <InterviewImage />,
  value1: {
    icon: <LayoutIcon />,
    title: "Structured evaluation flow",
    desc: "A consistent and fair evaluation of all candidates on per criterion basis.",
  },
  value2: {
    icon: <CommentIcon />,
    title: "Evaluator commenting",
    desc: "Commenting is done in the evaluation flow together with candidate scoring.",
  },
  value3: {
    icon: <UsersIcon />,
    title: "Multiple evaluators",
    desc: "Support for multiple evaluators with separate scoring for each.",
  },
  value4: {
    icon: <ClipboardIcon />,
    title: "Per candidate scoring",
    desc: "Understand how candidates differ with a combined candidate comparison table.",
  },
};

const compareValues = {
  color: "yellow",
  image: <CompareScene />,
  value1: {
    icon: <LayoutIcon />,
    title: "Combined candidate scores",
    desc: "Scores from all candidates and evaluators on one screen.",
  },
  value2: {
    icon: <StarIcon />,
    title: "Highlighted outliers",
    desc: "When scores between evaluators differ significantly, outliers are highlighted.",
  },
  value3: {
    icon: <CommentIcon />,
    title: "See all the comments",
    desc: "Comments from all evaluators visible in one place.",
  },
  value4: {
    icon: <SignpostIcon />,
    title: "Simplified decision making",
    desc: "Having all candidates and evaluation scores on one chart significantly simplifies decision making.",
  },
};

const onboardValues = {
  color: "green",
  image: <OnboardScene />,
  value1: {
    icon: <FlagIcon />,
    title: "Structured onboarding",
    desc: "Use talent data to get the most from structured onboarding.",
  },
  value2: {
    icon: <UsersIcon />,
    title: "Team insights",
    desc: "Get new employees up to speed faster with team behavioural insights.",
  },
  value3: {
    icon: <StarIcon />,
    title: "Understand motivators",
    desc: "Understand the values that motivate the team and how the new employee fits in.",
  },
  value4: {
    icon: <ClipboardIcon />,
    title: "Clear goals",
    desc: "Set clear induction goals and track them with Wisnio.",
  },
};

export default compose(
  ({
    data: { openPosition },
    account,
    match,
    evaluate,
    setEvaluate,
    openModal,
    updateModal,
    handleAddCandidateToTeam,
    onboardModal,
    setOnboardModal,
    overlayClose,
    setOverlayClose,
    toggleShowPositionTour,
    deleteModal,
    setDeleteModal,
    deletePosition,
    history,
  }) => {
    const { contentContext, collapsedContext } = useSidebar();
    const [content, setContent] = contentContext;
    const [collapsed] = collapsedContext;

    const sidebarContent = [];

    useEffect(() => {
      setContent(sidebarContent);
    }, []);

    return (
      <SidebarWrapper noPadding noMarginBottom noFooter>
        <SidebarHeader
          title={openPosition.name}
          subtitle={`${openPosition.parentTeam.name} • ${openPosition.candidatesUnion.length} candidates`}
          extrasubtitle={
            openPosition.template &&
            ` • Based on ${openPosition.template.title}`
          }
          extraItems={
            <div className={styles.extraButtons}>
              <TabSwitch
                items={[
                  {
                    active: true,
                    link: false,
                    component: (
                      <div className={styles.item}>
                        <span className={styles.icon}>
                          <Icon icon="Kanban" />
                        </span>
                        <p>List view</p>
                      </div>
                    ),
                  },
                  {
                    active: false,
                    link: `/team/${openPosition.parentTeam.id}/position/${openPosition.id}/compare`,
                    component: (
                      <div className={classNames(styles.item, "compare")}>
                        <span className={styles.icon}>
                          <Icon icon="Compare" />
                        </span>
                        <p>Comparison view</p>
                      </div>
                    ),
                  },
                ]}
              />
            </div>
          }
        >
          <div className="positionMapping">
            <Button
              bStyle={["verySmall"]}
              onClick={() => {
                history.push(
                  `/team/${parseInt(
                    match.params.teamId,
                    10
                  )}/position/${parseInt(match.params.positionId, 10)}/edit`
                );
              }}
            >
              <Icon icon="Edit" marginRight />
              Edit position
            </Button>
          </div>
          <div className={styles.printBtn}>
            <a
              href={`${printoutRoute}/printout/position/${
                openPosition.id
              }/${openPosition.name
                .match(
                  /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
                )
                .map((x) => x.toLowerCase())
                .join("_")}_position_profile.pdf`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button bStyle={["secondary", "verySmall"]} type="button">
                <Icon icon="Print" marginRight />
                Print
              </Button>
            </a>
          </div>
          <div className={classNames(styles.inviteButton, "invite")}>
            <Button
              bStyle={["primary", "verySmall"]}
              type="button"
              onClick={() => updateModal("inviteCandidates")}
            >
              <span className={styles.icon}>
                <Icon icon="AddUser" marginRight />
              </span>
              Add candidates
            </Button>
          </div>
          <div className={styles.moreBtn} id="positionDropdownMenu">
            <div className="dropdownWrapper" id="dropdownMenu">
              <Popup
                keepTooltipInside="body"
                trigger={
                  <EllipsisButton large id="positionDropdownMenuTrigger" />
                }
                position="bottom right"
                arrowStyle={{ display: "none" }}
                nested
              >
                <DropDown>
                  <li className="inviteStakeholders">
                    <Button
                      type="button"
                      bStyle={["none"]}
                      onClick={() => updateModal("shareAccess")}
                    >
                      <Icon icon="Lock" marginRight />
                      Share access
                    </Button>
                  </li>
                  {account.adminRights && (
                    <Popup
                      keepTooltipInside="body"
                      trigger={
                        <li>
                          <Button type="button" bStyle={["none"]}>
                            <Icon icon="Compare" marginRight />
                            Save as template
                          </Button>
                        </li>
                      }
                      closeOnDocumentClick
                      closeOnEscape
                      position="top left"
                      nested
                    >
                      {(close) => (
                        <div className={styles.saveTemplateWrapper}>
                          <SaveTemplate position={openPosition} close={close} />
                        </div>
                      )}
                    </Popup>
                  )}
                  {toggleShowPositionTour && (
                    <li>
                      <Button
                        type="button"
                        bStyle={["none"]}
                        onClick={() => {
                          document
                            .querySelector("#positionDropdownMenuTrigger")
                            .click();
                          toggleShowPositionTour();
                        }}
                      >
                        <Icon icon="Info" marginRight />
                        Restart tour
                      </Button>
                    </li>
                  )}
                  <li>
                    <Button
                      type="button"
                      bStyle={["none"]}
                      onClick={() => setDeleteModal(true)}
                    >
                      <Icon icon="Delete" marginRight />
                      Archive position
                    </Button>
                  </li>
                </DropDown>
              </Popup>
            </div>
          </div>
        </SidebarHeader>
        <div
          className={classNames(
            styles.main,
            document.whitelabel &&
              document.whitelabel !== "default" &&
              styles.whitelabel
          )}
        >
          <div className={styles.wrapper}>
            <Kanban
              position={openPosition}
              evaluate={evaluate}
              setEvaluate={setEvaluate}
              openModal={openModal}
              updateModal={updateModal}
              setOnboardModal={setOnboardModal}
              toggleShowPositionTour={toggleShowPositionTour}
              collapsed={collapsed}
            />
            <Modal
              closeTimeoutMS={350}
              isOpen={openModal === "description"}
              onRequestClose={() => updateModal("")}
              shouldCloseOnOverlayClick
              className={styles.modal}
            >
              <Button
                bStyle="close"
                type="button"
                onClick={() => updateModal("")}
              >
                <i className="fal fa-times" />
              </Button>
              <Description
                position={openPosition}
                closeModal={() => updateModal("")}
              />
            </Modal>
            <Modal
              closeTimeoutMS={350}
              isOpen={openModal === "objectives"}
              onRequestClose={() => updateModal("")}
              shouldCloseOnOverlayClick
              className={styles.modal}
            >
              <Button
                bStyle="close"
                type="button"
                onClick={() => updateModal("")}
              >
                <i className="fal fa-times" />
              </Button>
              <KeyObjectives
                position={openPosition}
                closeModal={() => updateModal("")}
              />
            </Modal>
            <Modal
              closeTimeoutMS={350}
              isOpen={openModal === "requirements"}
              onRequestClose={() => updateModal("")}
              shouldCloseOnOverlayClick
              className={styles.modal}
            >
              <Button
                bStyle="close"
                type="button"
                onClick={() => updateModal("")}
              >
                <i className="fal fa-times" />
              </Button>
              <Requirements
                position={openPosition}
                closeModal={() => updateModal("")}
              />
            </Modal>
            <Modal
              closeTimeoutMS={350}
              isOpen={openModal === "competencies"}
              onRequestClose={() => updateModal("")}
              shouldCloseOnOverlayClick
              className={styles.modal}
            >
              <Button
                bStyle="close"
                type="button"
                onClick={() => updateModal("")}
              >
                <i className="fal fa-times" />
              </Button>
              <Competencies
                position={openPosition}
                closeModal={() => updateModal("")}
              />
            </Modal>
            <Modal
              closeTimeoutMS={350}
              isOpen={
                openModal === "invite" || openModal === "inviteCandidates"
              }
              onRequestClose={() => updateModal("")}
              shouldCloseOnOverlayClick={overlayClose}
              shouldCloseOnEsc={overlayClose}
              className={styles.modal}
            >
              <Invite
                position={openPosition}
                updateModal={updateModal}
                setOverlayClose={setOverlayClose}
                resource={
                  openModal === "inviteCandidates" ? "candidate" : "position"
                }
              />
            </Modal>
            <Modal
              closeTimeoutMS={350}
              isOpen={openModal === "shareAccess"}
              onRequestClose={() => updateModal("")}
              shouldCloseOnOverlayClick={overlayClose}
              shouldCloseOnEsc={overlayClose}
              className={styles.modal}
            >
              <ShareAccess
                position={openPosition}
                updateModal={updateModal}
                setOverlayClose={setOverlayClose}
              />
            </Modal>
            <Modal
              closeTimeoutMS={350}
              isOpen={openModal === "editPosition"}
              onRequestClose={() => updateModal("")}
              shouldCloseOnOverlayClick
              className={styles.modal}
            >
              <EditPosition position={openPosition} updateModal={updateModal} />
            </Modal>
            <Modal
              isOpen={onboardModal && true}
              onRequestClose={() => setOnboardModal(false)}
              shouldCloseOnOverlayClick
              className={styles.modal}
            >
              <Button
                bStyle="close"
                type="button"
                onClick={() => setOnboardModal(false)}
              >
                <i className="fal fa-times" />
              </Button>
              <AddToTeamModal
                setModal={setOnboardModal}
                candidate={onboardModal}
                team={openPosition.parentTeam}
                handleAddCandidateToTeam={handleAddCandidateToTeam}
              />
            </Modal>
            <Modal
              closeTimeoutMS={350}
              isOpen={openModal === "evaluateInfo"}
              onRequestClose={() => updateModal("")}
              shouldCloseOnOverlayClick
              className={styles.modal}
            >
              <Button
                bStyle="close"
                type="button"
                onClick={() => updateModal("")}
              >
                <i className="fal fa-times" />
              </Button>
              <LearnMoreModal
                title="Candidate evaluation"
                subtitle="Wisnio uses interactive evaluation flow and candidate scorecards based on previously set key objectives, position requirements, and competencies to score each candidate."
                values={evaluateValues}
              />
            </Modal>
            <Modal
              closeTimeoutMS={350}
              isOpen={openModal === "compareInfo"}
              onRequestClose={() => updateModal("")}
              shouldCloseOnOverlayClick
              className={styles.modal}
            >
              <Button
                bStyle="close"
                type="button"
                onClick={() => updateModal("")}
              >
                <i className="fal fa-times" />
              </Button>
              <LearnMoreModal
                title="Compare candidates with ease"
                subtitle="Compare and assess combined candidate scores with ease. The comparison includes comments and average scores for each hiring criterion."
                values={compareValues}
              />
            </Modal>
            <Modal
              closeTimeoutMS={350}
              isOpen={openModal === "onboardInfo"}
              onRequestClose={() => updateModal("")}
              shouldCloseOnOverlayClick
              className={styles.modal}
            >
              <Button
                bStyle="close"
                type="button"
                onClick={() => updateModal("")}
              >
                <i className="fal fa-times" />
              </Button>
              <LearnMoreModal
                title="Candidate onboarding"
                subtitle="Go beyond company policies and super-charge new employee onboarding with actionable team and talent data."
                values={onboardValues}
              />
            </Modal>
            <Modal
              closeTimeoutMS={350}
              isOpen={!!deleteModal}
              onRequestClose={() => setDeleteModal(false)}
              shouldCloseOnOverlayClick
              className={styles.modal}
            >
              <RemovePosition
                updateDeletePrompt={setDeleteModal}
                deletePosition={deletePosition}
                position={openPosition}
                history={history}
              />
            </Modal>
          </div>
        </div>
      </SidebarWrapper>
    );
  }
);
