import React, { useState, useEffect } from "react";
import classNames from "classnames";
import Popup from "reactjs-popup";
import TextareaAutosize from "react-autosize-textarea";
import Button from "components/UI/Button";
import Icon from "components/UI/Icon";
import styles from "../EditTemplate.module.scss";

export default ({
  highlight,
  handleUpdatePositionTemplateHighlight,
  handleDeletePositionTemplateHighlight,
}) => {
  const [title, setTitle] = useState(highlight.title);
  const [desc, setDesc] = useState(highlight.description);
  const [icon, setIcon] = useState(highlight.icon);
  const [color, setColor] = useState(highlight.color);

  useEffect(() => {
    let timer = setTimeout(
      () =>
        handleUpdatePositionTemplateHighlight(
          highlight.id,
          title,
          desc,
          icon,
          color
        ),
      500
    );

    return () => {
      clearTimeout(timer);
    };
  }, [title, desc, color, icon]);

  const iconList = [
    "User",
    "Users",
    "Clipboard",
    "Signpost",
    "Star",
    "Building",
    "Chat",
    "Mail",
  ];

  return (
    <div className={styles.card}>
      <div className={classNames(styles.dragHandle)}>
        <Icon icon="Drag" />
      </div>
      <div className={styles.top}>
        <div className={styles.deleteIcon}>
          <Button
            bStyle={["none"]}
            onClick={() => handleDeletePositionTemplateHighlight(highlight.id)}
          >
            <Icon icon="Close" />
          </Button>
        </div>
      </div>
      <div className={styles.bottom}>
        <TextareaAutosize
          rows={1}
          maxRows={2}
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Title"
          className={styles.title}
          data-gramm_editor="false"
          spellCheck="false"
        />
        <textarea
          rows="3"
          className={styles.desc}
          value={desc}
          placeholder="Description"
          spellCheck="false"
          onChange={(e) => setDesc(e.target.value)}
        />
      </div>
    </div>
  );
};
