import dataUrlToFil from 'utils/dataUrlToFile'
import { graphql } from 'react-apollo'
import { loader } from 'graphql.macro'
import { compose, withHandlers, withState } from 'recompose'

const UPLOAD_AVATAR = loader('client/Mutations/uploadAvatar3.graphql')

export default compose(
  graphql(UPLOAD_AVATAR, { name: 'uploadAvatarMutation' }),
  withState('avatarScale', 'setAvatarScale', 1.2),
  withState('editorIsLoading', 'setEditorIsLoading', false),
  withHandlers({
    finishAvatarEdit: ({
      uploadAvatarMutation,
      avatarEditorRef,
      setEditorIsLoading,
      toggleAvatarEditor
    }) => () => {
      setEditorIsLoading(true)
      const canvas = avatarEditorRef.current.getImageScaledToCanvas()
      const resizedCanvas = document.createElement('canvas')
      const resizedContext = resizedCanvas.getContext('2d')
      resizedCanvas.height = 150
      resizedCanvas.width = 150
      resizedContext.drawImage(canvas, 0, 0, 150, 150)
      const dataURL = resizedCanvas.toDataURL('image/png')
      const File = dataUrlToFil(dataURL, 'avatar')
      console.log({File})
      uploadAvatarMutation({
        variables: { imageFile: File }
      }).then((res) => {
        setEditorIsLoading(false)
        toggleAvatarEditor(false)
      }).catch(err => {
        console.log({err})
      })
    }
  })
)
