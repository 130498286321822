import { withHandlers } from 'recompose'

export default withHandlers({
  handleChangeName: ({ updateTeam, team, updateModal }) => (e) => {
    e.preventDefault()
    const name = e.target.name.value
    updateTeam({
      variables: {
        name,
        team_id: team.id
      }
    }).then(() => {
      updateModal('')
    })
  }
})
