export default ({
  proxy, cache, team_id, candidate_id
}) => {
  const positionKeys = Object.keys(cache).filter(key => key.includes('PositionType'))
  positionKeys.forEach((key) => {
    if (cache[key].parent_team_id === team_id) {
      if (
        cache[key].onboarding
        && cache[cache[key].onboarding.id].candidate.id
          === `UserType:${candidate_id}`
      ) {
        delete cache[cache[key].onboarding.id]
        cache[key].onboarding = null
      }
    }
  })

  proxy.restore(cache)
}
