import React from "react";
import classNames from "classnames";
import { Link } from "react-scroll";
import AlignmentIndicator from "components/AlignmentIndicator/personality";
import styles from "./index.module.scss";
import Curve from "./curve";

export default ({ big5, type, isCurrentUser, team, resource, casestudy }) => {
  const getType = () => {
    if (type === "teamMember") {
      return styles.teamMember;
    }
    return null;
  };
  const noTeamMembers =
    (team && team.members.length === 0) ||
    (team && team.members.filter((m) => m.readyToRender).length === 0);

  return (
    <>
      <div className={classNames(styles.wrap, getType())}>
        {big5.map((dim) => {
          const labelClass = (alignment) => {
            if (alignment === "Very diverse") {
              return styles.veryDiverse;
            }
            if (alignment === "Diverse") {
              return styles.diverse;
            }
            if (alignment === "Similar") {
              return styles.similar;
            }
            if (alignment === "Very similar") {
              return styles.verySimilar;
            }
          };
          return (
            <Link to={dim.name} smooth offset={-36} key={dim.name}>
              <div className={styles.dim}>
                <i className={classNames(dim.icon, styles.icon)} />
                <h4 className={styles.desktop}>{dim.name}</h4>
                <div className={styles.desktop}>
                  {isCurrentUser && !noTeamMembers ? (
                    <AlignmentIndicator
                      dim={dim}
                      team={team}
                      highlight={isCurrentUser}
                      casestudy={casestudy}
                    />
                  ) : resource === "personal" ? null : team.members.filter(
                      (m) => m.personalityChart.readyToRender
                    ).length > 1 ? (
                    <p
                      className={classNames(
                        styles.label,
                        labelClass(dim.alignment)
                      )}
                    >
                      {dim.alignment}
                    </p>
                  ) : null}
                </div>
              </div>
            </Link>
          );
        })}

        <svg
          className={classNames(
            styles.chart,
            isCurrentUser && styles.isCurrentUser
          )}
          viewBox="0 0 570 100"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            className={classNames(styles.scoreLine, styles.edge)}
            d="M0,0 L570,0"
          />
          <path className={styles.scoreLine} d="M0,25 L570,25" />
          <path className={styles.scoreLine} d="M0,50 L570,50" />
          <path className={styles.scoreLine} d="M0,75 L570,75" />
          <path
            className={classNames(styles.scoreLine, styles.edge)}
            d="M0,100 L570,100"
          />
          {big5.map((dim, i) => {
            const cx = ((100 / 5 / 2 + (100 / 5) * i) * 570) / 100;
            const cy = 100 - dim.score;
            return (
              <g key={dim.name}>
                <text
                  className={styles.score}
                  x={`${cx}`}
                  y={`${cy - 10}`}
                  textAnchor="middle"
                >
                  {parseInt(dim.score, 10)}
                </text>
                <circle
                  className={styles.scoreCircle}
                  cx={`${cx}`}
                  cy={`${cy}`}
                  r={4}
                />
              </g>
            );
          })}
          {team &&
            team.members.length > 0 &&
            team.members.some((m) => m.readyToRender) && (
              <Curve big5={big5} isTeam={!noTeamMembers} />
            )}
          {resource === "personal" && (
            <Curve big5={big5} isTeam={!noTeamMembers} />
          )}
        </svg>
      </div>

      {big5.map((dim) => {
        const labelClass = (alignment) => {
          if (alignment === "Very diverse") {
            return styles.veryDiverse;
          }
          if (alignment === "Diverse") {
            return styles.diverse;
          }
          if (alignment === "Similar") {
            return styles.similar;
          }
          if (alignment === "Very similar") {
            return styles.verySimilar;
          }
        };

        return (
          <div className={styles.mobile} key={dim.name}>
            <Link to={dim.name} smooth>
              <div className={styles.dimWrapperMobile}>
                <div className={styles.left}>
                  <h4>
                    <i className={classNames(dim.icon, styles.icon)} />
                    {dim.name}
                  </h4>
                </div>
                <div className={styles.right}>
                  {isCurrentUser ? (
                    <AlignmentIndicator
                      dim={dim}
                      team={team}
                      highlight={isCurrentUser}
                      casestudy={casestudy}
                    />
                  ) : resource === "personal" ? null : team.members.length >
                    1 ? (
                    <p
                      className={classNames(
                        styles.label,
                        labelClass(dim.alignment)
                      )}
                    >
                      {dim.alignment}
                    </p>
                  ) : null}
                </div>
              </div>
            </Link>
          </div>
        );
      })}
    </>
  );
};
