import React from 'react'
import {
  compose, branch, renderComponent, withHandlers
} from 'recompose'
import { graphql, withApollo } from 'react-apollo'
import { loader } from 'graphql.macro'
import { withRouter } from 'react-router-dom'
import Circles from 'components/Loaders/Circles'

const TEAM_GOAL_GROUPS = loader('./TeamGoalGroups1.graphql')
const TEAM_GOAL_GROUP_FINAL_STATUS = loader(
  './updateTeamGoalGroupFinalStatus.graphql'
)

export default compose(
  withRouter,
  withApollo,
  graphql(TEAM_GOAL_GROUPS, {
    name: 'TEAM_GOAL_GROUPS',
    options: (props) => {
      return {
        variables: {
          team_id: parseInt(props.match.params.teamId, 10),
          play_id: parseInt(props.match.params.toolId, 10)
        },
        notifyOnNetworkStatusChange: true
      }
    }
  }),
  graphql(TEAM_GOAL_GROUP_FINAL_STATUS, {
    name: 'teamGoalGroupFinalStatus',
    options: (props) => {
      return {
        update: (proxy, { data: { updateTeamGoalGroup } }) => {
          const data = proxy.readQuery({
            query: TEAM_GOAL_GROUPS,
            variables: {
              team_id: parseInt(props.match.params.teamId, 10),
              play_id: parseInt(props.match.params.toolId, 10)
            }
          })

          proxy.writeQuery({
            query: TEAM_GOAL_GROUPS,
            variables: {
              team_id: parseInt(props.match.params.teamId, 10),
              play_id: parseInt(props.match.params.toolId, 10)
            },
            data
          })
        }
      }
    }
  }),
  branch(
    (props) => {
      const condition = props.TEAM_GOAL_GROUPS && props.TEAM_GOAL_GROUPS.loading
      return condition
    },
    renderComponent(() => (
      <div style={{ height: '100vh' }}>
        <Circles />
      </div>
    ))
  ),
  withHandlers({
    handleUpdateGroupFinal: ({ teamGoalGroupFinalStatus }) => (
      groupId,
      isFinal
    ) => {
      teamGoalGroupFinalStatus({
        variables: {
          groupId,
          isFinal
        }
      })
    }
  })
)
