import React, { useState } from 'react'
import Popup from 'reactjs-popup'
import Button from 'components/UI/Button'
import { ReactComponent as LinkIcon } from 'images/icons/Link.svg'
import Icon from 'components/UI/Icon'
import Select from 'components/UI/Select'
import classNames from 'classnames'
import UserAvatar from 'components/UserAvatar'
import DropDown from 'components/UI/DropDown'
import EllipsisButton from 'components/UI/EllipsisButton'

import styles from './MemberRow.module.scss'

const ContractTypeSelect = ({
  roleTypes,
  user,
  handleChangeAccountContract
}) => (
  <Select
    onChange={e => handleChangeAccountContract({ event: e, user, roleTypes })}
    value={user.account_role}
    noBorder
  >
    {roleTypes.map(rt => (
      <option key={rt.name} value={rt.name}>
        {rt.name.charAt(0).toUpperCase() + rt.name.slice(1)}
      </option>
    ))}
  </Select>
)

export default ({
  user,
  viewer,
  handleChangeAccountContract,
  updateRemoveMemberModal
}) => {
  const roleTypes = [
    { name: 'member', role: 2 },
    { name: 'owner', role: 3 },
    { name: 'admin', role: 4 }
  ]

  const [isCopied, setIsCopied] = useState(false)

  const copyClipboard = (value) => {
    const tempInput = document.createElement('input')
    tempInput.value = value
    document.body.appendChild(tempInput)
    tempInput.select()
    document.execCommand('copy')
    document.body.removeChild(tempInput)
    setIsCopied(true)
  }

  const organizations = () => {
    let orgs = ''
    user.organizations.map((org, i) => {
      if (user.organizations.length === i + 1) {
        orgs += `${org.name}`
      } else {
        orgs += `${org.name}, `
      }
    })

    orgs.substring(0, orgs.length - 2)
    return orgs
  }

  return (
    <tr className={styles.row}>
      <td className={styles.avatarCol}>
        <div className={styles.avatar}>
          <UserAvatar
            avatarStyle={styles}
            member={user}
            isInvite={user.type === 'invite'}
            size='small'
          />
        </div>
        <div className={styles.nameAndEmail}>
          {user.name && <p className={styles.name}>{user.name}</p>}
          <p className={styles.email}>{user.email}</p>
        </div>
      </td>

      <td className={styles.organizations}>
        <p>{organizations()}</p>
      </td>

      <td className={classNames(styles.state, styles[user.type])}>
        <div className={styles.stateFlex}>
          <p>{user.type === 'invite' ? 'Pending' : 'Active'}</p>
          {user.type === 'invite' && (
            <div className='smallPopupWrapper'>
              <Popup
                trigger={(
                  <div className={styles.shareLink}>
                    <Button
                      bStyle={['none']}
                      type='button'
                      onClick={() => copyClipboard(user.inviteLink)}
                    >
                      <LinkIcon />
                    </Button>
                  </div>
                )}
                className={styles.popup}
                position='top center'
                on='hover'
                arrow
                keepTooltipInside='table'
                closeOnDocumentClick
                repositionOnResize
              >
                <div className={styles.tooltipPopover}>
                  <span>
                    {isCopied ? 'Copied to clipboard!' : 'Copy invitation link'}
                  </span>
                </div>
              </Popup>
            </div>
          )}
        </div>
      </td>

      <td className={styles.guest}>
        {user.is_guest && (
          <div className='dropdownWrapper' id='dropdownMenu'>
            <Popup
              keepTooltipInside='table'
              trigger={(
                <p>
                  <Icon icon='User' marginRight />
                  Guest
                </p>
              )}
              on='hover'
              arrow={false}
              position='bottom center'
            >
              <p className={styles.guestPopup}>
                This user does not take up a seat for billing purposes.
              </p>
            </Popup>
          </div>
        )}
      </td>

      <td className={styles.role}>
        {viewer.id === user.id ? (
          <>
            <Select noBorder disabled>
              <option>
                {user.account_role.charAt(0).toUpperCase()
                  + user.account_role.slice(1)}
              </option>
            </Select>
            <div style={{ width: '12px' }} />
          </>
        ) : (
          <>
            <ContractTypeSelect
              roleTypes={roleTypes}
              user={user}
              handleChangeAccountContract={handleChangeAccountContract}
            />
          </>
        )}
      </td>

      <td className={styles.lastCol}>
        {user.account_role !== 'owner' && (
          <div className='dropdownWrapper' id='dropdownMenu'>
            <Popup
              keepTooltipInside='body'
              trigger={(
                <div className={styles.avatarWrapper}>
                  <EllipsisButton />
                </div>
              )}
              position='bottom right'
            >
              <DropDown>
                <li>
                  <Button
                    type='button'
                    bStyle={['none']}
                    onClick={() => updateRemoveMemberModal(user)}
                  >
                    <i className='fas fa-user-slash' />
                    Remove
                  </Button>
                </li>
              </DropDown>
            </Popup>
          </div>
        )}
      </td>
    </tr>
  )
}
