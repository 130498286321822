import React, { useEffect, useState } from "react";
import classNames from "classnames";
import compose from "./compose";
import styles from "./Invite.module.scss";
import SignupBox from "components/SignupBox";
import LoginBox from "components/LoginBox";
import UserAvatar from "components/UserAvatar";
import setWhitelabel from "utils/setWhitelabel";
import QueryString from "query-string";

const slides = [
  {
    title: "Start for free",
    subtitle: "Free for teams of up to 4 people",
    color: "dark",
  },
  {
    title: "Decision support tools",
    subtitle: "Hiring, succession planning, and team development",
    color: "dark",
  },
  {
    title: "Commitment-free",
    subtitle: "No credit card required for full access",
    color: "dark",
  },
];

const TeamMembers = ({ team }) => {
  return (
    <div className={styles.membersWrap}>
      <div className={styles.avatarsWrap}>
        {team.members.slice(0, 3).map((member, index) => (
          <UserAvatar
            key={`member-${index}`}
            member={{
              ...member,
              initials: member.initials_avatar_alt,
            }}
            size="small"
          />
        ))}
      </div>
      <h5
        className={styles.text}
      >{`${team.members.length} members already joined`}</h5>
    </div>
  );
};

export default compose(({ location, invite }) => {
  const { error } = QueryString.parse(location.search);

  useEffect(() => {
    setWhitelabel(invite.account.whitelabel);
  }, [invite]);

  const [slideNumber, setSlideNumber] = useState(0);

  const setSlide = (direction) => {
    if (direction === "prev") {
      if (slideNumber - 1 >= 0) {
        setSlideNumber(slideNumber - 1);
      } else {
        setSlideNumber(slides.length - 1);
      }
    }

    if (direction === "next") {
      if (slideNumber + 1 <= slides.length - 1) {
        setSlideNumber(slideNumber + 1);
      } else {
        setSlideNumber(0);
      }
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setSlide("next");
    }, 8000);
    return () => clearInterval(interval);
  }, [slideNumber]);

  return (
    <div className={styles.mainWrapper}>
      <div className={styles.main}>
        <div className={styles.left}>
          <div className={styles.wrapper}>
            <div className={styles.header}>
              <div className={styles.logoWrapper}>
                {invite.account.whitelabel ? (
                  <div
                    className={styles.defaultLogo}
                    style={{
                      backgroundImage:
                        "url(" + invite.account.whitelabel.logo + ")",
                      width:
                        invite.account.whitelabel.styles["--signup-logo"].width,
                      height:
                        invite.account.whitelabel.styles["--signup-logo"]
                          .height,
                    }}
                  />
                ) : (
                  <div className={styles.defaultLogo} />
                )}
              </div>
            </div>
            <h4 className={styles.heading}>{invite.heading}</h4>
            {invite.showTeam ? (
              <TeamMembers
                team={
                  invite.teams.find(
                    (teamInvite) => teamInvite.team.id === invite.showTeam
                  ).team
                }
              />
            ) : (
              <div className={styles.inviterWrap}>
                <div className={styles.avatar}>
                  <UserAvatar member={invite.inviter} size="small" />
                </div>
                <div className={styles.text}>
                  <p className={styles.invitedBy}>Invited by</p>
                  <h5
                    className={styles.name}
                  >{`${invite.inviter.first_name} ${invite.inviter.last_name}`}</h5>
                </div>
              </div>
            )}
            {error && <div className={styles.error}>{error}</div>}
            <div className={styles.signupWrapper}>
              {invite.user_exists ? (
                <LoginBox invite={invite} />
              ) : (
                <SignupBox email={invite.invited_email} />
              )}
            </div>
          </div>
        </div>
        <div className={styles.right}>
          <div className={styles.slides}>
            {slides.map((slide, index) => (
              <div
                key={"Slide-" + slide.title}
                className={classNames(
                  styles.slide,
                  styles[slide.color],
                  slideNumber === index && styles.show
                )}
              >
                <div className={styles.text}>
                  <h3 className={styles.title}>{slide.title}</h3>
                  <p className={styles.subtitle}>{slide.subtitle}</p>
                </div>
                <div className={styles.image} />
                <div className={styles.progressBar}>
                  <div className={styles.progress}></div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
});
