import React from "react";
import classNames from "classnames";
import Button from "components/UI/Button";
import Icon from "components/UI/Icon";
import Note from "./Note";
import NewComment from "./NewComment";
import compose from "./compose";
import styles from "./Notes.module.scss";

export default compose(
  ({
    notes,
    candidate,
    viewer,
    upsertCandidateNote,
    handleUpdateCandidateNote,
    handleDeleteCandidateNote,
    newNote,
    setNewNote,
    comment,
    setComment,
    editNote,
    setEditNote,
  }) => (
    <div className={styles.notesWrapper}>
      <div className={styles.notes}>
        {notes.map((note) => (
          <Note
            key={"Note-" + note.id}
            note={note}
            editNote={editNote}
            setEditNote={setEditNote}
            viewer={viewer}
            handleUpdateCandidateNote={handleUpdateCandidateNote}
            handleDeleteCandidateNote={handleDeleteCandidateNote}
          />
        ))}
      </div>
      {newNote && (
        <div className={styles.createNote}>
          <NewComment
            candidate={candidate}
            createCandidateNote={upsertCandidateNote}
            comment={comment}
            setComment={setComment}
            setNewNote={setNewNote}
          />
        </div>
      )}
      {!newNote && (
        <div className={styles.bottom}>
          <Button
            bStyle={["verySmall", "bluetext"]}
            onClick={() => setNewNote(true)}
          >
            <Icon icon="Plus" marginRight />
            Add new note
          </Button>
        </div>
      )}
    </div>
  )
);
