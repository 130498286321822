import ReviewConclusion from "../ReviewConclusion";
import ReviewScoring from "../ReviewScoring";
import ReviewSubmitted from "../ReviewSubmitted";

export const reviewSteps = [
  {
    name: "360° Assessment",
    id: "assesment_review",
    step_title: "360° Assessment",
    component: ReviewScoring,
  },
  {
    name: "360° Assessment",
    id: "assesment_conclusion",
    step_title: "360° Assessment",
    component: ReviewConclusion,
  },
  {
    name: "360° Assessment",
    id: "assesment_conclusion",
    step_title: "360° Assessment",
    component: ReviewSubmitted,
  },
];
