export default ({ tag, container, tooltip, scrollTop }) => {
  const spanRect = tag.getBoundingClientRect();
  const containerRect = container.getBoundingClientRect();

  const tooltipWidth = tooltip.clientWidth;

  let tooltipLeft = spanRect.left + spanRect.width / 2 - tooltipWidth / 2;

  // Adjust tooltip horizontally to stay within the container
  if (tooltipLeft + tooltipWidth > containerRect.right) {
    tooltipLeft = containerRect.right - tooltipWidth;
  } else if (tooltipLeft < containerRect.left) {
    tooltipLeft = containerRect.left;
  }

  return {
    left: tooltipLeft,
    top: spanRect.top,
  };
};
