import React from 'react'
import classNames from 'classnames'
import UserAvatar from 'components/UserAvatar'
import styles from './UserRow.module.scss'

export default ({ user, children, invite }) => (
  <tr className={styles.row}>
    <td className={styles.avatarCol}>
      <div className={styles.avatarAndName}>
        <div className={styles.avatar}>
          <UserAvatar
            avatarStyle={styles}
            member={user}
            isInvite={user.type === 'invite' || invite}
            size='small'
          />
        </div>
        <div className={styles.nameAndEmail}>
          {user.name && <p className={styles.name}>{user.name}</p>}
          <p className={styles.email}>{user.email}</p>
          {user.invited_email && (
            <p className={styles.email}>{user.invited_email}</p>
          )}
        </div>
      </div>
    </td>
    <td
      className={classNames(
        styles.state,
        styles[user.__typename === 'Invite' || user.type === 'invite' ? 'pending' : 'active']
      )}
    >
      <p>{user.__typename === 'Invite' || user.type === 'invite' ? 'Pending' : 'Active'}</p>
    </td>
    <td className={styles.rightCol}>{children}</td>
  </tr>
)
