import React from "react";
import { Link } from "react-router-dom";
import UserAvatar from "components/UserAvatar";
import Button from "components/UI/Button";
import TeamMemberAvatars from "components/TeamMemberAvatars";
import LinkHandler from "./LinkHandler";
import styles from "./CompareCandidates.module.scss";

const EvaluatedBy = (candidates, updateModal, selectedEvaluators, orderBy) => {
  const evaluators = [];

  candidates.map((c) => {
    c.evaluation.map((e) => {
      if (!evaluators.find((c1) => c1.id === e.evaluatedBy.id)) {
        evaluators.push(e.evaluatedBy);
      }
    });
  });
  return (
    <div className={styles.evaluatedByCol}>
      <Button
        bStyle={["none"]}
        type="button"
        onClick={() => updateModal("evaluators")}
      >
        <h5>Evaluated by</h5>
        <TeamMemberAvatars
          members={
            selectedEvaluators.length > 0 ? selectedEvaluators : evaluators
          }
          size="verySmall"
        />
      </Button>
    </div>
  );
};

const HeaderComponent = (cand, position) => {
  const type = cand.type === "user" ? "candidate" : cand.type;
  return (
    <div className={styles.userCol}>
      <LinkHandler
        link={`${`/${position.parent_org_id}`}/team/${
          position.parentTeam.id
        }/position/${position.id}/${type}/${cand.id}`}
        condition={
          cand.readyToRender &&
          position.parentTeam.members.some((m) => m.readyToRender)
        }
      >
        <div className={styles.avatarWrapper}>
          <UserAvatar member={cand} size="small" />
        </div>
      </LinkHandler>
      <LinkHandler
        link={`${`/${position.parent_org_id}`}/team/${
          position.parentTeam.id
        }/position/${position.id}/${type}/${cand.id}`}
        condition={
          cand.readyToRender &&
          position.parentTeam.members.some((m) => m.readyToRender)
        }
      >
        <p className={styles.name}>{cand.name}</p>
      </LinkHandler>
      <Link
        to={`${`/${position.parent_org_id}`}/team/${
          position.parentTeam.id
        }/position/${position.id}/${type}/${cand.id}/interviewguide${
          position.assessmentScorecardId
            ? "/" + position.assessmentScorecardId
            : ""
        }`}
      >
        <Button bStyle={["secondary", "verySmall"]} type="button">
          Evaluate
        </Button>
      </Link>
    </div>
  );
};

const getCandidates = (candidates, selectedEvaluators, orderBy) => {
  const candidatesList = [];

  if (selectedEvaluators.length > 0) {
    candidates.map((candidate) => {
      selectedEvaluators.map((evaluator) => {
        if (
          candidate.evaluation.some((e) => e.evaluatedBy.id === evaluator.id) &&
          !candidatesList.find((cl) => cl.id === candidate.id)
        ) {
          candidatesList.push(candidate);
        }
      });
    });
  } else {
    candidatesList.push(...candidates);
  }

  return candidatesList;
};

export default (
  candidates,
  position,
  updateModal,
  selectedEvaluators,
  orderBy
) =>
  React.useMemo(() => [
    {
      Header: EvaluatedBy(candidates, updateModal, selectedEvaluators),
      accessor: "col1",
    },
    ...getCandidates(candidates, selectedEvaluators, orderBy).map(
      (cand, index) => {
        return {
          Header: HeaderComponent(cand, position),
          accessor: `col${index + 2}`,
        };
      }
    ),
    {
      Header: () => null,
      accessor: "colLast",
    },
  ]);
