import React from "react";
import classNames from "classnames";
import UserAvatar from "components/UserAvatar";
import Score from "components/Score";

import styles from "./MiniChart.module.scss";

export default ({ resource, members, dim, min, max }) => {
  const membersList = [];

  members.map((m) => {
    if (
      m[resource].find((d) => d.name === dim || d.title === dim).score !== null
    ) {
      membersList.push({
        member: m,
        score: m[resource].find((d) => d.name === dim || d.title === dim).score,
      });
    }
  });

  return (
    <div className={styles.miniChartWrapper}>
      <div className={styles.miniChart}>
        <div className={styles.chart}>
          {membersList.map((m) => (
            <div
              style={{ left: `${m.score > 94 ? 94 : m.score}%` }}
              className={styles.avatar}
              key={`user-${m.member.id}`}
            >
              <UserAvatar member={m.member} size="verySmall" showName />
            </div>
          ))}
        </div>
      </div>
      {min && max && (
        <div className={styles.legend}>
          <p>{min}</p>
          <p>{max}</p>
        </div>
      )}
    </div>
  );
};
