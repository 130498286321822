import React from "react";
import classNames from "classnames";
import Button from "components/UI/Button";
import Icon from "components/UI/Icon";
import Draggable from "./Draggable";

import styles from "./Kanban.module.scss";

const clusterClasses = {
  compe_people: 3, // Interpersonal skills
  results: 1, // Results orientation
  org_effectiveness: 2, // Organisational effectiveness
  thought: 4, // Systems thinking
};

export default ({
  columns,
  setShowCompList,
  setOpenModal,
  resource,
  headers,
}) => (
  <div className={styles.kanbanWrapper}>
    {columns.slice(0, 3).map((col, colIndex) => {
      const columnHeader = headers[colIndex];

      // // Begin conflicting competencies logic
      // const colCompClasses = [];
      // const conflictingComps = [];

      // col.map((comp) => {
      //   colCompClasses.push({
      //     id: comp.id,
      //     class: clusterClasses[comp.dimParent],
      //   });
      // });

      // if (
      //   colCompClasses.filter((cl) => cl.class === 1).length > 0 &&
      //   colCompClasses.filter((cl) => cl.class === 2).length > 0
      // ) {
      //   colCompClasses.map((ccc) => {
      //     if (ccc.class === 1 || ccc.class === 2) {
      //       conflictingComps.push(ccc);
      //     }
      //   });
      // }

      // if (
      //   colCompClasses.filter((cl) => cl.class === 3).length > 0 &&
      //   colCompClasses.filter((cl) => cl.class === 4).length > 0
      // ) {
      //   colCompClasses.map((ccc) => {
      //     if (ccc.class === 3 || ccc.class === 4) {
      //       conflictingComps.push(ccc);
      //     }
      //   });
      // }

      return (
        <div
          key={`droppable-column${colIndex}`}
          className={classNames(
            styles.columnWrapper,
            styles[columnHeader.style]
          )}
        >
          <h4 className={styles.compTitleWrapper}>
            <div className={styles.left}>
              <h4 className={styles.compTitle}>
                <div className={styles.stars}>{columnHeader.icon}</div>
                {columnHeader.title}
              </h4>
            </div>
            <Button
              bStyle={["secondary", "small"]}
              type="button"
              onClick={() => setShowCompList(colIndex)}
            >
              <Icon icon="Edit" />
            </Button>
          </h4>

          <div
            className={classNames(
              styles.column,
              resource === "survey" && styles.survey
            )}
          >
            {columns[colIndex].length > 0 ? (
              columns[colIndex].map((item) => (
                <Draggable
                  key={item.id}
                  item={item}
                  setOpenModal={setOpenModal}
                  resource={resource}
                />
              ))
            ) : (
              <div className={styles.buttonWrapper}>
                <Button
                  bStyle={["dark"]}
                  type="button"
                  onClick={() => setShowCompList(colIndex)}
                >
                  <Icon icon="Plus" marginRight />
                  Add
                </Button>
              </div>
            )}
          </div>
        </div>
      );
    })}
  </div>
);
