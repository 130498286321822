import React from "react";
import { compose, lifecycle } from "recompose";
import { withRouter } from "react-router-dom";
import QueryString from "query-string";

import { graphql } from "react-apollo";
import { loader } from "graphql.macro";

import handleLoading from "utils/loader";
import Circles from "components/Loaders/Circles";

const VALIDATE_CHECKOUT_SESSION_SUCCESS = loader(
  "./validateCheckoutSessionSuccess.graphql"
);

export default compose(
  withRouter,
  graphql(VALIDATE_CHECKOUT_SESSION_SUCCESS, {
    options: (props) => {
      const { session_id } = QueryString.parse(props.location.search);
      return {
        variables: { session_id },
      };
    },
  }),
  handleLoading(() => (
    <div style={{ height: "100vh" }}>
      <Circles />
    </div>
  )),
  lifecycle({
    componentWillMount() {
      if (this.props.data.validateCheckoutSessionSuccess === "paid") {
        if (window.Intercom) {
          window.Intercom("trackEvent", "stripe-checkout-success");
        }
        const redirectUrl = this.props.location.pathname.split("/");
        redirectUrl.pop();
        window.location.replace(redirectUrl.join("/"));
      }
    },
  })
)((props) => {
  return (
    <div style={{ height: "100vh" }}>
      <Circles />
    </div>
  );
});
