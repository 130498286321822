import React from 'react'
import { Link } from 'react-router-dom'
import { ReactComponent as Logo } from 'images/logo/default/logo.svg'
import classNames from 'classnames'
import Button from 'components/UI/Button'
import { Form, Field } from 'react-final-form'
import compose from './compose'
import validatePassword from 'utils/validatePassword'
import styles from './ResetPassword.module.scss'

export default compose(
  ({ handleChangePassword, pwSuccess, location, history }) => {
    return (
      <div className={styles.wrapper}>
        <Logo className={styles.logo} />
        <div className={styles.smallerWrapper}>
          <h2 className={styles.title}>
            Please update your password
          </h2>
          <p className={styles.subtitle}>
            It must contain at least 8 characters, one uppercase
            letter, one lowercase letter and one digit.
          </p>

          <Form
            onSubmit={handleChangePassword}
            validate={({ new_password, repeat_new_password }) =>
              validatePassword({
                password: new_password,
                repeatpassword: repeat_new_password
              })
            }
            render={({
              submitError,
              handleSubmit,
              form,
              submitting,
              pristine,
              errors,
              valid,
              values
            }) => {
              return (
                <form onSubmit={handleSubmit} autoComplete="off">
                  <label htmlFor="old_password">
                    Old password
                    <Field
                      name="old_password"
                      component="input"
                      type="password"
                      placeholder="Current password"
                      required
                    />
                  </label>
                  <label htmlFor="new_password">
                    New password
                    <Field
                      name="new_password"
                      component="input"
                      type="password"
                      placeholder="Choose a new password"
                      required
                    />
                  </label>
                  <label htmlFor="email">
                    Repeat new password
                    <Field
                      type="password"
                      component="input"
                      name="repeat_new_password"
                      placeholder="Repeat the new password"
                      required
                    />
                  </label>

                  {pwSuccess && (
                    <p className={styles.success}>Success!</p>
                  )}
                  <Button
                    bStyle={['primary']}
                    type="submit"
                    disabled={pristine || submitting}
                  >
                    Set new password
                  </Button>
                  {(!valid || submitError) && (
                    <div className={styles.submitError}>
                      {submitError && <p>{submitError}</p>}
                      {Object.values(errors).map((err, key) => {
                        return (
                          <p
                            key={`form-error-${key}`}
                            className={styles.error}
                          >
                            {Object.values(err)}
                          </p>
                        )
                      })}
                    </div>
                  )}
                </form>
              )
            }}
          />
        </div>
      </div>
    )
  }
)
