import { withRouter } from "react-router-dom";
import { withState, compose, withHandlers } from "recompose";
import { graphql } from "react-apollo";
import { loader } from "graphql.macro";
import withStore from "utils/withStore";
import isEmail from "utils/isEmail";
import selectedOrg from "store/selectedOrg/index";

const ADD_PERSON_TO_ACCOUNT_TALENTPOOL = loader(
  "./addPersonToAccountTalentPool.graphql"
);

export default compose(
  withRouter,
  withStore("selectedOrg"),
  withState("nameOrEmail", "setNameOrEmail", null),
  graphql(ADD_PERSON_TO_ACCOUNT_TALENTPOOL, {
    name: "addPersonToAccountTalentPool",
  }),

  withHandlers({
    addUserFromList:
      ({ setNameOrEmail, sessionData, setSessionData }) =>
      async (user) => {
        setSessionData({
          ...sessionData,
          employee_under_review: {
            ...user,
            type: "user",
            obj_id: user.id,
          },
        });
        setNameOrEmail(null);
      },

    handleAddNewPerson:
      ({
        addPersonToAccountTalentPool,
        selectedOrg,
        sessionData,
        setSessionData,
      }) =>
      async ({ person }) => {
        const {
          data: { addPersonToAccountTalentPool: newPerson },
        } = await addPersonToAccountTalentPool({
          variables: {
            account_id: selectedOrg.account.id,
            person,
          },
        });
        setSessionData({
          ...sessionData,
          employee_under_review: {
            ...newPerson,
            type: "person",
            obj_id: newPerson.id,
          },
        });
      },
  })
);
