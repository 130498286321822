import React from 'react'
import classNames from 'classnames'

import styles from './TextEditor.module.scss'

export const Button = React.forwardRef(
  ({
    className, active, reversed, ...props
  }, ref) => (
    <span
      {...props}
      ref={ref}
      className={classNames(styles.button, active && styles.active)}
    />
  )
)

export const Icon = React.forwardRef(({ className, ...props }, ref) => (
  <span {...props} ref={ref} className={styles.icon} />
))

export const Instruction = React.forwardRef(({ className, ...props }, ref) => (
  <div {...props} ref={ref} className={styles.instruction} />
))

export const Menu = React.forwardRef(({ className, ...props }, ref) => (
  <div {...props} ref={ref} className={styles.menu} />
))

export const Toolbar = React.forwardRef(({ className, ...props }, ref) => (
  <Menu {...props} ref={ref} className={styles.toolbar} />
))
