import React from "react";
import { withHandlers, compose, withState } from "recompose";
import { graphql } from "react-apollo";
import { loader } from "graphql.macro";
import handleLoading from "utils/loader";
import Circles from "components/Loaders/Circles";
import { withRouter } from "react-router-dom";

const TEAM_QUERY = loader("./Team.graphql");
const ADD_PLAYER = loader("./AddPlayer2.graphql");
const REMOVE_PLAYER = loader("./RemovePlayer.graphql");
const SEND_EMAIL_TO_PLAYERS = loader("./sendEmailToPlayers1.graphql");

export default compose(
  withRouter,
  graphql(TEAM_QUERY, {
    options: ({ match: { params } }) => {
      return {
        variables: { id: parseInt(params.teamId, 10) },
      };
    },
  }),
  handleLoading(() => (
    <div style={{ height: "100vh" }}>
      <Circles />
    </div>
  )),
  withState("nameOrEmail", "setNameOrEmail", ""),
  withState("error", "setError", ""),
  withState("remindLoading", "setRemindLoading", ""),
  graphql(ADD_PLAYER, { name: "addPlayer" }),
  graphql(REMOVE_PLAYER, { name: "removePlayer" }),
  withState("list", "updateList", ({ data: { team }, play }) =>
    team.members.filter(
      (member) => !play.players.some((player) => member.id === player.id)
    )
  ),
  graphql(SEND_EMAIL_TO_PLAYERS, {
    name: "sendInviteEmails",
  }),
  withHandlers({
    addToPlayersList:
      ({ list, updateList, addPlayer, play }) =>
      async (member) => {
        await addPlayer({
          variables: {
            user_id: member.id,
            play_id: play.id,
          },
        });
        updateList(list.filter((player) => player.id !== member.id));
      },
    removePlayer:
      ({ list, updateList, removePlayer, players, data: { team }, play }) =>
      async (member) => {
        await removePlayer({
          variables: {
            user_id: member.id,
            play_id: play.id,
          },
        });
        if (
          (team.members.some((m) => m.id === member.id) &&
            list.some((m) => m.id !== member.id)) ||
          !list.length
        ) {
          updateList([...list, member]);
        }
      },
    handleSendInviteEmail:
      ({ sendInviteEmails, play, setRemindLoading }) =>
      async () => {
        setRemindLoading("Sending");
        const success = await sendInviteEmails({
          variables: { play_id: play.id },
        });
        if (success) {
          setRemindLoading("Invites sent");
        }
      },
  })
);
