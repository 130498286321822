import React from "react";
import classNames from "classnames";

import styles from "./TrafficLight.module.scss";

export default ({ score }) => {
  return (
    <div
      className={classNames(
        styles.lights,
        styles["lights-" + Math.round(score)]
      )}
    >
      {[...Array(5)].map((e, i) => (
        <div
          key={"Array-" + i}
          className={classNames(
            styles.light,
            Math.floor(score) > i && styles.active,
            Math.floor(score) === i &&
              score - Math.floor(score) > 0.3 &&
              styles.half
          )}
        ></div>
      ))}
    </div>
  );
};
