import React from "react";
import classNames from "classnames";
import Button from "components/UI/Button";
import TextBox from "components/UI/TextBox";
import ModalTitle from "components/UI/ModalTitle";
import MemberRow from "components/UserRow";
import DropDown from "components/UI/DropDown";
import Popup from "reactjs-popup";
import EllipsisButton from "components/UI/EllipsisButton";
import Invite from "components/Invite";
import ResendInvite from "./ResendInvite";
import compose from "./compose";

import styles from "./Modal.module.scss";

export default compose(
  ({
    data: { openPosition: position },
    match: {
      params: { teamId, orgId, candidateId },
    },
    updateModal,
    sendEvaluationInvites,
    handleRemoveEvaluationInvite,
    setOverlayClose,
    isLoading,
  }) => {
    return (
      <div className={styles.wrapper}>
        <Button bStyle="close" type="button" onClick={() => updateModal("")}>
          <i className="fal fa-times" />
        </Button>
        <div className={classNames(styles.modalWrapper)}>
          <div className={styles.modalHead}>
            <ModalTitle>Invite interviewers</ModalTitle>
            <p>
              Invite interviewers to access the guide and share their interview
              notes and ratings
            </p>
          </div>
          {position.evaluators && (
            <div className={styles.adminsWrapper}>
              <h4>Interviewers</h4>
              {position.evaluators
                .filter((e) => e.cand_id === parseInt(candidateId, 10))
                .map(({ id: invite_id, user }) => {
                  return (
                    <MemberRow key={`admin-${user.email}`} user={user}>
                      <div className={styles.lastCol}>
                        <div className="dropdownWrapper" id="dropdownMenu">
                          <Popup
                            keepTooltipInside="body"
                            trigger={
                              <div className={styles.avatarWrapper}>
                                <EllipsisButton />
                              </div>
                            }
                            position="bottom right"
                          >
                            <DropDown>
                              <li>
                                <ResendInvite invite_id={invite_id} />
                              </li>

                              <li>
                                <Button
                                  type="button"
                                  bStyle={["none"]}
                                  onClick={() =>
                                    handleRemoveEvaluationInvite({ invite_id })
                                  }
                                >
                                  <i className="fas fa-user-slash" />
                                  Remove
                                </Button>
                              </li>
                            </DropDown>
                          </Popup>
                        </div>
                      </div>
                    </MemberRow>
                  );
                })}
            </div>
          )}

          <div className={styles.formWrapper}>
            <Invite
              noheight
              validationCase="orgMember"
              position={position}
              org_id={parseInt(orgId, 10)}
              team_id={parseInt(teamId, 10)}
              position_id={position.id}
              handleSubmit={({ list }) => sendEvaluationInvites(list)}
              isLoading={isLoading}
              updateModal={updateModal}
              setOverlayClose={setOverlayClose}
              resource="candidateEvaluators"
              searchEnabled={true}
            />
          </div>
        </div>
      </div>
    );
  }
);
