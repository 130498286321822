import React from 'react'
import classNames from 'classnames'
import { ReactComponent as Arrow } from 'images/icons/arrow.svg'
import styles from './OrderBtn.module.scss'

export default ({ column, setOrder, order }) => (
  <div className={styles.orderButtons}>
    <div
      className={classNames(
        styles.btn,
        order.column === column && order.direction === 'ASC' && styles.active
      )}
      onClick={() => setOrder({ column, direction: 'ASC' })}
    >
      <Arrow />
    </div>
    <div
      className={classNames(
        styles.btn,
        styles.down,
        order.column === column && order.direction === 'DESC' && styles.active
      )}
      onClick={() => setOrder({ column, direction: 'DESC' })}
    >
      <Arrow />
    </div>
  </div>
)
