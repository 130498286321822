import React, { useEffect } from "react"
import Button from "components/UI/Button"
import Icon from "components/UI/Icon"
import { Form, Field } from "react-final-form"
import compose from "./compose"

import styles from "./NewAccount.module.scss"

export default compose(({ handleCreateAccount, updateModal, history }) => {
  useEffect(() => {
    if (history.location.search) {
      history.replace(history.location.pathname)
    }
  }, history)

  return (
    <>
      <Button bStyle="close" type="button" onClick={() => updateModal("")}>
        <i className="fal fa-times" />
      </Button>
      <div className={styles.modalWrapper}>
        <div className={styles.modalHead}>
          <h3 className={styles.title}>
            <div className={styles.icon}>
              <Icon icon="Logo" />
            </div>
            Create a new workspace
          </h3>
          <p>Use the form below to create a new workspace.</p>
        </div>
        <div className={styles.newTeam}>
          <Form
            autoComplete="new-password"
            onSubmit={handleCreateAccount}
            render={({ handleSubmit, form, submitting, pristine }) => (
              <form onSubmit={handleSubmit} autoComplete="new-password">
                <div className={styles.row}>
                  <h5>Workspace name</h5>
                  <Field
                    name="name"
                    component="input"
                    type="text"
                    placeholder="Enter workspace name"
                    autoComplete="off"
                    required
                  />
                </div>
                <div className={styles.buttonGroup}>
                  <Button
                    bStyle={["secondary", "small"]}
                    onClick={() => updateModal("")}
                  >
                    Cancel
                  </Button>
                  <Button
                    bStyle={["primary", "small"]}
                    type="submit"
                    disabled={submitting || pristine}
                  >
                    Create workspace
                  </Button>
                </div>
              </form>
            )}
          />
        </div>
      </div>
    </>
  )
})
