import React from "react";
import Popup from "reactjs-popup";
import classNames from "classnames";
import { ReactComponent as EmailIcon } from "images/icons/Email.svg";
import getAvatarColor from "./avatarColor";

import styles from "./UserAvatar.module.scss";

export default ({ member, avatarStyle, isInvite, showName, size, pointer }) => {
  if (showName) {
    if (member.avatar_url) {
      return (
        <div className="avatarPopupWrapper">
          <Popup
            trigger={
              <div
                className={classNames(
                  styles.wrapper,
                  size && styles[size],
                  styles[getAvatarColor(member, isInvite)],
                  pointer && styles.pointer
                )}
              >
                <img
                  alt="avatar"
                  aria-label="img"
                  className={(avatarStyle && avatarStyle.image) || styles.image}
                  src={member.avatar_url}
                />
              </div>
            }
            className={styles.popup}
            position="top center"
            on="hover"
          >
            <div className={styles.popover}>
              <p>{member.name}</p>
            </div>
          </Popup>
        </div>
      );
    }
    return (
      <div className="avatarPopupWrapper">
        <Popup
          trigger={
            <div
              className={classNames(
                styles.wrapper,
                size && styles[size],
                styles[getAvatarColor(member, isInvite)],
                pointer && styles.pointer
              )}
            >
              <div
                aria-label="initials"
                className={classNames(
                  (avatarStyle && avatarStyle.initials) || styles.initials
                )}
              >
                <p>
                  {member.initials ||
                    (isInvite && <EmailIcon />) ||
                    member.initials_avatar_alt}
                </p>
              </div>
            </div>
          }
          position="top center"
          on="hover"
        >
          <div className={styles.popover}>
            <p>{member.name}</p>
          </div>
        </Popup>
      </div>
    );
  }
  if (member.avatar_url) {
    return (
      <div
        aria-label="avatarWrapper"
        className={classNames(
          styles.wrapper,
          size && styles[size],
          styles[getAvatarColor(member, isInvite)],
          pointer && styles.pointer
        )}
      >
        <img
          alt="avatar"
          aria-label="img"
          className={(avatarStyle && avatarStyle.image) || styles.image}
          src={member.avatar_url}
        />
      </div>
    );
  }
  return (
    <div
      aria-label="avatarWrapper"
      className={classNames(
        styles.wrapper,
        size && styles[size],
        styles[getAvatarColor(member, isInvite)],
        pointer && styles.pointer
      )}
    >
      <div
        aria-label="initials"
        className={classNames(
          (avatarStyle && avatarStyle.initials) || styles.initials
        )}
      >
        <p>
          {member.initials ||
            (isInvite && <EmailIcon />) ||
            member.initials_avatar_alt}
        </p>
      </div>
    </div>
  );
};
