export default ({
  handleCreateTeamGoalGroup,
  handleUpdateGoalGroup,
  source,
  destination,
  draggableId,
  ungroupedGoals,
  setUngroupedGoals,
  handleAddGroupIdToGoal
}) => {
  if (!destination) return

  if (destination.droppableId === 'NewGroup') {
    handleCreateTeamGoalGroup(parseInt(draggableId, 10))
    return
  }

  if (source.droppableId === 'ungroupedGoals') {
    const item = ungroupedGoals[source.index]

    if (source.droppableId === destination.droppableId) {
      const filtered = [...ungroupedGoals].filter((_, i) => i !== source.index)
      filtered.splice(destination.index, 0, item)
      setUngroupedGoals(filtered)
      return
    }

    if (destination.droppableId.indexOf('group-') === 0) {
      handleAddGroupIdToGoal({
        group_id: parseInt(destination.droppableId.split('group-')[1], 10),
        goal_id: item.id
      })
      return
    }
  }

  if (source.droppableId.indexOf('group-') === 0) {
    if (destination.droppableId === 'ungroupedGoals') {
      handleAddGroupIdToGoal({
        group_id: 0,
        goal_id: parseInt(draggableId, 10)
      })
      return
    }

    if (destination.droppableId.indexOf('group-') === 0) {
      handleAddGroupIdToGoal({
        group_id: parseInt(destination.droppableId.split('group-')[1], 10),
        goal_id: parseInt(draggableId, 10)
      })
    }
  }
}
