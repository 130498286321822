import React from "react";
import classNames from "classnames";
import Wrapper from "components/UI/Wrapper";
import Button from "components/UI/Button";
import Field from "components/UI/Field";
import TextArea from "components/UI/TextArea";
import compose from "./compose";

import styles from "./SaveTemplate.module.scss";

export default compose(
  ({
    close,
    handleSaveTemplate,
    nameValue,
    setNameValue,
    descValue,
    setDescValue,
    saved,
    setSaved,
  }) => {
    return (
      <div className={styles.main}>
        <Wrapper left>
          <h5 className={styles.title}>Save position template</h5>
          <p className={styles.subtitle}>
            Make a list of required experiences, qualifications, and skills to
            achieve the key objectives in the role.
          </p>
          <div className={styles.content}>
            <form onSubmit={(e) => handleSaveTemplate(e)} autoComplete="off">
              <div className={styles.row}>
                <h5>Template name</h5>
                <div className={styles.smallRow}>
                  <Field
                    white
                    type="text"
                    name="name"
                    required
                    placeholder="Template name"
                    value={nameValue}
                    onChange={(e) => {
                      setSaved(false);
                      setNameValue(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className={styles.row}>
                <h5>Template description</h5>
                <div className={styles.smallRow}>
                  <TextArea
                    white
                    type="textarea"
                    name="description"
                    required
                    placeholder="Short description"
                    value={descValue}
                    onChange={(e) => {
                      setSaved(false);
                      setDescValue(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className={styles.footerButtons}>
                <Button
                  type="submit"
                  bStyle={["primary", "small"]}
                  disabled={saved || nameValue === "" || descValue === ""}
                >
                  {saved ? "Saved!" : "Save as template"}
                </Button>
                <Button
                  type="button"
                  bStyle={["secondary", "small"]}
                  onClick={close}
                >
                  Cancel
                </Button>
              </div>
            </form>
          </div>
        </Wrapper>
      </div>
    );
  }
);
