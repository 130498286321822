import React from 'react'
import Button from 'components/UI/Button'
import compose from './compose'

export default compose(
  ({
    handleResendInvite,
    btnState,
    invite,
    handleResendExistingUserInvite
  }) => (
    <Button
      bStyle={['none']}
      onClick={
        invite.type === 'user'
        || (invite.contract && invite.contract.state === 'waiting_user_acceptance')
          ? handleResendExistingUserInvite
          : handleResendInvite
      }
      disabled={btnState.disabled}
    >
      <i className={btnState.text} />
      {btnState.hoverText}
    </Button>
  )
)
