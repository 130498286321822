import { withHandlers } from 'recompose'
import { SELECT_ORG } from 'store/actionTypes'

export default withHandlers({
  handleSelectOrg:
    ({ data: { organizations }, dispatch, history }) =>
    (id) => {
      const organization = organizations.find((org) => org.id === id)
      dispatch({ type: SELECT_ORG, payload: organization })
      window.localStorage.setItem('recentOrg', organization.id)
      if (
        window.location.pathname !== '/dashboard' &&
        window.location.pathname !== '/'
      ) {
        history.push('/dashboard')
      }
    }
})
