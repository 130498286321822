import React, { useState } from "react";
import Popup from "reactjs-popup";
import classNames from "classnames";
import Button from "components/UI/Button";
import EllipsisButton from "components/UI/EllipsisButton";
import DropDown from "components/UI/DropDown";
import UserRow from "components/UserRow";
import ResendInvite from "components/ResendInvite";
import styles from "./Modal.module.scss";
import SendProfileReminder from "components/SendProfileReminder";

export default ({ user, team, removeFromTeam, client }) => {
  const [isLoading, setIsLoading] = useState(false);
  const isInviteOrPending = () => {
    if (user.type === "invite") return true;
    if (user.type && user.type === "invite") return true;
    if (user.state && user.state === "waiting_user_acceptance") return true;
    if (user.contract && user.contract.state === "waiting_user_acceptance")
      return true;
    return false;
  };

  return (
    <UserRow user={user}>
      <div className="dropdownWrapper" id="dropdownMenu">
        <Popup
          keepTooltipInside="body"
          trigger={
            <div className={styles.avatarWrapper}>
              <EllipsisButton />
            </div>
          }
          position="bottom right"
        >
          <DropDown>
            {isInviteOrPending() && (
              <li>
                <ResendInvite invite={user} org_id={team.organization_id} />
              </li>
            )}
            {!user.profileComplete && !isInviteOrPending() && (
              <li>
                <SendProfileReminder user={user} />
              </li>
            )}
            <li>
              <Button
                type="button"
                bStyle={["none"]}
                onClick={() => {
                  setIsLoading(user);
                  removeFromTeam({
                    variables: {
                      id: team.id,
                      user_id: user.id,
                      org_id: team.organization.id,
                    },
                  }).then((res) => {
                    client.reFetchObservableQueries(["TEAM"]);
                  });
                }}
                disabled={isLoading === user}
              >
                {isLoading ? (
                  <div
                    className={classNames(
                      styles.buttonText,
                      isLoading && styles.loading
                    )}
                  >
                    <i className="far fa-spinner-third" />
                    Removing...
                  </div>
                ) : (
                  <div className={styles.buttonText}>
                    <i className="fas fa-user-slash" />
                    Remove
                  </div>
                )}
              </Button>
            </li>
          </DropDown>
        </Popup>
      </div>
    </UserRow>
  );
};
