import React from "react";
import DOMPurify from "dompurify";
import classNames from "classnames";
import Button from "components/UI/Button";
import Icon from "components/UI/Icon";
import TextEditor from "components/TextEditor";
import Header from "./Header";
import styles from "../Summary.module.scss";

const getContentHtml = (content) => {
  return { __html: DOMPurify.sanitize(content) };
};

export default ({
  content,
  candidate,
  isVisible,
  showEditor,
  handleSave,
  editBtnTitle,
  handleTextEditorChange,
  value,
  readMore,
  setReadMore,
  setGptModal,
}) => {
  return (
    <>
      <Header
        content={content}
        candidate={candidate}
        isVisible={isVisible}
        showEditor={showEditor}
        handleSave={handleSave}
        handleTextEditorChange={handleTextEditorChange}
        editBtnTitle={editBtnTitle}
        setGptModal={setGptModal}
      />
      {content && !isVisible && (
        <div className={styles.textArea}>
          <div
            className={classNames(
              styles.deserializedText,
              readMore && styles.full,
              content.length > 800 && styles.long
            )}
            dangerouslySetInnerHTML={getContentHtml(content)}
          />
          {content.length > 800 && (
            <div
              className={classNames(
                styles.readMoreWrapper,
                readMore && styles.opened
              )}
            >
              <Button
                bStyle={["none"]}
                type="button"
                onClick={() => setReadMore(!readMore)}
              >
                {readMore ? (
                  <span className={styles.open}>
                    Read less <Icon icon="Chevron" />
                  </span>
                ) : (
                  <span>
                    Read more <Icon icon="Chevron" />
                  </span>
                )}
              </Button>
            </div>
          )}
        </div>
      )}
      {isVisible && (
        <div className={styles.bigWrapper}>
          <div className={styles.inputWrapper}>
            <TextEditor value={value} handleChange={handleTextEditorChange} />
          </div>
        </div>
      )}
    </>
  );
};
