import React from "react";
import classNames from "classnames";
import Sectors from "./Sectors";
import Groups from "./Groups";
import styles from "./index.module.scss";
import Blob from "./Blob";

export default ({ competencies, setShowModal, member, type }) => {
  const wedgeAngle =
    360 / competencies.groups.map((g) => g.dimensions).flat().length;
  const midX = 50;
  const midY = 50;
  const sectorRadius = 30;
  let compeDims = [];

  competencies.groups.forEach((group) => {
    compeDims = [...compeDims, ...group.dimensions];
  });

  const memberCompes = member && member.groups.map((g) => g.dimensions).flat();
  // const sortedByScore = competencies.sort((a,b) => b.score - a.score)// console.log({compeDims})

  // console.log(competencies.groups.map(g => g.dimensions).flat())
  // console.log(competencies.groups)

  const blobScores = compeDims.map((scoreObject, key) => {
    const startAngle = wedgeAngle * key;
    const scoreAngle = startAngle + wedgeAngle / 2;
    let distance = (scoreObject.score * sectorRadius) / 100;
    if (distance === 0) distance = 0;
    const scorePosX = Math.cos((scoreAngle * Math.PI) / 180) * distance + 50;

    const scorePosY = Math.sin((scoreAngle * Math.PI) / 180) * distance + 50;

    return { x: scorePosX, y: scorePosY };
  });

  const memberBlobScores =
    member &&
    member.groups
      .map((g) => g.dimensions)
      .flat()
      .map((scoreObject, key) => {
        let { score } = scoreObject;
        if (score === 0) score = 1;
        const startAngle = wedgeAngle * key;
        const scoreAngle = startAngle + wedgeAngle / 2;
        let distance = (score * sectorRadius) / 100;
        if (distance === 0) distance = 0;
        const scorePosX =
          Math.cos((scoreAngle * Math.PI) / 180) * distance + 50;

        const scorePosY =
          Math.sin((scoreAngle * Math.PI) / 180) * distance + 50;

        return { x: scorePosX, y: scorePosY };
      });

  return (
    <svg
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
      encoding="UTF-8"
      className={classNames(styles.main)}
    >
      {member && memberBlobScores && (
        <Blob
          competencies={memberCompes}
          wedgeAngle={wedgeAngle}
          midX={midX}
          midY={midY}
          isMemberHover={member && true}
          sectorRadius={sectorRadius}
          scores={blobScores}
        />
      )}
      <Blob
        competencies={compeDims}
        wedgeAngle={wedgeAngle}
        midX={midX}
        midY={midY}
        isMember={member && true}
        sectorRadius={sectorRadius}
        scores={memberBlobScores || blobScores}
      />
      <Sectors
        wedgeAngle={wedgeAngle}
        midX={midX}
        midY={midY}
        sectorRadius={sectorRadius}
        competencies={compeDims}
        member={member}
        memberCompes={memberCompes}
        groups={competencies.groups}
        setShowModal={setShowModal}
        type={type}
      />
      <Groups
        wedgeAngle={wedgeAngle}
        midX={midX}
        midY={midY}
        sectorRadius={sectorRadius}
        groups={competencies.groups}
        member={member}
        type={type}
      />
    </svg>
  );
};
