import React, { useEffect } from "react";
import Layout from "../../layouts/index";
import EvaluationGroup from "components/v2/UI/EvaluationGroup";

import Button from "components/UI/Button/index";
import Icon from "components/UI/Icon/index";
import UserAvatar from "components/UserAvatar/index";
import styles from "./styles.module.scss";

const ReviewScoring = ({
  data: {
    assessmentReview: {
      id: review_id,
      session: {
        assessment_rows: assessmentRows,
        created_by,
        employee_under_review,
        scorecard: { title: scorecard_title },
      },
      scores: assessmentScores,
      group: { id: group_id, title: groupTitle },
    },
  },

  currentStepIndex,
  setCurrentStepIndex,
  handleUpsertScore,
  handleUpdateReview,
}) => {
  const onScoreChange = (scoreData) => {
    handleUpsertScore({ ...scoreData, review_id });
  };

  useEffect(() => {
    handleUpdateReview(review_id, {
      status: "IN_PROGRESS",
    });
  }, []);

  const rightSideCol = (
    <div>
      <p>
        Please complete the survey below. Add detailed comments to each item to
        help guide development actions.
      </p>
    </div>
  );

  return (
    <div className={styles.reviewScoring}>
      <Layout
        variant="2-col-page"
        rightColContent={rightSideCol}
        title={groupTitle}
        icon="360Assesment"
      ></Layout>
      <Layout
        variant="2-col-page"
        rightColContent={
          <div className={styles.rightColContentStyle}>
            <div className={styles.employeeInfoStyle}>
              <div>
                <UserAvatar member={employee_under_review} size="small" />
              </div>

              <h4>{employee_under_review.name}</h4>
            </div>

            <div className={styles.scorecardInfoStyle}>
              <h5>Selected scorecard:</h5>
              <div className={styles.scorecardTitleStyle}>
                <Icon icon="Clipboard" primary size="large" />
                <p>{scorecard_title}</p>
              </div>
            </div>
          </div>
        }
        noMargin={true}
      >
        <div className={styles.invitationAvatarContainer}>
          <div>
            <p className={styles.invitedByText}>Created by</p>{" "}
          </div>
          <div className={styles.invitationPerson}>
            <div>
              <UserAvatar member={created_by} size="verySmall" />
            </div>
            <div>
              <p className="label bold">{created_by.name}</p>
            </div>
          </div>
        </div>
      </Layout>
      <div className={styles.mainDivStyle}>
        <EvaluationGroup
          scores={assessmentScores}
          onScoreChange={onScoreChange}
          evaluationRows={assessmentRows.map((row) => ({
            ...row,
            group_id,
            review_id,
          }))}
        />
        <div>
          <Button
            bStyle={["verySmall", "icon", "primary"]}
            onClick={() => setCurrentStepIndex(currentStepIndex + 1)}
          >
            <Icon icon="Check" />
            Save
          </Button>
        </div>
      </div>
      <div />
    </div>
  );
};

export default ReviewScoring;
