import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import compose from "./compose";
import Button from "components/UI/Button";
import PromptInput from "./PromptInput";
import Message from "./Message";

import styles from "./ChatInterface.module.scss";
import Icon from "components/UI/Icon";
import { useAppContext } from "contexts/app.context";
import InitiateScreen from "./InitiateScreen/index";

export default compose(
  ({
    messages,
    prompt,
    setPrompt,
    handleNewPrompt,
    promptNextMessage,
    setIsLoading,
    setMessages,
    sequence,
    contexts,
    variables,
    description,
    isLoading,
    bottomEl,
    examplePrompts,
    setTimeOut,
    timeOut,
    isReady,
    disabledText,
    handleTryAgain,
  }) => {
    const [examplePromptsOpen, setExamplePromptsOpen] = useState(true);

    const [filteredExamplePrompts, setFilteredExamplePrompts] =
      useState(examplePrompts);

    const { appSettings, setAppSettings } = useAppContext();
    const { showWisGptPreview } = appSettings;

    const promptGpt = () => {
      setIsLoading(true);
      promptNextMessage({
        variables: { messages: [] },
        sequence,
        contexts,
        variables,
      })
        .then((res) => {
          setIsLoading(false);
          setMessages(res.data.promptNextMessage);
        })
        .catch((err) => {
          setTimeOut(true);
          setIsLoading(false);
        });
    };

    useEffect(() => {
      if (!isReady) {
        setAppSettings({
          ...appSettings,
          showWisGptPreview: false,
        });
      }
    }, []);

    useEffect(() => {
      if (examplePrompts && examplePrompts.length > 0) {
        setFilteredExamplePrompts(examplePrompts);
      }
      setMessages([]);
      setTimeOut(false);
    }, [contexts, variables, examplePrompts]);

    useEffect(() => {
      let isMounted = true;
      if (messages && !messages.length && showWisGptPreview && isReady) {
        setTimeout(() => {
          if (isMounted) promptGpt();
        }, 200);
      }
      return () => {
        isMounted = false;
      };
    }, [contexts, variables, showWisGptPreview]);

    const handleShowFullscreen = () => {
      setAppSettings({ ...appSettings, showWisGptPreview: !showWisGptPreview });
    };

    const [isEnlarged, setIsEnlarged] = useState(false);
    const handleEnlargeWindow = () => {
      setIsEnlarged(!isEnlarged);
    };

    return (
      <>
        <div
          className={classNames(
            styles.firstPromptWrapper,
            !showWisGptPreview && styles.show
          )}
        >
          <div className={styles.toggleWrapper}>
            <button
              className={styles.toggleButton}
              onClick={handleShowFullscreen}
            >
              <Icon icon="Logo" large white />
              <p>WisGPT</p>
            </button>
          </div>
        </div>
        <div
          className={classNames(
            styles.interfaceWrapper,
            showWisGptPreview && styles.show,
            isEnlarged && styles.enlarged,
            !isEnlarged && styles.smallWidth
          )}
        >
          <div className={styles.interface}>
            <div className={styles.top}>
              <h1 className={styles.title}>
                <span className={styles.logo}>
                  <Icon icon="Logo" />
                </span>
                WisGPT
              </h1>

              <div className={styles.controls}>
                <div className={styles.enlargeButton}>
                  <Button
                    onClick={() => handleEnlargeWindow()}
                    bStyle={["none"]}
                  >
                    <Icon
                      icon={
                        isEnlarged
                          ? "FullscreenMinimizePurple"
                          : "FullscreenPurple"
                      }
                      large
                    />
                  </Button>
                </div>
                <Button onClick={handleShowFullscreen} bStyle={["none"]}>
                  <Icon icon="ChevronPurple" large />
                </Button>
              </div>
            </div>
            <div className={styles.messagesWrapper}>
              <div id="messagesWrapper" className={styles.messages}>
                {messages &&
                  messages.slice(1).map(({ role, content }, index) => {
                    return (
                      <Message
                        role={role}
                        content={content}
                        key={`message-${index}`}
                      />
                    );
                  })}
                {isLoading && (
                  <Message role={"assistant"} isLoading={isLoading} />
                )}
                {timeOut && (
                  <div className={styles.retry}>
                    <div className={styles.timeout}>
                      Sorry, WisGPT is super busy right now. Please try again in
                      a few minutes. Thanks for your patience!
                    </div>

                    <Button
                      className={styles.retry}
                      bStyle={["purple", "verySmall"]}
                      onClick={handleTryAgain}
                    >
                      Try again
                    </Button>
                  </div>
                )}
                <div ref={bottomEl}></div>
              </div>
            </div>
            {isReady ? (
              <div className={styles.promptWrapper}>
                <div className={styles.drawerButton}>
                  <Button
                    bStyle={["none"]}
                    onClick={() => {
                      if (examplePromptsOpen) {
                        setExamplePromptsOpen(false);
                      } else {
                        setExamplePromptsOpen(true);
                      }
                    }}
                  >
                    <div
                      className={classNames(
                        styles.icon,
                        examplePromptsOpen && styles.open
                      )}
                    >
                      <Icon icon="Chevron" />
                    </div>
                  </Button>
                </div>
                {filteredExamplePrompts.length > 0 && (
                  <div
                    className={classNames(
                      styles.examplePrompts,
                      examplePromptsOpen && styles.open
                    )}
                  >
                    {filteredExamplePrompts.map((examplePrompt, index) => (
                      <Button
                        key={"examplePrompt-" + index}
                        bStyle={["none"]}
                        disabled={
                          isLoading || !messages || !messages.length || !isReady
                        }
                        onClick={() => {
                          setPrompt(examplePrompt);
                          setTimeout(() => {
                            handleNewPrompt();
                          }, 10);
                          setTimeout(() => {
                            setExamplePromptsOpen(false);
                          }, 150);
                        }}
                      >
                        <p>{examplePrompt}</p>
                      </Button>
                    ))}
                  </div>
                )}

                <form onSubmit={handleNewPrompt}>
                  <PromptInput
                    prompt={prompt}
                    setPrompt={setPrompt}
                    showWisGptPreview={showWisGptPreview}
                    isLoading={isLoading}
                    onFullscreen={handleShowFullscreen}
                    onSubmit={handleNewPrompt}
                    isReady={isReady}
                    disabled={!messages || !messages.length || !isReady}
                  />
                </form>
              </div>
            ) : (
              <InitiateScreen
                description={description}
                disabledText={disabledText}
                examplePrompts={examplePrompts}
              />
            )}
          </div>
        </div>
      </>
    );
  }
);
