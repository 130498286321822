// MyComponent.js
import React, { useEffect, useState, useCallback } from "react";

import useSocketResponse from "./useSocketResponse";
import ChatInterface from "./ChatInterface";

export default ({
  conversationId,
  wisgptContextToken,
  prompt,
  requestType,
  params,
  collapsed,
  setCollapsed,
  examplePrompts,
}) => {
  const { sendRequest, response } = useSocketResponse(conversationId);
  const [stream, setStream] = useState([]);
  const [isStreaming, setIsStreaming] = useState(false);
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    setIsStreaming(true);
    sendRequest(requestType, {
      scope: wisgptContextToken,
      ...params,
      userInput: prompt,
    });
  }, [sendRequest]);

  const handleStream = useCallback((token) => {
    setIsStreaming(true);
    setStream((stream) => [...stream, token]);
  }, []);

  const handleStreamEnd = useCallback(() => {
    setIsStreaming(false);
    setMessages((messages) => [
      ...messages,
      {
        role: "assistant",
        content: stream.join(""),
      },
    ]);
    setStream([]);
  }, [stream]);

  const handlePrompt = (prompt) => {
    setMessages((messages) => [
      ...messages,
      {
        role: "user",
        content: prompt,
      },
    ]);
    sendRequest(requestType, {
      scope: wisgptContextToken,
      ...params,
      userInput: prompt,
    });
  };
  useEffect(() => {
    if (response) {
      switch (response.type) {
        case "newtoken":
          handleStream(response.token);
          break;
        case "endtoken":
          handleStreamEnd();
          break;
        case "ctxBlockResult":
          console.log("ctxBlockResult", response.ctxBlockResult);
          break;
        case "error":
          console.error("GPT GATEWAY ERROR", response);
          break;
        default:
          break;
      }
    }
  }, [response]);

  return (
    <ChatInterface
      handlePrompt={handlePrompt}
      messages={messages}
      isStreaming={isStreaming}
      isLoading={isStreaming}
      stream={stream}
      collapsed={collapsed}
      setCollapsed={setCollapsed}
      examplePrompts={examplePrompts}
      params={params}
    />
  );
};
