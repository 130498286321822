import { withHandlers } from "recompose";

export default withHandlers({
  handleCreateAssessmentSession:
    ({
      match: {
        params: { org_id },
      },
      history,
      createAssessmentSession,
      sessionData,
    }) =>
    async () => {
      try {
        const {
          data: {
            createAssessmentSession: { id },
          },
        } = await createAssessmentSession({
          variables: Object.assign({}, sessionData, {
            ...sessionData,
            employee_under_review: {
              type: sessionData.employee_under_review.type,
              obj_id: sessionData.employee_under_review.obj_id,
            },
          }),
          refetchQueries: ["ASSESSMENT_GROUP"],
        });
        history.push(`/${org_id}/assessment/${id}`);
      } catch (err) {
        throw new Error(err);
      }
    },
});
