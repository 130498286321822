import React from 'react'
import classNames from 'classnames'
import Button from 'components/UI/Button'
import Field from 'components/UI/Field'
import ModalTitle from 'components/UI/ModalTitle'
import Modal from 'react-modal'
import compose from './compose'
import RemovePosition from './RemovePosition'
import styles from './Modal.module.scss'

export default compose(
  ({
    history,
    updateModal,
    position,
    deletePosition,
    showDeletePrompt,
    updateDeletePrompt,
    handleChangeName,
    nameValue,
    setNameValue
  }) => (
    <div className={styles.wrapper}>
      <Button bStyle='close' type='button' onClick={() => updateModal('')}>
        <i className='fal fa-times' />
      </Button>
      <div className={classNames(styles.modalWrapper)}>
        <div className={styles.modalHead}>
          <ModalTitle>Edit position</ModalTitle>
        </div>
        <div className={styles.newTeam}>
          <form onSubmit={e => handleChangeName(e)} autoComplete='off'>
            <div className={styles.row}>
              <h5>Position name</h5>
              <div className={styles.smallRow}>
                <Field
                  fullWidth
                  large
                  type='text'
                  name='name'
                  required
                  value={nameValue}
                  onChange={(e) => {
                    setNameValue(e.target.value)
                  }}
                />
                <Button type='submit' bStyle={['primary', 'large', 'green']}>
                  Save
                </Button>
              </div>
            </div>
          </form>
          <div className={styles.removeButton}>
            <Button
              bStyle={['simple', 'icon']}
              type='button'
              onClick={() => updateDeletePrompt(true)}
            >
              <i className='fas fa-trash-alt' />
              Remove position
            </Button>
            <Modal
              closeTimeoutMS={350}
              isOpen={showDeletePrompt}
              onRequestClose={() => updateDeletePrompt(false)}
              shouldCloseOnOverlayClick
              className={styles.modal}
            >
              <RemovePosition
                updateDeletePrompt={updateDeletePrompt}
                deletePosition={deletePosition}
                position={position}
                history={history}
              />
            </Modal>
          </div>
        </div>
      </div>
    </div>
  )
)
