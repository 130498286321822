import React from 'react'
import Score from 'components/Score'
import classNames from 'classnames'
import styles from './index.module.scss'

export default ({
  dim,
  children,
  minScore,
  maxScore,
  colour,
  size,
  highlight,
  labelClass,
  members
}) => {
  const getColour = () => {
    if (colour === 'green' || labelClass === 'green') {
      return styles.green
    }
    if (colour === 'yellow' || labelClass === 'yellow') {
      return styles.yellow
    }
    if (colour === 'grey' || labelClass === 'grey') {
      return styles.grey
    }
    if (colour === 'red' || labelClass === 'red') {
      return styles.red
    }
    return null
  }

  const getSize = () => {
    if (size === 'large') {
      return styles.large
    }
    return styles.small
  }

  const noTeamMembers = members.length === 0 || members.filter(m => m.readyToRender).length === 0;

  if (noTeamMembers) {
    return (
      <div
        className={classNames(
          styles.lineWrapper,
          members.length > 1 && getColour(),
          getSize()
        )}
      >
        <div className={styles.line}>
          <div
            className={classNames(
              styles.distribution,
              highlight && styles.highlight
            )}
            style={{
              display: 'none'
            }}
          />

          {children}
        </div>
      </div>
    )
  }

  return (
    <div
      className={classNames(
        styles.lineWrapper,
        members.length > 1 && getColour(),
        getSize()
      )}
    >
      <div className={styles.line}>
        <div
          className={classNames(
            styles.distribution,
            highlight && styles.highlight
          )}
          style={{
            left: `${minScore}%`,
            width: `calc(${100 - minScore - (100 - maxScore)}% + ${
              size === 'large' ? '57px' : '35px'
            })`
          }}
        />

        <div
          className={classNames(
            styles.teamScore,
            highlight && styles.highlight
          )}
          style={{ left: `${dim.score}%` }}
        >
          <div className={styles.number}>
            <Score score={dim.score} scoreStyle={styles} />
          </div>
        </div>
        {children}
      </div>
    </div>
  )
}
