import React, { useState } from "react";
import Wrapper from "components/UI/Wrapper";
import Title from "components/UI/Title";
import Subtitle from "components/UI/Subtitle";
import Button from "components/UI/Button";
import CompetencyPlaceholder from "components/Placeholders/CompetencyPlaceholder";
import Modal from "react-modal";
import CompetencyModal from "components/Competencies/CompetencyModal";
import CompetencyChart from "components/Charts/CompetencyChart";
import CompetencyInsights from "./Insights";
import TeamMember from "./TeamMember";

import styles from "./Competencies.module.scss";

export default ({ team, teamMember }) => {
  const [showModal, setShowModal] = useState("");

  return (
    <div className={styles.main}>
      <Wrapper>
        <Title text="Competency map" icon="fas fa-clipboard-check" />
        <Subtitle
          text={`The competencies ${teamMember.first_name} is most confident in.`}
        />
        <Subtitle
          verySmall
          text={`
            Look for ways to leverage ${teamMember.first_name}'s strongest competencies and find out what ${teamMember.first_name} could learn from other team members.`}
        />
        {teamMember.competencyGraph &&
        !teamMember.competencies.some((c) => c.score === null) ? (
          <>
            <div className={styles.chart}>
              <CompetencyChart
                member={teamMember.competencyGraph}
                competencies={team.competencyGraph}
                setShowModal={setShowModal}
                type="memberInTeam"
              />
              <div className={styles.avatarsWrap}>
                <TeamMember
                  key={teamMember.id}
                  member={teamMember}
                  userId={teamMember.id}
                  teamCompetencies={team.competencyGraph}
                />
              </div>
              <Modal
                isOpen={showModal !== ""}
                onRequestClose={() => setShowModal("")}
                shouldCloseOnOverlayClick
              >
                <Button
                  bStyle="close"
                  type="button"
                  onClick={() => setShowModal("")}
                >
                  <i className="fal fa-times" />
                </Button>
                <CompetencyModal
                  resource="member"
                  members={team.members}
                  teamMember={teamMember}
                  competencies={team.competencies}
                  setShowModal={setShowModal}
                  currentCompName={showModal}
                />
              </Modal>
            </div>
          </>
        ) : (
          <CompetencyPlaceholder resource="member" />
        )}
        {!teamMember.competencies.some((c) => c.score === null) && (
          <CompetencyInsights team={team} teamMember={teamMember} />
        )}
      </Wrapper>
    </div>
  );
};
