import React from "react";
import { Link } from "react-router-dom";
import Button from "components/UI/Button";
import Wrapper from "components/UI/Wrapper";
import Title from "components/UI/Title";
import Subtitle from "components/UI/Subtitle";
import Candidates from "components/Candidates";

import styles from "./OtherCandidates.module.scss";

export default ({ position, candidateInTeam, team }) => (
  <div className={styles.main}>
    <Wrapper>
      <Title text="Other candidates" icon="fas fa-users" />
      <Subtitle
        text="Invite candidates and learn about how different personalities would
        diversify the team, and how they align on values."
      />
      <div className={styles.candidatesWrapper}>
        <Candidates
          position={Object.assign({}, position, {
            candidates: position.candidates.filter(
              c => c.id !== candidateInTeam.id
            )
          })}
        />
      </div>
      <div className={styles.footerButtonWrapper}>
        <Link to={`/team/${team.id}/position/${position.id}`}>
          <Button bStyle={["primary"]}>
            <i className="fas fa-chevron-left" />
            Back to position
          </Button>
        </Link>
      </div>
    </Wrapper>
  </div>
);
