import React from "react";
import { compose, withProps } from "recompose";
import { withApollo, graphql } from "react-apollo";
import { loader } from "graphql.macro";
import handleLoading from "utils/loader";
import withStore from "utils/withStore";
import FullPage from "components/Loaders/FullPage";
import scrollToTop from "utils/scrollToTop";
import logRead from "utils/logRead";

const TEAM_QUERY = loader("client/Queries/TeamQuery124.graphql");
const TOGGLE_SHOW_TEAM_TOUR = loader("./toggleShowTeamTour.graphql");

export default compose(
  withApollo,
  withStore("viewer"),
  graphql(TEAM_QUERY, {
    options: (props) => {
      return {
        variables: { id: parseInt(props.match.params.teamId, 10) },
      };
    },
  }),
  handleLoading(() => <FullPage />),
  graphql(TOGGLE_SHOW_TEAM_TOUR, {
    name: "toggleShowTeamTour",
  }),
  withProps(({ viewer, data: { team } }) => ({
    isTour: viewer.showTeamTour,
  })),
  scrollToTop,
  logRead({ obj_type: "team", obj_id: "data.team.id" })
);
