import React from "react";
import Button from "components/UI/Button";
import Icon from "components/UI/Icon";

import styles from "./GPTSummaryEmptyState.module.scss";

export default ({
  readyToRender,
  action,
  isLoading,
  title,
  description,
  disabledMessage,
}) => {
  return (
    <div className={styles.wrapper}>
      {!readyToRender && (
        <div className={styles.overlay}>
          <p>
            {disabledMessage
              ? disabledMessage
              : "The surveys have to be completed to use this feature."}
          </p>
        </div>
      )}
      <div className={styles.col}>
        <h4 className={styles.title}>{title}</h4>
      </div>
      <div className={styles.col}>
        <p className={styles.subtitle}>{description}</p>
        <div className={styles.ctaButton}>
          <Button
            bStyle={["purple", "verySmall"]}
            onClick={action}
            disabled={isLoading || !readyToRender}
          >
            {isLoading ? (
              <div className={styles.loading}>
                <i className="far fa-spinner-third" />
              </div>
            ) : (
              <Icon icon="Magicwand" marginRight />
            )}
            {isLoading ? "Generating..." : "Generate summary"}
          </Button>
        </div>
      </div>
    </div>
  );
};
