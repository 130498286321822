import { compose, withState, withHandlers } from 'recompose'
import { graphql } from 'react-apollo'
import { loader } from 'graphql.macro'
const SEND_PROFILE_INCOMPLETE_REMINDER = loader(
  './sendProfileIncompleteReminder.graphql'
)

export default compose(
  graphql(SEND_PROFILE_INCOMPLETE_REMINDER, {
    name: 'sendProfileIncompleteReminderMutation'
  }),
  withState('btnState', 'setBtnState', {
    text: 'fad fa-paper-plane',
    isLoading: false,
    hoverText: 'Send reminder'
  }),
  withHandlers({
    handleSendProfileReminder:
      ({
        setBtnState,
        sendProfileIncompleteReminderMutation,
        user
      }) =>
      () => {
        setBtnState({
          text: 'fas fa-spinner-third',
          isLoading: true,
          disabled: true,
          hoverText: 'Sending...'
        })
        sendProfileIncompleteReminderMutation({
          variables: {
            user_id: user.id
          }
        }).then(({ data: { sendProfileReminder: success } }) => {
          if (success) {
            setBtnState({
              text: 'fas fa-check',
              isLoading: false,
              disabled: true,
              hoverText: 'Reminder sent!'
            })
          } else {
            setBtnState({
              text: 'fas fa-exclamation-triangle',
              isLoading: false,
              disabled: true,
              hoverText: 'Error :('
            })
          }
        })
      }
  })
)
