import { withHandlers } from 'recompose'

export default withHandlers({
  handleRemoveUser: ({
    removeFromOrganizationMutation,
    updateErrorPrompt,
    organization
  }) => (user) => {
    removeFromOrganizationMutation({
      variables: {
        respurceType: user.type,
        resourceId: user.id,
        orgId: organization.id
      }
    }).catch((err) => {
      updateErrorPrompt({
        error: true,
        message: err.message.replace('GraphQL error: Error:', '')
      })
    })
  }
})
