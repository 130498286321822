import { compose, withState, withHandlers } from "recompose";
import { graphql } from "react-apollo";
import { loader } from "graphql.macro";
import renderLoader from "utils/loader";

const VALUESCHART = loader("./ValuesChart16.graphql");

export default compose(
  graphql(VALUESCHART),
  withState("openModal", "updateModal", {}),
  withHandlers({
    determineIfValueIsImportant:
      ({
        data: {
          personalProfile: {
            valuesChart: { values },
          },
        },
        openModal,
      }) =>
      () => {
        if (!openModal.name) return false;
        return (
          values
            .filter((d) => d.parent_id)
            .sort((a, b) => b.score - a.score)
            .slice(0, 3)
            .find((d) => d.name === openModal.name) && true
        );
      },
  }),
  renderLoader(() => null)
);
