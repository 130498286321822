import { compose } from 'recompose'
import { graphql } from 'react-apollo'
import handleLoading from 'utils/handleLoading'
import { loader } from 'graphql.macro'

const POSITION = loader('./PositionQuery31.graphql')

export default compose(
  graphql(POSITION, {
    options: (props) => {
      return { variables: { id: parseInt(props.positionId, 10) } }
    }
  }),
  handleLoading
)
