import React from "react";
import Button from "components/UI/Button";

import styles from "./RemovePosition.module.scss";

export default ({ deleteTeam, team, updateDeletePrompt, history }) => (
  <div className={styles.modalWrapper}>
    <h2 className={styles.title}>
      <i className="fas fa-trash-alt" />
      Are you sure you wish to delete this team?
    </h2>
    <p className={styles.subtitle}>
      All data will be lost and this action cannot be undone.
    </p>

    <div className={styles.buttonGroup}>
      <Button
        bStyle={["primary", "large", "red"]}
        type="button"
        onClick={() =>
          deleteTeam({
            variables: {
              id: team.id,
            },
          }).then((res) => {
            history.push("/teams");
          })
        }
      >
        <i className="fas fa-trash-alt" />
        Yes, remove
      </Button>
      <Button
        bStyle={["secondary", "large"]}
        type="button"
        onClick={() => updateDeletePrompt(false)}
      >
        No, cancel
      </Button>
    </div>
    <p className={styles.dangerous}>
      <i className="fas fa-exclamation-triangle" />
      Be aware that all open positions in this team will also be deleted!
    </p>
  </div>
);
