import React from "react";
import { compose, withHandlers, withProps, withState } from "recompose";

import { graphql } from "react-apollo";
import { loader } from "graphql.macro";

const PROMPT_NEXT_MESSAGE = loader("./promptNextMessage.graphql");

export default compose(
  withState("isLoading", "setIsLoading", false),
  withState("messages", "setMessages", []),
  withState("prompt", "setPrompt", ""),

  withState("timeOut", "setTimeOut", true),
  withProps({
    bottomEl: React.createRef(),
  }),
  graphql(PROMPT_NEXT_MESSAGE, {
    name: "promptNextMessage",
    options: ({ setIsLoading, messages, setMessages, setTimeOut }) => ({
      update: (proxy, { data: { promptNextMessage } }) => {
        if (messages.length) {
          setIsLoading(false);
          setTimeOut(false);
          setMessages(promptNextMessage);
        }

        var container = document.getElementById("messagesWrapper");

        if (container) {
          container.scrollTo({
            top: container.scrollHeight,
            behavior: "smooth",
          });
        }

        return promptNextMessage;
      },
      onError: (proxy, errors) => {
        setTimeOut(true);
        setIsLoading(false);
      },
    }),
  }),
  withHandlers({
    handleNewPrompt:
      ({
        prompt,
        setPrompt,
        promptNextMessage,
        messages,
        setMessages,
        setIsLoading,
        sequence,
        contexts,
        variables,
        bottomEl,
        heapEvent,
      }) =>
      async (e) => {
        if (e) e.preventDefault();

        setMessages([...messages, { role: "user", content: prompt }]);
        setPrompt("");

        var container = document.getElementById("messagesWrapper");

        setTimeout(() => {
          if (container) {
            container.scrollTo({
              top: container.scrollHeight,
              behavior: "smooth",
            });
          }
        }, 50);

        setIsLoading(true);
        promptNextMessage({
          variables: {
            variables,
            sequence,
            contexts,
            messages: [...messages, { role: "user", content: prompt }].map(
              ({ role, content }) => ({ role, content })
            ),
          },
        });

        if (window.heap) {
          window.heap.track(heapEvent);
        }
      },

    handleTryAgain:
      ({
        promptNextMessage,
        messages,
        sequence,
        variables,
        contexts,
        setTimeOut,
        setIsLoading,
      }) =>
      (e) => {
        setTimeOut(false);
        setIsLoading(true);
        promptNextMessage({
          variables: {
            variables,
            sequence,
            contexts,
            messages: [...messages].map(({ role, content }) => ({
              role,
              content,
            })),
          },
        });
      },
  })
);
