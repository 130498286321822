export default ({
  position,
  columns,
  setColumns,
  reorderPositionKanban,
  source,
  destination,
  draggableId,
}) => {
  if (!destination) return;
  if (source.droppableId === destination.droppableId) {
    const moveItem = columns[source.droppableId][source.index];

    const clone = [...columns];

    clone[source.droppableId].splice(source.index, 1);
    clone[source.droppableId].splice(destination.index, 0, moveItem);

    setColumns(clone);
    console.log({ moveItem });
    reorderPositionKanban({
      variables: {
        position_id: position.id,
        cand_id: moveItem.type === "user" ? moveItem.id : null,
        person_id: moveItem.type === "person" ? moveItem.id : null,
        column_id: parseInt(destination.droppableId, 10),
        order: destination.index,
      },
    });
  } else {
    const sourceCol = columns[source.droppableId];
    const draggable = sourceCol.find((item) => item.itemId === draggableId);

    const moveResult = columns.map((col) =>
      col.filter((item) => item.itemId !== draggableId)
    );
    moveResult[destination.droppableId].splice(destination.index, 0, draggable);
    setColumns(moveResult);

    reorderPositionKanban({
      variables: {
        position_id: position.id,
        cand_id: draggable.type === "user" ? draggable.id : null,
        person_id: draggable.type === "person" ? draggable.id : null,
        column_id: parseInt(destination.droppableId, 10),
        order: destination.index,
      },
    });
  }
};
