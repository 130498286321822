import React from 'react'
import {
  compose,
  withHandlers,
  withState,
  withProps,
  lifecycle
} from 'recompose'
import { withRouter } from 'react-router-dom'
import { graphql } from 'react-apollo'
import handleLoading from 'utils/loader'
import { loader } from 'graphql.macro'
import Circles from 'components/Loaders/Circles'

const USER_QUERY = loader('./User2.graphql')
const REMOVE_AVATAR = loader('./removeAvatar.graphql')

export default compose(
  withRouter,
  graphql(USER_QUERY),
  graphql(REMOVE_AVATAR, { name: 'removeAvatarMutation' }),
  withState('fileIsDragging', 'setFileIsDragging', false),
  withState('avatarFile', 'setAvatarFile', ''),
  withState('avatarEditor', 'toggleAvatarEditor', false),
  withProps({
    avatarEditorRef: React.createRef(),
    fileDropRef: React.createRef()
  }),

  withHandlers({
    removeAvatar: ({ removeAvatarMutation }) => () => {
      removeAvatarMutation()
    },
    handleUploadAvatar: ({ toggleAvatarEditor, setAvatarFile }) => ({
      target: {
        validity,
        files: [File]
      }
    }) => {
      if (validity.valid && File) {
        const reader = new FileReader()
        reader.onload = () => {
          setAvatarFile(reader.result)
        }
        reader.readAsDataURL(File)
        toggleAvatarEditor(true)
      }
    },
    handleFileDragEnter: ({ setFileIsDragging }) => (e) => {
      e.preventDefault()
      e.stopPropagation()
      setFileIsDragging(true)
    },
    handleFileDragOver: ({ setFileIsDragging }) => (e) => {
      e.preventDefault()
      e.stopPropagation()
      setFileIsDragging(true)
    },
    handleFileDragLeave: ({ setFileIsDragging }) => (e) => {
      e.preventDefault()
      e.stopPropagation()
      setFileIsDragging(false)
    },
    handleFileDrop: ({
      setAvatarFile,
      toggleAvatarEditor,
      setFileIsDragging
    }) => (e) => {
      e.preventDefault()
      e.stopPropagation()
      const validImageTypes = ['image/gif', 'image/jpeg', 'image/png']
      const [File] = e.dataTransfer.files
      if (validImageTypes.includes(File.type)) {
        const reader = new FileReader()
        reader.onload = () => {
          setAvatarFile(reader.result)
        }
        reader.readAsDataURL(File)
        toggleAvatarEditor(true)
        setFileIsDragging(false)
      }
    }
  }),
  handleLoading(Circles),
  lifecycle({
    componentDidMount() {
      this.props.fileDropRef.current.addEventListener(
        'dragenter',
        this.props.handleFileDragEnter
      )
      this.props.fileDropRef.current.addEventListener(
        'dragover',
        this.props.handleFileDragOver
      )
      this.props.fileDropRef.current.addEventListener(
        'dragleave',
        this.props.handleFileDragLeave
      )
      this.props.fileDropRef.current.addEventListener(
        'drop',
        this.props.handleFileDrop
      )
    },
    componentWillUnmount() {
      this.props.fileDropRef.current.removeEventListener(
        'dragenter',
        this.props.handleFileDragEnter
      )
      this.props.fileDropRef.current.removeEventListener(
        'dragover',
        this.props.handleFileDragOver
      )
      this.props.fileDropRef.current.removeEventListener(
        'dragleave',
        this.props.handleFileDragLeave
      )
      this.props.fileDropRef.current.removeEventListener(
        'drop',
        this.props.handleFileDrop
      )
    }
  })
)
