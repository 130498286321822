import { compose } from "recompose";
import { graphql } from "react-apollo";
import { loader } from "graphql.macro";
import { withRouter } from "react-router-dom";
import withStore from "utils/withStore";
import handleSubmit from "./handleSubmit";

const CREATE_NEW_TEAM = loader("./createNewTeam2.graphql");
const ORGANIZATION_TEAMS = loader(
  "client/Queries/OrganizationTeamsAndPositions.graphql"
);

export default compose(
  withRouter,
  withStore("viewer"),
  graphql(CREATE_NEW_TEAM, {
    name: "createNewTeam",
    options: ({ organization }) => ({
      update: (proxy, { data: { createTeam } }) => {
        const data = proxy.readQuery({
          query: ORGANIZATION_TEAMS,
          variables: {
            id: organization.id,
          },
        });
        data.organization.teams.push(createTeam);

        proxy.writeQuery({
          query: ORGANIZATION_TEAMS,
          variables: {
            id: organization.id,
          },
          data,
        });
      },
    }),
  }),
  handleSubmit
);
