import React from 'react'
import ValuesChart from 'components/Charts/Values'
import ValuesModal from 'components/Modals/Value'
import ValuesPlaceholder from 'components/Placeholders/ValuesPlaceholder'
import compose from './compose'
import styles from './Chart.module.scss'

export default compose(
  ({ user, openModal, updateModal, determineIfValueIsImportant }) => {
    if (user.personalProfile.valuesChart.readyToRender) {
      return (
        <div className={styles.wrap}>
          <ValuesChart
            data={user.personalProfile.valuesChart}
            highlight={user.personalProfile}
            valuesChart={user.personalProfile.valuesChart}
            values={user.personalProfile.valuesChart.values}
            updateModal={updateModal}
            resource="member"
          />
          <ValuesModal
            resource="orgUser"
            highlight={user.personalProfile.valuesChart}
            valueGroups={user.personalProfile.valuesChart.valueGroups}
            values={user.personalProfile.valuesChart.values}
            dim={openModal}
            updateModal={updateModal}
            isImportant={determineIfValueIsImportant()}
            member={user}
          />
        </div>
      )
    }
    return <ValuesPlaceholder resource="personal" />
  }
)
