import React from "react";
import Dimensions from "components/Dimensions";

export default ({ team }) => {
  if (
    team.readyToRender &&
    team.members.some((m) => m.readyToRender) &&
    team.members.length > 0
  ) {
    const teamBig5 = team.personality.filter((dim) => !dim.parentGroup);

    return <Dimensions team={team} data={teamBig5} resource="team" />;
  }
  return null;
};
