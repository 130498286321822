import React from 'react'
import classNames from 'classnames'

import styles from './Container.module.scss'

export default ({
  children, header, size, cStyle
}) => (
  <div
    className={classNames(
      styles.wrapper,
      header && styles[header],
      size && styles[size],
      typeof cStyle === 'object' && cStyle.map(c => styles[c])
    )}
  >
    {children}
  </div>
)
