import React, {
  useState,
  useRef,
  useEffect,
  useContext,
  createRef,
} from "react";
import classNames from "classnames";
import Button from "components/UI/Button";
import PromptInput from "./PromptInput";
import Message from "./Message";

import styles from "./ChatInterface.module.scss";
import Icon from "components/UI/Icon";
import useSocketResponse from "components/GPTGateway/useSocketResponse";

import IncomingMessage from "./IncomingMessage";

export default ({
  examplePrompts,
  isLoading,
  isStreaming,
  stream,
  requestId,
  messages,
  handlePrompt,
}) => {
  const [isBottom, setIsBottom] = useState(true);
  const [examplePromptsOpen, setExamplePromptsOpen] = useState(true);
  const [filteredExamplePrompts, setFilteredExamplePrompts] = useState(
    examplePrompts || []
  );

  const scrollableRef = useRef();

  const bottomEl = createRef();

  // When user scrolls to bottom, the chat always scrolls bottom when new text appears
  // and when the user scrolls up, the chat does not automatically scroll to the bottom to make for easier reading.

  useEffect(() => {
    if (isBottom && bottomEl.current) {
      // bottomEl.current.scrollIntoView();
      var bottomElement = document.getElementById("messagesWrapper");
      bottomElement.scrollTop = bottomElement.scrollHeight;
    }
  }, [isBottom, bottomEl]);

  const onScroll = () => {
    if (scrollableRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollableRef.current;
      const isNearBottom = scrollTop + clientHeight >= scrollHeight;

      if (isNearBottom) {
        setIsBottom(true);
      } else {
        setIsBottom(false);
      }
    }
  };

  useEffect(() => {
    const scrollableElement = scrollableRef.current;

    if (scrollableElement) {
      scrollableElement.addEventListener("scroll", onScroll);

      // Clean-up
      return () => {
        scrollableElement.removeEventListener("scroll", onScroll);
      };
    }
  }, []);

  useEffect(() => {
    if (examplePrompts && examplePrompts.length > 0) {
      setFilteredExamplePrompts(examplePrompts);
    }
  }, [examplePrompts]);

  return (
    <>
      <div className={classNames(styles.interfaceWrapper)}>
        <div className={styles.interface}>
          <div className={styles.upperPart}>
            <h5 className={styles.title}>
              <span className={styles.icon}>
                <Icon icon="Bulb" />
              </span>
              WisGPT Insights {requestId}
            </h5>
            <div
              id="messagesWrapper"
              className={classNames(
                styles.messagesWrapper,
                examplePromptsOpen && styles.examplePromptsOpen
              )}
              ref={scrollableRef}
            >
              {messages.map(({ role, content, isLoading }, index) => {
                return (
                  <Message
                    role={role}
                    content={content}
                    isLoading={isLoading}
                    key={`message-${index}`}
                  />
                );
              })}
              {isStreaming && <IncomingMessage stream={stream} />}

              <div ref={bottomEl}></div>
            </div>
          </div>
          <div className={styles.promptWrapper}>
            {filteredExamplePrompts.length > 0 && (
              <div
                className={classNames(
                  styles.drawerButton,
                  examplePromptsOpen && styles.open
                )}
              >
                <Button
                  bStyle={["none"]}
                  onClick={() => {
                    if (examplePromptsOpen) {
                      setExamplePromptsOpen(false);
                    } else {
                      setExamplePromptsOpen(true);
                    }
                  }}
                >
                  <div
                    className={classNames(
                      styles.icon,
                      examplePromptsOpen && styles.open
                    )}
                  >
                    <Icon icon="Chevron" />
                  </div>
                </Button>
              </div>
            )}
            {filteredExamplePrompts.length > 0 && (
              <div
                className={classNames(
                  styles.examplePrompts,
                  examplePromptsOpen && styles.open
                )}
              >
                {filteredExamplePrompts.map((examplePrompt, index) => (
                  <Button
                    key={"examplePrompt-" + index}
                    bStyle={["none"]}
                    disabled={isLoading || !messages || !messages.length}
                    onClick={() => {
                      setTimeout(() => {
                        // handleNewPrompt();
                        handlePrompt(examplePrompt);
                        setIsBottom(true);
                      }, 10);
                      setTimeout(() => {
                        setExamplePromptsOpen(false);
                      }, 150);
                    }}
                  >
                    <p>{examplePrompt}</p>
                  </Button>
                ))}
              </div>
            )}
            <PromptInput
              handlePrompt={handlePrompt}
              isLoading={isLoading}
              onSubmit={handlePrompt}
            />
          </div>
        </div>
      </div>
    </>
  );
};
