import React, { useState } from "react"
import Modal from "react-modal"
import Button from "components/UI/Button"
import Icon from "components/UI/Icon"
import classNames from "classnames"
import { Player, BigPlayButton } from "video-react"

import styles from "./LearningCenter.module.scss"

const LinkHandler = ({ type, thumbnail, url, children, setModal }) => {
  if (type === "video") {
    return (
      <Button
        bStyle={["none"]}
        onClick={() => setModal({ thumbnail: thumbnail, url: url })}
      >
        {children}
      </Button>
    )
  } else {
    return (
      <a target="_blank" href={url}>
        {children}
      </a>
    )
  }
}

export default ({ cards }) => {
  const [modal, setModal] = useState(false)
  return (
    <div className={styles.cards}>
      {cards.map((card) => (
        <LinkHandler
          key={"Link-" + card.title}
          type={card.type}
          thumbnail={card.thumbnail}
          url={card.url}
          setModal={setModal}
        >
          <div className={styles.card}>
            {card.type !== "video" ? (
              <div className={styles.icon}>
                <i className="fas fa-external-link-alt" />
              </div>
            ) : (
              <div className={styles.icon}>
                <Icon icon="Play" />
              </div>
            )}
            <div className={classNames(styles.image, styles.blue)}>
              <img src={card.thumbnail} />
            </div>
            <div className={styles.bottom}>
              <p className={styles.type}>{card.type}</p>
              <h5>{card.title}</h5>
            </div>
          </div>
        </LinkHandler>
      ))}
      <Modal
        isOpen={!!modal}
        onRequestClose={() => setModal(false)}
        shouldCloseOnOverlayClick
        className="videoModal"
      >
        <Player playsInline poster={modal.thumbnail} src={modal.url}>
          <BigPlayButton position="center" />
        </Player>
      </Modal>
    </div>
  )
}
