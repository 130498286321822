import React from "react";
import classNames from "classnames";
import Popup from "reactjs-popup";
import { Link } from "react-router-dom";
import Timestamp from "react-timestamp";
import UserAvatar from "components/UserAvatar";
import Button from "components/UI/Button";
import { Form } from "react-final-form";
import Icon from "components/UI/Icon";
import { printoutRoute } from "client/authRoutes";
import EllipsisButton from "components/UI/EllipsisButton";
import DropDown from "components/UI/DropDown";
import { ReactComponent as WhiteLogo } from "images/white-logo.svg";
import FormInfo from "components/PositionTemplates/UseTemplatePopup";

import styles from "./Template.module.scss";

export default ({
  orgId,
  moreData,
  template,
  personal,
  account,
  updateModal,
  setRemoveModal,
  handleCreatePosition,
  selectedTeam,
  setSelectedTeam,
  setStep,
  view,
}) => {
  return (
    <div
      key={template.id}
      className={classNames(
        styles.template,
        !template.isGlobal && styles.personal,
        styles[view]
      )}
    >
      <div className="dropdownWrapper" id="dropdownMenu">
        <Popup
          keepTooltipInside="body"
          trigger={
            <div className={styles.ellipsisButton}>
              <EllipsisButton />
            </div>
          }
          position="bottom right"
          arrow={false}
        >
          <DropDown>
            <li>
              <div className="dropdownWrapper" id="dropdownMenu">
                <Popup
                  keepTooltipInside="body"
                  trigger={
                    <Button type="button" bStyle={["none"]}>
                      <Icon icon="Plus" marginRight />
                      Use scorecard
                    </Button>
                  }
                  position="bottom right"
                  arrow={false}
                >
                  {(close) => (
                    <DropDown>
                      <Form
                        autoComplete="new-password"
                        onSubmit={handleCreatePosition}
                        render={({ handleSubmit, pristine, values }) => (
                          <div className={styles.popup}>
                            <form onSubmit={handleSubmit} autoComplete="off">
                              <FormInfo
                                data={moreData}
                                selectedTeam={selectedTeam}
                                setSelectedTeam={setSelectedTeam}
                                selectedTemplate={template.id}
                                setStep={setStep}
                              />
                              <div className={styles.buttonGroup}>
                                <Button
                                  bStyle={["primary", "small"]}
                                  type="submit"
                                  disabled={
                                    pristine ||
                                    !values.name ||
                                    !selectedTeam ||
                                    selectedTeam === -1
                                  }
                                >
                                  Continue
                                </Button>
                                <Button
                                  bStyle={["secondary", "small"]}
                                  type="button"
                                  onClick={close}
                                >
                                  Cancel
                                </Button>
                              </div>
                            </form>
                          </div>
                        )}
                      />
                    </DropDown>
                  )}
                </Popup>
              </div>
            </li>
            <li>
              <a
                href={`${printoutRoute}/printout/scorecard/${orgId}/${
                  template.id
                }/${template.title.replace(" ", "_")}_hiring_scorecard.pdf`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button bStyle={["none"]}>
                  <Icon icon="Print" marginRight />
                  Print PDF
                </Button>
              </a>
            </li>
            {personal && account.adminRights && (
              <li>
                <Link to={`/${orgId}/scorecard/${template.id}/edit`}>
                  <Button type="button" bStyle={["none"]}>
                    <Icon icon="Edit" marginRight />
                    Edit scorecard
                  </Button>
                </Link>
              </li>
            )}
            {personal && account.adminRights && (
              <li>
                <Button
                  type="button"
                  bStyle={["none"]}
                  onClick={() => setRemoveModal(template.id)}
                >
                  <Icon icon="Delete" marginRight />
                  Delete scorecard
                </Button>
              </li>
            )}
          </DropDown>
        </Popup>
      </div>
      <div
        className={classNames(
          styles.buttonWrapper,
          template.isGlobal && styles.global
        )}
      >
        <div className={styles.background}>
          <WhiteLogo />
        </div>
        <Button bStyle={["none"]} onClick={() => updateModal(template.id)}>
          <div className={styles.topAndMiddle}>
            <div className={styles.top}>
              {template.category && (
                <p
                  className={classNames(
                    styles.label,
                    styles[template.category.color]
                  )}
                >
                  {template.category.name}
                </p>
              )}
              {template.stage && (
                <p
                  className={classNames(
                    styles.label,
                    styles["border-" + template.category.color]
                  )}
                >
                  {template.stage.name}
                </p>
              )}
            </div>
            <div
              className={classNames(
                styles.middle,
                !template.category && styles.noCategory
              )}
            >
              <h4 className={styles.name}>{template.title}</h4>
            </div>
          </div>
          <div className={styles.bottom}>
            <div className={styles.avatar}>
              <UserAvatar member={template.createdBy} size="small" />
            </div>
            <div className={styles.info}>
              <h5 className={styles.name}>{`${template.createdBy.name}`}</h5>
              {template.isGlobal ? (
                <p className={styles.description}>{template.createdBy.title}</p>
              ) : (
                <p className={styles.description}>
                  {"Updated "}
                  <Timestamp
                    relative
                    date={new Date(template.updatedAt)}
                    autoUpdate
                  />
                </p>
              )}
            </div>
          </div>
        </Button>
      </div>
    </div>
  );
};
