import {
  ACTIVATE_ONBOARDING,
  SET_ONBOARDING,
  SET_ONBOARDING_STEP,
  NEXT_ONBOARDING_STEP,
  PREV_ONBOARDING_STEP,
  SKIP_ONBOARDING_STEP,
  FINISH_ONBOARDING,
  SET_CASE_STUDY,
  TOGGLE_SURVEYS_REMINDER
} from "store/actionTypes";

const initialState = {
  active: false,
  onboarding: null,
  currentStep: 0,
  currentSubstep: 0,
  caseStudy: null,
  surveysReminderShown: false,
  positionTemplate: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ONBOARDING:
      const { payload } = action

      if(payload.positionTemplate && !state.positionTemplate ) {
        const lastStepIndex = payload.onboarding.length - 1
        const lastSubstepIndex = payload.onboarding[lastStepIndex].blocks.length - 1
        const buttonUrl = `${payload.onboarding[lastStepIndex].blocks[lastSubstepIndex].buttonUrl}?positionTemplate=${payload.positionTemplate}`
        payload.onboarding[lastStepIndex].blocks[lastSubstepIndex].buttonUrl = buttonUrl
      }
      return Object.assign({}, state, {
        ...payload
      });
    case ACTIVATE_ONBOARDING:
      return Object.assign({}, state, {
        active: true,
        currentStep:
          action.payload && action.payload.step
            ? action.payload.step
            : state.currentStep
      });

    case SET_CASE_STUDY:
      return Object.assign({}, state, {
        caseStudy: action.payload
      });

    case SET_ONBOARDING_STEP:
      return Object.assign({}, state, {
        currentStep: action.payload.currentStep,
        currentSubstep: action.payload.currentSubstep
      });

    case NEXT_ONBOARDING_STEP:
      if (
        !!state.onboarding[state.currentStep].blocks[state.currentSubstep + 1]
      ) {
        return Object.assign({}, state, {
          currentSubstep: state.currentSubstep + 1
        });
      }
      return Object.assign({}, state, {
        currentStep: state.currentStep + 1,
        currentSubstep: 0
      });
    case PREV_ONBOARDING_STEP:
      if (
        !!state.onboarding[state.currentStep].blocks[state.currentSubstep - 1]
      ) {
        return Object.assign({}, state, {
          currentSubstep: state.currentSubstep - 1
        });
      }
      return Object.assign({}, state, {
        currentStep: state.currentStep - 1,
        currentSubstep:
          state.onboarding[state.currentStep - 1].blocks.length - 1
      });
    case SKIP_ONBOARDING_STEP:
      return Object.assign({}, state, {
        currentStep: state.currentStep + 1,
        currentSubstep: 0
      });
    case TOGGLE_SURVEYS_REMINDER:
      return Object.assign({}, state, {
        surveysReminderShown: !state.surveysReminderShown
      });
    case FINISH_ONBOARDING:
      return Object.assign({}, state, {
        active: false,
        currentStep: 1
      });
    default:
      return state;
  }
};
