import { compose, withState, withHandlers } from "recompose";
import withStore from "utils/withStore";
import { withRouter } from "react-router-dom";

export default compose(
  withRouter,
  withStore("onboarding"),
  withState("timer", "setTimer", Date.now()),
  withState("columns", "setColumns", (props) => {
    const columns = [[], [], [], []];
    const colKeys = [
      [0, 1, 2],
      [3, 4, 5],
      [6, 7, 8],
    ];

    if (props.answers.length > 0) {
      props.answers.forEach((comp, key) => {
        const colKey =
          key > 8
            ? 3
            : colKeys.findIndex((col) => col.some((cKey) => cKey === key));
        columns[colKey].push({
          id: `item-${comp.question_dim_id}`,
          title: props.survey.survey_rows.find(
            (sr) => sr.dimension.answer.question_dim_id === comp.question_dim_id
          ).dimension.name,
          desc: props.survey.survey_rows.find(
            (sr) => sr.dimension.answer.question_dim_id === comp.question_dim_id
          ).dimension.description_tid,
        });
      });
    } else {
      props.survey.survey_rows.forEach((comp) => {
        columns[3].push({
          id: `item-${comp.question_dim_id}`,
          title: comp.dimension.name,
          desc: comp.dimension.description_tid,
        });
      });
    }
    return columns;
  }),
  withHandlers({
    handleSetAnswer:
      ({
        survey,
        setAnswers,
        answers,
        currentRowId,
        setCurrentRowId,
        match,
        location,
        timer,
        setTimer,
      }) =>
      (answer) => {
        let currentRowWithAnswer;
        if (survey.id === 32) {
          const {
            state: {
              params: { obj_type_id, obj_id, subject_type_id, subject_id },
            },
          } = location;
          currentRowWithAnswer = {
            obj_id,
            obj_type_id,
            subject_type_id,
            subject_id,
            survey_row_id: survey.survey_rows[currentRowId].survey_row_id,
            question_dim_id: survey.survey_rows[currentRowId].question_dim_id,
            duration_ms: Math.abs(timer - Date.now()),
            answer,
          };
        } else {
          currentRowWithAnswer = {
            obj_type_id: 2,
            subject_type_id: match.params.teamId ? 3 : null,
            subject_id: match.params.teamId || null,
            survey_row_id: survey.survey_rows[currentRowId].survey_row_id,
            question_dim_id: survey.survey_rows[currentRowId].question_dim_id,
            duration_ms: Math.abs(timer - Date.now()),
            answer,
          };
        }

        if (answers[currentRowId]) {
          answers[currentRowId] = currentRowWithAnswer;
          setAnswers(answers);
        } else {
          setAnswers([...answers, currentRowWithAnswer]);
        }

        setCurrentRowId(
          currentRowId + 1 === survey.survey_rows.length
            ? currentRowId
            : currentRowId + 1
        );
        setTimer(Date.now());
      },
    handleSave:
      ({ answers, survey, timer, handleFinishSurvey, columns }) =>
      (competencyAnswers) => {
        handleFinishSurvey(
          competencyAnswers.map((a) => {
            return { ...a, duration_ms: Date.now() - timer };
          })
        );
      },
    handleDiscard:
      ({ history, onboarding }) =>
      () => {
        if (onboarding.active) {
          history.push("/onboarding");
        }
        history.goBack();
      },
  })
);
