import React from "react";
import Button from "components/UI/Button";
import EditorComponent from "./EditorComponent";
import compose from "./compose";

import styles from "./AboutPosition.module.scss";

export default compose(
  ({ position, summary, setSummary, handleSaveSummary, closeModal }) => (
    <div className={styles.main}>
      <div className={styles.wrapper}>
        <h2>
          <span className={styles.checkWrapper}>
            <i className="far fa-check" />
          </span>
          Key objectives
        </h2>
        <p>
          Describe what should be achieved or be different in 12 months to
          consider the hire successful. Write the key objectives as measurable
          results, not just a list of activities. For inspiration, take a look
          at the examples{" "}
          <a
            target="_blank"
            href="https://support.wisnio.com/en/articles/4769000-stage-2-position-mapping-tools#h_bb9adaf8c5"
          >
            here
          </a>
          .
        </p>
        <div className={styles.content}>
          <EditorComponent
            content={position.summary}
            value={summary}
            handleSave={handleSaveSummary}
            position={position}
            handleTextEditorChange={setSummary}
            editBtnTitle="Edit text"
          />
        </div>
      </div>
    </div>
  )
);
