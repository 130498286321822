import React from 'react'
import UserAvatar from 'components/UserAvatar'
import styles from './MemberAvatar.module.scss'

export default ({ member, setMemberHover }) => {
  if (member.valuesChart.readyToRender) {
    return (
      <>
        <div
          className={styles.avatarPosition}
          style={{
            top: `calc(${member.valuesChart.avatarPosition.y}% - 13px)`,
            left: `calc(${member.valuesChart.avatarPosition.x}% - 13px)`
          }}
          onMouseEnter={() => setMemberHover(member)}
          onMouseLeave={() => setMemberHover()}
        >
          <div className={styles.avatarWrapper}>
            <UserAvatar member={member} size='small' showName />
          </div>
        </div>
      </>
    )
  }
  return null
}
