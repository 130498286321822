import { compose, withHandlers } from 'recompose'

export default compose(

  withHandlers({
    handleUpdateList: ({
      require,
      updateList,
      list
    }) => ({user, key, value}) => {
      updateList(
        list.map(i => {
          if (i.email === user.email) {
            i[require.key][key] = parseInt(value, 10)
          }
          return i
        })
      )
    }
  })
)
