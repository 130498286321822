import React from 'react'
import Popup from 'reactjs-popup'
import Button from 'components/UI/Button'
import EllipsisButton from 'components/UI/EllipsisButton'
import classNames from 'classnames'
import DropDown from 'components/UI/DropDown'
import getAvatarColor from 'utils/orgAvatarColor'
import Select from 'components/UI/Select'
import styles from './Row.module.scss'
import compose from './compose'
import Modal from 'react-modal'
import RemoveYourselfFromOrgModal from 'components/Modals/RemoveYourselfFromOrgModal'

const ContractTypeSelect = ({ organization, handleRoleChange }) => {
  const roles = ['None', 'Member', 'Admin', 'Owner', 'Candidate']
  return (
    <Select
      onChange={(e) => handleRoleChange({ roleType: e.target.value })}
      value={organization.role}
      noBorder
      cStyle={organization.role}
    >
      {roles.map((role, i) => {
        return (
          <option
            key={`role-${i}`}
            value={role}
            disabled={['Candidate'].includes(role)}
          >
            {role}
          </option>
        )
      })}
    </Select>
  )
}

export default compose(
  ({
    confirmModal,
    setConfirmModal,
    organization,
    updateDeletePrompt,
    handleRoleChange,
    setRole
  }) => (
    <>
      <tr className={styles.row}>
        <td className={styles.avatarCol}>
          <div
            className={classNames(
              styles.avatar,
              styles[getAvatarColor(organization)]
            )}
          >
            <i className="fas fa-building" />
          </div>
          <div className={styles.nameAndEmail}>
            {organization.name && (
              <p className={styles.name}>{organization.name}</p>
            )}
          </div>
        </td>
        <td className={styles.members}>
          <p>
            {`${
              organization.members > 0 ? organization.members : 'No'
            } ${organization.members === 1 ? 'member' : 'members'}`}
          </p>
        </td>
        <td className={styles.teams}>
          <p>
            {`${organization.teams > 0 ? organization.teams : 'No'} ${
              organization.teams === 1 ? 'team' : 'teams'
            }`}
          </p>
        </td>
        <td className={styles.positions}>
          <p>
            {`${
              organization.positions > 0
                ? organization.positions
                : 'No'
            } ${
              organization.positions === 1
                ? 'open position'
                : 'open positions'
            }`}
          </p>
        </td>
        <td className={styles.role}>
          <ContractTypeSelect
            organization={organization}
            handleRoleChange={handleRoleChange}
          />
        </td>
        <td className={styles.lastCol}>
          <div className="dropdownWrapper" id="dropdownMenu">
            <Popup
              keepTooltipInside="body"
              trigger={
                <div className={styles.avatarWrapper}>
                  <EllipsisButton />
                </div>
              }
              position="bottom right"
            >
              <DropDown>
                <li>
                  <Button
                    type="button"
                    bStyle={['none']}
                    onClick={() =>
                      updateDeletePrompt(organization.id)
                    }
                  >
                    <i className="fas fa-trash-alt" />
                    Delete organisation
                  </Button>
                </li>
              </DropDown>
            </Popup>
          </div>
        </td>
      </tr>
      <Modal
        isOpen={!!confirmModal}
        onRequestClose={() => setConfirmModal(false)}
        shouldCloseOnOverlayClick
        className={styles.modal}
      >
        <RemoveYourselfFromOrgModal
          organization={organization}
          setConfirmModal={setConfirmModal}
          handleLeaveOrg={setRole}
        />
      </Modal>
    </>
  )
)
