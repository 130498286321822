import { compose, withHandlers, withState } from "recompose";
import { graphql } from "react-apollo";
import { loader } from "graphql.macro";
import { withRouter } from "react-router-dom";
import withStore from "utils/withStore";

const CANDIDATE_QUERY = loader("client/Queries/CandidateQuery198.graphql");
const UPSERT_CANDIDATE_NOTE = loader("./upsertCandidateNote.graphql");
const DELETE_CANDIDATE_NOTE = loader("./deleteCandidateNote.graphql");

export default compose(
  withRouter,
  withStore("viewer"),
  withState("newNote", "setNewNote", false),
  withState("comment", "setComment", ""),
  withState("editNote", "setEditNote", false),
  graphql(UPSERT_CANDIDATE_NOTE, {
    name: "upsertCandidateNote",
    options: ({
      match: {
        params: { candidateId, teamId, positionId, orgId, candidateType },
      },
    }) => {
      return {
        update: (proxy, { data: { upsertCandidateNote } }) => {
          const candidate = proxy.readQuery({
            query: CANDIDATE_QUERY,
            variables: {
              user_id:
                candidateType === "candidate"
                  ? parseInt(candidateId, 10)
                  : null,
              person_id:
                candidateType === "person" ? parseInt(candidateId, 10) : null,
              team_id: parseInt(teamId, 10),
              position_id: parseInt(positionId, 10),
              org_id: parseInt(orgId, 10),
            },
          });

          if (
            candidate.candidateInTeam.notes.filter(
              (f) => f.id === upsertCandidateNote.id
            ).length === 0
          ) {
            candidate.candidateInTeam.notes = [
              ...candidate.candidateInTeam.notes,
              upsertCandidateNote,
            ];
          }

          proxy.writeQuery({
            query: CANDIDATE_QUERY,
            variables: {
              user_id:
                candidateType === "candidate"
                  ? parseInt(candidateId, 10)
                  : null,
              person_id:
                candidateType === "person" ? parseInt(candidateId, 10) : null,
              team_id: parseInt(teamId, 10),
              position_id: parseInt(positionId, 10),
              org_id: parseInt(orgId, 10),
            },
            data: candidate,
          });
        },
      };
    },
  }),
  graphql(DELETE_CANDIDATE_NOTE, {
    name: "deleteCandidateNote",
    options: ({
      match: {
        params: { candidateId, teamId, positionId, orgId, candidateType },
      },
    }) => {
      return {
        update: (proxy, { data: { deleteCandidateNote } }) => {
          const candidate = proxy.readQuery({
            query: CANDIDATE_QUERY,
            variables: {
              user_id:
                candidateType === "candidate"
                  ? parseInt(candidateId, 10)
                  : null,
              person_id:
                candidateType === "person" ? parseInt(candidateId, 10) : null,
              team_id: parseInt(teamId, 10),
              position_id: parseInt(positionId, 10),
              org_id: parseInt(orgId, 10),
            },
          });

          candidate.candidateInTeam.notes = [
            ...candidate.candidateInTeam.notes.filter(
              (figure) => figure.id !== deleteCandidateNote
            ),
          ];

          proxy.writeQuery({
            query: CANDIDATE_QUERY,
            variables: {
              user_id:
                candidateType === "candidate"
                  ? parseInt(candidateId, 10)
                  : null,
              person_id:
                candidateType === "person" ? parseInt(candidateId, 10) : null,
              team_id: parseInt(teamId, 10),
              position_id: parseInt(positionId, 10),
              org_id: parseInt(orgId, 10),
            },
            data: candidate,
          });
        },
      };
    },
  }),
  withHandlers({
    handleUpdateCandidateNote:
      ({ upsertCandidateNote, candidate, match }) =>
      async (note_id, text) => {
        try {
          const type = match.params.candidateType;
          await upsertCandidateNote({
            variables: {
              id: note_id,
              cand_id: type === "candidate" ? candidate.id : null,
              person_id: type === "person" ? candidate.id : null,
              position_id: candidate.position.id,
              text: text,
            },
          });
        } catch (err) {
          throw new Error(err);
        }
      },
    handleDeleteCandidateNote:
      ({ deleteCandidateNote, candidate }) =>
      (note_id) => {
        try {
          deleteCandidateNote({
            variables: {
              id: note_id,
              position_id: candidate.position.id,
            },
          });
        } catch (err) {
          throw new Error(err);
        }
      },
  })
);
