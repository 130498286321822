import React from "react";
import classNames from "classnames";
import Invite from "components/Invite";
import Button from "components/UI/Button";
import ModalTitle from "components/UI/ModalTitle";
import compose from "./compose";

import styles from "./Modal.module.scss";

export default compose(
  ({
    match: {
      params: { org_id },
    },
    assessmentSession,
    updateModal,
    handleAddReviewersToAssessmentSession,
    isLoading,
    setOverlayClose,
  }) => (
    <div className={styles.wrapper}>
      <Button bStyle="close" type="button" onClick={() => updateModal("")}>
        <i className="fal fa-times" />
      </Button>
      <div className={classNames(styles.modalWrapper)}>
        <div className={styles.modalHead}>
          <ModalTitle>Add reviewers</ModalTitle>
          <p>Use the form below to add reviewers to this session.</p>
        </div>
        <Invite
          validationCase="assessmentSession"
          org_id={parseInt(org_id, 10)}
          assessment_session_id={assessmentSession.id}
          searchEnabled={true}
          updateModal={updateModal}
          setOverlayClose={setOverlayClose}
          handleSubmit={({ list }) =>
            handleAddReviewersToAssessmentSession(list)
          }
          color="green"
          isLoading={isLoading}
          askForName
        />
      </div>
    </div>
  )
);
