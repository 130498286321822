import React from "react";
import { compose, branch, renderComponent } from "recompose";
import { graphql } from "react-apollo";
import renderLoader from "utils/loader";
import { loader } from "graphql.macro";
import CardLoader from "components/Loaders/CardLoader";

import styles from "../Teams.module.scss";

const ORGANIZATION_TEAMS_AND_POSITIONS = loader(
  "client/Queries/OrganizationTeamsAndPositions.graphql"
);

const Skeleton = () => (
  <div className={styles.skeleton}>
    <div className={styles.cardLoaderWrapper}>
      <CardLoader />
    </div>
    <div className={styles.cardLoaderWrapper}>
      <CardLoader />
    </div>
    <div className={styles.cardLoaderWrapper}>
      <CardLoader />
    </div>
  </div>
);

export default compose(
  branch(({ selectedOrg }) => !selectedOrg.id, renderComponent(Skeleton)),
  graphql(ORGANIZATION_TEAMS_AND_POSITIONS, {
    options: ({ selectedOrg }) => {
      return {
        variables: { id: selectedOrg.id },
      };
    },
  }),
  renderLoader(Skeleton)
);
