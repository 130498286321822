import React from "react";
import classNames from "classnames";
import Button from "components/UI/Button";
import UserAvatar from "components/UserAvatar";

import compose from "./compose";

import styles from "./CustomSelect.module.scss";

export default compose(
  ({
    onChange,
    value,
    disabled,
    name,
    defaultValue,
    options,
    children,
    open,
    setOpen,
    icon,
    placeholder,
    selectedEvaluators,
    setSelectedEvaluators,
    customFunction,
    noAvatar,
  }) => (
    <div
      className={classNames(
        styles.customSelect,
        open && styles.open,
        selectedEvaluators.length > 0 && !open && styles.selected,
        "customSelect",
        disabled && styles.disabled
      )}
      onClick={() => setOpen(true)}
      aria-label="select"
    >
      {icon && (
        <div className={styles.icon}>
          <i className={icon} />
        </div>
      )}
      <h5>{placeholder}</h5>
      <div className={styles.selectArrow}>
        {open ? (
          <i className="fas fa-chevron-up" />
        ) : (
          <i className="fas fa-chevron-down" />
        )}
      </div>
      <div className={classNames(styles.options, "option")}>
        {options.map((o) => (
          <Button
            key={Math.random(10)}
            bStyle={["none"]}
            type="button"
            onClick={() => {
              if (selectedEvaluators.find((se) => se.id === o.id)) {
                setSelectedEvaluators([
                  ...selectedEvaluators.filter((e) => e.id !== o.id),
                ]);
                customFunction &&
                  customFunction([
                    ...selectedEvaluators.filter((e) => e.id !== o.id),
                  ]);
              } else {
                setSelectedEvaluators([...selectedEvaluators, o]);
                customFunction && customFunction([...selectedEvaluators, o]);
              }
            }}
          >
            <div
              className={classNames(
                styles.user,
                selectedEvaluators.find((se) => se.id === o.id) && styles.active
              )}
            >
              <div className={styles.left}>
                {!noAvatar && (
                  <div className={styles.avatar}>
                    <UserAvatar member={o} size="verySmall" />
                  </div>
                )}
                {o.name}
              </div>
              {selectedEvaluators.find((se) => se.id === o.id) && (
                <i className="far fa-check" />
              )}
            </div>
          </Button>
        ))}
      </div>
    </div>
  )
);
