import React from "react";
import UserAvatar from "components/UserAvatar";
import replaceAll from "utils/replaceAll";
import TopDimensions from "components/TopDimensions";
import addMembersToTeamDim from "utils/addMembersToTeamDim";
import Wrapper from "components/UI/Wrapper";
import Summary from "./Summary";

import styles from "./User.module.scss";

export default ({ candidate }) => {
  const topDimensions = {
    competencies:
      candidate.competencies &&
      !candidate.competencies.some((c) => c.score === null) &&
      candidate.competencies.sort((a, b) => b.score - a.score).slice(0, 3),
    personality:
      candidate.personality &&
      !candidate.personality.some((p) => p.score === null) &&
      candidate.inTeamStrengths.short.length > 0 &&
      candidate.inTeamStrengths.short.slice(0, 3),
    values: candidate.motivators.length > 0 && candidate.motivators.slice(0, 3),
  };

  const teamMembers = [...candidate.position.parentTeam.members, candidate];

  const dimensions = [
    ...candidate.position.parentTeam.personality.map((dim) => ({
      ...dim,
      members: addMembersToTeamDim({
        dim,
        members: teamMembers,
        dimGroup: "personality",
      }),
    })),
    ...candidate.position.parentTeam.competencies.map((dim) => ({
      ...dim,
      members: addMembersToTeamDim({
        dim,
        members: teamMembers,
        dimGroup: "competencies",
      }),
    })),
    ...candidate.position.parentTeam.values.map((dim) => ({
      ...dim,
      members: addMembersToTeamDim({
        dim,
        members: teamMembers,
        dimGroup: "values",
      }),
    })),
  ];

  return (
    <div className={styles.main}>
      <Wrapper left>
        <div className={styles.userHead}>
          <div className={styles.userHeadLeft}>
            <h4
              className={styles.candidateName}
            >{`${candidate.first_name} ${candidate.last_name}`}</h4>
            <p className={styles.candidateShortDesc}>
              {candidate.description.short}
            </p>
          </div>
          <div className={styles.userHeadRight}>
            {candidate.description && candidate.description.long && (
              <p className={styles.candidateLongDesc}>
                {replaceAll(
                  candidate.description.long,
                  "<%name%>",
                  candidate.first_name
                )}
              </p>
            )}
          </div>
        </div>
        <TopDimensions
          topDimensions={topDimensions}
          dimensions={dimensions}
          highlight={candidate}
          titles={{
            competencies: "Top competencies",
            personality: "Behavioural strengths",
            values: "Motivators",
          }}
          emptyStates={{
            competencies:
              "Waiting for user to complete the competencies survey",
            personality: "Waiting for user to complete the personality survey",
            values: "Waiting for user to complete the values survey",
          }}
        />
        <Summary position={candidate.position} candidate={candidate} />
      </Wrapper>
    </div>
  );
};
