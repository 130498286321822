import React, { useState } from "react";
import UserAvatar from "components/UserAvatar";
import Button from "components/UI/Button";
import styles from "./Invite.module.scss";

export default ({ addToPlayersList, list }) => {
  return (
    <div className={styles.members}>
      {list.length > 0 && <h5 className={styles.title}>TEAM</h5>}
      {list.map((member) => {
        return (
          <div className={styles.memberWrap} key={`member-${member.id}`}>
            <div className={styles.avatarWrapper}>
              <UserAvatar member={member} isInvite={false} size="mediumSmall" />
            </div>
            <div className={styles.info}>
              <p className={styles.userName}>{member.name}</p>
              <p className={styles.userEmail}>{member.email}</p>
            </div>
            <div className={styles.right}>
              <Button
                bStyle={["primary", "verySmall"]}
                type="button"
                onClick={() => addToPlayersList(member)}
              >
                Invite
              </Button>
            </div>
          </div>
        );
      })}
    </div>
  );
};
