import React, { useState } from "react";
import classNames from "classnames";
import Popup from "reactjs-popup";
import Modal from "react-modal";
import Button from "components/UI/Button";
import DropDown from "components/UI/DropDown";
import Icon from "components/UI/Icon";
import UploadFile from "./UploadFile";
import HoganScoresModal from "./HoganScoresModal";
import compose from "./compose";

import styles from "./CandidateData.module.scss";

const iconList = {
  "image/png": "JPG",
  "image/jpeg": "JPG",
  "application/pdf": "PDF",
  "application/msword": "DOC",
  "application/vnd.oasis.opendocument.text": "DOC",
};

const tagTitles = {
  cv: "CV",
  interview_notes: "Interview notes",
};

export default compose(
  ({
    candidate,
    handleUpsertCandidateAccountDimScore,
    handleDeleteCandidateAccountDimScore,
    handleDeleteAttachment,
    handleAttachmentDownload,
  }) => {
    const [openModal, setOpenModal] = useState(false);

    let tests = [];
    let files = [];

    console.log({ candidate });

    candidate.accountDimGroups &&
      candidate.accountDimGroups.forEach((dg, testIndex) => {
        const index = tests.findIndex((test) => test.groupTitle === dg.title);

        if (index !== -1) {
          dg.dimensions.map((dim) => {
            const index2 = tests[index].groups.findIndex(
              (test) => test.groupTitle === dg.title
            );

            if (index2 !== -1) {
              tests[index].groups[index2].push(dim);
            } else {
              tests[index].groups[index2] = [dim];
            }
          });
        } else {
          let groups = [];

          dg.dimensions.map((dim) => {
            const groupIndex = groups.findIndex(
              (group) => group.dimGroup === dim.group_title
            );

            if (groupIndex !== -1) {
              groups[groupIndex].dimensions.push(dim);
            } else {
              groups.push({
                dimGroup: dim.group_title,
                dimensions: [dim],
              });
            }
          });

          tests.push({
            groupTitle: dg.title,
            testIndex: testIndex,
            type: "test",
            icon: "Window",
            groups: groups,
            show: dg.dimensions.some((d) => d.score !== null),
          });
        }
      });

    candidate.attachments.map((att) => {
      if (att.isEmbedded) {
        files.push({
          id: att.id,
          name: att.file_name,
          type: "file",
          icon: iconList[att.content_type] || "DOC",
          tags: att.tags,
          action: () => handleAttachmentDownload(att),
        });
      }
    });

    return (
      <div className={styles.candidateData}>
        <div className={styles.headRow}>
          <div className={styles.left}>
            <h4 className={styles.title}>Candidate data</h4>
            <p className={styles.subtitle}>
              Manage data the profile is partly generated from
            </p>
          </div>
          <div className={styles.right}>
            <div className="dropdownWrapper" id="dropdownMenu">
              <Popup
                keepTooltipInside="body"
                trigger={
                  <Button bStyle={["blue", "verySmall"]}>
                    <Icon icon="Plus" marginRight />
                    Add data
                  </Button>
                }
                position="bottom right"
                arrowStyle={{ display: "none" }}
                nested
              >
                <DropDown>
                  {candidate.accountDimGroups && (
                    <>
                      <p className={styles.sectionTitle}>
                        <Icon icon="Database" marginRight />
                        Add manually
                      </p>
                      <li>
                        <Button
                          type="button"
                          bStyle={["none"]}
                          onClick={() => setOpenModal(0)}
                        >
                          Hogan assessment scores
                        </Button>
                      </li>
                      <li>
                        <Button
                          type="button"
                          bStyle={["none"]}
                          onClick={() => setOpenModal(1)}
                        >
                          OPQ assessment scores
                        </Button>
                      </li>
                    </>
                  )}

                  <p className={styles.sectionTitle}>
                    <Icon icon="File" marginRight />
                    Attachments
                  </p>
                  <li>
                    <UploadFile candidate={candidate} />
                  </li>
                </DropDown>
              </Popup>
            </div>
          </div>
        </div>
        <div className={styles.dataItems}>
          {tests &&
            tests.map((test) => {
              if (test.show) {
                return (
                  <div className={styles.item} key={"Test-" + test.groupTitle}>
                    <div className={styles.nameAndLabel}>
                      <h5 className={styles.itemName}>
                        <span className={styles.icon}>
                          <Icon icon={test.icon} />
                        </span>
                        {test.groupTitle}
                      </h5>
                    </div>
                    {test.type === "test" && (
                      <div className={styles.buttons}>
                        <Button
                          bStyle={["verySmall"]}
                          onClick={() => setOpenModal(test.testIndex)}
                        >
                          Edit
                        </Button>
                      </div>
                    )}
                  </div>
                );
              }
            })}
          {files &&
            files.map((file) => (
              <div className={styles.item} key={"File-" + file.name}>
                <div className={styles.nameAndLabel}>
                  <h5
                    className={classNames(styles.itemName, styles.withAction)}
                    onClick={file.action}
                  >
                    <span className={styles.icon}>
                      <Icon icon={file.icon} />
                    </span>
                    <span className={styles.title}>{file.name}</span>
                    <span className={styles.downloadIcon}>
                      <Icon icon="Download" />
                    </span>
                  </h5>
                  <div className={styles.labels}>
                    {file &&
                      file.tags &&
                      file.tags.map((tag) => (
                        <span key={"FileTag-" + tag} className={styles.label}>
                          {tagTitles[tag]}
                        </span>
                      ))}
                  </div>
                </div>
                <div className={styles.buttons}>
                  <span className={styles.remove}>
                    <Button
                      bStyle={["none"]}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteAttachment(file);
                      }}
                    >
                      <Icon icon="Close" />
                    </Button>
                  </span>
                </div>
              </div>
            ))}
        </div>
        {(!!openModal || openModal === 0) && (
          <Modal
            isOpen={!!openModal || openModal === 0}
            onRequestClose={() => setOpenModal(false)}
            shouldCloseOnOverlayClick
          >
            <Button
              bStyle="close"
              type="button"
              onClick={() => setOpenModal(false)}
            >
              <i className="fal fa-times" />
            </Button>
            <HoganScoresModal
              candidate={candidate}
              test={tests[openModal]}
              handleUpsertCandidateAccountDimScore={
                handleUpsertCandidateAccountDimScore
              }
              handleDeleteCandidateAccountDimScore={
                handleDeleteCandidateAccountDimScore
              }
            />
          </Modal>
        )}
      </div>
    );
  }
);
