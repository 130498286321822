import React from "react";
import { compose, withState, withHandlers, withProps } from "recompose";
import { graphql, withApollo } from "react-apollo";
import { loader } from "graphql.macro";
import { withRouter } from "react-router-dom";
import handleLoading from "utils/loader";
import withStore from "utils/withStore";
import Circles from "components/Loaders/Circles";
import serializer from "components/TextEditor/editorSerializer";
import deserialize from "./deserialize";

const POSITION_QUERY = loader("client/Queries/PositionQuery98.graphql");
const UPDATE_REQUIREMENT = loader("./updateRequirement.graphql");
const REMOVE_REQUIREMENT = loader("./removeRequirement1.graphql");
const CREATE_REQUIREMENT = loader("./createRequirement2.graphql");

export default compose(
  withRouter,
  withApollo,
  withState("cardTitle", "setCardTitle", (props) => props.card.title),
  withState(
    "cardDesc",
    "setCardDesc",
    (props) => deserialize(props.card.description) || ""
  ),
  withProps(({ cardOpen, card, newCard }) => {
    return {
      isOpen: cardOpen === card.order,
    };
  }),
  graphql(POSITION_QUERY, {
    options: (props) => {
      return {
        variables: { id: parseInt(props.match.params.positionId, 10) },
        notifyOnNetworkStatusChange: true,
      };
    },
  }),
  graphql(UPDATE_REQUIREMENT, {
    name: "updateRequirement",
  }),
  graphql(REMOVE_REQUIREMENT, {
    name: "removeRequirement",
    options: (props) => {
      return {
        update: (proxy, { data: { deletePositionRequirement } }) => {
          const data = proxy.readQuery({
            query: POSITION_QUERY,
            variables: {
              id: parseInt(props.match.params.positionId, 10),
            },
          });

          data.openPosition.requirements = [
            ...data.openPosition.requirements.filter(
              (req) => req.id !== deletePositionRequirement
            ),
          ];

          proxy.writeQuery({
            query: POSITION_QUERY,
            variables: {
              id: parseInt(props.match.params.positionId, 10),
            },
            data,
          });
        },
      };
    },
  }),
  graphql(CREATE_REQUIREMENT, {
    name: "createRequirement",
    options: (props) => {
      return {
        update: (proxy, { data: { createPositionRequirement } }) => {
          const data = proxy.readQuery({
            query: POSITION_QUERY,
            variables: {
              id: parseInt(props.match.params.positionId, 10),
            },
          });

          data.openPosition.requirements = [
            ...data.openPosition.requirements,
            createPositionRequirement,
          ];

          proxy.writeQuery({
            query: POSITION_QUERY,
            variables: {
              id: parseInt(props.match.params.positionId, 10),
            },
            data,
          });
        },
      };
    },
  }),
  withStore("newposition"),
  withHandlers({
    handleUpdate:
      ({
        data: { openPosition },
        updateRequirement,
        setCardOpen,
        cardTitle,
        cardDesc,
        card,
      }) =>
      () => {
        updateRequirement({
          variables: {
            id: card.id,
            position_id: openPosition.id,
            title: cardTitle || "",
            description: serializer.serialize(cardDesc),
          },
        }).then((res) => {});
      },
    handleRemove:
      ({ data: { openPosition }, removeRequirement, setCardOpen, card }) =>
      () => {
        removeRequirement({
          variables: {
            id: card.id,
            position_id: openPosition.id,
          },
        });
      },
    handleCreate:
      ({
        card,
        setNewCard,
        data: { openPosition },
        createRequirement,
        setCardOpen,
        cardTitle,
        cardDesc,
      }) =>
      () => {
        createRequirement({
          variables: {
            position_id: openPosition.id,
            title: cardTitle,
            description: serializer.serialize(cardDesc),
            order: card.order,
          },
        }).then((res) => {
          setNewCard(false);
          setCardOpen(card.order);
        });
      },
  }),
  handleLoading(() => (
    <div style={{ height: "100vh" }}>
      <Circles />
    </div>
  ))
);
