import React from "react";
import classNames from "classnames";
import Icon from "components/UI/Icon";

import styles from "./ModalTitle.module.scss";

export default ({ children, icon, color }) => (
  <h2 className={styles.title}>
    {icon && (
      <span className={classNames(styles.icon, styles[color])}>
        <Icon icon={icon} />
      </span>
    )}
    {children}
  </h2>
);
