import React, { useEffect } from "react";
import {
  withState,
  compose,
  branch,
  renderComponent,
  lifecycle,
} from "recompose";
import { graphql, withApollo } from "react-apollo";
import { loader } from "graphql.macro";
import Cookies from "cookies-js";
import AccountFrozenOverlay from "components/AccountFrozenOverlay";
import withStore from "utils/withStore";
import handleOrganizationAndAccountChangeOnRouteUpdate from "./handleOrganizationAndAccountChangeOnRouteUpdate";
import Sidebar from "components/Sidebar";
import SidebarProvider from "utils/sidebarProvider";
import { SocketProvider as GPTGWSocketProvider } from "components/GPTGateway/SocketContext";

const VIEWER_QUERY = loader("client/Queries/ViewerQuery25.graphql");

export default compose(
  withStore("viewer", "account", "selectedOrg", "route"),
  withState("stateChaneInProgress", "toggleStateChange", true),
  withState("collapsed", "setCollapsed", false),
  withApollo,
  graphql(VIEWER_QUERY, {
    name: "VIEWER_QUERY",
  }),
  branch(
    (props) => props.VIEWER_QUERY && props.VIEWER_QUERY.loading,
    renderComponent(() => null)
  ),
  lifecycle({
    componentWillMount() {
      // console.log('viewer', this.props.VIEWER_QUERY)
      // console.log('error', this.props.VIEWER_QUERY)

      const {
        id,
        type,
        name,
        email,
        surveys,
        default_organization_id,
        hasAdminAccess,
        occupation,
      } = this.props.VIEWER_QUERY.viewer;
      // console.log('updateing intercom in authed utils')
      if (type === "user") {
        window.Intercom("update", {
          app_id: process.env.REACT_APP_INTERCOM_APP_ID,
          user_id: id,
          name,
          email,
          occupation,
          has_admin_access: hasAdminAccess,
          user_hash: Cookies.get("iuh"),
          horizontal_padding: 12,
          vertical_padding: 12,

          "profile-complete": surveys.every((survey) => survey.complete),
        });
      }

      if (window._hsq) {
        window._hsq.push([
          "identify",
          {
            email,
          },
        ]);
      }

      if (window.heap) {
        window.heap.identify(`user-${id}`);
        window.heap.addUserProperties({ name, occupation, email });
      }

      if (default_organization_id && !window.localStorage.recentOrg) {
        window.localStorage.setItem("recentOrg", default_organization_id);
      }
    },
  })
)(
  ({
    children,
    route,
    dispatch,
    client,
    account,
    selectedOrg,
    viewer,
    collapsed,
    setCollapsed,
  }) => {
    useEffect(() => {
      handleOrganizationAndAccountChangeOnRouteUpdate({
        route,
        client,
        children,
        dispatch,
        account,
        selectedOrg,
      });
    }, [account, viewer, route]);
    if (route.stateChageInProgress) return null;

    const noSidebar = [
      "onboarding",
      "survey",
      "mapcompetencies",
      "newpassword",
    ];

    const match = noSidebar.find((element) => {
      if (route.current.toLowerCase().includes(element.toLowerCase())) {
        return true;
      }
    });

    const html = document.getElementsByTagName("html")[0];

    if (
      route.current.toLowerCase().includes("position/") &&
      !route.current.toLowerCase().includes("candidate/") &&
      !route.current.toLowerCase().includes("person/") &&
      !route.current.toLowerCase().includes("compare")
    ) {
      html.classList.add("disableHorizontalScroll");
    } else {
      html.classList.remove("disableHorizontalScroll");
    }

    if (window.innerWidth < 960) {
      const forceDesktop = ["workflow"];
      const exclusions = ["candidate", "interviewguide"];

      forceDesktop.map((page) => {
        if (route.current.toLowerCase().indexOf(page) > -1) {
          document.body.classList.add("forceDesktop");
        }
      });

      exclusions.map((page) => {
        if (route.current.toLowerCase().indexOf(page) > -1) {
          document.body.classList.remove("forceDesktop");
        }
      });
    }

    return (
      <AccountFrozenOverlay account={account} route={route}>
        <GPTGWSocketProvider>
          {match === undefined ? (
            <SidebarProvider collapsed={collapsed} setCollapsed={setCollapsed}>
              {viewer.accounts.length > 0 &&
              viewer.accounts.some(
                (a) => a.id === viewer.default_account_id
              ) ? (
                <Sidebar
                  viewer={viewer}
                  route={route}
                  account={account}
                  selectedOrg={selectedOrg}
                  collapsed={collapsed}
                  setCollapsed={setCollapsed}
                >
                  {children}
                </Sidebar>
              ) : (
                children
              )}
            </SidebarProvider>
          ) : (
            children
          )}
        </GPTGWSocketProvider>
      </AccountFrozenOverlay>
    );
  }
);
