import { compose, withHandlers, withState } from "recompose";
import { graphql } from "react-apollo";
import { loader } from "graphql.macro";
import { withRouter } from "react-router-dom";

const POSITION_TEMPLATE = loader("../positionTemplate9.graphql");
const UPSERT_POSITION_TEMPLATE_HIGHLIGHT = loader(
  "./upsertPositionTemplateHighlight.graphql"
);
const DELETE_POSITION_TEMPLATE_HIGHLIGHT = loader(
  "./deletePositionTemplateHighlight.graphql"
);

export default compose(
  withRouter,
  graphql(UPSERT_POSITION_TEMPLATE_HIGHLIGHT, {
    name: "upsertPositionTemplateHighlight",
    options: ({ match }) => {
      return {
        update: (proxy, { data: { upsertPositionTemplateHighlight } }) => {
          const data = proxy.readQuery({
            query: POSITION_TEMPLATE,
            variables: {
              id: parseInt(match.params.templateId, 10),
              org_id: parseInt(match.params.orgId, 10),
            },
          });

          if (
            data.positionTemplate.highlights.filter(
              (h) => h.id === upsertPositionTemplateHighlight.id
            ).length === 0
          ) {
            data.positionTemplate.highlights = [
              ...data.positionTemplate.highlights,
              upsertPositionTemplateHighlight,
            ];
          }

          proxy.writeQuery({
            query: POSITION_TEMPLATE,
            variables: {
              id: parseInt(match.params.templateId, 10),
              org_id: parseInt(match.params.orgId, 10),
            },
            data,
          });
        },
      };
    },
  }),
  graphql(DELETE_POSITION_TEMPLATE_HIGHLIGHT, {
    name: "deletePositionTemplateHighlight",
    options: ({ match }) => {
      return {
        update: (proxy, { data: { deletePositionTemplateHighlight } }) => {
          const data = proxy.readQuery({
            query: POSITION_TEMPLATE,
            variables: {
              id: parseInt(match.params.templateId, 10),
              org_id: parseInt(match.params.orgId, 10),
            },
          });

          data.positionTemplate.highlights = [
            ...data.positionTemplate.highlights.filter(
              (h) => h.id !== deletePositionTemplateHighlight
            ),
          ];

          proxy.writeQuery({
            query: POSITION_TEMPLATE,
            variables: {
              id: parseInt(match.params.templateId, 10),
              org_id: parseInt(match.params.orgId, 10),
            },
            data,
          });
        },
      };
    },
  }),
  withHandlers({
    handleCreatePositionTemplateHighlight:
      ({ upsertPositionTemplateHighlight, templateId }) =>
      async () => {
        try {
          await upsertPositionTemplateHighlight({
            variables: {
              template_id: templateId,
            },
          });
        } catch (err) {
          throw new Error(err);
        }
      },
    handleUpdatePositionTemplateHighlight:
      ({ upsertPositionTemplateHighlight, templateId }) =>
      async (highlight_id, title, desc, icon, color) => {
        try {
          await upsertPositionTemplateHighlight({
            variables: {
              id: highlight_id,
              template_id: templateId,
              icon: icon,
              color: color,
              title: title,
              description: desc,
            },
          });
        } catch (err) {
          throw new Error(err);
        }
      },
    handleDeletePositionTemplateHighlight:
      ({ deletePositionTemplateHighlight, templateId }) =>
      (highlight_id) => {
        try {
          deletePositionTemplateHighlight({
            variables: {
              id: highlight_id,
              template_id: templateId,
            },
          });
        } catch (err) {
          throw new Error(err);
        }
      },
  })
);
