export default {
  __schema: {
    types: [
      {
        kind: "OBJECT",
        name: "RootQuery",
        possibleTypes: null,
      },
      {
        kind: "SCALAR",
        name: "Int",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "Account",
        possibleTypes: null,
      },
      {
        kind: "SCALAR",
        name: "String",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "Whitelabel",
        possibleTypes: null,
      },
      {
        kind: "SCALAR",
        name: "JSONObject",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "AccountMemberType",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "OrganizationIdentifyer",
        possibleTypes: null,
      },
      {
        kind: "SCALAR",
        name: "Boolean",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "AccountOrganizationsOverview",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "AccountSubscriptionDetails",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "AccountBillingUser",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "Viewer",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "ActiveSurvey",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "SurveyRowLoaded",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "DimensionLoaded",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "SurveyAnswerObject",
        possibleTypes: null,
      },
      {
        kind: "SCALAR",
        name: "Float",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "ViewerAccount",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "ProfileSummary",
        possibleTypes: null,
      },
      {
        kind: "SCALAR",
        name: "Date",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "OrganizationType",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "ContractType",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "TeamType",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "ShortLongTextType",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "UserType",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "SurveyAnswer",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "ComputedBIG5UserDimension",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "PersonalityMainFacetType",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "UserSocDesDimensionType",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "ComputedUserValuesDimension",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "CoreTextType",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "ValuesChartType",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "ValuesChartGroupType",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "ComputedDimension",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "ScorePathObject",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "ValuesChartAvatarPosition",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "UserPersonalityChartType",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "PersonalityDimensionsType",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "MotivatorsSummaryType",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "StrengthsSummaryType",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "PositionType",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "Invite",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "OrganiationInvite",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "TeamInvite",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "Survey",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "SurveyRow",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "Dimension",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "Competency_dim",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "OnboardingType",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "OnboardingStep",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "OnboardingStepChecklistItem",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "PositionRequirement",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "PositionKanbanStep",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "TalentPoolType",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "PersonalDimensions",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "CompetencyDimension",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "CompetecnyGraph",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "CompetencyDimGroup",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "CompetencyDim",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "CommentType",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "Evaluator",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "LonglistedPersonType",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "CandidateEvaluation",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "KeyHighlight",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "CandidateNote",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "NoteCreator",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "Attachment",
        possibleTypes: null,
      },
      {
        kind: "UNION",
        name: "CandidateUnionType",
        possibleTypes: [
          {
            name: "LonglistedPersonType",
          },
          {
            name: "UserType",
          },
        ],
      },
      {
        kind: "OBJECT",
        name: "CompetencyInsight",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "CandidateCheclist",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "KeyFigure",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "ComputedBIG5TeamDimension",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "ComputedTeamValuesDimension",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "HiringSuggestions",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "SuggestionType",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "TeamValuesChartType",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "TeamPersonalityChartType",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "TeamGoal",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "WorkshopCompe",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "WorkshopPersonalityAssessment",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "TeamPlay",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "Toolkit",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "StepGroupType",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "GroupStep",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "TeamPlayUserType",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "TeamEffectivenessDimension",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "TeamPlayConclusion",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "Reaction",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "ReactionType",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "OrganizationAdmins",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "GettingStarted",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "GettingStartedChecklistItem",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "PasswordReset",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "CaseStudy",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "CaseStudySurveyField",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "ComparisonProfile",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "TeamGoalGroup",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "NotificationsSubscriptionType",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "UserTeamsAndPositionsInOrg",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "CurrentOrgAndAccount",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "CurrentOrganization",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "CurrentAccount",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "PositionTemplate",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "TemplateCreator",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "TemplateCategory",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "PositionStageType",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "Highlight",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "StripeProduct",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "StripeProductPrice",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "Onboarding",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "UserOnboardingStep",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "OnboardingBlock",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "CandidateDashboardData",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "CandidateDashboardPositionData",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "LearningCenter",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "RootMutation",
        possibleTypes: null,
      },
      {
        kind: "INPUT_OBJECT",
        name: "SurveyAnswerInputType",
        possibleTypes: null,
      },
      {
        kind: "SCALAR",
        name: "Upload",
        possibleTypes: null,
      },
      {
        kind: "INPUT_OBJECT",
        name: "AddableMember",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "AddTeamMemberResponse",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "RemoveFromTeamResponseType",
        possibleTypes: null,
      },
      {
        kind: "INPUT_OBJECT",
        name: "PositionConfAnswerType",
        possibleTypes: null,
      },
      {
        kind: "INPUT_OBJECT",
        name: "CaseStudySubmitObject",
        possibleTypes: null,
      },
      {
        kind: "INPUT_OBJECT",
        name: "UpdateContractType",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "FilterType",
        possibleTypes: null,
      },
      {
        kind: "INPUT_OBJECT",
        name: "NewOrgInviteType",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "CloseOnboardingResponseType",
        possibleTypes: null,
      },
      {
        kind: "INPUT_OBJECT",
        name: "TeamWorkshopCompetenciesAnswerType",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "AddCandidateToTeamSuccess",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "RemoveCAndidateEaluationSuccess",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "RemoveCommentResponseType",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "RemoveReactionResponseType",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "ValidateInviteEmailResponse",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "SubscriptionValidationResult",
        possibleTypes: null,
      },
      {
        kind: "INPUT_OBJECT",
        name: "NewAccountInviteType",
        possibleTypes: null,
      },
      {
        kind: "INPUT_OBJECT",
        name: "AccountInviteOrgType",
        possibleTypes: null,
      },
      {
        kind: "INPUT_OBJECT",
        name: "TemplateCompetencyInputType",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "LeaveOrgRespone",
        possibleTypes: null,
      },
      {
        kind: "INPUT_OBJECT",
        name: "PromptMessage",
        possibleTypes: null,
      },
      {
        kind: "INPUT_OBJECT",
        name: "ContextVariable",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "GPTMessage",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "__Schema",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "__Type",
        possibleTypes: null,
      },
      {
        kind: "ENUM",
        name: "__TypeKind",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "__Field",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "__InputValue",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "__EnumValue",
        possibleTypes: null,
      },
      {
        kind: "OBJECT",
        name: "__Directive",
        possibleTypes: null,
      },
      {
        kind: "ENUM",
        name: "__DirectiveLocation",
        possibleTypes: null,
      },
    ],
  },
};
