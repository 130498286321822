import React, { useState, useEffect } from "react";
import useAxios from "axios-hooks";
import { authShareCandidate } from "client/authRoutes";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { printoutRoute } from "client/authRoutes";
import FullPageLoader from "components/Loaders/FullPage";
import SidebarHeader from "components/UI/SidebarHeader";

import SidebarWrapper from "components/UI/SidebarWrapper";
import { useSidebar } from "utils/sidebarProvider";

import Button from "components/UI/Button";
import Icon from "components/UI/Icon";
import { ReactComponent as WisnioLogo } from "images/icons/Wisnio.svg";
import { Element } from "react-scroll";
import AdminSection from "./AdminSection";
import User from "./User";
import Competencies from "./Competencies";
import Personality from "./Personality";
import Values from "./Values";
import sortCandidateCompetencies from "utils/sortCandidateCompetencies";

import styles from "./Candidate.module.scss";

export default compose(withRouter)(
  ({
    history,
    match: {
      params: { secret },
    },
  }) => {
    const [{ data, loading, error, response }] = useAxios(
      `${authShareCandidate}/${secret}`
    );

    const { contentContext } = useSidebar();
    const [content, setContent] = contentContext;

    const sidebarContent = [
      {
        sectionTitle: "Navigation",
        items: [
          {
            title: "Summary",
            scrollTo: true,
          },
          {
            title: "Competencies",
            scrollTo: true,
          },
          {
            title: "Personality",
            scrollTo: true,
          },
          {
            title: "Values",
            scrollTo: true,
          },
        ],
      },
    ];

    useEffect(() => {
      setContent(sidebarContent);
    }, []);

    if (loading) return <FullPageLoader />;

    const { candidateInTeam } = data;

    const candidateName = candidateInTeam.name
      ? candidateInTeam.name.replace(" ", "_")
      : "";

    const printoutUrl = `${printoutRoute}/share/candidate/${secret}/printout/candidate/${candidateInTeam.position.parent_org_id}/${candidateInTeam.position.parentTeam.id}/${candidateInTeam.position.id}/${candidateInTeam.id}/${candidateName}_candidate_profile.pdf`;

    return (
      <SidebarWrapper>
        <SidebarHeader
          title={candidateInTeam.name}
          subtitle={candidateInTeam.position.parentTeam.name}
          noBreadcrumbs
        >
          {candidateInTeam.readyToRender && (
            <div className={styles.right}>
              <Button
                bStyle={["verySmall"]}
                onClick={() => window.open(printoutUrl, "_blank")}
              >
                <Icon icon="Print" marginRight />
                Print
              </Button>
            </div>
          )}
        </SidebarHeader>
        <div className={styles.wrapper}>
          <AdminSection candidate={candidateInTeam} secret={secret} />
          {(!!candidateInTeam.candidateSummary ||
            !!candidateInTeam.keyHighlights.length) && (
            <div className={styles.sectionHeader}>
              <div className={styles.logo}>
                <WisnioLogo />
              </div>
              <div className={styles.right}>
                <h5 className={styles.title}>Generated profile</h5>
                <p className={styles.subtitle}>
                  Data and analysis provided by Wisnio
                </p>
              </div>
            </div>
          )}
          {candidateInTeam.type !== "person" && (
            <>
              <User position={candidateInTeam} candidate={candidateInTeam} />

              <Element name="competencies">
                <Competencies
                  candidate={candidateInTeam}
                  team={candidateInTeam.position.parentTeam}
                  competencies={sortCandidateCompetencies(
                    candidateInTeam.position.parentTeam,
                    candidateInTeam
                  )}
                />
              </Element>
              <Element name="personality">
                <Personality
                  candidate={candidateInTeam}
                  team={candidateInTeam.position.parentTeam}
                />
              </Element>
              <Element name="values">
                <Values
                  candidate={candidateInTeam}
                  team={candidateInTeam.position.parentTeam}
                />
              </Element>
            </>
          )}
        </div>
      </SidebarWrapper>
    );
  }
);
