import React, { useRef, useEffect, useState } from "react";
import calculatePosition from "./CalculatePosition";
import classNames from "classnames";
import UserAvatar from "components/UserAvatar";

import styles from "./AIContentTag.module.scss";

export default ({ user, tag, dim, container }) => {
  if (!dim) return null;

  const [popoverPosition, setPopoverPosition] = useState(null);
  const [scrollTop, setScrollTop] = useState(0);

  const onScroll = (e) => {
    setScrollTop(e.target.documentElement.scrollTop);
  };

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const getMinLabel = () => {
    if (dim.groupId === "user_value_facets") {
      return "Less important";
    } else if (dim.__typename === "CompetencyDimension") {
      return "Less confident";
    } else {
      return (
        dim.label_min &&
        dim.label_min.charAt(0).toUpperCase() + dim.label_min.slice(1)
      );
    }
  };

  const getMaxLabel = () => {
    if (dim.groupId === "user_value_facets") {
      return "Very important";
    } else if (dim.__typename === "CompetencyDimension") {
      return "Highly confident";
    } else {
      return (
        dim.label_max &&
        dim.label_max.charAt(0).toUpperCase() + dim.label_max.slice(1)
      );
    }
  };

  const getDimGroup = () => {
    if (dim.groupId === "values") {
      return {
        group: "Values",
        color: "yellow",
      };
    } else if (dim.__typename === "CompetencyDimension") {
      return {
        group: "Competencies",
        color: "green",
      };
    } else {
      return {
        group: "Personality",
        color: "primary",
      };
    }
  };

  const popupRef = useRef();
  useEffect(() => {
    const popoverPosition = calculatePosition({
      container: container,
      tag,
      tooltip: popupRef.current,
      scrollTop,
    });

    setPopoverPosition(popoverPosition);
  }, [popupRef, scrollTop]);

  return (
    <div
      className={styles.wrapper}
      ref={popupRef}
      style={{
        ...popoverPosition,
      }}
    >
      <div className={styles.popover}>
        <div className={styles.topRow}>
          <h5 className={styles.dimTitle}>{dim.name}</h5>
          <h5
            className={classNames(styles.dimGroup, styles[getDimGroup().color])}
          >
            {getDimGroup().group}
          </h5>
        </div>
        <div className={styles.lineWrapper}>
          <div className={styles.line}>
            <div
              className={styles.avatar}
              style={{
                left: `calc(${dim.score > 94 ? 94 : dim.score}% - 25px)`,
              }}
            >
              <UserAvatar member={user} size="small" />
              <p
                className={classNames(
                  styles.score,
                  dim.score < 51 && styles.grey
                )}
              >
                {Math.round(dim.score)}
              </p>
            </div>
          </div>
          <div className={styles.opposites}>
            <div className={styles.left}>
              <h5 className={styles.label}>{getMinLabel()}</h5>
              <p className={styles.labelDesc}>{dim.description_min}</p>
            </div>
            <div className={styles.right}>
              <h5 className={styles.label}>{getMaxLabel()}</h5>
              <p className={styles.labelDesc}>{dim.description_max}</p>
            </div>
          </div>
        </div>
        <p className={styles.dimDescription}>{dim.description || dim.desc}</p>
      </div>
    </div>
  );
};
