import React from "react";

import styles from "./PersonalityPlaceholder.module.scss";

export default ({ resource }) => {
  const getText = () => {
    if (resource === "personal") {
      return "Please complete the personality survey.";
    }
    if (resource === "team") {
      return "Waiting for team members to complete the personality survey.";
    }
    if (resource === "member") {
      return "Waiting for user to complete the personality survey.";
    }
    return "Waiting for team members to complete the personality survey.";
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.noComplete}>
        <p>{getText()}</p>
      </div>
    </div>
  );
};
