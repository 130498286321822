import React, { useEffect } from "react";
import Layout from "layouts";
import styles from "./styles.module.scss";
import img from "images/backgrounds/success-page.svg";
import { withRouter } from "react-router-dom";
import Button from "components/UI/Button";

const ReviewSubmitted = ({
  match,
  history,
  data: {
    assessmentReview: {
      group: { title: groupTitle },
    },
  },
}) => {
  const redirectToReviews = () => {
    const { org_id } = match.params;
    history.push(`/${org_id}/assessments`);
  };

  useEffect(() => {
    const timer = setTimeout(redirectToReviews, 5000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Layout title={groupTitle} icon="360Assesment">
      <div className={styles.pageSubmitted}>
        <div className={styles.textarea}>
          <h2>Review submitted</h2>
          <h4>Thank you for your contribution!</h4>
          <p>You will be redirected to the navigation page shortly.</p>

          <div>
            <Button bStyle={["primary"]} onClick={() => redirectToReviews()}>
              Go back to reviews
            </Button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default withRouter(ReviewSubmitted);
