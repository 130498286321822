import React from "react";
import compose from "./compose";
import SidebarWrapper from "components/UI/SidebarWrapper/index";
import SidebarHeader from "components/UI/SidebarHeader/index";
import StepStatus from "./components/StepStatus";

export default compose(({ data: { assessmentSession } }) => {
  const handleNextClick = async () => {
    console.log("handleNextClick called");
  };

  return (
    <SidebarWrapper>
      <SidebarHeader title="Assessment status"></SidebarHeader>

      <StepStatus assessmentSession={assessmentSession} />
    </SidebarWrapper>
  );
});
