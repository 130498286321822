export default ({ position, viewer }) => {
  const competencies = position.competencies.slice(0, 3);
  const columns = position.kanbanColumns.map(() => []);
  const sorted = [];

  position.kanbanColumns.forEach((column, key) => {
    column.candidatesUnion.forEach((candId) => {
      const cand = position.candidatesUnion.find((c) => {
        const type = candId.split("-")[0];
        const id = parseInt(candId.split("-")[1], 10);

        return c.id === id && c.type === type;
      });

      if (!cand) return;

      const evaluations = cand.evaluation.filter(
        (e) => e.evaluatedBy.id === viewer.id
      );

      const evaluated =
        position.requirements.filter((req) =>
          evaluations
            .filter((e) => e.type === "requirement")
            .some((r) => r.item_id === req.id)
        ).length +
        competencies.filter((comp) =>
          evaluations
            .filter((e) => e.type === "competency")
            .some((c) => c.item_id === comp.id)
        ).length +
        evaluations.filter((e) => e.type === "personality").length +
        evaluations.filter((e) => e.type === "values").length +
        evaluations.filter((e) => e.type === "intuition").length;

      columns[key].push({
        itemId: `item-${cand.type}-${cand.id}`,
        id: cand.id,
        name: cand.name,
        first_name: cand.first_name,
        last_name: cand.last_name,
        email: cand.email,
        initials: cand.initials,
        avatar_url: cand.avatar_url,
        link: `/${position.parent_org_id}/team/${
          position.parentTeam.id
        }/position/${position.id}/${
          cand.type === "user" ? "candidate" : cand.type
        }/${cand.id}`,
        type: cand.type,
        isIncomplete: !cand.readyToRender,
        inviteSent: cand.invite,
        orgId: position.parent_org_id,
        evaluated,
      });
      sorted.push(`${cand.type}-${cand.id}`);
    });
  });

  position.candidatesUnion.forEach((cand) => {
    if (
      !sorted.some((c) => {
        const type = c.split("-")[0];
        const id = parseInt(c.split("-")[1], 10);
        return id === cand.id && type === cand.type;
      })
    ) {
      const evaluations = cand.evaluation.filter(
        (e) => e.evaluatedBy.id === viewer.id
      );
      const evaluated =
        position.requirements.filter((req) =>
          evaluations
            .filter((e) => e.type === "requirement")
            .some((r) => r.item_id === req.id)
        ).length +
        competencies.filter((comp) =>
          evaluations
            .filter((e) => e.type === "competency")
            .some((c) => c.item_id === comp.id)
        ).length +
        evaluations.filter((e) => e.type === "personality").length +
        evaluations.filter((e) => e.type === "values").length +
        evaluations.filter((e) => e.type === "intuition").length;

      columns[0].push({
        itemId: `item-${cand.type}-${cand.id}`,
        id: cand.id,
        name: cand.name,
        first_name: cand.first_name,
        last_name: cand.last_name,
        email: cand.email,
        initials: cand.initials,
        avatar_url: cand.avatar_url,
        link: `/${position.parent_org_id}/team/${
          position.parentTeam.id
        }/position/${position.id}/${
          cand.type === "user" ? "candidate" : cand.type
        }/${cand.id}`,
        type: cand.type,
        isIncomplete: !cand.readyToRender,
        inviteSent: cand.invite,
        orgId: position.parent_org_id,
        evaluated,
      });
    }
  });

  return columns;
};
