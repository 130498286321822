import React from "react"
import classNames from "classnames"
import Invite from "components/Invite"
import Button from "components/UI/Button"
import ModalTitle from "components/UI/ModalTitle"
import compose from "./compose"

import styles from "./Modal.module.scss"

export default compose(
  ({
    isLoading,
    handleSendInvites,
    account,
    updateModal,
    setOverlayClose,
    ACCOUNT_ORGANIZATIONS: { organizations },
  }) => (
    <div className={styles.wrapper}>
      <Button bStyle="close" type="button" onClick={() => updateModal("")}>
        <i className="fal fa-times" />
      </Button>
      <div className={classNames(styles.modalWrapper)}>
        <div className={styles.modalHead}>
          <ModalTitle>Invite administrators to workspace</ModalTitle>
          <p>Use the form below to invite administrators to the workspace.</p>
        </div>
        <Invite
          isLoading={isLoading}
          account_id={account.id}
          searchEnabled={false}
          updateModal={updateModal}
          setOverlayClose={setOverlayClose}
          require={{
            key: "Organization",
            values: ["id", "roleType"],
            id: organizations,
            roleType: [
              { name: "Member", id: 2 },
              { name: "Admin", id: 4 },
            ],
          }}
          handleSubmit={({ list }) => handleSendInvites({ list })}
          color="green"
        />
      </div>
    </div>
  )
)
