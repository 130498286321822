import { withHandlers } from "recompose";
import serializer from "components/TextEditor/editorSerializer";

export default withHandlers({
  handleSaveSummary:
    ({ summary, saveSummary, match, setEditSummary }) =>
    () => {
      const serialized = serializer.serialize(summary);
      const type = match.params.candidateType;
      saveSummary({
        variables: {
          position_id: parseInt(match.params.positionId, 10),
          candidate_id:
            type === "candidate"
              ? parseInt(match.params.candidateId, 10)
              : null,
          person_id:
            type === "person" ? parseInt(match.params.candidateId, 10) : null,
          summary: serialized,
        },
      }).then(() => {
        setEditSummary(false);
      });
    },
});
