export const teamScoreGroupsByIndex = {
  0: 'expert',
  1: 'expert',
  2: 'expert',
  3: 'strong',
  4: 'strong',
  5: 'strong',
  6: 'good',
  7: 'good',
  8: 'good',
  9: 'lacking'
}

export default {
  100: 'expert',
  99: 'expert',
  98: 'expert',
  75: 'strong',
  74: 'strong',
  73: 'strong',
  50: 'good',
  49: 'good',
  48: 'good',
  25: 'lacking'
}
