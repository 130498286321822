import React, { useState } from 'react'
import classNames from 'classnames'
import Button from 'components/UI/Button'
import InviteMembers from 'components/TeamMembers/InviteMembers'
import Field from 'components/UI/Field'
import ModalTitle from 'components/UI/ModalTitle'
import Modal from 'react-modal'
import compose from './compose'
import RemoveTeam from './RemoveTeam'
import MemberRow from './MemberRow'
import InviteRow from './InviteRow'
import styles from './Modal.module.scss'

export default compose(
  ({
    history,
    updateModal,
    team,
    handleChangeName,
    removeFromTeam,
    client,
    removeTeamFromInvite,
    showDeletePrompt,
    updateDeletePrompt,
    deleteTeam,
    nameValue,
    setNameValue,
    refetch
  }) => {
    const [inviteModal, setInviteModal] = useState('')

    return (
      <div className={styles.wrapper}>
        <Button bStyle='close' type='button' onClick={() => updateModal('')}>
          <i className='fal fa-times' />
        </Button>
        <div className={classNames(styles.modalWrapper)}>
          <div className={styles.modalHead}>
            <ModalTitle>Edit team</ModalTitle>
          </div>
          <div className={styles.newTeam}>
            <form onSubmit={e => handleChangeName(e)} autoComplete='off'>
              <div className={styles.row}>
                <h4>Team name</h4>
                <div className={styles.smallRow}>
                  <Field
                    fullWidth
                    large
                    type='text'
                    name='name'
                    value={nameValue}
                    onChange={(e) => {
                      setNameValue(e.target.value)
                    }}
                    required
                  />
                  <Button type='submit' bStyle={['primary', 'large']}>
                    Save
                  </Button>
                </div>
              </div>
            </form>
            <div className={styles.row}>
              <div className={styles.titleRow}>
                <div className={styles.column}>
                  <h4 className={styles.members}>Pending invites</h4>
                </div>
                <Button
                  type='button'
                  bStyle={['primary', 'small']}
                  onClick={() => setInviteModal('inviteMembers')}
                  refetch={refetch}
                >
                  <i className='fas fa-user-plus' />
                  Invite
                </Button>
              </div>
              <table>
                {team.pendingInvites.length > 0 ? (
                  <div className={styles.invitesWrapper}>
                    {team.pendingInvites.map(invite => (
                      <InviteRow
                        invite={invite}
                        team={team}
                        removeTeamFromInvite={removeTeamFromInvite}
                        client={client}
                        key={invite.id}
                      />
                    ))}
                  </div>
                ) : (
                  <div className={styles.invitesWrapper}>
                    <p className={styles.noPending}>No pending invites</p>
                  </div>
                )}
              </table>

              <div className={styles.titleRow}>
                <div className={styles.column}>
                  <h4 className={styles.members}>Members</h4>
                </div>
              </div>
              <Modal
                closeTimeoutMS={350}
                isOpen={inviteModal === 'inviteMembers'}
                onRequestClose={() => {
                  setInviteModal('')
                  updateModal('')
                }}
                shouldCloseOnOverlayClick
                className={styles.modal}
              >
                <InviteMembers
                  team={team}
                  updateModal={setInviteModal}
                  refetch={refetch}
                />
              </Modal>
              <table>
                <tbody>
                  {team.members.map(user => (
                    <MemberRow
                      user={user}
                      team={team}
                      removeFromTeam={removeFromTeam}
                      client={client}
                      key={user.id}
                    />
                  ))}
                </tbody>
              </table>
            </div>
            <div className={styles.removeButton}>
              <Button
                bStyle={['simple', 'icon']}
                type='button'
                onClick={() => updateDeletePrompt(true)}
              >
                <i className='fas fa-trash-alt' />
                Remove team
              </Button>

              <Modal
                closeTimeoutMS={350}
                isOpen={showDeletePrompt}
                onRequestClose={() => updateDeletePrompt(false)}
                shouldCloseOnOverlayClick
                className={styles.modal}
              >
                <RemoveTeam
                  deleteTeam={deleteTeam}
                  history={history}
                  updateDeletePrompt={updateDeletePrompt}
                  team={team}
                />
              </Modal>
            </div>
          </div>
        </div>
      </div>
    )
  }
)
