export default (member, isInvite) => {
  if (isInvite) {
    return 'grey'
  }
  if (!member.initials) {
    return 'blue'
  }
  const colors = {
    0: 'blue',
    1: 'green',
    2: 'yellow'
  }
  return colors[
    Array.from(member.initials.charCodeAt(0).toString()).map(Number)[0] % 3
  ]
}
