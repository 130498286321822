import React from "react";
import Popup from "reactjs-popup";
import classNames from "classnames";
import { Link } from "react-router-dom";
import Button from "components/UI/Button";
import { ReactComponent as UserCards } from "images/UserCards.svg";
import { ReactComponent as TableRow } from "images/inviteRow2.svg";
import TeamMemberAvatars from "components/TeamMemberAvatars";
import AddToTeams from "../AddToTeams";
import AddToPositions from "../AddToPositions";
import avatarColor from "../avatarColor";

import styles from "../MemberModal.module.scss";

export default ({
  isInvite,
  teamsAndPositions,
  addTeamMembersToExitstingTeam,
  addCandidatesToPosition,
  organization,
  loading,
}) => (
  <div className={styles.content}>
    <>
      <div className={styles.top}>
        <h4>{isInvite ? "Invited to teams" : "Member in teams"}</h4>
        <div
          className={classNames("dropdownWrapper", styles.dropdown)}
          id="dropdownMenu"
        >
          <Popup
            keepTooltipInside="body"
            trigger={
              <Button bStyle={["newsimple", "nopadding"]} type="button">
                Add to team
              </Button>
            }
            position="bottom right"
          >
            <AddToTeams
              organization={organization}
              member={teamsAndPositions.user}
              teamsAndPositions={teamsAndPositions}
              addTeamMembersToExitstingTeam={addTeamMembersToExitstingTeam}
              loading={loading}
            />
          </Popup>
        </div>
      </div>
      <div
        className={classNames(
          styles.column,
          teamsAndPositions.teams.length === 0 && styles.empty
        )}
      >
        {teamsAndPositions.teams.map((team) => (
          <Link to={`/team/${team.id}`} key={`team-${team.id}`}>
            <div className={styles.row}>
              <div className={styles.left}>
                <div
                  className={classNames(
                    styles.avatar,
                    styles[avatarColor(team.name)]
                  )}
                >
                  {team.name.slice(0, 1)}
                </div>
                <div className={styles.info}>
                  <h5>{team.name}</h5>
                  <p>
                    {team.members.length +
                      (team.members.length > 1 ? " members" : " member")}
                  </p>
                </div>
              </div>
              <div className={styles.right}>
                <TeamMemberAvatars
                  members={team.members}
                  length={team.members.length}
                  small
                />
                <i className="far fa-chevron-right" />
              </div>
            </div>
          </Link>
        ))}
        {teamsAndPositions.teams.length === 0 && (
          <div className={styles.emptyState}>
            <div className={styles.image}>
              <UserCards />
            </div>
            <h5>
              {`${
                isInvite ? "Invite" : teamsAndPositions.user.name
              } is not in any teams`}
            </h5>
            <p>Add them to a team from the button above.</p>
          </div>
        )}
      </div>
    </>
    <>
      <div className={styles.top}>
        <h4>{isInvite ? "Invited to positions" : "Candidate in positions"}</h4>
        <div
          className={classNames("dropdownWrapper", styles.dropdown)}
          id="dropdownMenu"
        >
          <Popup
            keepTooltipInside="body"
            trigger={
              <Button bStyle={["newsimple", "nopadding"]} type="button">
                Add to position
              </Button>
            }
            position="bottom right"
          >
            <AddToPositions
              organization={organization}
              member={teamsAndPositions.user}
              teamsAndPositions={teamsAndPositions}
              addCandidatesToPosition={addCandidatesToPosition}
            />
          </Popup>
        </div>
      </div>
      <div
        className={classNames(
          styles.column,
          teamsAndPositions.positions.length === 0 && styles.emptyPos
        )}
      >
        {teamsAndPositions.positions.map((position) => (
          <Link
            key={`pos-${position.id}`}
            to={`/team/${position.parent_team_id}/position/${position.id}`}
          >
            <div className={styles.row}>
              <div className={styles.left}>
                <div
                  className={classNames(
                    styles.avatar,
                    styles[avatarColor(position.name)]
                  )}
                >
                  {position.name.slice(0, 1)}
                </div>
                <div className={styles.info}>
                  <h5>{position.name}</h5>
                  <p>
                    {position.candidates.length +
                      (position.candidates.length > 1
                        ? " candidates"
                        : " candidate")}
                  </p>
                </div>
              </div>
              <div className={styles.right}>
                <i className="far fa-chevron-right" />
              </div>
            </div>
          </Link>
        ))}
        {teamsAndPositions.positions.length === 0 && (
          <div className={styles.emptyState}>
            <div className={styles.inviteRow}>
              <TableRow />
            </div>
            <div className={styles.inviteRow}>
              <TableRow />
            </div>
          </div>
        )}
      </div>
    </>
  </div>
);
