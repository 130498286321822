import { withHandlers } from 'recompose'

export default withHandlers({
  handleChangeAccountContract: ({
    updateAccountContract,
    updateAccountInvite,
    updateErrorPrompt,
    ACCOUNT_MEMBERS: { account }
  }) => async ({ event, user, roleTypes }) => {
    try {
      if(user.type === 'invite') {
        await
        await updateAccountInvite({
          variables: {
            account_id: account.id,
            invite_id: user.inviteId,
            update: {
              role: roleTypes.find(t => t.name === event.target.value).role
            }
          }
        })

      } else {
        await updateAccountContract({
          variables: {
            account_id: account.id,
            user_id: user.id,
            update: {
              role: roleTypes.find(t => t.name === event.target.value).role
            }
          }
        })
      }

    } catch (err) {
      updateErrorPrompt({
        error: true,
        message: err.message.replace('GraphQL error: ', '')
      })
    }


  }
})
