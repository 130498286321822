import React from "react";
import { compose } from "recompose";
import { loader } from "graphql.macro";
import { graphql } from "react-apollo";
import FullPage from "components/Loaders/FullPage";
import { withRouter } from "react-router-dom";
import handleLoading from "utils/loader";
const ASSESSMENT_GROUP = loader("./assessmentGroup5.graphql");

export default compose(
  withRouter,
  graphql(ASSESSMENT_GROUP, {
    options: ({
      match: {
        params: { group_id },
      },
    }) => ({
      variables: { id: parseInt(group_id, 10) },
    }),
  }),
  handleLoading(() => <FullPage />)
);
