import React from "react"
import { compose, withHandlers, withState } from "recompose"
import { withRouter } from "react-router-dom"
import handleLoading from "utils/loader"
import withStore from "utils/withStore"
import FullPage from "components/Loaders/FullPage"

import { graphql } from "react-apollo"
import { loader } from "graphql.macro"

const UPDATE_ACCOUNT = loader("./updateAccount.graphql")
const ACCOUNT_QUERY = loader("./AccountQuery.graphql")

export default compose(
  withRouter,
  withStore("viewer", "selectedOrg"),
  graphql(ACCOUNT_QUERY, {
    options: (props) => {
      return {
        variables: { id: parseInt(props.match.params.accountId, 10) },
      }
    },
  }),
  handleLoading(() => <FullPage />),
  withState("name", "updateName", ({ data: { account } }) => account.name),
  withState("isSaving", "toggleIsSaving", false),
  graphql(UPDATE_ACCOUNT, {
    name: "updateAccount",
  }),
  withHandlers({
    handleUpdateAccount:
      ({ data: { account }, updateAccount, toggleIsSaving }) =>
      (e) => {
        e.preventDefault()
        toggleIsSaving(true)
        updateAccount({
          variables: {
            id: account.id,
            name: e.target.name.value,
          },
        }).then(() => {
          toggleIsSaving(false)
        })
      },
  })
)
