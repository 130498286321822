import React from "react";

import PropTypes from "prop-types";
import { palette, typography } from "style/general";
import UserAvatar from "components/UserAvatar";

const InputUserAvatar = ({ user }) => {
  if (!user) return null;

  return (
    <>
      <div style={{ display: "flex", gap: 16 }}>
        <div>
          <UserAvatar member={user} size="small" />
        </div>
        <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
          <p>{user.name || user.email}</p>
          <p
            style={{
              ...typography.body,
              color: palette.grey1,
              ...typography.size14,
            }}
          >
            {user.position ?? null}
          </p>
        </div>
      </div>
    </>
  );
};

InputUserAvatar.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    position: PropTypes.string,
  }).isRequired,
};

export default InputUserAvatar;
