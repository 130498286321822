import React from 'react'
import DistributionBarChart from 'components/Charts/DistributionBarChart'
import classNames from 'classnames'

export default ({
  styles,
  dim,
  members,
  highlight,
  candidate,
  alignment,
  casestudy
}) => {
  const candidateDim =
    candidate &&
    highlight.personality.find((d) => d.name === dim.name)
  const getColor = () => {
    if (casestudy === true) {
      if (alignment === 'Very diverse') {
        return 'red'
      }
      if (alignment === 'Diverse') {
        return 'yellow'
      }
      if (alignment === 'Similar') {
        return 'grey'
      }
      if (alignment === 'Very similar') {
        return 'green'
      }
    }
    if (alignment === 'Very diverse') {
      return 'green'
    }
    if (alignment === 'Diverse') {
      return 'grey'
    }
    if (alignment === 'Similar') {
      return 'yellow'
    }
    if (alignment === 'Very similar') {
      return 'red'
    }
  }

  const noTeamMembers = candidate && members.length === 0
  return (
    <>
      <div className={styles.lineWrapper}>
        <DistributionBarChart
          dim={dim}
          members={members}
          candidate={candidate}
          resource="personality"
          colour={getColor()}
          size="large"
          highlight={highlight}
          casestudy={casestudy}
          modal
        />
        <div className={styles.opposites}>
          <div className={styles.left}>
            <h4 className={styles.label}>{dim.label_min}</h4>
            <p className={styles.desc}>{dim.description_min}</p>
          </div>
          <div className={styles.right}>
            <h4 className={styles.label}>{dim.label_max}</h4>
            <p className={styles.desc}>{dim.description_max}</p>
          </div>
        </div>
      </div>
      <div className={styles.asteriskWrapper} />
      {!noTeamMembers && (
        <div className={styles.conclusion}>
          <div className={styles.row}>
            {casestudy === true ? (
              <div>
                {alignment === 'Very diverse' ? (
                  <div
                    className={classNames(styles.icon, styles.red)}
                  >
                    <i className="fas fa-check" />
                  </div>
                ) : alignment === 'Diverse' ? (
                  <div
                    className={classNames(styles.icon, styles.yellow)}
                  >
                    <i className="fas fa-check" />
                  </div>
                ) : alignment === 'Similar' ? (
                  <div
                    className={classNames(styles.icon, styles.grey)}
                  >
                    <i className="fas fa-check" />
                  </div>
                ) : alignment === 'Very similar' ? (
                  <div
                    className={classNames(styles.icon, styles.green)}
                  >
                    <i className="fas fa-check-double" />
                  </div>
                ) : null}
              </div>
            ) : (
              <div>
                {alignment === 'Very diverse' ? (
                  <div
                    className={classNames(styles.icon, styles.green)}
                  >
                    <i className="fas fa-check-double" />
                  </div>
                ) : alignment === 'Diverse' ? (
                  <div
                    className={classNames(styles.icon, styles.grey)}
                  >
                    <i className="fas fa-check" />
                  </div>
                ) : alignment === 'Similar' ? (
                  <div
                    className={classNames(styles.icon, styles.yellow)}
                  >
                    <i className="fas fa-check" />
                  </div>
                ) : alignment === 'Very similar' ? (
                  <div
                    className={classNames(styles.icon, styles.red)}
                  >
                    <i className="fas fa-check" />
                  </div>
                ) : null}
              </div>
            )}

            <p>
              <strong>
                {`${highlight.first_name} is `}
                <span className={styles.lowercase}>{alignment}</span>
              </strong>

              <span>{`on ${dim.name}`}</span>
            </p>
          </div>
        </div>
      )}

      <div className={styles.listBoxWrapper}>
        {highlight.personality.find((d) => d.name === dim.name)
          .inTeamStrengths.length > 0 && (
          <div
            className={classNames(styles.listBox, styles.workBest)}
          >
            <h2>
              <i className="fas fa-bolt" />
              {casestudy
                ? 'You are stronger at:'
                : 'Stronger than the team at:'}
            </h2>
            <div className={styles.listRowWrapper}>
              {highlight.personality
                .find((d) => d.name === dim.name)
                .inTeamStrengths.map((d) => (
                  <div className={styles.listRow} key={d.short}>
                    <h4>{d.short}</h4>
                    <p>{d.long}</p>
                  </div>
                ))}
            </div>
          </div>
        )}

        <div
          className={classNames(styles.listBox, styles.mightStruggle)}
        >
          <h2>
            <i className="fas fa-exclamation-triangle" />
            Not comfortable with:
          </h2>
          <div className={styles.listRowWrapper}>
            {highlight.personality
              .find((d) => d.name === dim.name)
              .inTeamDerailers.map((d) => (
                <div className={styles.listRow} key={d.short}>
                  <h4>{d.short}</h4>
                  <p>{d.long}</p>
                </div>
              ))}
          </div>
        </div>
      </div>
      {candidate &&
        candidateDim.socDes &&
        candidateDim.socDes.length > 0 && (
          <div className={styles.interviewGuide}>
            <h2 className={styles.title}>Self-awareness check</h2>
            <h4 className={styles.subtitle}>
              Use these questions to validate whether the candidate
              has given a fair representation of their behavioural
              traits and is able to evaluate their strengths and
              potential blind spots objectively
            </h4>
            {candidateDim.socDes.map((sDim) => (
              <div key={sDim.name} className={styles.quote}>
                <i className="fas fa-quote-left" />
                <div className={styles.quoteContent}>
                  <h2>{sDim.name}</h2>
                  <h4 className={styles.quoteText}>
                    {sDim.question}
                  </h4>
                </div>

                <i className="fas fa-quote-right" />
              </div>
            ))}
          </div>
        )}
    </>
  )
}
