import { withHandlers } from "recompose";

export default withHandlers({
  handleCreateAssessmentGroup:
    ({
      history,
      createAssessmentGroup,
      groupData,
      match: {
        params: { org_id },
      },
    }) =>
    async () => {
      try {
        const {
          data: {
            createAssessmentGroup: { id },
          },
        } = await createAssessmentGroup({
          variables: { ...groupData, org_id: parseInt(org_id, 10) },
        });
        history.push(`/${org_id}/assessments`);
      } catch (err) {
        throw new Error(err);
      }
    },
});
