import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import Button from "components/UI/Button";
import UserAvatar from "components/UserAvatar";

import styles from "./UserCard.module.scss";

export default ({ viewer, selectedOrg }) => {
  const roles = {
    owner: "an owner",
    admin: "an admin",
    member: "a member",
    candidate: "a candidate",
  };

  return (
    <div
      className={classNames(
        styles.wrapper,
        selectedOrg.contract.role === "candidate" && styles.candidate
      )}
    >
      <div className={styles.left}>
        <UserAvatar member={viewer} size="mediumLarge" />
      </div>
      <div className={styles.right}>
        <h3>{"Welcome back, " + viewer.first_name + "! 👋"}</h3>
        <p>
          {"You are currently " +
            roles[selectedOrg.contract.role] +
            " of " +
            selectedOrg.name +
            "."}
        </p>
        <Link to="/personal">
          <Button bStyle={["newsimple", "verySmall"]}>View my profile</Button>
        </Link>
      </div>
    </div>
  );
};
