import React from "react";

import Description from "./Description";

import styles from "./User.module.scss";
import UserAvatar from "components/UserAvatar/index";

export default ({ user }) => (
  <div className={styles.main}>
    <div className={styles.wrapper}>
      <div className={styles.avatarWrapper}>
        <div className={styles.avatar}>
          <UserAvatar member={user} size="large" />
        </div>
      </div>
      <div className={styles.right}>
        <h2 className={styles.name}>{user.name}</h2>
        <Description user={user} />
      </div>
    </div>
  </div>
);
