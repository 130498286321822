import React from "react";
import classNames from "classnames";
import Score from "components/Score";
import Icon from "components/UI/Icon";
import DistributionBarChart from "components/Charts/DistributionBarChart";
import ViewerAvatar from "components/ViewerAvatar";
import UserAvatar from "components/UserAvatar";
import TeamMemberAvatars from "components/TeamMemberAvatars";

export default ({
  styles,
  dim,
  members,
  isImportant,
  highlight,
  resource,
  candidate,
  alignment,
  color,
  casestudy,
  isCandidate,
  member,
}) => {
  const getColour = () => {
    if ((resource === "team" ? dim.alignment : alignment) === "Super aligned") {
      return "green";
    }
    if ((resource === "team" ? dim.alignment : alignment) === "Well aligned") {
      return "grey";
    }
    if ((resource === "team" ? dim.alignment : alignment) === "May disagree") {
      return "yellow";
    }
    return styles.primary;
  };

  const showConc = resource === "personal" || (members && members.length > 1);

  return (
    <>
      <div className={styles.lineWrapper}>
        {resource === "personal" || resource === "orgUser" ? (
          <div className={styles.line}>
            <div
              className={classNames(
                styles.avatar,
                color === "green"
                  ? styles.green
                  : color === "yellow"
                  ? styles.yellow
                  : null
              )}
              style={{
                left: `calc(${dim.score > 95.12 ? 95.12 : dim.score}% - 35px)`,
              }}
            >
              <p
                className={classNames(
                  styles.avatarScore,
                  color === "green"
                    ? styles.green
                    : color === "yellow"
                    ? styles.yellow
                    : null
                )}
              >
                <Score score={dim.score} />
              </p>
              {resource === "orgUser" ? (
                <UserAvatar member={member} size="medium" />
              ) : (
                <ViewerAvatar size="medium" />
              )}
            </div>
          </div>
        ) : (
          <DistributionBarChart
            dim={dim}
            members={members}
            resource="values"
            size="large"
            colour={getColour()}
            highlight={highlight && highlight}
            candidate={candidate && candidate}
          />
        )}

        <div className={styles.opposites}>
          <div className={styles.left}>
            <h4 className={styles.label}>Less important</h4>
            <p className={styles.desc}>{dim.description_min}</p>
          </div>
          <div className={styles.right}>
            <h4 className={styles.label}>Very important</h4>
            <p className={styles.desc}>{dim.description_max}</p>
          </div>
        </div>
      </div>
      <div className={styles.asteriskWrapper} />
      {showConc && (
        <div className={styles.conclusion}>
          {dim.alignment || alignment ? (
            <div className={styles.row}>
              {(resource === "team" ? dim.alignment : alignment) ===
              "Super aligned" ? (
                <div className={classNames(styles.icon, styles.green)}>
                  <Icon icon="Check" />
                </div>
              ) : (resource === "team" ? dim.alignment : alignment) ===
                "Well aligned" ? (
                <div className={classNames(styles.icon, styles.grey)}>
                  <Icon icon="Check" />
                </div>
              ) : (resource === "team" ? dim.alignment : alignment) ===
                "May disagree" ? (
                <div className={classNames(styles.icon, styles.yellow)}>
                  <Icon icon="Check" />
                </div>
              ) : null}
              <p>
                <strong>
                  {resource === "team"
                    ? "Team"
                    : resource === "member"
                    ? highlight.first_name
                    : null}
                  {((resource === "team" && dim.alignment) ||
                    (resource === "member" && alignment)) === "May disagree"
                    ? " "
                    : " is "}
                  <span className={styles.alignmentText}>
                    {resource === "team"
                      ? dim.alignment
                      : resource === "member"
                      ? alignment
                      : null}
                  </span>
                </strong>
                on <span>{dim.name}</span>
              </p>
            </div>
          ) : null}
          {isImportant && (
            <div className={styles.row}>
              {resource === "personal" ? (
                <>
                  <div className={styles.avatar}>
                    <div className={styles.icon}>
                      <Icon icon="Check" />
                    </div>
                    <ViewerAvatar size="mediumSmall" />
                  </div>
                  <p>
                    <strong>Important value</strong>
                    to you
                  </p>
                </>
              ) : resource === "member" ? (
                <>
                  <div className={styles.avatar}>
                    <div className={styles.icon}>
                      <Icon icon="Check" />
                    </div>
                    <UserAvatar member={highlight} size="mediumSmall" />
                  </div>
                  <p>
                    <strong>
                      {`Important value to ${highlight.first_name}`}
                    </strong>
                  </p>
                </>
              ) : (
                <>
                  <div className={styles.iconImportant}>
                    <TeamMemberAvatars members={members} size="mediumSmall" />
                  </div>
                  <p>
                    <strong>Important value to team</strong>
                    (avg. score)
                  </p>
                </>
              )}
            </div>
          )}
        </div>
      )}

      <div className={styles.characteristicsWrapper}>
        <h4>
          <strong>{dim.name}</strong>
          emphasizes
        </h4>
        <div className={styles.smallCardWrapper}>
          {dim.keywords.split(",").map((keyword) => (
            <div className={styles.smallCard} key={keyword}>
              <p>{keyword}</p>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.scienceWrapper}>
        <h4>
          Scientific insights on
          <strong>{dim.name}</strong>
        </h4>
        <div className={styles.bigCard}>
          <p>{dim.science}</p>
        </div>
      </div>
      {candidate && isCandidate && !casestudy && (
        <div className={styles.interviewGuide}>
          <h2 className={styles.title}>Interview guide</h2>
          <h4 className={styles.subtitle}>
            Use the following statement to validate candidate's experience and
            attitude towards
            {` ${dim.name}.`}
          </h4>
          <div className={styles.quote}>
            <i className="fas fa-quote-left" />
            <h4 className={styles.quoteText}>
              {
                candidate.values.find((v) => v.name === dim.name)
                  .question_for_candidate
              }
            </h4>
            <i className="fas fa-quote-right" />
          </div>
        </div>
      )}
    </>
  );
};
