import { compose, withState } from "recompose";
import { graphql, withApollo } from "react-apollo";
import { loader } from "graphql.macro";
import { withRouter } from "react-router-dom";
import handleQueryLoading from "utils/loader";
import Circles from "components/Loaders/Circles";
import handleChaneName from "./handleChaneName";

const UPDATE_TEAM = loader("./editTeam3.graphql");
const REMOVE_FROM_TEAM = loader("./removeFromTeam1.graphql");
const REMOVE_TEAM_FROM_INVITE = loader("./removeTeamFromInvite.graphql");
const DELETE_TEAM = loader("./deleteTeam.graphql");
const ORGANIZATION_TEAMS_AND_POSITIONS = loader(
  "client/Queries/OrganizationTeamsAndPositions.graphql"
);

const TEAM_QUERY = loader("client/Queries/TeamQuery124.graphql");

export default compose(
  withRouter,
  withApollo,
  graphql(UPDATE_TEAM, { name: "updateTeam" }),
  graphql(REMOVE_FROM_TEAM, { name: "removeFromTeam" }),
  handleQueryLoading(Circles),
  graphql(REMOVE_TEAM_FROM_INVITE, {
    name: "removeTeamFromInvite",
    options: ({ team }) => {
      return {
        refetchQueries: ["TEAM"],
        update: async (proxy, { data: { removeTeamFromInvite } }) => {
          const data = proxy.readQuery({
            query: TEAM_QUERY,
            variables: {
              id: team.id,
            },
          });
          data.team.pendingInvites = data.team.pendingInvites.filter(
            (i) => i.id !== removeTeamFromInvite.id
          );
          proxy.writeQuery({
            query: TEAM_QUERY,
            variables: {
              id: team.id,
            },
            data,
          });
        },
      };
    },
  }),
  graphql(DELETE_TEAM, {
    name: "deleteTeam",
    options: (props) => {
      return {
        update: (proxy, { data: { deleteTeam }, dispatch }) => {
          const data = proxy.readQuery({
            query: ORGANIZATION_TEAMS_AND_POSITIONS,
            variables: {
              id: props.team.organization_id,
            },
          });

          const positionsQuery = proxy.readQuery({
            query: ORGANIZATION_TEAMS_AND_POSITIONS,
            variables: {
              id: props.team.organization_id,
            },
          });

          if (positionsQuery && positionsQuery.organization.allOpenPositions) {
            positionsQuery.organization.allOpenPositions =
              positionsQuery.organization.allOpenPositions.filter(
                (pos) => pos.parentTeam.id !== deleteTeam.id
              );

            proxy.writeQuery({
              query: ORGANIZATION_TEAMS_AND_POSITIONS,
              variables: {
                id: props.team.organization_id,
              },
              data: positionsQuery,
            });
          }

          data.organization.teams = data.organization.teams.filter(
            (team) => team.id !== deleteTeam.id
          );

          proxy.writeQuery({
            query: ORGANIZATION_TEAMS_AND_POSITIONS,
            variables: {
              id: props.team.organization_id,
            },
            data,
          });
        },
      };
    },
  }),
  handleQueryLoading(Circles),
  withState("nameValue", "setNameValue", (props) => props.team.name),
  withState("selectedOptions", "setSelectedOptions", []),
  withState("showDeletePrompt", "updateDeletePrompt", false),
  handleChaneName
);
