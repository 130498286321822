import React from "react";
import { compose, withState, withHandlers } from "recompose";
import { graphql, withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import handleLoading from "utils/loader";
import FullPage from "components/Loaders/FullPage";
import { loader } from "graphql.macro";

const POSITION_TEMPLATE = loader("./positionTemplate9.graphql");
const UPDATE_POSITION_TEMPLATE = loader("./updatePositionTemplate6.graphql");

export default compose(
  withApollo,
  withRouter,
  graphql(POSITION_TEMPLATE, {
    options: ({ match }) => ({
      variables: {
        id: parseInt(match.params.templateId, 10),
        org_id: parseInt(match.params.orgId, 10),
      },
    }),
  }),
  handleLoading(() => <FullPage />),
  withState("openModal", "updateModal", ""),
  withState(
    "nameValue",
    "setNameValue",
    (props) => props.data.positionTemplate.title
  ),
  withState(
    "descValue",
    "setDescValue",
    (props) => props.data.positionTemplate.description
  ),
  withState("saved", "setSaved", false),
  graphql(UPDATE_POSITION_TEMPLATE, {
    name: "updatePositionTemplate",
  }),
  withHandlers({
    handleUpdatePositionTemplate:
      ({ updatePositionTemplate, nameValue, descValue, setSaved, match }) =>
      () => {
        updatePositionTemplate({
          variables: {
            template_id: parseInt(match.params.templateId, 10),
            title: nameValue,
            description: descValue,
            objectives: null,
          },
        }).then((res) => {
          setSaved(true);
          setTimeout(() => setSaved(false), 4000);
        });
      },
  })
);
