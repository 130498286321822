import React from "react";
import Wrapper from "components/UI/Wrapper";
import Title from "components/UI/Title";
import Subtitle from "components/UI/Subtitle";
import TeamTopValues from "components/TopValues";
import ValuesChart from "./Chart";
import styles from "./Values.module.scss";

export default ({ team }) => (
  <div className={styles.main}>
    <Wrapper>
      <Title text="Values map" icon="fas fa-balance-scale" />
      <Subtitle text="The main motivators of team members." />
      <Subtitle
        verySmall
        text="Learn what makes the team thrive and what kind of culture would help them perform at their best."
      />
      <ValuesChart team={team} />
      {team.readyToRender &&
        team.members.some((m) => m.readyToRender) &&
        team.members.length > 0 && (
          <TeamTopValues data={team} resource="team" />
        )}
    </Wrapper>
  </div>
);
