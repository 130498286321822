import React, { useState } from "react";
import classNames from "classnames";
import Button from "components/UI/Button";
import Span from "./Span";

import styles from "./Scoring.module.scss";

const tooltips = {
  na: {
    title: "No information",
    description: "Too little or no information.",
  },
  0: {
    title: "Development area",
    description:
      "Many development indicators, no positives. Also demonstrates development indicators from the level below.",
  },
  1: {
    title: "Near requirements",
    description:
      "More development indicators than positive indicators. Few positive indicators fully achieved.",
  },
  2: {
    title: "Meets requirements",
    description:
      "All or most positive indicators achieved. Few or no development indicators demonstrated.",
  },
  3: {
    title: "Exceeds requirements",
    description:
      "All positive indicators and no development indicators. Demonstrates additional positive indicators for the next level.",
  },
};

export default ({ item, handleSaveRating, handleSaveSpanRating }) => {
  const showSpanScores =
    item.span && item.evaluation && item.evaluation !== 0 ? true : false;

  return (
    <div className={styles.scoringWrapper}>
      <div className={styles.scoring}>
        <div
          className={classNames(
            styles.button,
            item.evaluation === 0 && styles.selected
          )}
        >
          <Button
            bStyle={["none"]}
            type="button"
            onClick={() => {
              handleSaveRating({
                evaluation_type: "assessment_row",
                item_id: item.id,
                evaluation: 0,
              });
            }}
          >
            -
          </Button>
          <div className={styles.tooltip}>
            <h5>{tooltips["na"].title}</h5>
            <p>{tooltips["na"].description}</p>
          </div>
        </div>
        {[...Array(4)].map((e, i) => (
          <div
            key={`scoreBtn-${i}`}
            className={classNames(
              styles.button,
              item.evaluation === i + 1 && styles.selected
            )}
          >
            <Button
              bStyle={["none"]}
              type="button"
              onClick={() => {
                handleSaveRating({
                  evaluation_type: "assessment_row",
                  item_id: item.id,
                  evaluation: i + 1,
                });
              }}
            >
              {i + 1}
            </Button>
            <div className={styles.tooltip}>
              <h5>{tooltips[i].title}</h5>
              <p>{tooltips[i].description}</p>
            </div>
          </div>
        ))}
      </div>
      {showSpanScores && (
        <Span item={item} handleSaveSpanRating={handleSaveSpanRating} />
      )}
    </div>
  );
};
