import React, { useState } from "react";
import Icon from "components/UI/Icon";
import GPTGateway from "components/GPTGateway";

import styles from "./WisGPTInsights.module.scss";

export default ({
  wisgptContextToken,
  prompt,
  assessmentRowId,
  examplePrompts,
  collapsed,
  setCollapsed,
  conversationId,
}) => {
  return (
    <div className={styles.wisGpt}>
      {wisgptContextToken && (
        <GPTGateway
          conversationId={conversationId}
          requestType={"CANDIDATE_ASSESSMENT"}
          wisgptContextToken={wisgptContextToken}
          prompt={prompt}
          params={{ assessmentRowId }}
          collapsed={collapsed}
          setCollapsed={setCollapsed}
          examplePrompts={
            examplePrompts
              ? examplePrompts
              : [
                  "Summarize this in one paragraph",
                  "What's their most relevant accomplishment in this area?",
                  "What's the biggest risk factor I should cover during my interview?",
                  "Summarise relevant facts from interview notes",
                ]
          }
        />
      )}
    </div>
  );
};
