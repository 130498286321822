import React, { useState } from "react";
import Button from "components/UI/Button";
import Icon from "components/UI/Icon";

import styles from "./CTA.module.scss";

export const InviteToWisnio = ({ action, disabled, inviteSent }) => {
  const [invited, setInvited] = useState(false);

  return (
    <div className={styles.inviteToWisnio}>
      <Button
        bStyle={["primary", "verySmall"]}
        disabled={invited || disabled}
        onClick={() => {
          action();
          setInvited(true);
        }}
      >
        {!invited && (
          <span className={styles.icon}>
            <Icon icon="AddUser" marginRight />
          </span>
        )}
        {invited
          ? "Invite sent"
          : inviteSent
          ? "Resend invite"
          : "Invite to Wisnio"}
      </Button>
    </div>
  );
};

export const LinkedInPluginCTA = ({ action }) => (
  <div className={styles.linkedInPlugin}>
    <h4 className={styles.title}>Add candidates automatically!</h4>
    <p className={styles.subtitle}>
      Instead of adding candidates manually, you should consider installing
      Wisnio's LinkedIn plugin to enable you to import profiles instantly
    </p>
    <Button
      bStyle={["transparent", "verySmall"]}
      onClick={() => {
        window.open(
          "https://chromewebstore.google.com/detail/wisnio-extension/dacecibbecnjflfnpllalccamjojpbem",
          "_blank"
        );
      }}
    >
      <span className={styles.icon}>
        <Icon icon="Download" marginRight />
      </span>
      Download plugin
    </Button>
  </div>
);
