import React from "react";
import classNames from "classnames";

import Layout from "layouts/index";

import ScoreCard from "components/v2/UI/ScoreCard/ScoreCard/index";

import styles from "./styles.module.scss";

const StepChooseScorecard = ({
  assessmentScorecards,
  sessionData,
  setSessionData,
}) => {
  const selectedScoreCard = sessionData?.scorecard_id;

  return (
    <Layout title="Choose scorecard" icon="Clipboard">
      <div className={styles.container}>
        <div className={styles.templateSelection}>
          <div>
            <p className={styles.body}>Choose a scorecard for the assessment</p>
          </div>
          {/* TODO: Implement filters here */}
          {/* <div>{"<Filters>"}</div> */}
        </div>
        <div className={styles.grid}>
          {assessmentScorecards.map((template) => (
            <div
              key={template.id}
              className={classNames(
                styles.scorecardWrapper,
                selectedScoreCard === template.id && styles.selected
              )}
            >
              <ScoreCard
                template={template}
                sessionData={sessionData}
                setSessionData={setSessionData}
              />
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default StepChooseScorecard;
