import { withProps, withHandlers, withState, compose } from "recompose";
import { graphql } from "react-apollo";
import { loader } from "graphql.macro";
import handleLoading from "utils/loader";
import FullPage from "components/Loaders/FullPage";

const GENERATE_PROFILE_SUMMARY = loader("./generateGPTSummary1.graphql");
const DELETE_PROFILE_SUMMARY = loader("./deleteGPTSummary.graphql");
const ALL_PERSONAL_DIMENSIONS = loader(
  "client/Queries/allPersonalDimensions1.graphql"
);

export default compose(
  withState("isGenerating", "toggleGenerating", false),
  graphql(ALL_PERSONAL_DIMENSIONS),
  handleLoading(FullPage),
  withState("gptSummary", "setGptSummary", ({ viewer }) =>
    viewer.summary ? viewer.summary.text : ""
  ),
  graphql(GENERATE_PROFILE_SUMMARY, {
    name: "generateGPTSummary",
    options: ({ toggleGenerating, setGptSummary }) => {
      return {
        update: (proxy, { data: { generateProfileSummary } }) => {
          toggleGenerating(false);
          setGptSummary(generateProfileSummary.text);
        },
      };
    },
  }),
  graphql(DELETE_PROFILE_SUMMARY, {
    name: "deleteGPTSummary",
    options: ({ setGptSummary }) => {
      return {
        update: (proxy, { data: { deletePersonalProfileSummary } }) => {
          setGptSummary("");
        },
      };
    },
  }),
  withHandlers({
    handleGenerateSummary:
      ({ generateGPTSummary, toggleGenerating }) =>
      async () => {
        toggleGenerating(true);
        await generateGPTSummary();
        if (window.heap) {
          window.heap.track("create-summary-personal");
        }
      },
    handleDeleteSummary:
      ({ deleteGPTSummary }) =>
      async () => {
        await deleteGPTSummary();
        if (window.heap) {
          window.heap.track("delete-summary-personal");
        }
      },
  }),
  withProps(
    ({
      data: {
        personalProfile: { personality, competencies, values },
      },
    }) => {
      return {
        dimensions: [...personality, ...competencies, ...values],
      };
    }
  )
);
