import React from "react";
import ValuesLegends from "components/ValuesLegends";
import Wrapper from "components/UI/Wrapper";
import Title from "components/UI/Title";
import Subtitle from "components/UI/Subtitle";
import TopValues from "components/TopValues";
import compose from "./compose";
import ValuesChart from "./Chart";
import styles from "./Values.module.scss";

export default compose(({ data: { personalProfile } }) => (
  <div className={styles.main}>
    <Wrapper>
      <Title text="Values map" icon="fas fa-balance-scale" />
      <Subtitle text="Your personal motivators." />
      <Subtitle
        verySmall
        text="The long-term goals and principles that drive your actions."
      />
      <ValuesChart />
      {personalProfile.valuesChart.readyToRender && (
        <TopValues
          data={personalProfile}
          user={personalProfile}
          resource="personal"
        />
      )}
    </Wrapper>
  </div>
));
