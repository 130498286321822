const findNextEmptySlot = ({start, selection }) => {
  let response = 11
  for (let i = start; i < selection.length - 1; ++i) {
    // console.log('looping', i)

    if (selection[i + 1].survey_row_id === null) {
      // console.log('next index', i + 1, ' is empty')
      response = i + 1
      break
    }
  }
  // console.log('setting to:', response)
  return response
}


export default ({ columns, setNoTransitionIndex, noTransitionIndex, setCurrentDropHover, setIsDraggingInSelectionColumn }) => ({ source, destination }) => {
  // console.log('current no trans index: ', noTransitionIndex,)
  if (!destination || destination.droppableId === 'source') {
    // console.log('no destination or dragging to source, setting to 0')
    return setNoTransitionIndex(0)
  }

  const selectionClone = columns.selection.map((s, i) => {
    if (i === source.index) {
      return {
        obj_type_id: i,
        subject_type_id: null,
        subject_id: null,
        survey_row_id: null,
        question_dim_id: null,
        answer: null
      }
    }
    return s
  })

  const isDraggingInSelectionColumn = source.droppableId === 'selection' && destination.droppableId === 'selection'
  const destinationIsSource = isDraggingInSelectionColumn && source.index === destination.index
  const destinationIsEmpty = isDraggingInSelectionColumn ? selectionClone[destination.index] && !selectionClone[destination.index].survey_row_id: columns.selection[destination.index] && !columns.selection[destination.index].survey_row_id

  setCurrentDropHover(destination.index)
  setIsDraggingInSelectionColumn(isDraggingInSelectionColumn)

  // console.log('dragUpdate', 'destination:', destination.index, {source, destination, noTransitionIndex })


  if (destinationIsSource) {
    // console.log('destination is source setting ', destination.index)

    return setNoTransitionIndex(destination.index)
  }
  // if (source.droppableId === 'selection' && source.index === noTransitionIndex && destination.index < source.index) {
  //   console.log('source is prev transition index and destination is smaller, setting to: ', source.index)
  //   return setNoTransitionIndex(source.index)
  // }

  //
  // if (isDraggingInSelectionColumn && destination.index === source.index + 1) {
  //   console.log('destination is source + 1, loopint to find next', source.index)
  //   return setNoTransitionIndex(findNextEmptySlot({ start: source.index, selection: isDraggingInSelectionColumn ? selectionClone : columns.selection }))
  // }
  //
  //
  //

  // console.log('is destination', destination.index, 'empty? ', destinationIsEmpty)
  if (destinationIsEmpty) {
    // console.log('destination is empty, setting to', destination.index)
    return setNoTransitionIndex(destination.index)
  }

  // console.log('finding next empty index')
  return setNoTransitionIndex(findNextEmptySlot({ start: destination.index, selection: isDraggingInSelectionColumn ? selectionClone : columns.selection }))

}
