import React from "react";
import PersonalityPlaceholder from "components/Placeholders/PersonalityPlaceholder";
import Wrapper from "components/UI/Wrapper";
import Title from "components/UI/Title";
import Subtitle from "components/UI/Subtitle";
import styles from "./Personality.module.scss";
import PersonalityChart from "./Chart";
import Dimensions from "./Dimensions";

export default ({ team, candidate }) => (
  <div className={styles.main}>
    <Wrapper>
      <Title text="Personality map" icon="fas fa-dna" />
      <Subtitle
        text={`${candidate.first_name}'s unique traits and behavioural characteristics.`}
      />
      <Subtitle
        verySmall
        text={`Understand the unique behaviors ${candidate.first_name} brings to the team and think about how this would benefit your team.`}
      />
      {candidate.personality.some((dim) => dim.score === null) ? (
        <PersonalityPlaceholder resource="member" />
      ) : (
        <>
          <PersonalityChart member={candidate} team={team} />
          <Dimensions member={candidate} team={team} />
        </>
      )}
    </Wrapper>
  </div>
);
