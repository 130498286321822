import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Icon from "components/UI/Icon";
import classNames from "classnames";
import Button from "components/UI/Button";
import { ReactComponent as ProfileImg } from "images/Profile.svg";
import compose from "./compose";
import styles from "./ProfileBasedOn.module.scss";

export default compose(({ data: { surveys } }) => {
  const personalitySurveys = surveys.filter(
    (survey) => survey.type === "Personality survey"
  );
  const valuesSurveys = surveys.filter(
    (survey) => survey.type === "Values survey"
  );
  const competencySurveys = surveys.filter(
    (survey) => survey.type === "Competencies survey"
  );

  const allComplete = ![
    ...personalitySurveys,
    ...valuesSurveys,
    ...competencySurveys,
  ].some((s) => !s.complete);

  return (
    <div className={styles.main}>
      {!allComplete && (
        <div className={styles.img}>
          <ProfileImg />
        </div>
      )}
      <h5 className={styles.title}>
        {allComplete
          ? "The profile is based on these surveys"
          : "Complete your profile"}
      </h5>
      <p className={styles.subtitle}>
        {allComplete
          ? "Click on the survey to take it again"
          : "Click on a survey to take it"}
      </p>
      <div
        className={classNames(
          styles.surveys,
          allComplete && styles.allComplete
        )}
      >
        {[...personalitySurveys, ...valuesSurveys, ...competencySurveys].map(
          (survey) => (
            <Link
              className={classNames(styles.link)}
              to={`survey/${survey.id}`}
              key={survey.id}
            >
              <div
                className={classNames(
                  styles.card,
                  survey.complete && styles.complete
                )}
              >
                <div className={styles.left}>
                  <div className={styles.icon}>
                    <Icon icon={survey.complete ? "Check" : survey.icon} />
                  </div>
                  <div className={styles.text}>
                    <h5 className={styles.title}>{survey.type}</h5>
                  </div>
                </div>
                {!allComplete && (
                  <div className={styles.right}>
                    <div
                      className={classNames(
                        styles.info,
                        survey.complete && styles.complete
                      )}
                    >
                      {survey.complete ? (
                        <p>Completed</p>
                      ) : (
                        <p>{survey.duration}</p>
                      )}
                      <Icon icon="Chevron" />
                    </div>
                  </div>
                )}
              </div>
            </Link>
          )
        )}
      </div>
    </div>
  );
});
