import React from "react";
import Popup from "reactjs-popup";
import classNames from "classnames";
import CustomSelect from "components/UI/CustomSelect";
import getAlignment from "utils/getAlignment";
import { ReactComponent as HighDevImage } from "images/position/deviation.svg";
import createList from "./createList";
import Evaluations from "./Evaluations";
import MiniChart from "../MiniChart";
import compose from "./compose";

import styles from "./SummarySection.module.scss";

export default compose(
  ({
    candidate,
    viewer,
    list,
    selectedEvaluators,
    setFilter,
    setList,
    setSelectedEvaluators
  }) => {
    const boxes = document.getElementsByClassName("graphWrapper");

    const handleEvaluatorsFilter = state => {
      setList(createList(candidate, viewer, state));
    };

    const getEvaluators = () => {
      const evaluators = [];
      candidate.position.candidates.map(c => {
        c.evaluation.map(e => {
          if (!evaluators.find(c1 => c1.id === e.evaluatedBy.id)) {
            evaluators.push(e.evaluatedBy);
          }
        });
      });
      return evaluators;
    };

    const sectionComplete = type => {
      if (type === "intuition") return true;
      return !candidate[type].some(p => p.score === null);
    };

    for (const box of boxes) {
      if (box.clientHeight > 400) {
        box.classList.add(styles.overlay);
      }
    }

    let i = 0;

    return (
      <>
        <div className={styles.filter}>
          <p>Filter:</p>
          {getEvaluators().length > 0 && (
            <div className={styles.selectWrapper}>
              <CustomSelect
                cStyle="verySmall"
                icon="fas fa-user"
                customFunction={handleEvaluatorsFilter}
                placeholder="Evaluated by"
                options={getEvaluators()}
                selectedEvaluators={selectedEvaluators}
                setSelectedEvaluators={setSelectedEvaluators}
              />
            </div>
          )}
        </div>
        <div className={styles.wrapper}>
          {list.map((item, index) => {
            if (!item.bigTitle && !item.message) {
              i++;
            }
            const isFull =
              item.resource === "personality" ||
              item.resource === "values" ||
              item.resource === "intuition";

            if (item.message) {
              return <p className={styles.error}>{item.message}</p>;
            }
            if (item.bigTitle) {
              return (
                <div key={`Section-${index}`} className={styles.section}>
                  <div key={item.bigTitle} className={styles.sectionTitle}>
                    <div
                      className={classNames(styles.dot, styles[item.color])}
                    />
                    <div className={styles.text}>
                      <h3 className={styles.title}>{item.bigTitle}</h3>
                      {item.deviation >= 2 && (
                        <div className="tooltipPopupWrapper">
                          <Popup
                            trigger={
                              <p className={styles.deviation}>High deviation</p>
                            }
                            className={styles.popup}
                            position="right center"
                            on="hover"
                            arrow
                            keepTooltipInside="body"
                            closeOnDocumentClick
                            repositionOnResize
                          >
                            <div className={styles.deviationPopover}>
                              <div className={styles.highDev}>
                                <h5>High deviation</h5>
                                <p>
                                  Evaluations vary greatly. Further discussion
                                  is recommended.
                                </p>
                                <div className={styles.image}>
                                  <HighDevImage />
                                </div>
                              </div>
                            </div>
                          </Popup>
                        </div>
                      )}
                      {item.score > 0 && (
                        <div className={styles.bigScoreWrapper}>
                          <p className={styles.legend}>
                            Total
                            <br />
                            score
                          </p>
                          <div className={styles.bigScore}>
                            <h5>{item.score}</h5>
                            <p>out of 5</p>
                          </div>
                        </div>
                      )}
                      <p className={styles.desc}>{item.description}</p>
                    </div>
                  </div>
                </div>
              );
            }
            return (
              <div
                key={`listItem-${i}`}
                className={classNames(
                  styles.section,
                  styles[item.color],
                  isFull && styles.full
                )}
              >
                {!isFull && <p className={styles.number}>{i}</p>}
                <div className={styles.rightWrapper}>
                  <div className={styles.right}>
                    {!isFull && (
                      <div className={styles.titleRow}>
                        <div className={styles.left}>
                          <p className={styles.type}>{item.type}</p>
                          <h5 className={styles.title}>{item.title}</h5>
                          {item.deviation >= 2 && (
                            <div className="tooltipPopupWrapper">
                              <Popup
                                trigger={
                                  <p className={styles.deviation}>
                                    High deviation
                                  </p>
                                }
                                className={styles.popup}
                                position="right center"
                                on="hover"
                                arrow
                                keepTooltipInside="body"
                                closeOnDocumentClick
                                repositionOnResize
                              >
                                <div className={styles.deviationPopover}>
                                  <div className={styles.highDev}>
                                    <h5>High deviation</h5>
                                    <p>
                                      Evaluations vary greatly. Further
                                      discussion is recommended.
                                    </p>
                                    <div className={styles.image}>
                                      <HighDevImage />
                                    </div>
                                  </div>
                                </div>
                              </Popup>
                            </div>
                          )}
                        </div>
                        {item.score > 0 && (
                          <div className={styles.bigScoreWrapper}>
                            <p className={styles.legend}>
                              Total
                              <br />
                              score
                            </p>
                            <div className={styles.bigScore}>
                              <h5>{item.score}</h5>
                              <p>out of 5</p>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                    {!isFull && item.desc && (
                      <p className={styles.subtitle}>{item.desc}</p>
                    )}
                    {!isFull && item.label && (
                      <p
                        className={classNames(
                          styles.label,
                          styles[item.label.color]
                        )}
                      >
                        {item.label.name}
                      </p>
                    )}
                    <Evaluations item={item} />
                  </div>
                  {(item.resource === "personality" ||
                    item.resource === "values") && (
                    <div className={styles.infoSection}>
                      {item.dims &&
                        item.dims.map(d => (
                          <div key={d.dim.name} className={styles.multiple}>
                            <div className={styles.left}>
                              <h5 className={styles.title}>{d.dim.name}</h5>

                              {d.dim.descLong && (
                                <p className={styles.subtitle}>
                                  {d.dim.descLong}
                                </p>
                              )}
                              {!d.dim.descLong && d.dim.desc && (
                                <p className={styles.subtitle}>{d.dim.desc}</p>
                              )}
                              <div
                                className={classNames(
                                  styles.label,
                                  styles[d.color]
                                )}
                              >
                                <p>{d.label}</p>
                              </div>
                            </div>
                            <div
                              className={classNames(
                                styles.graphWrapper,
                                "graphWrapper"
                              )}
                            >
                              <div className={styles.content}>
                                <div className={styles.graph}>
                                  <MiniChart
                                    resource={item.resource}
                                    dim={d.dim.name}
                                    min={
                                      item.resource === "personality"
                                        ? d.dim.label_min
                                        : "Less important"
                                    }
                                    max={
                                      item.resource === "personality"
                                        ? d.dim.label_max
                                        : "Very important"
                                    }
                                    candidate={candidate}
                                    team={candidate.position.parentTeam}
                                  />
                                </div>
                                {d.dim &&
                                  ((item.resource === "personality" &&
                                    sectionComplete("personality")) ||
                                    (item.resource === "values" &&
                                      sectionComplete("values"))) && (
                                    <>
                                      <h5>Their attitude</h5>
                                      {item.resource === "personality" && (
                                        <>
                                          <p className={styles.behaviour}>
                                            {d.dim.inTeamStrengths[0].long}
                                          </p>
                                        </>
                                      )}
                                      {item.resource === "values" && (
                                        <p className={styles.behaviour}>
                                          {d.dim.score > 50
                                            ? d.dim.description_max
                                            : d.dim.description_min}
                                        </p>
                                      )}
                                    </>
                                  )}
                              </div>
                              <div className={styles.overlay} />
                            </div>
                          </div>
                        ))}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </>
    );
  }
);
