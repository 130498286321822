import React from 'react'
import Container from 'components/UI/Container'
import { Droppable } from 'react-beautiful-dnd'
import classNames from 'classnames'
import { ReactComponent as GoalAlignment } from 'images/icons/toolkits/goal-alignment.svg'
import styles from './GroupGoals.module.scss'

export default ({ isOpen }) => (
  <div className={styles.list}>
    <div className={styles.containerWrapper}>
      <Container size='large' cStyle={['noShadow', isOpen && 'active']}>
        <Droppable
          droppableId='NewGroup'
          direction='horizontal'
          className={styles.droppableBlock}
        >
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className={classNames(
                styles.droppableGroup,
                snapshot.isDraggingOver && styles.dragging
              )}
            >
              <div className={styles.row}>
                <div className={styles.col}>
                  <span className={styles.icon}>
                    <GoalAlignment />
                  </span>
                </div>
                <div className={styles.col}>
                  <div className={styles.text}>
                    <p>Drop goal here to create a new group</p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Droppable>
      </Container>
    </div>
  </div>
)
