import React from 'react'
import classNames from 'classnames'
import styles from './index.module.scss'

export default ({
  big5, isTeamMember, isCurrentUser, isTeam, candidate
}) => {
  const curve = big5.map((d, i) => {
    if (i + 1 === big5.length) return null
    const x = parseFloat(((100 / 5 / 2 + (100 / 5) * i) * 570) / 100)
    const y = parseFloat(100 - d.score)
    const nextX = parseFloat(x + ((100 / 5) * 570) / 100)
    const nextY = parseFloat(100 - big5[i + 1].score)

    if (i === 0) {
      return `M${x},${y} C${x + ((100 / 5 / 3) * 570) / 100},${y} ${nextX
        - ((100 / 5 / 3) * 570) / 100},${nextY} ${nextX},${nextY}`
    }
    return `S${nextX - ((100 / 5 / 3) * 570) / 100},${nextY} ${nextX},${nextY}`
  })
  return (
    <>
      {big5.map((dim, i) => {
        const cx = ((100 / 5 / 2 + (100 / 5) * i) * 570) / 100
        const cy = 100 - dim.score
        return (
          <g
            key={dim.name}
            className={classNames(
              isTeamMember && styles.teamMember,
              isCurrentUser && styles.isCurrentUser,
              candidate && styles.candidate
            )}
          >
            <text
              className={classNames(
                styles.score,
                isCurrentUser && styles.isCurrentUser,
                isTeamMember && styles.teamMember,
                candidate && styles.candidate
              )}
              x={`${cx}`}
              y={`${cy - 10}`}
              textAnchor='middle'
            >
              {parseInt(dim.score, 10)}
            </text>
            <circle
              className={classNames(
                styles.scoreCircle,
                isCurrentUser && styles.isCurrentUser,
                isTeamMember && styles.teamMember,
                candidate && styles.candidate
              )}
              cx={`${cx}`}
              cy={`${cy}`}
              r={4}
            />
          </g>
        )
      })}
      <path
        className={classNames(
          styles.curve,
          isTeamMember && styles.teamMember,
          isCurrentUser && styles.isCurrentUser,
          candidate && styles.candidate,
          isTeam && styles.teamAvarage
        )}
        d={`${curve.join(' ')}`}
      />
    </>
  )
}
