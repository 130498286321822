import { compose, withState, withHandlers } from "recompose";
import { graphql } from "react-apollo";
import { loader } from "graphql.macro";
import { withRouter } from "react-router-dom";
import withStore from "utils/withStore";

const CREATE_POSITION_TEMPLATE = loader("./createPositionTemplate2.graphql");
const POSITION_TEMPLATES = loader("client/Queries/PositionTemplates15.graphql");

export default compose(
  withRouter,
  withStore("account"),
  withState("nameValue", "setNameValue", ""),
  graphql(CREATE_POSITION_TEMPLATE, {
    name: "createPositionTemplate",
    options: ({ match }) => {
      return {
        update: (proxy, { data: { createPositionTemplate } }) => {
          const data = proxy.readQuery({
            query: POSITION_TEMPLATES,
            variables: {
              org_id: parseInt(match.params.orgId, 10),
            },
          });

          if (
            data.positionTemplates.filter(
              (h) => h.id === createPositionTemplate.id
            ).length === 0
          ) {
            data.positionTemplates = [
              ...data.positionTemplates,
              createPositionTemplate,
            ];
          }

          proxy.writeQuery({
            query: POSITION_TEMPLATES,
            variables: {
              org_id: parseInt(match.params.orgId, 10),
            },
            data,
          });
        },
      };
    },
  }),
  withHandlers({
    handleCreateTemplate:
      ({ selectedOrg, account, createPositionTemplate, history }) =>
      (e) => {
        e.preventDefault();
        const title = e.target.name.value;
        createPositionTemplate({
          variables: {
            title,
            account_id: account.id,
          },
        }).then((res) => {
          history.push(
            `/${selectedOrg.id}/scorecard/${res.data.createPositionTemplate.id}/edit`
          );
        });
      },
  })
);
