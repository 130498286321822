import React from "react";
import classNames from "classnames";
import Invite from "components/Invite";
import Button from "components/UI/Button";
import compose from "./compose";

import styles from "./Modal.module.scss";

export default compose(
  ({
    position,
    updateModal,
    handleAddCandidatesToPosition,
    isLoading,
    setOverlayClose,
    resource,
  }) => (
    <div className={styles.wrapper}>
      <Button bStyle="close" type="button" onClick={() => updateModal("")}>
        <i className="fal fa-times" />
      </Button>
      <div className={classNames(styles.modalWrapper)}>
        <Invite
          validationCase="position"
          org_id={position.parentOrganization.id}
          searchEnabled={position.parentOrganization.adminRights}
          updateModal={updateModal}
          setOverlayClose={setOverlayClose}
          position_id={position.id}
          team_id={position.parentTeam.id}
          handleSubmit={({ list }) => handleAddCandidatesToPosition(list)}
          color="green"
          isLoading={isLoading}
          resource={resource}
          askForName
        />
      </div>
    </div>
  )
);
