import React, { useState, useEffect } from "react";
import SidebarWrapper from "components/UI/SidebarWrapper";
import SidebarHeader from "components/UI/SidebarHeader";
import { Element } from "react-scroll";
import sortTeamCompetencies from "utils/sortTeamCompetencies";
import TeamHero from "./TeamHero";
import Competencies from "./Competencies";
import Personality from "./Personality";
import Values from "./Values";
import { useSidebar } from "utils/sidebarProvider";
import team from "./data.json";

export default () => {
  const { contentContext } = useSidebar();
  const [content, setContent] = contentContext;

  const sidebarContent = [
    {
      sectionTitle: "Navigation",
      items: [
        {
          title: "Summary",
          scrollTo: true,
        },
        {
          title: "Competencies",
          scrollTo: true,
        },
        {
          title: "Personality",
          scrollTo: true,
        },
        {
          title: "Values",
          scrollTo: true,
        },
      ],
    },
  ];

  useEffect(() => {
    setContent(sidebarContent);
  }, []);

  return (
    <SidebarWrapper>
      <SidebarHeader title={team.name} noBreadcrumbs></SidebarHeader>
      <Element name="summary">
        <TeamHero team={team} />
      </Element>
      <Element name="competencies">
        <Competencies
          team={team}
          competencies={sortTeamCompetencies(team)}
          competencyGraph={team.competencyGraph}
        />
      </Element>
      <Element name="personality">
        <Personality team={team} />
      </Element>
      <Element name="values">
        <Values team={team} />
      </Element>
    </SidebarWrapper>
  );
};
