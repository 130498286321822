import React, { useRef, useEffect, useState } from "react";
import calculatePosition from "./CalculatePosition";
import classNames from "classnames";
import PopupChart from "components/Charts/PopupChart";

import styles from "./AIContentTag.module.scss";

export default ({ tag, dim, container, highlightId }) => {
  if (!dim) return null;

  const [popoverPosition, setPopoverPosition] = useState(null);
  const [scrollTop, setScrollTop] = useState(0);

  const onScroll = (e) => {
    setScrollTop(e.target.documentElement.scrollTop);
  };

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const getDimGroup = () => {
    if (dim.groupId && dim.groupId.includes("value")) {
      return {
        group: "Values",
        color: "yellow",
      };
    } else if (
      (dim.code && dim.code.includes("compe")) ||
      dim.__typename === "CompetencyDimension"
    ) {
      return {
        group: "Competencies",
        color: "green",
      };
    } else {
      return {
        group: "Personality",
        color: "primary",
      };
    }
  };
  const popupRef = useRef();
  useEffect(() => {
    const popoverPosition = calculatePosition({
      container: container,
      tag,
      tooltip: popupRef.current,
      scrollTop,
    });

    setPopoverPosition(popoverPosition);
  }, [popupRef, scrollTop]);

  return (
    <div
      className={styles.wrapper}
      ref={popupRef}
      style={{
        ...popoverPosition,
      }}
    >
      <div className={styles.popover}>
        <div className={styles.topRow}>
          <h5 className={styles.dimTitle}>{dim.name}</h5>
          <h5
            className={classNames(styles.dimGroup, styles[getDimGroup().color])}
          >
            {getDimGroup().group}
          </h5>
        </div>
        <PopupChart
          dim={dim}
          score={dim.score}
          type="team"
          highlightId={highlightId}
        />
        <p className={styles.dimDescription}>{dim.description || dim.desc}</p>
      </div>
    </div>
  );
};
