import React from "react";
import classNames from "classnames";
import { Link } from "react-scroll";
import Popup from "reactjs-popup";
import Button from "components/UI/Button";
import Icon from "components/UI/Icon";
import styles from "./index.module.scss";
import Curve from "./curve";

export default ({
  dimensions,
  play,
  players,
  selectedDimension,
  setSelectedDimension,
}) => {
  return (
    <>
      <div className={styles.wrap}>
        {dimensions.map((dim, i) => {
          const playerScores = [];

          play.players.map((p) => {
            typeof p.effectiveness.find((e) => e.name === dim.name).score ===
              "number" &&
              playerScores.push(
                p.effectiveness.find((e) => e.name === dim.name).score
              );
          });

          const highDev =
            Math.max(...playerScores) - dim.score >= 30 ||
            dim.score - Math.min(...playerScores) >= 30;

          return (
            <Button
              onClick={() => {
                document.querySelectorAll(".playDimension")[0].scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                });
                setSelectedDimension(i);
              }}
              smooth
              offset={-36}
              key={dim.name}
            >
              <div
                className={classNames(
                  styles.dim,
                  selectedDimension === i && styles.active
                )}
              >
                <h4 className={styles.desktop}>
                  {dim.name}
                  {highDev && (
                    <div className="tooltipPopupWrapper">
                      <Popup
                        trigger={
                          <div className={styles.highDev}>
                            <Icon icon="Warning" />
                          </div>
                        }
                        className={styles.popup}
                        position="right center"
                        on="hover"
                        arrow
                        keepTooltipInside="body"
                        closeOnDocumentClick
                        repositionOnResize
                      >
                        <div className={styles.tooltipPopover}>
                          <h5>High deviation</h5>
                          <p>
                            Scores between team members vary greatly. Discuss
                            this with your team to understand the cause.
                          </p>
                        </div>
                      </Popup>
                    </div>
                  )}
                </h4>
              </div>
            </Button>
          );
        })}
        <svg
          className={styles.chart}
          viewBox="0 0 570 100"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            className={classNames(styles.scoreLine, styles.edge)}
            d="M0,0 L570,0"
          />
          <path className={styles.scoreLine} d="M0,25 L570,25" />
          <path className={styles.scoreLine} d="M0,50 L570,50" />
          <path className={styles.scoreLine} d="M0,75 L570,75" />
          <path
            className={classNames(styles.scoreLine, styles.edge)}
            d="M0,100 L570,100"
          />
          {dimensions.map((dim, i) => {
            const cx = ((100 / 5 / 2 + (100 / 5) * i) * 570) / 100;
            const cy = 100 - dim.score;
            return (
              <g key={dim.name}>
                <text
                  className={styles.score}
                  x={`${cx}`}
                  y={`${cy - 10}`}
                  textAnchor="middle"
                >
                  {parseInt(dim.score, 10)}
                </text>
                <circle
                  className={styles.scoreCircle}
                  cx={`${cx}`}
                  cy={`${cy}`}
                  r={4}
                />
              </g>
            );
          })}
          {players && players.length > 0 && (
            <Curve dimensions={dimensions} players={players} isTeam={true} />
          )}
        </svg>
      </div>

      {dimensions.map((dim) => {
        return (
          <div className={styles.mobile} key={dim.name}>
            <Link to={dim.name} smooth>
              <div className={styles.dimWrapperMobile}>
                <div className={styles.left}>
                  <h4>
                    <i className={classNames(dim.icon, styles.icon)} />
                    {dim.name}
                  </h4>
                </div>
              </div>
            </Link>
          </div>
        );
      })}
    </>
  );
};
