import React from "react";
import UserAvatar from "components/UserAvatar";

import styles from "../AssessmentResults.module.scss";

const ConclusionBox = ({ reviewer, conclusion, action }) => {
  return (
    <div className={styles.conclusionBox}>
      <div className={styles.conclusionBox__reviewer}>
        <div>
          <UserAvatar size="small" member={reviewer} />
        </div>

        <h4>{reviewer.name}</h4>
      </div>

      <p dangerouslySetInnerHTML={{ __html: conclusion }}></p>
      <p>{action}</p>
    </div>
  );
};

export default ConclusionBox;
