import { compose, withHandlers } from 'recompose'
import { graphql } from 'react-apollo'
import { loader } from 'graphql.macro'
import logOut from 'utils/logOut'
import { withRouter } from 'react-router-dom'
import withStore from 'utils/withStore'
import handleLoading from 'utils/handleLoading'
import { RESET_ORG } from 'store/actionTypes'

const INVITES_QUERY = loader('client/Queries/Invites7.graphql')
const SWITCH_ACCOUNT = loader('client/Mutations/switchAccount.graphql')

export default compose(
  withRouter,
  withStore('inviteStore', 'viewer'),
  graphql(INVITES_QUERY),
  graphql(SWITCH_ACCOUNT, {
    name: 'switchAccount'
  }),
  withHandlers({
    handleSwitchAccount:
      ({ dispatch, switchAccount, history }) =>
      (id) => {
        switchAccount({
          variables: {
            id
          }
        }).then((res) => {
          dispatch({ type: RESET_ORG })
          window.localStorage.removeItem('recentOrg')
          history.push('/dashboard')
        })
      }
  }),
  handleLoading,
  logOut
)
