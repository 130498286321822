import React from "react";
import { Router, Route } from "react-router-dom";
import { createBrowserHistory } from "history";
import Authenticated from "utils/authenticated";
import AuthRedirect from "utils/authRedirect";
import SharedRoutesAuthCheck from "utils/sharedRoutesAuth";
import ErrorBoundary from "components/Error";
import InviteRedirect from "utils/inviteRedirect";
import reduxStore from "store";
import { ROUTE_CHANGE } from "store/actionTypes";
import Dashboard from "./Dashboard";
import Teams from "./Dashboard/Teams";
import Positions from "./Dashboard/Positions";
import Survey from "./Survey";
import Signup from "./Authentication/Signup";
import Login from "./Authentication/Login";
import Invite from "./Authentication/Invite";
import Onboarding from "./Onboarding";
import Personal from "./Personal";
import Team from "./Team";
import TeamDemo from "./TeamDemo";
import TeamTools from "./TeamTools";
import TeamWorkflow from "./TeamWorkflow";
import MemberInTeam from "./MemberInTeam";
import Position from "./Position";
import EditPosition from "./EditPosition";
import CompareCandidates from "./Position/CompareCandidates";
import HiringTemplates from "./HiringTemplates";
import OrganizationSettingsGeneral from "./OrganizationSettings/General";
import OrganizationSettingsMembers from "./OrganizationSettings/ManagePeople";
import GeneralAccountSettings from "./AccountSettings/General";
import MembersAccountSettings from "./AccountSettings/Members";
import OrganizationsAccountSettings from "./AccountSettings/Organizations";
import BillingAccountSettings from "./AccountSettings/Billing";
import ValidateStripeCheckoutSuccess from "./AccountSettings/Billing/SubscriptionManagement/StripeSuccess";
import InterviewGuide from "./InterviewGuide";
import InterviewGuide2 from "./InterviewGuide2";
import MapCompetencies from "./MapCompetencies";
import Candidate from "./Candidate";
import ProfileSettings from "./ProfileSettings";
import ResetPassword from "./ResetPassword";
import MapTemplateCompetencies from "./MapTemplateCompetencies";
import Surveys from "./Surveys";
import EditTemplate from "./EditTemplate";
import OrgUser from "./OrgUser";
import NewPassword from "./NewPassword";
import AuthCallback from "./AuthCallback";
import ShareCandidate from "./Share/Candidate";
import MeetingRecorder from "./MeetingRecorder";
import Assessments from "./Assessments";
import Assessment from "./Assessment";
import AssessmentReview from "./AssessmentReview";
import AssessmentResults from "./AssessmentResults";
import AssessmentGroup from "./AssessmentGroup";
import AssessmentCreate from "./AssessmentGroup/CreateSession";
import CreateGroup from "./AssessmentGroup/CreateGroup/index";

const history = createBrowserHistory();

reduxStore.dispatch({
  type: ROUTE_CHANGE,
  payload: {
    current: history.location.pathname,
    isDirty: true,
  },
});

history.listen((e) => {
  reduxStore.dispatch({
    type: ROUTE_CHANGE,
    payload: { current: e.pathname, isDirty: true },
  });
  // console.log('updating intercom after route change')

  window.Intercom("update");

  if (window.ga) {
    window.ga(
      "set",
      "page",
      history.location.pathname + history.location.search
    );
    window.ga("send", "pageview");
  }
  if (window._hsq) {
    window._hsq.push(["setPath", history.location.pathname]);
    window._hsq.push(["trackPageView"]);
  }
});

export default () => (
  <ErrorBoundary>
    <Router history={history}>
      <Route path="/auth/success" component={AuthCallback} />
      <SharedRoutesAuthCheck>
        <Route
          exact
          path="/share/candidate/:secret"
          component={ShareCandidate}
          sidebar="Candidate"
        />
        <Route
          exact
          path="/demoteam"
          component={TeamDemo}
          sidebar="Demo team"
        />
      </SharedRoutesAuthCheck>
      <AuthRedirect>
        <Route exact path="/" component={Login} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/signup" component={Signup} />
        <Route exact path="/join/:inviteSecret" component={InviteRedirect} />
        <Route exact path="/invite/:inviteSecret" component={Invite} />

        <Route
          exact
          path="/reset-password/:resetSecret"
          component={ResetPassword}
        />
      </AuthRedirect>
      <Authenticated>
        <Route
          exact
          path="/dashboard"
          component={Dashboard}
          sidebar="Dashboard"
        />
        <Route exact path="/teams" component={Teams} sidebar="Teams" />
        <Route exact path="/hiring" component={Positions} sidebar="Hiring" />
        <Route
          exact
          path="/org/:orgId/settings"
          component={OrganizationSettingsGeneral}
          sidebar="Settings"
        />
        <Route
          exact
          path="/org/:orgId/members"
          component={OrganizationSettingsMembers}
          sidebar="Members"
        />
        <Route
          exact
          path="/org/:orgId/user/:userId"
          component={OrgUser}
          sidebar="Member"
        />
        <Route
          exact
          path="/account/:accountId/settings"
          component={GeneralAccountSettings}
          sidebar="Settings"
        />
        <Route
          exact
          path="/account/:accountId/members"
          component={MembersAccountSettings}
          sidebar="Members"
        />
        <Route
          exact
          path="/account/:accountId/organisations"
          component={OrganizationsAccountSettings}
          sidebar="Organisations"
        />
        <Route
          exact
          path="/account/:accountId/billing"
          component={BillingAccountSettings}
          sidebar="Billing"
        />
        <Route
          exact
          path="/account/:accountId/billing/success"
          component={ValidateStripeCheckoutSuccess}
          sidebar="Billing"
        />
        <Route
          exact
          path="/:orgId/scorecard/:templateId/edit"
          component={EditTemplate}
        />
        <Route path="/onboarding" component={Onboarding} />
        <Route path="/surveys" component={Surveys} />
        <Route path="/survey/:surveyId" component={Survey} />

        <Route path="/personal" component={Personal} sidebar="My profile" />
        <Route exact path="/team/:teamId" component={Team} sidebar="Team" />
        <Route
          exact
          path="/team/:teamId/toolkit"
          component={TeamTools}
          sidebar="Toolkit"
        />
        <Route
          exact
          path="/team/:teamId/workflow/:toolId/:toolType"
          component={TeamWorkflow}
          sidebar="Team tool"
        />
        <Route
          exact
          path="/team/:teamId/member/:memberId"
          component={MemberInTeam}
          sidebar="Team"
        />
        <Route
          exact
          path="/team/:teamId/scorecards"
          component={HiringTemplates}
          sidebar="Scorecards"
        />
        <Route
          exact
          path="/:orgId/scorecards"
          component={HiringTemplates}
          sidebar="Scorecards"
        />
        <Route
          exact
          path="/team/:teamId/position/:positionId"
          component={Position}
          sidebar="Position"
        />
        <Route
          exact
          path="/team/:teamId/position/:positionId/edit"
          component={EditPosition}
          sidebar="Position"
        />
        <Route
          exact
          path="/team/:teamId/position/:positionId/compare"
          component={CompareCandidates}
          sidebar="Position"
        />
        <Route
          exact
          path="/:orgId/team/:teamId/position/:positionId/:candidateType/:candidateId/interviewguide"
          component={InterviewGuide}
          sidebar="Interview Guide"
        />
        <Route
          exact
          path="/:orgId/team/:teamId/position/:positionId/:candidateType/:candidateId/interviewguide/:scorecardId"
          component={InterviewGuide2}
          sidebar="Candidate Evaluation"
        />
        <Route
          exact
          path="/position/:positionId/mapcompetencies"
          component={MapCompetencies}
        />
        <Route
          exact
          path="/:orgId/scorecard/:templateId/mapcompetencies"
          component={MapTemplateCompetencies}
        />

        <Route
          exact
          path="/:orgId/team/:teamId/position/:positionId/:candidateType/:candidateId"
          component={Candidate}
          sidebar="Candidate"
        />

        <Route
          exact
          path="/profile/settings"
          component={ProfileSettings}
          sidebar="Settings"
        />
        <Route
          exact
          path="/:org_id/assessments"
          component={Assessments}
          sidebar="Assessments"
        />
        <Route
          exact
          path="/:org_id/assessmentGroup/group/create"
          component={CreateGroup}
          sidebar="Assesment"
        />
        <Route
          exact
          path="/:org_id/assessmentGroup/:group_id"
          component={AssessmentGroup}
          sidebar="Assesment"
        />

        <Route
          exact
          path="/:org_id/assessment/:id/results"
          component={AssessmentResults}
          sidebar="Assessments"
        />
        <Route
          exact
          path="/:org_id/assessmentGroup/:group_id/session/create"
          component={AssessmentCreate}
          sidebar="Assessments"
        />

        <Route
          exact
          path="/:org_id/assessment/:session_id"
          component={Assessment}
          sidebar="Assesment"
        />

        <Route
          exact
          path="/:org_id/reviews/:review_id"
          component={AssessmentReview}
          sidebar="Assessments"
        />

        <Route exact path="/newPassword" component={NewPassword} />
        <Route exact path="/meetingRecorder" component={MeetingRecorder} />
      </Authenticated>
    </Router>
  </ErrorBoundary>
);
