import React from "react";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import Cookies from "cookies-js";
import pathToRegexp from "path-to-regexp";
import AuthenticatedUtils from "./AuthenticatedUtils";

export default compose(withRouter)(({ children, location, history }) => {
  const isAuthenticated = Cookies.get("_s") && true;
  if (
    children.some((child) =>
      pathToRegexp(child.props.path).exec(location.pathname)
    )
  ) {
    if (!isAuthenticated) {
      history.replace("/login");
      return null;
    }
    return <AuthenticatedUtils>{children}</AuthenticatedUtils>;
  }
  return null;
});
