import React from "react"
import Button from "components/UI/Button"
import ViewerAvatar from "components/ViewerAvatar"
import Modal from "react-modal"
import classNames from "classnames"
import AvatarModal from "components/Modals/AvatarModal"
import compose from "./compose"

import styles from "./ChangeAvatar.module.scss"

export default compose(
  ({
    data: { viewer },
    handleUploadAvatar,
    avatarEditor,
    toggleAvatarEditor,
    avatarFile,
    avatarEditorRef,
    fileDropRef,
    fileIsDragging,
    removeAvatar,
  }) => (
    <div className={styles.group}>
      <div className={styles.avatarWrapper}>
        <ViewerAvatar size="medium" />
      </div>
      <div className={styles.buttonGroup}>
        <div
          className={classNames(
            styles.filedrop,
            fileIsDragging && styles.isDragging
          )}
          ref={fileDropRef}
        />
        <Button bStyle={["secondary", "verySmall"]} type="file">
          <input
            type="file"
            className={styles.fileInput}
            onChange={handleUploadAvatar}
          />
          Upload
        </Button>
        <Modal
          isOpen={avatarEditor}
          shouldCloseOnOverlayClick
          className="avatarModal"
        >
          <Button
            bStyle="close"
            type="button"
            onClick={() => toggleAvatarEditor(false)}
          >
            <i className="fal fa-times" />
          </Button>
          <AvatarModal
            toggleAvatarEditor={toggleAvatarEditor}
            avatarFile={avatarFile}
            avatarEditorRef={avatarEditorRef}
          />
        </Modal>
        <Button
          bStyle={["secondary", "verySmall"]}
          type="button"
          onClick={() => removeAvatar()}
        >
          Remove
        </Button>
      </div>
    </div>
  )
)
