import React, { useState } from "react";
import TeamMembers from "components/TeamMembers";
import Wrapper from "components/UI/Wrapper";
import TopDimensions from "components/TopDimensions";
import addMembersToTeamDim from "utils/addMembersToTeamDim";
import Summary from "./Summary";

import styles from "./TeamHero.module.scss";

export default ({ team, refetch, isTour, toggleShowTeamTour }) => {
  const topDimensions = {
    competencies: team.competencies
      .filter((c) => c.dim_parent !== null)
      .sort((a, b) => b.score - a.score)
      .slice(0, 3),
    personality:
      team.readyToRender &&
      team.members.some((m) => m.readyToRender) &&
      team.strengths.short.length > 0 &&
      team.strengths.short.slice(0, 3),
    values:
      team.readyToRender &&
      team.members.some((m) => m.readyToRender) &&
      team.motivators.length > 0 &&
      team.motivators.slice(0, 3).map((motivator) => {
        return team.values.find((v) => v.name === motivator.name);
      }),
  };

  const teamMembers = team.members;

  const dimensions = [
    ...team.personality.map((dim) => ({
      ...dim,
      members: addMembersToTeamDim({
        dim,
        members: teamMembers,
        dimGroup: "personality",
      }),
    })),
    ...team.competencies.map((dim) => ({
      ...dim,
      members: addMembersToTeamDim({
        dim,
        members: teamMembers,
        dimGroup: "competencies",
      }),
    })),
    ...team.values.map((dim) => ({
      ...dim,
      members: addMembersToTeamDim({
        dim,
        members: teamMembers,
        dimGroup: "values",
      }),
    })),
  ];

  return (
    <div className={styles.main}>
      <Wrapper>
        <div className={styles.row}>
          <div className={styles.left}>
            <h4 className={styles.orgName}>{team.organization.name}</h4>
            <h2 className={styles.teamName}>{team.name}</h2>
          </div>
          <div className={styles.right}>
            <TeamMembers
              team={team}
              showRemoveBtn
              refetch={refetch}
              isTour={isTour}
              toggleShowTeamTour={toggleShowTeamTour}
            />
          </div>
        </div>
        {team.readyToRender && team.members.length > 0 && (
          <TopDimensions
            topDimensions={topDimensions}
            dimensions={dimensions}
            teamMembers={team.members}
            titles={{
              competencies: "Core competencies",
              personality: "Behavioural strengths",
              values: "Motivators",
            }}
            emptyStates={{
              competencies:
                "Waiting for team members to complete competencies survey",
              personality:
                "Waiting for team members to complete the personality survey",
              values: "Waiting for team members to complete the values survey",
            }}
          />
        )}
        <Summary team={team} />
      </Wrapper>
    </div>
  );
};

/* <Link to={`/team/${team.id}/toolkit`}>
  <Button type='button' bStyle={['blue', 'icon', 'small']}>
    <i className='fas fa-toolbox' />
    Toolkit
  </Button>
</Link> */
