import React from "react";
import { compose, branch, renderComponent, withHandlers } from "recompose";
import { graphql, withApollo } from "react-apollo";
import { loader } from "graphql.macro";
import { withRouter } from "react-router-dom";
import Circles from "components/Loaders/Circles";

const TEAM_QUERY = loader("client/Queries/TeamQuery124.graphql");

const TEAM_GOAL_GROUPS = loader("./TeamGoalGroups3.graphql");
const FINISH_TEAM_PLAY = loader("./FinishTeamPlay.graphql");

export default compose(
  withRouter,
  withApollo,
  graphql(TEAM_QUERY, {
    name: "TEAM_QUERY",
    options: (props) => {
      return {
        variables: {
          id: parseInt(props.match.params.teamId, 10),
        },
        notifyOnNetworkStatusChange: true,
      };
    },
  }),
  graphql(TEAM_GOAL_GROUPS, {
    name: "TEAM_GOAL_GROUPS",
    options: (props) => {
      return {
        variables: {
          team_id: parseInt(props.match.params.teamId, 10),
          play_id: parseInt(props.match.params.toolId, 10),
        },
        notifyOnNetworkStatusChange: true,
      };
    },
  }),
  graphql(FINISH_TEAM_PLAY, {
    name: "finishTeamPlay",
  }),
  withHandlers({
    handleFinishTeamPlay:
      ({ history, match, finishTeamPlay }) =>
      () => {
        finishTeamPlay({
          variables: {
            id: parseInt(match.params.toolId, 10),
          },
        }).then(() => {
          history.push(`/team/${parseInt(match.params.teamId, 10)}/toolkit`);
        });
      },
  }),
  branch(
    (props) => {
      const condition =
        (props.TEAM_QUERY && props.TEAM_QUERY.loading) ||
        (props.TEAM_GOAL_GROUPS && props.TEAM_GOAL_GROUPS.loading);
      return condition;
    },
    renderComponent(() => (
      <div style={{ height: "100vh" }}>
        <Circles />
      </div>
    ))
  )
);
