import React, { useState } from "react";
import classNames from "classnames";
import compose from "./compose";
import File from "./File";

import styles from "./Attachments.module.scss";
export default compose(
  ({ candidate, handleAttachmentDownload, handleDeleteAttachment }) => {
    const [showTooltip, setShowTooltip] = useState(null);

    return (
      <div className={styles.attachmentsWrapper}>
        <div className={styles.attachments}>
          {[...candidate.attachments.filter((att) => !att.isEmbedded)]
            .reverse()
            .map((file, i) => (
              <File
                key={`attachment-${file.id}-${i}`}
                file={file}
                handleAttachmentDownload={handleAttachmentDownload}
                handleDeleteAttachment={handleDeleteAttachment}
                setShowTooltip={setShowTooltip}
              />
            ))}
        </div>
        {[...candidate.attachments.filter((att) => !att.isEmbedded)].map(
          (attachment) => (
            <div
              key={`attachment-${attachment.id}`}
              data-id={attachment.id}
              className={classNames(
                styles.tooltip,
                showTooltip === attachment.id && styles.show
              )}
            >
              {attachment.file_name}
            </div>
          )
        )}
      </div>
    );
  }
);
