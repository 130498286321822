import React from 'react'
import Button from 'components/UI/Button'
import UserAvatar from 'components/UserAvatar'

import styles from './AccountLimitExceeded.module.scss'

export default ({ limitExceededMessage, setLimitExceededMessage }) => {
  return (
    <div className={styles.limitExceeded}>
      <div className={styles.icon}>
        <i className='far fa-times' />
      </div>
      <div className={styles.right}>
        <h4 className={styles.title}>{limitExceededMessage.title}</h4>
        <p className={styles.subtitle}>{limitExceededMessage.message}</p>
        {limitExceededMessage.showOwner && (
          <div className={styles.owner}>
            <div className={styles.user}>
              <div className={styles.avatar}>
                <UserAvatar
                  member={limitExceededMessage.accountOwner}
                  size='small'
                />
              </div>
              <div className={styles.right}>
                <h5 className={styles.name}>
                  {limitExceededMessage.accountOwner.name}
                </h5>
                <p className={styles.email}>
                  <a href={`mailto:${limitExceededMessage.accountOwner.email}`}>
                    {limitExceededMessage.accountOwner.email}
                  </a>
                </p>
              </div>
            </div>
          </div>
        )}
        {limitExceededMessage.subscriptionType !== 'custom'
          && limitExceededMessage.billingUrl && (
            <a href={limitExceededMessage.billingUrl}>
              <Button bStyle={['newsimple', 'nopadding']} type='button'>
                Go to billing
              </Button>
            </a>
        )}
        {limitExceededMessage.showContactUs && (
          <div className={styles.button}>
            <Button
              bStyle={['newsimple', 'nopadding']}
              type='button'
              onClick={() => window.Intercom('show')}
            >
              Contact us
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}
