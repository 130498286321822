export default ({ teamGoals, teamGoalGroups }) => {
  const rowNo = teamGoalGroups.length + 1 + 1;

  const rows = [];

  let i;
  for (i = 0; i < rowNo; i++) {
    rows.push([]);
  }

  teamGoalGroups.length > 0 &&
    teamGoalGroups.forEach((goal, index) => {
      rows[index] = {
        itemId: `item-${goal.id}`,
        id: goal.id,
        title: goal.title || "",
        goals: goal.goals,
        createdBy: goal.createdBy,
      };
    });

  teamGoals.length > 0 &&
    teamGoals.forEach((goal, index) => {
      if (
        !teamGoalGroups.some((gg) => gg.goals.some((g) => g.id === goal.id))
      ) {
        rows[rows.length - 1].push({
          itemId: `item-${goal.id}`,
          id: goal.id,
          title: goal.title || "",
          description: goal.description,
          createdBy: goal.createdBy,
        });
      }
    });

  return rows;
};
