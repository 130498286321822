import React from "react";
import Button from "components/UI/Button";
import EditorComponent from "./EditorComponent";
import compose from "./compose";

import styles from "./AboutPosition.module.scss";

export default compose(
  ({ position, summary, setSummary, handleSaveSummary, saved }) => (
    <div className={styles.main}>
      <div className={styles.wrapper}>
        <h2>
          <span className={styles.checkWrapper}>
            <i className="far fa-check" />
          </span>
          Key objectives
        </h2>
        <p>
          Define the key objectives that the new employee must achieve to be
          considered successful.
        </p>
        <div className={styles.content}>
          <EditorComponent
            value={summary}
            setValue={setSummary}
            saved={saved}
            handleSave={handleSaveSummary}
            position={position}
            editBtnTitle="Edit text"
          />
        </div>
      </div>
    </div>
  )
);
