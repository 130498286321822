import { useState, useEffect } from "react";

export const useResponsive = () => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowSize(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isMobile = windowSize <= 480;
  const isTablet = windowSize > 480 && windowSize <= 768;
  const isDesktop = windowSize > 768;
  const responsive = (width, type) => {
    if (type === "min") {
      return windowSize >= width;
    } else if (type === "max") {
      return windowSize <= width;
    }
  };

  return { isMobile, isTablet, isDesktop, responsive };
};
