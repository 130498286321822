import { compose } from "recompose";
import { loader } from "graphql.macro";
import { graphql } from "react-apollo";
import handleLoading from "utils/handleLoading";

import { withRouter } from "react-router-dom";

const ASSESSMENT_GROUPS = loader("./assessmentGroups.graphql");

export default compose(
  withRouter,
  graphql(ASSESSMENT_GROUPS, {
    options: ({
      match: {
        params: { org_id },
      },
    }) => ({
      variables: {
        org_id: parseInt(org_id, 10),
      },
    }),
  }),
  handleLoading
);
