import React from "react";
import Button from "components/UI/Button";

import styles from "./TourStep.module.scss";

export default ({ title, text, image }) => (
  <div className={styles.wrapper}>
    <div className={styles.person}>
      <div className={styles.left}>
        <div className={styles.avatar} />
        <p>Alo from Wisnio</p>
      </div>
    </div>
    <h4>{title}</h4>
    <p>{text}</p>
    {image && <div className={styles.image}>{image}</div>}
  </div>
);
