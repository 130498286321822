import React, { useState } from "react";
import Icon from "components/UI/Icon";
import styles from "./AccessRequest.module.scss";
import UserAvatar from "components/UserAvatar";
import Button from "components/UI/Button";
import classNames from "classnames";

export default ({ admins, updateModal }) => {
  const [isCopied, setIsCopied] = useState(null);
  const handleCopyEmail = ({ id, email }) => {
    navigator.clipboard.writeText(email);
    setIsCopied(id);
    setTimeout(() => {
      setIsCopied(null);
    }, 1000);
  };
  return (
    <div className={styles.wrap}>
      <header>
        <div className={styles.icon}>
          <Icon icon="Lock" />
        </div>

        <h2>Ask for access</h2>
      </header>

      <h4 className={styles.listTitle}>Workspace admins</h4>
      <div className={styles.list}>
        {admins.map(({ id, name, email, avatar, initials }) => (
          <div key={`admin-${id}`} className={styles.item}>
            <div className={styles.avatar}>
              <UserAvatar
                size="medium"
                member={{ id, name, email, avatar, initials }}
              />
            </div>
            <div className={styles.name}>{name}</div>
            <div className={styles.email}>{email}</div>
            <div
              className={styles.btn}
              onClick={() => handleCopyEmail({ id, email })}
            >
              {isCopied === id && (
                <div className={classNames("smallPopupWrapper", styles.popup)}>
                  <div className="popup-content">Copied to clipboard!</div>
                </div>
              )}

              <Button bStyle={["blue"]}>Copy email</Button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
