import React, { useState, useEffect } from "react";
import compose from "./compose";
import SidebarWrapper from "components/UI/SidebarWrapper/index";
import SidebarHeader from "components/UI/SidebarHeader/index";
import Button from "components/UI/Button/index";
import { withRouter } from "react-router-dom";
import StepIntro from "./StepIntro";
import StepChooseScorecard from "./StepChooseScorecard";
import StepReviewEmployeeSelection from "./StepReviewEmployeeSelection";
import * as Yup from "yup";

import styles from "./styles.module.scss";

export default compose((props) => {
  const {
    handleCreateAssessmentSession,
    data: { assessmentScorecards },
    sessionData,
    setSessionData,
  } = props;

  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    const schema = Yup.object().shape({
      employee_under_review: Yup.object().shape({
        obj_id: Yup.number().required(),
        type: Yup.string().required(),
      }),
      scorecard_id: Yup.number().required(),
      assessment_group_id: Yup.number().required(),
    });

    schema.isValid(sessionData).then((valid) => setIsValid(valid));
  }, [sessionData]);

  return (
    <SidebarWrapper>
      <SidebarHeader title="Create assessment session"></SidebarHeader>
      <div className={styles.stepsContainer}>
        <StepIntro />
        <StepReviewEmployeeSelection
          sessionData={sessionData}
          setSessionData={setSessionData}
        />
        <StepChooseScorecard
          assessmentScorecards={assessmentScorecards}
          sessionData={sessionData}
          setSessionData={setSessionData}
        />
        <div className={styles.createButton}>
          <Button
            bStyle={["primary"]}
            onClick={handleCreateAssessmentSession}
            disabled={!isValid}
          >
            Create session
          </Button>
        </div>
      </div>
    </SidebarWrapper>
  );
});
