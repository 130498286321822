import React from "react";
import classNames from "classnames";

import styles from "./PercentageCircle.module.scss";

const getColor = (score) => {
  if (score < 21) {
    return "grey";
  }
  if (score > 20 && score < 51) {
    return "primary";
  }
  if (score > 50 && score < 80) {
    return "green";
  }
  if (score > 79) {
    return "darkgreen";
  }
};

export default ({ percentage, color, className, simple }) => (
  <div
    className={classNames(
      styles.percentageCircleWrapper,
      simple && styles.simple,
      styles[color],
      className,
      styles[getColor(percentage)]
    )}
  >
    <svg viewBox="0 0 36 36" className={styles.circularChart}>
      <path
        className={styles.circleBg}
        d="M18 2.0845
            a 15.9155 15.9155 0 0 1 0 31.831
            a 15.9155 15.9155 0 0 1 0 -31.831"
      />
      {percentage > 0 && (
        <path
          className={styles.circle}
          strokeDasharray={`${percentage}, 100`}
          d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
        />
      )}
      {!simple && (
        <text
          x={percentage === 100 ? "8" : percentage < 10 ? "11" : "9"}
          y="20.35"
          className={classNames(
            styles.percentage,
            percentage === 100 && styles.smaller
          )}
        >
          {`${percentage}%`}
        </text>
      )}
    </svg>
  </div>
);
