import React, { useEffect } from "react";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import Button from "components/UI/Button";
import Title from "components/UI/Title";
import Subtitle from "components/UI/Subtitle";
import Field from "components/UI/Field";
import SidebarWrapper from "components/UI/SidebarWrapper";
import { useSidebar } from "utils/sidebarProvider";

import compose from "./compose";
import ChangeAvatar from "./ChangeAvatar";

import styles from "./ProfileSettings.module.scss";

export default compose(
  ({
    data: { viewer },
    handleChangePassword,
    pwError,
    pwSuccess,
    userSuccess,
    updateUser,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    email,
    setEmail,
    phone,
    setPhone,
    linkedIn,
    setLinkedIn,
    userError,
    setUserError,
  }) => {
    const { contentContext } = useSidebar();
    const [content, setContent] = contentContext;

    const sidebarContent = [];

    useEffect(() => {
      setContent(sidebarContent);
    }, []);

    return (
      <SidebarWrapper noHeader>
        <div className={styles.header}>
          <Title text="Profile settings" />
          <Subtitle
            left
            text="Make changes to your login details, profile name, avatar and more."
          />
        </div>
        <div className={styles.settings}>
          <div className={styles.left}>
            <div className={styles.form}>
              <form onSubmit={(e) => updateUser(e)}>
                <div className={styles.row}>
                  <div className={styles.input}>
                    <h4 className={styles.label}>Avatar</h4>
                    <ChangeAvatar />
                  </div>
                </div>
                <div className={styles.row}>
                  <div className={styles.input}>
                    <h4 className={styles.label}>First name *</h4>
                    <Field
                      fullWidth
                      white
                      small
                      type="text"
                      name="first_name"
                      placeholder="Your first name"
                      value={firstName}
                      onChange={(e) => {
                        setFirstName(e.target.value);
                        setUserError(null);
                      }}
                    />
                  </div>
                  <div className={styles.input}>
                    <h4 className={styles.label}>Last name *</h4>
                    <Field
                      fullWidth
                      white
                      small
                      type="text"
                      name="last_name"
                      placeholder="Your last name"
                      value={lastName}
                      onChange={(e) => {
                        setLastName(e.target.value);
                        setUserError(null);
                      }}
                    />
                  </div>
                </div>
                <div className={styles.row}>
                  <div className={styles.input}>
                    <h4 className={styles.label}>Email address *</h4>
                    <Field
                      fullWidth
                      white
                      small
                      type="email"
                      name="email"
                      placeholder="Enter your email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>
                <div className={styles.row}>
                  <div className={styles.input}>
                    <h4 className={styles.label}>Phone number</h4>
                    <div className={styles.phoneNumber}>
                      <PhoneInput
                        countrySelectProps={{ unicodeFlags: true }}
                        country="ee"
                        value={phone}
                        onChange={setPhone}
                        placeholder="Enter phone number"
                      />
                    </div>
                  </div>
                  <div className={styles.input}>
                    <h4 className={styles.label}>LinkedIn profile</h4>
                    <Field
                      fullWidth
                      white
                      small
                      type="text"
                      name="linkedin"
                      placeholder="Enter your LinkedIn profile link"
                      value={linkedIn}
                      onChange={(e) => setLinkedIn(e.target.value)}
                    />
                  </div>
                </div>
                <div className={styles.buttonGroup}>
                  <Button bStyle={["primary", "small"]} type="submit">
                    Save changes
                  </Button>
                  {userError && <p className={styles.error}>{userError}</p>}
                  {userSuccess && (
                    <p className={styles.success}>Changes saved</p>
                  )}
                </div>
              </form>
            </div>
            <form
              className={styles.changepassword}
              onSubmit={handleChangePassword}
              autoComplete="off"
            >
              <h4 className={styles.label}>Change password</h4>
              <p className={styles.sublabel}>
                It must contain atleast 8 characters, one uppercase letter, one
                lowercase letter and one digit.
              </p>
              <Field
                fullWidth
                white
                small
                type="password"
                name="old_password"
                placeholder="Enter current password"
                required
              />
              <Field
                fullWidth
                white
                small
                type="password"
                name="new_password"
                placeholder="Enter new password"
                required
              />
              <Field
                fullWidth
                white
                small
                type="password"
                name="repeat_new_password"
                placeholder="Repeat new password"
                required
              />
              <div className={styles.buttonGroup}>
                <Button bStyle={["primary", "green", "small"]} type="submit">
                  Set new password
                </Button>

                {pwSuccess && <p className={styles.success}>Success!</p>}
              </div>
              {pwError && <p className={styles.error}>{pwError}</p>}
            </form>
          </div>
        </div>
        <div className={styles.footer}>
          <h4 className={styles.label}>Wish to remove your account?</h4>
          <p className={styles.sublabel}>
            If you wish to remove your account, please contact us to resolve any
            issues which may have prompted you to leave, or to request removal.
            Simply send an email to{" "}
            <a href="mailto:support@wisnio.com">support@wisnio.com</a> or use
            chat.
          </p>
          <p className={styles.sublabel}>
            Please note that if you remove your account, all your data will be
            lost and this action cannot be undone.
          </p>
        </div>
      </SidebarWrapper>
    );
  }
);
