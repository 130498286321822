import React from "react";
import classNames from "classnames";
import styles from "./EllipsisButton.module.scss";

export default ({ type, onClick, disabled, large, extralarge, id }) => (
  <button
    id={id ? id : ""}
    className={classNames(
      styles.button,
      large && styles.large,
      extralarge && styles.extralarge
    )}
    type={type}
    onClick={onClick}
    disabled={disabled}
  >
    <i className="far fa-ellipsis-v" />
  </button>
);
