import React, { useState } from "react";
import { Link } from "react-router-dom";
import Button from "components/UI/Button";
import Icon from "components/UI/Icon";
import Modal from "react-modal";
import CompetencyModal from "components/Competencies/CompetencyModal";
import sortPositionCompetencies from "utils/sortPositionCompetencies";
import sortTeamCompetenciesForPosition from "utils/sortTeamCompetenciesForPosition";
import Footer from "components/Footer";
import classNames from "classnames";
import compose from "./compose";
import DroppableColumns from "./DroppableColumns";

import styles from "./Kanban.module.scss";

export default compose(
  ({
    position,
    handleSave,
    handleDiscard,
    showCompList,
    setShowCompList,
    openModal,
    setOpenModal,
    columns,
    setColumns,
    selectedColumns,
    setSelectedColumns,
    resource,
    headers,
  }) => {
    const [selectedCategory, setSelectedCategory] = useState("");

    const handleSelectCategory = (title) => {
      if (selectedCategory === title) {
        setSelectedCategory("");
      } else {
        setSelectedCategory(title);
      }
    };
    // console.log('columns', columns)
    const isSelected = (item) => {
      if (
        selectedColumns[0] &&
        selectedColumns[0].find((c) => c.title === item.title)
      ) {
        return styles.selected;
      }
      if (
        selectedColumns[1] &&
        selectedColumns[1].find((c) => c.title === item.title)
      ) {
        return styles.selected2;
      }
      if (
        selectedColumns[2] &&
        selectedColumns[2].find((c) => c.title === item.title)
      ) {
        return styles.selected3;
      }
    };

    const handleSetColumn = (item, key) => {
      if (!selectedColumns[key]) {
        setSelectedColumns[key]([item]);
      } else if (
        selectedColumns[key] &&
        selectedColumns[key].length < 3 &&
        !selectedColumns[key].find((c) => c.title === item.title)
      ) {
        if (key === 0) {
          setSelectedColumns([
            [...selectedColumns[0], item],
            selectedColumns[1],
            selectedColumns[2],
            selectedColumns[3].filter((c) => c.title !== item.title),
          ]);
        } else if (key === 1) {
          setSelectedColumns([
            selectedColumns[0],
            [...selectedColumns[1], item],
            selectedColumns[2],
            selectedColumns[3].filter((c) => c.title !== item.title),
          ]);
        } else if (key === 2) {
          setSelectedColumns([
            selectedColumns[0],
            selectedColumns[1],
            [...selectedColumns[2], item],
            selectedColumns[3].filter((c) => c.title !== item.title),
          ]);
        }
      } else if (
        selectedColumns[key] &&
        selectedColumns[key].find((c) => c.title === item.title)
      ) {
        setSelectedColumns([
          selectedColumns[0].filter((c) => c.title !== item.title),
          selectedColumns[1].filter((c) => c.title !== item.title),
          selectedColumns[2].filter((c) => c.title !== item.title),
          [...selectedColumns[3], item],
        ]);
      }
    };

    const handleCheck = () => {
      setColumns(selectedColumns);
      setShowCompList(false);
    };

    const handleCancel = () => {
      if (position && !position.competenciesHaveBeenConfed) {
        if (showCompList === 0) {
          setSelectedColumns([
            [],
            selectedColumns[1],
            selectedColumns[2],
            [...selectedColumns[3], ...selectedColumns[0]],
          ]);
          setShowCompList(false);
        }
        if (showCompList === 1) {
          setSelectedColumns([
            selectedColumns[0],
            [],
            selectedColumns[2],
            [...selectedColumns[3], ...selectedColumns[1]],
          ]);
          setShowCompList(false);
        }
        if (showCompList === 2) {
          setSelectedColumns([
            selectedColumns[0],
            selectedColumns[1],
            [],
            [...selectedColumns[3], ...selectedColumns[2]],
          ]);
          setShowCompList(false);
        }
      }
      setShowCompList(false);
    };

    const clusters = [
      {
        slug: "compe_people",
        title: "Interpersonal skills",
        color: "primary",
      },
      {
        slug: "results",
        title: "Results orientation",
        color: "purple",
      },
      {
        slug: "thought",
        title: "Systems thinking",
        color: "green",
      },
      {
        slug: "org_effectiveness",
        title: "Organisational effectiveness",
        color: "yellow",
      },
    ];

    return (
      <div className={styles.main}>
        {showCompList !== false && (
          <div className={styles.competenciesList}>
            <div className={styles.buttonGroup}>
              <Link to="#">
                <Button
                  bStyle={["secondary", "small"]}
                  type="button"
                  onClick={() => handleCancel()}
                >
                  <i className="fal fa-times" />
                </Button>
              </Link>
              <div className={styles.title}>
                <h4 className={styles.compTitle}>
                  <div className={styles.stars}>
                    {headers[showCompList].icon}
                  </div>
                  {headers[showCompList].title}
                </h4>
                <p>
                  Select characteristics (
                  {selectedColumns[showCompList].length || 0}
                  /3)
                </p>
              </div>
              <Link to="#">
                <Button
                  bStyle={["primary", "small"]}
                  type="button"
                  onClick={() => handleCheck()}
                >
                  <i className="fas fa-check" />
                </Button>
              </Link>
            </div>
            <div className={styles.compListWrapper}>
              <div className={styles.compListHeader}>
                <h3 className={styles.mainTitle}>
                  <i className="fas fa-clipboard-check" />
                  Competencies
                </h3>
                <p className={styles.subtitle}>
                  {resource === "survey"
                    ? "Select nine competencies that have helped you most to achieve your professional goals"
                    : "Select nine competencies that are required to achieve the objectives in the role"}
                </p>
              </div>
              {(resource === "position" || resource === "template") && (
                <>
                  <p className={styles.subtitle}>Choose category</p>
                  <div className={styles.clusters}>
                    {clusters.map((cluster, index) => (
                      <Button
                        key={"Cluster-button-" + cluster.slug}
                        bStyle={["none"]}
                        onClick={() => handleSelectCategory(cluster.slug)}
                      >
                        <div
                          key={"Cluster-" + index}
                          className={classNames(
                            styles.cluster,
                            styles[cluster.color],
                            selectedCategory === cluster.slug && styles.selected
                          )}
                        >
                          <h5>{cluster.title}</h5>
                        </div>
                      </Button>
                    ))}
                  </div>
                </>
              )}
              {[...columns[showCompList], ...columns[3]]
                .filter((c) =>
                  selectedCategory !== ""
                    ? c.dimParent === selectedCategory
                    : (c = c)
                )
                .map((item, index) => (
                  <div
                    className={classNames(
                      styles.competency,
                      isSelected(item),
                      item.coreComp && styles.coreComp,
                      item.potGap && styles.potGap,
                      resource === "survey" && styles.survey,
                      styles[item.dimParent]
                    )}
                    key={item.id}
                    onClick={() => {
                      handleSetColumn(item, showCompList);
                    }}
                  >
                    {(resource === "position" || resource === "template") && (
                      <div className={styles.clusterIcon}>
                        <Icon icon="CompCluster" />
                      </div>
                    )}
                    <div className={styles.upper}>
                      {item.coreComp && (
                        <h4
                          className={classNames(
                            styles.teamScoreTitle,
                            styles.coreComps
                          )}
                        >
                          <span className={styles.icon}>
                            <Icon icon="Users" />
                          </span>
                          Team is most confident in
                        </h4>
                      )}
                      {item.potGap && (
                        <h4
                          className={classNames(
                            styles.teamScoreTitle,
                            styles.potGaps
                          )}
                        >
                          <span className={styles.icon}>
                            <Icon icon="Users" />
                          </span>
                          Team is least confident in
                        </h4>
                      )}

                      <h4 className={styles.title}>{item.title}</h4>
                      <p className={styles.desc}>{item.desc}</p>
                    </div>
                    {(resource === "position" || resource === "template") && (
                      <div className={styles.modalIcon}>
                        <Button
                          bStyle={["none"]}
                          type="button"
                          onClick={() => setOpenModal(item.title)}
                        >
                          <Icon icon="Info" />
                        </Button>
                      </div>
                    )}
                  </div>
                ))}
            </div>
          </div>
        )}
        {showCompList === false && (
          <div className={styles.wrapper}>
            <div className={styles.headerBar}>
              <div className={styles.buttonGroup}>
                {resource === "template" ? (
                  <Button
                    bStyle={["secondary", "small"]}
                    type="button"
                    onClick={() => handleDiscard()}
                  >
                    <i className="fal fa-times" />
                  </Button>
                ) : (
                  <Link
                    to={
                      position
                        ? `/team/${position.parentTeam.id}/position/${position.id}`
                        : "/personal"
                    }
                  >
                    <Button bStyle={["secondary", "small"]} type="button">
                      <i className="fal fa-times" />
                    </Button>
                  </Link>
                )}
                <h4>
                  <i className="fas fa-clipboard-check" />
                  Competencies
                </h4>
                <Link to="#">
                  <Button
                    bStyle={["primary", "small"]}
                    type="button"
                    onClick={handleSave}
                    disabled={
                      columns[0].length < 3 ||
                      columns[1].length < 3 ||
                      columns[2].length < 3
                    }
                  >
                    <i className="fas fa-check" />
                  </Button>
                </Link>
              </div>
            </div>
            <div className={styles.kanbanBigWrapper}>
              <DroppableColumns
                columns={columns}
                headers={headers}
                setShowCompList={setShowCompList}
                setOpenModal={setOpenModal}
                resource={resource}
              />
            </div>
          </div>
        )}
        {resource === "position" && (
          <Modal
            closeTimeoutMS={350}
            onRequestClose={() => setOpenModal("")}
            isOpen={openModal !== ""}
            shouldCloseOnOverlayClick
            className={styles.modal}
          >
            <Button
              bStyle="close"
              type="button"
              onClick={() => setOpenModal("")}
            >
              <i className="fal fa-times" />
            </Button>
            <CompetencyModal
              resource="team"
              currentCompName={openModal}
              competencies={
                position
                  ? position.parentTeam.members.some((m) => m.readyToRender)
                    ? sortTeamCompetenciesForPosition(position)
                    : sortPositionCompetencies(position)
                  : columns
              }
              members={position && position.parentTeam.members}
              setShowModal={setOpenModal}
            />
          </Modal>
        )}
        {resource === "template" && (
          <Modal
            closeTimeoutMS={350}
            onRequestClose={() => setOpenModal("")}
            isOpen={openModal !== ""}
            shouldCloseOnOverlayClick
            className={styles.modal}
          >
            <Button
              bStyle="close"
              type="button"
              onClick={() => setOpenModal("")}
            >
              <i className="fal fa-times" />
            </Button>
            <CompetencyModal
              currentCompName={openModal}
              competencies={sortPositionCompetencies(position)}
              setShowModal={setOpenModal}
            />
          </Modal>
        )}
      </div>
    );
  }
);
