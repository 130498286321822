import { compose, withHandlers, withState } from "recompose";
import { withRouter } from "react-router-dom";
import { graphql } from "react-apollo";
import { loader } from "graphql.macro";

const RESEND_INVITE = loader("./resendInvite4.graphql");

export default compose(
  withRouter,
  withState("btnState", "setBtnState", {
    text: "fad fa-paper-plane",
    isLoading: false,
    hoverText: "Resend invite",
  }),
  graphql(RESEND_INVITE, { name: "resendInviteMutation" }),

  withHandlers({
    handleResendInvite:
      ({
        invite,
        resendInviteMutation,
        setBtnState,
        match: {
          params: { orgId },
        },
      }) =>
      () => {
        setBtnState({
          text: "fas fa-spinner-third",
          isLoading: true,
          disabled: true,
          hoverText: "Sending...",
        });

        resendInviteMutation({
          variables: {
            inviteId: invite.id,
            org_id: parseInt(orgId, 10),
          },
        })
          .then((res) => {
            setBtnState({
              text: "fas fa-check",
              isLoading: false,
              disabled: true,
              hoverText: "Reminder sent!",
            });
          })
          .catch((err) => {
            setBtnState({
              text: "fas fa-exclamation-triangle",
              isLoading: false,
              disabled: true,
              hoverText: "Error :(",
            });
          });
      },
  })
);
