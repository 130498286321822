import React from "react";
import SummarySection from "./SummarySection";
import SidebarWrapper from "components/UI/SidebarWrapper/index";
import SidebarHeader from "components/UI/SidebarHeader/index";
import compose from "./compose";
import Layout from "layouts/index";
import ConclusionBox from "./SummarySection/ConclusionBox";
import UserAvatar from "components/UserAvatar/index";

import styles from "./AssessmentResults.module.scss";

export default compose(({ data: { assessmentSession } }) => (
  <SidebarWrapper>
    <SidebarHeader title="Summary"></SidebarHeader>
    <Layout
      variant="2-col-header"
      title="Summary"
      icon="Clipboard"
      headerRight={
        <p>
          The summary report includes all scores and comments submitted by
          reviewers for this assessment.
        </p>
      }
    >
      <div className={styles.wrapper}>
        <div className={styles.candidate}>
          <div className={styles.avatar}>
            <UserAvatar
              member={assessmentSession.employee_under_review}
              size="medium"
            />
          </div>
          <div className={styles.right}>
            <h4 className={styles.name}>
              {assessmentSession.employee_under_review.name}
            </h4>
            <p className={styles.email}>
              {assessmentSession.employee_under_review.email}
            </p>
          </div>
        </div>
      </div>

      <div className={styles.resultsContainer}>
        <SummarySection assessmentSession={assessmentSession} />
        <div>
          <h3>Suggested actions</h3>
          <div style={{ marginLeft: 46 }}>
            {assessmentSession.reviews.map(
              ({ reviewer, conclusion, selected_action_id }) => {
                const action = assessmentSession.reviews
                  .find(
                    (review) => review.selected_action_id === selected_action_id
                  )
                  ?.scorecard_actions.find(
                    (action) => action.id === selected_action_id
                  );
                if (conclusion || action) {
                  return (
                    <ConclusionBox
                      reviewer={reviewer}
                      conclusion={conclusion}
                      action={action ? action.title : ""}
                    />
                  );
                }
                return null;
              }
            )}
          </div>
        </div>
      </div>
    </Layout>
  </SidebarWrapper>
));
