import React, { useState } from "react";
import UserAvatar from "components/UserAvatar";
import Button from "components/UI/Button";

import styles from "./AddToTeamModal.module.scss";

export default ({ setModal, candidate, team, handleAddCandidateToTeam }) => {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <div className={styles.modalWrapper}>
      <div className={styles.header}>
        <div className={styles.avatar}>
          <UserAvatar member={candidate} size="almostLarge" />
          <i className="fas fa-plus" />
        </div>
        <h2>
          {`Are you sure you wish to onboard ${candidate.name} to the team ${team.name}?`}
        </h2>
      </div>
      <h4>
        {`${candidate.name} will be removed from all positions connected to this team and will be added to the team as a team member. They will get an email notification and can view the team profile and other team members.`}
      </h4>
      <div className={styles.buttonGroup}>
        <Button
          bStyle={["primary", "large", "green"]}
          type="button"
          disabled={isLoading}
          onClick={() => {
            setIsLoading(true);
            handleAddCandidateToTeam();
          }}
        >
          <i className="fas fa-check" />
          Yes, add
        </Button>
        <Button
          bStyle={["secondary", "large"]}
          type="button"
          onClick={() => setModal(false)}
        >
          No, cancel
        </Button>
      </div>
    </div>
  );
};
