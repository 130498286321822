import React from 'react'
import { Link } from 'react-router-dom'
import { ReactComponent as Logo } from 'images/logo/default/logo.svg'
import classNames from 'classnames'
import Button from 'components/UI/Button'
import { Form, Field } from 'react-final-form'
import compose from './compose'
import validatePassword from 'utils/validatePassword'
import styles from './ResetPassword.module.scss'

export default compose(
  ({ secretIsValid, handleChangePassword, pwError, pwSuccess }) => {
    if (!secretIsValid) {
      return (
        <div className={classNames(styles.wrapper, styles.error)}>
          <Logo className={styles.logo} />
          <div className={styles.smallerWrapper}>
            <h2 className={styles.title}>Something went wrong!</h2>
            <p className={styles.subtitle}>
              If you need to reset you password, you can do that by
              clicking on "Forgot password?" on the log in page
            </p>
            <Link to="/login">
              <Button bStyle={['primary']} type="button">
                Go to the log in page
              </Button>
            </Link>
          </div>
        </div>
      )
    }
    if (pwSuccess) {
      return (
        <div className={classNames(styles.wrapper, styles.success)}>
          <Logo className={styles.logo} />
          <div className={styles.smallerWrapper}>
            <h2 className={styles.title}>Password reset!</h2>
            <p className={styles.subtitle}>
              You can now log in to your account using your new
              password.
            </p>
            <Link to="/login">
              <Button bStyle={['primary']} type="button">
                Go to the log in page
              </Button>
            </Link>
          </div>
        </div>
      )
    }
    return (
      <div className={styles.wrapper}>
        <Logo className={styles.logo} />
        <div className={styles.smallerWrapper}>
          <h2 className={styles.title}>Reset password</h2>
          <p className={styles.subtitle}>
            It must contain at least 8 characters, one uppercase
            letter, one lowercase and one digit.
          </p>

          <Form
            onSubmit={handleChangePassword}
            validate={(state) => validatePassword(state)}
            render={({
              submitError,
              handleSubmit,
              form,
              submitting,
              pristine,
              errors,
              valid,
              values
            }) => {
              return (
                <form onSubmit={handleSubmit} autoComplete="off">
                  <label htmlFor="new_password">
                    New password
                    <Field
                      name="password"
                      component="input"
                      type="password"
                      placeholder="Choose a new password"
                      required
                    />
                  </label>
                  <label htmlFor="email">
                    Repeat new password
                    <Field
                      type="password"
                      component="input"
                      name="repeatpassword"
                      placeholder="Repeat the new password"
                      required
                    />
                  </label>

                  {pwSuccess && (
                    <p className={styles.success}>Success!</p>
                  )}
                  <Button
                    bStyle={['primary']}
                    type="submit"
                    disabled={!valid || pristine || submitting}
                  >
                    Set new password
                  </Button>
                  {(!valid || submitError) && (
                    <div className={styles.submitError}>
                      {submitError && <p>{submitError}</p>}
                      {Object.values(errors).map((err, key) => {
                        return (
                          <p
                            key={`form-error-${key}`}
                            className={styles.error}
                          >
                            {Object.values(err)}
                          </p>
                        )
                      })}
                    </div>
                  )}
                </form>
              )
            }}
          />
        </div>
      </div>
    )
  }
)
