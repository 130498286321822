import React from 'react'
import Button from 'components/UI/Button'

import styles from './RemovePosition.module.scss'

export default ({ deleteOrg, updateDeletePrompt, showDeletePrompt }) => (
  <div className={styles.modalWrapper}>
    <h2 className={styles.title}>
      <i className='fas fa-trash-alt' />
      Are you sure you wish to delete this organisation?
    </h2>
    <h5 className={styles.subtitle}>
      All data will be lost and this action cannot be undone.
    </h5>

    <div className={styles.buttonGroup}>
      <Button
        bStyle={['primary', 'large', 'red']}
        type='button'
        onClick={() => deleteOrg({
          variables: {
            id: showDeletePrompt
          }
        })
        }
      >
        <i className='fas fa-trash-alt' />
        Yes, remove
      </Button>
      <Button
        bStyle={['secondary', 'large']}
        type='button'
        onClick={() => updateDeletePrompt(false)}
      >
        No, cancel
      </Button>
    </div>
    <p className={styles.dangerous}>
      <i className='fas fa-exclamation-triangle' />
      Be aware that all open positions and teams in this organisation will also
      be deleted!
    </p>
  </div>
)
