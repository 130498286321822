import { withHandlers } from "recompose";

export default withHandlers({
  handleAddCandidateToTeam:
    ({
      addCandidateToTeam,
      history,
      onboardModal: candidate,
      data: { openPosition },
    }) =>
    async () => {
      try {
        await addCandidateToTeam({
          variables: {
            user_id: candidate.id,
            team_id: openPosition.parent_team_id,
            org_id: openPosition.parent_org_id,
            pos_id: openPosition.id,
          },
        });
        if (window.Intercom) {
          window.Intercom("trackEvent", "started-candidate-onboarding", {
            user_id: candidate.id,
            team_id: openPosition.parent_team_id,
            org_id: openPosition.parent_org_id,
            pos_id: openPosition.id,
          });
        }

        history.replace(`/team/${openPosition.parent_team_id}`);
      } catch (err) {
        throw new Error(err);
      }
    },
});
