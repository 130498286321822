import React from "react"
import { withRouter } from "react-router-dom"
import { graphql } from "react-apollo"
import { compose } from "recompose"
import handleLoading from "utils/handleLoading"
import { loader } from "graphql.macro"

const TEAMMEMBER_QUERY = loader("./Queries/TeamMemberQuery.graphql")

export default compose(
  withRouter,
  graphql(TEAMMEMBER_QUERY, {
    options: (props) => {
      return {
        variables: {
          team_id: parseInt(props.match.params.teamId, 10),
          user_id: parseInt(props.match.params.memberId, 10),
        },
      }
    },
  }),
  handleLoading
)(({ data }) => {
  const truncate = (string) => {
    if (string.length > 14) return `${string.substring(0, 14)}...`
    return string
  }
  return <React.Fragment>{data.teamMember.name}</React.Fragment>
})
