import React from "react";
import { compose, withState, branch, renderComponent } from "recompose";
import { graphql, withApollo } from "react-apollo";
import { loader } from "graphql.macro";
import { withRouter } from "react-router-dom";
import Circles from "components/Loaders/Circles";
import makeGoalsFlow from "./makeGoalsFlow";

const VIEWER_QUERY = loader("client/Queries/ViewerQuery25.graphql");
const TEAM_QUERY = loader("client/Queries/TeamQuery124.graphql");

const TEAM_GOALS = loader("./TeamGoals1.graphql");

export default compose(
  withRouter,
  withApollo,
  graphql(VIEWER_QUERY, {
    name: "VIEWER_QUERY",
  }),
  withState("cardOpen", "setCardOpen", null),
  withState("newCard", "setNewCard", null),
  graphql(TEAM_QUERY, {
    name: "TEAM_QUERY",
    options: (props) => {
      return {
        variables: {
          id: parseInt(props.match.params.teamId, 10),
        },
        notifyOnNetworkStatusChange: true,
      };
    },
  }),
  graphql(TEAM_GOALS, {
    name: "TEAM_GOALS",
    options: (props) => {
      return {
        variables: {
          team_id: parseInt(props.match.params.teamId, 10),
          play_id: parseInt(props.match.params.toolId, 10),
        },
        notifyOnNetworkStatusChange: true,
      };
    },
  }),
  branch(
    (props) => {
      const condition =
        (props.TEAM_QUERY && props.TEAM_QUERY.loading) ||
        (props.TEAM_GOALS && props.TEAM_GOALS.loading) ||
        (props.VIEWER_QUERY && props.VIEWER_QUERY.loading);
      return condition;
    },
    renderComponent(() => (
      <div style={{ height: "100vh" }}>
        <Circles />
      </div>
    ))
  ),
  withState(
    "list",
    "setList",
    ({ TEAM_GOALS: { teamGoals }, VIEWER_QUERY: { viewer } }) =>
      makeGoalsFlow({ teamGoals, viewer })
  )
);
