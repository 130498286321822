import React from "react";
import classNames from "classnames";
import Button from "components/UI/Button";
import TextBox from "components/UI/TextBox";
import ModalTitle from "components/UI/ModalTitle";
import MemberRow from "components/UserRow";
import DropDown from "components/UI/DropDown";
import Popup from "reactjs-popup";
import EllipsisButton from "components/UI/EllipsisButton";
import Invite from "components/Invite";
import ResendInvite from "./ResendInvite";
import compose from "./compose";

import styles from "./Modal.module.scss";

export default compose(
  ({
    position,
    updateModal,
    addAdminsToPosition,
    handleRemoveAdmin,
    setOverlayClose,
    isLoading
  }) => {
    const isInviteOrPending = admin => {
      if (admin.type === "invite") return true;
      if (admin.type && admin.type === "invite") return true;
      if (admin.state && admin.state === "waiting_user_acceptance") return true;
      if (admin.contract && admin.contract.state === "waiting_user_acceptance")
        return true;
      return false;
    };

    return (
      <div className={styles.wrapper}>
        <Button bStyle="close" type="button" onClick={() => updateModal("")}>
          <i className="fal fa-times" />
        </Button>
        <div className={classNames(styles.modalWrapper)}>
          <div className={styles.modalHead}>
            <ModalTitle>Share access</ModalTitle>
            <p>
              The position administrator role gives permission to access and
              manage the team profile, related positions, and candidate
              profiles.
            </p>
          </div>
          {position.admins.length > 0 && (
            <div className={styles.adminsWrapper}>
              <h4>Position admins</h4>
              {position.admins.map(admin => (
                <MemberRow
                  key={`admin-${admin.email}`}
                  user={admin}
                  handleRemoveAdmin={handleRemoveAdmin}
                >
                  <div className={styles.lastCol}>
                    <div className="dropdownWrapper" id="dropdownMenu">
                      <Popup
                        keepTooltipInside="body"
                        trigger={
                          <div className={styles.avatarWrapper}>
                            <EllipsisButton />
                          </div>
                        }
                        position="bottom right"
                      >
                        <DropDown>
                          {isInviteOrPending(admin) && (
                            <li>
                              <ResendInvite invite={admin} />
                            </li>
                          )}
                          <li>
                            <Button
                              type="button"
                              bStyle={["none"]}
                              onClick={() => handleRemoveAdmin(admin)}
                            >
                              <i className="fas fa-user-slash" />
                              Remove
                            </Button>
                          </li>
                        </DropDown>
                      </Popup>
                    </div>
                  </div>
                </MemberRow>
              ))}
            </div>
          )}
          <div className={styles.formWrapper}>
            <Invite
              noheight
              validationCase="posAdmin"
              position={position}
              org_id={position.parentOrganization.id}
              team_id={position.parentTeam.id}
              position_id={position.id}
              handleSubmit={({ list }) => addAdminsToPosition(list)}
              isLoading={isLoading}
              updateModal={updateModal}
              setOverlayClose={setOverlayClose}
              resource="stakeholders"
            />
          </div>
        </div>
      </div>
    );
  }
);
