import { compose, withState, withHandlers } from "recompose";
import { withRouter } from "react-router-dom";
import { withApollo, graphql } from "react-apollo";
import { loader } from "graphql.macro";
import withStore from "utils/withStore";
import purgeTeamFromCache from "utils/purgeTeamFromCache";

const CREATE_POSITION_MUTATION = loader(
  "client/Mutations/createPosition6.graphql"
);
const ORGANIZATION_TEAMS_AND_POSITIONS = loader(
  "client/Queries/OrganizationTeamsAndPositions.graphql"
);

export default compose(
  withRouter,
  withApollo,
  withStore("selectedOrg"),
  withState("showMore", "setShowMore", false),
  withState("modal", "updateModal", { open: false }),
  withState("selectedTeam", "setSelectedTeam", (props) => props.team),
  withState("positionName", "setPositionName", ""),
  withState("formStep", "setFormStep", 0),
  graphql(CREATE_POSITION_MUTATION, {
    name: "createPosition",
    options: ({ client }) => ({
      update: (proxy, { data: { createNewPosition } }) => {
        const org =
          client.cache.data.data[
            `OrganizationType:${createNewPosition.parent_org_id}`
          ];
        if (org && org.allOpenPositions) {
          const positions = proxy.readQuery({
            query: ORGANIZATION_TEAMS_AND_POSITIONS,
            variables: {
              id: createNewPosition.parent_org_id,
            },
          });

          if (
            positions.organization &&
            positions.organization.allOpenPositions
          ) {
            positions.organization.allOpenPositions.push(createNewPosition);
            proxy.writeQuery({
              query: ORGANIZATION_TEAMS_AND_POSITIONS,
              variables: {
                id: createNewPosition.parent_org_id,
              },
              data: positions,
            });
          }
        }

        if (
          proxy.data.data[
            `${createNewPosition.parentTeam.__typename}:${createNewPosition.parentTeam.id}`
          ]
        ) {
          purgeTeamFromCache({
            cache: client.cache.data,
            team_id: createNewPosition.parentTeam.id,
            proxy,
          });
        }
      },
    }),
  }),
  withHandlers({
    handleCreatePosition:
      ({ team, createPosition, history }) =>
      (e) => {
        createPosition({
          variables: {
            name: e.name,
            parent_org: team.organization_id,
            team_id: team.id,
            template_id: e.template || null,
          },
        }).then((res) => {
          if (window.Intercom) {
            window.Intercom("trackEvent", "create-position", {
              id: res.data.createNewPosition.id,
            });
          }
          history.push(
            `/team/${team.id}/position/${res.data.createNewPosition.id}/edit`
          );
        });
      },
    handleDeletePositionTemplate:
      ({ team, deletePositionTemplate, setRemoveModal }) =>
      (id) => {
        deletePositionTemplate({
          variables: {
            id,
            org_id: parseInt(team.organization_id, 10),
          },
        }).then((res) => {
          setRemoveModal(false);
        });
      },
  })
);
