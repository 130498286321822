import React, { useEffect } from "react";
import classNames from "classnames";
import Position from "components/PositionCard";
import NoRights from "components/EmptyStates/NoRights";
import { useSidebar } from "utils/sidebarProvider";
import compose from "./compose";

import styles from "../Positions.module.scss";

export default compose(
  ({ data: orgTeams, selectedOrg, history, setOrgTeamsState }) => {
    const organization = orgTeams.organization;

    const showPlaceholders = (length) => {
      const placeholders = [];
      if (length < 4) {
        const num = 3 - length;
        for (let i = 0; i < num; i++) {
          placeholders.push(
            <div key={i} className={styles.placeholder}>
              <div className={styles.top}>
                <div className={styles.lines}>
                  <div className={styles.line}></div>
                  <div className={styles.line}></div>
                  <div className={styles.line}></div>
                  <div className={styles.line}></div>
                </div>
              </div>
              <div className={styles.bottom}>
                <div className={styles.line}></div>
              </div>
            </div>
          );
        }

        return placeholders;
      }
      return null;
    };

    const { contentContext } = useSidebar();
    const [content, setContent] = contentContext;

    const positions = [];

    organization &&
      organization.allOpenPositions &&
      organization.allOpenPositions.map((position) => {
        if (position) {
          positions.push({
            title: position.name,
            action: () =>
              history.push(
                "/team/" + position.parentTeam.id + "/position/" + position.id
              ),
            color: "green",
            initial: position.name.charAt(0).toUpperCase(),
            children: position.candidates.map((candidate) => {
              return {
                title: candidate.name,
                action: () =>
                  history.push(
                    "/" +
                      selectedOrg.id +
                      "/team/" +
                      position.parentTeam.id +
                      "/position/" +
                      position.id +
                      "/candidate/" +
                      candidate.id
                  ),
              };
            }),
          });
        }
      });

    const sidebarContent = [
      {
        items: positions,
      },
    ];

    useEffect(() => {
      setContent(sidebarContent);
    }, []);

    useEffect(() => {
      setOrgTeamsState(orgTeams);
    }, [orgTeams.organization.teams]);

    const emptyStateContent = {
      notice: {
        title: "You currently don’t have access to this feature. ",
        subtitle:
          "To fully experience Wisnio, please ask your workspace admin for access or consider setting up your own workspace.",
      },

      subtitle: "Create your own workspace to get access to:",
      list: [
        {
          title: "Powerful talent data",
          description:
            "Scientific assessments capture critical talent data on the team, the job, and shortlisted candidates to give you the insights they need.",
        },
        {
          title: "AI-powered insights",
          description:
            "The talent data is enhanced by AI-powered tools making it immediately actionable to you and to the candidates.",
        },
      ],
      thumbnail:
        "https://storage.googleapis.com/iaeqpsxmmk/onboarding_images/hiring.png",
      video: "https://storage.googleapis.com/iaeqpsxmmk/Hiring.mp4",
    };

    const noRights =
      organization &&
      !organization.adminRights &&
      organization.allOpenPositions.length < 1;

    return (
      <React.Fragment>
        {selectedOrg.id > 0 &&
          organization.allOpenPositions &&
          organization.allOpenPositions.length > 0 && (
            <div
              className={classNames(
                styles.teamsList,
                organization.allOpenPositions.length > 6 && styles.manyTeams
              )}
            >
              {organization.allOpenPositions.map((position) => (
                <Position key={position.id} position={position} />
              ))}
              {organization.adminRights &&
                showPlaceholders(organization.allOpenPositions.length)}
            </div>
          )}

        {noRights && (
          <div className={styles.noRights}>
            <NoRights
              pageTitle={"Hiring"}
              emptyStateContent={emptyStateContent}
              admins={organization.admins.active}
              isCandidate={selectedOrg.contract.role === "candidate"}
            />
          </div>
        )}
      </React.Fragment>
    );
  }
);
