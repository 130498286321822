import React, { useState } from "react";
import Modal from "react-modal";
import classNames from "classnames";
import UserAvatar from "components/UserAvatar";
import AvatarModal from "components/Modals/AvatarModal";
import Button from "components/UI/Button";
import Icon from "components/UI/Icon";
import Field from "components/UI/Field";
import Select from "components/UI/Select";
import compose from "./compose";

import styles from "./Profile.module.scss";

export default compose(
  ({
    viewer,
    blocks,
    title,
    text,
    button,
    color,
    image,
    handlePrevStep,
    handleNextStep,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    occupation,
    linkedInUrl,
    setLinkedInUrl,
    handleUploadAvatar,
    fileIsDragging,
    fileDropRef,
    avatarEditor,
    toggleAvatarEditor,
    avatarFile,
    avatarEditorRef,
    updateUser,
    userError,
    showOtherOccupationField,
    toggleOtherOccupation,
    handleSelectOccupation,
    setOtherOccupation,
    otherOccupation,
    setCompanySize,
  }) => {
    const companySizeField =
      blocks[0].fields &&
      blocks[0].fields.find((field) => field.name === "companySize");
    return (
      <div className={styles.wrapper}>
        <div className={styles.left}>
          <h1 className={styles.title}>{title}</h1>
          <p className={styles.subtitle}>{text}</p>
          <div className={styles.avatarSection}>
            <div
              className={classNames(
                styles.filedrop,
                fileIsDragging && styles.isDragging
              )}
              ref={fileDropRef}
            />
            <div className={styles.avatar}>
              <UserAvatar member={viewer} size="mediumLarge" />
            </div>
            <div className={styles.avatarButton}>
              <Modal
                isOpen={avatarEditor}
                shouldCloseOnOverlayClick
                className="avatarModal"
              >
                <Button
                  bStyle="close"
                  type="button"
                  onClick={() => toggleAvatarEditor(false)}
                >
                  <i className="fal fa-times" />
                </Button>
                <AvatarModal
                  toggleAvatarEditor={toggleAvatarEditor}
                  avatarFile={avatarFile}
                  avatarEditorRef={avatarEditorRef}
                />
              </Modal>
              <Button bStyle={["secondary"]} type="file">
                <input
                  type="file"
                  className={styles.fileInput}
                  onChange={handleUploadAvatar}
                />
                Upload avatar
              </Button>
              <p>Make sure the image is at least 512 x 512px.</p>
            </div>
          </div>
          <form className={styles.form} onSubmit={updateUser}>
            <div className={styles.row}>
              <div className={styles.column}>
                <p className={styles.label}>First name *</p>
                <Field
                  white
                  fullWidth
                  name="first_name"
                  component="input"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  placeholder="Enter first name"
                  type="text"
                  required
                />
              </div>
              <div className={styles.column}>
                <p className={styles.label}>Last name *</p>
                <Field
                  white
                  fullWidth
                  name="last_name"
                  component="input"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  placeholder="Enter last name"
                  type="text"
                  required
                />
              </div>
            </div>
            <div className={styles.row}>
              <div className={classNames(styles.column, styles.single)}>
                <p className={styles.label}>
                  What best describes your role and industry? *
                </p>
                <Select
                  fullWidth
                  name="occupation"
                  defaultValue={showOtherOccupationField ? "Other" : occupation}
                  onChange={handleSelectOccupation}
                  required
                >
                  <option hidden value="0">
                    Role and industry
                  </option>
                  <option value="Enterprise talent acquisition">
                    Enterprise talent acquisition
                  </option>
                  <option value="Executive search">Executive search</option>
                  <option value="Private equity / VC">
                    Private equity / VC
                  </option>
                  <option value="Business leader">Business leader</option>
                  <option value="Other">Other</option>
                </Select>
              </div>
            </div>
            {showOtherOccupationField && (
              <div className={styles.row}>
                <div className={classNames(styles.column, styles.single)}>
                  <p className={styles.label}>Please specify</p>
                  <Field
                    white
                    fullWidth
                    name="otherOccupation"
                    value={otherOccupation}
                    onChange={(e) => setOtherOccupation(e.target.value)}
                    component="input"
                    type="text"
                  />
                </div>
              </div>
            )}
            {companySizeField && (
              <div className={styles.row}>
                <div className={classNames(styles.column, styles.single)}>
                  <p className={styles.label}>Company size</p>
                  <Select
                    fullWidth
                    name="companySize"
                    // onChange={(e) => setCompanySize(e.target.value)}
                    required
                  >
                    <option hidden value="0">
                      Company size
                    </option>
                    {companySizeField.options[0].split(",").map((opt, i) => (
                      <option key={`company-size-option-${i}`} value={opt}>
                        {opt}
                      </option>
                    ))}
                  </Select>
                </div>
              </div>
            )}

            <div className={styles.row}>
              <div className={classNames(styles.column, styles.single)}>
                <p className={styles.label}>LinkedIn</p>
                <Field
                  white
                  fullWidth
                  name="linkedIn"
                  component="input"
                  value={linkedInUrl}
                  onChange={(e) => setLinkedInUrl(e.target.value)}
                  placeholder="https://linkedin.com/username"
                  type="text"
                />
              </div>
            </div>
            <div className={styles.buttons}>
              <Button bStyle={["primary", "small"]} type="submit">
                {button}
              </Button>
            </div>
            {userError && <p className={styles.error}>{userError}</p>}
          </form>
        </div>
        <div className={classNames(styles.right, styles[color])}>
          <div className={styles.circle}></div>
          <div className={styles.img}>
            <img src={image} />
          </div>
        </div>
      </div>
    );
  }
);
