import React from "react";
import classNames from "classnames";
import Popup from "reactjs-popup";
import Timestamp from "react-timestamp";
import UserAvatar from "components/UserAvatar";
import TrafficLight from "components/UI/TrafficLight";

import styles from "./EvaluationInfo.module.scss";

export default ({ item }) => {
  const evaluation = item.evaluation;

  const evaluationInfo = item.evaluationInfo;

  return (
    <div className="tooltipPopupWrapper">
      <Popup
        trigger={
          <div className={styles.cellTrigger}>
            <TrafficLight score={item.totalEvaluation} />
          </div>
        }
        className={styles.popup}
        position="right center"
        on="hover"
        mouseEnterDelay={300}
        arrow
        keepTooltipInside="table"
        closeOnDocumentClick
        repositionOnResize
      >
        <div className={styles.popover}>
          <div className={classNames(styles.comments)}>
            <div className={styles.top}>
              <h5 className={styles.commentsTitle}>{item.title}</h5>
              <div className={styles.trafficLight}>
                <TrafficLight score={item.totalEvaluation} />
                <p className={styles.score}>
                  {item.totalEvaluation > 0
                    ? item.totalEvaluation + " out of 5"
                    : "N/A"}
                </p>
              </div>
            </div>
            <div className={styles.evaluationsBox}>
              {evaluationInfo.map((c) => {
                const date = new Date(c.createdAt * 1000);
                return (
                  <div
                    key={`comment-${Math.random(10)}`}
                    className={styles.evaluationRow}
                  >
                    <div className={styles.left}>
                      <div className={styles.avatarWrapper}>
                        <UserAvatar member={c.evaluatedBy} size="small" />
                      </div>
                    </div>
                    <div className={styles.right}>
                      <div className={styles.scores}>
                        <div className={styles.upper}>
                          <p className={styles.name}>{c.evaluatedBy.name}</p>
                          <p className={styles.time}>
                            <Timestamp date={date} autoUpdate />
                          </p>
                        </div>
                        <div className={styles.evaluationScore}>
                          <div className={styles.trafficLight}>
                            <TrafficLight score={c.evaluation} />
                            <p className={styles.score}>
                              {c.evaluation > 0
                                ? c.evaluation + " out of 5"
                                : "N/A"}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className={styles.commentsColumn}>
                        {c.comments &&
                          c.comments.length > 0 &&
                          c.comments.map((comment) => {
                            const date2 = new Date(comment.created_at * 1000);
                            return (
                              <div
                                className={styles.commentWrapper}
                                key={comment.id}
                              >
                                <p
                                  key={comment.id}
                                  className={styles.commentMessage}
                                >
                                  {comment.comment}
                                </p>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </Popup>
    </div>
  );
};
