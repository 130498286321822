import React, { useState } from 'react'
import { ReactComponent as BulbIcon } from 'images/icons/Bulb.svg'
import TeamMemberAvatars from 'components/TeamMemberAvatars'
import PercentageCircle from 'components/UI/PercentageCircle'
import Modal from 'react-modal'
import classNames from 'classnames'
import Button from 'components/UI/Button'
import CompetencyModal from 'components/Competencies/CompetencyModal'
import MiniChart from './MiniChart'
import styles from './Insights.module.scss'

export default ({ team }) => {
  const [selected, setSelected] = useState('all')
  const [showMore, setShowMore] = useState(false)
  const [modal, updateModal] = useState('')

  const colors = {
    group_top: 'green',
    dim_top: 'green',
    group_bottom: 'red',
    dim_bottom: 'red'
  }

  const getCards = (all) => {
    if (selected === 'tops') {
      if (!showMore && !all) {
        return team.competencyInsights
          .filter(i => i.type === 'dim_top')
          .slice(0, 6)
      }
      return team.competencyInsights.filter(
        i =>  i.type === 'dim_top'
      )
    }
    if (selected === 'bottoms') {
      if (!showMore && !all) {
        return team.competencyInsights
          .filter(i => i.type === 'dim_bottom')
          .slice(0, 6)
      }
      return team.competencyInsights.filter(
        i => i.type === 'dim_bottom'
      )
    }
    if (selected === 'skills') {
      if (!showMore && !all) {
        return team.competencyInsights
          .filter(i => i.type === 'skills_development')
          .slice(0, 6)
      }
      return team.competencyInsights.filter(
        i => i.type === 'skills_development'
      )
    }
    if (!showMore && !all) {
      return team.competencyInsights.slice(0, 6)
    }
    return team.competencyInsights
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.filters}>
        <div
          className={classNames(
            styles.filter,
            selected === 'all' && styles.selected
          )}
        >
          <Button
            bStyle={['none']}
            type='button'
            onClick={() => setSelected('all')}
          >
            All insights
          </Button>
        </div>
        <div
          className={classNames(
            styles.filter,
            selected === 'tops' && styles.selected
          )}
        >
          <Button
            bStyle={['none']}
            type='button'
            onClick={() => setSelected('tops')}
          >
            Team strengths
          </Button>
        </div>
        <div
          className={classNames(
            styles.filter,
            selected === 'bottoms' && styles.selected
          )}
        >
          <Button
            bStyle={['none']}
            type='button'
            onClick={() => setSelected('bottoms')}
          >
            Potential gaps
          </Button>
        </div>
        <div
          className={classNames(
            styles.filter,
            selected === 'skills' && styles.selected
          )}
        >
          <Button
            bStyle={['none']}
            type='button'
            onClick={() => setSelected('skills')}
          >
            Unique skills
          </Button>
        </div>
      </div>
      <div className={styles.insights}>
        {getCards().map((insight, index) => {
          const members = insight.members
            && insight.members.map(id => team.members.find(m => m.id === id))
          const comp = team.competencies.find(c => c.dim_id === insight.dim_id)

          return (
            <div
              className={styles.insight}
              key={`insight-${insight.dim_id}-${index}`}
            >
              <Button
                bStyle={['none']}
                type='button'
                onClick={() => updateModal(
                  team.competencies.find(c => c.dim_id === insight.dim_id)
                    .name
                )
                }
              >
                {insight.type === 'skills_development' && (
                  <div className={styles.icon}>
                    <BulbIcon />
                  </div>
                )}
                {insight.type !== 'skills_development' && (
                  <PercentageCircle percentage={insight.score} />
                )}

                <h5 className={styles.title}>{insight.title}</h5>
                <p className={styles.description}>{insight.description}</p>
                {members && (
                  <div className={styles.avatars}>
                    <TeamMemberAvatars
                      members={members}
                      size='small'
                      showName
                    />
                  </div>
                )}
              </Button>
            </div>
          )
        })}
      </div>
      {getCards().length === 0 && (
        <div className={styles.emptyState}>
          {selected === 'skills' && (
            <p>No unique skills to show at this time.</p>
          )}
          {selected === 'tops' && (
            <p>No team strengths to show at this time.</p>
          )}
          {selected === 'bottoms' && (
            <p>No potential gaps to show at this time.</p>
          )}
        </div>
      )}
      {getCards(true).length > 6 && (
        <div className={styles.showMoreWrapper}>
          <Button
            bStyle={[showMore ? 'secondary' : 'dark', 'small']}
            type='button'
            onClick={
              showMore ? () => setShowMore(false) : () => setShowMore(true)
            }
          >
            {showMore ? 'Show less' : 'Show more'}
          </Button>
        </div>
      )}
      <Modal
        closeTimeoutMS={350}
        isOpen={!!modal}
        onRequestClose={() => updateModal('')}
        shouldCloseOnOverlayClick
        className={styles.modal}
      >
        <Button bStyle='close' type='button' onClick={() => updateModal('')}>
          <i className='fal fa-times' />
        </Button>
        <CompetencyModal
          resource='team'
          members={team.members}
          competencies={team.competencies}
          setShowModal={updateModal}
          currentCompName={modal}
        />
      </Modal>
    </div>
  )
}
