import React, { useState, useEffect } from "react";
import { Element } from "react-scroll";
import { useTour } from "@reactour/tour";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import Popup from "reactjs-popup";
import AIChat from "components/AIChat";
import DropDown from "components/UI/DropDown";
import SidebarWrapper from "components/UI/SidebarWrapper";
import SidebarHeader from "components/UI/SidebarHeader";
import Button from "components/UI/Button";
import Icon from "components/UI/Icon";
import ExploreMore from "components/UI/ExploreMore";
import sortTeamCompetencies from "utils/sortTeamCompetencies";
import { printoutRoute } from "client/authRoutes";
import Positions from "components/Positions";
import HiringSuggestions from "./HiringSuggestions";
import TeamHero from "./TeamHero";
import Competencies from "./Competencies";
import Personality from "./Personality";
import Values from "./Values";
import MembersInTeam from "./MembersInTeam";
import steps from "./steps";
import EditTeam from "./EditTeam";
import { useSidebar } from "utils/sidebarProvider";
import compose from "./compose";

import styles from "./Team.module.scss";

export default compose(
  ({ data: { refetch, team }, toggleShowTeamTour, isTour }) => {
    const { setIsOpen, setSteps } = useTour();
    const [openModal, updateModal] = useState("");

    useEffect(() => {
      if (isTour && team.members.length) {
        setSteps(steps);
        setIsOpen(true);
      }
    }, [isTour, team.members.length]);

    const { contentContext } = useSidebar();
    const [content, setContent] = contentContext;

    const sidebarContent = [
      {
        sectionTitle: "Navigation",
        items: [
          {
            title: "Competencies",
            scrollTo: true,
          },
          {
            title: "Personality",
            scrollTo: true,
          },
          {
            title: "Values",
            scrollTo: true,
          },
          {
            title: "Hiring",
            scrollTo: true,
          },
        ],
      },
    ];

    useEffect(() => {
      setContent(sidebarContent);
    }, []);

    return (
      <SidebarWrapper>
        <SidebarHeader title={team.name}>
          {team.adminRights && (
            <>
              <div className={styles.dropdownWrapper}>
                <div className="dropdownWrapper" id="dropdownMenu">
                  <Popup
                    keepTooltipInside="body"
                    trigger={
                      <Button type="button" bStyle={["verySmall", "green"]}>
                        <i className="fas fa-suitcase" />
                        Show open positions
                        <span className={styles.positionsLength}>
                          {team.openPositions.length}
                        </span>
                      </Button>
                    }
                    position="bottom center"
                  >
                    {(close) => (
                      <DropDown>
                        <Positions team={team} />
                      </DropDown>
                    )}
                  </Popup>
                </div>
              </div>
              <Button
                type="button"
                bStyle={["verySmall", "icon"]}
                onClick={() => updateModal("editTeam")}
              >
                <Icon icon="Edit" marginRight />
                Edit team
              </Button>
              <Modal
                closeTimeoutMS={350}
                isOpen={openModal === "editTeam"}
                onRequestClose={() => updateModal("")}
                shouldCloseOnOverlayClick
              >
                <EditTeam
                  team={team}
                  updateModal={updateModal}
                  refetch={refetch}
                />
              </Modal>
            </>
          )}
          {team.members.length > 0 &&
            team.members.some((m) => m.readyToRender) && (
              <Button
                bStyle={["verySmall"]}
                onClick={() =>
                  window.open(
                    `${printoutRoute}/printout/team/${
                      team.id
                    }/${team.name.replace(" ", "_")}_team_profile.pdf`,
                    "_blank"
                  )
                }
              >
                <Icon icon="Print" marginRight />
                Print
              </Button>
            )}
          {team.members.length > 0 &&
            team.members.some((m) => m.readyToRender) && (
              <Button bStyle={["verySmall"]} onClick={toggleShowTeamTour}>
                <Icon icon="Restart" marginRight />
                Restart tour
              </Button>
            )}
        </SidebarHeader>
        <AIChat
          sequence={"team_profile_chat_start"}
          contexts={["team"]}
          variables={[{ key: "team_id", value: team.id }]}
          description="An AI assistant trained to help you make sense of the Team Profile and give actionable advice."
          examplePrompts={[
            "How to make team meetings more effective and engaging for this team?",
            "What’s the one thing I should avoid when working with this team?",
            "What kinds of tasks is the team best at solving?",
            "What three things can make this team more productive?",
            "If this team were the Avengers, which superhero would they be missing?",
          ]}
          heapEvent="create-team-chatbot-prompt"
          isReady={
            team.readyToRender &&
            team.members.filter((m) => m.readyToRender).length > 0
          }
          disabledText="Team members must complete their surveys to enable this feature."
        />
        <TeamHero
          team={team}
          refetch={refetch}
          isTour={isTour}
          toggleShowTeamTour={toggleShowTeamTour}
        />
        <ExploreMore scrollTo="competencies" />
        <Element name="competencies">
          <Competencies
            team={team}
            competencies={sortTeamCompetencies(team)}
            competencyGraph={team.competencyGraph}
          />
        </Element>
        <Element name="personality">
          <Personality team={team} />
        </Element>
        <Element name="values">
          <Values team={team} />
        </Element>
        {team.readyToRender &&
          team.members.some((m) => m.readyToRender) &&
          team.members.length > 0 && (
            <Element name="hiring">
              <HiringSuggestions team={team} />
            </Element>
          )}
        <MembersInTeam team={team} refetch={refetch} />
      </SidebarWrapper>
    );
  }
);
