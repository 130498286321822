import { iconsAndColors } from "..";

const createSteps = (candidate, viewer, assessmentRows) => {
  const steps = [];
  assessmentRows.map((row) => {
    const evaluation = candidate.evaluation.find(
      (ev) => ev.item_id === row.id && ev.evaluatedBy.id === viewer.id
    )
      ? candidate.evaluation.find(
          (ev) => ev.item_id === row.id && ev.evaluatedBy.id === viewer.id
        ).evaluation
      : null;

    const comments = candidate.comments.filter((c) => c.item_id === row.id);

    row.evaluation = evaluation;
    row.comments = comments;
    row.icon = iconsAndColors[row.group_title]
      ? iconsAndColors[row.group_title].icon
      : "Flag";
    row.color = iconsAndColors[row.group_title]
      ? iconsAndColors[row.group_title].color
      : "grey";

    steps.push(row);
  });

  return steps;
};

export default createSteps;
