import React from "react";
import { withRouter } from "react-router-dom";
import { compose, withState } from "recompose";
import { graphql } from "react-apollo";
import FullPage from "components/Loaders/FullPage";
import { loader } from "graphql.macro";
import handleLoading from "utils/loader";
import createSteps from "./createSteps";

const WISGPT_CONTEXT_TOKEN = loader("./wisGptContextToken.graphql");

export default compose(
  withRouter,
  withState("collapsed", "setCollapsed", false),
  graphql(WISGPT_CONTEXT_TOKEN, {
    name: "WISGPT_CONTEXT_TOKEN",
    options: ({
      match: {
        params: { candidateId, teamId, positionId, orgId, candidateType },
      },
    }) => ({
      variables: {
        user_id:
          candidateType === "candidate" ? parseInt(candidateId, 10) : undefined,
        person_id:
          candidateType === "person" ? parseInt(candidateId, 10) : undefined,
        team_id: parseInt(teamId, 10),
        position_id: parseInt(positionId, 10),
        org_id: parseInt(orgId, 10),
        type: candidateType,
      },
    }),
  }),
  withState("steps", "setSteps", ({ candidate, viewer, assessmentRows }) =>
    createSteps(candidate, viewer, assessmentRows)
  ),
  handleLoading(() => <FullPage />)
);
