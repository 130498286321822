import React, { useState } from "react";
import Button from "components/UI/Button";
import Modal from "react-modal";
import Icon from "components/UI/Icon";
import CompetencyModal from "components/Competencies/CompetencyModal";
import sortPositionCompetencies from "utils/sortPositionCompetencies";
import sortTeamCompetenciesForPosition from "utils/sortTeamCompetenciesForPosition";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import classNames from "classnames";
import compose from "./compose";
import DroppableColumns from "./DroppableColumns";

import styles from "./Kanban.module.scss";

export default compose((props) => {
  const {
    columns,
    setColumns,
    position,
    handleSave,
    handleDiscard,
    openModal,
    setOpenModal,
    headers,
    resource,
  } = props;

  const [selectedCategory, setSelectedCategory] = useState("");

  const handleSelectCategory = (title) => {
    if (selectedCategory === title) {
      setSelectedCategory("");
    } else {
      setSelectedCategory(title);
    }
  };

  const onDragEnd = ({ source, destination, draggableId }) => {
    if (!destination) return;

    if (source.droppableId === destination.droppableId) {
      const moveItem = columns[source.droppableId][source.index];
      columns[source.droppableId][source.index] =
        columns[source.droppableId][destination.index];
      columns[source.droppableId][destination.index] = moveItem;
      setColumns(columns);
    } else {
      const sourceCol = columns[source.droppableId];
      const draggable = sourceCol.find((item) => item.id === draggableId);
      const moveResult = columns.map((col) =>
        col.filter((item) => item.id !== draggableId)
      );
      moveResult[destination.droppableId].splice(
        destination.index,
        0,
        draggable
      );

      if (
        moveResult[destination.droppableId].length > 3 &&
        destination.droppableId !== "3"
      ) {
        const sendItem =
          moveResult[destination.droppableId][destination.index + 1];
        if (sendItem === undefined) return;
        if (source.droppableId === "3") {
          moveResult[source.droppableId].splice(0, 0, sendItem);
        } else {
          moveResult[source.droppableId].splice(source.index, 0, sendItem);
        }
        moveResult[destination.droppableId] = moveResult[
          destination.droppableId
        ].filter((i) => i.id !== sendItem.id);
      }
      setColumns(moveResult);
    }
  };

  const clusters = [
    {
      slug: "compe_people",
      title: "Interpersonal skills",
      color: "primary",
    },
    {
      slug: "results",
      title: "Results orientation",
      color: "purple",
    },
    {
      slug: "thought",
      title: "Systems thinking",
      color: "green",
    },
    {
      slug: "org_effectiveness",
      title: "Organisational effectiveness",
      color: "yellow",
    },
  ];

  return (
    <div className={styles.main}>
      <div className={styles.header}>
        <div className={styles.left}>
          <h5>Desired competencies</h5>
          <p>Drag-and drop three competencies for each category</p>
        </div>
        <div className={styles.right}>
          <Button
            bStyle={["green", "verySmall"]}
            type="button"
            onClick={() => handleSave()}
            disabled={
              (props.columns[0] && props.columns[0].length < 3) ||
              (props.columns[1] && props.columns[1].length < 3) ||
              (props.columns[2] && props.columns[2].length < 3 && true)
            }
          >
            Save changes
          </Button>
          <Button
            bStyle={["secondary", "verySmall"]}
            type="button"
            onClick={() => handleDiscard()}
          >
            Discard & exit
          </Button>
        </div>
      </div>
      <div className={styles.wrapper}>
        <DragDropContext onDragEnd={onDragEnd}>
          <div className={styles.leftSide}>
            <div className={styles.compListWrapper}>
              {(resource === "position" || resource === "template") && (
                <>
                  <p className={styles.subtitle}>Choose category</p>
                  <div className={styles.clusters}>
                    {clusters.map((cluster, index) => (
                      <Button
                        bStyle={["none"]}
                        onClick={() => handleSelectCategory(cluster.slug)}
                      >
                        <div
                          key={"Cluster-" + index}
                          className={classNames(
                            styles.cluster,
                            styles[cluster.color],
                            selectedCategory === cluster.slug && styles.selected
                          )}
                        >
                          <h5>{cluster.title}</h5>
                        </div>
                      </Button>
                    ))}
                  </div>
                </>
              )}
              <p className={styles.subtitle}>Drag to choose competency</p>
              <Droppable droppableId="3">
                {(provided, snapshot) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {props.columns[3]
                      .filter((c) =>
                        selectedCategory !== ""
                          ? c.dimParent === selectedCategory
                          : (c = c)
                      )
                      .map((item, index) => (
                        <Draggable
                          key={item.id}
                          draggableId={`${item.id}`}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <div
                                onClick={() =>
                                  (resource === "position" ||
                                    resource === "template") &&
                                  setOpenModal(item.title)
                                }
                              >
                                <div
                                  className={classNames(
                                    styles.competency,
                                    styles[item.dimParent]
                                  )}
                                  key={item.id}
                                >
                                  {(resource === "position" ||
                                    resource === "template") && (
                                    <div className={styles.clusterIcon}>
                                      <Icon icon="CompCluster" />
                                    </div>
                                  )}
                                  <div className={styles.upper}>
                                    {item.coreComp && (
                                      <h4
                                        className={classNames(
                                          styles.teamScoreTitle,
                                          styles.coreComps
                                        )}
                                      >
                                        <span className={styles.icon}>
                                          <Icon icon="Users" />
                                        </span>
                                        Team is most confident in
                                      </h4>
                                    )}
                                    {item.potGap && (
                                      <h4
                                        className={classNames(
                                          styles.teamScoreTitle,
                                          styles.potGaps
                                        )}
                                      >
                                        <span className={styles.icon}>
                                          <Icon icon="Users" />
                                        </span>
                                        Team is least confident in
                                      </h4>
                                    )}

                                    <h4 className={styles.title}>
                                      {item.title}
                                    </h4>
                                    <p className={styles.desc}>{item.desc}</p>
                                  </div>
                                  {(resource === "position" ||
                                    resource === "template") && (
                                    <div className={styles.modalIcon}>
                                      <Button
                                        bStyle={["none"]}
                                        type="button"
                                        onClick={() => setOpenModal(item.title)}
                                      >
                                        <Icon icon="Info" />
                                      </Button>
                                    </div>
                                  )}
                                  <div className={styles.dragIcon}>
                                    <Icon icon="Drag" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
          </div>
          <div className={styles.rightSide}>
            <div className={styles.kanbanBigWrapper}>
              <DroppableColumns
                columns={columns}
                setOpenModal={setOpenModal}
                headers={headers}
                resource={resource}
              />
            </div>
          </div>
        </DragDropContext>
      </div>
      {resource === "position" && (
        <Modal
          closeTimeoutMS={350}
          onRequestClose={() => setOpenModal("")}
          isOpen={openModal !== ""}
          shouldCloseOnOverlayClick
          className={styles.modal}
        >
          <Button bStyle="close" type="button" onClick={() => setOpenModal("")}>
            <i className="fal fa-times" />
          </Button>
          <CompetencyModal
            resource="team"
            currentCompName={openModal}
            competencies={
              position.parentTeam.members.some((m) => m.readyToRender)
                ? sortTeamCompetenciesForPosition(position)
                : sortPositionCompetencies(position)
            }
            members={position.parentTeam.members}
            setShowModal={setOpenModal}
          />
        </Modal>
      )}
      {resource === "template" && (
        <Modal
          closeTimeoutMS={350}
          onRequestClose={() => setOpenModal("")}
          isOpen={openModal !== ""}
          shouldCloseOnOverlayClick
          className={styles.modal}
        >
          <Button bStyle="close" type="button" onClick={() => setOpenModal("")}>
            <i className="fal fa-times" />
          </Button>
          <CompetencyModal
            currentCompName={openModal}
            competencies={sortPositionCompetencies(position)}
            setShowModal={setOpenModal}
          />
        </Modal>
      )}
    </div>
  );
});
