import React from "react";
import classNames from "classnames";
import DOMPurify from "dompurify";
import Button from "components/UI/Button";
import Icon from "components/UI/Icon";
import styles from "./StartModal.module.scss";

export default ({ survey, setOpenModal, history }) => {
  const getContentHtml = content => ({ __html: DOMPurify.sanitize(content) });

  return (
    <div className={styles.modal}>
      <div className={styles.backButton}>
        <Button bStyle={["none"]} onClick={() => history.goBack()}>
          <div className={styles.icon}>
            <Icon icon="Arrow" />
          </div>
        </Button>
      </div>
      <div className={styles.left}>
        <div
          className={styles.content}
          dangerouslySetInnerHTML={getContentHtml(survey.intro.text)}
        ></div>
        <Button
          bStyle={["primary"]}
          type="button"
          onClick={() => setOpenModal(false)}
        >
          Continue
        </Button>
      </div>
      <div className={classNames(styles.right, styles[survey.intro.color])}>
        <div className={styles.image}>
          <img src={survey.intro.image} />
        </div>
      </div>
    </div>
  );
};
