import React from 'react'
import classNames from 'classnames'
import Score from 'components/Score'
import UserAvatar from 'components/UserAvatar'
import ViewerAvatar from 'components/ViewerAvatar'

import styles from './CompetencyGraph.module.scss'

export default ({
  members,
  highlight,
  comp,
  competencies,
  resource
}) => {
  const score =
    highlight && (resource === 'personal' || resource === 'orgUser')
      ? competencies.find((c) => c.name === comp.name).score
      : highlight &&
        highlight.competencies.find((c) => c.name === comp.name).score
  return (
    <div className={styles.graphWrapper}>
      <div className={classNames(styles.lineWrapper)}>
        <div className={styles.line}>
          <div
            className={classNames(styles.distribution)}
            style={{
              left: `${0}%`
            }}
          />
          {(resource !== 'personal' || resource !== 'orgUser') &&
            members &&
            members.length > 0 && (
              <div
                className={classNames(styles.teamScore)}
                style={{ left: `${comp.score}%` }}
              >
                <div className={styles.number}>
                  <Score score={comp.score} scoreStyle={styles} />
                </div>
              </div>
            )}
          {members &&
            members.map((member) => {
              if (highlight && member.id === highlight.id) return null
              const memberComp = member.competencies.find(
                (c) => c.name === comp.name
              )

              if (memberComp.score || memberComp.score === 0) {
                return (
                  <div
                    key={member.id}
                    className={classNames(
                      styles.wrapper,
                      styles.large
                    )}
                    style={{ left: `${memberComp.score}%` }}
                  >
                    <UserAvatar
                      member={member}
                      size="medium"
                      showName
                    />
                  </div>
                )
              }
            })}
          {highlight && (
            <div
              key={highlight.id}
              className={classNames(
                styles.wrapper,
                styles.large,
                styles.highlight
              )}
              style={{
                left: `${score}%`
              }}
            >
              {resource === 'personal' ? (
                <ViewerAvatar size="medium" />
              ) : (
                <UserAvatar
                  member={highlight}
                  size="medium"
                  showName
                />
              )}
              <div
                className={classNames(
                  styles.number,
                  (score < 20 ||
                    score > 80 ||
                    (score > 42 && score < 55)) &&
                    styles.bg
                )}
              >
                <Score score={score} scoreStyle={styles} />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className={styles.compGraphLegends}>
        <p className={styles.legend}>Less confident</p>
        <p className={styles.legend}>Neutral</p>
        <p className={styles.legend}>Highly confident</p>
      </div>
    </div>
  )
}
