import React from "react";
import { useDebounce } from "utils/debounce";
import TextEditor from "components/TextEditor";

import styles from "../AboutPosition.module.scss";

export default ({ handleTextEditorChange, handleSave, value }) => {
  const autoSave = useDebounce(() => {
    handleSave();
  }, 500);

  return (
    <>
      <div className={styles.bigWrapper}>
        <div className={styles.inputWrapper}>
          <TextEditor
            value={value}
            handleChange={handleTextEditorChange}
            autoSave={autoSave}
            placeholder="List 3-5 key objectives"
          />
        </div>
      </div>
    </>
  );
};
