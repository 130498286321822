import { withState, compose, withHandlers } from 'recompose'
import axios from 'utils/axios'
import { generatePasswordresetRoute } from 'client/authRoutes'

export default compose(
  withState('emailSent', 'setEmailSent', false),
  // graphql(CREATE_PASSWORD_RESET, { name: 'createPasswordReset' }),
  withHandlers({
    handleSubmit: ({ setEmailSent }) => (e) => {
      e.preventDefault()
      axios
        .post(
          generatePasswordresetRoute,
          {
            email: e.target.email.value
          },
          { withCredentials: true }
        )
        .then((res) => {
          setEmailSent(true)
        })
        .catch(err=> {
          setEmailSent(true)
        })
      // createPasswordReset({
      //   variables:
      // })

    }
  })
)
