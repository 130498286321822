import React, { useState } from 'react'
import Modal from 'react-modal'
import Button from 'components/UI/Button'
import styles from './styles.module.scss'
import Competencies from './Competencies'

export default ({ survey }) => {
  const [openModal, setOpenModal] = useState(true)
  return (
    <div>
      <Modal
        closeTimeoutMS={350}
        onRequestClose={() => setOpenModal(false)}
        isOpen={openModal}
        shouldCloseOnOverlayClick
      >
        <Button
          bStyle='close'
          type='button'
          onClick={() => setOpenModal(false)}
        >
          <i className='fal fa-times' />
        </Button>
        <div className={styles.wrapper}>
          <h3>
            Developing your competencies is essential to be successful and happy
            at work.
          </h3>
          <h3>
            <strong>We are here to help you with that.</strong>
          </h3>

          <p className={styles.content}>
            Choose one competency, that you struggle with at the moment and want
            to develop. The one that holds you back from achieving your goals
            faster or in a better way. It could be the competency that you are
            already quite good at, but need to be even better.
          </p>
          <Button
            bStyle={['primary', 'large']}
            type='button'
            onClick={() => setOpenModal(false)}
          >
            Continue
          </Button>
        </div>
      </Modal>
      <Competencies />
    </div>
  )
}
