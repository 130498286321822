import { connect } from 'react-redux'

export default (...args) => connect((state) => {
  if (args) {
    if (args.length === 1) return { [args]: state[args] }
    const selectedState = []
    args.forEach((arg) => {
      selectedState[arg] = state[arg]
    })
    return { ...selectedState }
  }
  return { store: state }
})
