import React from 'react'
import MemberModal from 'components/Modals/MemberModal'
import compose from './compose'

export default compose(
  ({
    TEAMS_AND_POSITIONS: {
      teamsAndPositionsInOrgByResouceType: teamsAndPositions
    },
    ORGANIZATION_QUERY: { organization },
    updateMemberModal,
    removeMemberModal,
    updateRemoveMemberModal,
    handleRemoveUser,
    viewer,
    handleChangeOrgContract,
    addTeamMembersToExitstingTeam,
    addCandidatesToPosition,
    loading
  }) => (
    <MemberModal
      updateMemberModal={updateMemberModal}
      teamsAndPositions={teamsAndPositions}
      organization={organization}
      removeMemberModal={removeMemberModal}
      updateRemoveMemberModal={updateRemoveMemberModal}
      handleRemoveUser={handleRemoveUser}
      viewer={viewer}
      handleChangeOrgContract={handleChangeOrgContract}
      addTeamMembersToExitstingTeam={addTeamMembersToExitstingTeam}
      addCandidatesToPosition={addCandidatesToPosition}
      loading={loading}
    />
  )
)
