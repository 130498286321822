import React, { useState } from "react";
import Popup from "reactjs-popup";
import classNames from "classnames";
import Button from "components/UI/Button";
import EllipsisButton from "components/UI/EllipsisButton";
import DropDown from "components/UI/DropDown";
import UserRow from "components/UserRow";
import ResendInvite from "components/ResendInvite";
import styles from "./Modal.module.scss";

export default ({ invite, team, removeTeamFromInvite, client }) => {
  const [isLoading, setIsLoading] = useState(false);

  console.log({ team });

  return (
    <UserRow user={invite} invite>
      <div className="dropdownWrapper" id="dropdownMenu">
        <Popup
          keepTooltipInside="body"
          trigger={
            <div className={styles.avatarWrapper}>
              <EllipsisButton />
            </div>
          }
          position="bottom right"
        >
          <DropDown>
            <li>
              <ResendInvite invite={invite} org_id={team.organization_id} />
            </li>
            <li>
              <Button
                type="button"
                bStyle={["none"]}
                onClick={() => {
                  setIsLoading(invite);
                  removeTeamFromInvite({
                    variables: {
                      team_id: team.id,
                      invite_id: invite.id,
                    },
                  });
                }}
                disabled={isLoading === invite}
              >
                {isLoading ? (
                  <div
                    className={classNames(
                      styles.buttonText,
                      isLoading && styles.loading
                    )}
                  >
                    <i className="far fa-spinner-third" />
                    Removing...
                  </div>
                ) : (
                  <div className={styles.buttonText}>
                    <i className="fas fa-user-slash" />
                    Remove
                  </div>
                )}
              </Button>
            </li>
          </DropDown>
        </Popup>
      </div>
    </UserRow>
  );
};
