import React from "react";
import { compose, withProps, withState } from "recompose";
import { graphql } from "react-apollo";
import { loader } from "graphql.macro";
import handleLoading from "utils/loader";
import FullPage from "components/Loaders/FullPage";
import { withRouter } from "react-router-dom";
import withStore from "utils/withStore";
import mapColumns from "./mapColumns";
import mapDataRows from "./mapDataRows";

const POSITION_QUERY = loader("client/Queries/PositionQuery98.graphql");
const DELETE_POSITION = loader("../EditPosition/deletePosition.graphql");
const ORGANIZATION_TEAMS_AND_POSITIONS = loader(
  "client/Queries/OrganizationTeamsAndPositions.graphql"
);

export default compose(
  withRouter,
  withStore("account"),
  graphql(POSITION_QUERY, {
    options: (props) => {
      return {
        variables: { id: parseInt(props.match.params.positionId, 10) },
        notifyOnNetworkStatusChange: true,
      };
    },
  }),
  handleLoading(() => <FullPage />),
  graphql(DELETE_POSITION, {
    name: "deletePosition",
    options: (props) => {
      return {
        update: (proxy, { data: { deletePosition } }) => {
          const data = proxy.readQuery({
            query: ORGANIZATION_TEAMS_AND_POSITIONS,
            variables: {
              id: props.position.parentOrganization.id,
            },
          });
          data.organization.allOpenPositions =
            data.organization.allOpenPositions.filter(
              (pos) => pos.id !== deletePosition.id
            );
          proxy.writeQuery({
            query: ORGANIZATION_TEAMS_AND_POSITIONS,
            variables: {
              id: props.position.parentOrganization.id,
            },
            data,
          });
        },
      };
    },
  }),
  withState("selectedEvaluators", "setSelectedEvaluators", []),
  withState("showComments", "setShowComments", null),
  withState("filter", "setFilter", "compare"),
  withState("orderBy", "setOrder", null),
  withState("modal", "updateModal", ""),
  withState("deleteModal", "setDeleteModal", false),
  withState("descModal", "updateDescModal", ""),
  withProps(
    ({
      data: { openPosition },
      showComments,
      setShowComments,
      filter,
      updateModal,
      updateDescModal,
      selectedEvaluators,
      orderBy,
    }) => {
      const candidates =
        filter === "compare"
          ? openPosition.kanbanColumns[3].candidatesUnion
              .map((cand) =>
                openPosition.candidatesUnion.find((c) => {
                  const [type, id] = cand.split("-");
                  return c.type === type && c.id === parseInt(id, 10);
                })
              )
              .filter(Boolean)
          : openPosition.candidatesUnion;

      const mean = (evaluations = []) => {
        const total = evaluations.reduce((acc, e) => acc + e.evaluation, 0);
        return total / evaluations.length || 0;
      };

      const candidatesSorted = candidates.sort((a, b) => {
        if (orderBy === "requirements") {
          return (
            mean(b.evaluation.filter((e) => e.type === "requirement")) -
            mean(a.evaluation.filter((e) => e.type === "requirement"))
          );
        } else if (orderBy === "competencies") {
          return (
            mean(b.evaluation.filter((e) => e.type === "competency")) -
            mean(a.evaluation.filter((e) => e.type === "competency"))
          );
        } else if (orderBy === "team_fit") {
          return (
            mean(
              b.evaluation.filter((e) =>
                ["personality", "values", "intuition"].includes(e.type)
              )
            ) -
            mean(
              a.evaluation.filter((e) =>
                ["personality", "values", "intuition"].includes(e.type)
              )
            )
          );
        } else if (orderBy === "total" || orderBy === null) {
          return (
            mean(
              b.evaluation.filter((e) =>
                [
                  "personality",
                  "values",
                  "intuition",
                  "requirement",
                  "competency",
                ].includes(e.type)
              )
            ) -
            mean(
              a.evaluation.filter((e) =>
                [
                  "personality",
                  "values",
                  "intuition",
                  "requirement",
                  "competency",
                ].includes(e.type)
              )
            )
          );
        } else {
          return 0;
        }
      });

      const columns = mapColumns(
        candidatesSorted,
        openPosition,
        updateModal,
        selectedEvaluators,
        orderBy
      );

      const dataRows = mapDataRows({
        candidates: candidatesSorted,
        openPosition,
        showComments,
        setShowComments,
        selectedEvaluators,
        updateDescModal,
        orderBy,
      });

      return {
        columns,
        dataRows,
      };
    }
  )
);
