import React from "react"
import { compose, withProps, withState } from "recompose"
import { graphql } from "react-apollo"
import { loader } from "graphql.macro"
import handleLoading from "utils/loader"
import Circles from "components/Loaders/Circles"
import { ReactComponent as ExpertIcon } from "images/icons/competencies/colored-must.svg"
import { ReactComponent as GoodIcon } from "images/icons/competencies/colored-important.svg"
import { ReactComponent as OkayIcon } from "images/icons/competencies/colored-good.svg"
import scoreGroups from "utils/compeScoreGroups"

const COMPETENCIES_QUERY = loader("./CompetenciesQuery5.graphql")
// const scoreGroups = {
//   76: 'expert',
//   75: 'expert',
//   74: 'expert',
//   51: 'good',
//   50: 'good',
//   49: 'good',
//   26: 'okay',
//   25: 'okay',
//   24: 'okay',
//   0: 'lacking'
// }

export default compose(
  graphql(COMPETENCIES_QUERY),
  handleLoading(() => (
    <div style={{ height: "40vh" }}>
      <Circles />
    </div>
  )),
  withState("showModal", "setShowModal", ""),
  withProps(
    ({
      data: {
        personalProfile: { competencies, competencyGraph },
      },
    }) => {
      const compes = {
        expert: competencies.filter(
          (comp) => scoreGroups[comp.score] === "expert"
        ),
        good: competencies.filter(
          (comp) => scoreGroups[comp.score] === "strong"
        ),
        okay: competencies.filter((comp) => scoreGroups[comp.score] === "good"),
      }

      const headers = {
        expert: {
          icon: ExpertIcon,
          title: "You are great at",
        },
        good: {
          icon: GoodIcon,
          title: "You are strong at",
        },
        okay: {
          icon: OkayIcon,
          title: "You are good at",
        },
      }
      return {
        competencies: compes,
        competencyGraph,
        headers,
      }
    }
  )
)
