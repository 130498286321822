import React from "react";
import classNames from "classnames";
import Icon from "components/UI/Icon";
import Button from "components/UI/Button";

import styles from "./WisGPTSummary.module.scss";

export default ({ setGptModal, action, disabled, isGenerating }) => (
  <div className={styles.wisGPTSummary}>
    <div className={styles.text}>
      {disabled ? (
        <p>
          To unlock WisGPT Powered Summary you need to import
          <br />
          candidates data using{" "}
          <a
            target="_blank"
            href="https://chromewebstore.google.com/detail/wisnio-extension/dacecibbecnjflfnpllalccamjojpbem"
          >
            Wisnio LinkedIn plugin
          </a>
          .
        </p>
      ) : (
        <>
          <p>
            WisGPT drafts a new summary tailored to showcase the candidate's
            achievements and skills directly from the insights of your connected
            LinkedIn profile.
          </p>
          <p>
            Generating a new summary will automatically replace the current
            profile summary. We encourage you to edit and refine it further,
            ensuring it perfectly matches your professional narrative and goals.
          </p>
          <p>Do you wish to generate a new summary?</p>
        </>
      )}
    </div>
    <div
      className={classNames(
        styles.submitWrapper,
        document.getElementById("inviteWrapper") &&
          document.getElementById("inviteWrapper").offsetHeight > 450 &&
          styles.relative
      )}
    >
      <Button
        bStyle={["secondary", "verySmall"]}
        onClick={() => setGptModal(false)}
      >
        Cancel
      </Button>
      {disabled ? (
        <Button bStyle={["grey", "verySmall"]}>
          <Icon icon="Lock" marginRight />
          Generate summary
        </Button>
      ) : (
        <Button
          bStyle={["purple", "verySmall"]}
          onClick={action}
          disabled={isGenerating}
        >
          {isGenerating ? (
            <span className={styles.loadingIcon}>
              <i className="far fa-spinner-third" />
            </span>
          ) : (
            <Icon icon="Magicwand" marginRight />
          )}
          Generate summary
        </Button>
      )}
    </div>
  </div>
);
