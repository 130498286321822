import React from "react";
import classNames from "classnames";
import TextareaAutosize from "react-autosize-textarea";
import Icon from "components/UI/Icon";
import Button from "components/UI/Button";

import styles from "./PromptInput.module.scss";

export default ({
  isFullscreen,
  prompt,
  setPrompt,
  onSubmit,
  isLoading,
  disabled,
  isReady,
  isEnlarged,
}) => {
  const handleEnterPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey && prompt && prompt !== "") {
      e.preventDefault();
      e.stopPropagation();
      onSubmit();
    }
  };

  const handleInputPlaceholder = (isReady) => {
    if (!isReady) {
      return "Please complete your surveys to use this feature.";
    }

    if (window.innerWidth > 960) {
      return "👋 Ask WisGPT by typing or by choosing a prompt.";
    }

    return "Explain my profile...";
  };

  return (
    <div
      className={classNames(
        styles.inputWrapper,
        isEnlarged && styles.isEnlarged
      )}
    >
      <TextareaAutosize
        maxRows={4}
        name="comment"
        autoFocus={isFullscreen}
        placeholder={handleInputPlaceholder(isReady)}
        className={classNames(
          styles.textarea,
          prompt.length > 0 && styles.active
        )}
        rows={1}
        onChange={(e) => setPrompt(e.target.value)}
        value={prompt}
        id="cpromptTextarea"
        data-gramm_editor="false"
        onKeyPress={(e) => handleEnterPress(e)}
        disabled={!isReady || disabled}
      />
      <div className={styles.actionButtons}>
        <span className={styles.sendIcon}>
          <Button
            bStyle={["none"]}
            type="submit"
            disabled={prompt === "" || isLoading || disabled}
          >
            <Icon icon="Send" />
          </Button>
        </span>
      </div>
    </div>
  );
};
