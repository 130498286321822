import React from "react";
import classNames from "classnames";
import Modal from "react-modal";
import ModalTitle from "components/UI/ModalTitle";
import Button from "components/UI/Button";
import Icon from "components/UI/Icon";
import AttachmentForm from "./AttachmentForm";
import compose from "./compose";

import styles from "./UploadFile.module.scss";

export default compose(
  ({
    uploading,
    handleUploadAttachment,
    openModal,
    setOpenModal,
    description,
    setDescription,
    selectedTags,
    setSelectedTags,
    setUploadFiles,
  }) => {
    return (
      <div className={styles.wrapper}>
        {uploading ? (
          <div className={styles.loader}>
            <i className="far fa-spinner-third" />
          </div>
        ) : (
          <Button bStyle={["none"]}>
            <Icon icon="Plus" marginRight />
            Upload file
            <input
              type="file"
              className={styles.fileInput}
              onChange={(e) => {
                e.preventDefault();
                e.persist();
                setUploadFiles(e.target.files);
                setOpenModal(true);
              }}
            />
          </Button>
        )}
        <Modal
          isOpen={openModal}
          onRequestClose={() => setOpenModal(false)}
          shouldCloseOnOverlayClick={true}
          shouldCloseOnEsc={true}
          className={classNames(styles.modal, "fitContent")}
        >
          <Button
            bStyle="close"
            type="button"
            onClick={() => setOpenModal(false)}
          >
            <i className="fal fa-times" />
          </Button>
          <div className={styles.modalHead}>
            <ModalTitle icon="File">Edit attachment</ModalTitle>
            <p>Use the form below to describe the contents of the file</p>
          </div>
          <AttachmentForm
            description={description}
            setDescription={setDescription}
            selectedTags={selectedTags}
            setSelectedTags={setSelectedTags}
            handleUploadAttachment={handleUploadAttachment}
          />
        </Modal>
      </div>
    );
  }
);
