import React from "react";
import classNames from "classnames";
import { useSidebar } from "utils/sidebarProvider";
import Footer from "components/Footer";

import styles from "./SidebarWrapper.module.scss";

export default ({
  noHeader,
  noPadding,
  noSidebar,
  noMarginBottom,
  children,
  noFooter,
  wide,
}) => {
  const { collapsedContext } = useSidebar();
  const [collapsed] = collapsedContext;

  return (
    <div
      id="wrapper"
      className={classNames(
        "sidebarWrapper",
        styles.sidebarWrapper,
        noHeader && styles.noHeader,
        noPadding && styles.noPadding,
        noMarginBottom && styles.noMarginBottom,
        collapsed && styles.collapsed,
        noSidebar && styles.noSidebar,
        wide && styles.wide
      )}
    >
      <div className={styles.body}>{children}</div>
      {!noFooter && <Footer />}
    </div>
  );
};
