import { withRouter } from "react-router-dom"
import { compose } from "recompose"
import { withApollo } from "react-apollo"
import { loader } from "graphql.macro"
import scrollToTop from "utils/scrollToTop"
import logRead from "utils/logRead"
import withStore from "utils/withStore"

export default compose(
  withRouter,
  withApollo,
  withStore("viewer"),
  scrollToTop,
  logRead({
    obj_type: "personalProfile",
  })
)
