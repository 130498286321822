const isDev = process.env.NODE_ENV === "development";
const hasProxy = typeof process.env.REACT_APP_API_PROXY !== "undefined";

const baseRoute = isDev && hasProxy ? process.env.REACT_APP_API_PROXY : "";

export const signupRoute = `${baseRoute}/signup`;
export const newUserFromSessionRoute = `${baseRoute}/newUserFromSession`;
export const emailLoginRoute = `${baseRoute}/auth/email`;
export const authStrategies = `${baseRoute}/authStrategies`;
export const apiRoute =
  process.env.REACT_APP_GRAPHQL_ROUTE || `${baseRoute}/api`;
export const getInvite = `${baseRoute}/getInvite`;
export const logoutRoute = `${baseRoute}/logout`;
export const generatePasswordresetRoute = `${baseRoute}/generatePasswordReset`;
export const validatePasswordResetSecret = `${baseRoute}/validatePasswordResetSecret`;
export const updatePasswordRoute = `${baseRoute}/updatePassword`;
export const printoutRoute = process.env.REACT_APP_PDF_URL || `${baseRoute}`;
export const caseStudyRoute = `${baseRoute}/caseStudies`;
export const microsoftAuthLoginRoute = `${baseRoute}/auth/login/microsoft`;
export const linkedInAuthLoginRoute = `${baseRoute}/auth/login/linkedIn`;
export const googleAuthLoginRoute = `${baseRoute}/auth/login/google`;
export const atuhCallbackRoute = `${baseRoute}/auth/callback`;
export const useTemplateRoute = `${baseRoute}/useTemplate`;
export const evaluation = `${baseRoute}/evaluation`;
export const authEvaluationInvite = `${baseRoute}/auth/evaluationInvite`;
export const authShareCandidate = `${baseRoute}/candidate`;
export const authAttachment = `${baseRoute}/attachment`;
export const GPTGatewaySocetUrl =
  process.env.REACT_APP_GPT_GATEWAY_SOCKET_URL || `${baseRoute}`;
