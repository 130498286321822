import React from "react";
import { compose, withState, withHandlers } from "recompose";
import { graphql } from "react-apollo";
import { loader } from "graphql.macro";
import handleLoading from "utils/loader";
import withStore from "utils/withStore";
import FullPage from "components/Loaders/FullPage";
import handleSelectOrg from "./handleSelectOrg";
import { RESET_ORG } from "store/actionTypes";

const ORGANIZATIONS = loader("client/Queries/OrganizationsQuery21.graphql");
const SWITCH_ACCOUNT = loader("client/Mutations/switchAccount.graphql");

export default compose(
  withStore("inviteStore", "selectedOrg", "account", "viewer"),
  withState("modal", "updateModal", ""),
  graphql(SWITCH_ACCOUNT, {
    name: "switchAccount",
  }),
  graphql(ORGANIZATIONS, {
    skip: ({ viewer: { default_account_id } }) => {
      return !default_account_id;
    },
    options: (props) => ({
      variables: {
        account_id: parseInt(props.viewer.default_account_id, 10),
      },
    }),
  }),
  handleLoading(() => <FullPage />),
  withHandlers({
    handleSwitchAccount:
      ({ dispatch, switchAccount, history }) =>
      (id) => {
        switchAccount({
          variables: {
            id,
          },
        }).then((res) => {
          dispatch({ type: RESET_ORG });
          window.localStorage.removeItem("recentOrg");
          history.push("/dashboard");
        });
      },
  }),
  handleSelectOrg
);
