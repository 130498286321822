import Chip from "components/v2/UI/Chip";
import Timestamp from "react-timestamp";
import Table from "components/v2/UI/Table";
import React from "react";
import compose from "./compose";
import { useResponsive } from "utils/hooks/useResponsive";
import Button from "components/UI/Button/index";
import Icon from "components/UI/Icon/index";
import classnames from "classnames";
import styles from "./styles.module.scss";

export default compose(
  ({
    match: {
      params: { org_id },
    },
    history,
    data: { assessmentGroups },
  }) => {
    const { isMobile } = useResponsive();
    const status = {
      "IN PROGRESS": {
        variant: "light",
        color: "orange",
      },
      COMPLETED: {
        variant: "light",
        color: "green",
      },
    };

    const popoverActions = [
      {
        name: "Complete session",
        icon: "CheckLarge",
        action: () => alert("Send reminder"),
      },
    ];

    const columns = React.useMemo(
      () => [
        {
          Header: "Name",
          accessor: "title",
          Cell: ({ row: { original } }) => (
            <a href={`/${org_id}/assessmentGroup/${original.id}`}>
              <div className={styles.cellContainer}>
                <p className={classnames("label")}>{original.title}</p>
                {isMobile && (
                  <Chip
                    color={status[original.status].color}
                    variant={status[original.status].variant}
                  >
                    {original.status}
                  </Chip>
                )}
              </div>
            </a>
          ),
        },
      ],
      []
    );

    if (!isMobile) {
      columns.push(
        {
          Header: "Date",
          accessor: "created_at",
          Cell: ({ value }) => {
            const date = new Date(parseInt(value));
            return (
              <p className={classnames("size14")}>
                <Timestamp
                  date={date}
                  autoUpdate
                  options={{ format: "date" }}
                />
              </p>
            );
          },
        },
        {
          Header: "Status",
          accessor: "status",
          Cell: ({ row, value }) => {
            console.log({ row });
            return (
              <Chip color={status[value].color} variant={status[value].variant}>
                {value}
              </Chip>
            );
          },
        },
        // TODO: Implement after complete session action on API ise ready
        // {
        //   Header: "Actions",
        //   accessor: "actions",
        //   Cell: ({ row }) => (
        //     <div className={style.cellWrapper}>
        //       <Popover popOverActions={popoverActions} popLeft={true} />
        //     </div>
        //   ),
        // }
        // TODO: Remove after actions are ready
        {
          Header: "",
          accessor: "id",
          Cell: ({ row: { original } }) => {
            return (
              <a href={`/${org_id}/assessmentGroup/${original.id}`}>
                <Button bStyle={["verySmall"]}>
                  <Icon icon="Arrow" />
                </Button>
              </a>
            );
          },
        }
      );
    }

    // TODO: If popover needs separate actions, remove actions here and add them through the table columns on top

    const handleActions = (session) => {
      console.log({ session });
    };

    return <Table columns={columns} data={assessmentGroups} />;
  }
);
