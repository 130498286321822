import React from 'react'
import Popup from 'reactjs-popup'
import Modal from 'react-modal'
import { Link } from 'react-router-dom'
import Select from 'components/UI/Select'
import UserAvatar from 'components/UserAvatar'
import EllipsisButton from 'components/UI/EllipsisButton'
import Button from 'components/UI/Button'
import DropDown from 'components/UI/DropDown'
import RemoveMember from 'components/Modals/RemoveMember'
import ResendInvite from './ResendInvite'
import UserTeamsAndPositions from './UserTeamsAndPositions'

import styles from './MemberModal.module.scss'

export default ({
  teamsAndPositions,
  organization,
  removeMemberModal,
  updateRemoveMemberModal,
  handleRemoveUser,
  updateMemberModal,
  viewer,
  handleChangeOrgContract,
  addTeamMembersToExitstingTeam,
  addCandidatesToPosition,
  loading
}) => {
  if (teamsAndPositions.user === '') return null

  const ContractTypeSelect = ({ roleTypes, user }) => (
    <Select
      onChange={(e) =>
        handleChangeOrgContract({ event: e, user, roleTypes })
      }
      value={user.roleType}
      noBorder
    >
      {roleTypes
        .filter((rt) => rt.name !== 'candidate')
        .map((rt) => (
          <option key={rt.name} value={rt.name}>
            {rt.name.charAt(0).toUpperCase() + rt.name.slice(1)}
          </option>
        ))}
      <option key="candidate" value="candidate" disabled>
        Candidate
      </option>
    </Select>
  )

  const roleTypes = [
    { name: 'candidate', role: 1 },
    { name: 'member', role: 2 },
    { name: 'admin', role: 4 }
  ]

  const isInviteOrPending = () => {
    if (teamsAndPositions.user.type === 'invite') return true
    if (
      teamsAndPositions.user.type &&
      teamsAndPositions.user.type === 'invite'
    )
      return true
    if (
      teamsAndPositions.user.state &&
      teamsAndPositions.user.state === 'waiting_user_acceptance'
    )
      return true
    if (
      teamsAndPositions.user.contract &&
      teamsAndPositions.user.contract.state ===
        'waiting_user_acceptance'
    )
      return true
    return false
  }

  return (
    <>
      <Button
        bStyle="close"
        type="button"
        onClick={() => updateMemberModal('')}
      >
        <i className="fal fa-times" />
      </Button>
      <div className={styles.modalWrapper}>
        <div className={styles.modalHead}>
          <div className={styles.left}>
            <div className={styles.avatar}>
              <UserAvatar
                member={teamsAndPositions.user}
                size="medium"
                isInvite={!teamsAndPositions.user.name}
              />
            </div>
            <div className={styles.user}>
              <h3>{teamsAndPositions.user.name}</h3>
              <div className={styles.info}>
                <p className={styles.label}>
                  {teamsAndPositions.user.state}
                </p>
                <p className={styles.email}>
                  {teamsAndPositions.user.email}
                </p>
                <p className={styles.profileComplete}>
                  {teamsAndPositions.user.profileComplete ? (
                    <Link
                      to={`/org/${organization.id}/user/${teamsAndPositions.user.id}`}
                    >
                      View profile
                    </Link>
                  ) : (
                    'Profile Incomplete'
                  )}
                </p>
              </div>
            </div>
          </div>
          <div className={styles.right}>
            <div className={styles.role}>
              {viewer.id === teamsAndPositions.user.id ? (
                <>
                  <Select noBorder disabled>
                    <option>
                      {teamsAndPositions.user.roleType
                        .charAt(0)
                        .toUpperCase() +
                        teamsAndPositions.user.roleType.slice(1)}
                    </option>
                  </Select>
                  <div style={{ width: '12px' }} />
                </>
              ) : (
                <>
                  {teamsAndPositions.user.state === 'pending' ? (
                    <ContractTypeSelect
                      roleTypes={roleTypes}
                      user={teamsAndPositions.user}
                      handleChangeOrgContract={
                        handleChangeOrgContract
                      }
                    />
                  ) : (
                    <ContractTypeSelect
                      roleTypes={roleTypes}
                      user={teamsAndPositions.user}
                      handleChangeOrgContract={
                        handleChangeOrgContract
                      }
                    />
                  )}
                </>
              )}
            </div>
            {viewer.id !== teamsAndPositions.user.id && (
              <div className="dropdownWrapper" id="dropdownMenu">
                <Popup
                  keepTooltipInside="body"
                  trigger={
                    <div className={styles.avatarWrapper}>
                      <EllipsisButton extralarge />
                    </div>
                  }
                  position="bottom right"
                >
                  <DropDown>
                    {isInviteOrPending() && (
                      <li>
                        <ResendInvite
                          invite={teamsAndPositions.user}
                        />
                      </li>
                    )}
                    <li>
                      <Button
                        type="button"
                        bStyle={['none']}
                        onClick={() =>
                          updateRemoveMemberModal(
                            teamsAndPositions.user
                          )
                        }
                      >
                        <i className="fas fa-user-slash" />
                        Remove
                      </Button>
                    </li>
                  </DropDown>
                </Popup>
              </div>
            )}
          </div>
        </div>
        <UserTeamsAndPositions
          isInvite={!teamsAndPositions.user.name}
          teamsAndPositions={teamsAndPositions}
          organization={organization}
          addTeamMembersToExitstingTeam={
            addTeamMembersToExitstingTeam
          }
          addCandidatesToPosition={addCandidatesToPosition}
          loading={loading}
        />
      </div>
      <Modal
        isOpen={removeMemberModal && true}
        onRequestClose={() => updateRemoveMemberModal(false)}
        shouldCloseOnOverlayClick
      >
        <RemoveMember
          member={removeMemberModal}
          type="organisation"
          updateRemoveMemberModal={updateRemoveMemberModal}
          handleRemoveUser={handleRemoveUser}
        />
      </Modal>
    </>
  )
}
