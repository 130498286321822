import { compose, withState, withHandlers, lifecycle } from "recompose";

export default compose(
  withState("scrolling", "setScrolling", false),
  withHandlers({
    scroll:
      ({ setScrolling }) =>
      () => {
        if (window.scrollY > 700) {
          setScrolling(true);
        }
        if (window.scrollY < 700) {
          setScrolling(false);
        }
      },
  }),
  lifecycle({
    componentWillMount() {
      window.addEventListener("scroll", this.props.scroll);
    },
    componentWillUnmount() {
      window.removeEventListener("scroll", this.props.scroll);
    },
  })
);
