import React from "react";
import { compose, withState, withHandlers, lifecycle } from "recompose";
import { graphql, withApollo } from "react-apollo";
import { loader } from "graphql.macro";
import withStore from "utils/withStore";
import handleLoading from "utils/loader";
import scrollToTop from "utils/scrollToTop";
import Circles from "components/Loaders/Circles";
import { TOGGLE_EDITING_COMPETENCIES } from "store/actionTypes";

import coreAndPotGap from "./utils/coreAndPotGap";
import POSITION_QUERY from "./utils/positionQuery";

const SAVE_COMPETENCIES = loader(
  "client/Mutations/saveCompetenciesConf.graphql"
);

export default compose(
  withApollo,
  withStore("newposition"),
  graphql(POSITION_QUERY, {
    options: (props) => {
      return {
        variables: { id: parseInt(props.match.params.positionId, 10) },
        notifyOnNetworkStatusChange: true,
      };
    },
  }),
  graphql(SAVE_COMPETENCIES, {
    name: "saveCompetenciesConf",
  }),
  handleLoading(() => (
    <div style={{ height: "100vh" }}>
      <Circles />
    </div>
  )),
  withState("columns", "setColumns", (props) => {
    const columns = [[], [], [], []];
    const colKeys = [
      [0, 1, 2],
      [3, 4, 5],
      [6, 7, 8],
    ];

    if (props.data.openPosition.competenciesHaveBeenConfed) {
      props.data.openPosition.competencies.forEach((comp, key) => {
        const colKey =
          key > 8
            ? 3
            : colKeys.findIndex((col) => col.some((cKey) => cKey === key));

        columns[colKey].push({
          id: `item-${comp.id}`,
          title: comp.title,
          desc: comp.descShort,
          ...coreAndPotGap({
            comp,
            team: props.data.openPosition.parentTeam,
          }),
          dimParent: comp.dimParent,
        });
      });
    } else {
      props.data.openPosition.competencies.forEach((comp) => {
        columns[3].push({
          id: `item-${comp.id}`,
          title: comp.title,
          desc: comp.descShort,
          ...coreAndPotGap({
            comp,
            team: props.data.openPosition.parentTeam,
          }),
          dimParent: comp.dimParent,
        });
      });
    }

    columns[3].sort((a, b) => {
      const aIndex = a.potGap ? 0 : a.potGap === a.coreComp ? 1 : 2;
      const bIndex = b.potGap ? 0 : b.potGap === b.coreComp ? 1 : 2;
      return aIndex - bIndex;
    });
    return columns;
  }),
  withState("openModal", "setOpenModal", ""),

  withHandlers({
    handleSave:
      ({
        history,
        client,
        data: { openPosition },
        saveCompetenciesConf,
        columns,
      }) =>
      () => {
        const configuration = [...columns[0], ...columns[1], ...columns[2]].map(
          (c, key) => {
            const comp = openPosition.competencies.find(
              (i) => i.title === c.title
            );
            return {
              obj_type_id: 9,
              obj_id: openPosition.id,
              subject_type_id: 9,
              subject_id: openPosition.id,
              survey_row_id: comp.surveyRowId,
              question_dim_id: comp.questionDimId,
              answer: 100 - key,
            };
          }
        );
        const leftover = [...columns[3]].map((c) => {
          const comp = openPosition.competencies.find(
            (i) => i.title === c.title
          );
          return {
            obj_type_id: 9,
            obj_id: openPosition.id,
            subject_type_id: 9,
            subject_id: openPosition.id,
            survey_row_id: comp.surveyRowId,
            question_dim_id: comp.questionDimId,
            answer: 0,
          };
        });
        saveCompetenciesConf({
          variables: {
            position_id: openPosition.id,
            answers: [...configuration, ...leftover],
          },
        }).then((res) => {
          client.query({
            query: POSITION_QUERY,
            fetchPolicy: "network-only",
            variables: {
              id: openPosition.id,
            },
          });
          history.push(
            `/team/${openPosition.parentTeam.id}/position/${openPosition.id}/edit`
          );
        });
      },
    handleDiscard:
      ({ history, data: { openPosition } }) =>
      () => {
        history.push(
          `/team/${openPosition.parentTeam.id}/position/${openPosition.id}/edit`
        );
      },
  }),
  scrollToTop,
  lifecycle({
    componentWillUnmount() {
      this.props.dispatch({ type: TOGGLE_EDITING_COMPETENCIES });
    },
  })
);
