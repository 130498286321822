import React from 'react'
import classNames from 'classnames'
import styles from './MemberAvatar.module.scss'

const Avatar = ({ member, isCurrentUser }) => {
  if (member.avatar_url) {
    return (
      <div className={classNames(styles.wrapper, styles.isCurrentUser)}>
        <img
          alt='avatar'
          aria-label='img'
          className={styles.image}
          src={member.avatar_url}
        />
      </div>
    )
  }
  return (
    <div className={classNames(styles.wrapper, styles.isCurrentUser)}>
      <div aria-label='initials' className={styles.initials}>
        <p>{member.initials}</p>
      </div>
    </div>
  )
}

export default ({ isCurrentUser }) => (
  <>
    <div
      className={styles.avatarPosition}
      style={{
        top: `${isCurrentUser.valuesChart.avatarPosition.y}%`,
        left: `${isCurrentUser.valuesChart.avatarPosition.x}%`
      }}
    >
      <Avatar member={isCurrentUser} isCurrentUser />
    </div>
  </>
)
