import React from "react";
import { compose, withState } from "recompose";
import { graphql } from "react-apollo";
import { loader } from "graphql.macro";
import handleLoading from "utils/loader";
import handleUpsertScore from "./handleUpsertScore";
import handleUpdateReview from "./handleUpdateReview";
import FullPage from "components/Loaders/FullPage";

const ASSESSMENT_REVIEW = loader("client/Queries/AssessmentReview9.graphql");
const UPSERT_SCORE = loader("./upsertScore7.graphql");
const UPDATE_REVIEW = loader("./updateAssessmentReview3.graphql");

const refetchRewiev = (props) => ({
  refetchQueries: [
    {
      query: ASSESSMENT_REVIEW,
      variables: { review_id: parseInt(props.match.params.review_id, 10) },
    },
  ],
});

export default compose(
  withState("currentStepIndex", "setCurrentStepIndex", 0),

  graphql(ASSESSMENT_REVIEW, {
    options: ({
      match: {
        params: { review_id },
      },
    }) => ({ variables: { review_id: parseInt(review_id, 10) } }),
  }),
  handleLoading(() => <FullPage />),
  graphql(UPSERT_SCORE, {
    name: "upsertScore",
    options: (props) => refetchRewiev(props),
  }),
  graphql(UPDATE_REVIEW, {
    name: "updateReview",
    options: (props) => refetchRewiev(props),
  }),
  handleUpsertScore,
  handleUpdateReview
);
