import React from 'react'
import {
  compose, withState, withHandlers, withProps
} from 'recompose'
import { graphql, withApollo } from 'react-apollo'
import { loader } from 'graphql.macro'
import { withRouter } from 'react-router-dom'
import handleLoading from 'utils/loader'
import Circles from 'components/Loaders/Circles'
import makeGoalsFlow from '../makeGoalsFlow'

const TEAM_GOALS = loader('../TeamGoals1.graphql')
const UPDATE_GOAL = loader('./updateGoal1.graphql')
const REMOVE_GOAL = loader('./removeGoal.graphql')
const CREATE_GOAL = loader('./createGoal.graphql')

export default compose(
  withRouter,
  withApollo,
  withState('cardTitle', 'setCardTitle', props => props.card.title),
  withState('cardDesc', 'setCardDesc', props => props.card.description || ''),
  withProps(({ cardOpen, card, newCard }) => {
    return {
      isOpen: cardOpen === card.order && !newCard
    }
  }),
  graphql(TEAM_GOALS, {
    options: (props) => {
      return {
        variables: {
          team_id: parseInt(props.match.params.teamId, 10),
          play_id: parseInt(props.match.params.toolId, 10)
        },
        notifyOnNetworkStatusChange: true
      }
    }
  }),
  graphql(UPDATE_GOAL, {
    name: 'updateRequirement',
    options: (props) => {
      return {
        update: (proxy, { data: { updateTeamGoal } }) => {
          const data = proxy.readQuery({
            query: TEAM_GOALS,
            variables: {
              team_id: parseInt(props.match.params.teamId, 10),
              play_id: parseInt(props.match.params.toolId, 10)
            }
          })

          const key = data.teamGoals.findIndex(g => g.id === updateTeamGoal.id)

          data.teamGoals[key] = updateTeamGoal

          proxy.writeQuery({
            query: TEAM_GOALS,
            variables: {
              team_id: parseInt(props.match.params.teamId, 10),
              play_id: parseInt(props.match.params.toolId, 10)
            },
            data
          })

          props.setList(
            makeGoalsFlow({
              teamGoals: data.teamGoals,
              viewer: props.viewer
            })
          )
        }
      }
    }
  }),
  graphql(REMOVE_GOAL, {
    name: 'removeRequirement',
    options: (props) => {
      return {
        update: (proxy, { data: { deleteTeamGoal } }) => {
          const data = proxy.readQuery({
            query: TEAM_GOALS,
            variables: {
              team_id: parseInt(props.match.params.teamId, 10),
              play_id: parseInt(props.match.params.toolId, 10)
            }
          })

          data.teamGoals = [
            ...data.teamGoals.filter(g => g.id !== deleteTeamGoal.id)
          ]

          proxy.writeQuery({
            query: TEAM_GOALS,
            variables: {
              team_id: parseInt(props.match.params.teamId, 10),
              play_id: parseInt(props.match.params.toolId, 10)
            },
            data
          })

          props.setList(
            makeGoalsFlow({
              teamGoals: data.teamGoals,
              viewer: props.viewer
            })
          )
        }
      }
    }
  }),
  graphql(CREATE_GOAL, {
    name: 'createRequirement',
    options: (props) => {
      return {
        update: (proxy, { data: { createTeamGoal } }) => {
          const data = proxy.readQuery({
            query: TEAM_GOALS,
            variables: {
              team_id: parseInt(props.match.params.teamId, 10),
              play_id: parseInt(props.match.params.toolId, 10)
            }
          })

          data.teamGoals = [...data.teamGoals, createTeamGoal]

          proxy.writeQuery({
            query: TEAM_GOALS,
            variables: {
              team_id: parseInt(props.match.params.teamId, 10),
              play_id: parseInt(props.match.params.toolId, 10)
            },
            data
          })

          props.setList(
            makeGoalsFlow({
              teamGoals: data.teamGoals,
              viewer: props.viewer
            })
          )
        }
      }
    }
  }),
  withHandlers({
    handleUpdate: ({
      match,
      data: { teamGoals },
      updateRequirement,
      setCardOpen,
      cardTitle,
      cardDesc,
      card
    }) => () => {
      updateRequirement({
        variables: {
          id: card.id,
          team_id: parseInt(match.params.teamId, 10),
          play_id: parseInt(match.params.toolId, 10),
          title: cardTitle,
          description: cardDesc
        }
      }).then((res) => {
        setCardOpen(null)
      })
    },
    handleRemove: ({
      match,
      data: { teamGoals },
      removeRequirement,
      setCardOpen,
      card
    }) => () => {
      removeRequirement({
        variables: {
          id: card.id,
          team_id: parseInt(match.params.teamId, 10),
          play_id: parseInt(match.params.toolId, 10)
        }
      }).then((res) => {
        setCardOpen(null)
      })
    },
    handleCreate: ({
      match,
      card,
      setNewCard,
      data: { teamGoals },
      createRequirement,
      setCardOpen,
      cardTitle,
      cardDesc
    }) => () => {
      createRequirement({
        variables: {
          team_id: parseInt(match.params.teamId, 10),
          title: cardTitle,
          description: cardDesc,
          order: card.order,
          play_id: parseInt(match.params.toolId, 10)
        }
      }).then((res) => {
        setCardOpen(null)
        setNewCard(null)
      })
    }
  }),
  handleLoading(() => (
    <div style={{ height: '100vh' }}>
      <Circles />
    </div>
  ))
)
