import React from "react";
import { ReactComponent as UserIcon } from "images/icons/User.svg";

import styles from "./CompetencyModal.module.scss";

export default ({ comp }) => {
  return (
    <div className={styles.wrapper}>
      <h2 className={styles.title}>{comp.name}</h2>
      <p className={styles.description}>{comp.description_tid}</p>
      <div className={styles.behaviors}>
        <h4 className={styles.interviewTitle}>Behavioural examples</h4>
        <p className={styles.prefix}>
          <UserIcon />
          Person who has the competency
        </p>
        <div className={styles.questionsWrapper}>
          {comp.behaviours &&
            comp.behaviours.map(b => (
              <p key={b} className={styles.question}>
                {b}
              </p>
            ))}
        </div>
      </div>
    </div>
  );
};
