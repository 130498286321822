import React from 'react'
import Button from 'components/UI/Button'

import styles from './IENotificationBar.module.scss'

export default ({ setNotificationBar }) => (
  <div className={styles.notificationBar}>
    <div className={styles.wrapper}>
      <div className={styles.close}>
        <Button
          bStyle={['none']}
          type='button'
          onClick={() => setNotificationBar(false)}
        >
          <i className='fal fa-times' />
        </Button>
      </div>
      <p className={styles.infoTitle}>
        <i className='fas fa-exclamation-triangle' />
        Wisnio is not optimized for Internet Explorer.
      </p>
      <p className={styles.infoText}>
        Microsoft has ended support for Internet Explorer. Please consider using
        a modern web browser (i.e
        {' '}
        <a
          target='_blank'
          rel='noopener noreferrer'
          href='https://www.google.com/chrome/'
        >
          Chrome
        </a>
        ,
        {' '}
        <a
          target='_blank'
          rel='noopener noreferrer'
          href='https://www.mozilla.org/en-US/firefox/new/'
        >
          Firefox
        </a>
        ).
      </p>
      <p className={styles.links}>
        <a
          target='_blank'
          rel='noopener noreferrer'
          href='https://medium.com/@burger.neal/the-end-of-life-of-internet-explorer-11-12736f9ff75f'
        >
          Read more
        </a>
      </p>
    </div>
  </div>
)
