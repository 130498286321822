import React from 'react'
import DistributionBarChart from 'components/Charts/DistributionBarChart'
import TeamMemberAvatars from 'components/TeamMemberAvatars'
import classNames from 'classnames'

export default ({ styles, dim, members, highlight, labelClass }) => {
  const getScoreText = () => {
    if (dim.score < 26) {
      return ` highly ${dim.label_min}`
    }
    if (dim.score > 25 && dim.score < 51) {
      return ` moderately ${dim.label_min}`
    }
    if (dim.score > 50 && dim.score < 76) {
      return ` moderately ${dim.label_max}`
    }
    if (dim.score > 75) {
      return ` highly ${dim.label_max}`
    }
    return null
  }

  return (
    <>
      <div className={styles.lineWrapper}>
        <DistributionBarChart
          dim={dim}
          members={members}
          resource="personality"
          colour={labelClass}
          size="large"
          highlight={highlight}
          labelClass={labelClass}
        />
        <div className={styles.opposites}>
          <div className={styles.left}>
            <h4 className={styles.label}>{dim.label_min}</h4>
            <p className={styles.desc}>{dim.description_min}</p>
          </div>
          <div className={styles.right}>
            <h4 className={styles.label}>{dim.label_max}</h4>
            <p className={styles.desc}>{dim.description_max}</p>
          </div>
        </div>
      </div>
      <div className={styles.asteriskWrapper} />
      {members.length > 1 && (
        <div className={styles.conclusion}>
          <div className={styles.row}>
            {dim.alignment === 'Very diverse' ? (
              <div className={classNames(styles.icon, styles.green)}>
                <i className="fas fa-check-double" />
              </div>
            ) : dim.alignment === 'Diverse' ? (
              <div className={classNames(styles.icon, styles.grey)}>
                <i className="fas fa-check" />
              </div>
            ) : dim.alignment === 'Similar' ? (
              <div className={classNames(styles.icon, styles.yellow)}>
                <i className="fas fa-check" />
              </div>
            ) : dim.alignment === 'Very similar' ? (
              <div className={classNames(styles.icon, styles.red)}>
                <i className="fas fa-check" />
              </div>
            ) : null}
            <p>
              <strong>
                Team is
                <span
                  className={styles.lowercase}
                >{` ${dim.alignment}`}</span>
              </strong>
              on <span>{dim.name}</span>
            </p>
          </div>
          <div className={styles.row}>
            <div className={styles.icon}>
              <TeamMemberAvatars members={members} size="small" />
            </div>
            <p>
              <strong>
                Team is
                <span className={styles.lowercase}>
                  {getScoreText()}
                </span>
              </strong>
              (avg. score)
            </p>
          </div>
        </div>
      )}

      <div className={styles.listBoxWrapper}>
        <div className={classNames(styles.listBox, styles.workBest)}>
          <h2>
            <i className="fas fa-bolt" />
            The team is strong at:
          </h2>
          <div className={styles.listRowWrapper}>
            {dim.strengths.map((strenght) => (
              <div className={styles.listRow} key={strenght.short}>
                <h4>{strenght.short}</h4>
                <p>{strenght.long}</p>
              </div>
            ))}
          </div>
        </div>
        <div
          className={classNames(styles.listBox, styles.mightStruggle)}
        >
          <h2>
            <i className="fas fa-exclamation-triangle" />
            Not comfortable with:
          </h2>
          <div className={styles.listRowWrapper}>
            {dim.derailers.map((derailer) => (
              <div className={styles.listRow} key={derailer.short}>
                <h4>{derailer.short}</h4>
                <p>{derailer.long}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}
