import React from 'react'
import UserAvatar from 'components/UserAvatar'
import Score from 'components/Score'
import styles from './SwarmPlot.module.scss'

function scaleBetween(unscaledNum, min, max) {
  const minAllowed = 40
  const maxAllowed = 100
  return (
    ((maxAllowed - minAllowed) * (unscaledNum - min)) / (max - min) + minAllowed
  )
}

export default ({ dim, members, resource }) => {
  // console.log({dim})
  const userScoresSorted = members
    .map((m) => {
      return {
        ...m,
        score: m[resource].find(d => d.name === dim.name).score
      }
    })
    .sort((a, b) => a.score - b.score)
  const usersSortedWithScaleFactor = userScoresSorted.map((member, i) => {
    const scoresInRange = userScoresSorted.filter(
      u => u.id !== member.id && Math.abs(u.score - member.score) < 10
    )

    if (dim.dim_id === 508) {
      console.log({ member, scoresInRange })
    }

    //
    // const accumulator = 0
    //
    // // for (let count = 0; members.length - 1; ++count ) {
    // //   const nextDiff = userScoresSorted[i + 1] && userScoresSorted[i + 1].score - member.score
    // //   if (nextDiff && nextDiff <= 10 ) {
    // //     accumulator += nextDiff
    // //   }
    // // }
    // //
    // // console.log({accumulator})
    // const prev = userScoresSorted[i - 1] && userScoresSorted[i - 1].score
    //
    // const next = userScoresSorted[i + 1] && userScoresSorted[i + 1].score
    //
    // let scaleFactor
    // if (i === 0) {
    //   scaleFactor = next - member.score < 15 ? (100 - (next - member.score) / 2) * 0.1 : 1
    // } else if (!next) {
    //   scaleFactor = member.score - prev < 15 ? (100 - (member.score - prev) / 2) * 0.1 : 1
    // } else {
    //   scaleFactor = next - member.score < 15 || member.score - prev < 15
    //     ? (100 - (next - member.score + (member.score - prev)) / 3) * 0.1
    //     : 1
    // }

    return {
      ...member,
      scaleFactor: scoresInRange.length + 1
    }
  })
  console.log({ dim })
  return (
    <div className={styles.wrap}>
      <div className={styles.chart}>
        <div className={styles.membersWrap}>
          <div className={styles.blobWrap}>
            {usersSortedWithScaleFactor.map((member, i) => {
              const { score, scaleFactor } = member
              const blobSize = scaleBetween(
                scaleFactor,
                1,
                Math.max(...usersSortedWithScaleFactor.map(m => m.scaleFactor))
              )

              // const blobSize = 40 * member.scaleFactor
              if (dim.dim_id === 508) {
                console.log({ score, scaleFactor, blobSize })
              }

              return (
                <div
                  key={`swarm-dim-${dim.dim_id}-member-${member.id}-blob`}
                  className={styles.blob}
                  style={{
                    left: `${member.score}%`,
                    transform: `translate(-${blobSize / 2}px, -${blobSize / 2
                      - 8}px)`,
                    height: `${blobSize}px`,
                    width: `${blobSize}px`
                  }}
                />
              )
            })}
          </div>
          <div className={styles.avg} style={{ left: `${dim.score}%` }}>
            <div className={styles.number}>
              <Score score={dim.score} scoreStyle={styles} />
            </div>
          </div>
          {userScoresSorted.map((member, i) => {
            const next = userScoresSorted[i + 1]
            const nextIsTooClose = next && next.score - member.score < 5
            const Yamount = userScoresSorted[i - 2]
              && member.score - userScoresSorted[i - 2].score < 5
              ? 32
              : 18
            const top = nextIsTooClose
              ? i % 2
                ? `${Yamount}px`
                : `-${Yamount}px`
              : null

            return (
              <div
                key={`swarm-dim-${dim.dim_id}-member-${member.id}`}
                className={styles.member}
                style={{ left: `${member.score}%`, top }}
              >
                <UserAvatar member={member} size='verySmall' showName />
              </div>
            )
          })}
        </div>
        <svg>
          <filter id='goo'>
            <feGaussianBlur
              in='SourceGraphic'
              stdDeviation='10'
              result='blur'
            />
            <feColorMatrix
              in='blur'
              mode='matrix'
              values='1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9'
              result='goo'
            />
            <feComposite in='SourceGraphic' in2='goo' operator='atop' />
          </filter>
        </svg>
      </div>
    </div>
  )
}
