import React from "react";
import classNames from "classnames";
import Button from "components/UI/Button";
import Icon from "components/UI/Icon";
import Label from "components/UI/Label";

import styles from "./CompetenciesList.module.scss";

const priorities = {
  33: "low",
  66: "medium",
  100: "high",
};

const candPriorityTitles = {
  high: "top-ranked",
  medium: "neutral",
  low: "low-ranked",
};

const priorityIcons = {
  high: "Bolt",
  medium: "Circle",
  low: "Warning",
};
const priorityColors = {
  high: "green",
  medium: "grey",
  low: "yellow",
};

export default ({
  competencies,
  posCompetencies,
  setShowModal,
  columnTitle,
  columnIcon,
  posPriority,
  color,
}) => (
  <div className={classNames(styles.compBox, styles[color])}>
    <div className={styles.colHead}>
      <div className={classNames(styles.icon, styles[posPriority])}>
        <Icon icon={columnIcon} />
      </div>
      <h4 className={styles.colTitle}>{columnTitle}</h4>
    </div>
    <div className={styles.compColumn}>
      {posCompetencies.map((c) => {
        const candComp = competencies.find((c1) => c.title === c1.name);
        const candScore = parseInt(candComp.score, 10);
        const candPriority =
          Object.entries(priorities).find(
            ([k]) => candScore < Number(k)
          )?.[1] ?? Object.values(priorities).slice(-1)[0];

        return (
          <div className={styles.comp} key={c.title}>
            <Button
              bStyle={["none"]}
              type="button"
              onClick={() => setShowModal(c.title)}
            >
              <div className={styles.compHead}>
                <div
                  className={classNames(
                    styles.priorityLabel,
                    styles[candPriority]
                  )}
                >
                  <Icon icon={priorityIcons[candPriority]} />
                  {candPriorityTitles[candPriority]}
                </div>
              </div>
              <h5 className={styles.compTitle}>{c.title}</h5>
              <p className={styles.compDesc}>{c.descShort}</p>
            </Button>
          </div>
        );
      })}
    </div>
  </div>
);
