import React, { useState } from "react";
import classNames from "classnames";
import Popup from "reactjs-popup";
import Modal from "react-modal";
import { Link } from "react-router-dom";
import DropDown from "components/UI/DropDown";
import Button from "components/UI/Button";
import Icon from "components/UI/Icon";
import getAvatarColor from "utils/orgAvatarColor";
import NewAccount from "components/Modals/NewAccount";
import OrgInvitation from "components/Modals/OrgInvitation";
import { CLOSE_INVITE } from "store/actionTypes";
import Notifications from "./Notifications";
import BannerCTA from "./BannerCTA";
import compose from "./compose";

import styles from "./NoOrgs.module.scss";

export default compose(
  ({
    data: { invites },
    account,
    inviteStore,
    dispatch,
    viewer,
    handleSwitchAccount,
    logOut,
  }) => {
    const [accountModal, setAccountModal] = useState(false);
    const [showWorkspaces, setShowWorkspaces] = useState(true);

    return (
      <div className={styles.main}>
        <div className={styles.wrapper}>
          <h3 className={styles.title}>You don't have an active workspace</h3>
          <div className={styles.bottom}>
            <div className={styles.actions}>
              {(invites.length > 0 || viewer.accounts.length === 0) && (
                <div className={classNames(styles.notifications)}>
                  <div className={styles.action}>
                    <div className={styles.left}>
                      <div className={styles.icon}>
                        <Icon icon="Bell" />
                      </div>
                      {" Notifications (" + invites.length + ")"}
                    </div>
                  </div>
                  <Notifications
                    invites={invites}
                    dispatch={dispatch}
                    inviteStore={inviteStore}
                  />
                </div>
              )}
              {viewer.accounts.length > 0 && (
                <>
                  <div className={styles.workspaces}>
                    <Button
                      bStyle={["secondary"]}
                      onClick={() => {
                        if (showWorkspaces) {
                          setShowWorkspaces(false);
                        } else {
                          setShowWorkspaces(true);
                        }
                      }}
                    >
                      <div className={styles.left}>
                        <div className={styles.icon}>
                          <Icon icon="Kebab" />
                        </div>
                        Browse workspaces
                        <span
                          className={classNames(
                            styles.arrow,
                            showWorkspaces && styles.close
                          )}
                        >
                          <Icon icon="Chevron" />
                        </span>
                      </div>
                    </Button>

                    <div
                      className={classNames(
                        styles.accountsWrapper,
                        showWorkspaces && styles.show
                      )}
                    >
                      {viewer.accounts
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((a) => (
                          <li
                            className={classNames(
                              a.name === account.name && styles.selected
                            )}
                            key={"account-" + a.id}
                          >
                            <Button
                              type="button"
                              bStyle={["none"]}
                              onClick={() => {
                                handleSwitchAccount(a.id);
                              }}
                            >
                              <div className={styles.left}>
                                <div
                                  className={classNames(
                                    styles.avatar,
                                    styles[getAvatarColor(a)]
                                  )}
                                >
                                  <h5>{a.name[0]}</h5>
                                </div>
                                {a.name}
                              </div>
                              <div className={styles.arrow}>
                                <Icon icon="Chevron" />
                              </div>
                            </Button>
                          </li>
                        ))}
                    </div>
                  </div>
                </>
              )}
              <div className={styles.action}>
                <Link to="/personal">
                  <Button bStyle={["secondary"]}>
                    <div className={styles.left}>
                      <div className={styles.icon}>
                        <Icon icon="User" />
                      </div>
                      My profile
                    </div>
                    <span>View your profile</span>
                  </Button>
                </Link>
              </div>
              <div className={styles.action}>
                <Button bStyle={["secondary"]} onClick={() => logOut()}>
                  <div className={styles.left}>
                    <div className={classNames(styles.icon, styles.arrow)}>
                      <Icon icon="Arrow" />
                    </div>
                    Log out
                  </div>
                  <span>Log out of Wisnio</span>
                </Button>
              </div>
            </div>
          </div>
          <div className={styles.banner}>
            <BannerCTA action={() => setAccountModal(true)} />
          </div>
        </div>
        <Modal
          isOpen={!!accountModal}
          onRequestClose={() => setAccountModal(false)}
          shouldCloseOnOverlayClick
          className={classNames(styles.modal, "create")}
        >
          <NewAccount updateModal={setAccountModal} />
        </Modal>
        <Modal
          isOpen={inviteStore.isOpen}
          onRequestClose={() => dispatch({ type: CLOSE_INVITE })}
          shouldCloseOnOverlayClick
          className="create"
        >
          <Button
            bStyle="close"
            type="button"
            onClick={() => dispatch({ type: CLOSE_INVITE })}
          >
            <i className="fal fa-times" />
          </Button>
          <OrgInvitation invite={inviteStore.invite} />
        </Modal>
      </div>
    );
  }
);
