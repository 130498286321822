import React from "react";
import { Link } from "react-router-dom";
import Headroom from "react-headroom";
import classNames from "classnames";
import Button from "components/UI/Button";
import Icon from "components/UI/Icon";
import { ReactComponent as GoalAlignment } from "images/icons/toolkits/goal-alignment.svg";
import { ReactComponent as CompetencyMapping } from "images/icons/toolkits/competency-mapping.svg";
import { ReactComponent as ValuesAlignment } from "images/icons/toolkits/values-alignment.svg";
import { ReactComponent as Retrospective } from "images/icons/toolkits/retrospective.svg";
import { ReactComponent as UsersIcon } from "images/icons/Users.svg";
import styles from "./WorkflowHeader.module.scss";

export default ({
  toolkit,
  list,
  history,
  currentTab,
  currentIndex,
  setCurrentTab,
  setCurrentIndex,
  teamId,
  handleFinishTeamPlay,
}) => {
  const setNextObj = () => {
    if (currentIndex + 1 <= list[currentTab].length - 1) {
      setCurrentTab(currentTab);
      setCurrentIndex(currentIndex + 1);
    } else {
      setCurrentTab(currentTab + 1);
      setCurrentIndex(0);
    }
  };

  const icon = {
    "goal-alignment": GoalAlignment,
    "competency-mapping": CompetencyMapping,
    "values-alignment": ValuesAlignment,
    retrospective: Retrospective,
    "team-effectiveness": UsersIcon,
  };

  return (
    <>
      <Headroom className="main">
        <div className={classNames(styles.header)}>
          <div className={styles.left}>
            <div className={classNames(styles.icon, styles[toolkit.color])}>
              {React.createElement(icon[toolkit.icon], null)}
            </div>
            <div className={styles.info}>
              <h5 className={styles.name}>{toolkit.name}</h5>
              <p className={styles.email}>Tool</p>
            </div>
          </div>
          <div className={styles.right}>
            {currentTab + 1 === list.length &&
            currentIndex + 1 === list[currentTab].length ? (
              <Button
                bStyle={["green", "small"]}
                type="button"
                onClick={
                  toolkit.inProgress
                    ? handleFinishTeamPlay
                    : () => history.push(`/team/${teamId}/toolkit`)
                }
              >
                Finish
              </Button>
            ) : (
              <Button
                bStyle={["green"]}
                type="button"
                onClick={() => setNextObj()}
              >
                Complete step
              </Button>
            )}
            <Link to={`/team/${teamId}/toolkit`}>
              <Button bStyle={["none"]} type="button">
                <Icon icon="Close" />
              </Button>
            </Link>
          </div>
        </div>
      </Headroom>
    </>
  );
};
