import React from 'react'
import { Link } from 'react-router-dom'

// export default compose(
//   branch(
//     props => props.link,
//     renderComponent(props => (
//       <Link onClick={() => window.scrollTo(0, 0)} to={props.link}>
//         {props.children}
//       </Link>
//     ))
//   )
// )

export default ({ link, children, condition }) => {
  if (link && condition) {
    return (
      <Link onClick={() => window.scrollTo(0, 0)} to={link}>
        {children}
      </Link>
    )
  }
  return children
}
