import React from "react";
import { compose, withState } from "recompose";
import { graphql, withApollo } from "react-apollo";
import { loader } from "graphql.macro";
import { withRouter } from "react-router-dom";
import handleLoading from "utils/loader";

import Circles from "components/Loaders/Circles";

const POSITION_QUERY = loader("client/Queries/PositionQuery98.graphql");

export default compose(
  withRouter,
  withApollo,
  withState("cardOpen", "setCardOpen", null),
  withState("newCard", "setNewCard", null),
  graphql(POSITION_QUERY, {
    options: (props) => {
      return {
        variables: { id: parseInt(props.match.params.positionId, 10) },
        notifyOnNetworkStatusChange: true,
      };
    },
  }),
  handleLoading(() => (
    <div style={{ height: "100vh" }}>
      <Circles />
    </div>
  ))
);
