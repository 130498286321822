import React from 'react'
import TeamMembers from 'components/TeamMembers'
import Wrapper from 'components/UI/Wrapper'
import Title from 'components/UI/Title'
import Subtitle from 'components/UI/Subtitle'

import styles from './OtherMembersInTeam.module.scss'

export default ({ teamMember, team }) => (
  <div className={styles.main}>
    <Wrapper>
      <Title text='Other members of the team' icon='fas fa-users' />
      <Subtitle
        text={`Learn about other team members to see which values drive their
        motivation and how their personality contributes to the team's
        diversity.`}
      />
      <div className={styles.membersWrapper}>
        <TeamMembers team={team} teamMember={teamMember} />
      </div>
    </Wrapper>
  </div>
)
