import React from "react";
import classNames from "classnames";
import Button from "components/UI/Button";
import Field from "components/UI/Field";
import { ReactComponent as Users } from "images/icons/Users.svg";
import avatarColor from "../avatarColor";
import compose from "./compose";

import styles from "./AddToTeams.module.scss";

export default compose(
  ({
    teamsAndPositions,
    addTeamMembersToExitstingTeam,
    searchValue,
    setSearchValue,
    loading,
    organization
  }) => {
    const teams = organization.teams.filter(
      t => !teamsAndPositions.positions.some(p => p.parent_team_id === t.id)
    );

    return (
      <div className={styles.wrapper}>
        <h5>Add to team</h5>
        <div className={styles.search}>
          <Field
            white
            small
            noBorder
            deleteIcon
            autoComplete="off"
            icon="fas fa-search"
            type="text"
            name="search"
            value={searchValue}
            setValue={setSearchValue}
            placeholder="Search teams"
            onChange={e => setSearchValue(e.target.value)}
          />
        </div>
        {teams
          .filter(
            t =>
              !teamsAndPositions.positions.some(p => p.parent_team_id === t.id)
          )
          .filter(ot => !teamsAndPositions.teams.find(ut => ut.id === ot.id))
          .some(
            t => t.name.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1
          ) ? (
          <div className={styles.teams}>
            {teams
              .filter(
                ot => !teamsAndPositions.teams.find(ut => ut.id === ot.id)
              )
              .filter(
                t =>
                  t.name.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1
              )
              .map(team => {
                return (
                  <Button
                    key={`teamAdd${team.id}`}
                    bStyle={["none"]}
                    type="button"
                    onClick={() => addTeamMembersToExitstingTeam(team)}
                    disabled={
                      loading ||
                      team.members.length + team.pendingInvites.length > 19
                    }
                  >
                    <div className={styles.team}>
                      <div className={styles.left}>
                        <div
                          className={classNames(
                            styles.avatar,
                            styles[avatarColor(team.name)]
                          )}
                        >
                          <Users />
                        </div>
                        <div className={styles.info}>
                          <p className={styles.name}>{team.name}</p>
                          <p className={styles.members}>
                            {team.members.length +
                              (team.members.length === 1
                                ? " member"
                                : " members")}
                          </p>
                        </div>
                      </div>
                      <div className={styles.right}>
                        <p>Click to add</p>
                      </div>
                    </div>
                  </Button>
                );
              })}
          </div>
        ) : (
          <div className={styles.teams}>
            <p className={styles.noteams}>No teams found</p>
          </div>
        )}
      </div>
    );
  }
);
