import React, { useState } from "react";
import DOMPurify from "dompurify";
import classNames from "classnames";
import { Link } from "react-router-dom";
import Popup from "reactjs-popup";
import Icon from "components/UI/Icon";
import { Form } from "react-final-form";
import DropDown from "components/UI/DropDown";
import Timestamp from "react-timestamp";
import UserAvatar from "components/UserAvatar";
import Button from "components/UI/Button";
import { printoutRoute } from "client/authRoutes";
import UseTemplatePopup from "../UseTemplatePopup";

import compose from "./compose";

import styles from "./TemplateModal.module.scss";

export default compose(
  ({
    templateId,
    moreData,
    orgId,
    data: { positionTemplate },
    setStep,
    handleCreatePosition,
    selectedTeam,
    setSelectedTeam,
    account,
    isAdmin,
  }) => {
    const [showMore, setShowMore] = useState(false);
    const [showMoreDetail, setShowMoreDetail] = useState("");

    const getContentHtml = (content) => ({
      __html: DOMPurify.sanitize(content),
    });

    const competencies = positionTemplate.competencies.filter(
      (comp) => typeof comp.answer === "number"
    );

    return (
      <React.Fragment>
        <div
          className={classNames(
            styles.header,
            positionTemplate.category && styles[positionTemplate.category.color]
          )}
        >
          <h2 className={styles.title}>
            {positionTemplate &&
            positionTemplate.isGlobal &&
            positionTemplate.title.match(/(\w+)/g).length === 2
              ? `${positionTemplate.title.split(" ")[0]} \n ${
                  positionTemplate.title.split(" ")[1]
                }`
              : positionTemplate.title}
          </h2>
          <div className={styles.labels}>
            {positionTemplate.category && (
              <p
                className={classNames(
                  styles.label,
                  styles[positionTemplate.category.color]
                )}
              >
                {positionTemplate.category.name}
              </p>
            )}
            {positionTemplate.stage && (
              <p
                className={classNames(
                  styles.label,
                  styles["border-" + positionTemplate.category.color]
                )}
              >
                {positionTemplate.stage.name}
              </p>
            )}
          </div>
        </div>
        <div className={classNames(styles.wrapper)}>
          <div className={styles.bar}>
            <div className={styles.buttons}>
              <div className={styles.buttonWrapper}>
                <div className="dropdownWrapper" id="dropdownMenu">
                  <Popup
                    keepTooltipInside=".modal"
                    trigger={
                      <Button bStyle={["primary", "verySmall"]}>
                        Use scorecard
                      </Button>
                    }
                    position="bottom left"
                    arrow={false}
                  >
                    {(close) => (
                      <>
                        {!isAdmin ? (
                          <div className={styles.notAdmin}>
                            This is an administrator-only function. Contact the
                            organisation administrator or{" "}
                            <a onClick={() => window.Intercom("show")}>
                              click here
                            </a>{" "}
                            to chat with us for help
                          </div>
                        ) : (
                          <DropDown>
                            <Form
                              autoComplete="new-password"
                              onSubmit={handleCreatePosition}
                              render={({
                                handleSubmit,
                                form,
                                submitting,
                                pristine,
                                values,
                              }) => (
                                <div className={styles.popup}>
                                  <form
                                    onSubmit={handleSubmit}
                                    autoComplete="off"
                                  >
                                    <UseTemplatePopup
                                      data={moreData}
                                      selectedTeam={selectedTeam}
                                      setSelectedTeam={setSelectedTeam}
                                      selectedTemplate={templateId}
                                      setStep={setStep}
                                    />
                                    <div className={styles.buttonGroup}>
                                      <Button
                                        bStyle={["primary", "small"]}
                                        type="submit"
                                        disabled={
                                          pristine ||
                                          !values.name ||
                                          !selectedTeam ||
                                          selectedTeam === -1
                                        }
                                      >
                                        Create position
                                      </Button>
                                      <Button
                                        bStyle={["secondary", "small"]}
                                        type="button"
                                        onClick={close}
                                      >
                                        Cancel
                                      </Button>
                                    </div>
                                  </form>
                                </div>
                              )}
                            />
                          </DropDown>
                        )}
                      </>
                    )}
                  </Popup>
                </div>
              </div>
              <div className={styles.buttonWrapper}>
                <a
                  href={`${printoutRoute}/printout/scorecard/${orgId}/${
                    positionTemplate.id
                  }/${positionTemplate.title.replace(
                    " ",
                    "_"
                  )}_hiring_scorecard.pdf`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button bStyle={["secondary", "verySmall"]}>Print PDF</Button>
                </a>
              </div>
              {!positionTemplate.isGlobal && account.adminRights && (
                <div className={styles.buttonWrapper}>
                  <Link to={`/${orgId}/scorecard/${positionTemplate.id}/edit`}>
                    <Button bStyle={["secondary", "verySmall"]}>
                      Edit scorecard
                    </Button>
                  </Link>
                </div>
              )}
            </div>
            <div className={styles.createdBy}>
              <div className={styles.info}>
                <h5>{`Created by ${positionTemplate.createdBy.name}`}</h5>
                {positionTemplate.isGlobal ? (
                  <p>{positionTemplate.createdBy.title}</p>
                ) : (
                  <p>
                    {"Last updated "}
                    <Timestamp
                      relative
                      date={new Date(positionTemplate.createdAt)}
                      autoUpdate
                    />
                  </p>
                )}
              </div>
              <div className={styles.avatar}>
                <UserAvatar member={positionTemplate.createdBy} size="small" />
              </div>
            </div>
          </div>
          <div
            className={classNames(
              styles.content,
              !positionTemplate.isGlobal && styles.userMade
            )}
          >
            {positionTemplate.highlights.filter(
              (h) => h.title && h.title !== ""
            ).length > 0 && (
              <div className={styles.includes}>
                <h5 className={styles.title}>Highlights</h5>
                <div className={styles.numbers}>
                  {positionTemplate.highlights
                    .filter((h) => h.title && h.title !== "")
                    .map((highlight) => (
                      <div className={styles.number}>
                        <div className={styles.right}>
                          <h3 className={styles.numberTitle}>
                            {highlight.title}
                          </h3>
                          <p className={styles.numberDesc}>
                            {highlight.description}
                          </p>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            )}
            <p className={styles.description}>{positionTemplate.description}</p>
            <div
              className={classNames(styles.separate, showMore && styles.show)}
            >
              <Button
                bStyle={["secondary", "verySmall"]}
                onClick={() => {
                  if (showMore) {
                    setShowMore(false);
                  } else {
                    setShowMore(true);
                  }
                }}
              >
                {showMore ? "Show less" : "Show more"}
              </Button>
            </div>
            <div className={classNames(styles.table, showMore && styles.show)}>
              <div className={styles.titleBar}>
                <h5>Key objectives</h5>
                <p className={styles.subtitle}>
                  Key objectives help create the context for basic requirements
                  and competencies.
                </p>
              </div>
              <div className={styles.list}>
                <div
                  className={styles.keyObjectives}
                  dangerouslySetInnerHTML={getContentHtml(
                    positionTemplate.objectives
                  )}
                />
              </div>
              <div className={classNames(styles.titleBar, styles.primary)}>
                <h5>Position requirements</h5>
                <p className={styles.subtitle}>
                  The required experiences, qualifications, and skills needed to
                  achieve the key objectives of the role.
                </p>
              </div>
              <div className={styles.list}>
                {positionTemplate.requirements.map((req) => (
                  <li
                    key={req.id}
                    className={styles.hoverable}
                    onClick={() => {
                      if (showMoreDetail.title === req.title) {
                        setShowMoreDetail("");
                      } else if (req.description) {
                        setShowMoreDetail(req);
                      }
                    }}
                  >
                    <div className={styles.listRow}>
                      {req.title}
                      {req.description && (
                        <span
                          className={classNames(
                            styles.arrow,
                            showMoreDetail.title === req.title && styles.open
                          )}
                        >
                          <Button bStyle={["none"]}>
                            <Icon icon="Chevron" />
                          </Button>
                        </span>
                      )}
                    </div>
                    <div
                      className={classNames(
                        styles.moreDetail,
                        showMoreDetail.title === req.title && styles.show
                      )}
                    >
                      <div
                        className={styles.rowDescription}
                        dangerouslySetInnerHTML={getContentHtml(
                          req.description
                        )}
                      ></div>
                    </div>
                  </li>
                ))}
              </div>
              <div className={classNames(styles.titleBar, styles.green)}>
                <h5>Competencies</h5>
                <p className={styles.subtitle}>
                  Competencies are the knowledge, skills, and abilities required
                  to perform a job successfully.
                </p>
              </div>
              <div className={styles.list}>
                {competencies.slice(0, 3).map((comp) => (
                  <li
                    key={comp.id}
                    className={styles.hoverable}
                    onClick={() => {
                      if (showMoreDetail.title === comp.title) {
                        setShowMoreDetail("");
                      } else if (comp.desc) {
                        setShowMoreDetail(comp);
                      }
                    }}
                  >
                    <div className={styles.listRow}>
                      <div className={styles.left}>
                        {comp.title}
                        <span className={styles.stars}>Must have</span>
                      </div>
                      {comp.desc && (
                        <span
                          className={classNames(
                            styles.arrow,
                            showMoreDetail.title === comp.title && styles.open
                          )}
                        >
                          <Button bStyle={["none"]}>
                            <Icon icon="Chevron" />
                          </Button>
                        </span>
                      )}
                    </div>
                    <div
                      className={classNames(
                        styles.moreDetail,
                        showMoreDetail.title === comp.title && styles.show
                      )}
                    >
                      <div
                        className={styles.rowDescription}
                        dangerouslySetInnerHTML={getContentHtml(comp.desc)}
                      ></div>
                    </div>
                  </li>
                ))}
                {competencies.slice(3, 6).map((comp) => (
                  <li
                    key={comp.id}
                    className={styles.hoverable}
                    onClick={() => {
                      if (showMoreDetail.title === comp.title) {
                        setShowMoreDetail("");
                      } else if (comp.desc) {
                        setShowMoreDetail(comp);
                      }
                    }}
                  >
                    <div className={styles.listRow}>
                      <div className={styles.left}>
                        {comp.title}
                        <span
                          className={classNames(styles.stars, styles.light)}
                        >
                          Important to have
                        </span>
                      </div>
                      {comp.desc && (
                        <span
                          className={classNames(
                            styles.arrow,
                            showMoreDetail.title === comp.title && styles.open
                          )}
                        >
                          <Button bStyle={["none"]}>
                            <Icon icon="Chevron" />
                          </Button>
                        </span>
                      )}
                    </div>
                    <div
                      className={classNames(
                        styles.moreDetail,
                        showMoreDetail.title === comp.title && styles.show
                      )}
                    >
                      <div
                        className={styles.rowDescription}
                        dangerouslySetInnerHTML={getContentHtml(comp.desc)}
                      ></div>
                    </div>
                  </li>
                ))}
                {competencies.slice(6, 9).map((comp) => (
                  <li
                    key={comp.id}
                    className={styles.hoverable}
                    onClick={() => {
                      if (showMoreDetail.title === comp.title) {
                        setShowMoreDetail("");
                      } else if (comp.desc) {
                        setShowMoreDetail(comp);
                      }
                    }}
                  >
                    <div className={styles.listRow}>
                      <div className={styles.left}>
                        {comp.title}
                        <span
                          className={classNames(styles.stars, styles.lightest)}
                        >
                          Good to have
                        </span>
                      </div>
                      {comp.desc && (
                        <span
                          className={classNames(
                            styles.arrow,
                            showMoreDetail.title === comp.title && styles.open
                          )}
                        >
                          <Button bStyle={["none"]}>
                            <Icon icon="Chevron" />
                          </Button>
                        </span>
                      )}
                    </div>
                    <div
                      className={classNames(
                        styles.moreDetail,
                        showMoreDetail.title === comp.title && styles.show
                      )}
                    >
                      <div
                        className={styles.rowDescription}
                        dangerouslySetInnerHTML={getContentHtml(comp.desc)}
                      ></div>
                    </div>
                  </li>
                ))}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
);
