import React from "react";
import classNames from "classnames";
import Button from "components/UI/Button";
import Icon from "components/UI/Icon";

import styles from "./BannerCTA.module.scss";

export default ({ action }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.left}>
        <h3>Create your own workspace!</h3>
        <p>
          To access the full functionality of the platform, create your own
          workspace.
        </p>
        <Button bStyle={["primary", "verySmall"]} onClick={action}>
          Create new workspace
        </Button>
      </div>
      <div className={styles.right}>
        <div className={styles.listItem}>
          <div className={styles.icon}>
            <Icon icon="Users" />
          </div>
          <p>Free for teams of up to 4 people</p>
        </div>
        <div className={styles.listItem}>
          <div className={styles.icon}>
            <Icon icon="Logo" />
          </div>
          <p>Full platform functionality</p>
        </div>
        <div className={styles.listItem}>
          <div className={styles.icon}>
            <Icon icon="Billing" />
          </div>
          <p>No credit card required</p>
        </div>
      </div>
    </div>
  );
};
