import React from "react";
import Circles from "components/Loaders/Circles";
import axios from "utils/axios";
import {
  compose,
  branch,
  renderComponent,
  withState,
  lifecycle,
} from "recompose";
import { withRouter } from "react-router-dom";
import { authStrategies } from "client/authRoutes";

export default compose(
  withRouter,
  withState("loading", "setLoading", true),
  withState("modal", "updateModal", { open: false }),
  withState("authStrategies", "setAuthStrategies", []),
  lifecycle({
    componentDidMount() {
      let url;
      if (this.props.invite) {
        url = `${authStrategies}?inviteSecret=${this.props.invite.secret}`;
      } else {
        url = authStrategies;
      }
      axios.get(url).then(({ data }) => {
        this.props.setAuthStrategies(data);
        this.props.setLoading(false);
      });
    },
  }),
  branch(
    ({ loading }) => loading && true,
    renderComponent(() => (
      <div style={{ height: "400px" }}>
        <Circles />
      </div>
    ))
  )
);
