import { compose, withState, withHandlers } from 'recompose'

export default compose(
  withState('openModal', 'updateModal', {}),
  withHandlers({
    determineIfValueIsImportant:
      ({
        user: {
          personalProfile: {
            valuesChart: { values }
          }
        },
        openModal
      }) =>
      () => {
        if (!openModal.name) return false
        return (
          values
            .filter((d) => d.groupId === 'user_value_facets')
            .sort((a, b) => b.score - a.score)
            .slice(0, 3)
            .find((d) => d.name === openModal.name) && true
        )
      }
  })
)
