import React from "react";
import { compose, withHandlers, withState } from "recompose";
import { withRouter } from "react-router-dom";
import { graphql } from "react-apollo";
import { loader } from "graphql.macro";
import withStore from "utils/withStore";

const PLAYERS_WITH_EFFECTIENESS_SCORES = loader(
  "../playWithEffectivenessScores2.graphql"
);
const CREATE_COMMENT = loader("./createComment4.graphql");
const REMOVE_COMMENT = loader("./removeComment2.graphql");

export default compose(
  withStore("viewer"),
  withState("comment", "setComment", ""),
  withState("showAccordion", "setShowAccordion", ""),
  graphql(CREATE_COMMENT, {
    name: "createComment",
    options: ({ play }) => ({
      update: (proxy, { data: { createComment } }) => {
        const data = proxy.readQuery({
          query: PLAYERS_WITH_EFFECTIENESS_SCORES,
          variables: {
            play_id: parseInt(play.id, 10),
          },
        });

        if (data.teamPlay.comments) {
          data.teamPlay.comments = [...data.teamPlay.comments, createComment];
        }

        proxy.writeQuery({
          query: PLAYERS_WITH_EFFECTIENESS_SCORES,
          variables: {
            play_id: parseInt(play.id, 10),
          },
          data,
        });
      },
    }),
  }),
  graphql(REMOVE_COMMENT, {
    name: "removeComment",
    options: ({ play }) => ({
      update: (proxy, { data: { removeComment } }) => {
        const data = proxy.readQuery({
          query: PLAYERS_WITH_EFFECTIENESS_SCORES,
          variables: {
            play_id: parseInt(play.id, 10),
          },
        });

        data.teamPlay.comments = data.teamPlay.comments.filter(
          (c) => c.id !== removeComment.id
        );

        proxy.writeQuery({
          query: PLAYERS_WITH_EFFECTIENESS_SCORES,
          variables: {
            play_id: parseInt(play.id, 10),
          },
          data,
        });
      },
    }),
  }),
  withHandlers({
    handleCreateComment:
      ({ play, dimension, comment, createComment, setComment }) =>
      async (e) => {
        e.preventDefault();
        if (comment && comment.length > 0) {
          await createComment({
            variables: {
              obj_type: "team_play",
              obj_id: parseInt(play.id, 10),
              item_type: "team_play_effectiveness",
              item_id: parseInt(dimension.dim_id, 10),
              comment,
            },
          }).then(() => {
            setComment("");
          });
        }
      },
    handleRemoveComment:
      ({ removeComment }) =>
      async (comment) => {
        await removeComment({
          variables: {
            id: comment.id,
          },
        });
      },
  })
);
