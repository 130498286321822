import React, { useEffect } from "react";
import classNames from "classnames";
import NoRights from "components/EmptyStates/NoRights";
import Team from "components/TeamCard";
import { useSidebar } from "utils/sidebarProvider";
import compose from "./compose";

import Button from "components/UI/Button";
import Icon from "components/UI/Icon";

import styles from "../Teams.module.scss";

export default compose(
  ({ data: { organization }, selectedOrg, history, updateModal }) => {
    const { contentContext } = useSidebar();
    const [content, setContent] = contentContext;

    const emptyStateContent = {
      notice: {
        title: "You currently don’t have access to this feature. ",
        subtitle:
          "To fully experience Wisnio, please ask your workspace admin for access or consider setting up your own workspace.",
      },

      subtitle: "Create your own workspace to get access to:",
      list: [
        {
          title: "Team Profiles",
          description:
            "Get insights into potential competency caps and strengths, team members' unique characteristics, and team culture. ",
        },
        {
          title: "AI-powered insights",
          description:
            "AI-powered tools interpret the talent data and make it immediately actionable. You just need to ask WisGPT the right questions. ",
        },
      ],
      thumbnail:
        "https://storage.googleapis.com/iaeqpsxmmk/onboarding_images/team_dev.png",
      video: "https://storage.googleapis.com/iaeqpsxmmk/team.mp4",
    };

    const teams = [
      selectedOrg.adminRights && {
        title: "Create team",
        action: () => updateModal("newTeam"),
        icon: "Plus",
        style: "primary",
        sticky: true,
      },
    ];

    organization &&
      organization.teams &&
      organization.teams.map((team) => {
        if (team) {
          teams.push({
            title: team.name,
            action: () => history.push(`/team/${team.id}`),
            color: "extradarkgrey",
            initial: team.name.charAt(0).toUpperCase(),
            children: team.members.map((member) => {
              return {
                title: member.name,
                action: () =>
                  history.push("/team/" + team.id + "/member/" + member.id),
              };
            }),
          });
        }
      });

    const sidebarContent = [
      {
        items: teams,
      },
    ];

    useEffect(() => {
      setContent(sidebarContent);
    }, [selectedOrg]);

    const showPlaceholders = (length) => {
      const placeholders = [];
      if (length < 4) {
        const num = 3 - length;
        for (let i = 0; i < num; i++) {
          placeholders.push(
            <div key={i} className={styles.placeholder}>
              <div className={styles.top}>
                <div className={styles.avatars}>
                  <div className={styles.avatar}></div>
                  <div className={styles.avatar}></div>
                </div>
                <div className={styles.lines}>
                  <div className={styles.line}></div>
                  <div className={styles.line}></div>
                  <div className={styles.line}></div>
                </div>
              </div>
              <div className={styles.bottom}>
                <div className={styles.line}></div>
              </div>
            </div>
          );
        }

        return placeholders;
      }
      return null;
    };

    const noRights =
      selectedOrg.id > 0 &&
      organization &&
      !organization.adminRights &&
      organization.teams.length === 0;

    return (
      <React.Fragment>
        {selectedOrg.id > 0 && organization && organization.teams.length > 0 && (
          <div
            className={classNames(
              styles.teamsList,
              organization.teams.length > 6 && styles.manyTeams
            )}
          >
            {organization.teams.map((team) => (
              <Team team={team} key={team.id} />
            ))}
            {showPlaceholders(organization.teams.length)}
          </div>
        )}
        {noRights && (
          <div className={styles.noRights}>
            <NoRights
              pageTitle={"Teams"}
              emptyStateContent={emptyStateContent}
              admins={organization.admins.active}
              isCandidate={selectedOrg.contract.role === "candidate"}
            />
          </div>
        )}
      </React.Fragment>
    );
  }
);
