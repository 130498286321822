import React from 'react'
import classNames from 'classnames'
import Button from 'components/UI/Button'
import Field from 'components/UI/Field'
import { ReactComponent as Users } from 'images/icons/Users.svg'
import avatarColor from '../avatarColor'
import compose from './compose'

import styles from './AddToPosition.module.scss'

export default compose(
  ({
    organization,
    teamsAndPositions,
    addCandidatesToPosition,
    searchValue,
    setSearchValue,
    loading
  }) => {
    const positions = organization.allOpenPositions.filter(
      p => !teamsAndPositions.teams.some(t => t.id === p.parentTeam.id)
    )
    return (
      <div className={styles.wrapper}>
        <h5>Add to position</h5>
        <div className={styles.search}>
          <Field
            white
            small
            noBorder
            deleteIcon
            autoComplete='off'
            icon='fas fa-search'
            type='text'
            name='search'
            value={searchValue}
            setValue={setSearchValue}
            placeholder='Search positions'
            onChange={e => setSearchValue(e.target.value)}
          />
        </div>
        {positions
          .filter(
            ot => !teamsAndPositions.positions.find(ut => ut.id === ot.id)
          )
          .some(
            t => t.name.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1
          ) ? (
            <div className={styles.positions}>
              {positions
                .filter(
                  ot => !teamsAndPositions.positions.find(ut => ut.id === ot.id)
                )
                .filter(
                  t => t.name.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1
                )
                .map(position => (
                  <Button
                    key={`posAdd${position.id}`}
                    bStyle={['none']}
                    type='button'
                    onClick={() => addCandidatesToPosition(position)}
                    disabled={loading}
                  >
                    <div className={styles.position}>
                      <div className={styles.left}>
                        <div
                          className={classNames(
                            styles.avatar,
                            styles[avatarColor(position.name)]
                          )}
                        >
                          <Users />
                        </div>
                        <div className={styles.info}>
                          <p className={styles.name}>{position.name}</p>
                          <p className={styles.members}>
                            {position.candidates.length
                            + (position.candidates.length === 1
                              ? ' candidate'
                              : ' candidates')}
                          </p>
                        </div>
                      </div>
                      <div className={styles.right}>
                        <p>Click to add</p>
                      </div>
                    </div>
                  </Button>
                ))}
            </div>
          ) : (
            <div className={styles.positions}>
              <p className={styles.nopositions}>No positions found</p>
            </div>
          )}
      </div>
    )
  }
)
