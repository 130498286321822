import React from 'react'
import Container from 'components/UI/Container'
import Button from 'components/UI/Button'
import { ReactComponent as GoalAlignment } from 'images/icons/toolkits/goal-alignment.svg'

import compose from './compose'

import styles from './PickFinalGoals.module.scss'

export default compose(
  ({ TEAM_GOAL_GROUPS: { teamGoalGroups }, handleUpdateGroupFinal }) => (
    <div className={styles.main}>
      <div className={styles.content}>
        <p className={styles.subtitle}>
          Choose 1 - 3 goals from the grouped goals and decide on a time-frame
          for its completion.
        </p>
        <h5 className={styles.final}>Final goals:</h5>
        <div className={styles.finalGoalsWrapper}>
          {teamGoalGroups
            .filter(gg => gg.isFinal)
            .map(gg => (
              <div className={styles.goal} key={`goal-${gg.id}`}>
                <div className={styles.remove}>
                  <Button
                    bStyle={['none']}
                    type='button'
                    onClick={() => handleUpdateGroupFinal(gg.id, false)}
                  >
                    <i className='far fa-times' />
                  </Button>
                </div>
                <div className={styles.iconActive}>
                  <GoalAlignment />
                </div>
                <h5 className={!gg.title ? styles.italic : styles.something}>
                  {gg.title || 'No title'}
                </h5>
              </div>
            ))}
          {teamGoalGroups.filter(gg => gg.isFinal).length < 1 && (
            <>
              <div className={styles.goal}>
                <div className={styles.icon}>
                  <GoalAlignment />
                </div>
                <div className={styles.line} />
                <div className={styles.line} />
              </div>
              <div className={styles.goal}>
                <div className={styles.icon}>
                  <GoalAlignment />
                </div>
                <div className={styles.line} />
                <div className={styles.line} />
              </div>
            </>
          )}
        </div>
        <div className={styles.goalGroupsWrapper}>
          {teamGoalGroups
            .filter(gg => !gg.isFinal)
            .map((g, index) => (
              <div className={styles.group} key={`group-${g.id}`}>
                <Container size='large' cStyle={['noShadow']}>
                  <div className={styles.row}>
                    <div className={styles.col}>
                      <span className={styles.number}>{index + 1}</span>
                    </div>
                    <div className={styles.col}>
                      <div className={styles.text}>
                        <h5
                          className={
                            !g.title ? styles.italic : styles.something
                          }
                        >
                          {g.title || 'No title'}
                        </h5>
                      </div>
                    </div>
                    <div className={styles.col}>
                      <Button
                        bStyle={['newsimple']}
                        type='button'
                        onClick={() => handleUpdateGroupFinal(g.id, true)}
                      >
                        Choose
                      </Button>
                    </div>
                  </div>
                </Container>
              </div>
            ))}
        </div>
      </div>
    </div>
  )
)
