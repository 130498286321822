import React from 'react'
import classNames from 'classnames'
import Button from 'components/UI/Button'
import compose from './compose'

import styles from './ResendInvite.module.scss'

export default compose(
  ({
    handleResendInvite,
    btnState,
    invite,
    handleResendExistingUserInvite
  }) => (
    <Button
      bStyle={['none']}
      onClick={
        invite.type === 'user'
        || (invite.contract && invite.contract.state === 'waiting_user_acceptance')
          ? handleResendExistingUserInvite
          : handleResendInvite
      }
      disabled={btnState.disabled}
    >
      <div
        className={classNames(
          styles.buttonText,
          btnState.isLoading && styles.loading
        )}
      >
        <i className={btnState.text} />
        {btnState.hoverText}
      </div>
    </Button>
  )
)
