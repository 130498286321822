import React from "react";
import Button from "components/UI/Button";
import DistributionBarChart from "components/Charts/DistributionBarChart";
import { ReactComponent as Bulb } from "images/icons/Bulb.svg";
import getAlignment from "utils/getAlignment";

import styles from "../TopValues.module.scss";

export default ({
  memberWorkBest,
  data,
  updateModal,
  teamMember,
  candidate,
}) => {
  const noTeamMembers = data.members.length === 0 || data.members.filter(m => m.readyToRender).length === 0;

  return (
    <>
      {memberWorkBest.map((dim) => {
        const teamDim = data.valuesChart.values.find(
          (d) => d.name === dim.name
        );
        const { colour, className, label } = getAlignment({
          dim,
          team: data,
          teamMember,
          resource: "values",
        });

        return (
          <div className={styles.row} key={dim.name}>
            <div className={styles.column}>
              <div className={styles.titleRow}>
                <h3 className={styles.title}>{dim.name}</h3>
                {!noTeamMembers && (
                  <span className={styles[className]}>{label}</span>
                )}
              </div>
              <p className={styles.subtitle}>{dim.definition}</p>
              <div className={styles.barChartWrapper}>
                <DistributionBarChart
                  dim={teamDim}
                  members={data.members}
                  resource="values"
                  colour={colour}
                  highlight={candidate || teamMember}
                  candidate={candidate}
                />
              </div>
              <div className={styles.extremes}>
                <div className={styles.column}>
                  <h5 className={styles.title}>Less important</h5>
                  <p className={styles.text}>{dim.description_min}</p>
                </div>
                <div className={styles.column}>
                  <h5 className={styles.title}>Very important</h5>
                  <p className={styles.text}>{dim.description_max}</p>
                </div>
              </div>
            </div>
            <div className={styles.column}>
              <div className={styles.card}>
                <div className={styles.content}>
                  <h5 className={styles.title}>
                    <i className="fas fa-user-shield" />
                    {`${
                      teamMember ? teamMember.first_name : candidate.first_name
                    }'s attitude`}
                  </h5>
                  <p className={styles.text}>{teamDim.valuesStrength}</p>
                </div>
                <div className={styles.footer}>
                  <Button
                    bStyle={["none", "icon"]}
                    type="button"
                    onClick={() => updateModal(dim)}
                  >
                    {`More on ${dim.name}`}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};
