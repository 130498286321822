import React from "react";
import classNames from "classnames";
import { ReactComponent as BulbIcon } from "images/icons/Bulb.svg";
import { ReactComponent as UserIcon } from "images/icons/User.svg";
import TeamMemberAvatars from "components/TeamMemberAvatars";
import UserAvatar from "components/UserAvatar";
import Button from "components/UI/Button";
import Icon from "components/UI/Icon";
import CompetencyGraph from "components/Competencies/CompetencyGraph";

import styles from "./CompetencyModal.module.scss";

const priorities = {
  33: "low",
  66: "medium",
  100: "high",
};

const posPriorities = {
  3: "high",
  6: "medium",
  9: "low",
  25: "notRelevant",
};

const rankIcons = {
  notRelevant: "Minus",
  low: "Warning",
  medium: "Circle",
  high: "Bolt",
};
const posRankIcons = {
  notRelevant: "",
  low: "LowRank",
  medium: "MediumRank",
  high: "HighRank",
};

const posRankDescriptions = {
  notRelevant:
    "Not critical for the role. Discuss only if it becomes unexpectedly relevant.",
  low: "Beneficial but not essential. Explore during interviews if candidates are closely matched.",
  medium:
    "Differentiating competency for the role. Validate for final candidates to determine their potential.",
  high: "Critical competency for the role. Validate thoroughly during interviews with all candidates.",
};

const priorityTitles = {
  high: "Must have",
  medium: "Important to have",
  low: "Nice to have",
  notRelevant: "Not relevant",
};

const candPriorityTitles = {
  high: "Top-ranked",
  medium: "Neutral",
  low: "Low-ranked",
};

const rankDescriptions = {
  low: "The candidate rated this lower, indicating low confidence. Explore reasons and evaluate its importance.",
  medium:
    "The candidate gave this an average priority, indicating moderate confidence. Assess track record and growth potential.",
  high: "The candidate prioritized this competency, indicating high confidence. Validate with examples during the interview.",
};

const interviewRecomendations = {
  strength: {
    text: "Explore during interview to learn how they can leverage their key strengths in this role.",
    icon: <Icon icon="Atom" />,
    color: "blue",
  },
  potentialIssue: {
    text: "During the interview, determine if it's a low priority for the candidate or if it's an area of lesser skill.",
    icon: <Icon icon="Warning" />,
    color: "red",
  },
};

export default ({
  currentCompName,
  viewer,
  members,
  teamMember,
  candidate,
  setShowModal,
  resource,
  competencies,
  candidateCompetencies,
  member,
}) => {
  if (currentCompName === "") return null;

  const comp = competencies.find(
    (c) => c.name === currentCompName || c.title === currentCompName
  );

  const currentCompIndex = competencies.findIndex((c) => c.name === comp.name);
  const incrementedCompeIndex = currentCompIndex + 1;
  const decrementedCompeIndex = currentCompIndex - 1;
  const nextComp =
    incrementedCompeIndex === competencies.length ? 0 : incrementedCompeIndex;
  const prevComp =
    decrementedCompeIndex === -1
      ? competencies.length - 1
      : decrementedCompeIndex;

  const insightMembers = [];

  const candComp = candidateCompetencies
    ? candidateCompetencies.find((c1) => currentCompName === c1.name)
    : candidate
    ? candidate.competencies.find((c1) => currentCompName === c1.name)
    : null;
  const candScore = candComp ? parseInt(candComp.score, 10) : null;

  const candPriority =
    Object.entries(priorities).find(([k]) => candScore < Number(k))?.[1] ??
    Object.values(priorities).slice(-1)[0];

  const posRank = candidate
    ? candidate.positionCompetencies.findIndex(
        (pc) => pc.title === currentCompName
      )
    : null;

  const posPriority =
    Object.entries(posPriorities).find(([k]) => posRank < Number(k))?.[1] ??
    Object.values(posPriorities).slice(-1)[0];

  comp.insight &&
    comp.insight.members &&
    comp.insight.members.length > 0 &&
    comp.insight.members.map((m) => {
      insightMembers.push(members.find((mem) => mem.id === m));
    });

  const recommendation =
    candPriority === "high" && ["high", "medium", "low"].includes(posPriority)
      ? "strength"
      : candPriority === "low" &&
        ["high", "medium", "low"].includes(posPriority)
      ? "potentialIssue"
      : null;

  return (
    <>
      <div className={styles.desktop}>
        <div className={styles.prevButton}>
          <Button
            bstyle={["none"]}
            type="button"
            onClick={() => setShowModal(competencies[prevComp].name)}
          >
            <i className="fas fa-chevron-left" />
          </Button>
        </div>
      </div>
      <div className={styles.modalWrapper}>
        <div className={styles.info}>
          <div className={styles.titleNButtons}>
            <div className={styles.mobile}>
              <Button
                bstyle={["none"]}
                type="button"
                onClick={() => setShowModal(competencies[prevComp].name)}
              >
                <i className="fas fa-chevron-left" />
              </Button>
            </div>
            <h2>{comp.name}</h2>
            <div className={styles.mobile}>
              <Button
                bstyle={["none"]}
                type="button"
                onClick={() => setShowModal(competencies[nextComp].name)}
              >
                <i className="fas fa-chevron-right" />
              </Button>
            </div>
          </div>
          <h4 className={styles.subtitle}>{comp.description || comp.desc}</h4>
        </div>
        {resource === ("candidate" || "comparison") &&
          (() => {
            const candComp = candidate.competencies.find(
              (cc) => cc.name === comp.title
            );

            return (
              <h4 className={styles.candidateStrongText}>
                <div className={styles.iconWrapper}>{candComp.icon}</div>
                {candComp.title}
              </h4>
            );
          })}

        {resource === "candidate" && (
          <>
            <div className={styles.cards}>
              <div className={styles.card}>
                <div className={styles.cardHead}>
                  <span
                    className={classNames(
                      styles.icon,
                      styles.candidateIcon,
                      styles[candPriority]
                    )}
                  >
                    <Icon icon={rankIcons[candPriority]} />
                  </span>
                  <h5>{`${candPriorityTitles[candPriority]}`}</h5>
                </div>
                <p className={styles.cardDesc}>
                  {rankDescriptions[candPriority]}
                </p>
              </div>
              <div className={styles.card}>
                <div className={styles.cardHead}>
                  <span
                    className={classNames(
                      styles.icon,
                      styles.posPriority,
                      styles[posPriority]
                    )}
                  >
                    <Icon icon={posRankIcons[posPriority]} />
                  </span>
                  <h5>{`${priorityTitles[posPriority]}`}</h5>
                </div>
                <p className={styles.cardDesc}>
                  {posRankDescriptions[posPriority]}
                </p>
              </div>
            </div>
            {/* {recommendation && (
              <div
                className={classNames(
                  styles.recommendation,
                  styles[interviewRecomendations[recommendation].color]
                )}
              >
                <div className={styles.icon}>
                  {interviewRecomendations[recommendation].icon}
                </div>
                <p className={styles.text}>
                  {interviewRecomendations[recommendation].text}
                </p>
              </div>
            )} */}
          </>
        )}

        {resource === "personal" && (
          <CompetencyGraph
            highlight={viewer}
            resource="personal"
            competencies={competencies}
            comp={comp}
            large
            uid={Math.random(10)}
          />
        )}

        {resource === "candidate" && (
          <div className={styles.compGraphWrapper}>
            <div className={styles.desktopGraph}>
              <CompetencyGraph
                members={members}
                highlight={teamMember || candidate}
                comp={comp}
                large
                uid={Math.random(10)}
              />
            </div>
            <div className={styles.tablet}>
              <CompetencyGraph
                members={members}
                highlight={teamMember || candidate}
                comp={comp}
                uid={Math.random(10)}
              />
            </div>
            <div className={styles.mobile}>
              <CompetencyGraph
                members={members}
                highlight={teamMember || candidate}
                comp={comp}
                small
                uid={Math.random(10)}
              />
            </div>
          </div>
        )}

        {resource === "orgUser" && (
          <CompetencyGraph
            highlight={member}
            resource="orgUser"
            competencies={competencies}
            comp={comp}
            large
            uid={Math.random(10)}
          />
        )}

        {members && members.length > 0 && !candidate && (
          <div className={styles.compGraphWrapper}>
            <div className={styles.desktopGraph}>
              <CompetencyGraph
                members={members}
                highlight={teamMember || candidate}
                comp={comp}
                large
                uid={Math.random(10)}
              />
            </div>
            <div className={styles.tablet}>
              <CompetencyGraph
                members={members}
                highlight={teamMember || candidate}
                comp={comp}
                uid={Math.random(10)}
              />
            </div>
            <div className={styles.mobile}>
              <CompetencyGraph
                members={members}
                highlight={teamMember || candidate}
                comp={comp}
                small
                uid={Math.random(10)}
              />
            </div>
          </div>
        )}

        {resource === "position" && (
          <p className={styles.longDesc}>{comp.desc}</p>
        )}
        {teamMember &&
          teamMember.inTeamCompetencyInsights
            .filter(
              (c) =>
                c.dim_id ===
                competencies.find(
                  (c1) =>
                    c1.name === currentCompName || c1.title === currentCompName
                ).dim_id
            )
            .map((insight) => (
              <div key={insight.title} className={styles.insight}>
                <div className={styles.left}>
                  <div className={styles.avatars}>
                    <UserAvatar member={teamMember} size="verySmall" showName />
                  </div>
                  <div className={styles.text}>
                    <h5>{insight.title}</h5>
                    <p>{insight.description_long}</p>
                  </div>
                </div>
                <div className={styles.right}>
                  <BulbIcon />
                  <h5>Insight</h5>
                </div>
              </div>
            ))}

        {!teamMember && comp.insight && (
          <div className={styles.insight}>
            <div className={styles.left}>
              <div className={styles.avatars}>
                <TeamMemberAvatars
                  members={insightMembers}
                  size="verySmall"
                  showName
                />
              </div>
              <div className={styles.text}>
                <h5>{comp.insight.title}</h5>
                <p>{comp.insight.description_long}</p>
              </div>
            </div>
            <div className={styles.right}>
              <BulbIcon />
              <h5>Insight</h5>
            </div>
          </div>
        )}

        {(resource === "candidate" && comp.questionsForCandidate.length > 0) ||
        (resource === "candidateOther" &&
          comp.questionsForCandidate.length > 0) ? (
          <div className={styles.interviewWrapper}>
            <h4 className={styles.interviewTitle}>
              <span className={styles.interviewTitleIcon}>
                <Icon icon="Chat" />
              </span>
              Behavioural interview questions
            </h4>
            <div className={styles.questionsWrapper}>
              {comp.questionsForCandidate.map((q) => (
                <div className={styles.questionWrapper} key={q}>
                  <div className={styles.left}>
                    <span className={styles.questionIcon}>
                      <Icon icon="ChatBubble" />
                    </span>
                    <p className={styles.question}>
                      {q.split(",").map((q1) => (
                        <li key={q1} className={styles.questionRow}>
                          {q1}
                        </li>
                      ))}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <>
            {comp.behaviours && (
              <div
                className={classNames(
                  styles.interviewWrapper,
                  styles.behaviors
                )}
              >
                <p className={styles.prefix}>
                  <UserIcon />
                  {`A person who is highly skilled at ${comp.name}`}
                </p>
                <div className={styles.questionsWrapper}>
                  {comp.behaviours.map((q, key) => (
                    <li className={styles.question} key={`behaviour-${key}`}>
                      {q}
                    </li>
                  ))}
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <div className={styles.desktop}>
        <div className={styles.nextButton}>
          <Button
            bstyle={["none"]}
            type="button"
            onClick={() => setShowModal(competencies[nextComp].name)}
          >
            <i className="fas fa-chevron-right" />
          </Button>
        </div>
      </div>
    </>
  );
};

// {(!team || team.members.length === 0) && candidate && (
//   <div className={styles.compGraphWrapper}>
//     <div className={styles.desktopGraph}>
//       <CompetencyGraph
//         members={members}
//         teamMember={teamMember && teamMember}
//         candidate={candidate && candidate}
//         comp={comp}
//         large
//         uid={Math.random(10)}
//       />
//     </div>
//     <div className={styles.tablet}>
//       <CompetencyGraph
//         members={members}
//         teamMember={teamMember && teamMember}
//         candidate={candidate && candidate}
//         comp={comp}
//         uid={Math.random(10)}
//       />
//     </div>
//     <div className={styles.mobile}>
//       <CompetencyGraph
//         members={members}
//         teamMember={teamMember && teamMember}
//         candidate={candidate && candidate}
//         comp={comp}
//         small
//         uid={Math.random(10)}
//       />
//     </div>
//     <div className={styles.compGraphLegends}>
//       <p className={styles.legend}>
//         <i className='fas fa-chart-network' />
//         Neutral
//       </p>
//       <p className={styles.legend}>
//         <IconGoodG />
//         Good
//       </p>
//       <p className={styles.legend}>
//         <IconImportantG />
//         Strong
//       </p>
//       <p className={styles.legend}>
//         <IconMustG />
//         Great
//       </p>
//     </div>
//   </div>
// )}
