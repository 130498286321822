import React from "react";
import { Droppable } from "react-beautiful-dnd";
import classNames from "classnames";
import Icon from "components/UI/Icon";
import styles from "./Kanban.module.scss";
import Draggable from "./Draggable";

const clusterClasses = {
  compe_people: 3, // Interpersonal skills
  results: 1, // Results orientation
  org_effectiveness: 2, // Organisational effectiveness
  thought: 4, // Systems thinking
};

export default ({ setOpenModal, columns, headers, resource }) => (
  <div className={styles.kanbanWrapper}>
    {columns.slice(0, 3).map((col, colIndex) => {
      const columnHeader = headers[colIndex];

      // // Begin conflicting competencies logic
      // const colCompClasses = [];
      // const conflictingComps = [];

      // col.map((comp) => {
      //   colCompClasses.push({
      //     id: comp.id,
      //     class: clusterClasses[comp.dimParent],
      //   });
      // });

      // if (
      //   colCompClasses.filter((cl) => cl.class === 1).length > 0 &&
      //   colCompClasses.filter((cl) => cl.class === 2).length > 0
      // ) {
      //   colCompClasses.map((ccc) => {
      //     if (ccc.class === 1 || ccc.class === 2) {
      //       conflictingComps.push(ccc);
      //     }
      //   });
      // }

      // if (
      //   colCompClasses.filter((cl) => cl.class === 3).length > 0 &&
      //   colCompClasses.filter((cl) => cl.class === 4).length > 0
      // ) {
      //   colCompClasses.map((ccc) => {
      //     if (ccc.class === 3 || ccc.class === 4) {
      //       conflictingComps.push(ccc);
      //     }
      //   });
      // }

      return (
        <div
          key={`droppable-column${colIndex}`}
          className={classNames(
            styles.columnWrapper,
            styles[columnHeader.style]
          )}
        >
          <div className={styles.columnHead}>
            <h4 className={styles.compTitle}>
              <div className={styles.stars}>{columnHeader.icon}</div>
              {columnHeader.title}
            </h4>
          </div>
          <div className={styles.column}>
            <p className={styles.dragHere}>
              <Icon icon="Drag" marginRight />
              Drag here
            </p>
            <Droppable
              droppableId={colIndex.toString()}
              className={styles.droppableBlock}
            >
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className={styles.droppable}
                >
                  {col.map((item, index) => (
                    <Draggable
                      key={`draggable-${item.id}`}
                      item={item}
                      index={index}
                      setOpenModal={setOpenModal}
                      resource={resource}
                    />
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        </div>
      );
    })}
  </div>
);
