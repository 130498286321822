import React from "react";

import { useSortBy, useTable } from "react-table";
import { typography } from "style/general";
import styles from "./styles.module.scss";

const Table = ({ columns, data }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useSortBy);

  return (
    <table {...getTableProps()} className={styles.table}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                {...column.getHeaderProps(column.getSortByToggleProps())}
                className={styles.th}
                style={{
                  ...typography.label,
                  ...typography.bold,
                  ...typography.size14,
                }}
              >
                {column.render("Header")}
                <span>
                  {column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""}
                </span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, rowIndex) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()} className={styles.tr}>
              {row.cells.map((cell, cellIndex) => {
                // If this is the last column, and it has a renderActions function, use that.
                if (
                  cellIndex === row.cells.length - 1 &&
                  columns[cellIndex].renderActions
                ) {
                  return (
                    <td {...cell.getCellProps()} className={styles.td}>
                      {columns[cellIndex].renderActions(row.original)}
                    </td>
                  );
                }
                return (
                  <td {...cell.getCellProps()} className={styles.td}>
                    {cell.render("Cell")}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default Table;
