import React, { useState } from "react";
import UserAvatar from "components/UserAvatar";
import Button from "components/UI/Button";
import Icon from "components/UI/Icon";
import { ReactComponent as GoogleIcon } from "images/icons/Google.svg";
import { ReactComponent as LinkedinIcon } from "images/icons/LinkedIn.svg";
import { ReactComponent as MicrosoftIcon } from "images/icons/Microsoft.svg";
import Circles from "components/Loaders/Circles";
import compose from "./compose";

import styles from "./Callback.module.scss";

export default compose(({ loading, data, newUserFromSession }) => {
  const [isChecked, setIsChecked] = useState(true);

  if (loading) {
    return (
      <div style={{ height: "100vh" }}>
        <Circles />
      </div>
    );
  }

  return (
    <React.Fragment>
      <div className={styles.mainWrapper}>
        <div className={styles.backButton}>
          <Button bStyle={["none"]}>
            <div className={styles.icon}>
              <Icon icon="Arrow" />
            </div>
            Go back
          </Button>
        </div>
        <div className={styles.main}>
          <div className={styles.wrapper}>
            <div className={styles.login}>
              <div className={styles.header}>
                <div className={styles.logo} />
              </div>
              <h3 className={styles.subtitle}>
                Talent analytics for winning teams
              </h3>
              <div className={styles.container}>
                <h3>Confirm sign-up</h3>
                <p>Please confirm your sign up details below.</p>
                <div className={styles.userInfo}>
                  <div className={styles.left}>
                    <div className={styles.avatar}>
                      <UserAvatar size="small" member={data} />
                    </div>
                    <div className={styles.info}>
                      <h5 className={styles.name}>{data.name}</h5>
                      <p className={styles.email}>
                        {data.loginType + " login"}
                      </p>
                    </div>
                  </div>
                  <div className={styles.icon}>
                    {data.loginType === "google" ? (
                      <GoogleIcon />
                    ) : data.loginType === "linkedin" ? (
                      <LinkedinIcon />
                    ) : data.loginType === "microsoft" ? (
                      <MicrosoftIcon />
                    ) : null}
                  </div>
                </div>
                <p className={styles.confirmEmail}>Account e-mail address</p>
                <input type="email" value={data.email} disabled></input>
                <label htmlFor="checkbox" className={styles.checkbox}>
                  <input
                    id="checkbox"
                    type="checkbox"
                    name="checkbox"
                    onChange={(e) =>
                      e.target.checked
                        ? setIsChecked(false)
                        : setIsChecked(true)
                    }
                  />
                  <p className={styles.label}>
                    I have read and agree to the{" "}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://wisnio.com/privacy-policy/"
                    >
                      terms & conditions
                    </a>
                  </p>
                </label>
                <div className={styles.submit}>
                  <Button
                    bStyle={["primary", "small"]}
                    disabled={isChecked}
                    onClick={newUserFromSession}
                  >
                    Create account
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
});
