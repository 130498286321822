import { withState, compose } from "recompose";
import queryString from "query-string";

import withStore from "utils/withStore";

export default compose(
  withStore("selectedOrg", "viewer", "account"),
  withState("searchTerm", "setSearchTerm", ""),
  withState("stages", "setStages", []),
  withState("selectedCategory", "setSelectedCategory", null),
  withState("selectedTags", "setSelectedTags", []),
  withState("createTeamModal", "updateCreateTeamModal", { open: false }),
  withState("categories", "setCategories", []),
  withState("step", "setStep", 0),
  withState("view", "setView", "cards"),
  withState("currentTab", "setCurrentTab", ({ location }) =>
    location.state && location.state.currentTab ? location.state.currentTab : 0
  ),
  withState("templateSecret", "setTemplateSecret", ({ location }) => {
    const { scorecardName } = queryString.parse(location.search);

    if (scorecardName) {
      return scorecardName;
    }

    return null;
  }),
  withState("modal", "updateModal", ({ templateSecret }) =>
    templateSecret ? templateSecret : null
  ),
  withState("selectedTemplate", "setSelectedTemplate", null)
);
