import React from "react";
import classNames from "classnames";

import styles from "./Subtitle.module.scss";

export default ({ text, white, small, verySmall, left }) => (
  <h4
    className={classNames(
      styles.subtitle,
      white && styles.white,
      small && styles.small,
      verySmall && styles.verySmall,
      left && styles.left
    )}
  >
    {text}
  </h4>
);
