import React from 'react'
import {
  compose, withState, withProps, branch
} from 'recompose'
import { graphql, withApollo } from 'react-apollo'
import { loader } from 'graphql.macro'
import handleLoading from 'utils/loader'
import withStore from 'utils/withStore'
import Circles from 'components/Loaders/Circles'
import { withRouter } from 'react-router-dom'
import handleChangeOrgContract from './handleChangeOrgContract'
import handleRemoveUser from './handleRemoveUser'

import {
  defaultDataIdFromObject,
} from 'apollo-cache-inmemory'

const ACCOUNT_MEMBERS = loader('client/Queries/AccountMembers4.graphql')
const UPDATE_ACCOUNT_CONTRACT = loader('./UpdateAccountContract.graphql')
const UPDATE_ACCOUNT_INVITE = loader('./UpdateAccountInvite.graphql')

const REMOVE_FROM_ACCOUNT = loader('./RemoveFromAccount.graphql')

export default compose(
  withRouter,
  withApollo,
  withStore('viewer'),
  withState('limit', 'setLimit', 15),
  withState('pageNr', 'setPageNr', 0),
  withState('order', 'setOrder', { column: 'email', direction: 'ASC' }),
  withState('removeMemberModal', 'updateRemoveMemberModal', null),

  graphql(ACCOUNT_MEMBERS, {
    name: 'ACCOUNT_MEMBERS',
    options: props => ({
      variables: {
        id: parseInt(props.match.params.accountId, 10)
      },
      notifyOnNetworkStatusChange: true
    })
  }),

  branch(
    props => props.ACCOUNT_MEMBERS && props.ACCOUNT_MEMBERS.loading,
    handleLoading(() => (
      <div style={{ height: '100vh' }}>
        <Circles />
      </div>
    ))
  ),
  graphql(UPDATE_ACCOUNT_CONTRACT, {
    name: 'updateAccountContract'
  }),
  graphql(UPDATE_ACCOUNT_INVITE, {
    name: 'updateAccountInvite'
  }),
  graphql(REMOVE_FROM_ACCOUNT, {
    name: 'removeFromAccount',
    options: ({ client, updateRemoveMemberModal, ACCOUNT_MEMBERS}) => ({
      update: (
        proxy,
        {
          data: {
            removeFromAccount: { keys }
          }
        }
      ) => {
        ACCOUNT_MEMBERS.refetch()
        updateRemoveMemberModal(false)
        // keys.forEach((key) => {
        //
        //   console.log({key})
        //   const [typename, value] = key.split(':')
        //   if (proxy.data.data[key]) {
        //     console.log(proxy.data.data[key])
        //     const idObject = defaultDataIdFromObject(proxy.data.data[key])
        //
        //     client.deleteCache({ typename: typename, value: proxy.data.data[key] })
        //   }
        //   updateRemoveMemberModal(false)
        // })
      }
    })
  }),
  handleChangeOrgContract,
  handleRemoveUser,
  withProps(
    ({
      order,
      limit,
      pageNr,
      searchValue,
      ACCOUNT_MEMBERS: { loading, account }
    }) => {
      if (loading) return
      const accountMembers = searchValue && (searchValue !== '' || searchValue !== ' ')
        ? account.members.filter((member) => {
          const emailCondition = member.email.indexOf(searchValue) !== -1
          const nameCondition = member.name
                && member.name.toLowerCase().indexOf(searchValue) !== -1
          return emailCondition || nameCondition
        })
        : account.members
          .sort((a, b) => {
            const aVar = a[order.column].toUpperCase()
            const bVar = b[order.column].toUpperCase()
            if (order.direction === 'ASC') return aVar.localeCompare(bVar)
            return bVar.localeCompare(aVar)
          })
          .slice(limit * pageNr, limit * pageNr + limit)

      return {
        accountMembers
      }
    }
  )
)
