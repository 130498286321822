import React from "react";
import { compose, withState } from "recompose";
import { withApollo, graphql } from "react-apollo";
import { loader } from "graphql.macro";
import handleLoading from "utils/loader";
import FullPage from "components/Loaders/FullPage";
import { withRouter } from "react-router-dom";
import logRead from "utils/logRead";
import purgeTeamAndCandidateFromCache from "utils/purgeTeamAndCandidateFromCache";
import withStore from "utils/withStore";
import handleAddCandidateToTeam from "./handleAddCandidateToTeam";

const POSITION_QUERY = loader("client/Queries/PositionQuery98.graphql");
const TOGGLE_SHOW_POSITION_TOUR = loader("./toggleShowPositionTour.graphql");
const ADD_CANDIDATE_TO_TEAM = loader(
  "client/Mutations/AddCandidateToTeam7.graphql"
);
const DELETE_POSITION = loader("./EditPosition/deletePosition.graphql");
const ORGANIZATION_TEAMS_AND_POSITIONS = loader(
  "client/Queries/OrganizationTeamsAndPositions.graphql"
);

export default compose(
  withApollo,
  withRouter,
  withStore("account"),
  withState("overlayClose", "setOverlayClose", true),
  withState("evaluate", "setEvaluate", false),
  withState("openModal", "updateModal", ""),
  withState("onboardModal", "setOnboardModal", false),
  withState("deleteModal", "setDeleteModal", false),
  graphql(POSITION_QUERY, {
    options: (props) => {
      return {
        variables: { id: parseInt(props.match.params.positionId, 10) },
        notifyOnNetworkStatusChange: true,
      };
    },
  }),
  graphql(TOGGLE_SHOW_POSITION_TOUR, {
    name: "toggleShowPositionTour",
  }),
  graphql(ADD_CANDIDATE_TO_TEAM, {
    name: "addCandidateToTeam",
    options: (props) => {
      return {
        update: (proxy, { data: { addCandidateToTeam } }) => {
          purgeTeamAndCandidateFromCache({
            cache: props.client.cache.data,
            candId: addCandidateToTeam.cand_id,
            team_id: parseInt(props.match.params.teamId, 10),
            proxy,
          });

          const data = proxy.readQuery({
            query: POSITION_QUERY,
            variables: {
              id: parseInt(props.match.params.positionId, 10),
            },
          });

          data.openPosition.onboardings = [
            ...data.openPosition.onboardings,
            data.openPosition.candidates.find(
              (c) => c.id === addCandidateToTeam.cand_id
            ),
          ];

          data.openPosition.candidates = [
            ...data.openPosition.candidates.filter(
              (c) => c.id !== addCandidateToTeam.cand_id
            ),
          ];

          proxy.writeQuery({
            query: POSITION_QUERY,
            variables: {
              id: parseInt(props.match.params.positionId, 10),
            },
            data,
          });

          props.setOpenModal(false);
        },
      };
    },
  }),
  graphql(DELETE_POSITION, {
    name: "deletePosition",
    options: (props) => {
      return {
        update: (proxy, { data: { deletePosition } }) => {
          const org_id = props.data.openPosition.parentOrganization.id;
          const data = proxy.readQuery({
            query: ORGANIZATION_TEAMS_AND_POSITIONS,
            variables: {
              id: org_id,
            },
          });
          data.organization.allOpenPositions =
            data.organization.allOpenPositions.filter(
              (pos) => pos.id !== deletePosition.id
            );
          proxy.writeQuery({
            query: ORGANIZATION_TEAMS_AND_POSITIONS,
            variables: {
              id: org_id,
            },
            data,
          });
        },
      };
    },
  }),
  handleLoading(() => <FullPage />),
  handleAddCandidateToTeam
  // logRead({
  //   obj_type: "position",
  //   obj_id: "data.openPosition.id",
  // })
);
