import React from 'react'
import { Link } from 'react-router-dom'
import TeamCard from 'components/Card'
import TeamMemberAvatars from 'components/TeamMemberAvatars'

import styles from './TeamCard.module.scss'

export default ({ team }) => {
  if (!team) return null

  const profilesComplete = team.members.filter(member => member.readyToRender)
    .length

  return (
    <Link to={`team/${team.id}`} className={styles.link}>
      <TeamCard cardStyle={['team']}>
        <div aria-label='top'>
          <div className={styles.avatars}>
            <TeamMemberAvatars
              members={team.members}
              length={team.members.length}
              small
            />
          </div>
          <h4>{team.name}</h4>

          {team.members.length > 0 ? (
            <div aria-label='upperLine'>
              <p>
                {`${team.members.length} member${
                  team.members.length === 1 ? '' : 's'
                } in team`}
              </p>
              <p
                aria-label={
                  team.members.length - profilesComplete > 0 && 'yellow'
                }
              >
                {team.members.length - profilesComplete > 0
                  ? `${team.members.length - profilesComplete} profile${
                    team.members.length - profilesComplete === 1 ? '' : 's'
                  } incomplete`
                  : 'All profiles complete'}
              </p>
            </div>
          ) : (
            <div aria-label='upperLine'>
              <p>Waiting for members</p>
            </div>
          )}
        </div>
        <div aria-label='bottom'>
          <p>
            {team.pendingInvites.length > 0
              ? `${team.pendingInvites.length} pending invite${team.pendingInvites.length === 1 ? '' : 's'}`
              : 'No pending invites'}
          </p>
        </div>
      </TeamCard>
    </Link>
  )
}
