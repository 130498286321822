import React from "react";
import { compose } from "recompose";
import { loader } from "graphql.macro";
import { graphql } from "react-apollo";
import FullPage from "components/Loaders/FullPage";
import handleLoading from "utils/loader";
import withStore from "utils/withStore";
import { withRouter } from "react-router-dom";

const USER_REVIEWS = loader("./userReviews4.graphql");

export default compose(
  withRouter,
  withStore("selectedOrg"),
  graphql(USER_REVIEWS, {
    options: ({
      match: {
        params: { org_id },
      },
    }) => ({
      variables: {
        org_id: parseInt(org_id, 10),
      },
    }),
  }),
  handleLoading(() => <FullPage />)
);
