import React, { useEffect } from "react";
import classNames from "classnames";
import Modal from "react-modal";
import Button from "components/UI/Button";
import Template from "components/PositionTemplates/Template";
import TemplateModal from "components/PositionTemplates/TemplateModal";
import CardLoader from "components/Loaders/CardLoader";
import DeleteModal from "./DeleteModal";
import compose from "./compose";

import styles from "./ChooseTemplate.module.scss";

export default compose(
  ({
    POSITION_TEMPLATES: { positionTemplates },
    data,
    account,
    updateModal,
    setStep,
    selectedTeam,
    setSelectedTeam,
    currentTab,
    selectedTemplate,
    setSelectedTemplate,
    handleDeletePositionTemplate,
    handleCreatePosition,
    removeModal,
    setRemoveModal,
    setEditModal,
    selectedCategory,
    setSelectedCategory,
    setSelectedStage,
    setCategories,
    modal,
    selectedOrg,
    match,
    searchTerm,
    setStages,
    selectedTags,
    templateSecret,
    view,
  }) => {
    if (!positionTemplates) {
      return (
        <div className={styles.skeleton}>
          <div className={styles.cardLoaderWrapper}>
            <CardLoader template />
          </div>
          <div className={styles.cardLoaderWrapper}>
            <CardLoader template />
          </div>
          <div className={styles.cardLoaderWrapper}>
            <CardLoader template />
          </div>
        </div>
      );
    }

    const global = searchTerm
      ? positionTemplates.filter(
          (t) =>
            t.isGlobal &&
            t.title.toLowerCase().includes(searchTerm.toLowerCase())
        )
      : positionTemplates.filter((t) => t.isGlobal);
    const accountTemplates = searchTerm
      ? positionTemplates.filter(
          (t) =>
            !t.isGlobal &&
            t.title.toLowerCase().includes(searchTerm.toLowerCase())
        )
      : positionTemplates.filter((t) => !t.isGlobal);
    const templates = currentTab === 0 ? global : accountTemplates;

    const templateCategories = [];

    templates.forEach((t) => {
      if (
        t.category &&
        !templateCategories.filter((s) => s.title === t.category.name).length >
          0
      ) {
        templateCategories.push({
          title: t.category.name,
          dot: t.category.color,
          style: selectedCategory === t.category.name ? "active" : "",
        });
      }
    });

    templateCategories.sort();

    useEffect(() => {
      const categories = [];

      categories.push({
        title: "All",
        action: () => {
          setSelectedCategory(null);
          setSelectedStage(null);
        },
        dot: "grey",
        style: !selectedCategory ? "active" : "",
      });

      templateCategories.forEach((t) => {
        if (!categories.filter((s) => s.title === t.title).length > 0) {
          categories.push({
            title: t.title,
            action: () => {
              if (selectedCategory === t.title) {
                setSelectedCategory(null);
                setSelectedStage(null);
              } else {
                setSelectedCategory(t.title);
                setSelectedStage(null);
              }
            },
            dot: t.dot,
            style: t.style,
          });
        }
      });
      setCategories(categories);

      const stagesArray = [];

      templates.forEach((t) => {
        if (
          t.stage &&
          !stagesArray.filter((f) => f.name === t.stage.name).length > 0
        ) {
          if (selectedCategory && t.category.name === selectedCategory) {
            stagesArray.push({
              id: t.stage.id,
              name: t.stage.name,
              color: t.stage.color,
            });
          } else if (!selectedCategory) {
            stagesArray.push({
              id: t.stage.id,
              name: t.stage.name,
              color: t.stage.color,
            });
          }
        }
      });

      setStages(stagesArray);
    }, [currentTab, selectedCategory]);

    const filteredTemplates = () => {
      if (selectedCategory && selectedTags.length === 0) {
        return templates.filter(
          (template) =>
            template.category && template.category.name === selectedCategory
        );
      } else if (selectedCategory && selectedTags.length > 0) {
        return templates.filter(
          (template) =>
            template.category &&
            template.category.name === selectedCategory &&
            template.stage &&
            template.stage &&
            selectedTags.some((t) => t.name === template.stage.name)
        );
      } else if (!selectedCategory && selectedTags.length > 0) {
        return templates.filter(
          (template) =>
            template.stage &&
            selectedTags.some((t) => t.name === template.stage.name)
        );
      } else {
        return templates;
      }
    };

    return (
      <div className={classNames(styles.wrapper, "chooseTemplateWrapper")}>
        {templates.length > 0 && (
          <div className={classNames(styles.templatesWrapper, "templates")}>
            {filteredTemplates()
              .sort((a, b) => b.updatedAt - a.updatedAt)
              .map((template) => (
                <div
                  className={classNames(styles.template, styles[view])}
                  key={template.id}
                >
                  <Template
                    key={template.id}
                    moreData={data}
                    template={template}
                    account={account}
                    personal={accountTemplates.find(
                      (t) => t.id === template.id
                    )}
                    updateModal={updateModal}
                    setEditModal={setEditModal}
                    setRemoveModal={setRemoveModal}
                    setSelectedTemplate={setSelectedTemplate}
                    setStep={setStep}
                    handleCreatePosition={handleCreatePosition}
                    selectedTeam={selectedTeam}
                    setSelectedTeam={setSelectedTeam}
                    selectedTemplate={selectedTemplate}
                    orgId={match.params.orgId}
                    view={view}
                  />
                </div>
              ))}
          </div>
        )}
        {templates.length === 0 && searchTerm === "" && (
          <div className={styles.nothing}>
            <p>No workspace scorecards have been created yet.</p>
            <p>
              To create a scorecard, click 'Create a new scorecard' in the upper
              right corner. Alternatively, select 'Save as Scorecard' from the
              position view to save all position configuration.
            </p>
          </div>
        )}
        {templates.length === 0 && searchTerm !== "" && (
          <div className={styles.nothing}>
            <p>No scorecards match your search.</p>
            <p>
              Please try another search term or try searching using categories
              and tags.
            </p>
          </div>
        )}
        {!!removeModal && (
          <Modal
            closeTimeoutMS={350}
            isOpen={!!removeModal}
            onRequestClose={() => setRemoveModal(false)}
            shouldCloseOnOverlayClick
            className={classNames(styles.modal, "delete")}
          >
            <Button
              bStyle="close"
              type="button"
              onClick={() => setRemoveModal(false)}
            >
              <i className="fal fa-times" />
            </Button>
            <DeleteModal
              templateId={removeModal}
              setRemoveModal={setRemoveModal}
              handleDeletePositionTemplate={handleDeletePositionTemplate}
            />
          </Modal>
        )}
        {!!modal && (
          <Modal
            closeTimeoutMS={350}
            isOpen={!!modal}
            onRequestClose={() => {
              window.history.replaceState(
                null,
                "",
                `/${match.params.orgId}/scorecards`
              );
              updateModal("");
            }}
            shouldCloseOnOverlayClick
            className={classNames(styles.modal, "modal")}
          >
            <Button
              bStyle="close"
              type="button"
              onClick={() => {
                window.history.replaceState(
                  null,
                  "",
                  `/${match.params.orgId}/scorecards`
                );
                updateModal("");
              }}
            >
              <i className="fal fa-times" />
            </Button>
            <TemplateModal
              moreData={data}
              templateId={!templateSecret ? modal : null}
              templateSecret={templateSecret}
              selectedTemplate={selectedTemplate}
              setSelectedTemplate={setSelectedTemplate}
              updateModal={updateModal}
              setStep={setStep}
              orgId={selectedOrg.id}
              handleCreatePosition={handleCreatePosition}
              selectedTeam={selectedTeam}
              setSelectedTeam={setSelectedTeam}
              isAdmin={selectedOrg.adminRights}
            />
          </Modal>
        )}
      </div>
    );
  }
);
