import { withState, compose, withHandlers } from "recompose";
import { graphql, withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import { loader } from "graphql.macro";

const ADD_REVIEWERS_TO_ASSESSMENT_SESSION = loader(
  "./addReviewersToAssessmentSession.graphql"
);

export default compose(
  withRouter,
  withApollo,
  withState("isLoading", "setLoading", false),
  graphql(ADD_REVIEWERS_TO_ASSESSMENT_SESSION, {
    name: "addReviewersToAssessmentSession",
  }),
  withHandlers({
    handleAddReviewersToAssessmentSession:
      ({
        addReviewersToAssessmentSession,
        updateModal,
        assessmentSession,
        setLoading,
      }) =>
      (list) => {
        setLoading(true);
        const users = list.map((i) => {
          return {
            obj_id: i.id,
            type: i.type,
            email: i.email,
            first_name: i.first_name,
            last_name: i.last_name,
          };
        });

        addReviewersToAssessmentSession({
          variables: {
            reviewers: users,
            session_id: assessmentSession.id,
          },
        }).then((res) => {
          updateModal(false);
        });
      },
  })
);
