import React from "react";
import classNames from "classnames";
import Curve from "components/Charts/CurveChart/curve";
import CurveChart from "components/Charts/CurveChart";
import Distribution from "components/Charts/CurveChart/Distribution";

import styles from "./LineChart.module.scss";

export default ({ play, selectedDimension, setSelectedDimension }) => {
  const completePlayers = play.players.filter(
    (p) => !p.effectiveness.some((e) => e.score === null)
  );

  return (
    <div className={styles.chart}>
      <div className={classNames(styles.wrap, "personalityChart")}>
        <CurveChart
          dimensions={play.effectiveness.filter(
            (e) => e.groupId === "team_play_effectiveness"
          )}
          play={play}
          players={completePlayers}
          selectedDimension={selectedDimension}
          setSelectedDimension={setSelectedDimension}
        />
        <Distribution players={completePlayers}>
          {completePlayers.map((player) => (
            <Curve
              key={player.id}
              dimensions={player.effectiveness.filter(
                (dim) => dim.groupId === "team_play_user_effectiveness"
              )}
              isPlayer
            />
          ))}
        </Distribution>
      </div>
    </div>
  );
};
