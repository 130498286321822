import { compose, withState } from "recompose";
import { graphql, withApollo } from "react-apollo";
import { loader } from "graphql.macro";
import { withRouter } from "react-router-dom";
import withStore from "utils/withStore";
import purgeTeamPositionTalentpoolCache from "utils/purgeTeamPositionTalentpoolCache";

import handleChangeOrgContract from "./handleChangeOrgContract";
import handleRemoveUser from "./handleRemoveUser";
import handleUpdate from "./handleUpdate";

const UPDATE_ORG_CONTRACT = loader("./UpdateOrgContract7.graphql");
const REMOVE_FROM_ORGANIZATION = loader("./RemoveFromOrganization7.graphql");
const TALENT_POOL_SEARCH = loader("./SearchTalentPool2.graphql");

export default compose(
  withRouter,
  withApollo,
  withStore("viewer"),
  graphql(TALENT_POOL_SEARCH, {
    name: "TALENT_POOL_SEARCH",
    skip: ({ searchValue }) => searchValue.length === 0,
    options: (props) => {
      return {
        variables: {
          org_id: parseInt(props.match.params.orgId, 10),
          searchValue: props.searchValue,
          validationCase: "organization",
        },
        notifyOnNetworkStatusChange: true,
      };
    },
  }),
  graphql(UPDATE_ORG_CONTRACT, {
    name: "updateOrgContractMutation",
  }),
  withState("removeMemberModal", "updateRemoveMemberModal", false),
  graphql(REMOVE_FROM_ORGANIZATION, {
    name: "removeFromOrganizationMutation",
    options: (props) => {
      return {
        update: (proxy, { data: { removeFromOrganization } }) => {
          purgeTeamPositionTalentpoolCache({
            cache: props.client.cache.data,
            keys: removeFromOrganization.keys,
            orgId: parseInt(props.match.params.orgId, 10),
            proxy,
          });
          props.setSearchValue("");
          props.updateRemoveMemberModal(false);
        },
      };
    },
  }),
  handleChangeOrgContract,
  handleRemoveUser,
  handleUpdate
);
