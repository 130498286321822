import React from "react";
import Button from "components/UI/Button";
import Icon from "components/UI/Icon";
import { Form, Field } from "react-final-form";
import compose from "./compose";

import styles from "./NewOrg.module.scss";

export default compose(({ handleCreateOrganization, updateModal }) => (
  <>
    <Button bStyle="close" type="button" onClick={() => updateModal("")}>
      <i className="fal fa-times" />
    </Button>
    <div className={styles.modalWrapper}>
      <div className={styles.modalHead}>
        <h3 className={styles.title}>
          <div className={styles.icon}>
            <Icon icon="Building" />
          </div>
          Create a new organisation
        </h3>
        <p>Use the form below to create a new organisation.</p>
      </div>
      <div className={styles.newTeam}>
        <Form
          autoComplete="new-password"
          onSubmit={handleCreateOrganization}
          render={({ handleSubmit, form, submitting, pristine }) => (
            <form onSubmit={handleSubmit} autoComplete="new-password">
              <div className={styles.row}>
                <h5>Organisation name</h5>
                <Field
                  name="name"
                  component="input"
                  type="text"
                  placeholder="Enter organisation name"
                  autoComplete="off"
                  required
                />
              </div>
              <div className={styles.buttonGroup}>
                <Button
                  bStyle={["secondary", "small"]}
                  onClick={() => updateModal("")}
                >
                  Cancel
                </Button>
                <Button
                  bStyle={["primary", "small"]}
                  type="submit"
                  disabled={submitting || pristine}
                >
                  Create organisation
                </Button>
              </div>
            </form>
          )}
        />
      </div>
    </div>
  </>
));
