import React from 'react'
import { withState, compose, withHandlers } from 'recompose'
import { graphql, withApollo } from 'react-apollo'
import { withRouter } from 'react-router-dom'
import { loader } from 'graphql.macro'
import handleLoading from 'utils/loader'
import Circles from 'components/Loaders/Circles'

const ACCOUNT_ORGANIZATIONS = loader('./AccountOrganization.graphql')
const SEND_ACCOUNT_INVITES = loader('./SendAccountInvites3.graphql')
const ACCOUNT_MEMBERS = loader('client/Queries/AccountMembers4.graphql')

export default compose(
  withRouter,
  withApollo,
  graphql(ACCOUNT_ORGANIZATIONS, {
    name: 'ACCOUNT_ORGANIZATIONS',
    options: (props) => {
      return {
        variables: {
          account_id: props.account.id
        }
      }
    }
  }),
  handleLoading(() => (
    <div style={{ height: '100vh' }}>
      <Circles />
    </div>
  )),

  graphql(SEND_ACCOUNT_INVITES, {
    name: 'sendAccountInvites',
    options: (props) => {
      return {
        update: (proxy, { data: { sendAccountInvites } }) => {
          const data = proxy.readQuery({
            query: ACCOUNT_MEMBERS,
            variables: {
              id: props.account.id
            }
          })

          data.account.members.push(...sendAccountInvites)
          data.account.membersInAccount = data.account.membersInAccount + sendAccountInvites.filter(Boolean).length

          proxy.writeQuery({
            query: ACCOUNT_MEMBERS,
            data,
            variables: {
              id: props.account.id
            }
          })
        }
      }
    }
  }),
  withState('isLoading', 'setLoading', false),
  withHandlers({
    handleSendInvites: ({ updateModal, setLoading, sendAccountInvites, account }) => async ({
      list
    }) => {
      setLoading(true)
      sendAccountInvites({
        variables: {
          invites: list.map((i) => {
            return {
              account_id: account.id,
              email: i.email,
              role_type: 4,
              Organization: {
                org_id: i.Organization.id,
                role_type: i.Organization.roleType
              }
            }
          })
        }
      }).then(res => {

        updateModal('')
        setLoading(false)
      })


    }
  })
)
