import React from "react";
import { compose, withState } from "recompose";
import { withRouter } from "react-router-dom";
import handleLoading from "utils/loader";
import withStore from "utils/withStore";
import { loader } from "graphql.macro";
import { graphql } from "react-apollo";
import handleSelectOrg from "./handleSelectOrg";

import styles from "./OrgSelect.module.scss";

const ORGANIZATIONS = loader("client/Queries/OrganizationsQuery21.graphql");

export default compose(
  withRouter,
  withStore("selectedOrg", "account"),
  withState("searchValue", "setSearchValue", ""),
  withState("modal", "updateModal", ""),
  graphql(ORGANIZATIONS, {
    options: ({ viewer }) => {
      return {
        variables: {
          account_id: parseInt(viewer.default_account_id, 10),
        },
      };
    },
  }),
  handleLoading(() => (
    <div className={styles.skeleton}>
      <div className={styles.avatar}></div>
      <div className={styles.line}></div>
    </div>
  )),
  handleSelectOrg
);
