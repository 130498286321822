import React from "react";
import {
  renderComponent,
  branch,
  compose,
  withProps,
  withState,
  withHandlers,
} from "recompose";
import { withApollo, graphql } from "react-apollo";
import { loader } from "graphql.macro";
import loading from "utils/loader";
import Circles from "components/Loaders/Circles";
import withStore from "utils/withStore";
import {
  NEXT_ONBOARDING_STEP,
  PREV_ONBOARDING_STEP,
  SKIP_ONBOARDING_STEP,
  FINISH_ONBOARDING,
} from "store/actionTypes";

const ONBOARDING_QUERY = loader("./Onboarding6.graphql");
const SURVEYS = loader("./Survey/surveys3.graphql");
const COMPLETE_ONBOARDING = loader("./completeOnboarding.graphql");

export default compose(
  withApollo,
  withStore("userOnboarding", "viewer"),
  graphql(ONBOARDING_QUERY),
  graphql(SURVEYS, { name: "SURVEYS" }),
  branch(
    ({ SURVEYS: { loading } }) => loading,
    renderComponent(() => null)
  ),
  graphql(COMPLETE_ONBOARDING, { name: "completeOnboardingMutation" }),
  loading(() => (
    <div style={{ height: "100vh" }}>
      <Circles />
    </div>
  )),
  withHandlers({
    handleNextStep:
      ({ dispatch }) =>
      () => {
        dispatch({ type: NEXT_ONBOARDING_STEP });
      },
    handlePrevStep:
      ({ dispatch }) =>
      () =>
        dispatch({ type: PREV_ONBOARDING_STEP }),
    handleSkipStep:
      ({ dispatch }) =>
      () =>
        dispatch({ type: SKIP_ONBOARDING_STEP }),
    completeOnboarding:
      ({ dispatch, completeOnboardingMutation }) =>
      async () => {
        await completeOnboardingMutation();
        dispatch({ type: FINISH_ONBOARDING });
      },
  }),
  withProps(({ completeOnboarding }) => ({
    actions: {
      completeOnboarding,
    },
  }))
);
