import React from 'react'
import Button from 'components/UI/Button'
import compose from './compose'

export default compose(({ handleSendProfileReminder, btnState }) => (
  <Button
    bStyle={['none']}
    onClick={handleSendProfileReminder}
    disabled={btnState.disabled}
  >
    <i className={btnState.text} />
    {btnState.hoverText}
  </Button>
))
