import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import Button from "components/UI/Button";
import Icon from "components/UI/Icon";
import compose from "./compose";

import styles from "../ShowPositions.module.scss";

export default compose(({ data: { openPosition } }) => {
  const pendingProfiles = openPosition.candidates.filter(
    (c) => c.readyToRender
  ).length;

  return (
    <div className={styles.positionRow}>
      <Link
        to={
          "/team/" + openPosition.parentTeam.id + "/position/" + openPosition.id
        }
      >
        <Button bStyle={["none"]}>
          <h5 className={styles.name}>{openPosition.name}</h5>
          <div className={styles.right}>
            <p className={styles.candidates}>
              {openPosition.candidates.length + " candidates"}
            </p>
            {openPosition.candidates.length === 0 ? (
              <p className={styles.profiles}>
                <Icon icon="User" marginRight />
                Waiting for candidates
              </p>
            ) : (
              <p
                className={classNames(
                  styles.profiles,
                  pendingProfiles === openPosition.candidates.length &&
                    styles.profiles,
                  pendingProfiles < openPosition.candidates.length &&
                    styles.incomplete
                )}
              >
                <Icon icon="User" marginRight />
                {pendingProfiles +
                  "/" +
                  openPosition.candidates.length +
                  " profiles complete"}
              </p>
            )}
            <p
              className={classNames(
                styles.invites,
                openPosition.pendingInvites.length > 0 && styles.bold
              )}
            >
              <Icon icon="Email" marginRight />
              {openPosition.pendingInvites.length + " pending invites"}
            </p>
          </div>
        </Button>
      </Link>
    </div>
  );
});
