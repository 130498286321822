export default ({ teamGoals, viewer }) => {
  const list = []

  teamGoals
    .filter(g => g.createdBy.id === viewer.id)
    .forEach((goal, key) => {
      list.push({
        id: goal.id,
        title: goal.title,
        description: goal.description,
        order: key
      })
    })

  return list
}
