import React from "react";
import UserAvatar from "components/UserAvatar";
import Button from "components/UI/Button";
import Modal from "react-modal";
import classNames from "classnames";
import AvatarModal from "components/Modals/AvatarModal";
import compose from "./compose";
import Description from "./Description";

import styles from "./User.module.scss";

export default compose(
  ({
    data: { viewer },
    handleUploadAvatar,
    avatarEditor,
    toggleAvatarEditor,
    avatarFile,
    avatarEditorRef,
    fileDropRef,
    fileIsDragging,
  }) => (
    <div className={styles.main}>
      <div className={styles.wrapper}>
        <div className={styles.avatarWrapper}>
          <div
            className={classNames(
              styles.filedrop,
              fileIsDragging && styles.isDragging
            )}
            ref={fileDropRef}
          />
          <div className={styles.avatar}>
            <UserAvatar member={viewer} size="large" />
            <div className={styles.avatarButton}>
              <Modal
                isOpen={avatarEditor}
                shouldCloseOnOverlayClick
                className="avatarModal"
              >
                <Button
                  bStyle="close"
                  type="button"
                  onClick={() => toggleAvatarEditor(false)}
                >
                  <i className="fal fa-times" />
                </Button>
                <AvatarModal
                  toggleAvatarEditor={toggleAvatarEditor}
                  avatarFile={avatarFile}
                  avatarEditorRef={avatarEditorRef}
                />
              </Modal>
              <Button bStyle={["none"]} type="file">
                <input
                  type="file"
                  className={styles.fileInput}
                  onChange={handleUploadAvatar}
                />
                Upload avatar
              </Button>
              <p>or drag here</p>
            </div>
          </div>
        </div>
        <div className={styles.right}>
          <h2 className={styles.name}>{viewer.name}</h2>
          <Description />
        </div>
      </div>
    </div>
  )
);
