import React, { useState, useEffect } from "react";
import classNames from "classnames";
import Modal from "react-modal";
import Button from "components/UI/Button";
import Icon from "components/UI/Icon";
import NewOrganization from "components/Modals/NewOrg";
import getAvatarColor from "utils/orgAvatarColor";
import { CLOSE_INVITE } from "store/actionTypes";
import OrgInvitation from "components/Modals/OrgInvitation";
import { INVITE } from "store/actionTypes";
import QueryString from "query-string";
import compose from "./compose";

import styles from "./OrgSelect.module.scss";

export default compose(
  ({
    data: { invites },
    account,
    organizations,
    modal,
    updateModal,
    handleSelectOrg,
    handleSwitchAccount,
    viewer,
    inviteStore,
    dispatch,
  }) => {
    const [showWorkspaces, setShowWorkspaces] = useState(false);

    const { invite: inviteSecret } = QueryString.parse(window.location.search);

    useEffect(() => {
      const invite = invites.find((i) => i.secret === inviteSecret);

      if (invites.length > 0 && invite) {
        dispatch({
          type: INVITE,
          payload: invite,
        });
      }
    }, []);

    return (
      <div className={styles.bigSelectorWrapper}>
        <div className={styles.bigSelector}>
          <p className={styles.account}>{`Workspace: ${account.name}`}</p>
          <h3>Select an organisation</h3>
          <p>
            Choose an organisation to view the teams and positions associated
            with it.
          </p>
          {viewer.accounts.length > 0 && (
            <>
              <div className={styles.workspaces}>
                <Button
                  bStyle={["secondary"]}
                  onClick={() => {
                    if (showWorkspaces) {
                      setShowWorkspaces(false);
                    } else {
                      setShowWorkspaces(true);
                    }
                  }}
                >
                  <div className={styles.left}>
                    <div className={styles.icon}>
                      <Icon icon="Kebab" />
                    </div>
                    Choose another workspace
                    <span
                      className={classNames(
                        styles.arrow,
                        showWorkspaces && styles.close
                      )}
                    >
                      <Icon icon="Chevron" />
                    </span>
                  </div>
                </Button>

                <div
                  className={classNames(
                    styles.accountsWrapper,
                    showWorkspaces && styles.show
                  )}
                >
                  {viewer.accounts
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((a) => (
                      <li
                        className={classNames(
                          a.name === account.name && styles.selected
                        )}
                        key={"account-" + a.id}
                      >
                        <Button
                          type="button"
                          bStyle={["none"]}
                          onClick={() => {
                            handleSwitchAccount(a.id);
                            setShowWorkspaces(false);
                          }}
                        >
                          <div className={styles.left}>
                            <div
                              className={classNames(
                                styles.avatar,
                                styles[getAvatarColor(a)]
                              )}
                            >
                              <h5>{a.name[0]}</h5>
                            </div>
                            {a.name}
                          </div>
                          <div className={styles.arrow}>
                            <Icon icon="Chevron" />
                          </div>
                        </Button>
                      </li>
                    ))}
                </div>
              </div>
            </>
          )}
          <div className={styles.orgList}>
            {organizations &&
              organizations
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((org) => (
                  <Button
                    key={`Organization:${org.name}:${org.id}`}
                    bStyle={["none"]}
                    type="button"
                    onClick={() => handleSelectOrg(org.name)}
                  >
                    <div className={styles.org}>
                      <div className={styles.left}>
                        <div
                          className={classNames(
                            styles.avatar,
                            styles[getAvatarColor(org)]
                          )}
                        >
                          <h5>{org.name.charAt(0).toUpperCase()}</h5>
                        </div>
                        <h5>{org.name}</h5>
                      </div>
                      <p className={styles.members}>
                        {`${
                          org.usersInTalentPool > 0
                            ? org.usersInTalentPool
                            : "No"
                        } ${
                          org.usersInTalentPool === 1 ? "member" : "members"
                        }`}
                      </p>
                    </div>
                  </Button>
                ))}
            {account.adminRights && (
              <Button
                bStyle={["none"]}
                type="button"
                onClick={() => updateModal("newOrg")}
              >
                <div className={classNames(styles.org, styles.new)}>
                  <div className={styles.left}>
                    <div className={styles.avatar}>
                      <h5>
                        <i className="far fa-plus" />
                      </h5>
                    </div>
                    <h5>Create a new Organisation</h5>
                  </div>
                </div>
              </Button>
            )}
            <Modal
              isOpen={modal === "newOrg" && true}
              onRequestClose={() => updateModal("")}
              shouldCloseOnOverlayClick
              className={classNames(styles.modal, "create")}
            >
              <NewOrganization updateModal={updateModal} />
            </Modal>
            <Modal
              isOpen={inviteStore.isOpen}
              onRequestClose={() => dispatch({ type: CLOSE_INVITE })}
              shouldCloseOnOverlayClick
              className="create"
            >
              <Button
                bStyle="close"
                type="button"
                onClick={() => dispatch({ type: CLOSE_INVITE })}
              >
                <i className="fal fa-times" />
              </Button>
              <OrgInvitation invite={inviteStore.invite} />
            </Modal>
          </div>
        </div>
      </div>
    );
  }
);
