import React from "react";
import PersonalityChart from "components/Charts/Personality";

import Curve from "components/Charts/Personality/curve";
import Distribution from "components/Charts/Personality/Distribution";

import styles from "./index.module.scss";

export default ({ team, member }) => (
  <div className={styles.wrap}>
    <PersonalityChart
      big5={team.personality.filter((dim) => !dim.parent_id)}
      team={team}
      isCurrentUser={member}
    />
    <Distribution
      loadedMembers={team.members.filter((m) => m.readyToRender)}
      isCurrentUser={member}
    >
      {team.members.map((teamMember) => (
        <Curve
          key={teamMember.id}
          big5={teamMember.personality.filter((dim) => !dim.parent_id)}
          isTeamMember
          isCurrentUser={teamMember.id === member.id}
        />
      ))}
      <Curve
        big5={member.personality.filter((dim) => !dim.parent_id)}
        candidate
      />
    </Distribution>
  </div>
);
