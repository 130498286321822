import React from 'react'
import Wrapper from 'components/UI/Wrapper'
import Title from 'components/UI/Title'
import Subtitle from 'components/UI/Subtitle'
import CompetenciesList from './CompetenciesList'

import styles from './Competencies.module.scss'

export default ({ viewer }) => (
  <div className={styles.main}>
    <Wrapper>
      <Title text='Competency map' icon='fas fa-clipboard-check' />
      <Subtitle text='Your core competencies.' />
      <Subtitle
        verySmall
        text='The competencies you rely on most to achieve your professional goals.'
      />

      <CompetenciesList viewer={viewer} />
    </Wrapper>
  </div>
)
