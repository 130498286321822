import React from "react";
import { compose, withState, branch } from "recompose";
import { withRouter } from "react-router-dom";
import { graphql } from "react-apollo";
import scrollToTop from "utils/scrollToTop";
import FullPage from "components/Loaders/FullPage";
import withStore from "utils/withStore";

import { loader } from "graphql.macro";
import handleLoading from "utils/loader";

const CANDIDATE_QUERY = loader("client/Queries/CandidateQuery198.graphql");
const ASSESSMENT_SCORECARD = loader("./assessmentScorecard3.graphql");

export default compose(
  withRouter,
  withStore("viewer", "selectedOrg"),
  withState("currentTab", "setCurrentTab", 0),
  withState("selectedEvaluators", "setSelectedEvaluators", []),
  withState("list", "setList", ["Introduction", "Evaluation", "Final report"]),
  withState("openModal", "updateModal", null),
  withState("overlayClose", "setOverlayClose", true),
  graphql(ASSESSMENT_SCORECARD, {
    name: "ASSESSMENT_SCORECARD",
    options: ({
      match: {
        params: { scorecardId },
      },
    }) => ({
      variables: {
        id: parseInt(scorecardId, 10),
      },
    }),
  }),
  graphql(CANDIDATE_QUERY, {
    name: "CANDIDATE_QUERY",
    options: ({
      match: {
        params: { candidateId, teamId, positionId, orgId, candidateType },
      },
    }) => ({
      variables: {
        user_id:
          candidateType === "candidate" ? parseInt(candidateId, 10) : null,
        person_id:
          candidateType === "person" ? parseInt(candidateId, 10) : null,
        team_id: parseInt(teamId, 10),
        position_id: parseInt(positionId, 10),
        org_id: parseInt(orgId, 10),
      },
      notifyOnNetworkStatusChange: true,
    }),
  }),
  branch(
    (props) => {
      const condition =
        props.CANDIDATE_QUERY &&
        props.CANDIDATE_QUERY.loading &&
        props.ASSESSMENT_SCORECARD &&
        props.ASSESSMENT_SCORECARD.loading;
      return condition;
    },
    handleLoading(() => <FullPage />)
  ),
  scrollToTop
);
