import React from 'react'
import classNames from 'classnames'
import { ReactComponent as PosPlaceholder } from 'images/PosPlaceholder.svg'

import styles from './CardLoader.module.scss'

export default ({ small, template }) => (
  <div className={classNames(styles.card, small && styles.small, template && styles.template)}>
    {small && <div className={styles.avatar} />}
    {!small && !template && <PosPlaceholder />}
    {template && <div className={styles.content}><div className={styles.top}></div><div className={styles.bottom}><div className={styles.line}></div><div className={styles.line}></div></div></div>}
  </div>
)
