import React, { useState, createElement } from "react";
import classNames from "classnames";

import styles from "./MeetingRecorder.module.scss";

export default () => {
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const [sourceElement, setSourceElement] = useState("");
  const [recordingBase64, setRecordingBase64] = useState("");
  const [isRecording, setIsRecording] = useState(false);

  var audioElement = document.getElementsByClassName("audio-element")[0];

  console.log(audioElement);

  /** Creates a source element for the the audio element in the HTML document*/
  const createSourceForAudioElement = () => {
    const sourceElement = React.createElement("source");

    setSourceElement(sourceElement);
  };

  //Controller

  /** Stores the actual start time when an audio recording begins to take place to ensure elapsed time start time is accurate*/
  var audioRecordStartTime;

  /** Starts the audio recording*/
  const startAudioRecording = () => {
    setRecordingBase64("");
    setIsRecording(true);
    console.log("Recording Audio...");

    //If a previous audio recording is playing, pause it
    let recorderAudioIsPlaying = isAudioPlaying; // the paused property tells whether the media element is paused or not
    console.log("paused?", !recorderAudioIsPlaying);
    if (recorderAudioIsPlaying) {
      audioElement.pause();
      //also hide the audio playing indicator displayed on the screen
      setIsAudioPlaying(false);
    }

    //start recording using the audio recording API
    audioRecorder
      .start()
      .then(() => {
        //on success

        //store the recording start time to display the elapsed time according to it
        audioRecordStartTime = new Date();
      })
      .catch((error) => {
        //on error
        //No Browser Support Error
        if (
          error.message.includes(
            "mediaDevices API or getUserMedia method is not supported in this browser."
          )
        ) {
          console.log("To record audio, use browsers like Chrome and Firefox.");
        }

        //Error handling structure
        switch (error.name) {
          case "AbortError": //error from navigator.mediaDevices.getUserMedia
            console.log("An AbortError has occured.");
            break;
          case "NotAllowedError": //error from navigator.mediaDevices.getUserMedia
            console.log(
              "A NotAllowedError has occured. User might have denied permission."
            );
            break;
          case "NotFoundError": //error from navigator.mediaDevices.getUserMedia
            console.log("A NotFoundError has occured.");
            break;
          case "NotReadableError": //error from navigator.mediaDevices.getUserMedia
            console.log("A NotReadableError has occured.");
            break;
          case "SecurityError": //error from navigator.mediaDevices.getUserMedia or from the MediaRecorder.start
            console.log("A SecurityError has occured.");
            break;
          case "TypeError": //error from navigator.mediaDevices.getUserMedia
            console.log("A TypeError has occured.");
            break;
          case "InvalidStateError": //error from the MediaRecorder.start
            console.log("An InvalidStateError has occured.");
            break;
          case "UnknownError": //error from the MediaRecorder.start
            console.log("An UnknownError has occured.");
            break;
          default:
            console.log("An error occured with the error name " + error.name);
        }
      });
  };
  /** Stop the currently started audio recording & sends it
   */
  const stopAudioRecording = () => {
    setIsRecording(false);
    console.log("Stopping Audio Recording...");

    //stop the recording using the audio recording API
    audioRecorder
      .stop()
      .then((audioAsblob) => {
        //Play recorder audio
        playAudio(audioAsblob);
      })
      .catch((error) => {
        //Error handling structure
        switch (error.name) {
          case "InvalidStateError": //error from the MediaRecorder.stop
            console.log("An InvalidStateError has occured.");
            break;
          default:
            console.log("An error occured with the error name " + error.name);
        }
      });
  };

  /** Cancel the currently started audio recording */
  const cancelAudioRecording = () => {
    setIsRecording(false);
    setRecordingBase64("");
    console.log("Canceling audio...");

    //cancel the recording using the audio recording API
    audioRecorder.cancel();
  };

  const playAudio = (recorderAudioAsBlob) => {
    //read content of files (Blobs) asynchronously
    let reader = new FileReader();

    //once content has been read
    reader.onload = (e) => {
      //store the base64 URL that represents the URL of the recording audio
      let base64URL = e.target.result;

      //set the audio element's source using the base64 URL
      setRecordingBase64(base64URL);

      //play the audio after successfully setting new src and type that corresponds to the recorded audio
      console.log("Playing audio...");
      // audioElement.play();

      //Display text indicator of having the audio play in the background
      setIsAudioPlaying(true);
    };

    //read content and convert it to a URL (base64)
    reader.readAsDataURL(recorderAudioAsBlob);
  };

  // audio-recording.js ---------------
  //API to handle audio recording

  var audioRecorder = {
    /** Stores the recorded audio as Blob objects of audio data as the recording continues*/
    audioBlobs: [] /*of type Blob[]*/,
    /** Stores the reference of the MediaRecorder instance that handles the MediaStream when recording starts*/
    mediaRecorder: null /*of type MediaRecorder*/,
    /** Stores the reference to the stream currently capturing the audio*/
    streamBeingCaptured: null /*of type MediaStream*/,
    /** Start recording the audio
     * @returns {Promise} - returns a promise that resolves if audio recording successfully started
     */
    start: function () {
      //Feature Detection
      if (!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia)) {
        //Feature is not supported in browser
        //return a custom error
        return Promise.reject(
          new Error(
            "mediaDevices API or getUserMedia method is not supported in this browser."
          )
        );
      } else {
        //Feature is supported in browser

        //create an audio stream
        return (
          navigator.mediaDevices
            .getUserMedia({ audio: true } /*of type MediaStreamConstraints*/)
            //returns a promise that resolves to the audio stream
            .then((stream) /*of type MediaStream*/ => {
              //save the reference of the stream to be able to stop it when necessary
              audioRecorder.streamBeingCaptured = stream;

              //create a media recorder instance by passing that stream into the MediaRecorder constructor
              audioRecorder.mediaRecorder = new MediaRecorder(
                stream
              ); /*the MediaRecorder interface of the MediaStream Recording
                      API provides functionality to easily record media*/

              //clear previously saved audio Blobs, if any
              audioRecorder.audioBlobs = [];

              //add a dataavailable event listener in order to store the audio data Blobs when recording
              audioRecorder.mediaRecorder.addEventListener(
                "dataavailable",
                (event) => {
                  //store audio Blob object
                  audioRecorder.audioBlobs.push(event.data);
                }
              );

              //start the recording by calling the start method on the media recorder
              audioRecorder.mediaRecorder.start();
            })
        );

        /* errors are not handled in the API because if its handled and the promise is chained, the .then after the catch will be executed*/
      }
    },
    /** Stop the started audio recording
     * @returns {Promise} - returns a promise that resolves to the audio as a blob file
     */
    stop: function () {
      //return a promise that would return the blob or URL of the recording
      return new Promise((resolve) => {
        //save audio type to pass to set the Blob type
        let mimeType = audioRecorder.mediaRecorder.mimeType;

        //listen to the stop event in order to create & return a single Blob object
        audioRecorder.mediaRecorder.addEventListener("stop", () => {
          //create a single blob object, as we might have gathered a few Blob objects that needs to be joined as one
          let audioBlob = new Blob(audioRecorder.audioBlobs, {
            type: mimeType,
          });

          //resolve promise with the single audio blob representing the recorded audio
          resolve(audioBlob);
        });
        audioRecorder.cancel();
      });
    },
    /** Cancel audio recording*/
    cancel: function () {
      //stop the recording feature
      audioRecorder.mediaRecorder.stop();

      //stop all the tracks on the active stream in order to stop the stream
      audioRecorder.stopStream();

      //reset API properties for next recording
      audioRecorder.resetRecordingProperties();
    },
    /** Stop all the tracks on the active stream in order to stop the stream and remove
     * the red flashing dot showing in the tab
     */
    stopStream: function () {
      //stopping the capturing request by stopping all the tracks on the active stream
      audioRecorder.streamBeingCaptured
        .getTracks() //get all tracks from the stream
        .forEach((track) /*of type MediaStreamTrack*/ => track.stop()); //stop each one
    },
    /** Reset all the recording properties including the media recorder and stream being captured*/
    resetRecordingProperties: function () {
      audioRecorder.mediaRecorder = null;
      audioRecorder.streamBeingCaptured = null;

      /*No need to remove event listeners attached to mediaRecorder as
          If a DOM element which is removed is reference-free (no references pointing to it), the element itself is picked
          up by the garbage collector as well as any event handlers/listeners associated with it.
          getEventListeners(audioRecorder.mediaRecorder) will return an empty array of events.*/
    },
  };

  console.log(sourceElement.toString());

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <h1 className="title">Audio Recording API Demo</h1>
        {!isRecording && (
          <button onClick={() => startAudioRecording()}>Start recording</button>
        )}
        {isRecording && (
          <>
            <button onClick={() => cancelAudioRecording()}>
              Cancel recording
            </button>
            <button onClick={() => stopAudioRecording()}>Stop recording</button>
          </>
        )}
      </div>
      <audio controls className="audio-element">
        {recordingBase64 && <source src={recordingBase64}></source>}
      </audio>
    </div>
  );
};
