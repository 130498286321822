import React, { useState } from "react";
import TemplateModal from "components/PositionTemplates/TemplateModal";
import Modal from "react-modal";
import Button from "components/UI/Button";
import classNames from "classnames";

export default ({ orgId, template }) => {
  const [modal, updateModal] = useState("t");
  
  console.log(template)
  return (
    <Modal
      closeTimeoutMS={350}
      isOpen={!!modal}
      onRequestClose={() => {
        updateModal("");
      }}
      shouldCloseOnOverlayClick
      className={"modal"}
    >
      <Button
        bStyle="close"
        type="button"
        onClick={() => {
          updateModal("");
        }}
      >
        <i className="fal fa-times" />
      </Button>
      <TemplateModal
        moreData={{}}
        templateSecret={template}
        updateModal={updateModal}
        orgId={orgId}
        notAdmin
      />
    </Modal>
  );
};
