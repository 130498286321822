import { SET_ACCOUNT } from 'store/actionTypes'

const initialState = {
  id: null,
  name: '',
  adminRights: false,
  frozen: false,
  whitelabel: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ACCOUNT:
      return Object.assign({}, state, action.payload)

    default:
      return state
  }
}
