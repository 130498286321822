import React, { useState, useEffect } from "react";
import classNames from "classnames";
import CommentWidget from "components/Comment";
import Button from "components/UI/Button";
import Icon from "components/UI/Icon";
import MiniChart from "../MiniChart";
import createList from "./createList";
import compose from "./compose";

import styles from "./SimpleEvaluationSection.module.scss";

const getContentHtml = (content) => ({ __html: content });

export default compose(
  ({
    data: { candidateInTeam },
    list,
    setList,
    handleSaveRating,
    candidate,
    viewer,
  }) => {
    const [showMore, setShowMore] = useState("");

    const sectionComplete = (type) => {
      if (type === "intuition") return true;
      return !candidate[type]?.some((p) => p.score === null);
    };

    useEffect(() => {
      if (candidateInTeam) {
        setList(createList({ candidateInTeam, viewer }));
      }
    }, [candidateInTeam]);

    const getStyle = (title) => {
      const element = document.getElementsByClassName("moreInfo-" + title)[0];

      if (element) {
        if (showMore && title === showMore.title) {
          return { maxHeight: element.scrollHeight };
        } else {
          return { maxHeight: "0" };
        }
      }
    };

    return (
      <div className={styles.wrapper}>
        {list.map((row) => (
          <table
            key={"Row-" + row.bigTitle}
            className={classNames(styles.table, styles[row.color])}
          >
            <tbody>
              <tr>
                <th>{row.bigTitle}</th>
                <th></th>
                <th></th>
              </tr>
              {row.items.map((item) => {
                return (
                  <React.Fragment key={"Item-" + item.item_id}>
                    <tr
                      className={classNames(
                        styles.mainRow,
                        showMore.title === item.title && styles.active
                      )}
                    >
                      <td
                        className={classNames(
                          styles.title,
                          item.desc && styles.pointer
                        )}
                        onClick={() => {
                          if (showMore.title === item.title) {
                            setShowMore("");
                          } else if (item.desc) {
                            setShowMore(item);
                          }
                        }}
                      >
                        {item.title}
                        {item.desc && (
                          <span
                            className={classNames(
                              styles.arrow,
                              showMore.title === item.title && styles.open
                            )}
                          >
                            <Button bStyle={["none"]}>
                              <Icon icon="Chevron" />
                            </Button>
                          </span>
                        )}
                      </td>
                      <td className={styles.evaluate}>
                        {[...Array(5)].map((e, i) => (
                          <div
                            key={`scoreBtn-${i}`}
                            className={classNames(
                              styles.button,
                              item.evaluation === i + 1 && styles.selected
                            )}
                          >
                            <Button
                              bStyle={["none"]}
                              type="button"
                              onClick={() => {
                                handleSaveRating({
                                  evaluation_type: item.evType,
                                  item_id: item.item_id,
                                  evaluation: i + 1,
                                });
                              }}
                            >
                              {i + 1}
                            </Button>
                          </div>
                        ))}
                        <div
                          className={classNames(
                            styles.noInfo,
                            item.evaluation === 0 && styles.selected
                          )}
                        >
                          <Button
                            bStyle={["none"]}
                            type="button"
                            onClick={() => {
                              handleSaveRating({
                                evaluation_type: item.evType,
                                item_id: item.item_id,
                                evaluation: 0,
                              });
                            }}
                          >
                            {window.innerWidth > 1000
                              ? "Not enough information"
                              : "N/A"}
                          </Button>
                        </div>
                      </td>
                      <td
                        className={classNames(
                          styles.addComment,
                          !item.evaluation &&
                            item.evaluation !== 0 &&
                            styles.disabled
                        )}
                      >
                        <CommentWidget
                          relative
                          showAll
                          comments={item.comments || []}
                          objType="position"
                          objId={candidate.position.id}
                          subjectType={
                            candidate.type === "person" ? "person" : "candidate"
                          }
                          subjectId={candidate.id}
                          itemType="evaluation"
                          itemId={item.id}
                          modal
                          noIcon={window.innerWidth < 1000}
                        />
                        <span className={styles.tooltip}>
                          <p>Please leave evaluation before commenting.</p>
                        </span>
                      </td>
                    </tr>
                    <tr
                      style={getStyle(item.title)}
                      className={classNames(
                        "moreInfo-" + item.title,
                        styles.moreInfo,
                        showMore && showMore.title === item.title && styles.show
                      )}
                    >
                      <td>
                        <div
                          className={styles.description}
                          dangerouslySetInnerHTML={getContentHtml(item.desc)}
                        ></div>
                        <div className={styles.row}>
                          {item.questions && item.questions.length > 0 && (
                            <div className={styles.questions}>
                              {item.questions.slice(0, 2).map((q, index) => (
                                <div
                                  key={`question-${index}`}
                                  className={styles.questionWrapper}
                                >
                                  <p className={styles.ask}>Ask</p>
                                  <p className={styles.question}>{q}</p>
                                </div>
                              ))}
                            </div>
                          )}
                          {((item.resource === "personality" &&
                            sectionComplete("personality")) ||
                            (item.resource === "values" &&
                              sectionComplete("values"))) && (
                            <div className={styles.infoSection}>
                              {item.dims &&
                                item.dims.map((d, i) => (
                                  <div
                                    key={`dimension-${i}`}
                                    className={styles.multiple}
                                  >
                                    <div className={styles.left}>
                                      <h5 className={styles.title}>
                                        {d.dim.name}
                                      </h5>
                                      {d.dim.descLong && (
                                        <p className={styles.subtitle}>
                                          {d.dim.descLong}
                                        </p>
                                      )}
                                      {!d.dim.descLong && d.dim.desc && (
                                        <p className={styles.subtitle}>
                                          {d.dim.desc}
                                        </p>
                                      )}
                                      {d.label && (
                                        <div
                                          className={classNames(
                                            styles.label,
                                            styles[d.color]
                                          )}
                                        >
                                          <p>{d.label}</p>
                                        </div>
                                      )}

                                      <div className={styles.questions}>
                                        {(d.dim.questionForCandidate ||
                                          d.dim.question_for_candidate) && (
                                          <div
                                            className={styles.questionWrapper}
                                          >
                                            <p className={styles.ask}>Ask</p>
                                            <p className={styles.question}>
                                              {d.dim.questionForCandidate ||
                                                d.dim.question_for_candidate}
                                            </p>
                                          </div>
                                        )}
                                        {d.dim.socDes &&
                                          d.dim.socDes.length > 0 && (
                                            <div
                                              key={d.dim.questionForCandidate}
                                              className={styles.questionWrapper}
                                            >
                                              <p className={styles.ask}>Ask</p>
                                              <p className={styles.question}>
                                                {d.dim.socDes[0].question}
                                              </p>
                                            </div>
                                          )}
                                      </div>
                                    </div>
                                    <div
                                      className={classNames(
                                        styles.graphWrapper,
                                        "graphWrapper"
                                      )}
                                    >
                                      <div className={styles.content}>
                                        <div className={styles.graph}>
                                          <MiniChart
                                            resource={item.resource}
                                            dim={d.dim.name}
                                            min={
                                              item.resource === "personality"
                                                ? d.dim.label_min
                                                : "Less important"
                                            }
                                            max={
                                              item.resource === "personality"
                                                ? d.dim.label_max
                                                : "Very important"
                                            }
                                            candidate={candidate}
                                            team={candidate.position.parentTeam}
                                          />
                                        </div>
                                        {d.dim &&
                                          item.resource === "personality" && (
                                            <>
                                              <h5 className={styles.motivator}>
                                                <Icon icon="Star" marginRight />
                                                {d.dim.inTeamStrengths[0].short
                                                  .charAt(0)
                                                  .toUpperCase() +
                                                  d.dim.inTeamStrengths[0].short.slice(
                                                    1
                                                  )}
                                              </h5>
                                              <p className={styles.behaviour}>
                                                {d.dim.inTeamStrengths[0].long}
                                              </p>
                                              <h5 className={styles.derailer}>
                                                <Icon
                                                  icon="Warning"
                                                  marginRight
                                                />
                                                {d.dim.inTeamDerailers[0].short
                                                  .charAt(0)
                                                  .toUpperCase() +
                                                  d.dim.inTeamDerailers[0].short.slice(
                                                    1
                                                  )}
                                              </h5>
                                              <p className={styles.behaviour}>
                                                {d.dim.inTeamDerailers[0].long}
                                              </p>
                                            </>
                                          )}
                                        {d.dim && item.resource === "values" && (
                                          <>
                                            <h5>Their attitude</h5>
                                            <p className={styles.behaviour}>
                                              {d.dim.score > 50
                                                ? d.dim.description_max
                                                : d.dim.description_min}
                                            </p>
                                          </>
                                        )}
                                      </div>
                                      <div className={styles.overlay} />
                                    </div>
                                  </div>
                                ))}
                            </div>
                          )}
                          {item.resource === "competencies" && item.dim && (
                            <div
                              className={classNames(
                                styles.graphWrapper,
                                "graphWrapper"
                              )}
                            >
                              <div className={styles.content}>
                                {item.dim && (
                                  <div className={styles.graph}>
                                    <MiniChart
                                      resource={item.resource}
                                      dim={item.title}
                                      candidate={
                                        candidate.competencies.some(
                                          (tc) => tc.score !== null
                                        ) && candidate
                                      }
                                      team={candidate.position.parentTeam}
                                    />
                                  </div>
                                )}
                                {item.dim && item.resource === "competencies" && (
                                  <>
                                    <h5>Behavioural examples</h5>
                                    {item.dim.behaviours.map((b, i) => (
                                      <p
                                        key={`behaviour-${i}`}
                                        className={styles.behaviour}
                                      >
                                        {b}
                                      </p>
                                    ))}
                                  </>
                                )}
                              </div>
                              <div className={styles.overlay} />
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
        ))}
      </div>
    );
  }
);
