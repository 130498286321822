import Rollbar from 'rollbar'

const initRollbar = () => {
  window.rollbar = new Rollbar({
    accessToken: process.env.REACT_APP_ROLLBAR_KEY,
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
      environment: 'production',
      client: {
        javascript: {
          source_map_enabled: true,
          code_version: process.env.REACT_APP_GIT_REVISION,
          guess_uncaught_frames: true
        }
      }
    }
  })
  window.onerror = (msg, file, line, col, error) => window.rollbar.error(error)
}

const addUserToRollbar = ({ id, email, username }) => {
  if (typeof window.rollbar !== 'object') {
    return
  }

  window.rollbar.configure({
    payload: {
      person: {
        id,
        email,
        username
      }
    }
  })
}

export { initRollbar, addUserToRollbar }
