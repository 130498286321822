import React from 'react'
import classNames from 'classnames'

import styles from './Card.module.scss'

export default ({ cardStyle, children }) => (
  <div
    className={
      typeof cardStyle === 'object'
        ? classNames(
          styles.wrapper,
          cardStyle.map(s => styles[s])
        )
        : styles.wrapper
    }
    aria-label='card'
  >
    {children}
  </div>
)
