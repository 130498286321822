import { withHandlers, compose, withState } from "recompose";
import { graphql } from "react-apollo";
import { loader } from "graphql.macro";
import handleLoading from "utils/loader";
import Circles from "components/Loaders/Circles";
import isEmail from "utils/isEmail";

const INVITE_MUTATION = loader("./sendOrgInvites2.graphql");
const VALIDATE_INVITE_EMAIL = loader(
  "client/Mutations/validateInviteEmail1.graphql"
);
const TALENT_POOL_QUERY = loader("client/Queries/TalentPoolQuery9.graphql");

export default compose(
  graphql(INVITE_MUTATION, {
    name: "sendInvite",
    options: ({ organization }) => {
      return {
        update: (proxy, { data: { sendOrgInvites } }) => {
          const data = proxy.readQuery({
            query: TALENT_POOL_QUERY,
            variables: {
              id: organization.id,
            },
          });

          data.organization.talentPool.push(...sendOrgInvites);
          data.organization.usersInTalentPool =
            data.organization.usersInTalentPool +
            sendOrgInvites.filter(Boolean).length;

          proxy.writeQuery({
            query: TALENT_POOL_QUERY,
            data,
            variables: {
              id: organization.id,
            },
          });
        },
      };
    },
  }),
  graphql(VALIDATE_INVITE_EMAIL, {
    name: "validateInviteEmail",
  }),
  handleLoading(Circles),
  withState("email", "setEmail", ""),
  withState("invitesList", "setInvitesList", []),
  withState("error", "setError", null),
  withState("success", "setSuccess", false),
  withState("isLoading", "setLoading", false),
  withState("limitExceededMessage", "setLimitExceededMessage", null),
  withHandlers({
    handleSubmit:
      ({ invitesList, setError, sendInvite, setLoading, updateModal }) =>
      () => {
        setError(null);
        setLoading(true);

        sendInvite({
          variables: {
            invites: invitesList,
          },
        })
          .then(() => {
            setLoading(false);
            updateModal(false);
          })
          .catch((err) => {
            setError(err.message.replace("GraphQL error: ", ""));
          });
      },
    handleValidateEmail:
      ({
        organization,
        validateInviteEmail,
        setError,
        invitesList,
        setInvitesList,
        setEmail,
        setLimitExceededMessage,
        setOverlayClose,
      }) =>
      (emailValue) => {
        const email = emailValue.replace(/\s/g, "");
        // console.log({email, trim: email.trim()})
        if (!isEmail(email)) {
          return setError("Please enter a valid email address!");
        }
        validateInviteEmail({
          variables: {
            offset_amount: invitesList.length,
            validationCase: "organization",
            org_id: organization.id,
            email,
          },
          notifyOnNetworkStatusChange: true,
          fetchPolicy: "no-cache",
        })
          .then((res) => {
            if (res.data.validateInviteEmail.validationResult) {
              setLimitExceededMessage(
                res.data.validateInviteEmail.validationResult
              );
            }
            if (res.data.validateInviteEmail.success) {
              setInvitesList([
                ...invitesList,
                { orgId: organization.id, roleType: 2, email },
              ]);
              setOverlayClose && setOverlayClose(false);
              setEmail("");
            }
            return setError(res.data.validateInviteEmail.message);
          })
          .catch((err) => setError(err.message.replace("GraphQL error: ", "")));
      },
  })
);
