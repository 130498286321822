import React, { useEffect } from "react";
import Modal from "react-modal";
import classNames from "classnames";
import SidebarWrapper from "components/UI/SidebarWrapper";
import NewTeam from "components/Modals/NewTeam";
import NewAccount from "components/Modals/NewAccount";
import Button from "components/UI/Button";
import Icon from "components/UI/Icon";
import TeamsList from "./TeamsList";
import LearnMoreModal from "./LearnMoreModal";
import compose from "./compose";
import styles from "./Teams.module.scss";

export default compose(({ selectedOrg, updateModal, modal, history }) => {
  return (
    <SidebarWrapper noHeader>
      <div className={styles.showTeams}>
        <div className={styles.header}>
          <h2 className={styles.title}>Teams</h2>
          <p className={styles.subtitle}>
            Here you will see the teams you have created or have been added to.
          </p>
          {selectedOrg.adminRights && (
            <Button
              bStyle={["primary", "verySmall"]}
              onClick={() => updateModal("newTeam")}
            >
              <Icon icon="Plus" marginRight />
              Create team
            </Button>
          )}
        </div>
        <TeamsList
          selectedOrg={selectedOrg}
          history={history}
          updateModal={updateModal}
        />
        {modal === "newTeam" && (
          <Modal
            isOpen={modal === "newTeam"}
            onRequestClose={() => updateModal(false)}
            shouldCloseOnOverlayClick
            className={classNames(styles.modal, "create")}
          >
            <Button
              bStyle="close"
              type="button"
              onClick={() => updateModal(false)}
            >
              <i className="fal fa-times" />
            </Button>
            <NewTeam organization={selectedOrg} />
          </Modal>
        )}
        {modal === "teamGuide" && (
          <Modal
            isOpen={modal === "teamGuide"}
            onRequestClose={() => updateModal(false)}
            shouldCloseOnOverlayClick
            className={styles.modal}
          >
            <Button
              bStyle="close"
              type="button"
              onClick={() => updateModal(false)}
            >
              <i className="fal fa-times" />
            </Button>
            <LearnMoreModal updateModal={updateModal} />
          </Modal>
        )}
        {modal === "newAccount" && (
          <Modal
            isOpen={modal === "newAccount"}
            onRequestClose={() => updateModal(false)}
            shouldCloseOnOverlayClick
            className={classNames(styles.modal, "create")}
          >
            <Button
              bStyle="close"
              type="button"
              onClick={() => updateModal(false)}
            >
              <i className="fal fa-times" />
            </Button>
            <NewAccount updateModal={updateModal} />
          </Modal>
        )}
      </div>
    </SidebarWrapper>
  );
});
