import React, { useState, useEffect } from "react";
import Field from "components/UI/Field/index";
import SidebarHeader from "components/UI/SidebarHeader/index";
import SidebarWrapper from "components/UI/SidebarWrapper/index";
import InputGroup from "components/v2/UI/InputGroup/index";
import Layout from "layouts/index";
import styles from "../styles.module.scss";

import TextEditor from "components/TextEditor";
import deserialize from "utils/deserialize";
import serializer from "components/TextEditor/editorSerializer";
import compose from "./compose";
import Button from "components/UI/Button/index";

export default compose(
  ({ groupData, setGroupData, handleCreateAssessmentGroup }) => {
    const updateField = (field, value) => {
      setGroupData({ ...groupData, [field]: value });
    };

    const validateForm = () => {
      return groupData.title;
    };

    const [descriptionValue, setDescriptionValue] = useState(
      deserialize(groupData.subtitle)
    );

    let timeoutId = null;

    const handleTextEditorChange = (value) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        setDescriptionValue(value);
      }, 750);
    };

    useEffect(() => {
      updateField("description", serializer.serialize(descriptionValue));
    }, [descriptionValue]);

    return (
      <SidebarWrapper>
        <SidebarHeader title="Create assessment session"></SidebarHeader>
        <Layout title="Create assessment group" icon="360Assessment" noMargin>
          <div className={styles.assessmentGroup__mainContainer}>
            <div className={styles.assessmentGroup__titleContainer}>
              <div className={styles.assessmentGroup__titleItem}>
                <InputGroup
                  label="Title"
                  description="Enter the title of the assessment group"
                >
                  <Field
                    type="text"
                    value={groupData.title}
                    onChange={(e) => updateField("title", e.target.value)}
                    white
                    noBorder
                  />
                </InputGroup>
                <div></div>
              </div>
              <div className={styles.assessmentGroup__titleItem}>
                <InputGroup
                  label="Subtitle"
                  description="Enter the subtitle of the assessment group (optional)"
                >
                  <Field
                    type="text"
                    white
                    noBorder
                    value={groupData.subtitle}
                    onChange={(e) => updateField("subtitle", e.target.value)}
                  />
                </InputGroup>
              </div>
            </div>
            <div>
              {" "}
              <InputGroup
                label="Conclusion"
                description="Enter the conclusion of the assessment group"
              >
                <TextEditor
                  value={descriptionValue}
                  handleChange={handleTextEditorChange}
                />
              </InputGroup>
            </div>

            <div>
              <Button
                disabled={!validateForm()}
                onClick={() =>
                  validateForm() && handleCreateAssessmentGroup(groupData)
                }
              >
                Create Group
              </Button>
            </div>
          </div>
        </Layout>
      </SidebarWrapper>
    );
  }
);
