import React, { useState } from "react";
import classNames from "classnames";
import CommentWidget from "components/Comment";
import Button from "components/UI/Button";
import Icon from "components/UI/Icon";
import TextEditor from "components/TextEditor";
import { useDebounce } from "utils/debounce";
import EvaluationInfo from "./EvaluationInfo";
import Totals from "./Totals";
import compose from "./compose";

import styles from "./OverviewSection.module.scss";

const getContentHtml = (content) => ({ __html: content });

export default compose(
  ({
    data: { candidateAssessmentSummary },
    handleUpsertCandidateAssessmentSummary,
    candidate,
    list,
    executiveSummary,
    setExecutiveSummary,
    saved,
  }) => {
    const [showMore, setShowMore] = useState("");

    const autoSave = useDebounce(() => {
      handleUpsertCandidateAssessmentSummary(executiveSummary);
    }, 500);

    const getStyle = (title) => {
      const element = document.getElementsByClassName("moreInfo-" + title)[0];

      if (element) {
        if (showMore && title === showMore.title) {
          return { maxHeight: element.scrollHeight };
        } else {
          return { maxHeight: "0" };
        }
      }
    };

    return (
      <div className={styles.wrapper}>
        {/* <div className={styles.totals}>
          <Totals list={list} />
        </div> */}
        <div className={styles.execSummary}>
          <h3 className={styles.title}>Executive summary</h3>
          <div className={styles.textbox}>
            <p className={classNames(styles.autosave, saved && styles.show)}>
              Auto-saved
            </p>
            <TextEditor
              value={executiveSummary}
              handleChange={setExecutiveSummary}
              autoSave={autoSave}
              placeholder="Write an executive summary"
            />
          </div>
        </div>
        {list.map((row) => (
          <table
            key={"Row-" + row.groupTitle}
            className={classNames(styles.table, styles[row.color])}
          >
            <tbody>
              <tr className={classNames(styles.headRow, styles[row.color])}>
                <th>{row.groupTitle}</th>
                <th></th>
                <th></th>
              </tr>
              {row.items.map((item) => {
                return (
                  <React.Fragment key={"Item-" + item.id}>
                    <tr
                      className={classNames(
                        styles.mainRow,
                        showMore.title === item.title && styles.active
                      )}
                    >
                      <td
                        className={classNames(
                          styles.title,
                          item.description && styles.pointer
                        )}
                        onClick={() => {
                          if (showMore.title === item.title) {
                            setShowMore("");
                          } else if (item.description) {
                            setShowMore(item);
                          }
                        }}
                      >
                        {item.title}
                        {item.description && (
                          <span
                            className={classNames(
                              styles.arrow,
                              showMore.title === item.title && styles.open
                            )}
                          >
                            <Button bStyle={["none"]}>
                              <Icon icon="Chevron" />
                            </Button>
                          </span>
                        )}
                      </td>
                      <td className={styles.evaluate}>
                        <EvaluationInfo item={item} />
                      </td>
                      <td
                        className={classNames(
                          styles.addComment,
                          item.comments < 1 && styles.disabled
                        )}
                      >
                        <CommentWidget
                          relative
                          showAll
                          comments={
                            candidate.comments.filter(
                              (c) => c.item_id === item.id
                            ) || []
                          }
                          objType="position"
                          objId={candidate.position.id}
                          subjectType={
                            candidate.type === "person" ? "person" : "candidate"
                          }
                          subjectId={candidate.id}
                          itemType="assessment_row"
                          itemId={item.id}
                          modal
                          noIcon={window.innerWidth < 1000}
                        />
                      </td>
                    </tr>
                    <tr
                      style={getStyle(item.title)}
                      className={classNames(
                        "moreInfo-" + item.title,
                        styles.moreInfo,
                        showMore && showMore.title === item.title && styles.show
                      )}
                    >
                      <td>
                        <div
                          className={styles.description}
                          dangerouslySetInnerHTML={getContentHtml(
                            item.description
                          )}
                        ></div>
                      </td>
                    </tr>
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
        ))}
      </div>
    );
  }
);
